// Data
import { listOfCountries } from './countries';

export const getCountryCode = (name) => {
	const country = listOfCountries.find((item) => item.properties.name === name);

	if (country) {
		return country.id;
	}
};
