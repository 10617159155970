// Core
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { compose } from 'recompose';

// Context
import { RootContext } from 'contexts/RootContext';

// UI
import RadioButton from 'components/Radio/RadioButton/RadioButton';
import { MultipleSelectDropdown } from 'components/MultipleSelectDropdown';

// Styles
import styles from './RadioTerritoriesGroup.module.css';

const RadioTerritoriesGroup = ({
	name,
	className,
	disabled,
	horizontal,
	items,
	value,
	withFocusRef,
	children,
	transferRelease,
	errorsFromInput,
	onChange,
	selectedTerritories,
	onSelectTerritory,
	exclusiveTerritories,
	onExcluseTerritory,
	...props
}) => {
	const { territories } = useContext(RootContext);

	const [selected, setSelected] = useState(value);
	// eslint-disable-next-line no-unused-vars
	const [errors, setErrors] = useState({});

	const getChecked = (item) => {
		if (typeof onChange === 'function') {
			return value === item.value;
		} else {
			return selected === item.value;
		}
	};

	const handleChange = (item) => (e) => {
		if (errorsFromInput) {
			setErrors({});
			errorsFromInput({});
		}
		if (typeof onChange === 'function') {
			onChange(e);
			setSelected(item.value);
		} else {
			setSelected(item.value);
		}
	};

	const classes = classNames(
		className,
		styles['RadioGroup'],
		disabled && 'disabled',
		horizontal && 'horizontal'
	);

	useEffect(() => {
		if (value) setSelected(value);
	}, [value, selected]);

	return (
		<div className={classes}>
			{items.map((item) => (
				<div className={styles.radioItem} key={item.id || item.value}>
					<RadioButton
						{...props}
						name={name}
						key={item.id || item.value}
						text={item.text}
						value={item.value}
						disabled={item.hasOwnProperty('disabled') ? item.disabled : false}
						onChange={handleChange(item)}
						checked={getChecked(item)}
						className={styles.radioBtnItem}
						itemChildren={item.children}
					/>

					{item.value === 'selected-territories' &&
						selected === 'selected-territories' && (
							<FormattedMessage
								id={'rod.release.cmo.settings.service_territories.selected'}
							>
								{(placeholder) => (
									<MultipleSelectDropdown
										className={styles.dropdown}
										isCountriesData
										placeholder={placeholder}
										items={[...territories]}
										selected={selectedTerritories}
										onChange={onSelectTerritory}
									/>
								)}
							</FormattedMessage>
						)}
					{item.value === 'exclusive-territories' &&
						selected === 'exclusive-territories' && (
							<FormattedMessage
								id={'rod.release.cmo.settings.service_territories.except'}
							>
								{(placeholder) => (
									<MultipleSelectDropdown
										className={styles.dropdown}
										isCountriesData
										placeholder={placeholder}
										items={[...territories]}
										selected={exclusiveTerritories}
										onChange={onExcluseTerritory}
									/>
								)}
							</FormattedMessage>
						)}
				</div>
			))}
			{children}
		</div>
	);
};

RadioTerritoriesGroup.defaultProps = {
	name: 'radio_test',
};

RadioTerritoriesGroup.displayName = 'RadioTerritoriesGroup';

export default compose()(RadioTerritoriesGroup);
