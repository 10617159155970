export function compareMaps(map1, map2) {
  let testVal;
  if (map1.size !== map2.size) {
      return false;
  }
  for (let [key, val] of map1) {
      testVal = map2.get(key);
      if (testVal !== val || (testVal === undefined && !map2.has(key))) {
          return false;
      }
  }
  return true;
}
