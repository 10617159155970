export const getRightTypesCodes = (data) => {
	let result = [];

	for (let i of data) {
		if (i.right_types) {
			result.push(...i.right_types);
		}
	}

	result = result.sort((a, b) => a.id - b.id);
	return result;
};
