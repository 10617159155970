// Core
import { FormattedMessage } from 'react-intl';

export const checkUploadCount = (rule, releaseType, errorsValue) => {
	switch (rule) {
		case 'upload_only_one_track':
			return (
				<FormattedMessage
					id={'rod.release_create.error.upload_only_one_track'}
					values={{ type: releaseType }}
				/>
			);

		case 'max_upload_count':
			return (
				<FormattedMessage id={'rod.release_create.error.max_upload_count'} />
			);

		case 'universal_error':
			return (
				<FormattedMessage
					id={'rod.release_create.error.upload_universal_error'}
					values={{ name: errorsValue }}
				/>
			);
		case 'max_size_error':
			return (
				<FormattedMessage
					id={'rod.release_create.error.upload_max_size_error'}
					values={{ name: errorsValue }}
				/>
			);
		case 'file_type_error':
			return (
				<FormattedMessage
					id={'rod.release_create.error.upload_file_type_error'}
				/>
			);

		default:
			break;
	}
};
