/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

import React from 'react';
import getDisplayName from 'recompose/getDisplayName';
import { UIConsumer } from '../contexts/UIContext';

export const withUI = WrappedComponent => {

	const UIComponent = ((props) => (
		<UIConsumer>
			{state => (
				<WrappedComponent UIContext={state} {...props} />
			)}
		</UIConsumer>
	));

	const ForwardRef = React.forwardRef((props, ref) => (
		<UIComponent {...props} withFocusRef={ref}/>
	));
	ForwardRef.displayName = getDisplayName(WrappedComponent);
	ForwardRef.name = ForwardRef.displayName;
	ForwardRef.defaultProps = WrappedComponent.defaultProps;

	return ForwardRef;
};
