/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 08/06/2020, 17:46
 */

import classNames from 'classnames';
import React, { Component } from 'react';
import styles from './Bullet.module.css';

class Bullet extends Component {
	render() {
		const { children, text, color, outline, className, ...props } = this.props;
		const classes = classNames(
			className,
			color,
			styles['Bullet'],
			outline && 'outline',
		);
		return (
			<div {...props} className={classes}>
				{/*<svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
				{/*    <path d="M15 9L-8.15666e-07 17.6603L-5.85621e-08 0.339745L15 9Z" fill={'#FAFAFA'}/>*/}
				{/*</svg>*/}
				<div className={styles.Rect}/>
				<span>{children || text || ''}</span>
			</div>
		);
	}
}

export default Bullet;
