// Core
import { useContext } from 'react';
import { FormattedHTMLMessage } from 'react-intl';

// Context
import { UIContext } from 'contexts/UIContext';

// Styles
import styles from './TitleButton.module.css';

const TitleButton = () => {
	const { showModal } = useContext(UIContext);

	return (
		<button
			onClick={showModal({
				text: <FormattedHTMLMessage id={'rod.contracts.sign.help'} />,
			})}
			className={styles.help_btn}
		>
			<svg
				width="25"
				height="25"
				viewBox="0 0 25 25"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle cx="12.5" cy="12.5" r="12" stroke="var(--color-grey)" />
				<path
					d="M11.6076 14.8525C11.6076 14.3798 11.7043 13.9394 11.8975 13.5311C12.0907 13.1229 12.3246 12.7738 12.5992 12.4837C12.8738 12.1937 13.1484 11.9197 13.423 11.6619C13.6976 11.3933 13.9316 11.1033 14.1248 10.7917C14.318 10.4695 14.4146 10.1311 14.4146 9.77654C14.4146 9.2394 14.2367 8.83118 13.8807 8.55186C13.5349 8.26181 13.0823 8.11678 12.523 8.11678C11.4042 8.11678 10.6872 8.63781 10.3719 9.67986L9.16675 8.97083C9.42101 8.21884 9.85325 7.64947 10.4635 7.26273C11.0737 6.86525 11.7653 6.6665 12.5382 6.6665C13.4434 6.6665 14.2163 6.93507 14.8571 7.47221C15.508 8.00935 15.8334 8.75598 15.8334 9.71208C15.8334 10.1633 15.7368 10.5876 15.5436 10.9851C15.3503 11.3826 15.1164 11.7264 14.8418 12.0164C14.5672 12.3065 14.2926 12.5912 14.018 12.8705C13.7434 13.139 13.5095 13.4452 13.3163 13.789C13.123 14.122 13.0264 14.4765 13.0264 14.8525H11.6076ZM12.3094 18.3332C12.0348 18.3332 11.8009 18.2311 11.6076 18.027C11.4144 17.8229 11.3178 17.5758 11.3178 17.2857C11.3178 16.9957 11.4144 16.7486 11.6076 16.5445C11.8009 16.3404 12.0348 16.2383 12.3094 16.2383C12.5942 16.2383 12.8281 16.3404 13.0111 16.5445C13.2044 16.7486 13.301 16.9957 13.301 17.2857C13.301 17.5758 13.2044 17.8229 13.0111 18.027C12.8281 18.2311 12.5942 18.3332 12.3094 18.3332Z"
					fill="var(--color-grey)"
				/>
			</svg>
		</button>
	);
};

export default TitleButton;
