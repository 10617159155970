// Core
import React, { Component } from 'react';
import { PoseGroup } from 'react-pose';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { compose } from 'recompose';
import { withLang, withRoot, withUI } from 'hocs';

// Utils
import { Modal, Shade } from './utils/posed';

// UI
import { Label } from 'components/Labels/Label';
import { Input } from 'components/Inputs/Input';
import { CloseButton } from 'components/Buttons';
import { Button } from 'components/Buttons';
import { Col, Container, Row } from 'components/Grid';
import BaseModal from './BaseModal';
import ErrorsBlock from 'components/MultipleUploader/ErrorsBlock/ErrorsBlock';

// Styles
import styles from './Modal.module.css';

class InvoiceNumberModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			invoiceId: this.props.invoiceId || '',
			isVisible: false,
			isActive: true,
			errors: {},
			buttonDisabled: false,
		};
	}

	componentDidMount() {
		this.setState({
			isVisible: true,
			onCloseFn: this.props.onClose,
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {}

	willUnmount(e) {
		this.setState({
			isVisible: false,
			errors: {},
		});
		setTimeout(() => {
			this.state.onCloseFn();
		}, 300);
	}

	handleChangeId = (value) => {
		this.setState({
			buttonDisabled: false,
		});

		const onlyNumbers = /^[0-9]+$/;

		if (!onlyNumbers.test(value)) {
			this.setState({
				errors: { number_value: true },
				buttonDisabled: true,
			});
		}

		if (value.length < 6) {
			this.setState({
				errors: { min_value: 6 },
				buttonDisabled: true,
			});
		}

		if (value.length > 50) {
			this.setState({
				errors: { max_value: 50 },
				buttonDisabled: true,
			});
		}

		if (value.length >= 6 && value.length <= 50 && onlyNumbers.test(value)) {
			this.setState({
				errors: {},
				buttonDisabled: false,
			});
		}

		this.setState({
			invoiceId: value ? value : '',
		});
	};

	handleConfirm = async () => {
		this.setState({
			buttonDisabled: true,
		});

		const accountId = localStorage.getItem('accountId');

		try {
			const data = await this.props.createInvoice(
				accountId,
				this.props.signType,
				this.state.invoiceId,
				this.props.fd
			);

			this.setState({
				buttonDisabled: false,
			});

			if (data) {
				this.props.history.push(
					`/accounts/${accountId}/reports/sign?id=${data.id}`
				);
				this.setState({
					isVisible: false,
					errors: {},
				});
			}
		} catch (error) {
			const { response } = error;

			if (response && response.data && response.data.errors) {
				const { errors } = response.data;

				if ('id' in errors && errors.id[0].rule === 'unique_value') {
					this.setState({
						errors: { unique_value: 'required' },
					});
				} else {
					this.setState({
						errors: errors,
					});
				}
			}
		} finally {
			this.setState({
				buttonDisabled: false,
			});
		}
	};

	render() {
		return (
			<BaseModal className={styles.Modal}>
				<PoseGroup>
					{this.state.isVisible && [
						<Shade key="shade" className={styles.Overlay} />,
						<Modal
							key="modal"
							style={{
								marginLeft: 'auto',
							}}
						>
							<Container fluid>
								<Row>
									<Col
										md={12 - 4}
										onClick={(e) => {
											this.willUnmount(e);
										}}
									/>
									<Col md={4} className={styles.notify}>
										<div className={styles.notify__wrapper}>
											<div className={styles.rubric_modal_wrapper}>
												<div className={styles.notify__title}>
													<ul className={styles.Tray}>
														<li>
															<div className={styles.readAdminModalTitle}></div>
														</li>
													</ul>
												</div>
												<CloseButton
													black
													small
													className={styles.CloseButtonNotify}
													onClick={(e) => {
														this.willUnmount(e);
													}}
												/>
											</div>
											<Label font="--gilroy-Medium-32" black>
												<FormattedMessage
													id={`rod.reports_page.withdraw.invoice_number`}
												/>
											</Label>
											<span className={styles.invoiceInputTitle}>
												<FormattedHTMLMessage
													id={'rod.reports_page.withdraw.invoice_number'}
												/>
											</span>
											<div>
												<ul>
													<li className={styles.rubricAdminModalListItem}>
														<div className={styles.numberInput}>
															<FormattedMessage
																id={
																	'rod.reports_page.withdraw.invoice_number.placeholder'
																}
															>
																{(placeholder) => (
																	<Input
																		errors={{}}
																		onChange={this.handleChangeId}
																		value={this.state.invoiceId}
																		placeholder={placeholder}
																		required
																	/>
																)}
															</FormattedMessage>

															{Object.keys(this.state.errors).includes(
																'min_value'
															) && (
																<span className={styles.Helper}>
																	{
																		<FormattedMessage
																			id={'rod.error.min_value'}
																		/>
																	}
																</span>
															)}
															{Object.keys(this.state.errors).includes(
																'max_value'
															) && (
																<span className={styles.Helper}>
																	{
																		<FormattedMessage
																			id={'rod.error.max_value'}
																		/>
																	}
																</span>
															)}
															{Object.keys(this.state.errors).includes(
																'unique_value'
															) && (
																<span className={styles.Helper}>
																	{
																		<FormattedMessage
																			id={'rod.error.unique.invoice_id.value'}
																		/>
																	}
																</span>
															)}
															{Object.keys(this.state.errors).includes(
																'number_value'
															) && (
																<span className={styles.Helper}>
																	{
																		<FormattedMessage
																			id={'rod.error.number_value'}
																		/>
																	}
																</span>
															)}
														</div>
													</li>
												</ul>
											</div>
											<span className={styles.invoiceModalText}>
												<FormattedHTMLMessage
													id={
														'rod.reports_page.withdraw.invoice_number.modal_text'
													}
												/>
											</span>
											<div className={styles.btnConfirmRubric}>
												<div className={styles.errors}>
													<ErrorsBlock errors={this.state.errors} />
												</div>
												<Button
													disabled={this.state.buttonDisabled}
													text={
														<FormattedHTMLMessage
															id={'rod.reports_page.withdraw'}
														/>
													}
													className={
														this.state.isActive
															? styles.activeBtn
															: styles.inactiveBtn
													}
													variant={'primary'}
													onClick={this.handleConfirm}
												/>
											</div>
										</div>
									</Col>
								</Row>
							</Container>
						</Modal>,
					]}
				</PoseGroup>
			</BaseModal>
		);
	}
}

export default compose(
	withLang,
	withUI,
	withRoot,
	withRouter
)(InvoiceNumberModal);
