import classNames from 'classnames';
// import { useEffect, useState } from 'react';
import { withLang } from 'hocs';
import React from 'react';
import { compose } from 'recompose';
import styles from './FaqAdminLang.module.css';

const FaqAdminLang = (props) => {
	const { className, lang } = props;
	const classes = classNames(className, styles['Lang']);

	return (
		<div className={classes}>
			<ul className={styles.List}>
				<li className="current-lang">{lang}</li>
				<li className="another-lang" /*  onClick={changeLang} */>
					{lang === 'en' ? 'ru' : 'en'}
				</li>
			</ul>
		</div>
	);
};

export default compose(withLang)(FaqAdminLang);
