// Core
import moment from 'moment';

export const getEndTime = (releaseType, start) => {
	let endTime;

	switch (releaseType) {
		case 43:
			endTime = moment(start, 'mm:ss:SSS')
				.add(29.99, 'seconds')
				.format('mm:ss:SSS');
			break;

		case 70:
			endTime = moment(start, 'mm:ss:SSS')
				.add(60, 'seconds')
				.format('mm:ss:SSS');
			break;

		default:
			break;
	}

	return endTime.toString();
};
