/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 13/11/2020, 10:03
 */

import classNames from 'classnames';
import React, { Component } from 'react';
import styles from './CloseButton.module.css';

class CloseButton extends Component {
	render() {
		const { children, text, white, className, black, small, ...props } = this.props;
		const classes = classNames(
			className,
			white && 'white',
			black && 'black',
			styles.CloseButton,
		);
		return (
			<button {...props} className={classes}>
				<svg width={small ? 16 : 24}
						 height={small ? 16 : 24}
						 viewBox="0 0 24 24"
						 fill="none"
						 xmlns="http://www.w3.org/2000/svg"
				>
					<path fillRule="evenodd" clipRule="evenodd"
								d="M1.5 0L0 1.5L10.5 12L8.20796e-05 22.5L1.50008 24L12 13.5L22.5 24L24 22.5L13.5 12L24.0001 1.50001L22.5001 7.28297e-06L12 10.5L1.5 0Z"
								fill="#FFFFFF"
					/>
				</svg>

			</button>
		);
	}
}

export default CloseButton;
