// Core
import React, { useState, useEffect, useContext } from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import { FormattedHTMLMessage } from 'react-intl';

// Context
import { LangContext } from 'contexts/LangContext';
import { withAuth, withRoot } from 'hocs';
import { UploadPhonogramModal } from 'material-design/modals';
import { compose } from 'recompose';
import s from './RepertoireCompositionReleases.module.css';
import { Loading, NoData } from 'components';
import eye from 'images/eye_black.svg';
import nota2 from 'images/nota-2.svg';
import icon_cd from 'images/icon_cd.svg';

function RepertoireCompositionReleases(props) {
	const { lang } = useContext(LangContext);
	const match = useRouteMatch();
	const compositionId = match.params.id;
	const [modal, setModal] = useState(false);
	const [loading, setLoading] = useState(true);
	const [releases, setReleases] = useState([]);
	const [isInitialLoadingDone, setIsInitialLoadingDone] = useState(false);
	const [releaseTypes, setReleaseTypes] = useState([]);
	const {
		authContext: { accountId },
		rootContext: { getCompositionReleases, getReleaseTypesAll },
	} = props;
	useEffect(() => {
		const p0 = getCompositionReleases(accountId, compositionId);
		const p1 = getReleaseTypesAll(lang);

		Promise.all([p0, p1])
			.then((values) => {
				setReleases(values[0]);
				setReleaseTypes(values[1]);
				setLoading(false);
				setIsInitialLoadingDone(true);
			})
			.catch((error) => {
				console.info('Error', error);
				setLoading(false);
			});
	}, []);

	useEffect(() => {
		setLoading(true);
		if (isInitialLoadingDone) {
			getReleaseTypesAll(lang)
				.then((res) => {
					setReleaseTypes(res);
					setLoading(false);
				})
				.catch((error) => {
					console.info('Error', error);
					setLoading(false);
				});
		}
	}, lang);

	function modalOpen(active) {
		setModal(active);
	}

	const getTypeTitleById = (id) => {
		return releaseTypes.find((type) => type.id === id).title;
	};
	return loading ? (
		<Loading />
	) : (
		<>
			{releases && releases.length > 0 ? (
				<>
					<div className={s.songPhonogram__body}>
						<div className={s.songPhonogramBody__tableHead}>
							<FormattedHTMLMessage id={'rod.for_all.title'} />
							<FormattedHTMLMessage id={'rod.for_all.performers'} />
							<FormattedHTMLMessage id={'rod.composition.recordery.category'} />
							<FormattedHTMLMessage id={'rod.composition.recordery.date'} />
							<FormattedHTMLMessage id={'UPC'} />
						</div>
						<ul className={s.songPhonogramBody__tableBody}>
							{releases.map((i) => (
								<li key={i.id}>
									<Link
										key={i.id}
										to={`/repertoire/releases/${i.id}/info`}
										className={`${s.song__tableItem} ${s.song__table} ${s.song__link}`}
									>
										<div className={s.song__tableName} title={i.title}>
											<img src={nota2} alt="" />
											<span>{i.title}</span>
										</div>
										<div
											className={s.song__tableExecutor}
											title={i.performers.join(',')}
										>
											<span>{i.performers.join(',')}</span>
										</div>
										<div className={s.song__tableCategory}>
											<span>{getTypeTitleById(i.release_type_id)}</span>
										</div>
										<div className={s.song__tableDate}>
											<span>{i.date}</span>
										</div>
										<div className={s.song__tableUPC}>
											<span>{i.UPC}</span>
										</div>
									</Link>
								</li>
							))}
						</ul>
					</div>
				</>
			) : (
				<div>
					<NoData />
				</div>
				// <div className={s.songPhonogram__empty}>
				// 	<img src={icon_cd} width={64} height={64} alt="" />
				// 	<span>
				// 		Просим{' '}
				// 		<a className={s.songPhonogram__emptyButton} onClick={modalOpen}>
				// 			загрузить
				// 		</a>{' '}
				// 		или{' '}
				// 		<a className={s.songPhonogram__emptyButton} onClick={modalOpen}>
				// 			{' '}
				// 			добавить{' '}
				// 		</a>{' '}
				// 		фонограмму
				// 	</span>
				// </div>
			)}

			<UploadPhonogramModal
				active={modal}
				setActive={modalOpen}
			></UploadPhonogramModal>
		</>
	);
}
export default compose(withAuth, withRoot)(RepertoireCompositionReleases);
