import { useContext, useEffect } from 'react';

// Context
import { UIContext } from 'contexts/UIContext';

// Icons
import stop_button from 'images/stop_button.svg';
import play_button from 'images/play_button.svg';

// Styles
import styles from './PlayActive.module.css';

const PlayActive = ({ item, index }) => {
	const { audioId, play, handlePlay, handlePlayStop } = useContext(UIContext);

	return (
		<div className={styles.playBtn}>
			<img
				data-tag={'play'}
				src={audioId === item.id && play ? stop_button : play_button}
				className={styles.repertoire__playLogo}
				onClick={
					audioId === item.id && play
						? handlePlayStop
						: () => {
								handlePlay(
									item.id,
									item.audio.data,
									item.title,
									item.performers
								);
						  }
				}
				alt="play"
			/>
		</div>
	);
};

export default PlayActive;
