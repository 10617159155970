// Core
import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

// UI
import { FaqArticle } from './FaqArticle';
import { FaqGeneral } from './FaqGeneral';
import { FaqSearch } from './FaqSearch';
import { FaqSubSection } from './FaqSubSection';

export default function Faq(props) {
	let { path } = useRouteMatch();

	useEffect(() => {
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Switch>
			<Route
				exact
				path={`${path}/section/:sectionId/article/:articleId`}
				render={({ match }) => {
					return (
						<FaqArticle
							sectionId={match.params.sectionId}
							articleId={match.params.articleId}
						/>
					);
				}}
			/>
			<Route
				exact
				path={`${path}/section/:sectionId`}
				render={({ match }) => {
					return <FaqSubSection sectionId={match.params.sectionId} />;
				}}
			/>
			<Route
				exact
				path={`${path}/search`}
				render={() => {
					return <FaqSearch />;
				}}
			/>
			<Route exact path={path}>
				<FaqGeneral />
			</Route>
		</Switch>
	);
}
