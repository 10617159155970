/* eslint-disable react-hooks/exhaustive-deps */
// Core
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams, useLocation } from 'react-router-dom';
import moment from 'moment';

// Sevices
import { accounts } from 'services';
import { transliterate as tr } from 'transliteration';
import cloneDeep from 'lodash/cloneDeep';

// Context
import { UIContext } from 'contexts/UIContext';
import { RootContext } from 'contexts/RootContext';

// UI
import { Loading } from 'components';
import { RadioGroup } from 'components/Radio';
import { CheckBoxOutlets } from 'material-design/components';
import modalTypes from 'constants/modalTypes';
import { EndConfirmationPage } from 'components/EndConfirmationPage';

// Styles
import s from './RepertoireReleaseShipment.module.css';

const RepertoireReleaseShipment = (props) => {
	// eslint-disable-next-line no-unused-vars
	const [personalData, setPersonalData] = useState('7-');
	const [personalData2, setPersonalData2] = useState('all');
	const [personal, setPersonal] = useState(
		props.data || {
			distribution_date: '7-',
			outlets: 'All outlets available',
		}
	);
	// eslint-disable-next-line no-unused-vars
	const [inputDate, setInputDate] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [isDateEmpty, setIsDateEmpty] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [errors, setErrors] = useState({});
	const [shipments, setShipments] = useState([]);
	const [allOutlets, setAllOutlets] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [showCheckbox, setShowCheckbox] = useState(false);
	const [checks, setChecks] = useState([]);
	const [allChecked, setAllChecked] = useState(true);
	const [checked, setChecked] = useState([]);
	const [notEarlyUsedOutlets, setNotEarlyUsedOutlets] = useState([]);
	const [isSubmitnBtnEnable, setIsSubmitBtnEnable] = useState(
		personalData2 === 'inc' ? false : true
	);
	const [isLastPage, setIsLastPage] = useState(false);

	const {
		showBackNavi,
		hideBackNavi,
		showTitle,
		hideTitle,
		showModal,
	} = useContext(UIContext);
	const { getReleaseShipments, postReleaseUpdatedShipments } = useContext(
		RootContext
	);
	const { id: releaseId } = useParams();
	const location = useLocation();
	const typeId = location?.state?.typeId;
	const shipmentsArr = location?.state?.shipmentsArr;

	const accountId = localStorage.getItem('accountId');
	const lang = localStorage.getItem('lang');

	useEffect(() => {
		showTitle('rod.release.release_shipment');
		showBackNavi(
			`/repertoire/releases/${releaseId}/info`,
			null,
			'rod.action.back_to_release'
		);

		setIsLoading(true);

		if (typeId) {
			getAllOutlets(lang, typeId);
		} else {
			accounts
				.getReleaseInfoForBrowsing(accountId, releaseId)
				.then((res) => {
					res = res.data.data;
					getAllOutlets(lang, res.release_type_id);
				})
				.catch((error) => {
					console.error('Error', error);
				});
		}

		if (shipmentsArr && shipmentsArr.length) {
			const e = {};

			e.currentTarget = { value: getType('asap') };
			whoAreYouChangeHandler(e);
			setChecks(shipmentsArr);
			setShipments(shipmentsArr);
		} else {
			getReleaseShipments(accountId, releaseId)
				.then((res) => {
					const e = {};
					e.currentTarget = { value: getType('asap') };
					whoAreYouChangeHandler(e);
					setChecks(res);
					setShipments(res);
				})
				.catch((err) => {
					console.info('Error:', err);
				});
		}

		return function cleanup() {
			hideBackNavi();
			hideTitle();
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (checks.length) {
			const data = checks.filter((item) => item.checked);

			if (data.length) {
				setIsSubmitBtnEnable(true);
			} else {
				setIsSubmitBtnEnable(false);
			}
		}
	}, [checked]);

	useEffect(() => {
		if (typeId) {
			getAllOutlets(lang, typeId);
		} else {
			accounts
				.getReleaseInfoForBrowsing(accountId, releaseId)
				.then((res) => {
					res = res.data.data;
					getAllOutlets(lang, res.release_type_id);
				})
				.catch((error) => {
					console.info('Error', error);
				});
		}
	}, [lang]);

	const getAllOutlets = (language, id) => {
		accounts
			.getOutletsWithTypes(language, id)
			.then((outlets) => {
				outlets = outlets.data.data;

				const e = {};
				e.currentTarget = { value: getType('asap') };
				whoAreYouChangeHandler(e);
				setAllOutlets(outlets);
			})
			.catch((error) => {
				console.error('Error', error);
			});
	};

	useEffect(() => {
		const notUsed = [];
		const shipmentsTitles = [];
		if (shipments.length && allOutlets.length) {
			shipments.map((item) => {
				if (item.is_aggregator) {
					shipmentsTitles.push(item.id);
					shipmentsTitles.push(item.aggregator.id);
				} else {
					shipmentsTitles.push(item.id);
				}
			});

			const data = allOutlets.filter((item) => {
				if (shipmentsTitles.includes(item.id)) {
					const outlet = shipments.find((outlet) => outlet.id === item.id);
					if (outlet.status === 'shipping') {
						item.isLocked = true;
					} else {
						item.isLocked = false;
						notUsed.push(item);
						setNotEarlyUsedOutlets(notUsed);
					}

					return item;
				}
			});

			const dataIds = data.map((item) => item.id);
			const otherOutlets = shipments.filter(
				(item) => !dataIds.includes(item.id)
			);

			let otherOutletsIncludeTranslate = [];

			if (otherOutlets.length) {
				const otherOutletsRu = cloneDeep(otherOutlets);

				const otherOutletsEn = cloneDeep(otherOutlets).map((item) => {
					item.title = tr(item.title);
					return item;
				});

				if (lang === 'en') {
					otherOutletsIncludeTranslate = otherOutletsEn.map((item) => {
						if (item.status === 'shipping') {
							item.isLocked = true;
						} else {
							item.isLocked = false;
							notUsed.push(item);
							setNotEarlyUsedOutlets(notUsed);
						}
						return item;
					});
				} else {
					otherOutletsIncludeTranslate = otherOutletsRu.map((item) => {
						if (item.status === 'shipping') {
							item.isLocked = true;
						} else {
							item.isLocked = false;
							notUsed.push(item);
							setNotEarlyUsedOutlets(notUsed);
						}
						return item;
					});
				}
			}

			const result = [...data, ...otherOutletsIncludeTranslate];

			setChecks(result);
			// setChecks(data);
			setIsLoading(false);
		}
	}, [shipments, allOutlets, allChecked]);

	const radio2 = [
		{
			text: (
				<FormattedMessage
					id={'rod.release.create.step.distribution.input_radio3'}
				/>
			),
			value: 'all',
			disabled: false,
		},
		{
			text: (
				<FormattedMessage
					id={'rod.release.create.step.distribution.input_radio4'}
				/>
			),
			value: 'inc',
			disabled: false,
		},
	];

	const getType = (type) => {
		if (type === 'regular') return '7+';
		if (type === 'asap') return '7-';
	};

	const whoAreYouChangeHandler = (e) => {
		if (e.currentTarget.value === '7+') {
			setPersonal((prev) => ({
				...prev,
				sale_start_date: correctDate(Date.now() + 604800000),
				type: 'regular',
			}));
			setInputDate(true);
		} else if (e.currentTarget.value === '7-') {
			setInputDate(false);
			setPersonal((prev) => ({
				...prev,
				sale_start_date: correctDate(Date.now() + 172800000),
				type: 'asap',
			}));
		}
		setPersonalData(e.currentTarget.value);
	};

	const correctDate = (date) => {
		if ([10, 19].includes(date.length)) return date;
		const currDate = isNaN(date) ? date : new Date(date);
		const currMonth = currDate.getMonth() + 1;
		const currYear = currDate.getFullYear();
		let currDay = moment(currDate).format('MMM Do YY').split(' ')[1];
		currDay = isNaN(currDay.slice(0, 2))
			? currDay.slice(0, 1)
			: currDay.slice(0, 2);
		let result =
			currYear +
			'-' +
			currMonth.toString().padStart(2, '0') +
			'-' +
			currDay.padStart(2, '0');
		return result;
	};

	const getCheckedOutlets = (item) => {
		switch (item.checked) {
			case true:
				const youtubeOutlet = checks.find((outlet) => outlet.id === 21859);
				if (item.id === 436356 && !youtubeOutlet.checked) {
					youtubeOutlet['checked'] = true;
					setChecked((prev) => [...prev, item, youtubeOutlet]);
				} else setChecked((prev) => [...prev, item]);

				break;
			case false:
				const youtubeIdOutlet = checks.find((outlet) => outlet.id === 436356);
				if (item.id === 21859 && youtubeIdOutlet?.checked) {
					youtubeIdOutlet['checked'] = false;
					const data = checked.filter(
						(i) => i.id !== item.id && i.id !== 436356
					);
					setChecked(data);
				} else {
					const data = checked.filter((i) => i.id !== item.id);
					setChecked(data);
				}
				break;
			default:
				break;
		}
	};

	const whoAreYouChangeHandler2 = (e) => {
		setPersonalData2(e.currentTarget.value);

		if (e.currentTarget.value === 'all') {
			setAllChecked(true);
			setIsSubmitBtnEnable(true);
		} else {
			setAllChecked(false);
			if (!checked.length) setIsSubmitBtnEnable(false);
		}

		const checkedId = checked.map((item) => item.id);
		const allOutletsId = checks.map((item) => item.id);
		personal.outlets =
			e.currentTarget.value === 'all' ? allOutletsId : checkedId;

		e.currentTarget.value === 'all'
			? setShowCheckbox(false)
			: setShowCheckbox(true);
	};

	const submitShipments = () => {
		if (allChecked) {
			const data = notEarlyUsedOutlets.map((item) => item.id);

			postReleaseUpdatedShipments(accountId, releaseId, {
				outlets: data,
			})
				.then((res) => {
					setIsLastPage(true);
				})
				.catch((err) => {
					console.info('Error:', err);
				});
		} else {
			const data = checks.filter((item) => item.checked).map((item) => item.id);

			if (!data.length) return;

			postReleaseUpdatedShipments(accountId, releaseId, { outlets: data })
				.then((res) => {
					setIsLastPage(true);
				})
				.catch((err) => {
					console.info('Error:', err);
				});
		}
	};

	return (
		<div className={s.page}>
			{!isLastPage && (
				<div className={s.main}>
					<div className={s.body}>
						<div className={s.Input__radio}>
							<div className={s.Input__title}>
								<FormattedMessage
									id={'rod.release.create.step.distribution.input_title'}
								/>
							</div>

							<RadioGroup
								className={s.RadioGroup}
								name={'outlets'}
								value={personalData2}
								onChange={whoAreYouChangeHandler2}
								items={radio2}
							/>
							{isLoading ? (
								<Loading className={s.loader} />
							) : (
								<>
									<div className={s.Outlets}>
										<div className={s.table}>
											{checks.map((item, index) => (
												<CheckBoxOutlets
													name={'check_box_outlets'}
													key={index}
													checks={checks}
													onChange={setChecks}
													item={item}
													allChecked={allChecked}
													index={index}
													getCheckedOutlets={getCheckedOutlets}
													isLocked={item.isLocked ? true : false}
												/>
											))}
										</div>
									</div>
								</>
								// )
							)}
							<div className={s.button__wrapper}>
								<div className={s.input}>
									<button
										className={isSubmitnBtnEnable ? s.next : s.submitDisabled}
										// onClick={submitShipments}
										onClick={
											isSubmitnBtnEnable &&
											showModal(
												{
													title: (
														<FormattedMessage
															id={'rod.release.confirmation-title'}
														/>
													),
													text: (
														<FormattedMessage
															id={'rod.release.shipment.confirmation-text'}
														/>
													),

													onAction: () => {
														submitShipments();
													},
													confirmBtnTxt: (
														<FormattedMessage id={'rod.performance_work.yes'} />
													),
													declineBtnTxt: (
														<FormattedMessage id={'rod.modal.cancel'} />
													),
												},
												modalTypes.FULL_MODAL
											)
										}
									>
										<FormattedMessage id={'rod.release.release_shipment_btn'} />
									</button>
									{Object.keys(errors).includes('check_box_outlets') && (
										<span className={s.HelperBtn}>
											{
												<FormattedMessage
													id={`rod.error.${errors.check_box_outlets[0].rule}`}
												/>
											}
										</span>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			)}

			{isLastPage && (
				<EndConfirmationPage
					title={'rod.release.shipment.end.title'}
					text={'rod.release.shipment.end.text'}
					textLinks={{
						one: {
							link: `/repertoire/releases/${releaseId}/deliveries`,
							phrase: 'rod.link_word.on_page',
						},
						two: { link: '/faq', phrase: 'rod.link_word.faq' },
					}}
					btnText={'rod.action.to_index_page'}
					btnLink={`/`}
				/>
			)}
		</div>
	);
};

export default RepertoireReleaseShipment;
