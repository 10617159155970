const BROMA_COM_URLS = {
	BROMA_COM_PROD: '/rod.broma16.com',
	BROMA_COM_STAGE_B: '/b.techrod.pro',
	BROMA_COM_STAGE_C: '/c.techrod.pro',
};

export const isForCom =
	window.location.href.includes(BROMA_COM_URLS.BROMA_COM_PROD) ||
	window.location.href.includes(BROMA_COM_URLS.BROMA_COM_STAGE_C) ||
	window.location.href.includes(BROMA_COM_URLS.BROMA_COM_STAGE_B);

export default BROMA_COM_URLS;
