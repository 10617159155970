// Utils
import { fm } from './fm';

// Constants
import accountCompletionSteps from 'constants/accountCompletionSteps';

export const statusLocalization = (status) => {
	switch (status) {
		case accountCompletionSteps.PHONE_CONFIRM:
			return fm('rod.field.admin.phone');
		case accountCompletionSteps.EMAIL_CONFIRM:
			return fm('rod.field.admin.email');
		case accountCompletionSteps.ACCOUNT_CREATED:
			return fm('rod.field.admin.acc-created');
		case accountCompletionSteps.BEGIN:
			return fm('rod.release.create.step.tracks.fragment.start');
		case accountCompletionSteps.ROLE:
			return fm('rod.field.admin.role-selection');
		case accountCompletionSteps.PERSONAL_DATA:
			return fm('rod.account.personal.title');
		case accountCompletionSteps.CONTACT_DATA:
			return fm('rod.admin.account.contact_information');
		case accountCompletionSteps.DOCUMENTS:
			return fm('rod.field.admin.documents');
		case accountCompletionSteps.IDENTITY:
			return fm('rod.field.admin.identifiers');
		case accountCompletionSteps.MONEY:
			return fm('rod.field.admin.payment-details');
		case accountCompletionSteps.PENDING:
			return fm('rod.admin.pending_documents');
		case accountCompletionSteps.PENDING_CONTRACT:
			return fm('rod.admin.pending_contracts');
		case accountCompletionSteps.APPROVED:
			return fm('rod.admin.approved_documents');
		case accountCompletionSteps.DECLINED:
			return fm('rod.repertoire.rejected');
		case accountCompletionSteps.DECLINED_DOCUMENTS:
			return fm('rod.admin.declined_documents');
		case accountCompletionSteps.DECLINED_CONTRACTS:
			return fm('rod.admin.declined_contracts');
		case accountCompletionSteps.APPROVED_CONTRACTS:
			return fm('rod.admin.approved_contracts');
		case accountCompletionSteps.SIGN_PHYSICAL:
			return fm('rod.field.admin.signing');
		case accountCompletionSteps.CONFIRM:
			return fm('rod.admin.account.status.confirm');
		case accountCompletionSteps.SIGN_SMS:
			return fm('rod.field.admin.signing');
		case accountCompletionSteps.SMS_CONFIRM:
			return fm('rod.admin.account.status.sms_confirm');
		case accountCompletionSteps.COMPLETE:
			return fm('rod.contracts.complete.header');
		case accountCompletionSteps.PERSONAL_AREA:
			return fm('rod.admin.account.status.personal_area');
		case accountCompletionSteps.REPERTOIRE_APPLY:
			return fm('rod.admin.account.status.repertoire_apply');
		case accountCompletionSteps.PERSONAL_AREA_LEGASY:
			return fm('rod.admin.account.status.personal_area_legacy');
		case accountCompletionSteps.LIST:
			return status;
		case 'catalog.upload':
			return fm('rod.admin.account.status.catalog_apply');
		case 'payment.create':
			return fm('rod.admin.account.status.payment.create');
		case 'payment.moderation':
			return fm('rod.admin.account.status.payment.moderation');
		case 'payment.rejected':
			return fm('rod.admin.account.status.payment.rejected');
		case 'payment.in_processing':
			return fm('rod.admin.account.status.payment.in_processing');
		case 'payment.paid':
			return fm('rod.admin.account.status.payment.paid');

		default:
			return status;
	}
};
