import React from 'react';
import {
	FormControlLabelSwitch,
	SwitchCustom,
} from 'material-design/MuiStyled/MuiStyled';

const MuiSwitch = ({ label, onChange, checked }) => {
	return (
		<FormControlLabelSwitch
			control={
				<SwitchCustom
					checked={checked}
					onChange={onChange}
					inputProps={{ 'aria-label': 'controlled' }}
				/>
			}
			label={label}
		/>
	);
};

export default MuiSwitch;
