export const generateUniqueId = () => {
	// Get the current timestamp
	const timestamp = new Date().getTime();

	// Generate a random number between 10 and 100
	const randomNum = Math.floor(Math.random() * 91 + 10);

	// Concatenate timestamp and random number to create a unique ID
	const uniqueId = timestamp + '' + randomNum;

	return uniqueId;
};
