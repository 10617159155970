import React from 'react';

import {
	TextFieldInput,
	AutocompleteInput,
} from 'material-design/MuiStyled/MuiStyled';
import { makeStyles, createStyles } from '@mui/styles';
import { Popper } from '@mui/material';
import isItemSelected from 'logic/multiselectHighlight.js';

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			'& .MuiAutocomplete-listbox': {
				margin: 0,
				padding: 0,
				'& li': {
					backgroundColor: '#FFF',
					font: 'var(--gilroy-Medium-16-150)',
					padding: '12px 8px',
					'&:hover': {
						backgroundColor: 'var(--color-light-grey) !important',
					},
				},
			},
		},
	})
);

const CustomPopper = function (props) {
	const classes = useStyles();
	return <Popper {...props} className={classes.root} placement="bottom" />;
};

const MuiSelectNarative = ({ onChange, label, items, value, required }) => {
	const lang = localStorage.getItem('lang');

	if (lang === 'ru') {
		items = items.sort((a, b) => (a.title_ru > b.title_ru ? 1 : -1));
	} else {
		items = items.sort((a, b) => (a.title_en > b.title_en ? 1 : -1));
	}

	if (value) {
		for (let j = 0; j < items.length; j++) {
			if (value === items[j].code) {
				value = {
					//id: items[j].id,
					code: items[j].code,
					title_ru: items[j].title_ru,
					title_en: items[j].title_en,
				};
			}
		}
	}

	const filterOptions = (_, state) => {
		const newOptions = [];
		if (state.inputValue) {
			items.forEach((item) => {
				const word = lang === 'ru' ? item.title_ru : item.title_en;
				if (
					word.toLowerCase().includes(state.inputValue.toLowerCase()) &&
					word.toLowerCase().indexOf(state.inputValue.toLowerCase()) === 0
				)
					newOptions.push(item);
			});
			return newOptions;
		} else return items;
	};

	return (
		<AutocompleteInput
			id="disable-clearable"
			disableClearable
			onChange={(_, obj) => onChange(obj)}
			options={items}
			value={value}
			getOptionLabel={(option) => {
				return (lang === 'ru' ? option.title_ru : option.title_en) || '';
			}}
			isOptionEqualToValue={(option, value) =>
				lang === 'ru'
					? option.title_ru === value.title_ru
					: option.title_en === value.title_en
			}
			renderInput={(params) => (
				<TextFieldInput
					{...params}
					label={label}
					variant="standard"
					required={required}
				/>
			)}
			renderOption={(option, item) => (
				<li
					key={item}
					{...option}
					style={{
						backgroundColor: isItemSelected(item, value)
							? 'var(--color-green)'
							: 'var(--color-white)',
						color: isItemSelected(item, value) ? 'var(--color-white)' : 'unset',
					}}
				>
					{lang === 'ru' ? item.title_ru : item.title_en}
				</li>
			)}
			PopperComponent={CustomPopper}
			filterOptions={filterOptions}
		/>
	);
};

export default MuiSelectNarative;
