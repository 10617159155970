/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 02/10/2020, 16:10
 */

import { InfoButton } from 'components/Buttons/InfoButton';
import { Checkbox } from 'components/Checkboxes';
import { Label } from 'components/Labels/Label';
import { Token } from 'components/Token';
import { withUI } from 'hocs';
import editIcon from 'images/edit.svg';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { getHelpMessage } from '../../../../../../../../messages/helper';
import styles from './Condition.module.css';
import checkGreen from 'images/check-green.svg';

class Condition extends Component {
	static propTypes = {
		title: PropTypes.string,
		onEditClick: PropTypes.func,
		onSelect: PropTypes.func,
		included: PropTypes.array.isRequired,
		excluded: PropTypes.array.isRequired,
		checked: PropTypes.bool,
	};

	selectMediaTypeHelpMessage = () => {
		const { conditionType, conditionMediaType } = this.props;
		if (conditionType === 'digital') {
			return conditionMediaType === 'recording'
				? 'condition_digital_recording'
				: 'condition_digital_composition';
		}

		if (conditionType === 'public_performance') {
			return conditionMediaType === 'recording'
				? 'condition_public_performance_recording'
				: 'condition_public_performance_composition';
		}

		if (conditionType === 'synchronization') {
			return conditionMediaType === 'recording'
				? 'condition_synchronization_recording'
				: 'condition_synchronization_composition';
		}
	};

	render() {
		const {
			UIContext: { showModal },
			title,
			onEditClick,
			onSelect,
			checked,
			included,
			excluded,
			hiddenCheckBox,
			isAddService,
		} = this.props;

		const lang = localStorage.getItem('lang');

		const message = DOMPurify.sanitize(
			...getHelpMessage(lang, this.selectMediaTypeHelpMessage())
		);

		const getToken = (territory) => {
			switch (territory.title) {
				case 'Весь Мир':
					return (
						<Token
							title={
								<FormattedMessage
									id={'rod.account.create.license-agreement.whole-world'}
								/>
							}
							style={{
								backgroundColor: 'transparent',
								font: 'var(--gilroy-Medium-18-22)',
								paddingLeft: '0px',
							}}
						/>
					);
				default:
					return (
						<Token
							title={territory.title}
							style={{
								backgroundColor: 'transparent',
								font: 'var(--gilroy-Medium-18-22)',
								paddingLeft: '0px',
							}}
						/>
					);
			}
		};

		return (
			<div
				className={styles.Condition}
				style={{
					opacity: hiddenCheckBox ? '0.6' : '1',
					pointerEvents: hiddenCheckBox ? 'none' : 'all',
				}}
			>
				<div className={styles.Title}>
					<InfoButton
						className={styles.infoButton}
						onClick={showModal({
							text: parse(message),
						})}
					>
						{hiddenCheckBox && isAddService && (
							<img src={checkGreen} alt="" className={styles.checkGreen} />
						)}
						<Checkbox
							text={title}
							className={styles.Checkbox}
							onChange={onSelect}
							checked={checked}
							hiddenCheckBox={hiddenCheckBox ? hiddenCheckBox : false}
						/>
					</InfoButton>
				</div>

				<div className={styles.Fields}>
					<div className="line">
						<Label black font="--gilroy-Medium-18">
							<FormattedMessage id={'rod.field.territories.included'} />:
						</Label>

						<ul>
							{included.map((territory) => getToken(territory))}
							{!hiddenCheckBox && (
								<li>
									<button onClick={onEditClick} className={styles.editBtn}>
										<img src={editIcon} alt="" />
										<FormattedMessage id={'rod.field.edit'} />
									</button>
								</li>
							)}
						</ul>
					</div>

					<div className="line">
						<Label black font="--gilroy-Medium-18">
							<FormattedMessage id={'rod.field.territories.excluded'} />:
						</Label>
						<ul>
							{excluded.map((territory) => (
								<Token
									title={territory.title}
									style={{
										font: 'var(--gilroy-Medium-16)',
									}}
								/>
							))}
						</ul>
					</div>
				</div>
			</div>
		);
	}
}

export default compose(withUI)(Condition);
