// Core
import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { UIContext } from 'contexts/UIContext';

// UI
import { Button } from 'components/Buttons/Button';
import { Label } from 'components/Labels';

// Styles
import styles from 'pages/contracts/AddContract/NoCode/NoCode.module.css';

const NoCode = ({ onSubmit }) => {
	const { isAccountLoading, setIsAccountLoading } = useContext(AuthContext);
	const { hideBackNavi } = useContext(UIContext);

	const handleBack = () => {
		if (typeof onSubmit === 'function') {
			onSubmit();
		}
	};

	useEffect(() => {
		hideBackNavi();

		if (isAccountLoading) {
			setTimeout(() => {
				setIsAccountLoading(false);
			}, 500);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.page}>
			<div className={styles.form}>
				<div className={styles.title}>
					<Label font="--gilroy-Medium-40" black>
						<FormattedMessage id={'rod.contracts.no_code.header'} />
					</Label>
					<Label font="--gilroy-Medium-24" black>
						<FormattedMessage id={'rod.contracts.no_code.body'} />
					</Label>
				</div>
				<div className={styles.controls}>
					<Button
						className={styles.button}
						text={<FormattedMessage id={'rod.action.goto_contract'} />}
						type={'submit'}
						variant={'primary'}
						onClick={handleBack}
					/>
				</div>
			</div>
		</div>
	);
};

export default NoCode;
