// Core
import React from 'react';
import classNames from 'classnames';
import { withLang, withAuth } from 'hocs';
import { compose } from 'recompose';

// Styles
import styles from './RubricLang.module.css';

class RubricLang extends React.Component {
	static propTypes = {};
	static defaultProps = {};
	static displayName = 'Lang';

	render() {
		const { className, langContext, withFocusRef, lang, ...props } = this.props;
		const classes = classNames(className, styles['Lang']);

		const anotherLang = lang === 'en' ? 'ru' : 'en';
		const changeArticlesLang = () => {
			const { setLang } = this.props;
			localStorage.setItem('rubric_lang', anotherLang);
			setLang(anotherLang);
		};

		return (
			<div {...props} className={classes}>
				<ul className={styles.list}>
					<li className="current-lang">{lang}</li>
					<li className={styles.anotherLangRubric} onClick={changeArticlesLang}>
						{anotherLang}
					</li>
				</ul>
			</div>
		);
	}
}

export default compose(withLang, withAuth)(RubricLang);
