// Utils
import { fm } from 'utils';

const TableHead = () => {
	return (
		<thead>
			<tr>
				<th>{fm('rod.admin.account.files.document_type')}</th>
				<th>{fm('rod.admin.account.files.file')}</th>
				<th className="cb">{fm('rod.admin.account.files.quality')}</th>
				<th className="cb">{fm('rod.admin.account.contracts.signs')}</th>
				<th className="cb">{fm('rod.admin.account.contracts.all_pages')}</th>
			</tr>
		</thead>
	);
};

export default TableHead;
