import React, { useContext, useState, useEffect } from 'react';
import { LangContext } from 'contexts/LangContext';
import s from './RepertoireReleaseNotices.module.css';
import { Loading } from 'components';
import { RepertoireReleaseNoticesItem } from './RepertoireReleaseNoticesItem';

import { withAuth } from 'hocs';
import { compose } from 'recompose';
import { accounts } from 'services';

function RepertoireReleaseNotices(props) {
	const { lang } = useContext(LangContext);
	const [notices, setNotices] = useState([]);
	const [loading, setLoading] = useState(true);

	const {
		authContext: { accountId },
		releaseId,
		getInfo,
	} = props;

	const scopeList = {
		audio: { en: 'Audio', ru: 'Аудио' },
		code: { en: 'Code', ru: 'Код' },
		date: { en: 'Date', ru: 'Дата' },
		image: { en: 'Cover image', ru: 'Обложка' },
		genre: { en: 'Genre', ru: 'Жанр' },
		contributors: { en: 'Contributors', ru: 'Контрибьюторы' },
		lyrics: { en: 'Lyrics', ru: 'Лирика' },
	};

	useEffect(() => {
		accounts
			.getReleaseInfoForBrowsing(accountId, releaseId)
			.then((res) => {
				res = res.data.data;
				getInfo({ title: res.title, ean: res.ean });
			})
			.catch((error) => {
				console.info('Error', error);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setLoading(true);
		accounts
			.getReleaseNotices(accountId, releaseId, lang)
			.then((res) => {
				if (res.data.message === 'not_found') {
					setNotices([]);
				} else {
					res = res.data.data;
					setNotices(res);
				}
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				console.error('Error', error);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang]);

	const structuredList = () => {
		let result = [];
		let scopeList = [];
		notices.forEach((currentReason) => {
			if (!scopeList.includes(currentReason.scope)) {
				scopeList.push(currentReason.scope);
			}
		});
		/* change titleList from a to z - add such code here*/
		result = scopeList.map((currentScope) => {
			let reasonsByScope = notices.filter((currentReason) => {
				return currentReason.scope === currentScope;
			});

			return { scope: currentScope, reasons: reasonsByScope }; //example of each obj inside the resulted arr
		});
		return result;
	};

	// 	{
	// 	text: 'Под фонограмму «! (AUSRUFEZEICHEN)» необходимо загрузить аудио.',
	// 	code: 'recording_missing_audio',
	// 	crucial: true,
	// 	scope: 'audio',
	// },

	return loading ? (
		<Loading className={s.loader} />
	) : (
		<div>
			<ul className={s.table__body}>
				{structuredList().map((item) => (
					<li>
						<p className={s.scope_title}>{scopeList[item.scope][lang]}</p>
						<RepertoireReleaseNoticesItem data={item} /*  lang={lang}  */ />
					</li>
				))}
			</ul>
		</div>
	);
}
export default compose(withAuth)(RepertoireReleaseNotices);
