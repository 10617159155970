// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';

// UI
import { FormInput } from 'components/Form';

// Styles
import styles from '../RadioGroup.module.css';

const H11EditRadioGroup = ({
	h11Moderation,
	personal,
	handleOnBlurDateInput,
	changeField,
	errors,
}) => {
	return (
		<div className={styles.input} style={{ marginTop: '-20px' }}>
			{h11Moderation ? (
				<FormInput
					className={styles.date}
					type={'muiInputDate_v2-4'}
					defaultValue={true}
					name={'sale_start_date'}
					handleOnBlurDateInput={handleOnBlurDateInput}
					onChange={changeField}
					data={personal}
					label={
						<FormattedMessage
							id={'rod.release.create.step.distribution.input_date'}
						/>
					}
				/>
			) : (
				<FormInput
					className={styles.date}
					type={'muiInputDate_v2-5'}
					defaultValue={true}
					name={'sale_start_date'}
					handleOnBlurDateInput={handleOnBlurDateInput}
					onChange={changeField}
					data={personal}
					label={
						<FormattedMessage
							id={'rod.release.create.step.distribution.input_date'}
						/>
					}
				/>
			)}

			{Object.keys(errors).includes('sale_start_date') && (
				<span className={styles.Helper}>
					{
						<FormattedMessage
							id={`rod.error.${errors.sale_start_date[0].rule}`}
						/>
					}
				</span>
			)}
		</div>
	);
};

export default H11EditRadioGroup;
