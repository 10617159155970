// Core
import { FormattedMessage } from 'react-intl';

// UI
import { Input } from 'components/Inputs';

// Styles
import styles from './CodeInput.module.css';

const CodeInput = ({
	showCodeButton,
	codeRef,
	errors,
	onChangeCode,
	showError,
	showConfirmCodeButton,
	handleConfirm,
	phone,
}) => {
	return (
		<div
			className={styles.codeInput}
			style={showCodeButton ? { marginTop: '10px' } : { marginTop: '28px' }}
		>
			<FormattedMessage
				id={phone ? 'rod.field.sms_code' : 'rod.field.verify_email_code'}
			>
				{(placeholder) => (
					<Input
						forwardRef={codeRef}
						placeholder={placeholder}
						clearInputIfHasError={true}
						errors={errors.code}
						name={'code'}
						onChange={(s) => {
							onChangeCode(s);
						}}
						showError={showError}
						disabled={
							Object.keys(errors) &&
							errors?.code &&
							(errors.code[0]?.rule === 'code_exhausted' ||
								errors.code[0]?.rule === 'forbidden' ||
								(errors.code[0]?.rule === 'invalid' &&
									errors.code[0]?.remaining === 0))
						}
						forbidEnter
					/>
				)}
			</FormattedMessage>
			{showConfirmCodeButton && (
				<button
					className={styles.resendBtn}
					style={phone ? { bottom: '-5px' } : { bottom: '20px' }}
					onClick={handleConfirm}
				>
					<FormattedMessage id={'rod.action.confirm'} />
				</button>
			)}
		</div>
	);
};

export default CodeInput;
