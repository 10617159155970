// Core
import { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

// Context
import { AuthContext } from 'contexts/AuthContext';

// UI
import { Button } from 'components/Buttons/Button';
import { Label } from 'components/Labels';

// Styles
import styles from './NoContractPage.module.css';

const NoContractPage = () => {
	const { isAccountLoading, setIsAccountLoading } = useContext(AuthContext);
	const history = useHistory();

	const handleGoToMain = () => {
		history.push('/');
	};

	useEffect(() => {
		if (isAccountLoading) {
			setTimeout(() => {
				setIsAccountLoading(false);
			}, 700);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.page}>
			<div className={styles.form}>
				<div className={styles.title}>
					<Label font="--gilroy-Medium-40" black>
						<FormattedMessage id={'rod.contracts.no_contract.header'} />
					</Label>
					<Label font="--gilroy-Medium-24" black>
						<FormattedMessage
							id={
								process.env.REACT_APP_CLIENT === 'mts'
									? 'rod.contracts.no_contract.body.old'
									: 'rod.contracts.no_contract.body'
							}
						/>
					</Label>
				</div>
				<div className={styles.controls}>
					<Button
						className={styles.button}
						text={
							<FormattedMessage
								id={
									process.env.REACT_APP_CLIENT === 'mts'
										? 'rod.action.to_index_page'
										: 'rod.contracts.no_contract.button'
								}
							/>
						}
						type={'submit'}
						variant={'primary'}
						onClick={handleGoToMain}
					/>
				</div>
			</div>
		</div>
	);
};

export default NoContractPage;
