// Core
import { FormattedHTMLMessage } from 'react-intl';

// Icons
import close from 'images/close_button.svg';

// Styles
import styles from './Helper.module.css';

const Helper = ({ setHelper }) => {
	return (
		<div className={styles.helper}>
			<img onClick={() => setHelper(false)} src={close} alt="" />
			<span>
				<FormattedHTMLMessage id={'rod.drafts.title'} />
			</span>
		</div>
	);
};

export default Helper;
