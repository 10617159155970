// Core
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Context
import { RootContext } from 'contexts/RootContext';

// Utils
import { getJSONParse } from 'utils';
import { stylingDigit } from '../utils/stylingDigit';
import {
	assignColorIndexToOutlet,
	strokeColorRange,
} from '../utils/assignColorIndexToOutlet';
import { getStartEndDatesForOneYear } from '../../utils/getStartEndDatesForOneYear';

// UI
import Thead from './Thead/Thead';
import { Pagination } from 'material-design/components';
import LoaderWhithoutProgress from 'components/LoaderWhithoutProgress';

// Icons
import chevronDownIcon from 'images/chevron_down_black.svg';

// Styles
import styles from '../StatisticByDatesSection.module.css';

const TopTracks = ({
	topTracks,
	handleCheckedTopTrack,
	page,
	total,
	setPage,
	setIsPaginationPressed,
	setPageLoading,
	main,
	chartMode,
	statisticData,
	accountOutlets,
	isAdmin,
	accountId,
	outlets,
}) => {
	const lang = localStorage.getItem('lang');
	const { getAccountStatisticStreamsV2 } = useContext(RootContext);
	const history = useHistory();

	const [openedIdx, setOpenedIdx] = useState([]);
	const [tracksData, setTracksData] = useState({});

	const handleCollapse = async (idx, track) => {
		if (openedIdx.includes(idx)) {
			setOpenedIdx(openedIdx.filter((item) => item !== idx));
			return;
		}

		if (!tracksData[`${track.id}:${track.heaven11_release_id}`]) {
			setTracksData({
				...tracksData,
				[`${track.id}:${track.heaven11_release_id}`]: 'loading',
			});
			await fetchItem(track.id, track.heaven11_release_id);
		}
		setOpenedIdx([...openedIdx, idx]);
	};

	const fetchItem = async (trackId, releaseId) => {
		const outlets =
			statisticData['outlets']?.length > 0
				? statisticData['outlets']
				: accountOutlets;

		const outletsCodes = outlets.map((outlet) => outlet.code);
		const startEndDates = getStartEndDatesForOneYear(statisticData, isAdmin);

		const { data } = await getAccountStatisticStreamsV2(
			isAdmin,
			accountId,
			startEndDates[0],
			startEndDates[1],
			outletsCodes,
			[trackId],
			releaseId ? [releaseId] : [],
			false
		);

		setTracksData({
			...tracksData,
			[`${trackId}:${releaseId}`]: data.data,
		});
	};

	const handleClick = (item) => {
		return (main
			? () => history.push(`/repertoire/recordings/${item.id}`)
			: () => handleCheckedTopTrack(item))();
	};

	return (
		<div>
			<table className={styles.topTracksTable}>
				<Thead chartMode={chartMode} />
				<tbody className={styles.topTracksTable__body}>
					{topTracks?.length > 0 &&
						topTracks.map((item, index) => (
							<>
								<tr
									key={index}
									className={`${styles.tableRow} ${
										openedIdx.includes(index) ? styles.noSeparate : ''
									}`}
								>
									{chartMode === 'phono' && (
										<td
											className={`${styles.topTracksTable__item} ${
												styles.moreIcon
											} ${openedIdx.includes(index) ? styles.active : ''}`}
											onClick={() => {
												if (
													tracksData[
														`${item.id}:${item.heaven11_release_id}`
													] &&
													tracksData[
														`${item.id}:${item.heaven11_release_id}`
													] === 'loading'
												) {
													return;
												}
												handleCollapse(index, item);
											}}
										>
											{tracksData[`${item.id}:${item.heaven11_release_id}`] &&
											tracksData[`${item.id}:${item.heaven11_release_id}`] ===
												'loading' ? (
												<div className={styles.loader}>
													<LoaderWhithoutProgress />
												</div>
											) : (
												<img alt="" src={chevronDownIcon} />
											)}
										</td>
									)}

									<td
										className={`${styles.topTracksTable__item}, ${styles.topTracksTable__itemNumber}`}
										onClick={() => handleClick(item)}
									>
										{index + 1 + (page - 1) * 10}
									</td>
									<td
										className={styles.topTracksTable__item}
										onClick={() => handleClick(item)}
									>
										{item.raw_title}
										<span className={styles.topTracksTable__fullName}>
											{item.raw_title}
										</span>
									</td>
									<td
										className={styles.topTracksTable__item}
										onClick={() => handleClick(item)}
									>
										{item.performers ? getJSONParse(item.performers) : '-'}
									</td>
									{chartMode !== 'compo' && (
										<td
											className={styles.topTracksTable__item}
											onClick={() => handleClick(item)}
										>
											{item.release_name}
										</td>
									)}

									<td
										className={styles.topTracksTable__item}
										onClick={() => handleClick(item)}
									>
										{stylingDigit(item.streams)}
									</td>
								</tr>

								{tracksData[`${item.id}:${item.heaven11_release_id}`] &&
									tracksData[`${item.id}:${item.heaven11_release_id}`] !==
										'loading' &&
									tracksData[`${item.id}:${item.heaven11_release_id}`].map(
										(track, idx) => {
											const outletTitle = outlets.find(
												(outlet) => outlet.code === track.outlet
											);
											const outletColor =
												strokeColorRange[
													assignColorIndexToOutlet(track.outlet)
												];

											return (
												<tr
													className={`${styles.tableRow} ${
														styles.accordionRow
													} ${styles.noSeparate} ${
														idx ===
														tracksData[`${item.id}:${item.heaven11_release_id}`]
															.length -
															1
															? styles.lastRow
															: ''
													} ${openedIdx.includes(index) ? styles.rowOpen : ''}`}
												>
													<td></td>
													<td
														className={`${styles.topTracksTable__item}, ${styles.topTracksTable__itemNumber}`}
													></td>
													<td className={styles.outletInfo}>
														<div
															className={styles.outletItem}
															style={{ backgroundColor: outletColor }}
														/>
														<div>{outletTitle[`title_${lang}`]}</div>
													</td>
													<td className={styles.topTracksTable__item}></td>
													{chartMode !== 'compo' && (
														<td className={styles.topTracksTable__item}></td>
													)}
													<td className={styles.topTracksTable__item}>
														{stylingDigit(track.stat_count)}
													</td>
												</tr>
											);
										}
									)}
							</>
						))}
				</tbody>
			</table>

			{total > 10 && !main && (
				<Pagination
					page={page}
					total={total}
					paginate={(pageNumber) => {
						setPage(pageNumber);
						setIsPaginationPressed(true);
						setPageLoading(true);
					}}
					items_on_page={10}
				/>
			)}
		</div>
	);
};

export default TopTracks;
