// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import { FormInput } from 'components/Form/FormInput';
import CmoBtn from '../CmoBtn/CmoBtn';

// Styles
import s from '../../Authors.module.css';

const PublisherMain = ({
	handleOnChange,
	handleOnFocus,
	handleOnBlur,
	index,
	errors,
	selectPerson,
	tmpInputValue,
	setModal,
	deletePublisher,
	isLoading,
	onSave,
}) => {
	return (
		<div className={s.publisher_item}>
			<div className={s.publisher_author}>
				<FormInput
					type="muiInput"
					name={'publisher'}
					onChange={handleOnChange(index)}
					errors={
						errors.publisher &&
							errors.publisher.some((item) => item.key === index)
							? errors
							: {}
					}
					data={selectPerson.contributors[index]}
					label={
						<FormattedMessage
							id={'rod.authors_shares.author_publisher_id'}
							values={{ id: index + 1 }}
						/>
					}
				/>
				{Object.keys(errors).includes('publisher') &&
					errors['publisher'].map(
						(item) =>
							item.key === index &&
							(item.type === 'max_length' ? (
								<span className={s.Helper}>
									<FormattedMessage
										id={`rod.error.max_length`}
										values={{
											num: item.value,
										}}
									/>
								</span>
							) : (
								<>
									{errors.publisher &&
										errors.publisher.some((item) => item.key === index) && (
											<span className={s.Helper}>
												<FormattedMessage
													id={`rod.release_create.error.required`}
												/>
											</span>
										)}
								</>
							))
					)}
			</div>
			<div className={s.input}>
				<FormInput
					type={'muiSlider'}
					name={`publisher_share`}
					onChange={handleOnChange(index)}
					onFocus={handleOnFocus(index)}
					onBlur={handleOnBlur(index)}
					tmpInputValue={tmpInputValue}
					errors={errors}
					data={selectPerson.contributors[index]}
					labelSlider={
						<FormattedMessage
							id={'rod.authors_shares.publisher_share_id'}
							values={{ id: index + 1 }}
						/>
					}
					width={128}
					bind={selectPerson?.contributors[index]?.publisher_show}
				/>
				{Object.keys(errors).includes('publisher_share') &&
					errors['publisher_share'].includes(index) && (
						<span className={s.Helper}>
							{<FormattedMessage id={`rod.release_create.error.required`} />}
						</span>
					)}
			</div>
			<CmoBtn
				selectPerson={selectPerson}
				index={index}
				onSave={onSave}
				deleteContributor={deletePublisher}
				setModal={setModal}
				isLoading={isLoading}
				type="publisher"
			/>
		</div>
	);
};

export default PublisherMain;
