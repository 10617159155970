// Core
import React, { Component } from 'react';

// Context
import { RootContext } from 'contexts/RootContext';

// UI
import { Logo } from 'components/Logo';
import { Header, NavBar } from '../../containers';

// Styles
import styles from './Layout.module.css';

class Layout extends Component {
	static propTypes = {};
	static defaultProps = {};
	static displayName = 'Layout';
	static contextType = RootContext;
	state = {
		show: false,
	};

	handleScroll = (e) => {
		const { handleDashboardScrolled } = this.context;
		const { scrollTop, scrollHeight, clientHeight } = e.target;

		if (clientHeight + Math.ceil(scrollTop) >= scrollHeight) {
			handleDashboardScrolled(true);
		} else {
			handleDashboardScrolled(false);
		}
	};

	render() {
		return (
			<>
				<div className={styles.Layout}>
					<Logo className={styles.MainLogo} />
					<Header />
					<div className={styles.NavBar}>
						<NavBar />
					</div>
					<main
						className={`${styles.Page}  ${
							this.props.withPlayer ? styles['with-player'] : ''
						}`}
					>
						{this.props.children}
					</main>
				</div>
			</>
		);
	}
}

export default Layout;
