// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Icons
import download from 'images/download.svg';
import clock from 'images/clock.svg';

// Styles
import styles from './DownloadWrapper.module.css';

const DownloadWrapper = ({
	item,
	contractsFiles,
	getAttachments,
	downloadHref,
}) => {
	return (
		<div className={styles.downloadWrapper}>
			{!item.status ? (
				contractsFiles.find((data) => data.contractId === item.id)?.files[0]
					?.url && (
					<>
						<button
							className={styles.load__btn}
							onClick={() => getAttachments(item.id)}
						>
							<img src={download} alt="" />
							<FormattedMessage
								id={'rod.field.contracts.contract_download_signed'}
							/>
						</button>
						<a
							href={downloadHref}
							download
							id="download"
							target="_blank"
							rel="noreferrer"
							hidden
						>
							{' '}
						</a>
					</>
				)
			) : (
				<div>
					<button className={styles.load__btn}>
						<img src={clock} alt="" />
						<FormattedMessage id={'rod.treaties_page.pending_review'} />
					</button>
				</div>
			)}
		</div>
	);
};

export default DownloadWrapper;
