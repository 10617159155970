// Langs
import pagesEn from 'messages/EN/pages';
import pagesRu from 'messages/RU/pages';

export const filterRecordingSettingsData = [
	{
		id: 1,
		title_ru: 'статус',
		title_en: 'status',
		list: [
			{
				id: 1,
				name_ru: pagesRu['rod.repertoire.not_ready'],
				name_en: pagesEn['rod.repertoire.not_ready'],
				code: 'not_ready',
				checked: false,
			},
			{
				id: 2,
				name_ru: pagesRu['rod.repertoire.ready'],
				name_en: pagesEn['rod.repertoire.ready'],
				code: 'ready',
				checked: false,
			},
			{
				id: 3,
				name_ru: pagesRu['rod.repertoire.status.disputed'],
				name_en: pagesEn['rod.repertoire.status.disputed'],
				code: 'disputed',
				checked: false,
			},
			{
				id: 4,
				name_ru: pagesRu['rod.composition.filter.active'],
				name_en: pagesEn['rod.composition.filter.active'],
				code: 'active',
				checked: false,
			},
			{
				id: 5,
				name_ru: pagesRu['rod.repertoire.expiring'],
				name_en: pagesEn['rod.repertoire.expiring'],
				code: 'expiring',
				checked: false,
			},
			{
				id: 6,
				name_ru: pagesRu['rod.composition.filter.expired'],
				name_en: pagesEn['rod.composition.filter.expired'],
				code: 'expired',
				checked: false,
			},
		],
	},
];
