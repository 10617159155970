/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 25/05/2020, 20:02
 */

import React from 'react';
import ReactDOM from 'react-dom';

const modalRoot = document.getElementById('modal-root');

class BaseModal extends React.Component {
	constructor(props) {
		super(props);
		this.el = document.createElement('div');
		this.el.style.cssText = 'position: fixed; width: 100%; height: 100%;';
	}

	componentDidMount() {
		document.addEventListener('keyup', this.keyUp);
		modalRoot.appendChild(this.el);
	}

	componentWillUnmount() {
		modalRoot.removeChild(this.el);
		document.removeEventListener('keyup', this.keyUp);
	}

	keyUp = (event) => {
		if (event.keyCode === 27) {
			this.close();
		}
	};

	close = () => {
		if (typeof this.props.onClose === 'function') {
			this.props.onClose();
		}
	};

	render() {
		return ReactDOM.createPortal(this.props.children, this.el);
	}
}

export default BaseModal;
