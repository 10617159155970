// Core
import { FormattedMessage } from 'react-intl';

// Utils
import { formatDistributionDate } from '../utils/formatDistributionDate';
import { groupTracksByReleaseType } from '../utils/groupTracksByReleaseType';

// Styles
import s from '../ConfirmDistributions.module.css';
import styles from './AdditionalDistributions.module.css';

const AdditionalDistributions = ({ tracks }) => {
	return (
		<>
			{groupTracksByReleaseType(tracks).map((track) => (
				<div className={styles.main}>
					<span className={s.title}>
						<FormattedMessage
							id={
								track.release_type_id === 43
									? 'rod.release.create.step.submit.additional.distribution_rt'
									: 'rod.release.create.step.submit.additional.distribution_tiktok'
							}
						/>
					</span>

					{track.additional.map((additional) => (
						<div className={s.distributions}>
							<div className={s.item}>
								<span className={s.item__title}>
									<FormattedMessage
										id={'rod.release.create.step.submit.additional.title'}
									/>
								</span>
								<span className={s.item__text}>{additional.title}</span>
							</div>
							<div className={s.item}>
								<span className={s.item__title}>
									<FormattedMessage
										id={
											track.release_type_id === 43
												? 'rod.release.create.step.submit.additional.distribution.rt.date'
												: 'rod.release.create.step.submit.additional.distribution.tiktok.date'
										}
									/>
								</span>
								<span className={s.item__textLh}>
									{formatDistributionDate(
										additional.distributions.sale_start_date
									)}
								</span>
							</div>
						</div>
					))}
				</div>
			))}
		</>
	);
};

export default AdditionalDistributions;
