// Core
import { FormattedMessage } from 'react-intl';

export const contractSteps = [
	{
		id: 1,
		status: 'complete',
		title: <FormattedMessage id={'rod.account.create.step_1.title'} />,
	},
	{
		id: 2,
		status: 'complete',
		title: <FormattedMessage id={'rod.account.create.step_2.title'} />,
	},
	{
		id: 3,
		status: 'active',
		title: <FormattedMessage id={'rod.account.create.step_3.title'} />,
	},
];
