// Core
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { withUI, withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';

// Context
import { LangContext } from 'contexts/LangContext';
import { RootContext } from 'contexts/RootContext';

// UI
import { FormInput } from 'components/Form/FormInput';
import { ReportsCheckLayout } from '../../../ReportsCheckLayout';

// Styles
import styles from './Company.module.css';

const Company = ({ data, changeField, errors, accountType, ...props }) => {
	const { lang } = useContext(LangContext);
	const { countries } = useContext(RootContext);

	const [personal, setPersonal] = useState({});

	useEffect(() => {
		setPersonal(data);
	}, [data, lang]);
	
	return (
		<>
			{personal && (
				<ReportsCheckLayout
					title="rod.account.personal.title"
				>
					<div>
						<FormattedMessage
							id={
								accountType === 'legal_entity'
									? 'rod.field.company.name'
									: 'rod.field.personal_entrepreneur.name'
							}
						>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									helper={
										<FormattedMessage
											id={
												accountType === 'legal_entity'
													? 'rod.field.company.name.helper'
													: 'rod.field.personal_entrepreneur.name.helper'
											}
										/>
									}
									name={'title'}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage
							id={
								accountType === 'legal_entity'
									? 'rod.field.company.signer'
									: 'rod.field.personal_entrepreneur.signer'
							}
						>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									helper={
										<FormattedMessage
											id={
												accountType === 'legal_entity'
													? 'rod.field.company.signer.helper'
													: 'rod.field.personal_entrepreneur.signer.helper'
											}
										/>
									}
									name={'behalf'}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage
							id={
								accountType === 'legal_entity'
									? 'rod.field.company.signer_short_name'
									: 'rod.field.personal_entrepreneur.signer_short_name'
							}
						>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									helper={
										<FormattedMessage
											id={
												accountType === 'legal_entity'
													? 'rod.field.company.signer_short_name.helper'
													: 'rod.field.personal_entrepreneur.signer_short_name.helper'
											}
										/>
									}
									name={'behalf_short'}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.company.tax_number'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'itin'}
									onChange={changeField}
									errors={errors}
									data={personal}
									required={
										window.location.href.includes('.ru') ||
										window.location.href.includes('broma16ru-stage')
											? true
											: false
									}
								/>
							)}
						</FormattedMessage>
					</div>
					<div>
						<FormattedMessage id={'rod.field.country'}>
							{(placeholder) => (
								<FormInput
									type="country"
									countries={countries}
									placeholder={placeholder}
									name={'country_id'}
									onChange={changeField}
									errors={errors}
									data={personal}
									className={styles.country}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.admin.account.city'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'city'}
									onChange={changeField}
									errors={errors}
									data={personal}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.zip'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'zip_code'}
									onChange={changeField}
									errors={errors}
									data={personal}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage
							id={'rod.field.registration_address.street_house'}
						>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'address'}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
					</div>
				</ReportsCheckLayout>
			)}
		</>
	);
};

export default compose(withUI, withAuth, withRoot)(Company);
