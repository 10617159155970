// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';

// UI
import { Col, Row } from 'react-grid-system';

// Styles
import s from '../../TreatiesPage.module.css';

const EmptyContract = () => {
	return (
		<div className={s.main}>
			<div className={s.item__body}>
				<span className={s.item__title}>
					<FormattedMessage id={'rod.composition.contract.condition'} />
					{` ${1}: `}
					<span className={s.emptyItem__title}>
						<FormattedMessage id={'rod.treaties_page.empty'} />
					</span>
				</span>
				<Row className={s.datesSection}>
					<Col className={s.dateColumn}>
						<div className={s.table__item}>
							<div className={s.condition__title}>
								<FormattedMessage id={'rod.treaties_page.dates'} />
							</div>
							<div className={s.empty}>
								<FormattedMessage id={'rod.treaties_page.empty'} />
							</div>
						</div>
					</Col>
					<Col className={s.dateColumn}>
						<div className={s.table__item}>
							<div>
								<div className={s.condition__title}>
									<FormattedMessage id={'rod.treaties_page.collection'} />
								</div>
								<div className={s.empty}>
									<FormattedMessage id={'rod.treaties_page.empty'} />
								</div>
							</div>
						</div>
					</Col>
					<Col>
						<div className={s.territory__title}>
							<FormattedMessage id={'rod.contracts.regions'} />
						</div>
						<div className={s.empty}>
							<FormattedMessage id={'rod.treaties_page.empty.regions'} />
						</div>
					</Col>
				</Row>

				<div className={s.dates}></div>
			</div>
		</div>
	);
};

export default EmptyContract;
