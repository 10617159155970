import React, { useEffect, useState } from 'react';
import {
	TextFieldInput,
	AutocompleteInputGenres,
} from 'material-design/MuiStyled/MuiStyled';
import { makeStyles, createStyles } from '@mui/styles';
import { Popper } from '@mui/material';
import { accounts } from 'services';
import styles from './MuiAutocompleteMultiple.module.css';

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			'& .MuiAutocomplete-listbox': {
				background: 'var(--color-light-grey)',
				margin: 0,
				padding: 0,
				'& li': {
					backgroundColor: '#FFF',
					font: 'var(--gilroy-Medium-16-150)',
					padding: '12px 16px',
					'&:hover': {
						backgroundColor: '#F0F0F0;',
					},
				},
			},
		},
	})
);

const CustomPopper = function (props) {
	const classes = useStyles();
	return <Popper {...props} className={classes.root} placement="bottom" />;
};

const MuiAutocomplete = ({ onChange, label, value, required, initialData }) => {
	const [outletsArr, setOutletsArr] = useState([]);
	const lang = localStorage.getItem('lang');
	// const titleByLang = [`title_${lang}`];
	useEffect(() => {
		accounts
			// .getOutlets(lang)
			.getAccountStatisticOutlets()
			.then((res) => {
				res = res.data.data;
				res = res.sort((a, b) =>
					a[`title_${lang}`] > b[`title_${lang}`] ? 1 : -1
				);
				setOutletsArr(res);
			})
			.catch((error) => {
				console.error('Outlets loading error', error);
			});

		// setOutletsArr(initialData.sort((a, b) => (a.title > b.title ? 1 : -1)));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (outletsArr.length) {
		for (let i = 0; i < value.length; i++) {
			for (let j = 0; j < outletsArr.length; j++) {
				if (value[i] === outletsArr[j][`title_${lang}`]) {
					value[i] = outletsArr[j];
					// value[i] = {
					// 	id: outletsArr[j].id,
					// 	title: outletsArr[j].title,
					// };
				}
			}
		}
	}

	const filterOptions = (_, state) => {
		const newOptions = [];
		if (state.inputValue) {
			outletsArr.forEach((item) => {
				const word = item[`title_${lang}`];
				if (
					word.toLowerCase().includes(state.inputValue.toLowerCase()) &&
					word.toLowerCase().indexOf(state.inputValue.toLowerCase()) === 0
				) {
					newOptions.push(item);
				}
			});
			return newOptions;
		} else return outletsArr;
	};

	return (
		<div className={styles.autoGenres_wrapper}>
			<AutocompleteInputGenres
				multiple
				id="size-small-standard-multi"
				size="small"
				limitTags={2}
				onChange={(_, obj) => onChange(obj)}
				options={outletsArr}
				value={value}
				renderInput={(params) => (
					<TextFieldInput
						{...params}
						label={label}
						variant="standard"
						required={required}
					/>
				)}
				getOptionLabel={(option) => {
					return option[`title_${lang}`];
				}}
				renderOption={(option, item) => {
					return <li {...option}>{item[`title_${lang}`]}</li>;
				}}
				isOptionEqualToValue={(option, value) => {
					return option[`title_${lang}`] === value[`title_${lang}`];
				}}
				PopperComponent={CustomPopper}
				filterOptions={filterOptions}
			/>
			{value.length > 2 && (
				<span className={styles.autoTag}>+{value.length - 2}</span>
			)}
		</div>
	);
};

export default MuiAutocomplete;
