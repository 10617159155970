export const addTypesToDrafts = (
	initialDrafts,
	releases,
	compositionTypes,
	setLoading
) => {
	const lang = localStorage.getItem('lang');

	if (initialDrafts.length !== 0) {
		initialDrafts.forEach((item) => {
			switch (item.draft_type) {
				case 'release':
					releases.forEach((releaseItem) => {
						if (item.type_id.toString() === releaseItem.id.toString())
							item.type = releaseItem.title;
					});
					break;
				case 'composition':
					compositionTypes.forEach((compositionItem) => {
						if (item.type_id.toString() === compositionItem.id.toString())
							item.type =
								lang === 'en'
									? compositionItem.title_en
									: compositionItem.title_ru;
					});
					break;

				default:
					break;
			}

			if (item.created_date) {
				item.created_date = item.created_date.replace(
					/(\d{4})-(\d{2})-(\d{2}).+/gm,
					'$3.$2.$1'
				);
			}
			return item;
		});
		setLoading(false);
	}
	return initialDrafts;
};
