export const stylingDigit = (digit) => {
	let result = [];
	if (+digit > 999) {
		for (let i = 0; i < digit.length / 3; i++) {
			result.unshift(digit.slice(digit.length - 3));
			digit = digit.slice(0, digit.length - 3);
		}
		result.unshift(digit);
		return result.join(' ');
	} else return digit;
};
