/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 09/02/2021, 17:28
 */

import classnames from 'classnames';
import { Label } from 'components/Labels/Label';
import { DropZone } from 'components/Upload/DropZone';
import { withRoot } from 'hocs';
import attachmentImg from 'images/attachment.svg';
import closeImg from 'images/close_black.svg';
import downloadImg from 'images/contract_download.svg';
import numeral from 'numeral';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import styles from './ContractListItem.module.css';

class ContractListItem extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			files: [],
		};
	}

	// componentDidUpdate(prevProps, prevState) {
	//   if (this.state.files.length !== prevState.files.length) {

	//   }
	// }

	upload = (files) => {
		const {
			rootContext: { uploadContract },
			contract_type,
		} = this.props;
		const fd = new FormData();
		fd.append(`file`, files[0]);
		// files.forEach((file, index) => {
		// 	fd.append(`file.${index}`, file);
		// });
		return uploadContract(this.props.match.params.id, contract_type, fd)
			.then((res) => {
				res = res.data.data;
				if (this.props.isAdditional) {
					const contract = res;
					contract[0].type = contract[0].type
						.replace('contract-', '')
						.split()[0];

					this.props.setUploadedContracts([
						...this.props.uploadedContracts,
						...contract,
					]);
				}
				this.setState({ files: res });
				return Promise.resolve(res);
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	};

	remove = (id) => () => {
		const {
			rootContext: { deleteUploadedContract },
			contract_type,
		} = this.props;
		return deleteUploadedContract(
			this.props.match.params.id,
			contract_type,
			id
		).then((res) => {
			res = res.data.data;
			if (this.props.isAdditional) {
				const data = this.props.uploadedContracts.filter(
					(item) => item.id !== id
				);

				this.props.setUploadedContracts(data);
			}

			this.setState({ files: res });
			return Promise.resolve(res);
		});
	};

	correctDateView = (date) => {
		return date
			? date.replace(/(\d{1,4})-(\d{1,2})-(\d{1,2})/g, '$3.$2.$1').slice(0, 10)
			: '';
	};

	render() {
		const {
			title,
			status,
			index,
			contract_url,
			showFiles,
			showUpload,
			showDownload,
			showDownloadSigned,
			showDate,
			showStatus,
			date,
			hash,
			showHash,
			signed_contract,
			errors,
		} = this.props;
		const { files } = this.state;
		return (
			<div className={styles.Item}>
				<div className={styles.Title}>
					<Label font="--gilroy-Medium-24" black>
						{`${index + 1}. ${title}`}
					</Label>
					{showDownload && (
						<div className={styles.Download}>
							{/* <a href={contract_url} target="_blank" rel="noopener noreferrer"> */}
							<a
								href={this.props.files[0].url}
								target="_blank"
								rel="noopener noreferrer"
							>
								<img src={downloadImg} alt="" />
								<Label font="--gilroy-Medium-16" black>
									<FormattedMessage
										id={`rod.field.contracts.contract_download`}
									/>
								</Label>
							</a>
						</div>
					)}
				</div>

				{showStatus && (
					<div className={classnames(styles.Status, status)}>
						<Label font="--gilroy-Medium-16" className={'status-title'}>
							<FormattedMessage
								id={'rod.field.contracts.contract_status.title'}
							/>
							:{' '}
							<FormattedMessage
								id={`rod.field.contracts.contract_status.value.${status}`}
							/>
						</Label>
					</div>
				)}
				{errors && errors.length > 0 && (
					<div className={styles.Error}>
						<Label font="--gilroy-Medium-16" className={'status-error'}>
							<div>
								<FormattedMessage
									id={'rod.field.contracts.contract_status.error'}
								/>
								:
								<ul>
									{errors.map((err) => (
										<li>
											<FormattedMessage
												id={`rod.field.contracts.contract_error.value.${err}`}
											/>
										</li>
									))}
								</ul>
								{showDownloadSigned && signed_contract && (
									<div className={styles.DownloadSigned}>
										<a
											href={signed_contract.url}
											target="_blank"
											rel="noopener noreferrer"
										>
											<img src={downloadImg} alt="" />
											<Label font="--gilroy-Medium-16" black>
												<FormattedMessage
													id={`rod.field.contracts.contract_download_signed`}
												/>
											</Label>
										</a>
									</div>
								)}
							</div>
						</Label>
					</div>
				)}
				{showDate && date && (
					<div className={styles.Date}>
						<Label font="--gilroy-Medium-16">
							<FormattedMessage
								id={'rod.field.contracts.contract_status.date'}
							/>
							: {this.correctDateView(date)}
							{/* : {moment(date).format('L')} */}
						</Label>
					</div>
				)}
				{showHash && hash && (
					<div className={styles.Param}>
						<Label font="--gilroy-Medium-16">document hash: {hash}</Label>
					</div>
				)}
				<div className={styles.uploadBlock}>
					{showUpload && (
						<DropZone onUpload={this.upload} isSignDocPage={true} />
					)}
					{showFiles && files.length > 0 && (
						<ul className={styles.Files}>
							{files.map((file) => (
								<div className={styles.Uploaded}>
									<div className={styles.Line}>
										<img src={attachmentImg} alt="attachment" />
										<div className="filename">{file.client_name}</div>
										<div className="size">
											{numeral(file.size).format('0b')}
										</div>
										<div
											className={styles.Remove}
											onClick={this.remove(file.id)}
										>
											<img src={closeImg} alt="" />
										</div>
									</div>
								</div>
							))}
						</ul>
					)}
				</div>
			</div>
		);
	}
}

ContractListItem.propTypes = {};

ContractListItem.defaultProps = {
	showStatus: true,
};

export default compose(withRoot, withRouter)(ContractListItem);
