/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/02/2021, 11:32
 */

import { Loading, Wizard } from 'components';
import { Button } from 'components/Buttons/Button';
import { ContractListItem } from 'components/DeclinedContractListItem';
import { Label } from 'components/Labels/Label';
import accountCompletionSteps from 'constants/accountCompletionSteps';
import contractStates from 'constants/contractStates';
import documentTypes from 'constants/documentTypes';
import { RootContext } from 'contexts/RootContext';
import { withAuth, withLang, withRoot } from 'hocs';
// import update from 'immutability-helper';
import Success from 'pages/common/Success';
import React, { PureComponent, useContext, useEffect, useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
// import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './Complete.module.css';

export const DeclinedContracts = (props) => {
	const {
		getContracts,
		getAccountPersonalData,
		getAdditionalContracts,
	} = useContext(RootContext);
	const { id } = useParams();

	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({});
	const [account, setAccount] = useState({});
	const [uploadedContracts, setUploadedContracts] = useState([]);
	const [contractsWithError, setContractsWithError] = useState([]);

	useEffect(() => {
		getAccountPersonalData(id)
			.then((res) => {
				res = res.data.data;
				setAccount(res);

				getContracts(id).then((res) => {
					res = res.data.data;

					setData(res);
					setLoading(false);
				});
			})
			.catch((error) => {
				console.error('Complete - getAccountPersonalData error: ', error);
				console.error(
					'Complete - getAccountPersonalData error.response: ',
					error.response
				);
			});
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (data.length) {
			const contracts = data
				.map((item) => {
					let moderation_status = {};

					if (
						account.moderation_status.hasOwnProperty(
							`contract-${item.contract_type}${item.hash}`
						)
					) {
						moderation_status =
							account.moderation_status[
								`contract-${item.contract_type}${item.hash}`
							];
					}
					return {
						...item,
						moderation_status,
					};
				})
				.filter((item) => {
					return !(
						item.moderation_status.valid &&
						item.moderation_status.signature &&
						item.moderation_status.quality
					);
				});
			setContractsWithError(contracts);
		}
	}, [data]);

	if (loading) {
		return <Loading non_margin={true} />;
	}

	return (
		<div className={styles.Declined}>
			<Label
				text={
					<FormattedHTMLMessage
						id={'rod.account.create.moderation.declined_contracts.header'}
					/>
				}
				black
				font="--gilroy-Medium-40"
			/>

			<Label
				text={
					<FormattedHTMLMessage
						id={'rod.account.create.moderation.declined_contracts'}
					/>
				}
				black
				font="--gilroy-Medium-24"
			/>
			<ul style={{ listStyle: 'none' }} className={styles.contractList}>
				{contractsWithError.map((item, index) => (
					<li>
						<ContractListItem
							{...item}
							index={index}
							showStatus={false}
							showDownloadSigned
							errors={Object.keys(item.moderation_status).filter(
								(key) => !item.moderation_status[key]
							)}
							setUploadedContracts={setUploadedContracts}
							uploadedContracts={uploadedContracts}
							isAdditional={props.isAdditional}
							showDownload={item.status === contractStates.SIGNING}
							showFiles={item.status === contractStates.SIGNING}
							showUpload={item.status === contractStates.SIGNING}
						/>
					</li>
				))}
			</ul>

			<div className={styles.Controls}>
				<Button
					text={<FormattedHTMLMessage id={'rod.action.to_contracts'} />}
					variant={'primary'}
					disabled={
						props.isAdditional &&
						contractsWithError.length !== uploadedContracts.length
					}
					onClick={() => props.toContracts(uploadedContracts)}
				/>
			</div>
		</div>
	);
};

const DeclinedDocuments = (props) => {
	const { getAccountDocuments, getAccountPersonalData } = useContext(
		RootContext
	);
	const { id } = useParams();

	const [quality, setQuality] = useState(false);
	const [invalid, setInvalid] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		getAccountPersonalData(id).then((account) => {
			account = account.data.data;
			getAccountDocuments(id).then((res) => {
				res = res.data.data;
				setLoading(false);
			});

			let inv = false;
			let qual = false;
			Object.keys(account.moderation_status).forEach((key) => {
				switch (key) {
					case documentTypes.PASSPORT_PHOTO_PAGE:
					case documentTypes.PASSPORT_REGISTRATION_PAGE:
					case documentTypes.DRIVER_LICENSE_FRONT_PAGE:
					case documentTypes.DRIVER_LICENSE_BACK_PAGE:
					case documentTypes.COMPANY_TAX_RESIDENCY_CERTIFICATE:
					case documentTypes.COMPANY_REGISTRATION_CERTIFICATE:
					case documentTypes.SOLE_ENTREPRENEUR_REGISTRATION_CERTIFICATE:
						if (!account.moderation_status[key].quality) {
							qual = true;
						}
						if (!account.moderation_status[key].valid) {
							inv = true;
						}
						break;
					default:
						return null;
				}
			});
			setInvalid(inv);
			setQuality(qual);
		});
		// eslint-disable-next-line
	}, []);

	if (loading) {
		return <Loading non_margin={true} />;
	}
	return (
		<div className={styles.Declined}>
			<Label
				text={
					<FormattedHTMLMessage
						id={'rod.account.create.moderation.declined_documents.header'}
					/>
				}
				black
				font="--gilroy-Medium-40"
			/>

			<Label
				text={
					<FormattedHTMLMessage
						id={'rod.account.create.moderation.declined_documents'}
					/>
				}
				black
				font="--gilroy-Medium-24"
			/>
			<ul>
				{quality && (
					<li>
						<Label
							text={
								<FormattedHTMLMessage
									id={'rod.account.create.moderation.error_quality'}
								/>
							}
							black
							font="--gilroy-Medium-24"
						/>
					</li>
				)}
				{invalid && (
					<li>
						<Label
							text={
								<FormattedHTMLMessage
									id={'rod.account.create.moderation.error_invalid'}
								/>
							}
							black
							font="--gilroy-Medium-24"
						/>
					</li>
				)}
			</ul>
			<Label
				text={
					<FormattedHTMLMessage
						id={'rod.account.create.moderation.declined_documents.todo'}
					/>
				}
				black
				font="--gilroy-Medium-24"
			/>
			<div className={styles.Controls}>
				<Button
					text={<FormattedHTMLMessage id={'rod.action.to_documents'} />}
					variant={'primary'}
					onClick={props.toDocuments}
				/>
			</div>
		</div>
	);
};

class Complete extends PureComponent {
	static propTypes = {};

	state = {
		isPersonal: true,
	};

	steps = [
		{
			id: 1,
			status: 'complete',
			title: <FormattedMessage id={'rod.account.create.step_3_1.title'} />,
		},
		{
			id: 2,
			status: 'active',
			title: <FormattedMessage id={'rod.account.create.step_3_2.title'} />,
		},
	];

	render() {
		const { step } = this.props;
		switch (step) {
			case accountCompletionSteps.DECLINED_DOCUMENTS:
				return (
					<DeclinedDocuments
						account={this.props.account}
						toDocuments={this.props.toDocuments}
					/>
				);

			case accountCompletionSteps.DECLINED_CONTRACTS:
				return (
					<div className={styles.Page}>
						<Wizard steps={this.steps} className={styles.Wizard} />
						<div className={styles.Container}>
							<DeclinedContracts
								account={this.props.account}
								toContracts={this.props.toContracts}
							/>
						</div>
					</div>
				);

			case accountCompletionSteps.PENDING_CONTRACT:
				return <Redirect to={`/accounts/${this.props.id}/contracts/pending`} />;

			default:
				return (
					<Success>
						<Label
							text={
								<FormattedHTMLMessage
									id={'rod.account.create.moderation.pending.header'}
								/>
							}
							black
							font="--gilroy-Medium-40"
						/>

						<Label
							text={
								<FormattedHTMLMessage
									id={'rod.account.create.moderation.pending'}
								/>
							}
							black
							font="--gilroy-Medium-24"
						/>
						<div className={styles.Controls}></div>
					</Success>
				);
		}
	}
}

export default compose(withRoot, withAuth, withLang)(Complete);
