export const getTenTopCountries = (data, countriesCache) => {
	let result = [];
	result = data.slice(0, 10).filter((item) => item.auditions);
	result.map((item) => {
		if (countriesCache) {
			const country = countriesCache.filter((el) => el.code === item.code);
			if (country.length > 0) {
				item.title_ru = country[0].title_ru;
				item.title_en = country[0].title_en;
			}
			return item;
		}
		return item;
	});
  
	return result;
};
