// Core
import { FormattedHTMLMessage } from 'react-intl';

// Styles
import styles from './Thead.module.css';

const Thead = ({ chartMode }) => {
	return (
		<thead className={styles.topTracksTable__header}>
			<tr>
				{chartMode !== 'compo' && <td> </td>}
				<td> </td>
				<td>
					<FormattedHTMLMessage id={'rod.statistic.top.track.title'} />
				</td>
				<td>
					<FormattedHTMLMessage id={'rod.statistic.top.performer'} />
				</td>
				{chartMode !== 'compo' && (
					<td>
						<FormattedHTMLMessage id={'rod.statistic.top.album'} />
					</td>
				)}
				<td>
					<FormattedHTMLMessage id={'rod.statistic.top.streams'} />
				</td>
			</tr>
		</thead>
	);
};

export default Thead;
