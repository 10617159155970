/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

import { Button } from 'components/Buttons';
import { Checkbox } from 'components/Checkboxes/Checkbox';
import { Label } from 'components/Labels';
import { Wizard } from 'components/Wizard';
import { withUI } from 'hocs';
import AccordionGroup from 'pages/contracts/Confirm/components/AccordionGroup/AccordionGroup';
import DNDUpload from 'pages/contracts/Confirm/components/DNDUpload/DNDUpload';
import styles from 'pages/contracts/Confirm/Confirm.module.css';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';

class Confirm extends Component {
	steps = [
		{
			id: 1,
			status: 'complete',
			title: 'Зарегистрируйтесь',
		},
		{
			id: 2,
			status: 'complete',
			title: 'Подтвердите данные автора',
		},
		{
			id: 3,
			status: 'complete',
			title: 'Присоединитесь к услуге',
		},
		{
			id: 3,
			status: 'active',
			title: 'Загрузите сканы контрактов',
		},
	];

	state = {
		isTerritorySelectOpen: false,
		editOptions: null,
		accordions: [
			{
				id: 1,
				title: 'Договор сингл сонг паблишинг',
				content: (
					<div>
						Lorem ipsum dolor sit amet, consectetur adipisicing elit. At, atque
						culpa doloribus ea, enim ex fugit hic inventore officia quibusdam
						quo, repellat unde vero? Consequatur eaque officiis quam ratione
						rem.
					</div>
				),
				open: false,
				complete: true,
			},
			{
				id: 2,
				title: 'Лицензионный договор',
				content: (
					<div>
						Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius error
						fugiat neque ullam? Alias, corporis culpa ducimus enim fugiat in
						ipsa ipsum iure obcaecati omnis similique tempore temporibus tenetur
						ullam?
						<div className={'checkboxes'}>
							<Checkbox
								text={
									'я обладаю полномочиями и не имею препятствий к подписанию контракта'
								}
								className={'checkbox'}
							/>
							<Checkbox
								text={'я согласен с условиями контракта'}
								className={'checkbox'}
							/>
						</div>
					</div>
				),
				open: true,
				complete: false,
			},
		],
	};

	toggleOpenHandler = (id) => {
		const accordions = [...this.state.accordions].map((acc) => ({
			...acc,
			open: acc.id !== id ? false : acc.open,
		}));
		const acc = accordions.find((item) => item.id === id);
		if (acc) {
			acc.open = !acc.open;
		}
		this.setState({ accordions });
	};

	render() {
		// const { isTerritorySelectOpen } = this.state;
		const {
			UIContext: { showModal },
		} = this.props;
		return (
			<React.Fragment>
				<Wizard steps={this.steps} className={styles.Wizard} />
				<div className={styles.Page}>
					<Label variant={'text-30-140'} black className={styles.Label}>
						Подтвердите условия контрактов
					</Label>
					<Label className={styles.Sub} variant={'text-16-140'} black>
						Данный контракт является рамочным — Вы сможете выбрать, какие
						произведения к нему присоединить после загрузки репертуара
					</Label>
					<AccordionGroup
						accordions={this.state.accordions}
						className={styles.Accordion}
						onClick={this.toggleOpenHandler}
					/>

					<div className={styles.Controls}>
						<Button text={'назад к выбору условий'} variant={'link'} />
						<Button
							text={'Загрузить подписанный контракт'}
							variant={'primary'}
							onClick={showModal({
								title: <FormattedMessage id={'rod.contracts.upload.title'} />,
								children: <DNDUpload />,
							})}
						/>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default compose(withUI)(Confirm);
