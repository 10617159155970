// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Icons
import emptyImg from 'images/empty-img.svg';

// Styles
import s from './Cover.module.css';

const CoverBlock = ({
	cover,
	isCoverLoaded,
	handleImageLoad,
	handleImageErrored,
}) => {
	return (
		<div className={s.info__logo}>
			{!cover && !isCoverLoaded && (
				<div className={s.noCover}>
					<img src={emptyImg} className={s.imgNoCover} alt="" />
					<span className={s.noImgTitle}>
						<FormattedMessage id={'rod.release.info.no-cover'} />
					</span>
				</div>
			)}

			{cover && (
				<div className={s.preview}>
					{!isCoverLoaded && <div className={s.preLoader} />}
					<img
						style={{ display: isCoverLoaded ? 'block' : 'none' }}
						className={!isCoverLoaded ? s.loader : s.cover}
						src={cover}
						onLoad={handleImageLoad}
						onError={handleImageErrored}
						alt=""
					/>
				</div>
			)}
		</div>
	);
};

export default CoverBlock;
