// Core
import { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

// Context
import { AuthContext } from 'contexts/AuthContext';

// UI
import { Button } from 'components/Buttons/Button';
import { Label } from 'components/Labels';

// Styles
import styles from './ContractOverduePage.module.css';

const ContractOverduePage = () => {
	const { logout, isAccountLoading, setIsAccountLoading } = useContext(
		AuthContext
	);

	const handleGoToMain = () => {
		logout();
	};

	useEffect(() => {
		if (isAccountLoading) {
			setTimeout(() => {
				setIsAccountLoading(false);
			}, 700);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.page}>
			<div className={styles.form}>
				<div className={styles.title}>
					<Label font="--gilroy-Medium-40" black>
						<FormattedMessage id={'rod.contracts.contract_overdue.header'} />
					</Label>
					<Label font="--gilroy-Medium-24" black>
						<FormattedMessage id={'rod.contracts.contract_overdue.body'} />
					</Label>
				</div>
				<div className={styles.controls}>
					<Button
						className={styles.button}
						text={<FormattedMessage id={'rod.action.to_index_page'} />}
						type={'submit'}
						variant={'primary'}
						onClick={handleGoToMain}
					/>
				</div>
			</div>
		</div>
	);
};

export default ContractOverduePage;
