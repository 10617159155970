// Core
import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

// Utils
import { withRoot, withAuth } from 'hocs';
import { compose } from 'recompose';
import debounce from 'lodash.debounce';
import lscache from 'lscache';
import { setCache } from 'services/cacheHelper/setCache';
import { getJSONParse } from 'utils';

// UI
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Input, SelectCustom } from 'material-design/MuiStyled/MuiStyled';
import { CheckboxBlack } from 'material-design/MuiStyled/MuiStyled';
import { TextFieldInput } from 'material-design/MuiStyled/MuiStyled';
import CheckedSceleton from './MuiMultipleCheckedAnimation';
import { Label } from 'components/Labels';

// Styles
import s from './MuiMultipleCheckedTracks.module.css';

const MultipleSelectCheckmarks = (props) => {
	const [tracks, setTracks] = useState([]);
	const [isAllChecked, setIsAllChecked] = useState(false);
	const [selectedData, setSelectedData] = useState([]);
	const [checkedTracks, setCheckedTracks] = useState([]);
	const [checkedIds, setCheckedIds] = useState([]);
	const [isBtnOnlyShowId, setIsBtnOnlyShowId] = useState('');
	const [searchValue, setSearchValue] = useState(null);
	const [isShowSceleton, setIsShowSceleton] = useState(false);
	const [page] = useState(1);
	const [limit] = useState(100);
	const [isReqLive] = useState(false);

	const {
		rootContext: {
			getAccountStatisticAllTracks,
			getAccountStatisticAuthorAllTracks,
		},
		dateFrom,
		dateTo,
		accountId,
		isAdmin,
		chartMode,
	} = props;

	const ref = React.useRef(false);
	const allTracksRequest =
		chartMode === 'compo' && !props.releaseId
			? getAccountStatisticAuthorAllTracks
			: getAccountStatisticAllTracks;

	useEffect(() => {
		setIsShowSceleton(true);
		allTracksRequest(
			isAdmin,
			accountId,
			searchValue ? searchValue : '',
			page,
			limit,
			dateFrom,
			dateTo,
			props.releaseId ? [props.releaseId] : []
		).then((res) => {
			res = res.data.data;
			if (checkedIds.length) {
				res.recordings.map((item) => {
					if (checkedIds.includes(item.id)) {
						item.checked = true;
					} else {
						item.checked = false;
					}
					return item;
				});
			} else {
				res.recordings.map((item) => (item.checked = true));
			}
			setTracks([
				{ title: 'All', checked: !checkedIds.length },
				...res.recordings,
			]);
			setIsAllChecked(!checkedIds.length);
			setIsShowSceleton(false);
			if (!props.releaseId) {
				props.setLoading(false);
			}
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dateFrom, dateTo, props.release, chartMode]);

	useEffect(() => {
		const data = tracks.filter((item) => item.checked);
		setSelectedData(data);
		const trackIds = data
			.filter((item) => item.id || item.id === 0)
			.map((item) => item.id);
		const ids = checkedIds.length ? checkedIds : trackIds;
		props.handleCheckedTracks(ids, isAllChecked);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tracks, checkedIds]);

	useEffect(() => {
		if (selectedData.length > 0) {
			ref.current = true;
		}
	}, [selectedData]);

	useEffect(() => {
		if (searchValue && searchValue.length >= 3) {
			setIsShowSceleton(true);
			allTracksRequest(
				isAdmin,
				accountId,
				searchValue,
				page,
				limit,
				dateFrom,
				dateTo,
				props.releaseId ? [props.releaseId] : []
			)
				.then((res) => {
					res = res.data.data;
					const data = res.recordings.map((item) => {
						if (checkedIds.includes(item.id)) {
							item.checked = true;
						} else {
							item.checked = false;
						}
						return item;
					});
					setIsAllChecked(false);
					if (data.length) {
						setTracks([{ title: 'All', checked: false }, ...data]);
					} else {
						setTracks(data);
					}
					setIsShowSceleton(false);
				})
				.catch((err) => console.error('error', err))
				.finally(() => setIsShowSceleton(false));
		} else if (searchValue === '') {
			const allTracksForCheckedHash = `${page}*${limit}
      		*${dateFrom}*${dateTo}*allTracksForChecked*${accountId}`;
			const allTracksForCheckedReqFromCash = lscache.get(
				allTracksForCheckedHash
			);

			if (allTracksForCheckedReqFromCash && !isReqLive) {
				const data = allTracksForCheckedReqFromCash.recordings.map((item) => {
					checkedIds.includes(item.id)
						? (item.checked = true)
						: (item.checked = false);
					return item;
				});

				setTracks([{ title: 'All', checked: false }, ...data]);
				setIsAllChecked(false);
			} else {
				setIsShowSceleton(true);
				allTracksRequest(
					isAdmin,
					accountId,
					searchValue,
					page,
					limit,
					dateFrom,
					dateTo,
					props.releaseId ? [props.releaseId] : []
				)
					.then((res) => {
						res = res.data.data;
						if (isReqLive) {
							const data = res.recordings.map((item) => {
								checkedIds.includes(item.id)
									? (item.checked = true)
									: (item.checked = false);
								return item;
							});
							setTracks([{ title: 'All', checked: false }, ...data]);
							setIsAllChecked(false);
							setIsShowSceleton(false);
						} else {
							setCache(
								'statisticTrackForSelectCache',
								allTracksForCheckedHash,
								res
							);
						}

						const data = res.recordings.map((item) => {
							checkedIds.includes(item.id)
								? (item.checked = true)
								: (item.checked = false);
							return item;
						});
						setTracks([{ title: 'All', checked: false }, ...data]);
						setIsAllChecked(false);
						setIsShowSceleton(false);
					})
					.catch((err) => console.info('error', err))
					.finally(() => setIsShowSceleton(false));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchValue]);

	const handleChange = (e) => {
		const {
			target: { value },
		} = e;
		setSearchValue(value);
		props.setTrackSearchValue && props.setTrackSearchValue(value);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedChangeHandler = useCallback(debounce(handleChange, 500), []);

	const isCheckedIdsUsed = (track) => {
		if (track.title !== 'All') {
			if (checkedIds.includes(track.id)) {
				const trackData = checkedTracks.filter((item) => item.id !== track.id);
				const IdsData = checkedIds.filter((item) => item !== track.id);
				setCheckedTracks(trackData);
				setCheckedIds(IdsData);
			} else if (isAllChecked) {
				let ids = [];
				const trackData = tracks.filter(
					(item) => item.id !== track.id && item.title !== 'All'
				);
				tracks.forEach((item) => {
					if (item.id && item.id !== track.id) ids.push(item.id);
				});

				setCheckedTracks(trackData);
				setCheckedIds(ids);
			} else {
				setCheckedIds((prev) => [...prev, track.id]);
				setCheckedTracks((prev) => [...prev, track]);
			}
		} else if (track.title === 'All') {
			setCheckedIds([]);
			setCheckedTracks([]);
		}
	};

	const handleChaсked = (track) => {
		isCheckedIdsUsed(track);
		if (track.title === 'All') {
			if (track.checked) {
				track.checked = false;
				setIsAllChecked(false);
			} else {
				track.checked = true;
				setIsAllChecked(true);
			}

			const data = tracks.map((item) => {
				item.checked = track.checked;
				return item;
			});

			setTracks(data);
		} else {
			const data = tracks.map((item) => {
				if (item.title === 'All') {
					item.checked = false;
					setIsAllChecked(false);
				}
				if (item.id === track.id) {
					item.checked ? (item.checked = false) : (item.checked = true);
				}
				return item;
			});
			setTracks(data);
		}
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedCheckedHandler = useCallback(debounce(handleChaсked, 500), [
		tracks,
	]);

	const handleEarlyChaсked = (track) => {
		const chacked = checkedTracks.map((item) => {
			if (item.id === track.id) {
				item.checked ? (item.checked = false) : (item.checked = true);
			}
			return item;
		});

		const data = chacked.filter((item) => item.checked);
		const IdsData = checkedIds.filter((item) => item !== track.id);
		setCheckedIds(IdsData);
		setCheckedTracks(data);
	};

	const handleMouseOver = (index) => {
		if (index) {
			setIsBtnOnlyShowId(index);
		}
	};

	const handleMouseLeave = () => {
		setIsBtnOnlyShowId('');
	};

	const handleClickOnly = (track) => {
		const data = tracks.map((item) => {
			item.title === track.title
				? (item.checked = true)
				: (item.checked = false);

			return item;
		});

		setIsAllChecked(false);
		setTracks(data);
		setCheckedTracks([track]);
		setCheckedIds([track.id]);

		if (props.checkedTopTrack) {
			props.handleCheckedTopTrack(track);
		}
	};

	useEffect(() => {
		if (props.checkedTopTrack) {
			props.checkedTopTrack.title = props.checkedTopTrack.raw_title;
			props.checkedTopTrack.checked = true;
			handleClickOnly(props.checkedTopTrack);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.checkedTopTrack]);

	return (
		<>
			<div>
				<FormControl
					variant="standard"
					sx={{ m: 1 }}
					className={s.formControl}
					error={!selectedData.length && ref.current && !checkedIds.length}
				>
					<Input id="demo-multiple-checkbox-label">
						<FormattedMessage id={'rod.statistic.tracks'} />
					</Input>
					<SelectCustom
						labelId="demo-multiple-checkbox-label"
						id="demo-multiple-checkbox"
						multiple
						MenuProps={{
							style: { zIndex: 99999999999 },
						}}
						value={
							tracks.length === 10 || checkedTracks.length
								? checkedTracks
								: selectedData
						}
						renderValue={(selected) => {
							if (isShowSceleton) return '';
							if (selected[0]?.title === 'All' && tracks[0]?.title === 'All') {
								if (selected.length === 2) {
									return (
										<>
											<p
												title={`${selected[1].title} - ${getJSONParse(
													selected[1].performers
												)}`}
												className={s.selected_single}
											>{`${selected[1].title} - ${getJSONParse(
												selected[1].performers
											)}`}</p>
										</>
									);
								}
								return (
									<>
										<FormattedMessage id={'rod.statistic.tracks-selected'} />
										:&nbsp;
										{selected.length - 1 + '/' + (tracks.length - 1)}
										&nbsp;
										<FormattedMessage
											id={'rod.statistic.tracks-selected-tracks'}
										/>
									</>
								);
							}

							if (selected.length === 1) {
								return (
									<>
										<p
											title={`${selected[0].title} - ${getJSONParse(
												selected[0].performers
											)}`}
											className={s.selected_single}
										>{`${selected[0].title} - ${getJSONParse(
											selected[0].performers
										)}`}</p>
									</>
								);
							}

							if (checkedIds.length && !tracks.length) {
								return (
									<>
										<FormattedMessage id={'rod.statistic.tracks-selected'} />
										:&nbsp;
										{checkedIds.length + '/' + tracks.length}
										&nbsp;
										<FormattedMessage
											id={'rod.statistic.tracks-selected-tracks'}
										/>
									</>
								);
							}

							if (checkedIds.length) {
								return tracks[0]?.title === 'All' ? (
									<>
										<FormattedMessage id={'rod.statistic.tracks-selected'} />
										:&nbsp;
										{checkedIds.length + '/' + (tracks.length - 1)}
										&nbsp;
										<FormattedMessage
											id={'rod.statistic.tracks-selected-tracks'}
										/>
									</>
								) : (
									<>
										<FormattedMessage id={'rod.statistic.tracks-selected'} />
										:&nbsp;
										{checkedIds.length + '/' + tracks.length}
										&nbsp;
										<FormattedMessage
											id={'rod.statistic.tracks-selected-tracks'}
										/>
									</>
								);
							}
						}}
						className={s.selectCustom}
					>
						<FormattedMessage id={'rod.statistic.search-title'}>
							{(label) => (
								<TextFieldInput
									className={s.search}
									autoFocus={false}
									initialValue={searchValue ? searchValue : ''}
									onChange={debouncedChangeHandler}
									label={label}
									variant="standard"
								/>
							)}
						</FormattedMessage>
						<div className={s.wrapper}>
							{tracks.length > 0 && !isShowSceleton
								? tracks.map((track, index) => (
										<MenuItem
											className={s.menuItem}
											key={index}
											title={track.title}
											value={track.title}
											//TODO- Restore IT !
											onMouseOver={() => handleMouseOver(index)}
											onMouseLeave={handleMouseLeave}
										>
											<CheckboxBlack
												checked={track.checked}
												onChange={() => debouncedCheckedHandler(track)}
											/>
											<Label
												onClick={() => debouncedCheckedHandler(track)}
												className={s.menuItem__Label}
											>
												{track.title === 'All' ? (
													<FormattedMessage id={'rod.statistic.search-all'} />
												) : (
													`${track.title} - ${getJSONParse(track.performers)}`
												)}
											</Label>

											{isBtnOnlyShowId && isBtnOnlyShowId === index && (
												<button
													className={s.btnOnly}
													onClick={() => handleClickOnly(track)}
												>
													Only
												</button>
											)}
										</MenuItem>
								  ))
								: isShowSceleton && <CheckedSceleton />}
							{!tracks.length && !isShowSceleton && !checkedTracks.length && (
								<p className={s.notFound}>
									<FormattedMessage id={'rod.statistic.search-not-found'} />
								</p>
							)}
							{!tracks.length && checkedTracks.length > 0 && (
								<p className={s.notFound}>
									<FormattedMessage id={'rod.statistic.checked-tracks'} />
								</p>
							)}
							<div>
								{!tracks.length && checkedTracks.length > 0 && !isShowSceleton && (
									<>
										{checkedTracks.map((track, index) => (
											<MenuItem
												className={s.menuItem}
												key={index}
												value={track.title}
												//TODO- Restore IT !
												onMouseOver={() => handleMouseOver(index)}
												onMouseLeave={handleMouseLeave}
											>
												<CheckboxBlack
													checked={track.checked}
													onChange={() => handleEarlyChaсked(track)}
												/>

												<Label onClick={() => debouncedCheckedHandler(track)}>
													{track.title === 'All' ? (
														<FormattedMessage id={'rod.statistic.search-all'} />
													) : (
														track.title
													)}
												</Label>

												{isBtnOnlyShowId && isBtnOnlyShowId === index && (
													<button
														className={s.btnOnly}
														onClick={() => handleClickOnly(track)}
													>
														Only
													</button>
												)}
											</MenuItem>
										))}
									</>
								)}
							</div>
						</div>
					</SelectCustom>
				</FormControl>
			</div>
		</>
	);
};

export default compose(withRoot, withAuth)(MultipleSelectCheckmarks);
