/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 09/12/2020, 18:26
 */

import { Label } from 'components/Labels/Label';
import React from 'react';

// import PropTypes from 'prop-types';
import styles from './Terms.module.css';

const Terms = (props) => {
	return (
		<div className={styles.Page}>
			<div className="body">
				<Label
					text={
						'Terms'
						//<FormattedHTMLMessage id={'rod.repertoire.banner.header'}/>
					}
					className="header"
					font="--gilroy-Medium-24"
					black
				/>
				<Label
					text={
						'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur delectus dolor explicabo nisi quibusdam quisquam. Doloremque illum ipsa odio temporibus. A animi ea eligendi illum minima recusandae saepe soluta voluptas!'
						// <FormattedHTMLMessage id={'rod.repertoire.banner.body'}/>
					}
					font="--gilroy-Medium-16"
					black
				/>
			</div>
		</div>
	);
};

Terms.propTypes = {};

export default Terms;
