import React from 'react';
import {
	CheckboxMyShares,
	FormControlLabelCheckbox,
} from 'material-design/MuiStyled/MuiStyled';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

const MuiCheckbox = ({ label, onChange, className, value, checked }) => {
	return (
		<FormControlLabelCheckbox
			className={className}
			control={
				<CheckboxMyShares
					checked={value}
					icon={<RadioButtonUncheckedIcon />}
					checkedIcon={<RadioButtonCheckedIcon />}
					onChange={onChange}
				/>
			}
			label={label}
		/>
	);
};

export default MuiCheckbox;
