// Core
import RichTextEditor from 'react-rte';

export const initialData = {
	article_id: '',
	title: '',
	text: RichTextEditor.createEmptyValue(),
	pic: null,
	pic_identifier: null,
	pic_preview: null,
	section_selected: {},
	sections: [],
};
