/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

import classnames from 'classnames';
import { Label } from 'components/Labels/Label';
import React, { Component } from 'react';
import { Col, Row } from 'react-grid-system';
import styles from './List.module.css';

class List extends Component {

	state = {
		contracts: [
			{
				title: 'Издательский контракт',
				number: '№4230-2847',
				licensor: 'ООО “рога и копыта компани интернешенел инкорпорейтед лимитед эдишен блэк меджик саунд систем”',
				repertoire: 'произведение, фонограмма, лирика',
				expType: 'цифровая, публичное исполнение, синхронизация',
				territories: 'весь мир, кроме стран Россия, Уганда',
				date: '31.10.2019 — 31.10.2022',
			}, {
				title: 'Издательский контракт',
				number: '№4230-2848',
				licensor: 'ООО “рога и копыта компани интернешенел инкорпорейтед лимитед эдишен блэк меджик саунд систем”',
				repertoire: 'произведение, фонограмма, лирика',
				expType: 'цифровая, публичное исполнение, синхронизация',
				territories: 'весь мир, кроме стран Россия, Уганда',
				date: '31.10.2019 — 31.10.2023',
			},
		],
		selectedContractIndex: 0,
	};

	selectHandler = (index) => () => {
		this.setState({ selectedContractIndex: index });
	};

	render() {
		return (
			<div className={styles.Page}>
				<Row>
					<Col md={4} offset={{ md: 1 }}>
						<Label text={'выберите контракт:'} variant={'text-30-140'} className={styles.Title}/>
						<div className={styles.List}>
							{this.state.contracts.map((con, index) => (
								<div onClick={this.selectHandler(index)}
										 className={classnames(styles.ListItem, { [styles.Selected]: this.state.selectedContractIndex === index })}
								>{con.title}<br/>{con.number}</div>))}
						</div>
					</Col>
					<Col md={4} offset={{ md: 2 }}>
						<Label text={'условия:'} variant={'text-30-140'} className={styles.Title}/>
						<div className={styles.Info}>
							<div className={styles.InfoBlock}>
								<div className={styles.InfoFieldTitle}>лицензиар:</div>
								<div className={styles.InfoField}>
									{this.state.contracts[this.state.selectedContractIndex].licensor}
								</div>
							</div>
							<div className={styles.InfoBlock}>
								<div className={styles.InfoFieldTitle}>репертуар:</div>
								<div className={styles.InfoField}>
									{this.state.contracts[this.state.selectedContractIndex].repertoire}
								</div>
							</div>
							<div className={styles.InfoBlock}>
								<div className={styles.InfoFieldTitle}>тип эксплуатации:</div>
								<div className={styles.InfoField}>
									{this.state.contracts[this.state.selectedContractIndex].expType}
								</div>
							</div>
							<div className={styles.InfoBlock}>
								<div className={styles.InfoFieldTitle}>территории:</div>
								<div className={styles.InfoField}>
									{this.state.contracts[this.state.selectedContractIndex].territories}
								</div>
							</div>
							<div className={styles.InfoBlock}>
								<div className={styles.InfoFieldTitle}>срок действия:</div>
								<div className={styles.InfoField}>
									{this.state.contracts[this.state.selectedContractIndex].date}
								</div>
							</div>

						</div>
					</Col>
				</Row>
			</div>
		);
	}
}

export default List;
