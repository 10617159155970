// Core
import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

// Styles
import styles from '../AdminAccountDrafts.module.css';

const TableHeader = () => {
	return (
		<div className={`${styles.repertoire__songTitle} ${styles.song__table}`}>
			<FormattedHTMLMessage id={'rod.for_all.title'}></FormattedHTMLMessage>
			<FormattedHTMLMessage id={'rod.for_all.performer'}></FormattedHTMLMessage>
			<FormattedHTMLMessage id={'rod.for_all.type'}></FormattedHTMLMessage>
			<FormattedHTMLMessage id={'rod.for_all.status'}></FormattedHTMLMessage>
			<FormattedHTMLMessage id={'rod.for_all.date'}></FormattedHTMLMessage>
		</div>
	);
};

export default TableHeader;
