export const showGenres = (allGenres, genres, lang) => {
	if (genres) {
		const filteredArrays = genres
			.map((item) => {
				return allGenres.filter((genre) => item === genre.id || item === genre.code);
			})
			.flat();

		const filteredGenres = filteredArrays.map((item) =>
			lang === 'en' ? item.title_en : item.title_ru
		);
		
		return filteredGenres.join(', ');
	}

	return '-';
};
