export const getGenres = (lang, allGgenres, genres) => {
	const releaseGenres = genres
		.map((code) => allGgenres.filter((item) => item.code === code))
		.flat();

	if (lang === 'ru') {
		return releaseGenres.map((genre) => genre.title_ru).join(', ');
	} else {
		return releaseGenres.map((genre) => genre.title_en).join(', ');
	}
};
