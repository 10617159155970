import React, { useEffect, useRef, useState } from 'react';

import s from './Pagination.module.css';

import usePagination from '@mui/material/usePagination';

export default function Pagination({
	page,
	total,
	paginate,
	items_on_page = 10,
}) {
	const { items } = usePagination({
		count: Math.ceil(total / items_on_page),
		onChange: (_, num) => {
			paginate(num);
		},
		page: page,
	});

	const valueStartRef = useRef(null);
	const valueEndRef = useRef(null);

	const [valueStart, setValueStart] = useState('');
	const [showValueStart, setShowValueStart] = useState(false);

	const [valueEnd, setValueEnd] = useState('');
	const [showValueEnd, setShowValueEnd] = useState(false);

	const pageCalc = (value) => {
		if (Math.ceil(total / items_on_page) <= value)
			return Math.ceil(total / items_on_page);
		else if (value.length !== 0 && value <= 0) return 1;
		else return parseInt(value);
	};

	const startSelectPage = (e) => {
		e.preventDefault();
		valueStartRef.current[0].blur();
		valueStartRef.current.classList.remove('form__focus');
		const selectNum = pageCalc(valueStartRef.current[0].value);
		if (selectNum) {
			paginate(selectNum);
		}
		setValueStart('');
	};
	const endSelectPage = (e) => {
		e.preventDefault();
		valueEndRef.current[0].blur();
		valueEndRef.current.classList.remove('form__focus');
		const selectNum = pageCalc(valueEndRef.current[0].value);
		if (selectNum) {
			paginate(selectNum);
		}
		setValueEnd('');
	};

	const pageSelectStart = (e) => {
		e.preventDefault();
		setValueStart(' ');
		setShowValueStart(true);
		valueStartRef.current.classList.add('form__focus');
		valueStartRef.current[0].focus();
	};
	const pageSelectEnd = (e) => {
		e.preventDefault();
		setValueEnd(' ');
		setShowValueEnd(true);
		valueEndRef.current.classList.add('form__focus');
		valueEndRef.current[0].focus();
	};

	const onCloseValueStart = () => {
		setShowValueStart(false);
	};

	const onCloseValueEnd = () => {
		setShowValueEnd(false);
	};

	useEffect(() => {
		if (!showValueStart) return;

		const handleShowValueStart = (e) => {
			if (!valueStartRef.current) return;
			if (!valueStartRef.current.contains(e.target)) {
				valueStartRef.current.classList.remove('form__focus');
				setValueStart('');
				onCloseValueStart();
			}
		};

		document.addEventListener('click', handleShowValueStart);

		return () => {
			document.removeEventListener('click', handleShowValueStart);
		};
	}, [showValueStart, onCloseValueStart]);

	useEffect(() => {
		if (!showValueEnd) return;

		const handleShowValueEnd = (e) => {
			if (!valueEndRef.current) return;
			if (!valueEndRef.current.contains(e.target)) {
				valueEndRef.current.classList.remove('form__focus');
				setValueEnd('');
				onCloseValueEnd();
			}
		};

		document.addEventListener('click', handleShowValueEnd);

		return () => {
			document.removeEventListener('click', handleShowValueEnd);
		};
	}, [showValueEnd, onCloseValueEnd]);

	return (
		<ul className={s.repertoire__songItemsPaggination}>
			{items.map(({ page, type, selected, ...item }, index) => {
				let children = null;
				if (type === 'start-ellipsis') {
					children = (
						<form
							ref={valueStartRef}
							key={index}
							onSubmit={startSelectPage}
							onClick={(e) => pageSelectStart(e)}
							className={s.selectPage}
						>
							<span>{valueStart.length !== 0 ? valueStart : '...'}</span>
							<input
								value={valueStart}
								onChange={(e) => setValueStart(e.target.value)}
								key={index}
								placeholder="…"
								maxlength="6"
							/>
						</form>
					);
				} else if (type === 'end-ellipsis') {
					children = (
						<form
							ref={valueEndRef}
							key={index}
							onSubmit={endSelectPage}
							onClick={(e) => pageSelectEnd(e)}
							className={s.selectPage}
						>
							<span>{valueEnd.length !== 0 ? valueEnd : '...'}</span>
							<input
								value={valueEnd}
								onChange={(e) => setValueEnd(e.target.value)}
								key={index}
								placeholder="…"
								maxlength="6"
							/>
						</form>
					);
				} else if (type === 'page') {
					children = (
						<button
							key={index}
							type="button"
							className={selected ? s.pagginationActive : undefined}
							{...item}
						>
							{page}
						</button>
					);
				} else {
					children =
						type !== 'next' ? (
							<button
								key={index}
								type="button"
								{...item}
								className={s.paggination__arrowLeft}
							>
								<svg
									width="10"
									height="17"
									viewBox="0 0 10 17"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M9.25685 0.771497L8.48546 0.000107287L0.771592 7.71397L0.771485 7.71387L9.59588e-05 8.48526L0.000203245 8.48536L0.00017762 8.48539L0.771567 9.25678L0.771592 9.25675L8.48538 16.9705L9.25677 16.1991L1.54298 8.48536L9.25685 0.771497Z"
										fill="#1E1E1E"
									/>
								</svg>
							</button>
						) : (
							<button
								key={index}
								type="button"
								{...item}
								className={s.paggination__arrowRight}
							>
								<svg
									width="10"
									height="18"
									viewBox="0 0 10 18"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M0.713854 16.7832L1.48524 17.5546L9.19911 9.84071L9.19922 9.84082L9.97061 9.06943L9.9705 9.06932L9.97052 9.0693L9.19913 8.29791L9.19911 8.29793L1.48533 0.584149L0.713937 1.35554L8.42772 9.06932L0.713854 16.7832Z"
										fill="#1E1E1E"
									/>
								</svg>
							</button>
						);
				}
				return children;
			})}
		</ul>
	);
}
