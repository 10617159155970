// Core
import { useState, useEffect, useContext } from 'react';
import { FormattedMessage } from 'react-intl';

// Context
import { LangContext } from 'contexts/LangContext';

// UI
import { withUI, withRoot, withAuth } from 'hocs';
import { compose } from 'recompose';
import { Loading } from 'components';
import { Col, Row } from 'components/Grid';
import Flag from 'components/Flag';
import check from 'images/check.svg';
import worldwide from 'images/worldwide.svg';
import { accounts } from 'services';

// Data
import contractsTitles from './data/contractsTitles.json';

// Utils
import {
	prepareOutletsExcludedData,
	prepareOutletsIncludedData,
} from './helpers/prepareOutletsData';
import { makeReportSchedule } from './helpers/makeReportSchedule';
import { getRightTypesCodes } from './helpers/getRightTypesCodes';
import { getContractTypeNumberForTableMarkup } from './helpers/getContractTypeNumberForTableMarkup';

// Styles
import styles from './ContractDataMarkup.module.css';

const ContractDataMarkup = (props) => {
	const { lang } = useContext(LangContext);

	const [loading, setLoading] = useState(true);
	// eslint-disable-next-line no-unused-vars
	const [outlets, setOutlets] = useState();
	// eslint-disable-next-line no-unused-vars
	const [outletsIncludedData, setOutletsIncludedData] = useState();
	// eslint-disable-next-line no-unused-vars
	const [outletsExcludedData, setOutletsExcludedData] = useState();
	const [exploitationTypes, setExploitationTypes] = useState();
	const [JSONShares, setJSONShares] = useState();
	const [rightTypes, setRightTypes] = useState();

	const [
		cotractExploitationTypesNumber,
		setCotractExploitationTypesNumber,
	] = useState();
	const [cotractRightTypesNumber, setCotractRightTypesNumber] = useState();
	// eslint-disable-next-line no-unused-vars
	const [contractProductsNumber, setContractProductsNumber] = useState();

	const {
		contractData,
		contractCondition,
		contractConditionDetails,
		rootContext: { countries, getExploitationTypes },
	} = props;
	const CONTRACT_TYPE = contractData.contract_type;

	useEffect(() => {
		parseJSON();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const parseJSON = () => {
		const jsonData = JSON.parse(JSON.stringify(contractsTitles));
		setJSONShares(jsonData.shares);
	};

	useEffect(() => {
		if (contractConditionDetails) {
			const getOutletsFromBack = accounts.getOutlets(lang);
			const getExploitationTypesFromBack = getExploitationTypes();
			Promise.all([getOutletsFromBack, getExploitationTypesFromBack]).then(
				(res) => {
					setOutlets(res[0].data.data);
					setOutletsIncludedData(
						prepareOutletsIncludedData(
							res[0].data.data,
							contractConditionDetails
						)
					);
					setOutletsExcludedData(
						prepareOutletsExcludedData(
							res[0].data.data,
							contractConditionDetails
						)
					);
					setExploitationTypes(res[1].data);
					setRightTypes(getRightTypesCodes(res[1].data));
					setLoading(false);
					getContractTypeNumberForTableMarkup(
						CONTRACT_TYPE,
						setContractProductsNumber,
						setCotractExploitationTypesNumber,
						setCotractRightTypesNumber
					);
				}
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang]);

	return loading ? (
		<Loading />
	) : (
		contractData && (
			<div className={styles.mainWrapper}>
				<div className={styles.title}>
					{contractData.title}
					{` > ${contractConditionDetails.title}`}

					<div className={styles.title_sub}>
						<FormattedMessage
							id={
								CONTRACT_TYPE === 'license'
									? 'rod.account.create.license-agreement'
									: CONTRACT_TYPE === 'sub_publishing'
									? 'rod.contracts.sub'
									: 'rod.contracts.publishing'
							}
						/>
					</div>
				</div>
				<div className={styles.info}>
					<Row style={{ margin: '0' }}>
						<Col sm={4} className={styles.infoNameCol}>
							<ul className={styles.infoNames}>
								{contractData.id && (
									<li>
										<FormattedMessage id={'rod.contracts.number'} />
									</li>
								)}
								<li>
									<FormattedMessage id={'rod.contracts.term'} />
								</li>
								<li>
									<FormattedMessage id={'rod.contracts.start_collection'} />
								</li>
								<li>
									<FormattedMessage id={'rod.contracts.end_self_off_period'} />
								</li>
								{contractCondition.prolongation_length &&
									contractCondition.prolongation_length > 0 && (
										<li>
											<FormattedMessage
												id={'rod.contracts.automatic_prolongation'}
											/>
										</li>
									)}
								<li>
									<FormattedMessage id={'rod.contracts.rights_transfer'} />
								</li>
								{contractData.report_schedule && (
									<li>
										<FormattedMessage id={'rod.contracts.reports_transfer'} />
									</li>
								)}
								{contractConditionDetails.countries.including &&
									contractConditionDetails.countries.including.length && (
										<li>
											<FormattedMessage id={'rod.field.territories.included'} />
										</li>
									)}
								{contractConditionDetails.countries.excluding &&
									contractConditionDetails.countries.excluding.length && (
										<li>
											<FormattedMessage
												id={'rod.contracts.territories_excluded'}
											/>
										</li>
									)}
							</ul>
						</Col>
						<Col sm={5} className={styles.infoValueCol}>
							<ul className={styles.infoValues}>
								{contractData.id && <li>{contractData.id}</li>}

								<li>
									{contractCondition.begins
										? contractCondition.begins.replace(
												/(\d{4})-(\d{2})-(\d{2})/g,
												`$3.$2.$1`
										  )
										: '\u2212'}
									&nbsp;{'\u2212'}&nbsp;
									{contractCondition.ends
										? contractCondition.ends.replace(
												/(\d{4})-(\d{2})-(\d{2})/g,
												`$3.$2.$1`
										  )
										: '\u2212'}
								</li>
								<li>
									{contractCondition.collection_begins
										? contractCondition.collection_begins.replace(
												/(\d{4})-(\d{2})-(\d{2})/g,
												`$3.$2.$1`
										  )
										: '\u2212'}
								</li>
								<li>
									{contractCondition.collection_ends
										? contractCondition.collection_ends.replace(
												/(\d{4})-(\d{2})-(\d{2})/g,
												`$3.$2.$1`
										  )
										: '\u2212'}
								</li>

								{contractCondition.prolongation_length &&
									contractCondition.prolongation_length > 0 && (
										<li>
											{lang === 'ru' ? 'на ' : 'for '}
											{contractCondition.prolongation_length / 12}{' '}
											{lang === 'ru'
												? contractCondition.prolongation_length / 12 > 1
													? 'года '
													: 'год'
												: contractCondition.prolongation_length / 12 > 1
												? 'years'
												: 'year'}
										</li>
									)}

								<li>
									{contractCondition.is_specific ? (
										<FormattedMessage id={'rod.contracts.specific'} />
									) : contractCondition.is_exlusive ? (
										<FormattedMessage id={'rod.contracts.exclusive'} />
									) : (
										'\u2212'
									)}
								</li>

								{contractData.report_schedule && (
									<li>
										{makeReportSchedule(contractData.report_schedule, lang)}
									</li>
								)}

								{contractConditionDetails.countries.including &&
									contractConditionDetails.countries.including.length && (
										<li>
											<div className={styles.flagsBox}>
												{contractConditionDetails.countries.including.map(
													(territory) => {
														const code = countries.find(
															(country) => country.id === territory
														);

														return territory === 17 ? (
															<div className={styles.country}>
																<img src={worldwide} alt="" />
																<span
																	style={{
																		textTransform: 'lowercase',
																	}}
																>
																	<FormattedMessage
																		id={'rod.contracts.whole_world'}
																	/>
																</span>
															</div>
														) : code ? (
															<div className={styles.country}>
																<Flag
																	code={code.code}
																	className={styles.flag}
																/>
																<span>
																	{lang === 'en'
																		? code.title_en
																		: code.title_ru}
																</span>
															</div>
														) : (
															<></>
														);
													}
												)}
											</div>
										</li>
									)}

								{contractConditionDetails.countries.excluding &&
									contractConditionDetails.countries.excluding.length && (
										<li>
											<div className={styles.flagsBox}>
												{contractConditionDetails.countries.excluding.map(
													(territory) => {
														const code = countries.find(
															(country) => country.id === territory
														);

														return territory === 17 ? (
															<div className={styles.country}>
																<img src={worldwide} alt="" />
																<span
																	style={{
																		textTransform: 'lowercase',
																	}}
																>
																	<FormattedMessage
																		id={'rod.contracts.whole_world'}
																	/>
																</span>
															</div>
														) : code ? (
															<div className={styles.country}>
																<Flag
																	code={code.code}
																	className={styles.flag}
																/>
																<span>
																	{lang === 'en'
																		? code.title_en
																		: code.title_ru}
																</span>
															</div>
														) : (
															<></>
														);
													}
												)}
											</div>
										</li>
									)}
							</ul>
						</Col>
					</Row>
				</div>

				{exploitationTypes &&
					contractConditionDetails.exploitation_types &&
					contractConditionDetails.exploitation_types.including &&
					contractConditionDetails.exploitation_types.including.length > 0 && (
						<div className={styles.rightsTable}>
							<Row className={styles.rightsTableTitle}>
								<Col sm={3}>
									{/* тайп номера 1,2,3,4 */}
									<FormattedMessage id={'rod.contracts.rights_types_group'} />
								</Col>
								{CONTRACT_TYPE !== 'sub_publishing' &&
									contractConditionDetails.exploitation_types.including[0]
										.price && (
										<Col sm={2}>
											{/* тайп номера 1,2,3,4 */}
											<FormattedMessage id={'rod.contracts.price'} />
										</Col>
									)}
								{cotractExploitationTypesNumber === 4 && (
									<Col sm={2}>
										{/* тайп номера 4 - * collect_licensor_share Сбор доли лицензиара / Collect licensor share*/}
										<FormattedMessage
											id={'rod.contracts.collect_licensor_share'}
										/>
									</Col>
								)}

								{[2, 3].includes(cotractExploitationTypesNumber) && (
									<Col sm={2}>
										{/* тайп номера 2,3 - * collect_assignor_share  Сбор доли автора / Collect author's share*/}
										<FormattedMessage
											id={'rod.contracts.collect_assignor_share'}
										/>
									</Col>
								)}
								{[2, 3].includes(cotractExploitationTypesNumber) && (
									<Col sm={2}>
										{/* тайп номера 2,3 - * collect_author_share_at_source Выплата доли автора от источника / Collect author revenue at source*/}
										<FormattedMessage
											id={'rod.contracts.collect_author_share_at_source'}
										/>
									</Col>
								)}

								{[1, 3, 4].includes(cotractExploitationTypesNumber) && (
									<Col sm={2}>
										{/* тайп номера 1,3,4 */}
										{JSONShares[CONTRACT_TYPE] &&
											JSONShares[CONTRACT_TYPE].licensor[lang]}
									</Col>
								)}
								<Col sm={2}>
									{/* тайп номера 1,2,3,4 */}
									{JSONShares[CONTRACT_TYPE] &&
										JSONShares[CONTRACT_TYPE].licensee[lang]}
								</Col>
							</Row>

							<Row className={styles.rightsTableValues}>
								<Col sm={3}>
									<ul className={styles.rightsTableValuesLeftSide}>
										<ul className={styles.rightsTableValuesRightSide}>
											{contractConditionDetails.exploitation_types.including.map(
												(type, index) => (
													<li key={type.code + index}>
														{type.code &&
															exploitationTypes.find(
																(expl) => expl.code === type.code
															)[lang === 'ru' ? 'title_ru' : 'title_en']}
													</li>
												)
											)}
										</ul>
									</ul>
								</Col>
								{CONTRACT_TYPE !== 'sub_publishing' &&
									contractConditionDetails.exploitation_types.including[0]
										.price && (
										<Col sm={2}>
											<ul className={styles.rightsTableValuesRightSide}>
												{contractConditionDetails.exploitation_types.including.map(
													(type, index) => (
														<li>{type.price ? type.price : '\u2212'}</li>
													)
												)}
											</ul>
										</Col>
									)}

								{cotractExploitationTypesNumber === 4 && (
									<Col sm={2}>
										<ul className={styles.rightsTableValuesRightSide}>
											{contractConditionDetails.exploitation_types.including.map(
												(type, index) => (
													<li>
														{type.licensor_share_passed ? (
															<img src={check} alt="" />
														) : (
															'\u2212'
														)}
													</li>
												)
											)}
										</ul>
									</Col>
								)}
								{[2, 3].includes(cotractExploitationTypesNumber) && (
									<Col sm={2}>
										<ul className={styles.rightsTableValuesRightSide}>
											{contractConditionDetails.exploitation_types.including.map(
												(type, index) => (
													<li>
														{type.licensor_share_passed ? (
															<img src={check} alt="" />
														) : (
															'\u2212'
														)}
													</li>
												)
											)}
										</ul>
									</Col>
								)}
								{[2, 3].includes(cotractExploitationTypesNumber) && (
									<Col sm={2}>
										<ul className={styles.rightsTableValuesRightSide}>
											{contractConditionDetails.exploitation_types.including.map(
												(type, index) => (
													<li>
														{type.licensor_share_from_source ? (
															<img src={check} alt="" />
														) : (
															'\u2212'
														)}
													</li>
												)
											)}
										</ul>
									</Col>
								)}

								{[1, 3, 4].includes(cotractExploitationTypesNumber) && (
									<Col sm={2}>
										<ul className={styles.rightsTableValuesRightSide}>
											{contractConditionDetails.exploitation_types.including.map(
												(type, index) => (
													<li key={type.licensor_share + index}>
														{' '}
														{type.licensor_share.split('.')[0]}%
													</li>
												)
											)}
										</ul>
									</Col>
								)}
								<Col sm={2}>
									<ul className={styles.rightsTableValuesRightSide}>
										<ul className={styles.rightsTableValuesRightSide}>
											{contractConditionDetails.exploitation_types.including.map(
												(type, index) => (
													<li key={type.licensee_share + index}>
														{' '}
														{type.licensee_share.split('.')[0]}%
													</li>
												)
											)}
										</ul>
									</ul>
								</Col>
							</Row>
						</div>
					)}

				{rightTypes &&
					contractConditionDetails.right_types &&
					contractConditionDetails.right_types.including &&
					contractConditionDetails.right_types.including.length > 0 && (
						<div className={styles.rightsTable}>
							<Row className={styles.rightsTableTitle}>
								<Col sm={3}>
									{/* тайп номера 1,2,3,4,5 */}
									<FormattedMessage id={'rod.contracts.right_type'} />
								</Col>
								{cotractRightTypesNumber === 1 && (
									<Col sm={2}>
										{/* тайп номера 1 - * licensor_share_via_publisher */}
										<FormattedMessage
											id={'rod.contracts.licensor_share_via_publisher'}
										/>
									</Col>
								)}

								{[4].includes(cotractRightTypesNumber) && (
									<Col sm={2}>
										{/* тайп номера 4 - * collect_assignor_share  Сбор доли автора / Collect author's share*/}
										<FormattedMessage
											id={'rod.contracts.collect_assignor_share'}
										/>
									</Col>
								)}
								{[4].includes(cotractRightTypesNumber) && (
									<Col sm={2}>
										{/* тайп номера 4 - * collect_author_share_at_source Выплата доли автора от источника / Collect author revenue at source*/}
										<FormattedMessage
											id={'rod.contracts.collect_author_share_at_source'}
										/>
									</Col>
								)}
								{[5].includes(cotractRightTypesNumber) && (
									<Col sm={2}>
										{/* тайп номера 5 - * collect_licensor_share Сбор доли лицензиара / Collect licensor share*/}
										<FormattedMessage
											id={'rod.contracts.collect_licensor_share'}
										/>
									</Col>
								)}

								{[1, 2, 4, 5].includes(cotractRightTypesNumber) && (
									<Col sm={2}>
										{/* тайп номера 1,2,4,5 */}
										{JSONShares[CONTRACT_TYPE] &&
											JSONShares[CONTRACT_TYPE].licensor[lang]}
									</Col>
								)}
								<Col sm={2}>
									{/* тайп номера 1,2,3,4,5 */}
									{JSONShares[CONTRACT_TYPE] &&
										JSONShares[CONTRACT_TYPE].licensee[lang]}
								</Col>
							</Row>

							<Row className={styles.rightsTableValues}>
								<Col sm={3}>
									<ul className={styles.rightsTableValuesLeftSide}>
										<ul className={styles.rightsTableValuesRightSide}>
											{contractConditionDetails.right_types.including.map(
												(type, index) => (
													<li key={type.code + index}>
														{type.code &&
															rightTypes.find(
																(expl) => expl.code === type.code
															)[lang === 'ru' ? 'title_ru' : 'title_en']}
													</li>
												)
											)}
										</ul>
									</ul>
								</Col>
								{cotractRightTypesNumber === 1 && (
									<Col sm={2}>
										<ul className={styles.rightsTableValuesRightSide}>
											{contractConditionDetails.right_types.including.map(
												(type, index) => (
													<li>
														{type.licensor_share_via_publisher ? (
															<img src={check} alt="" />
														) : (
															'\u2212'
														)}
													</li>
												)
											)}
										</ul>
									</Col>
								)}
								{cotractRightTypesNumber === 4 && (
									<Col sm={2}>
										<ul className={styles.rightsTableValuesRightSide}>
											{contractConditionDetails.right_types.including.map(
												(type, index) => (
													<li>
														{type.licensor_share_passed ? (
															<img src={check} alt="" />
														) : (
															'\u2212'
														)}
													</li>
												)
											)}
										</ul>
									</Col>
								)}
								{cotractRightTypesNumber === 5 && (
									<Col sm={2}>
										<ul className={styles.rightsTableValuesRightSide}>
											{contractConditionDetails.right_types.including.map(
												(type, index) => (
													<li>
														{type.licensor_share_passed ? (
															<img src={check} alt="" />
														) : (
															'\u2212'
														)}
													</li>
												)
											)}
										</ul>
									</Col>
								)}
								{cotractRightTypesNumber === 4 && (
									<Col sm={2}>
										<ul className={styles.rightsTableValuesRightSide}>
											{contractConditionDetails.right_types.including.map(
												(type, index) => (
													<li>
														{type.licensor_share_from_source ? (
															<img src={check} alt="" />
														) : (
															'\u2212'
														)}
													</li>
												)
											)}
										</ul>
									</Col>
								)}

								{[1, 2, 4, 5].includes(cotractExploitationTypesNumber) && (
									<Col sm={2}>
										<ul className={styles.rightsTableValuesRightSide}>
											{contractConditionDetails.right_types.including.map(
												(type, index) => (
													<li key={type.licensor_share + index}>
														{' '}
														{type.licensor_share.split('.')[0]}%
													</li>
												)
											)}
										</ul>
									</Col>
								)}
								<Col sm={2}>
									<ul className={styles.rightsTableValuesRightSide}>
										<ul className={styles.rightsTableValuesRightSide}>
											{contractConditionDetails.right_types.including.map(
												(type, index) => (
													<li key={type.licensee_share + index}>
														{' '}
														{type.licensee_share.split('.')[0]}%
													</li>
												)
											)}
										</ul>
									</ul>
								</Col>
							</Row>
						</div>
					)}
			</div>
		)
	);
};

export default compose(withUI, withRoot, withAuth)(ContractDataMarkup);
