import React from 'react';

import s from './InputRubric.module.css';

export default function InputRubric({ handleOnChange, value }) {
	return (
		<div className={s.repertoire__mainFilter}>
			<div
				className={
					!value
						? `${s.repertoire__mainFilterInput}`
						: `${s.repertoire__mainFilterInput} ${s.repertoire__mainFilterInputFilled}`
				}
			>
				<input
					type="text"
					onChange={(e) => handleOnChange(e.target.value)}
					value={value}
					autoFocus={true}
				/>
			</div>
		</div>
	);
}
