// Core
import React from 'react';

// UI
import {
	TextFieldInput,
	AutocompleteInput,
} from 'material-design/MuiStyled/MuiStyled';
import { makeStyles, createStyles } from '@mui/styles';
import { Popper } from '@mui/material';
import isItemSelected from 'logic/multiselectHighlight.js';

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			zIndex: 2,
			'& .MuiAutocomplete-listbox': {
				margin: 0,
				padding: 0,
				'& li': {
					backgroundColor: 'var(--color-white)',
					font: 'var(--gilroy-Medium-16-150)',
					padding: '12px  8px',
					'&:hover': {
						backgroundColor: 'var(--color-light-grey) !important',
					},
				},
			},
			'& 	.MuiAutocomplete-popupIndicator': {
				backgroundColor: 'var(--color-green)',
				color: 'var(--color-white)',
			},
		},
	})
);

const CustomPopper = function (props) {
	const classes = useStyles();
	return <Popper {...props} className={classes.root} placement="bottom" />;
};

const MuiSelect = ({ onChange, label, items, value, required, errors }) => {
	const lang = localStorage.getItem('lang');

	if (value) {
		for (let j = 0; j < items.length; j++) {
			if (value === items[j].code) {
				value = {
					id: items[j].id,
					title_ru: items[j].title_ru,
					title_en: items[j].title_en,
					code: items[j].code,
				};
			}
		}
	}

	const filterOptions = (_, state) => {
		let newOptions = [];
		if (state.inputValue) {
			items.forEach((item) => {
				const word = lang === 'ru' ? item.title_ru : item.title_en;
				if (
					word.toLowerCase().includes(state.inputValue.toLowerCase()) &&
					word.toLowerCase().indexOf(state.inputValue.toLowerCase()) === 0
				)
					newOptions.push(item);
			});

			return newOptions;
		} else {
			return items;
		}
	};

	return (
		<AutocompleteInput
			id="disable-clearable"
			disableClearable
			onChange={(_, obj) => onChange(obj)}
			options={items}
			value={value}
			getOptionLabel={(option) => {
				return (lang === 'ru' ? option.title_ru : option.title_en) || '';
			}}
			isOptionEqualToValue={(option, value) => {
				return option.code === value;
			}}
			renderInput={(params) => (
				<TextFieldInput
					{...params}
					label={label}
					variant="standard"
					required={required}
					error={
						errors &&
						(typeof errors === 'string' ||
							typeof errors === 'boolean' ||
							(Array.isArray(errors) && errors.length > 0) ||
							(typeof errors === 'object' && Object.keys(errors).length > 0))
					}
				/>
			)}
			renderOption={(option, item) => {
				return (
					<li
						key={item}
						{...option}
						style={{
							backgroundColor: isItemSelected(item, value)
								? 'var(--color-green)'
								: 'var(--color-white)',
							color: isItemSelected(item, value)
								? 'var(--color-white)'
								: 'unset',
						}}
					>
						{lang === 'ru' ? item.title_ru : item.title_en}
					</li>
				);
			}}
			PopperComponent={CustomPopper}
			filterOptions={filterOptions}
		/>
	);
};

export default MuiSelect;
