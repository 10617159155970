/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 03/03/2021, 16:02
 */

// Core
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Document, Page } from 'react-pdf';
import { withRouter } from 'react-router-dom';

// UI
import { Button } from 'components/Buttons/Button';
import { Checkbox } from 'components/Checkboxes/Checkbox';
import { Label } from 'components/Labels/Label';
import modalTypes from 'constants/modalTypes';
import { withAuth, withRoot, withUI } from 'hocs';
import fileImg from 'images/file.svg';
import { Col, Container, Row } from 'react-grid-system';
import { compose } from 'recompose';

// Styles
import styles from './Contract.module.css';

class Contract extends PureComponent {
	constructor(props) {
		super(props);
		this.form = React.createRef();
		this.state = {
			formWidth: 600,
			numPages: 0,
			agreed1: false,
			agreed2: false,
			contacts: {},
			errors: {},
			isSubmitPressed: false,
		};
	}

	componentDidMount() {
		const {
			authContext: { setIsAccountLoading },
			rootContext: { getAccountContactsData, countries },
		} = this.props;

		setIsAccountLoading(true);
		getAccountContactsData(this.props.match.params.id)
			.then((res) => {
				res = res.data.data;
				const contactCountry = countries.filter(
					(item) => item.id === res.phone_code_country_id
				);
				res.dialing_code = contactCountry[0].dialing_code;
				this.setState({
					contacts: res,
					loading: false,
				});
				setIsAccountLoading(false);
			})
			.catch((err) => {
				console.error(err.errors);
				setIsAccountLoading(false);
			});
			
		window.addEventListener('resize', this.update);
		this.update();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.update);
	}

	update = () => {
		this.setState({
			formWidth: this.form.current.getBoundingClientRect().width - 100,
		});
	};

	loadSuccess = ({ numPages }) => {
		this.setState({
			numPages,
		});
	};

	agreed = (field) => () => {
		this.setState({
			[field]: !this.state[field],
		});
	};

	signContractFromModal = () => {
		this.props.onSubmit(this.props.contractToSign);
	};

	submit = () => {
		if (this.state.isSubmitPressed) return;
		const {
			UIContext: { showModal, putResendSMSTime },
			rootContext: {
				acceptContract,
				resendSignSMS,
				signContract,
				noCodeSignSMS,
			},
			sms,
			contractToSign,
			onNoCode,
		} = this.props;
		const { dialing_code, phone_number } = this.state.contacts;

		const showModalHandler = (errors = null) => {
			showModal(
				{
					phone: this.state.contacts['user-phone']
						? this.state.contacts['user-phone']
						: `+${dialing_code}${phone_number}`,
					id: this.props.match.params.id,
					contract: contractToSign,
					type: contractToSign.contract_type,
					resendSignSMS: resendSignSMS,
					signContractFromModal: this.signContractFromModal,
					signContractFromApi: signContract,
					noCodeSignSMS: noCodeSignSMS,
					onNoCode: onNoCode,
					onSubmit: this.props.onSubmit,
					changeSignContractsType: this.props.changeSignContractsType,
					errors: errors || null,
				},
				modalTypes.CONTRACT_SMS_SIGN
			)();
		};

		if (sms) {
			acceptContract(this.props.match.params.id, contractToSign.contract_type)
				.then((res) => {
					putResendSMSTime();
					showModalHandler();
				})
				.catch((data) => {
					showModalHandler(data.response.data.errors);
				});
		} else {
			acceptContract(
				this.props.match.params.id,
				contractToSign.contract_type
			).then((res) => {
				this.props.onSubmit(contractToSign);
			});
		}
	};

	getContractType = () => {
		const { contract_type } = this.props.contractToSign;

		switch (contract_type) {
			case 'license':
				return <FormattedMessage id={'rod.account.create.license-agreement'} />;
			case 'publishing':
				return (
					<FormattedMessage id={'rod.account.create.publishing-agreement'} />
				);
			case 'sub_publishing':
				return (
					<FormattedMessage
						id={'rod.account.create.sub-publishing-agreement'}
					/>
				);

			default:
				return <FormattedMessage id={'rod.account.create.license-agreement'} />;
		}
	};

	render() {
		const { numPages, formWidth, checkbox1, checkbox2 } = this.state;
		const { contractToSign } = this.props;
		const typeTitle = this.getContractType();

		return (
			<Container fluid className={styles.Page}>
				<div className={styles.Form} ref={this.form}>
					<div className={styles.Header}>
						<img src={fileImg} alt="" />
						<Label font="--gilroy-Medium-40" black text={typeTitle} />
					</div>

					<div className={styles.SubHeader}>
						<Label
							font="--gilroy-Medium-32"
							black
							text={
								<FormattedMessage id={'rod.field.contracts.pdf.subheader'} />
							}
						/>
					</div>

					<Document
						file={contractToSign.contract_url}
						className={styles.Document}
						onLoadSuccess={this.loadSuccess}
					>
						{Array.from(new Array(numPages), (el, index) => (
							<Page
								pageNumber={index + 1}
								key={`page_${index + 1}`}
								className={styles.DocPage}
								width={formWidth}
								renderTextLayer={false}
							>
								<span className={styles.Hash}>
									hash document: {contractToSign.hash}
								</span>
							</Page>
						))}
					</Document>
					<Row>
						<Col sm={10} offset={{ sm: 1 }} className={styles.Header}>
							<div className={styles.Controls}>
								<Checkbox
									className={styles.Checkbox}
									onChange={this.agreed('checkbox1')}
									checked={checkbox1}
									required
								>
									<FormattedMessage id={'rod.field.contracts.agreed1'} />
								</Checkbox>
								<Checkbox
									className={styles.Checkbox}
									checked={checkbox2}
									onChange={this.agreed('checkbox2')}
									required
								>
									<FormattedMessage id={'rod.field.contracts.agreed2'} />
								</Checkbox>
								<div className={styles.btnWrapper}>
									<Button
										text={<FormattedMessage id={'rod.action.confirm'} />}
										variant={'primary'}
										disabled={!checkbox1 || !checkbox2}
										onClick={this.submit}
									/>
									{Object.keys(this.state.errors).includes('code') && (
										<span className={styles.Helper}>
											{<FormattedMessage id={`rod.error.code.forbidden`} />}
										</span>
									)}
								</div>
							</div>
						</Col>
					</Row>
				</div>
			</Container>
		);
	}
}

Contract.propTypes = {};
Contract.defaultProps = {};
export default compose(withUI, withRoot, withRouter, withAuth)(Contract);
