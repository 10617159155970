// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Context
// import { RootContext } from 'contexts/RootContext';
// import { UIContext } from 'contexts/UIContext';

// Components
import { FormInput } from 'components/Form/FormInput';
import CmoBtn from '../CmoBtn/CmoBtn';

// Styles
import s from './AuthorMain.module.css';

const AuthorMain = ({
	handleOnChange,
	handleOnFocus,
	handleOnBlur,
	deleteAuthor,
	index,
	errors,
	selectPerson,
	tmpInputValue,
	setModal,
	onSave,
	isLoading,
}) => {
	// TODO
	// Окрашивание зеленым кнопки работает некорректно, когда несколько контрибьюторов
	// const { getAuthorSocieties } = useContext(RootContext);
	// const { setReleaseAuthorSocieties } = useContext(UIContext);
	// const releaseId = localStorage.getItem('releaseId');

	// useEffect(() => {
	// 	if (
	// 		selectPerson &&
	// 		selectPerson?.contributors &&
	// 		selectPerson?.contributors[index]?.id
	// 	) {
	// 		try {
	// 			getAuthorSocieties(
	// 				releaseId,
	// 				selectPerson.id,
	// 				selectPerson?.contributors[index]?.id,
	// 				'author'
	// 			).then((res) => {
	// 				if (res.data.data?.length > 0) {
	// 					setReleaseAuthorSocieties(true);
	// 				}
	// 			});
	// 		} catch (error) {
	// 			console.error('Error getAuthorSocieties');
	// 		}
	// 	}

	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	return (
		<div className={s.grid}>
			<div className={s.input}>
				<FormInput
					type="muiInput"
					name={'title'}
					onChange={handleOnChange(index)}
					errors={
						errors.title && errors.title.some((item) => item.key === index)
							? errors
							: {}
					}
					data={selectPerson.contributors[index]}
					label={
						<FormattedMessage
							id={'rod.release.create.step.recording_info.label.author'}
							values={{ id: index + 1 }}
						/>
					}
					required
				/>
				{Object.keys(errors).includes('title') &&
					errors['title'].map(
						(item) =>
							item.key === index &&
							(item.type === 'max_length' ? (
								<span className={s.helper}>
									<FormattedMessage
										id={`rod.error.max_length`}
										values={{
											num: item.value,
										}}
									/>
								</span>
							) : (
								<>
									{errors.title &&
										errors.title.some((item) => item.key === index) && (
											<span className={s.helper}>
												<FormattedMessage
													id={`rod.release_create.error.required`}
												/>
											</span>
										)}
								</>
							))
					)}
			</div>
			<div className={s.input}>
				<FormInput
					type={'muiAutocompleteMultiple'}
					name={'roles'}
					onChange={handleOnChange(index)}
					errors={
						errors.roles && errors.roles.some((item) => item === index)
							? errors
							: {}
					}
					data={selectPerson.contributors[index]}
					label={
						<FormattedMessage
							id={'rod.release.create.step.recording_info.label.author_role'}
							values={{ id: index + 1 }}
						/>
					}
				/>
				{Object.keys(errors).includes('roles') &&
					errors['roles'].includes(index) &&
					errors.roles.some((item) => item === index) && (
						<span className={s.helper}>
							{<FormattedMessage id={`rod.release_create.error.required`} />}
						</span>
					)}
			</div>
			<div className={`${s.info} ${s.title__info} ${s.authorData}`}>
				<FormInput
					type={'muiSlider'}
					name={'ownership'}
					onChange={handleOnChange(index)}
					onFocus={handleOnFocus(index)}
					onBlur={handleOnBlur(index)}
					tmpInputValue={tmpInputValue}
					errors={errors}
					data={selectPerson.contributors[index]}
					labelSlider={
						<FormattedMessage
							id={'rod.release.create.step.recording_info.label.author_share'}
							values={{ id: index + 1 }}
						/>
					}
					width={92}
					required
					bind={selectPerson?.contributors[index]?.publisher_show}
				/>
				{Object.keys(errors).includes('ownership') &&
					errors['ownership'].includes(index) && (
						<span className={s.helper}>
							{<FormattedMessage id={`rod.release_create.error.required`} />}
						</span>
					)}
				<CmoBtn
					selectPerson={selectPerson}
					index={index}
					onSave={onSave}
					deleteContributor={deleteAuthor}
					setModal={setModal}
					isLoading={isLoading}
					type="author"
					release
				/>
			</div>
		</div>
	);
};

export default AuthorMain;
