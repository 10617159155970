export default function Save({ className }) {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M11 18C11 18.5523 11.4477 19 12 19C12.5523 19 13 18.5523 13 18L11 18ZM12.7071 0.292892C12.3166 -0.0976314 11.6834 -0.0976315 11.2929 0.292892L4.92893 6.65685C4.53841 7.04738 4.53841 7.68054 4.92893 8.07107C5.31946 8.46159 5.95262 8.46159 6.34315 8.07107L12 2.41421L17.6569 8.07107C18.0474 8.46159 18.6805 8.46159 19.0711 8.07107C19.4616 7.68054 19.4616 7.04738 19.0711 6.65685L12.7071 0.292892ZM13 18L13 1L11 1L11 18L13 18Z"
				fill="black"
			/>
			<path
				d="M1 19V23H23V19"
				stroke="black"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
