import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

// Context
import { UIContext } from 'contexts/UIContext';

// UI
import { Label } from 'components/Labels/Label';
import { InfoButton } from 'components/Buttons';
import EditableText from '../../EditableText/EditableText';

// Styles
import styles from '../LyricsForm.module.css';

const LyricWrapper = ({ changeField, errors, selectPerson }) => {
	const { showModal } = useContext(UIContext);
	const [lyrics, setLyrics] = useState(selectPerson.lyrics || '');

	useEffect(() => {
		if (!selectPerson.lyrics_id) {
			setLyrics('');
		} else {
			setLyrics(selectPerson.lyrics || null);
		}
	}, [selectPerson]);

	return (
		<div className={styles.lyricWrapper}>
			<InfoButton
				onClick={showModal({
					text: (
						<FormattedHTMLMessage id={'rod.release.create.step.lyrics.help'} />
					),
				})}
			>
				<Label
					text={
						<FormattedMessage
							id={'rod.release.create.step.lyrics.add_lyrics.title'}
						/>
					}
					font="--gilroy-Medium-24-28"
					black
				/>
			</InfoButton>
			<EditableText
				changeField={changeField}
				initialHtml={lyrics}
				selectPerson={selectPerson}
			/>
			{errors && Object.keys(errors).includes('lyrics') && (
				<span className={styles.Helper}>
					{
						<FormattedMessage
							id={`rod.release_create.error.lyrics.${errors.lyrics}`}
						/>
					}
				</span>
			)}
		</div>
	);
};

export default LyricWrapper;
