export const convertDataToCumulative = (newStatForSelectedOutlets) => {
	let cumulativeVariant = {};
	const sortingValues = Object.values(newStatForSelectedOutlets).sort(
		(a, b) => a.maxY - b.maxY
	);

	for (let i = 1; i <= sortingValues.length; i++) {
		cumulativeVariant[`id${i}`] = sortingValues[i - 1];
	}

	return cumulativeVariant;
};
