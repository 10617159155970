// Core
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import lscache from 'lscache';

// Context
import { UIContext } from 'contexts/UIContext';
import { RootContext } from 'contexts/RootContext';

// Services
import { accounts, dictionaries } from 'services';

// Utils
import { setCache } from 'services/cacheHelper/setCache';

// UI
import { AddRoleContractsBanner } from 'material-design/components/Banners/AddRoleContractsBanner';
import Header from './Header/Header';
import Body from './Body/Body';
import { BottomError, BottomNavi } from 'components';

// Styles
import s from './Confirm.module.css';

const Confirm = (props) => {
	const [personal, setPersonal] = useState({});
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(true);
	const [country, setCountry] = useState('');
	const [release, setRelease] = useState('');
	const [cover, setCover] = useState('');
	const [audio] = useState(new Audio());
	const [recommendedOutlets, setRecommendedOutlets] = useState([]);
	// eslint-disable-next-line no-unused-vars
	const [isLoading, setIsLoading] = useState(true);
	const [isReqLive] = useState(false);
	const [isNextClicked, setIsNextClicked] = useState(false);
	const [moderationContract, setModerationContract] = useState(true);
	const [contractType, setContractType] = useState([]);
	const [disabled, setDisabled] = useState(false);
	const [isH11Step, setIsH11Step] = useState(
		window.location.href.includes('/edit/')
	);

	const lang = localStorage.getItem('lang');
	const releaseId = localStorage.getItem('releaseId');
	const isH11Edit = window.location.href.includes('/edit/');
	const releaseEdit = JSON.parse(localStorage.getItem('releaseEdit'));
	const h11EditId = releaseEdit?.id;
	const accountId = localStorage.getItem('accountId');
	const history = useHistory();

	const {
		isShowServicesRepertoireBanner,
		setIsTransferRelease,
		setIsBottomNaviShow,
	} = useContext(UIContext);

	const { postAdditionalServices } = useContext(RootContext);

	const handleFileUpload = (files) => {
		setErrors({});

		if (
			files[0].type !== 'image/jpg"' &&
			files[0].type !== 'image/jpeg' &&
			files[0].type !== 'image/png' &&
			files[0].type !== 'image/tiff'
		) {
			setErrors({ file: [{ rule: 'image_wrong_format' }] });
			return;
		}

		setLoading(true);
		const fd = new FormData();
		fd.append('file', files[0]);
		accounts
			.setReleaseCover(h11EditId && isH11Edit ? h11EditId : releaseId, fd)
			.then((res) => {
				setLoading(false);
				setCover(res.data.data.url);
			})
			.catch((err) => {
				setLoading(false);
				if (err?.errors) {
					if (err.errors.file[0].rule === 'image_dimensions') {
						setErrors(err.errors);
					}
				}
			});
		return Promise.resolve();
	};

	const nextHandler = () => {
		setDisabled(true);
		if (isNextClicked) {
			return;
		}

		setIsNextClicked(true);
		audio.currentTime = 0;
		audio.pause();

		const sendRequest =
			h11EditId && isH11Edit
				? accounts.sendModerateEditedRelease(h11EditId, personal)
				: accounts.sendModerateRelease(releaseId, personal);

		sendRequest
			.then(() => {
				if (isH11Step) {
					const releaseEdit = { id: h11EditId, step: 'moderation' };
					localStorage.setItem('releaseEdit', JSON.stringify(releaseEdit));
					history.push(`/release/${h11EditId}/edit/moderation`);
				} else {
					history.push(`/release/${releaseId}/moderation`);
				}
				setIsTransferRelease(false);
			})
			.catch((err) => {
				if (err?.response?.status === 422) {
					setErrors({ code: '422' });
					console.error(err.response.data.errors);
				}
			})
			.finally(() => {
				setDisabled(false);
			});
	};

	const backHandler = () => {
		if (typeof props.handlePrevStep === 'function') {
			if (h11EditId && isH11Edit) {
				history.push(`/release/${h11EditId}/edit/distribution`);
			} else {
				history.push(`/release/${releaseId}/distribution`);
			}
			audio.currentTime = 0;
			audio.pause();
			props.handlePrevStep();
		}
	};

	const emptyTrackList = () => {
		if (typeof props.emptyTrackList === 'function') {
			if (h11EditId && isH11Edit) {
				history.push(`/release/${h11EditId}/edit/file`);
			} else {
				history.push(`/release/${releaseId}/file`);
			}
			audio.currentTime = 0;
			audio.pause();
			props.emptyTrackList();
		}
	};

	const gerReleaseTypes = (data) => {
		const releaseTypesCache = lscache.get('releaseTypesCache');
		if (releaseTypesCache && !isReqLive && releaseTypesCache.lang === lang) {
			const a = releaseTypesCache.data.find(
				(item) => item.id === data.release_type_id
			);
			setRelease(a);
		} else {
			accounts.getReleaseTypes(lang).then((res) => {
				res = res.data.data;
				setCache('releaseTypes', 'releaseTypesCache', res);
				const a = res.find((item) => item.id === data.release_type_id);
				setRelease(a);
			});
		}
	};

	const handleOnLoad = () => {
		setLoading(false);
	};

	useEffect(() => {
		setIsBottomNaviShow(true);

		return () => {
			setIsBottomNaviShow(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const releaseId = localStorage.getItem('releaseId');
		const promise = new Promise((resolve, reject) => {
			setDisabled(true);
			accounts
				.getReleaseData(h11EditId && isH11Edit ? h11EditId : releaseId)
				.then((res) => {
					const formatDate = (dateString) => {
						return dateString.replace(
							/(\d{4})-(\d{2})-(\d{2})(.+)/gm,
							'$3.$2.$1'
						);
					};

					res.data.data.created_date = formatDate(res.data.data?.created_date);
					res.data.data.distributions.sale_start_date = formatDate(
						res.data.data?.distributions.sale_start_date
					);

					if (!res.data.data?.cover?.url) {
						setErrors({ ...errors, cover: 'required' });
					} else {
						setCover(res.data.data.cover.url);
					}
					if (res.data.data?.tracks && res.data.data?.tracks.length === 0) {
						setErrors({ ...errors, tracks: 'required' });
					}

					setIsH11Step(res.data.data?.step.includes('h11'));
					setModerationContract(res.data.moderation_contract);
					setContractType(res.data.type_contract);
					setPersonal(res.data.data);
					resolve(res.data.data);
				})
				.catch((error) => {
					console.error('Error', error);
				})
				.finally(() => {
					setDisabled(false);
				});
		});

		promise
			.then((data) => {
				const countriesCache = lscache.get('countriesCashe');
				if (countriesCache && !isReqLive) {
					const a = countriesCache.find(
						(item) => item.id === data.created_country_id
					);
					setCountry(a);
				} else {
					dictionaries.getCountries().then((res) => {
						res = res.data.data;
						setCache('countries', 'countriesCashe', res);
						const a = res.find((item) => item.id === data.created_country_id);
						setCountry(a);
					});
				}
				gerReleaseTypes(data);
			})
			.catch((error) => {
				console.error('Error', error);
			});

		accounts
			.getOutletsWithTypes(lang, props.releaseTypeId)
			.then((outlets) => {
				outlets = outlets.data.data;

				const recommendedOutletsCount = outlets.filter(
					(outlet) => outlet.is_recommended === 1
				);
				setRecommendedOutlets(recommendedOutletsCount);
				setIsLoading(false);
			})
			.catch((error) => {
				console.error('Error', error);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (Object.keys(personal).length) gerReleaseTypes(personal);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang]);

	return (
		<>
			<div className={s.page}>
				<Header type={release?.title} name={personal.title} />
				<div className={s.main}>
					{isShowServicesRepertoireBanner &&
						contractType &&
						((!moderationContract && contractType.length > 0) ||
							contractType.length > 0) && (
							<div className={s.addRoleContractsBanner}>
								<AddRoleContractsBanner
									data={{
										title: (
											<FormattedMessage
												id={
													'rod.account.add_role_banner_release_not_available_title'
												}
											/>
										),
										content: (
											<FormattedMessage
												id={
													!moderationContract
														? 'rod.account.add_role_banner_release_not_available_moderation'
														: 'rod.account.add_role_banner_release_not_available_text'
												}
											/>
										),
										btnText: (
											<FormattedMessage
												id={
													!moderationContract
														? 'rod.account.save_to_draft_btn'
														: 'rod.account.add_service_btn'
												}
											/>
										),

										data: ['distribution'],
										postAdditionalServices,
										id: accountId,
										action: !moderationContract ? 'to draft' : 'sign services',
									}}
								/>
							</div>
						)}
					<Body
						personal={personal}
						country={country}
						cover={cover}
						loading={loading}
						handleOnLoad={handleOnLoad}
						handleFileUpload={handleFileUpload}
						emptyTrackList={emptyTrackList}
						recommendedOutlets={recommendedOutlets}
					/>
				</div>
			</div>
			<div className={s.bottom_container}>
				<BottomError
					errors={errors}
					error="code"
					messageId="rod.release.moderation.error"
				/>
				<BottomNavi
					showPrevBtn
					disabled={
						isShowServicesRepertoireBanner
							? Object.keys(errors).length !== 0 ||
							  disabled ||
							  (!moderationContract &&
									contractType &&
									contractType.length > 0) ||
							  (contractType && contractType.length > 0)
							: Object.keys(errors).length !== 0
					}
					back={backHandler}
					next={nextHandler}
					nextText="rod.release.create.step.submit.submit"
				/>
			</div>
		</>
	);
};

export default Confirm;
