// Core
import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { RootContext } from 'contexts/RootContext';
import { UIContext } from 'contexts/UIContext';

// Utils
import { withAuth, withRoot, withUI } from 'hocs';
import { compose } from 'recompose';

// Constants
import accountCompletionSteps from 'constants/accountCompletionSteps';

// UI
import { Loading } from 'components';
import { Create } from 'pages/account/Create';

const Account = (props) => {
	const { match } = props;
	const {
		contractsExpiredRedirect,
		getAccountCompletionStep,
		switchAccount,
		setIsNoContract,
		setIsAccountLoading,
	} = useContext(AuthContext);
	const {
		hideUploadHandler,
		showUploadHandler,
		setIsExpiredContract,
		isForbiddenOnboarding,
	} = useContext(UIContext);
	const { getAccountPersonalData } = useContext(RootContext);
	const isNoContract = localStorage.getItem('no_contract');

	const [account, setAccount] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		update();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [match.params.id]);

	useEffect(() => {
		return () => {
			hideUploadHandler();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const update = () => {
		setIsAccountLoading(true);
		switchAccount(match.params.id);
		getAccountPersonalData(match.params.id)
			.then((res) => {
				if (res.data && 'is_expired_contract' in res.data) {
					setIsExpiredContract(true);
				} else {
					setIsExpiredContract(false);
				}

				if (res.status === 207 || res.status === 205) {
					return contractsExpiredRedirect(res.status);
				} else if (isNoContract) {
					setIsNoContract();
				}

				res = res.data.data;

				if (
					res.completion_step === accountCompletionSteps.PERSONAL_AREA ||
					res.completion_step === accountCompletionSteps.REPERTOIRE_APPLY
				) {
					showUploadHandler();
				}

				if (res.completion_step !== accountCompletionSteps.APPROVED) {
					setIsAccountLoading(false);
				} else {
					setTimeout(() => {
						setIsAccountLoading(false);
					}, 7000);
				}

				setAccount({
					...res,
					moderation_status: res.moderation_status || {},
				});
			})
			.catch((error) => {
				console.error(
					'Account - catched error.response: getAccountPersonalData',
					error.response
				);
				setIsAccountLoading(false);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	if (loading) {
		return <Loading non_margin={true} />;
	}

	if (!account) {
		return <Redirect to={'/accounts'} />;
	}

	try {
		getAccountCompletionStep(account.completion_step);
		if (
			isForbiddenOnboarding &&
			(account.completion_step === 'role' ||
				account.completion_step === 'personal_data' ||
				account.completion_step === 'contact_data' ||
				account.completion_step === 'documents' ||
				account.completion_step === 'identity' ||
				account.completion_step === 'money')
		) {
			return <Redirect to={'/no_contract'} />;
		} else {
			switch (account.completion_step) {
				case accountCompletionSteps.APPROVED:
				case accountCompletionSteps.SIGN_PHYSICAL:
				case accountCompletionSteps.SIGN_SMS:
				case accountCompletionSteps.APPROVED_CONTRACTS:
					return <Redirect to={`/accounts/${account.id}/contracts/add`} />;
				case accountCompletionSteps.PENDING_CONTRACT:
					return <Redirect to={`/accounts/${account.id}/contracts/add`} />;
				case accountCompletionSteps.PERSONAL_AREA:
				case accountCompletionSteps.REPERTOIRE_APPLY:
					return <Redirect to={`/accounts/${account.id}/dashboard`} />;
				case accountCompletionSteps.PERSONAL_AREA_LEGASY:
					return <Redirect to={`/accounts/${account.id}/dashboard`} />;
				default:
					return <Create account={account} />;
			}
		}
	} catch (err) {
		console.error('Account - catched error: getAccountCompletionStep', err);
	}
};

export default compose(withRoot, withAuth, withUI)(Account);
