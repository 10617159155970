/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 24/11/2020, 19:02
 */

import { Button } from 'components/Buttons';
import { Form } from 'components/Form';
import { Input } from 'components/Inputs';
import { Label } from 'components/Labels';
import { Link } from 'components/Links';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { showError } from 'validators/showError';
import styles from './Login.module.css';
import { withUI } from 'hocs';
import { compose } from 'recompose';

class Login extends PureComponent {
	static propTypes = {
		onSubmit: PropTypes.func,
		errors: PropTypes.object,
	};

	static defaultProps = {
		errors: {},
	};

	form = React.createRef();
	username = React.createRef();
	password = React.createRef();

	submitHandler = (event) => {
		event.preventDefault();
		if (this.props.onSubmit) {
			this.props.onSubmit({
				username: this.username.current.value,
				password: this.password.current.value,
			});
		}
	};

	render() {
		const { errors } = this.props;

		return (
			<Form
				ref={this.form}
				onSubmit={this.submitHandler}
				className={styles.form_wrapper}
			>
				<Label className="title" black>
					<FormattedMessage id={'rod.login.header'} />
				</Label>
				<FormattedMessage id={'rod.field.email'}>
					{(placeholder) => (
						<Input
							placeholder={placeholder}
							ref={this.username}
							errors={errors.email}
							showError={showError('email')}
							className={styles.Input}
						/>
					)}
				</FormattedMessage>
				<FormattedMessage id={'rod.field.password'}>
					{(placeholder) => (
						<Input
							placeholder={placeholder}
							type={'password'}
							ref={this.password}
							errors={errors.password}
							showError={showError('password')}
							className={styles.Input}
						/>
					)}
				</FormattedMessage>

				<div className={styles.submit}>
					<Button type={'submit'} variant={'primary'} className={styles.Button}>
						<FormattedMessage id={'rod.action.login'} />
					</Button>
				</div>

				<div className="links">
					<Link to={'/password/restore'}>
						<FormattedMessage id={'rod.login.text.forgot_password'} />
					</Link>
				</div>

				{this.props.UIContext.isAllowSignup && (
					<div className={styles.register_wrapper}>
						<Label
							font="--gilroy-Medium-20-140p"
							black
							className={styles.register_title}
						>
							<FormattedHTMLMessage id={'rod.login.register.header'} />
						</Label>
						<Label
							font="--gilroy-Medium-16-140"
							black
							className={styles.register_title}
						>
							<FormattedHTMLMessage
								id={'rod.login.text.have_not_account_yet'}
							/>
						</Label>
						<div className={styles.register_button}>
							<Link to={'/registration'}>
								<Button className={styles.Button}>
									<FormattedHTMLMessage
										id={'rod.landing.form.action.register'}
									/>
								</Button>
							</Link>
						</div>
					</div>
				)}
			</Form>
		);
	}
}

// export default Login;
export default compose(withUI)(Login);
