// Core
import { FormattedMessage } from 'react-intl';

export const checkSnippetErrors = (field, errors) => {
	switch (field) {
		case 'snippet_start':
			return (
				<FormattedMessage
					id={`rod.release_create_snipped.${errors.snippet_start[0].rule}`}
				/>
			);

		default:
			break;
	}
};
