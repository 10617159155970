// Core
import { FormattedHTMLMessage } from 'react-intl';
import moment from 'moment';

// Constants
import errorRules from 'constants/errorRules';
import { INPUT_TIME_COUNTER } from 'constants/timeConstants';

export const showError = (error, isChangeSignTypeAvailable, register) => {
	switch (error.rule) {
		case errorRules.INVALID:
			if (error.remaining === 0) {
				if (isChangeSignTypeAvailable) {
					return <FormattedHTMLMessage id={'rod.error.code.limit'} />;
				}

				return <FormattedHTMLMessage id={'rod.error.code_exhausted'} />;
			} else {
				return (
					<FormattedHTMLMessage
						id={'rod.error.code.invalid'}
						values={{ count: error.remaining }}
					/>
				);
			}
		case errorRules.RETRY:
			return <FormattedHTMLMessage id={'rod.error.code.retry'} />;

		case errorRules.FORBIDDEN:
			if (register) {
				return <FormattedHTMLMessage id={'rod.error.code_exhausted'} />;
			}
			return <FormattedHTMLMessage id={'rod.error.code.forbidden'} />;

		case errorRules.EXHAUSTED:
			if (isChangeSignTypeAvailable) {
				return <FormattedHTMLMessage id={'rod.error.code.limit'} />;
			}

			return <FormattedHTMLMessage id={'rod.error.code_exhausted'} />;

		default:
			return <FormattedHTMLMessage id={`rod.error.${error.rule}`} />;
	}
};

export const updateAllErrors = (
	register,
	errors,
	contextErrors,
	setShowResendButton,
	setAllErrors
) => {
	let combinedErrors = [];

	if (register) {
		if (contextErrors?.code) {
			combinedErrors = [...errors, ...contextErrors.code];
		} else {
			combinedErrors = [...errors];
		}
	}

	const filteredErrors = errors.filter((element) => element.rule !== 'retry');

	if (filteredErrors.find((el) => el.rule === 'forbidden')) {
		setShowResendButton(false);
	}

	setAllErrors(combinedErrors);
};

export const reset = (
	time,
	setTime,
	interval,
	clearInterval,
	setShowResendButton,
	clearAllErrors,
	withParentErrors
) => {
	interval = setInterval(() => {
		if (time.valueOf() > 0) {
			const updatedTime = moment(time.subtract(1, 'seconds'));
			setTime(updatedTime);
		} else {
			clearInterval(interval);
			if (!withParentErrors) {
				setShowResendButton(true);
				clearAllErrors();
			}
		}
	}, 1000);
};

export const handleResend = (
	event,
	onResend,
	clearAllErrors,
	contextResendSMSTime,
	setShowResendButton,
	putResendSMSTime,
	setTime,
	reset
) => {
	event.preventDefault();
	clearAllErrors();
	setShowResendButton(false);
	setTime(moment(new Date(contextResendSMSTime)));

	if (typeof onResend === 'function') {
		onResend();
	}

	putResendSMSTime(new Date().getTime());
	setTime(moment(new Date(INPUT_TIME_COUNTER)));
	reset();
};

// Function to remove duplicates based on 'rule' property
export const removeDuplicates = (array) => {
	const uniqueArray = array.filter(
		(item, index, self) => index === self.findIndex((t) => t.rule === item.rule)
	);
	return uniqueArray;
};
