/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 25/05/2020, 20:02
 */

import { CloseButton } from 'components/Buttons';
import { Col, Container, Row } from 'components/Grid';
import { Label } from 'components/Labels/Label';
import React, { Component } from 'react';
import posed, { PoseGroup } from 'react-pose';
import BaseModal from './BaseModal';
import { FormattedHTMLMessage } from 'react-intl';
import styles from './Modal.module.css';

const Modal = posed.div({
	enter: {
		x: 0,
		opacity: 1,
		// delay: 200,
		transition: {
			x: {
				ease: 'easeIn',
			},
			default: { duration: 300 },
		},
	},
	exit: {
		x: '100%',
		opacity: 0,
		transition: { duration: 250 },
	},
});

const Shade = posed.div({
	enter: { opacity: 0.5 },
	exit: { opacity: 0 },
});

class FullScreenModal extends Component {
	constructor() {
		super();
		this.state = { isVisible: false, isTitle: false };
	}

	componentDidMount() {
		this.setState({
			isVisible: true,
			onCloseFn: this.props.onClose,
		});
	}

	willUnmount(e) {
		this.setState({
			isVisible: false,
		});
		setTimeout(() => {
			this.state.onCloseFn();
		}, 300);
	}

	render() {
		const { title, text, children, width } = this.props;
		return (
			<BaseModal className={styles.Modal}>
				<PoseGroup>
					{this.state.isVisible && [
						<Shade key="shade" className={styles.Overlay} />,

						<Modal
							key="modal"
							style={{
								marginLeft: 'auto',
							}}
						>
							<Container fluid>
								<Row>
									<Col
										// md={12 - 4}
										onClick={(e) => {
											this.willUnmount(e);
										}}
									/>
									<Col width={width ? width : '1'} className={styles.LeftSide}>
										<div className={styles.Content}>
											{title && <Label className={styles.Title} text={title} />}
											{text ? (
												<div className={styles.Paragraph}>
													{/* <FormattedHTMLMessage id={text.props.id}>
														{(pureText) => (
															<div
																className={styles.Paragraph}
																style={{
																	paddingTop: pureText.includes('<h1>')
																		? '116px'
																		: '0',
																}}
															>
																{text}
															</div>
														)}
													</FormattedHTMLMessage> */}

													{text}
												</div>
											) : (
												children
											)}
										</div>

										<CloseButton
											small
											className={styles.CloseButton}
											onClick={(e) => {
												this.willUnmount(e);
											}}
										/>
									</Col>
								</Row>
							</Container>
						</Modal>,
					]}
				</PoseGroup>
			</BaseModal>
		);
	}
}

export default FullScreenModal;
