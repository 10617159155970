/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 08/06/2020, 17:46
 */
import { Loading } from 'components';
import { Button } from 'components/Buttons/Button';
import { Label } from 'components/Labels';
import { Success as SuccessForm } from 'forms/common';
import { withAuth, withUI } from 'hocs';
import React, { Component } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import { auth } from 'services';
import styles from './ForgotPasswordSuccess.module.css';

class ForgotPasswordSuccess extends Component {
	state = {
		loading: true,
		success: false,
	};

	componentDidMount() {
		// this.props.UIContext.showBackNavi();
		const {
			match: { params },
		} = this.props;
		const token = params.token;

		// changePassword({ token }).then(res => {
		// 	this.setState({
		// 		loading: false,
		// 		success: true,
		// 	});
		// }).catch(err => {
		// 	this.setState({
		// 		loading: false,
		// 		success: false,
		// 	});
		// });
		auth
			.changePasswordSuccess(token)
			.then((res) => {
				this.setState({
					loading: false,
					success: true,
				});
			})
			.catch((err) => {
				this.setState({
					loading: false,
					success: false,
				});
			});
	}

	componentWillUnmount() {
		// this.props.UIContext.hideBackNavi();
	}

	handleClick = () => {
		this.props.history.push('/login');
	};

	render() {
		if (this.state.loading) {
			return <Loading />;
		}
		if (!this.state.success) {
			return <Redirect to={'/password/restore/wrong'} />;
		}

		return (
			<SuccessForm>
				<Label black className={styles.Header} font="--gilroy-Medium-40">
					<FormattedHTMLMessage id={'rod.text.change_password.success'} />
				</Label>
				<Button
					className={styles.Button}
					onClick={this.handleClick}
					text={<FormattedMessage id={'rod.action.to_login'} />}
					variant={'primary'}
				/>
			</SuccessForm>
		);
	}
}

export default compose(withUI, withAuth)(ForgotPasswordSuccess);
