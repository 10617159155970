export const releaseTypes = [
	{
		id: 2,
		code: 'Album',
		is_compound: 1,
		title: 'Альбом',
	},
	{
		id: 69,
		code: 'Compilation',
		is_compound: 1,
		title: 'Компиляция',
	},
	{
		id: 64,
		code: 'EP',
		is_compound: 1,
		title: 'EP',
	},
	{
		id: 42,
		code: 'RingbackToneRelease',
		is_compound: 0,
		title: 'Рингбэктон Релиз',
	},
	{
		id: 43,
		code: 'RingtoneRelease',
		is_compound: 0,
		title: 'Рингтон Релиз',
	},
	{
		id: 51,
		code: 'Single',
		is_compound: 1,
		title: 'Сингл',
	},
	{
		id: 70,
		code: 'TikTokRelease',
		is_compound: 0,
		title: 'TikTok Релиз',
	},
];

export const getReleaseTypeById = (id) => {
	const lang = localStorage.getItem('lang');

	switch (id) {
		case 2:
			if (lang === 'ru') return 'альбом';
			return 'album';
		case 69:
			if (lang === 'ru') return 'компиляция';
			return 'compilation';
		case 64:
			return 'EP';
		case 42:
			if (lang === 'ru') return 'рингбэктон';
			return 'ringbackton';
		case 43:
			if (lang === 'ru') return 'рингтон';
			return 'ringtone';
		case 51:
			if (lang === 'ru') return 'сингл';
			return 'single';
		case 70:
			if (lang === 'ru') return 'TikTok';
			return 'TikTok';
		default:
			return '';
	}
};
