// Core
import { FormattedMessage } from 'react-intl';

export const getTypeTitle = (typeId, releasesIDs) => {
	if (releasesIDs.length && typeId) {
		let type = releasesIDs.find((el) => el.id === typeId);
		return type ? (
			type.title
		) : (
			<FormattedMessage id={'rod.release_type.other'} />
		);
	}
	return '';
};
