// Icons
import calendarImg from 'images/calendar_icon.svg';
import clearImg from 'images/close_button.svg';
import clearImgRed from 'images/close_button_red.svg';

// Styles
import styles from '../DateInput.module.css';

const DateInputIcon = ({
	date,
	errors,
	hideCross,
	isEdit,
	handleFocus,
	handleClear,
}) => {
	const showClearButton = date && (!hideCross || isEdit);
	const clearImgSrc = !errors ? clearImg : clearImgRed;

	return (
		<>
			{!showClearButton && (
				<img
					className={styles.Icon}
					src={calendarImg}
					alt="calendar"
					onClick={handleFocus}
				/>
			)}

			{showClearButton && (
				<button className={styles.Icon} onClick={handleClear}>
					<img src={clearImgSrc} alt="clear" />
				</button>
			)}
		</>
	);
};

export default DateInputIcon;
