import moment from 'moment';
export default function dateFormatEditor(
	currDate,
	format = 'dmy',
	splitter = '.'
) {
	if (!currDate) return '';

	splitter = splitter + '';

	if (typeof currDate === 'object') {
	} else if (currDate.includes('T')) {
		currDate = new Date(currDate);
	} else if (
		[10, 19].includes(currDate.length) &&
		typeof +currDate.slice(0, 4) === 'number' &&
		currDate.indexOf('-') !== -1
	) {
		currDate = new Date(currDate.replace(/-/g, '/'));
	} else if (
		[10].includes(currDate.length) &&
		typeof +currDate.slice(-4) === 'number' &&
		(currDate.indexOf('-') !== -1 || currDate.indexOf('.') !== -1)
	) {
		const dateInCorrectForm = `${currDate.slice(-4)}/${currDate.slice(
			3,
			5
		)}/${currDate.slice(0, 2)}`;
		currDate = new Date(dateInCorrectForm);
	} else if (
		[10, 19].includes(currDate.length) &&
		typeof +currDate.slice(0, 4) === 'number' &&
		currDate.indexOf('.') !== -1
	) {
		currDate = new Date(currDate.replace(/./g, '/'));
	} else if (
		[10, 19].includes(currDate.length) &&
		typeof +currDate.slice(-4) === 'number'
	) {
		const dateInCorrectForm = `${currDate.slice(-4)}/${currDate.slice(
			3,
			5
		)}/${currDate.slice(0, 2)}`;
		currDate = new Date(dateInCorrectForm);
	}
	const currMonth = currDate.getMonth() + 1;
	const currYear = currDate.getFullYear();
	let currDay = moment(currDate).format('MMM Do YY').split(' ')[1];
	currDay = isNaN(currDay.slice(0, 2))
		? currDay.slice(0, 1)
		: currDay.slice(0, 2);
	currDay = parseInt(currDay);
	let result;

	switch (format) {
		case 'ymd':
			result =
				currYear +
				splitter +
				currMonth.toString().padStart(2, '0') +
				splitter +
				currDay.toString().padStart(2, '0');
			break;
		case 'dmy':
			result =
				currDay.toString().padStart(2, '0') +
				splitter +
				currMonth.toString().padStart(2, '0') +
				splitter +
				currYear;

			break;

		default:
			break;
	}

	return result;
}
