/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

import React from 'react';
import { Col as WrappedCol } from 'react-grid-system';

const Col = (props) => {
	const { children, ...restProps } = props;
	return (
		<WrappedCol style={{
			margin: 0,
			padding: 0,
		}} {...restProps}>
			{children}
		</WrappedCol>
	);
};

export default Col;
