
import styles from './RoundButton.module.css'

const RoundedButton = ({ children, onClick, classes }) => {
  return (
    <div onClick={onClick} className={[styles.buttonGreen, classes].join(' ')}>
      {children}
    </div>
  )
}

export default RoundedButton;