import { cacheConfig } from 'cacheConfig';
import lscache from 'lscache';

export const setCache = (name, hash, response) => {
	const lang = localStorage.getItem('lang');
	
	if (cacheConfig[name].isFlag || cacheConfig[name].isDictionaries) {
		return lscache.set(hash, response, cacheConfig[name].lifeTime);
	}

	if (name === 'releaseTypesAll' || name === 'releaseTypes') {
		return lscache.set(
			hash,
			{ data: response, lang: lang },
			cacheConfig[name].lifeTime
		);
	}

	let { CACHE_ID_NAME, CACHE_ID_VALUES, lifeTime } = cacheConfig[name];
	if (localStorage.getItem(CACHE_ID_NAME)) {
		CACHE_ID_VALUES = JSON.parse(localStorage.getItem(CACHE_ID_NAME));
	}

	CACHE_ID_VALUES.push({
		hash: [hash],
		time: Date.now(),
	});

	localStorage.setItem(CACHE_ID_NAME, JSON.stringify(CACHE_ID_VALUES));

	if (CACHE_ID_VALUES.length <= 10) {
		lscache.set(hash, response, lifeTime);
	} else {
		const reqTimes = CACHE_ID_VALUES.map((item) => item.time);

		const oldestReqTime = Math.min(...reqTimes);

		const oldestReqData = CACHE_ID_VALUES.filter(
			(item) => item.time === oldestReqTime
		);

		const newRequestsData = CACHE_ID_VALUES.filter(
			(item) => item.time !== oldestReqTime
		);

		localStorage.setItem(CACHE_ID_NAME, JSON.stringify(newRequestsData));

		lscache.remove(oldestReqData[0].hash);
		lscache.set(hash, response, lifeTime);
	}
};
