// Core
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

// Utils
import { formatTimerTime } from 'utils';

// UI
import { Input } from 'components/Inputs';
import CodeInput from '../CodeInput/CodeInput';

// Styles
import styles from '../UserEditModal.module.css';

const EmailBlock = ({
	user,
	emailRef,
	emaiCodeRef,
	edit,
	userData,
	onChangeEmail,
	onChangeCode,
	emailSecondsLeft,
	showEmailCodeButton,
	handleSendCode,
	showCodeInput,
	showConfirmCodeButton,
	handleConfirm,
	errors,
	isEmailConfirmed,
	isResendButton,
	emailDisabled,
	hideEmailEdition,
	resendErrors,
	phoneSecondsLeft,
	showPhoneCodeInput,
}) => {
	const showError = (error) => {
		if (error.rule) {
			switch (error.rule) {
				case 'invalid':
					if (error.hasOwnProperty('remaining')) {
						if (error.remaining === 0) {
							return <FormattedHTMLMessage id={'rod.error.code_exhausted'} />;
						} else {
							return (
								<FormattedHTMLMessage
									id={'rod.error.code.invalid'}
									values={{ count: error.remaining }}
								/>
							);
						}
					} else {
						return <FormattedMessage id={'rod.error.email_format'} />;
					}

				case 'forbidden':
					return <FormattedHTMLMessage id={'rod.error.code_exhausted'} />;
				case 'not_uniques':
					return <FormattedHTMLMessage id={'rod.error.email_exists'} />;

				default:
					return <FormattedHTMLMessage id={`rod.error.${error.rule}`} />;
			}
		} else if (error.email) {
			return <FormattedHTMLMessage id={`rod.error.${error.email}`} />;
		} else {
			return <FormattedHTMLMessage id={`rod.error.${error}`} />;
		}
	};

	const isDisabled =
		!edit ||
		emailDisabled ||
		isEmailConfirmed ||
		phoneSecondsLeft > 0 ||
		showPhoneCodeInput ||
		(resendErrors &&
			resendErrors?.code &&
			(resendErrors.code[0]?.rule === 'code_exhausted' ||
				resendErrors.code[0]?.rule === 'forbidden' ||
				(resendErrors.code[0]?.rule === 'invalid' &&
					resendErrors.code[0]?.remaining === 0)));

	return (
		<div className={styles.confirmFields}>
			<FormattedMessage id={'rod.field.sms.helper'}>
				{(helper) => {
					return (
						<div className={styles.fieldInput}>
							<Input
								forwardRef={emailRef}
								className={isDisabled ? styles.disabled : ''}
								clearInputIfHasError={true}
								errors={errors?.email || resendErrors?.code}
								placeholder={user.login}
								value={userData.login}
								onChange={(s) => {
									onChangeEmail(s);
								}}
								name={'email'}
								showError={showError}
								helper={
									!showEmailCodeButton && !hideEmailEdition
										? `${helper} ${
												emailSecondsLeft > 0
													? formatTimerTime(emailSecondsLeft)
													: '0:00'
										  }`
										: undefined
								}
								disabled={isDisabled}
								doneIcon={isEmailConfirmed}
							/>
							{showEmailCodeButton && edit && !hideEmailEdition && (
								<button
									className={styles.resendBtn}
									style={errors?.email ? { bottom: 0 } : { bottom: '20px' }}
									onClick={handleSendCode}
								>
									<FormattedMessage
										id={
											isResendButton
												? 'rod.verify_sms.send_again'
												: 'rod.verify.send_code'
										}
									/>
								</button>
							)}
						</div>
					);
				}}
			</FormattedMessage>
			{showCodeInput && (
				<CodeInput
					showCodeButton={showEmailCodeButton}
					codeRef={emaiCodeRef}
					errors={errors}
					onChangeCode={onChangeCode}
					showError={showError}
					showConfirmCodeButton={showConfirmCodeButton}
					handleConfirm={handleConfirm}
				/>
			)}
		</div>
	);
};

export default EmailBlock;
