// Constants
import { isForCom } from 'constants/urlConstants';

export const getBalance = (balance) => {
	if (
		balance &&
		Object.keys(balance).length !== 0 &&
		balance.royalties &&
		balance.currency
	) {
		const formattedRoyalties = balance.royalties.replace(
			/(\d)(?=(\d{3})+(?!\d))/g,
			`$1\u00A0`
		);

		if (
			isForCom &&
			(balance.currency === '$' ||
				balance.currency === '£' ||
				balance.currency === '€' ||
				balance.currency === '₽')
		) {
			return balance.currency + ' ' + formattedRoyalties;
		}
		return formattedRoyalties + ' ' + balance.currency;
	} else {
		return '-';
	}
};
