// Core
import { useContext } from 'react';
import { FormattedHTMLMessage } from 'react-intl';

// Context
import { RootContext } from 'contexts/RootContext';

// Icons
import download_button from 'images/download_button.svg';

// Styles
import styles from './ReportItem.module.css';

const ReportItem = ({ report, setShowDownloadError }) => {
	const { getAccountPaymentsReportById } = useContext(RootContext);

	const accountId = localStorage.getItem('accountId');
	const lang = localStorage.getItem('lang');

	const handleDownloadReport = async (report) => {
		try {
			const data = await getAccountPaymentsReportById(accountId, report.id);
			if (data && data.file_id) {
				window.open(data.file_id, '_parent');
			} else {
				setShowDownloadError(true);
			}
		} catch (error) {
			console.error(error);
			setShowDownloadError(true);
		}
	};

	return (
		<div className={styles.container}>
			<span className={styles.reportName}>
				{lang === 'ru' ? report.heading : report.heading_en}
			</span>
			<button onClick={() => handleDownloadReport(report)}>
				<img src={download_button} alt="" />
				<span className={styles.btnText}>
					<FormattedHTMLMessage id={'rod.action.download'} />
				</span>
			</button>
		</div>
	);
};

export default ReportItem;
