// Core
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

// Styles
import styles from './InfoHelper.module.css';

const InfoHelper = ({ title, text }) => {
	return (
		<div className={styles.helper}>
			<div className={styles.helper__wrapper}>
				<span className={styles.helper__title}>
					<FormattedMessage
						id={!title ? 'rod.release.create.step.cover.helper' : title}
					/>
				</span>
				<br />
				<span className={styles.helper__main}>
					<FormattedHTMLMessage id={text} />
				</span>
			</div>
		</div>
	);
};

export default InfoHelper;
