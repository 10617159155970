import { FormattedMessage } from 'react-intl';

export const initialSteps = [
	{
		id: 1,
		status: 'active',
		title: <FormattedMessage id={'rod.add_composition_page.step1'} />,
	},
	{
		id: 2,
		status: 'default',
		title: <FormattedMessage id={'rod.add_composition_page.step2'} />,
	},
	{
		id: 3,
		status: 'default',
		title: <FormattedMessage id={'rod.add_composition_page.step3'} />,
	},
	{
		id: 4,
		status: 'default',
		title: <FormattedMessage id={'rod.add_composition_page.step4'} />,
	},
	{
		id: 5,
		status: 'default',
		title: <FormattedMessage id={'rod.add_composition_page.step5'} />,
	},
];
