// Core
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import numeral from 'numeral';
import classnames from 'classnames';

// UI
import { Label } from 'components/Labels/Label';
import { DropZone } from 'components/Upload/DropZone';

// Icons
import attachmentImg from 'images/attachment.svg';
import closeImg from 'images/close_black.svg';
import downloadImg from 'images/contract_download.svg';

// Styles
import styles from './SignListItem.module.css';

const SignListItem = (props) => {
	const {
		title,
		status,
		index,
		showFiles,
		showUpload,
		showDownload,
		showDownloadSigned,
		showDate,
		showStatus,
		date,
		hash,
		showHash,
		generated_url,
		signed_contract,
		errors,
		onDocumentUpload,
		onDocumentRemove,
		withoutIndex = false,
	} = props;

	const [files, setFiles] = useState();

	const upload = (files) => {
		for (const file of files) {
			const fileExtension = `.${file.name.split('.').pop()}`;
			if (fileExtension.toLowerCase() !== '.pdf') {
				const error = new Error(`Unsupported file format: ${fileExtension}.`);
				console.error('Documents - uploadAccountDocument error: ', error);
				return Promise.reject(error);
			}
		}
		setFiles(files[0]);
		onDocumentUpload(files[0]);
	};

	const remove = (fromButton = false) => {
		if (errors) {
			setFiles(null);
			onDocumentRemove(true);
		}
		if (fromButton) {
			setFiles(null);
			onDocumentRemove();
		}
	};

	const correctDateView = (date) => {
		if (date && typeof date === 'number') {
			const currDate = new Date(date);
			const currMonth = currDate.getMonth() + 1;
			const currYear = currDate.getFullYear();
			let currDay = moment().format('MMM Do YY').split(' ')[1];
			currDay = isNaN(currDay.slice(0, 2))
				? currDay.slice(0, 1)
				: currDay.slice(0, 2);
			let dateFrom =
				currDay.padStart(2, '0') +
				'.' +
				currMonth.toString().padStart(2, '0') +
				'.' +
				currYear;

			return dateFrom;
		} else {
			return date
				? date
						.replace(/(\d{1,4})-(\d{1,2})-(\d{1,2})/g, '$3.$2.$1')
						.slice(0, 10)
				: '';
		}
	};

	const localizeTitles = (title) => {
		return title?.replace('Payment', '');
	};
	return (
		<div className={styles.Item}>
			<div className={styles.Title}>
				<span className={styles.invoiceNumber}>
					{withoutIndex ? '' : `${index + 1}. `}
					<FormattedMessage
						id="rod.account.invoice-sign.invoice_number"
						values={{ value: localizeTitles(title) }}
					/>
				</span>
				{showDownload && generated_url && (
					<div className={styles.Download}>
						<a
							href={generated_url?.url}
							target="_blank"
							rel="noopener noreferrer"
						>
							<img src={downloadImg} alt="" />
							<Label font="--gilroy-Medium-16" black>
								<FormattedMessage
									id={`rod.field.contracts.contract_download`}
								/>
							</Label>
						</a>
					</div>
				)}
				{showDownloadSigned && signed_contract && (
					<div className={styles.Download}>
						<a
							href={signed_contract.url}
							target="_blank"
							rel="noopener noreferrer"
						>
							<img src={downloadImg} alt="" />
							<Label font="--gilroy-Medium-16" black>
								<FormattedMessage
									id={`rod.field.contracts.contract_download_signed`}
								/>
							</Label>
						</a>
					</div>
				)}
			</div>

			{showStatus && (
				<div className={classnames(styles.Status, status)}>
					<Label font="--gilroy-Medium-16" className={'status-title'}>
						<FormattedMessage
							id={'rod.field.contracts.contract_status.title'}
						/>
						:{' '}
						<FormattedMessage
							id={`rod.field.contracts.contract_status.value.${status}`}
						/>
					</Label>
				</div>
			)}
			{errors && errors.length > 0 && (
				<div className={styles.Error}>
					<Label font="--gilroy-Medium-16" className={'status-error'}>
						<div>
							<FormattedMessage
								id={'rod.field.contracts.contract_status.error'}
							/>
							:
							<ul>
								{errors.map((err) => (
									<li>
										<FormattedMessage
											id={`rod.field.contracts.contract_error.value.${err}`}
										/>
									</li>
								))}
							</ul>
						</div>
					</Label>
				</div>
			)}
			{showDate && date && (
				<div className={styles.Date}>
					<Label font="--gilroy-Medium-16">
						<FormattedMessage id={'rod.field.contracts.contract_status.date'} />
						: {correctDateView(date)}
					</Label>
				</div>
			)}
			{showHash && hash && (
				<div className={styles.Param}>
					<Label font="--gilroy-Medium-16">document hash: {hash}</Label>
				</div>
			)}
			<div className={styles.uploadBlock}>
				{showUpload && (
					<>
						<DropZone
							onUpload={upload}
							accept=".pdf"
							error={errors?.errors?.file[0]}
							files={files}
							isSignDocPage={true}
						/>
					</>
				)}
				{showFiles && files && (
					<ul className={styles.Files}>
						{
							<div className={styles.Uploaded}>
								<div className={styles.Line}>
									<img src={attachmentImg} alt="attachment" />
									<div className="filename">{files.name}</div>
									<div className="size">{numeral(files.size).format('0b')}</div>
									{props.canRemoveFile && (
										<div className={styles.Remove} onClick={() => remove(true)}>
											<img src={closeImg} alt="" />
										</div>
									)}
								</div>
							</div>
						}
					</ul>
				)}
			</div>
		</div>
	);
};

export default SignListItem;
