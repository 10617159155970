// Core
import React, {
	useEffect,
	useState,
	useRef,
	useCallback,
	useContext,
} from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

// Context
import { RootContext } from 'contexts/RootContext';

// UI
import RecordingLine from './RecordingLine/RecordingLine';
import { Pagination, Input } from 'material-design/components';
import { MainStatsCompositions } from 'material-design/components/MainStats/MainStatsCompositions';
import { withUI, withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';
import debounce from 'lodash.debounce';
import { accounts } from 'services';
import { Loading } from 'components';
import { RepertoireFilterModal } from 'material-design/modals/RepertoireFilterModal';
import ContentEmpty from './ContentEmpty/ContentEmpty';

// Icons
import close from 'images/close_button.svg';

// Data
import { filterRecordingSettingsData } from './data/filterSettingsData';

// Hooks
import { useOutsideClick } from 'material-design/hooks';

// Utils
import { handleFilterClear } from './utils/handleFilterClear';
import { handleClear } from './utils/handleClear';

// Styles
import styles from './RepertoireRecordingSection.module.css';

function RepertoirePhonogramSection(props) {
	const { statistics } = props;

	const prevFilters = JSON.parse(localStorage.getItem('prevFilters'));
	const prevStatus = localStorage.getItem('prevStatus');
	const prevPage = localStorage.getItem('prevPage');

	const [page, setPage] = useState(prevPage !== null ? +prevPage : 1);
	const [phonogramItems, setPhonogramItems] = useState([]);
	const [value, setValue] = useState('');
	const [total, setTotal] = useState(0);
	const [loading, setLoading] = useState(true);

	const {
		authContext: { accountId },
		UIContext: { isPlayerShow, play, handlePlay, audioId, handlePlayStop },
	} = props;

	const lang = localStorage.getItem('lang');

	const [modal, setModal] = useState(false);
	const [filterSettings, setFilterSettings] = useState(
		filterRecordingSettingsData
	);

	const [currentFilterSettings, setCurrentFilterSettings] = useState(
		prevFilters ?? {}
	);
	const [selectFilters, setSelectFilters] = useState(prevFilters ?? {});
	const [recordingsStatus, setRecordingsStatus] = useState(prevStatus ?? '');
	const [isFocus, setIsFocus] = useState(false);

	const history = useHistory();
	const resultRef = useRef(null);
	const helpRef = useRef(null);

	const modalOpen = (active) => setModal(active);
	const handleChangeFilter = (data) => setFilterSettings(data);
	const handleCurrentFilter = (data) => setCurrentFilterSettings(data);
	const onClose = () => {
		setIsFocus(false);
	};
	useOutsideClick(resultRef, onClose, isFocus, helpRef);

	const { getRepertoireDataByCiType } = useContext(RootContext);

	useEffect(() => {
		const firstFilter = currentFilterSettings
			? Object.values(currentFilterSettings)[0]?.show
				? Object.values(currentFilterSettings)[0]
				: ''
			: '';

		if (firstFilter) {
			setRecordingsStatus(firstFilter.code);
			localStorage.setItem('prevStatus', firstFilter.code);
		} else {
			setRecordingsStatus('');
			localStorage.removeItem('prevStatus');
		}
	}, [currentFilterSettings]);

	const handleChange = (e) => {
		onChange(e);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedChangeHandler = useCallback(debounce(handleChange, 500), [
		recordingsStatus,
	]);

	const onChange = (filter) => {
		setValue(filter);
		setLoading(true);
		getRecordingsData(filter);
		setPage(1);
		localStorage.setItem('prevPage', 1);
	};

	useEffect(() => {
		setLoading(true);
		getRecordingsData(value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [recordingsStatus]);

	useEffect(() => {
		if (page !== 'initial') {
			setLoading(true);
			getRecordingsData(value, page);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	const getRecordingsData = (filterText, page = prevPage) => {
		const searchByStatus = recordingsStatus.length > 0;
		switch (filterText.length >= 3) {
			case true:
				switch (searchByStatus) {
					case true:
						if (['active', 'expired', 'expiring'].includes(recordingsStatus)) {
							getRepertoireDataByCiType(
								accountId,
								'recordings',
								recordingsStatus,
								page,
								filterText
							)
								.then((res) => {
									setTotal(res.total);
									addAudioData(res.data);
								})
								.catch((error) => {
									console.info('Error', error);
								})
								.finally(() => {
									setLoading(false);
								});
						} else {
							accounts
								.getAssetsPageTextByStatus(
									accountId,
									filterText,
									page,
									'recordings',
									`status=${recordingsStatus}`
								)
								.then((res) => {
									res = res.data.data;
									setTotal(res.total);
									addAudioData(res.data);
								})
								.catch((error) => {
									console.info('Error', error);
								});
						}
						break;
					case false:
						accounts
							.getAssetsPageText(accountId, filterText, page, 'recordings')
							.then((res) => {
								res = res.data.data;
								setTotal(res.total);
								addAudioData(res.data);
							})
							.catch((error) => {
								console.info('Error', error);
							});
						break;
					default:
						break;
				}
				break;
			case false:
				switch (searchByStatus) {
					case true:
						if (['active', 'expired', 'expiring'].includes(recordingsStatus)) {
							getRepertoireDataByCiType(
								accountId,
								'recordings',
								recordingsStatus,
								page,
								filterText
							)
								.then((res) => {
									setTotal(res.total);
									addAudioData(res.data);
								})
								.catch((error) => {
									console.info('Error', error);
								})
								.finally(() => {
									setLoading(false);
								});
						} else {
							accounts
								.getRecordingsPageByStatus(accountId, recordingsStatus, page)
								.then((res) => {
									res = res.data;
									setTotal(res.total);
									addAudioData(res.data);
								})
								.catch((error) => {
									console.info('Error', error);
								});
						}
						break;
					case false:
						accounts
							.getAssetsPage(accountId, 'recordings', page)
							.then((res) => {
								res = res.data.data;
								setTotal(res.total);
								addAudioData(res.data);
							})
							.catch((error) => {
								console.info('Error', error);
							});
						break;
					default:
						break;
				}
				break;
			default:
				break;
		}
	};

	const {
		UIContext: { upload, isAccountFeatureRecordingView },
	} = props;

	const changeStatusFromStatsBlock = (selectedStatus) => {
		if (selectedStatus === '') {
			handleClear(
				filterSettings,
				setFilterSettings,
				setCurrentFilterSettings,
				setSelectFilters
			);
			return;
		}

		const item = filterSettings[0];
		const setting = item['list'].find((obj) => obj.code === selectedStatus);

		item.list.map((obj) =>
			obj.id === setting.id ? (obj.checked = true) : (obj.checked = false)
		);

		const newCurrentFilter = {};
		newCurrentFilter['' + item.id + setting.id] = {
			parentId: item.id,
			currentId: setting.id,
			id: '' + item.id + setting.id,
			title_en: item.title_en,
			title_ru: item.title_ru,
			name_en: setting.name_en,
			name_ru: setting.name_ru,
			code: setting.code,
			show: true,
		};

		setCurrentFilterSettings(newCurrentFilter);
		setSelectFilters(newCurrentFilter);
		setPage(1);
		localStorage.setItem('prevPage', 1);
		localStorage.setItem('prevFilters', JSON.stringify(newCurrentFilter));
		setFilterSettings([item]);
	};

	async function addAudioData(phonograms) {
		let result = [];
		let count = 0;
		if (phonograms.length === 0) {
			setPhonogramItems([]);
			setLoading(false);
		} else
			await phonograms.forEach((item, index) => {
				accounts.getRecordingAudio(accountId, item.id).then((res) => {
					count += 1;
					res = res.data;
					item['audio'] = res;
					result.push(item);
					if (phonograms.length === count) {
						const sorted = result.sort((a, b) =>
							a.title.localeCompare(b.title)
						);
						setPhonogramItems(sorted);
						setLoading(false);
					}
				});
			});
	}

	return (
		<>
			<MainStatsCompositions
				data={statistics}
				changeStatus={changeStatusFromStatsBlock}
				parent={'recordings'}
			/>
			<div className={styles.inputWrapper}>
				<Input
					curRef={helpRef}
					handleOnChange={debouncedChangeHandler}
					setIsFocus={setIsFocus}
					setActive={modalOpen}
					filter={currentFilterSettings}
					parent={'releaseSection'}
				/>
				{Object.values(currentFilterSettings).some((i) => i.show === true) && (
					<>
						<ul className={styles.filter}>
							{Object.keys(currentFilterSettings).map(
								(item) =>
									currentFilterSettings[item].show && (
										<li
											className={styles.filter__item}
											key={currentFilterSettings[item].id}
											onClick={() =>
												handleFilterClear(
													currentFilterSettings[item],
													filterSettings,
													setFilterSettings,
													currentFilterSettings,
													setCurrentFilterSettings
												)
											}
										>
											<span>
												{lang === 'en'
													? `${currentFilterSettings[item].title_en}:
													${currentFilterSettings[item].name_en}`
													: `${currentFilterSettings[item].title_ru}:
													${currentFilterSettings[item].name_ru}`}
											</span>
											<img
												src={close}
												width={16}
												onClick={() =>
													handleFilterClear(
														currentFilterSettings[item],
														filterSettings,
														setFilterSettings,
														currentFilterSettings,
														setCurrentFilterSettings
													)
												}
												alt=""
											/>
										</li>
									)
							)}

							<li className={styles.filter__itemButton}>
								<button
									className={styles.btnAsLink}
									type="button"
									onClick={() =>
										handleClear(
											filterSettings,
											setFilterSettings,
											setCurrentFilterSettings,
											setSelectFilters
										)
									}
								>
									<FormattedMessage id={'rod.repertoire.clear_all'} />
								</button>
							</li>
						</ul>
					</>
				)}
			</div>
			{loading ? (
				<Loading className={styles.loader} />
			) : phonogramItems && phonogramItems.length ? (
				<div className={styles.repertoire__mainContent}>
					<div>
						<div
							className={`${styles.repertoire__phonogramTitle} ${styles.song__table} ${styles.adaptive__songTitle}`}
						>
							<div style={{ display: 'none' }}></div>
							<FormattedHTMLMessage id={'rod.for_all.title'} />
							<FormattedHTMLMessage id={'rod.for_all.subtitle'} />
							<FormattedHTMLMessage id={'rod.for_all.performers'} />
							<FormattedHTMLMessage id={'rod.for_all.status'} />
							<div style={{ display: 'hidden' }}></div>
							<div style={{ display: 'hidden' }}></div>
						</div>

						<ul className={styles.repertoire__phonogramItemsWrapper}>
							{phonogramItems.map((item, index) => (
								<li className={styles.itemWrapper}>
									{isAccountFeatureRecordingView ? (
										// eslint-disable-next-line jsx-a11y/anchor-is-valid
										<a
											onClick={(e) => {
												!e.target.getAttribute('data-tag') &&
													history.push(`/repertoire/recordings/${item.id}`);
											}}
										>
											<RecordingLine
												item={item}
												index={index}
												audioId={audioId}
												play={play}
												handlePlay={handlePlay}
												handlePlayStop={handlePlayStop}
											/>
										</a>
									) : (
										<RecordingLine
											item={item}
											index={index}
											audioId={audioId}
											play={play}
											handlePlay={handlePlay}
											handlePlayStop={handlePlayStop}
										/>
									)}
								</li>
							))}
						</ul>
					</div>

					<div style={{ marginBottom: isPlayerShow ? '50px' : '0px' }}>
						<Pagination
							page={page}
							total={total}
							paginate={(pageNumber) => {
								setPage(pageNumber);
								localStorage.setItem('prevPage', pageNumber);
							}}
						/>
					</div>
				</div>
			) : (
				<ContentEmpty upload={upload} />
			)}
			<RepertoireFilterModal
				active={modal}
				setActive={modalOpen}
				filterData={filterSettings}
				setFilterData={handleChangeFilter}
				currentFilterSettings={currentFilterSettings}
				setCurrentFilter={handleCurrentFilter}
				selectFilters={selectFilters}
				setSelectFilters={setSelectFilters}
				setPage={setPage}
				whiteTheme
			/>
		</>
	);
}

export default compose(withUI, withAuth, withRoot)(RepertoirePhonogramSection);
