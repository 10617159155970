// Core
import { FormattedMessage } from 'react-intl';

// Styles
import styles from './ValidationError.module.css';

const ValidationError = ({ errors, newRecording, field }) => {
	return (
		<>
			{(errors &&
				errors.id === newRecording.id &&
				Object.keys(errors).includes(`${field}_max_length`)) ||
				(errors &&
					'status' in errors &&
					Object.keys(errors).length > 0 &&
					errors.id === newRecording.id &&
					Object.keys(errors.errors).includes(field) &&
					errors.errors[field][0].rule === 'max_length' && (
						<span className={styles.helper}>
							{
								<FormattedMessage
									id={`rod.performance_work.max_length.validation.error`}
								/>
							}
						</span>
					))}
		</>
	);
};

export default ValidationError;
