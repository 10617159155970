// Core
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { useState } from 'react';

// Services
import { getReleaseTypeById } from 'utils';

// Utils
import { formatListens } from './utils/formatListens';

// UI
import no_cover from 'images/no_cover.svg';

// Styles
import styles from './TopReleaseCard.module.css';

const TopReleaseCard = ({ release }) => {
	const [imgError, setImgError] = useState(false);

	const handleImgError = () => {
		setImgError(true);
	};

	return (
		<Link
			key={release.id}
			to={`/repertoire/releases/${release.id}/info`}
			className={styles.link}
		>
			<div className={styles.card}>
				<div className={styles.image}>
					{!release.cover || imgError ? (
						<div className={styles.noCover}>
							<div>
								<img src={no_cover} className={styles.imgNoCover} alt="" />
								<span className={styles.noCoverTitle}>
									<FormattedMessage id={'rod.release.info.no-cover'} />
								</span>
							</div>
						</div>
					) : (
						<img
							className={styles.cover}
							src={release.cover}
							alt=""
							onError={handleImgError}
						/>
					)}
				</div>
				<div className={styles.album}>
					{release.release_type_id && (
						<span>{getReleaseTypeById(release.release_type_id)}</span>
					)}
					{release.moderation_status && (
						<span>
							<FormattedMessage
								id={`rod.repertoire.${release.moderation_status}`}
							/>
						</span>
					)}
				</div>
				<div className={styles.title} title={release.title}>
					{release.title ?? '-'}
				</div>
				<div
					className={styles.performer}
					title={
						release.performers &&
						release.performers.length &&
						release.performers.join(' ')
					}
				>
					{release.performers && release.performers.length
						? release.performers.join(' ')
						: '-'}
				</div>
				<div className={styles.streams}>
					<span className={styles.streams__text}>
						<FormattedMessage id={'rod.release.info.streams'} />:
					</span>{' '}
					<span>{formatListens(release.listens) ?? '-'}</span>
				</div>
			</div>
		</Link>
	);
};

export default TopReleaseCard;
