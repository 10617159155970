// Core
import lscache from 'lscache';

// Utils
import { modifyTitle } from './modifyTitle';

export const getCountries = (
	isReqLive,
	setCountries,
	dictionaries,
	setCache
) => {
	const countriesCache = lscache.get('countriesCashe');
	if (countriesCache && !isReqLive) {
		const countries = countriesCache.map((obj) => {
			let result = obj;
			result.title_en = modifyTitle(obj.title_en);
			result.title_ru = modifyTitle(obj.title_ru);
			return result;
		});
		setCountries(countries);
		return countries;
	} else {
		return dictionaries
			.getCountries()
			.then((res) => {
				res = res.data.data;
				setCache('countries', 'countriesCashe', res);

				const countries = res.map((obj) => {
					let result = obj;
					result.title_en = modifyTitle(obj.title_en);
					result.title_ru = modifyTitle(obj.title_ru);
					return result;
				});

				setCountries(countries);

				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				return Promise.reject();
			});
	}
};
