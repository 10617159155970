export default function dateFormat(str) {
	let res = '';
	let isoDateTime;
	if (!str) return res;
	try {
		isoDateTime = new Date(str.getTime()).toISOString();
	} catch (wrong) {
		return str;
	}

	return isoDateTime;
}
