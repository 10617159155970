const itemsLexeme = 'rod.field';

export const dropdownItems = [
	{
		keyword: 'statute',
		lexeme: `${itemsLexeme}.statute`,
	},
	{
		keyword: 'agreement',
		lexeme: `${itemsLexeme}.agreement`,
	},
	{
		keyword: 'attorney',
		lexeme: `${itemsLexeme}.attorney`,
	},
];
