import s from './RepertoireReleaseModerationItem.module.css';
import { Col, Row } from 'react-grid-system';

// data =  { title_en: currentTitle, tracks: reasonsByTitle };

export default function RepertoireReleaseModerationItem({ data, lang }) {
	//{title_en: 'Hatred', title_ru: 'Ненависть', track_id: null, track_name: null, category: 'release_metadata'}
	return (
		<>
			<ul className={s.issue_wrapper}>
				{data &&
					data.map((item, index) => (
						<li key={item.track_id}>
							<Row className={s.issue_element}>
								<Col width={275} className={s.text_left}>
									<span>{index === 0 ? item[`title_${lang}`] : ''}</span>
								</Col>
								<Col width={80}>
									<span>
										<svg
											width="10"
											height="10"
											viewBox="0 0 10 10"
											fill={
												item['category'].includes('cover')
													? '#F75E63'
													: '#C4C4C4'
											}
											xmlns="http://www.w3.org/2000/svg"
										>
											<rect width="10" height="10" />
										</svg>
									</span>
								</Col>
								<Col width={140}>
									<span>
										<svg
											width="10"
											height="10"
											viewBox="0 0 10 10"
											fill={
												item['category'].includes('release_metadata')
													? '#F75E63'
													: '#C4C4C4'
											}
											xmlns="http://www.w3.org/2000/svg"
										>
											<rect width="10" height="10" />
										</svg>
									</span>
								</Col>
								<Col width={100}>
									<span>
										<svg
											width="10"
											height="10"
											viewBox="0 0 10 10"
											fill={
												item['category'].includes('track_metadata')
													? '#F75E63'
													: '#C4C4C4'
											}
											xmlns="http://www.w3.org/2000/svg"
										>
											<rect width="10" height="10" />
										</svg>
									</span>
								</Col>
								<Col width={110}>
									<span>
										<svg
											width="10"
											height="10"
											viewBox="0 0 10 10"
											fill={
												item['category'].includes('audio')
													? '#F75E63'
													: '#C4C4C4'
											}
											xmlns="http://www.w3.org/2000/svg"
										>
											<rect width="10" height="10" />
										</svg>
									</span>
								</Col>
								<Col className={s.track}>
									<span>{item['track_name']}</span>
								</Col>
							</Row>
						</li>
					))}
			</ul>
		</>
	);
}
