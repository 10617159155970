// Core
import { FormattedMessage } from 'react-intl';

export const roles = [
	{
		id: 1,
		item: <FormattedMessage id={'rod.muiAuthor.author_of_lyrics'} />,
		logo: 'A',
		disabled: false,
	},
	{
		id: 2,
		item: <FormattedMessage id={'rod.muiAuthor.composer_writer'} />,
		logo: 'C',
		disabled: false,
	},
	{
		id: 3,
		item: <FormattedMessage id={'rod.muiAuthor.composer_author'} />,
		logo: 'CA',
		disabled: false,
	},
	{
		id: 4,
		item: <FormattedMessage id={'rod.muiAuthor.arranger'} />,
		logo: 'AR',
		disabled: false,
	},
	{
		id: 5,
		item: <FormattedMessage id={'rod.muiAuthor.adaptor'} />,
		logo: 'AD',
		disabled: false,
	},
	{
		id: 6,
		item: <FormattedMessage id={'rod.muiAuthor.translator'} />,
		logo: 'TR',
		disabled: false,
	},
];

export const ACDisabledRoles = [
	{
		id: 1,
		item: <FormattedMessage id={'rod.muiAuthor.author_of_lyrics'} />,
		logo: 'A',
		disabled: true,
	},
	{
		id: 2,
		item: <FormattedMessage id={'rod.muiAuthor.composer_writer'} />,
		logo: 'C',
		disabled: true,
	},
	{
		id: 3,
		item: <FormattedMessage id={'rod.muiAuthor.composer_author'} />,
		logo: 'CA',
		disabled: false,
	},
	{
		id: 4,
		item: <FormattedMessage id={'rod.muiAuthor.arranger'} />,
		logo: 'AR',
		disabled: false,
	},
	{
		id: 5,
		item: <FormattedMessage id={'rod.muiAuthor.adaptor'} />,
		logo: 'AD',
		disabled: false,
	},
	{
		id: 6,
		item: <FormattedMessage id={'rod.muiAuthor.translator'} />,
		logo: 'TR',
		disabled: false,
	},
];

export const CADisabledRoles = [
	{
		id: 1,
		item: <FormattedMessage id={'rod.muiAuthor.author_of_lyrics'} />,
		logo: 'A',
		disabled: false,
	},
	{
		id: 2,
		item: <FormattedMessage id={'rod.muiAuthor.composer_writer'} />,
		logo: 'C',
		disabled: false,
	},
	{
		id: 3,
		item: <FormattedMessage id={'rod.muiAuthor.composer_author'} />,
		logo: 'CA',
		disabled: true,
	},
	{
		id: 4,
		item: <FormattedMessage id={'rod.muiAuthor.arranger'} />,
		logo: 'AR',
		disabled: false,
	},
	{
		id: 5,
		item: <FormattedMessage id={'rod.muiAuthor.adaptor'} />,
		logo: 'AD',
		disabled: false,
	},
	{
		id: 6,
		item: <FormattedMessage id={'rod.muiAuthor.translator'} />,
		logo: 'TR',
		disabled: false,
	},
];
