// Core
import { FormattedMessage } from 'react-intl';

// Styles
import styles from '../StatisticByDatesChart.module.css';

const MainContentHeader = ({
	amountYAxisTitle,
	isCumulativeBttActive,
	chartModeSwitch,
}) => {
	return (
		<div className={styles.statistic__mainContentHeader}>
			<div className={styles.statistic__mainContentHeaderInfo}>
				<div className={styles.statistic__mainContentHeaderInfo_title}>
					<FormattedMessage id={'rod.statistic.chart-title'} />
				</div>
				<div className={styles.statistic__mainContentHeaderInfo_amount}>
					{amountYAxisTitle && <FormattedMessage id={`${amountYAxisTitle}`} />}
				</div>
			</div>
			<div className={styles.statistic__mainContentButtons}>
				<button
					className={`${styles.statistic__mainContentButtonsFirst} ${
						isCumulativeBttActive
							? styles.statistic__mainContentButtonsActive
							: ''
					}`}
					onClick={chartModeSwitch}
				>
					<FormattedMessage id={'rod.statistic.chart-accumulative'} />
				</button>
				<button
					className={`${styles.statistic__mainContentButtonsSecond} ${
						!isCumulativeBttActive
							? styles.statistic__mainContentButtonsActive
							: ''
					}`}
					onClick={chartModeSwitch}
				>
					<FormattedMessage id={'rod.statistic.chart-comparative'} />
				</button>
			</div>
		</div>
	);
};

export default MainContentHeader;
