/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import { withForwardRef } from '../../../hocs';
import styles from './SMSCode.module.css';

const CODE_LENGTH = 4;

class SMSCode extends PureComponent {

	static propTypes = {
		/**
		 * Forward class name.
		 */
		className: PropTypes.string,

		/**
		 * Defines HTML input type attribute.
		 *
		 * @default 'text'
		 */
		type: PropTypes.string,
	};

	static defaultProps = {
		type: 'text',
		value: '46',
	};

	ref = React.createRef();

	componentDidMount() {
		this.ref.current.focus();
		// this.ref.current.selectionEnd = 2;
		// this.ref.current.selectionStart = 2;
	}

	onFocus = (...args) => {
		const position = CODE_LENGTH - this.props.value.length;
		setTimeout(() => {
			this.ref.current.selectionEnd = position;
			this.ref.current.selectionStart = position;
		}, 50);

		if (this.props.onFocus) {
			this.props.onFocus(...args);
		}
	};

	render() {
		const { error, value, className, forwardRef, ...props } = this.props;

		const classes = classnames(
			className,
			'auth-input',
			error && 'error',
			styles.Input,
		);

		if (forwardRef) {
			this.ref = forwardRef;
		}

		const val = value + '_'.repeat(CODE_LENGTH - value.length);

		return (
			<input
				ref={this.ref}
				value={val}
				maxLength={CODE_LENGTH}
				className={classes}
				onFocus={this.onFocus}
				{...props}
			/>
		);
	}
}

export default compose(
	withForwardRef,
)(SMSCode);