export const handleSetUPC = (value, personal, setPersonal) => {
	personal.generate_ean = !value;

	if (personal.generate_ean) {
		personal.ean = null;
	}

	setPersonal({ ...personal });
};

export const handleSetGRID = (value, personal, setPersonal) => {
	personal.generate_grid = !value;

	if (personal.generate_grid) {
		personal.grid = null;
	}

	setPersonal({ ...personal });
};

export const handleSetCatalogNumber = (value, personal, setPersonal) => {
	personal.generate_catalog_number = !value;

	if (personal.generate_catalog_number) {
		personal.catalog_number = null;
	}

	setPersonal({ ...personal });
};

export const handleISRC = (
	value,
	personal,
	setPersonal,
	selectPerson,
	toogleMenu
) => {
	selectPerson.generate_isrc = !value;
	if (selectPerson.generate_isrc) {
		selectPerson.isrc = '';
	}
	personal[toogleMenu] = selectPerson;
	setPersonal([...personal]);
};
