// Core
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Container, Row } from 'react-grid-system';

// Utils
import { generateContactsSteps } from '../utils/generateContactsSteps';

// UI
import { Loading } from 'components';
import { Button } from 'components/Buttons/Button';
import { FormInput } from 'components/Form/FormInput';
import { Label } from 'components/Labels/Label';
import { InfoButton } from 'components/Buttons';
import { Wizard } from 'components/Wizard';
import accountTypes from 'constants/accountTypes';
import { withAuth, withLang, withRoot, withUI } from 'hocs';
import update from 'immutability-helper';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import { compose } from 'recompose';
import { getHelpMessage } from '../../../../../messages/helper';

// Styles
import styles from './Contacts.module.css';

class Contacts extends PureComponent {
	static propTypes = {};

	constructor(props) {
		super(props);
		this.form = React.createRef();
		this.steps = generateContactsSteps(props.personalData.business_type);
		this.state = {
			loading: true,
			contacts: {},
		};
	}

	isEmailValid = (email) => {
		const regular = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return regular.test(String(email).toLowerCase());
	};

	nextHandler = (e) => {
		const err = {};
		e.preventDefault();

		if (!this.isEmailValid(this.state.contacts.email)) {
			err['email'] = [{ rule: 'email_format' }];
		}
		if (!this.state.contacts.city) {
			err['city'] = [{ rule: 'required' }];
		}
		if (typeof this.props.onChange === 'function') {
			this.props.onChange({
				data: {
					...this.state.contacts,
				},
				nonBackErrors: err,
			});
		}
	};

	componentDidMount() {
		this.update();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.id !== this.props.id) {
			this.update();
		}

		if (
			prevProps.personalData.business_type !==
			this.props.personalData.business_type
		) {
			this.steps = generateContactsSteps(this.props.personalData.business_type);
		}
	}

	update() {
		const {
			authContext: { changeWidget, setIsAccountLoading },
			rootContext: { getAccountContactsData },
		} = this.props;
		setIsAccountLoading(true);
		changeWidget();
		getAccountContactsData(this.props.id)
			.then((res) => {
				res = res.data.data;
				const { ...rest } = res;
				this.setState(
					update(this.state, {
						contacts: { $set: { ...rest } },
						loading: { $set: false },
					})
				);
				setIsAccountLoading(false);
			})
			.catch((error) => {
				console.error('Contacts - getAccountContactsData error: ', error);
				console.error(
					'Contacts - getAccountContactsData error.response: ',
					error.response
				);
				setIsAccountLoading(false);
			});
	}

	changeField = (field) => (value) => {
		if (field === 'country_id') {
			!this.state.contacts.phone_number
				? this.setState(
						update(this.state, {
							contacts: {
								phone_code_country_id: { $set: value },
								country_id: { $set: value },
							},
						})
				  )
				: this.setState(
						update(this.state, {
							contacts: {
								country_id: { $set: value },
							},
						})
				  );
		} else {
			this.setState(
				update(this.state, { contacts: { [field]: { $set: value } } })
			);
		}
	};

	changeFields = (query) => {
		this.setState(update(this.state, { contacts: query }));
	};

	render() {
		const {
			personalData: { business_type },
			UIContext: { showModal },
			handleOpenPrevTabs,
		} = this.props;

		const lang = localStorage.getItem('lang');

		const message = DOMPurify.sanitize(...getHelpMessage(lang, 'contacts'));

		if (this.state.loading) {
			return <Loading non_margin={true} />;
		}

		return (
			<Container fluid className={styles.Page}>
				<Wizard
					steps={this.steps}
					className={styles.Wizard}
					handleOpenPrevTabs={handleOpenPrevTabs}
				/>
				<div className={styles.Form}>
					<Row>
						<Col sm={11} offset={{ sm: 1 }} className={styles.Header}>
							<InfoButton
								onClick={showModal({
									text: parse(message),
								})}
							>
								<Label
									text={
										<FormattedMessage
											id={'rod.account.create.contacts.title'}
										/>
									}
									font="--gilroy-Medium-40"
									display="inline"
									black
								/>
							</InfoButton>
						</Col>
					</Row>
					<form onSubmit={this.nextHandler} ref={this.form}>
						{(business_type === accountTypes.PERSONAL ||
							business_type === accountTypes.PERSONAL_ENTREPRENEUR) &&
							this.renderPersonalFields()}
						{business_type === accountTypes.COMPANY &&
							this.renderCompanyFields()}
						<Row className={styles.Controls}>{this.renderControls()}</Row>
					</form>
				</div>
			</Container>
		);
	}

	renderPersonalFields() {
		const {
			rootContext: { countries },
			errors,
		} = this.props;
		const { contacts } = this.state;
		return (
			<div>
				<Row>
					<Col sm={5} offset={{ sm: 1 }}>
						<FormattedMessage id={'rod.field.country'}>
							{(placeholder) => (
								<FormInput
									type="country"
									countries={countries}
									placeholder={placeholder}
									name={'country_id'}
									onChange={this.changeField}
									errors={errors}
									required
									data={contacts}
									className={styles.CountryInput}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.zip'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'zip_code'}
									onChange={this.changeField}
									errors={errors}
									required
									data={contacts}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.admin.account.city'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'city'}
									onChange={this.changeField}
									errors={errors}
									data={contacts}
									required
								/>
							)}
						</FormattedMessage>
						<div className={styles.street}>
							<FormattedMessage id={'rod.field.address_noCity'}>
								{(placeholder) => (
									<FormInput
										placeholder={placeholder}
										name="address"
										onChange={this.changeField}
										errors={errors}
										required
										data={contacts}
									/>
								)}
							</FormattedMessage>
						</div>
					</Col>
					<Col sm={5}>
						<FormattedMessage id={'rod.field.phone'}>
							{(placeholder) => (
								<FormInput
									type={'phone'}
									placeholder={placeholder}
									names={['phone_number', 'phone_code_country_id']}
									onChanges={this.changeFields}
									errors={errors}
									required
									data={contacts}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.email'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'email'}
									onChange={this.changeField}
									errors={errors}
									required
									data={contacts}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.website'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'site_url'}
									onChange={this.changeField}
									errors={errors}
									data={contacts}
								/>
							)}
						</FormattedMessage>
					</Col>
				</Row>
			</div>
		);
	}

	renderCompanyFields() {
		const { contacts } = this.state;
		const {
			rootContext: { countries },
			errors,
		} = this.props;

		return (
			<div>
				<Row>
					<Col sm={5} offset={{ sm: 1 }}>
						<FormattedMessage id={'rod.field.country'}>
							{(placeholder) => (
								<FormInput
									type="country"
									countries={countries}
									placeholder={placeholder}
									name={'country_id'}
									onChange={this.changeField}
									errors={errors}
									required
									data={contacts}
									className={styles.CountryInput}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.zip'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'zip_code'}
									onChange={this.changeField}
									errors={errors}
									required
									data={contacts}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.admin.account.city'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'city'}
									onChange={this.changeField}
									errors={errors}
									data={contacts}
									required
								/>
							)}
						</FormattedMessage>
						<div className={styles.street}>
							<FormattedMessage id={'rod.field.address_noCity'}>
								{(placeholder) => (
									<FormInput
										placeholder={placeholder}
										name="address"
										onChange={this.changeField}
										errors={errors}
										required
										data={contacts}
									/>
								)}
							</FormattedMessage>
						</div>
					</Col>
					<Col sm={5}>
						<FormattedMessage id={'rod.field.phone'}>
							{(placeholder) => (
								<FormInput
									type={'phone'}
									placeholder={placeholder}
									names={['phone_number', 'phone_code_country_id']}
									onChanges={this.changeFields}
									errors={errors}
									required
									data={contacts}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.email'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'email'}
									onChange={this.changeField}
									errors={errors}
									required
									data={contacts}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.company_website'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'site_url'}
									onChange={this.changeField}
									errors={errors}
									data={contacts}
								/>
							)}
						</FormattedMessage>
					</Col>
				</Row>
			</div>
		);
	}

	renderControls() {
		return (
			<React.Fragment>
				<Col md={5} xs={12} sm={12} offset={{ md: 1 }}>
					<div className={styles.FieldsCol}>
						<Button
							text={<FormattedMessage id={'rod.action.next'} />}
							variant={'primary'}
							onClick={this.nextHandler}
						/>
					</div>
				</Col>
			</React.Fragment>
		);
	}
}

export default compose(withRoot, withAuth, withLang, withUI)(Contacts);
