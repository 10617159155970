import * as React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import s from './MuiMultipleCheckedTracks.module.css';

export default function MuiMultipleCheckedAnimation() {
	return (
		<Box className={s.sceletonWrapper}>
			<Skeleton height={36} />
			<Skeleton animation="wave" height={36} />
			<Skeleton animation="wave" height={36} />
			<Skeleton animation="wave" height={36} />
			<Skeleton animation="wave" height={36} />
			<Skeleton animation={false} height={36} />
		</Box>
	);
}
