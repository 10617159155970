/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 7/26/19 2:41 PM
 */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthConsumer } from '../contexts/AuthContext';

const ProtectedRoute = ({ component: Component, ...rest }) => (
	<AuthConsumer>
		{({ isAuth }) => (
			<Route
				render={props =>
					isAuth ? <Component {...props} /> : <Redirect to="/login"/>
				}
				{...rest}
			/>
		)}
	</AuthConsumer>
);

export default ProtectedRoute;

