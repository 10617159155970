/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 15/04/2021, 22:27
 */

import classnames from 'classnames';
import React, { PureComponent } from 'react';
// import PropTypes from 'prop-types';
import styles from './Flag.module.css';

class Flag extends PureComponent {
	render() {
		const { className, code, dialing_code } = this.props;
		//const {} = this.state;
		let flagCode = code;
		if (code === 'CS' && dialing_code === 420) {
			flagCode = 'CZS';
		}

		const classes = classnames(
			className,
			styles.flag,
			styles[flagCode.toLowerCase()]
		);

		return <div className={classes} />;
	}
}

Flag.propTypes = {};

Flag.defaultProps = {};

export default Flag;
