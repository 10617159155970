/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

import React, { Component } from 'react';
import styles from './Progress.module.css';

class Progress extends Component {
	render() {
		return (
			<div className={styles.Progress}>
				<div className={styles.Bar} style={{ width: `${this.props.value}%` }}/>
			</div>
		);
	}
}

export default Progress;
