import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { TextFieldInput } from 'material-design/MuiStyled/MuiStyled';

const MuiAutocomplete = ({ onChange, items, label, value, required }) => {
	return (
		<Autocomplete
			id="disable-clearable"
			disableClearable
			onChange={(_, obj) => onChange(obj.title)}
			options={items}
			value={{ title: value }}
			defaultValue={{ title: value }}
			getOptionLabel={(option) => option.title}
			isOptionEqualToValue={(option, value) => option.title === value.title}
			renderInput={(params) => (
				<TextFieldInput
					{...params}
					label={label}
					variant="standard"
					required={required}
				/>
			)}
		/>
	);
};

export default MuiAutocomplete;
