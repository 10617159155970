// Core
import { FormattedMessage } from 'react-intl';
import React, { useContext, useEffect, useState } from 'react';
import { PoseGroup } from 'react-pose';

// Services
import { accounts } from 'services';

// Context
import { UIContext } from 'contexts/UIContext';

// UI
import BaseModal from '../BaseModal';
import { Button } from 'components/Buttons';
import { Container } from 'components/Grid';
import { Label } from 'components/Labels/Label';
import { Modal, Shade } from './posed/posed';

// Icons
import close_black from 'images/close_black.svg';

// Styles
import styles from '../Modal.module.css';

const RemoveModal = (props) => {
	const [isVisible, setIsVisible] = useState(false);
	const [errors, setErrors] = useState({});

	const { setCurrentDraftCount, removeOneDraft } = useContext(UIContext);
	const accountId = localStorage.getItem('accountId');

	useEffect(() => {
		setIsVisible(true);

		return () => {
			setIsVisible(false);
		};
	}, []);

	useEffect(() => {
		accounts
			.getAssetsDrafts(accountId)
			.then((res) => {
				setCurrentDraftCount(res.data.total);
			})
			.catch((error) => {
				console.error('Error', error);
			});
	}, []);

	const handleDelete = () => {
		if (props.handleDeletePseudonym) {
			const { accountId, pseudonymId } = props;
			props.handleDeletePseudonym(accountId, pseudonymId);
			props.onClose();
		} else {
			const {
				id,
				draft_type,
				onClose,
				releases,
				getReleasesAfterRemove,
			} = props;

			accounts
				.deleteDraft(draft_type, id)
				.then((res) => {
					onClose();
					const releasesAfterRemove = releases.filter((item) => item.id !== id);
					getReleasesAfterRemove(releasesAfterRemove);
					removeOneDraft()
				})
				.catch((err) => {
					if (err.response.status === 404 || err.response.status === 403) {
						setErrors({ delete: [{ rule: 'failed' }] });
					} else {
						console.info('error', err.response);
					}
				});
		}
	};

	return (
		<BaseModal className={styles.Modal} onClose={props.onClose}>
			<PoseGroup>
				{isVisible && [
					<Shade key="shade" className={styles.Overlay} />,

					<Modal
						key="modal"
						style={{
							marginLeft: 'auto',
						}}
					>
						<Container fluid className={styles.containerWhite}>
							<div className={styles.LeftSideWhite}>
								<div className={styles.ContentWhite}>
									<Label
										text={
											<FormattedMessage
												id={
													props.handleDeletePseudonym
														? `rod.pseudonyms.delete-confirm`
														: `rod.drafts.delete-confirm`
												}
											/>
										}
										font="--gilroy-Medium-24"
										className={styles.titleWhite}
									/>
									<Label
										text={
											<FormattedMessage
												id={
													props.handleDeletePseudonym
														? `rod.pseudonyms.delete-help`
														: `rod.drafts.delete-help`
												}
											/>
										}
										font="--gilroy-Medium-14-140p"
									/>
									<div className={styles.deleteWrapper}>
										<Button
											text={<FormattedMessage id={`rod.drafts.delete`} />}
											variant={'primary'}
											type={'submit'}
											className={styles.confirmBtn}
											onClick={handleDelete}
										/>
										{Object.keys(errors).includes('delete') && (
											<span className={styles.Helper}>
												<FormattedMessage
													id={`rod.error.${errors.delete[0].rule}`}
												/>
											</span>
										)}
									</div>
									<Button
										text={<FormattedMessage id={`rod.drafts.delete-cancel`} />}
										link
										onClick={props.onClose}
									/>
								</div>

								<img
									src={close_black}
									alt=""
									className={styles.CloseButton}
									onClick={props.onClose}
								/>
							</div>
						</Container>
					</Modal>,
				]}
			</PoseGroup>
		</BaseModal>
	);
};

export default RemoveModal;
