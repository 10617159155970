export const narrative = [
	{
		id: 1,
		title_ru: 'Присутствует',
		title_en: 'Present',
		code: 'explicit',
	},
	{
		id: 2,
		title_ru: 'Отсутствует',
		title_en: 'None ',
		code: 'not_explicit',
	},
];
