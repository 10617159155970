// Core
import { FormattedHTMLMessage } from 'react-intl';

// Icons
import close from 'images/close_button.svg';

// Styles
import s from './Banner.module.css';

const Banner = ({ setBanner, title, value }) => {
	return (
		<div className={s.banner}>
			<img onClick={() => setBanner(false)} src={close} alt="close" />
			<span>
				{!value ? (
					<FormattedHTMLMessage id={title} />
				) : (
					<FormattedHTMLMessage values={value} id={title} />
				)}
			</span>
		</div>
	);
};

export default Banner;
