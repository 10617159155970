export const prepareOutletsIncludedData = (
	outlets,
	contractConditionDetails
) => {
	let result;

	if (
		contractConditionDetails?.outlets?.including &&
		contractConditionDetails?.outlets?.including.length
	) {
		result = contractConditionDetails?.outlets?.including.map((item) => {
			const matchedOutlet = outlets.find((outlet) => outlet.id === item);
			return matchedOutlet ? matchedOutlet.title : '\u2212';
		});
		return <span>{result.join(', ')}</span>;
	} else return <span>{'\u2212'}</span>;
};

export const prepareOutletsExcludedData = (
	outlets,
	contractConditionDetails
) => {
	let result;

	if (
		contractConditionDetails?.outlets?.excluding &&
		contractConditionDetails?.outlets?.excluding.length
	) {
		result = contractConditionDetails?.outlets?.excluding.map((item) => {
			const matchedOutlet = outlets.find((outlet) => outlet.id === item);
			return matchedOutlet ? matchedOutlet.title : '\u2212';
		});
		return <span>{result.join(', ')}</span>;
	} else return <span>{'\u2212'}</span>;
};
