/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 01/02/2021, 18:49
 */

import classnames from 'classnames';
import { Label } from 'components/Labels/Label';
import { DropZone } from 'components/Upload/DropZone';
import { withRoot } from 'hocs';
import attachmentImg from 'images/attachment.svg';
import closeImg from 'images/close_black.svg';
import downloadImg from 'images/contract_download.svg';
import numeral from 'numeral';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import moment from 'moment';
import styles from './ContractListItem.module.css';

class ContractListItem extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			files: props.signed_contract,
		};
	}

	upload = (files) => {
		const {
			rootContext: { uploadContract },
			contract_type,
		} = this.props;
		const fd = new FormData();
		fd.append(`file`, files[0]);

		return uploadContract(this.props.match.params.id, contract_type, fd)
			.then((res) => {
				res = res.data.data;
				this.setState({ files: res[0] });
				this.props.isFileUploaded(true);
				return Promise.resolve(res);
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	};

	remove = (id) => () => {
		const {
			rootContext: { deleteUploadedContract },
			contract_type,
		} = this.props;
		return deleteUploadedContract(
			this.props.match.params.id,
			contract_type,
			id
		).then((res) => {
			res = res.data.data;
			this.setState({ files: null });
			this.props.isFileUploaded(false);
			return Promise.resolve(res);
		});
	};

	correctDateView = (date) => {
		if (date && typeof date === 'number') {
			const currDate = new Date(date);
			const currMonth = currDate.getMonth() + 1;
			const currYear = currDate.getFullYear();
			let currDay = moment().format('MMM Do YY').split(' ')[1];
			currDay = isNaN(currDay.slice(0, 2))
				? currDay.slice(0, 1)
				: currDay.slice(0, 2);
			let dateFrom =
				currDay.padStart(2, '0') +
				'.' +
				currMonth.toString().padStart(2, '0') +
				'.' +
				currYear;

			return dateFrom;
		} else {
			return date
				? date
						.replace(/(\d{1,4})-(\d{1,2})-(\d{1,2})/g, '$3.$2.$1')
						.slice(0, 10)
				: '';
		}
	};

	localizeTitles = (title) => {
		switch (title) {
			case 'Лицензионный договор':
				return <FormattedMessage id={'rod.account.create.license-agreement'} />;
			case 'Licensing agreement':
				return <FormattedMessage id={'rod.account.create.license-agreement'} />;
			case 'Single-song издательский договор':
				return (
					<FormattedMessage id={'rod.account.create.single-song-agreement'} />
				);
			case 'Субиздательский договор':
				return (
					<FormattedMessage
						id={'rod.account.create.sub-publishing-agreement'}
					/>
				);
			case 'Single song publishing agreement':
				return (
					<FormattedMessage id={'rod.account.create.single-song-agreement'} />
				);
			default:
				return title;
		}
	};

	render() {
		const {
			title,
			status,
			index,
			showFiles,
			showUpload,
			showDownload,
			showDownloadSigned,
			showDate,
			showStatus,
			date,
			hash,
			showHash,
			signed_contract,
			errors,
			withoutIndex = false,
		} = this.props;

		return (
			<div className={styles.Item}>
				<div className={styles.Title}>
					<Label font="--gilroy-Medium-24" black>
						{withoutIndex ? '' : `${index + 1}. `}
						{this.localizeTitles(title)}
					</Label>
					{showDownload && (
						<div className={styles.Download}>
							<a
								href={this.props.files[0].url}
								target="_blank"
								rel="noopener noreferrer"
							>
								<img src={downloadImg} alt="" />
								<Label font="--gilroy-Medium-16" black>
									<FormattedMessage
										id={`rod.field.contracts.contract_download`}
									/>
								</Label>
							</a>
						</div>
					)}
					{showDownloadSigned && signed_contract && (
						<div className={styles.Download}>
							<a
								href={signed_contract.url}
								target="_blank"
								rel="noopener noreferrer"
							>
								<img src={downloadImg} alt="" />
								<Label font="--gilroy-Medium-16" black>
									<FormattedMessage
										id={`rod.field.contracts.contract_download_signed`}
									/>
								</Label>
							</a>
						</div>
					)}
				</div>

				{showStatus && (
					<div className={classnames(styles.Status, status)}>
						<Label font="--gilroy-Medium-16" className={'status-title'}>
							<FormattedMessage
								id={'rod.field.contracts.contract_status.title'}
							/>
							:{' '}
							<FormattedMessage
								id={`rod.field.contracts.contract_status.value.${status}`}
							/>
						</Label>
					</div>
				)}
				{errors && errors.length > 0 && (
					<div className={styles.Error}>
						<Label font="--gilroy-Medium-16" className={'status-error'}>
							<div>
								<FormattedMessage
									id={'rod.field.contracts.contract_status.error'}
								/>
								:
								<ul>
									{errors.map((err) => (
										<li>
											<FormattedMessage
												id={`rod.field.contracts.contract_error.value.${err}`}
											/>
										</li>
									))}
								</ul>
							</div>
						</Label>
					</div>
				)}
				{showDate && date && (
					<div className={styles.Date}>
						<Label font="--gilroy-Medium-16">
							<FormattedMessage
								id={'rod.field.contracts.contract_status.date'}
							/>
							: {this.correctDateView(date)}
						</Label>
					</div>
				)}
				{showHash && hash && (
					<div className={styles.Param}>
						<Label font="--gilroy-Medium-16">document hash: {hash}</Label>
					</div>
				)}
				<div className={styles.uploadBlock}>
					{showUpload && (
						<>
							<DropZone
								onUpload={this.upload}
								error={this.props.errors.errors?.files[0]}
								resetSubmitErrors={this.props.resetSubmitErrors}
								files={this.state.files}
								isSignDocPage={true}
							/>
						</>
					)}
					{showFiles && this.state.files && (
						<ul className={styles.Files}>
							{
								<div className={styles.Uploaded}>
									<div className={styles.Line}>
										<img src={attachmentImg} alt="attachment" />
										<div className="filename">
											{this.state.files.client_name}
										</div>
										<div className="size">
											{numeral(this.state.files.size).format('0b')}
										</div>
										<div
											className={styles.Remove}
											onClick={this.remove(this.state.files.id)}
										>
											<img src={closeImg} alt="" />
										</div>
									</div>
								</div>
							}
						</ul>
					)}
				</div>
			</div>
		);
	}
}

ContractListItem.propTypes = {};

ContractListItem.defaultProps = {
	showStatus: true,
};

export default compose(withRoot, withRouter)(ContractListItem);
