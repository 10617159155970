export const cacheConfig = {
	statisticChartCache: {
		CACHE_ID_NAME: 'STAT_CHART_CACHE_ID',
		CACHE_ID_VALUES: [],
		lifeTime: 30,
	},
	statisticMapCache: {
		CACHE_ID_NAME: 'STAT_MAP_CACHE_ID',
		CACHE_ID_VALUES: [],
		lifeTime: 30,
	},
	statisticTopTracksCache: {
		CACHE_ID_NAME: 'STAT_TOPTRACKS_CACHE_ID',
		CACHE_ID_VALUES: [],
		lifeTime: 30,
	},
	flagsCache: { isFlag: true, lifeTime: 1440 },
	statisticTrackForSelectCache: {
		CACHE_ID_NAME: 'STAT_TRACK_FOR_SELECT_CACHE_ID',
		CACHE_ID_VALUES: [],
		lifeTime: 30,
	},
	countries: { isDictionaries: true, lifeTime: 1440 },
	countriesWithCompound: { isDictionaries: true, lifeTime: 1440 },
	countriesWithCompoundTrue: { isDictionaries: true, lifeTime: 1440 },
	currency: { isDictionaries: true, lifeTime: 1440 },
	languages: { isDictionaries: true, lifeTime: 1440 },
	genres: { isDictionaries: true, lifeTime: 1440 },
	genresWithoutTypes: { isDictionaries: true, lifeTime: 1440 },
	releaseTypes: { lifeTime: 1440 },
	releaseTypesAll: { lifeTime: 1440 },
};
