// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';

// UI
import { FormInput } from 'components/Form/FormInput';

const RadioSexGroup = ({ changeField, errors, personal }) => {
	return (
		<FormInput
			type={'radio'}
			name={'sex'}
			onChange={changeField}
			errors={errors}
			data={personal}
			horizontal
			items={[
				{
					text: <FormattedMessage id={'rod.field.sex.male'} />,
					value: 'male',
				},
				{
					text: <FormattedMessage id={'rod.field.sex.female'} />,
					value: 'female',
				},
			]}
		/>
	);
};

export default RadioSexGroup;
