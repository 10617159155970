import React, { useContext, useState, useEffect } from 'react';
import { LangContext } from 'contexts/LangContext';
import s from './RepertoireRecordingNotices.module.css';
import { RepertoireRecordingNoticesItem } from './RepertoireRecordingNoticesItem';
import { useRouteMatch } from 'react-router-dom';
import { withAuth, withRoot } from 'hocs';
import { Loading, NoData } from 'components';
import { compose } from 'recompose';

function RepertoireReсordingNotices(props) {
	const match = useRouteMatch();
	const recordingId = match.params.id;
	const { lang } = useContext(LangContext);
	const [loading, setLoading] = useState(true);
	const [notices, setNotices] = useState([]);

	const {
		authContext: { accountId },
		rootContext: { getRecordingIssues },
	} = props;

	const scopeList = {
		audio: { en: 'Audio', ru: 'Аудио' },
		code: { en: 'Code', ru: 'Код' },
		date: { en: 'Date', ru: 'Дата' },
		image: { en: 'Cover image', ru: 'Обложка' },
		genre: { en: 'Genre', ru: 'Жанр' },
		contributors: { en: 'Contributors', ru: 'Контрибьюторы' },
		lyrics: { en: 'Lyrics', ru: 'Лирика' },
	};

	useEffect(() => {
		getRecordingIssues(accountId, recordingId, lang)
			.then((res) => {
				setNotices(res);
				setLoading(false);
			})
			.catch((error) => {
				console.error('Error', error);
				setLoading(false);
			});
	}, [lang]);

	const structuredList = () => {
		let result = [];
		let scopeList = [];
		notices.forEach((currentReason) => {
			if (!scopeList.includes(currentReason.scope)) {
				scopeList.push(currentReason.scope);
			}
		});
		result = scopeList.map((currentScope) => {
			let reasonsByScope = notices.filter((currentReason) => {
				return currentReason.scope === currentScope;
			});

			return { scope: currentScope, reasons: reasonsByScope };
		});
		return result;
	};

	return loading ? (
		<Loading />
	) : (
		<div>
			{notices.length ? (
				<ul className={s.table__body}>
					{structuredList().map((item) => (
						<li>
							<p className={s.scope_title}>{scopeList[item.scope][lang]}</p>
							<RepertoireRecordingNoticesItem data={item} />
						</li>
					))}
				</ul>
			) : (
				<div>
					<NoData />
				</div>
			)}
		</div>
	);
}
export default compose(withAuth, withRoot)(RepertoireReсordingNotices);
