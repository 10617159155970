// Core
import { isBefore, parseISO, startOfDay } from 'date-fns';

export const isDateUnavailable = (dateString, minDate) => {
	const providedDate = startOfDay(parseISO(dateString)); // Remove time component from provided date
	const minDateObject = minDate ? startOfDay(new Date(minDate)) : null; // Remove time component from minDate

	// Check if providedDate is before minDate
	if (minDateObject && isBefore(providedDate, minDateObject)) {
		return true; // Date is unavailable
	}

	return false; // Date is available (including if it's exactly the same as minDate)
};
