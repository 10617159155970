const changeField = ({ index, field, e, selectPerson, tmpInputValue }) => {
	const currentObj = selectPerson.contributors[index];
	let currentValue = e?.target?.value;

	switch (field) {
		case 'ownership':
			let firstSum = 0;
			let sharesSum = 0;
			let floatSum = 0;
			let lastSum = 0;

			let sharesMaxSum = 100;

			if (currentValue <= 0) {
				if (
					!Object.keys(tmpInputValue).length &&
					(typeof currentValue === 'string' || +currentObj.ownership < 1)
				) {
					currentValue = 0.1;
				} else if (Object.keys(tmpInputValue).length) {
					currentValue = '';
				}
			}

			selectPerson.contributors.forEach((item, i) => {
				if (i !== index) {
					if (item.publisher_show) {
						firstSum += +item.publisher_share;
					}
					firstSum += +item.ownership;
				} else {
					if (currentObj.publisher_show) {
						firstSum += +currentObj.publisher_share;
					}
					firstSum += +currentValue;
				}
			});

			if (currentObj.publisher_show) {
				const newSum = +currentObj.publisher_share + +currentObj.ownership;
				if (currentValue >= 100) {
					if (typeof currentValue === 'string') {
						currentObj.publisher_share = 0.1;
						if (currentValue + currentObj.publisher_share > 100) {
							currentValue = 99.9;
						}
					} else {
						currentObj.publisher_share = 1;
						if (currentValue + currentObj.publisher_share > 100) {
							currentValue = 99;
						}
					}
				} else {
					if (firstSum > 100) {
						currentObj.publisher_share = +(newSum - currentValue).toFixed(2);

						if (currentObj.publisher_share <= 0) {
							currentObj.publisher_share = 1;
						}
					} else {
						currentObj.publisher_share = (
							+currentObj.publisher_share -
							(+currentObj.ownership - currentValue)
						).toFixed(2);

						if (currentObj.publisher_share <= 0) {
							currentObj.publisher_share = 1;
						}
					}
				}
			}

			selectPerson.contributors.forEach((item, i) => {
				if (i !== index) {
					if (item.publisher_show) {
						sharesSum += +item.publisher_share;
					}
					sharesSum += +item.ownership;
				} else {
					if (currentObj.publisher_show) {
						sharesSum += +currentObj.publisher_share;
					}
					sharesSum += +currentValue;
				}
			});

			if (sharesSum > sharesMaxSum) {
				selectPerson.contributors.forEach((item, i) => {
					if (i !== index) {
						if (+item.publisher_share > 0.1) {
							item.publisher_share =
								+item.publisher_share -
								+item.publisher_share / (100 / (sharesSum - 100));

							item.publisher_share =
								item.publisher_share < 0.1
									? 0.1
									: +item.publisher_share.toFixed(2);

							floatSum += item.publisher_share;
						}

						if (+item.ownership > 0.1) {
							item.ownership =
								+item.ownership - +item.ownership / (100 / (sharesSum - 100));

							item.ownership =
								item.ownership < 0.1
									? 0.1
									: item.publisher_share
									? +item.ownership.toFixed(2)
									: +item.ownership.toFixed(1);

							floatSum += item.ownership;
						}
					} else {
						if (+item.publisher_share > 0) {
							floatSum += item.publisher_share;
						}
						floatSum += currentValue;
					}
				});
			}

			if (floatSum > sharesMaxSum) {
				for (var j = 0; j < selectPerson.contributors.length; j++) {
					if (j !== index) {
						if (+selectPerson.contributors[j].ownership > 0.1) {
							const floatDifference = selectPerson.contributors[j]
								.publisher_share
								? +(floatSum - sharesMaxSum).toFixed(2)
								: +(floatSum - sharesMaxSum).toFixed(1);
							if (+selectPerson.contributors[j].publisher_share > 0.1) {
								const lastDigit = floatDifference[floatDifference.length - 1];

								if (+lastDigit % 2 === 0) {
									selectPerson.contributors[j].ownership =
										selectPerson.contributors[j].ownership -
										floatDifference / 2;

									selectPerson.contributors[j].publisher_share =
										selectPerson.contributors[j].publisher_share -
										floatDifference / 2;

									selectPerson.contributors[j].ownership =
										selectPerson.contributors[j].ownership < 0.1
											? 0.1
											: selectPerson.contributors[j].publisher_share
											? +selectPerson.contributors[j].ownership.toFixed(2)
											: +selectPerson.contributors[j].ownership.toFixed(1);

									selectPerson.contributors[j].publisher_share =
										selectPerson.contributors[j].publisher_share < 0.1
											? 0.1
											: +selectPerson.contributors[j].publisher_share.toFixed(
													2
											  );
								} else {
									const smallRound =
										Math.floor(+floatDifference * Math.pow(10, 2)) /
										Math.pow(10, 2);

									const bigRound =
										Math.ceil(+floatDifference * Math.pow(10, 2)) /
										Math.pow(10, 2);

									selectPerson.contributors[j].ownership =
										selectPerson.contributors[j].ownership - bigRound;

									selectPerson.contributors[j].publisher_share =
										selectPerson.contributors[j].publisher_share - smallRound;

									selectPerson.contributors[j].ownership =
										selectPerson.contributors[j].ownership < 0.1
											? 0.1
											: selectPerson.contributors[j].publisher_share
											? +selectPerson.contributors[j].ownership.toFixed(2)
											: +selectPerson.contributors[j].ownership.toFixed(1);

									selectPerson.contributors[j].publisher_share =
										selectPerson.contributors[j].publisher_share < 0.1
											? 0.1
											: +selectPerson.contributors[j].publisher_share.toFixed(
													2
											  );
								}
							} else {
								selectPerson.contributors[j].ownership =
									selectPerson.contributors[j].ownership - +floatDifference;

								selectPerson.contributors[j].ownership =
									selectPerson.contributors[j].ownership < 0.1
										? 0.1
										: selectPerson.contributors[j].publisher_share
										? +selectPerson.contributors[j].ownership.toFixed(2)
										: +selectPerson.contributors[j].ownership.toFixed(1);
							}
							break;
						}
					}
				}
			}

			selectPerson.contributors.forEach((item, i) => {
				if (i !== index) {
					if (item.publisher_show) {
						lastSum += +item.publisher_share;
					}
					lastSum += +item.ownership;
				} else {
					if (currentObj.publisher_show) {
						lastSum += +currentObj.publisher_share;
					}
					lastSum += +currentValue;
				}
			});

			if (lastSum > sharesMaxSum) {
				currentValue -= currentObj.publisher_share
					? +(lastSum - 100).toFixed(2)
					: +(lastSum - 100).toFixed(1);
			}

			currentObj[field] = currentValue;
			break;
		case 'publisher_share':
			if (currentValue <= 0) {
				if (
					typeof currentValue === 'string' ||
					+currentObj.publisher_share < 1
				) {
					currentValue = tmpInputValue.field === 'publisher_share' ? '' : 0.1;
				} else {
					currentValue = 1;
				}
			} else if (currentValue >= 100) {
				if (
					typeof currentValue === 'string' ||
					+currentObj.publisher_share > 99
				) {
					currentValue = 99.9;
				} else {
					currentValue = 99;
				}
			}

			const newSum = +currentObj.publisher_share + +currentObj.ownership;
			const authorValue = newSum - currentValue;
			if (newSum - currentValue <= 0) {
				if (typeof currentValue === 'string') {
					currentObj.ownership = 0.1;
					currentValue = newSum - currentObj.ownership;
				} else {
					currentObj.ownership = 1;
					currentValue = newSum - currentObj.ownership;
				}
			} else {
				currentObj.ownership = currentObj.publisher_share
					? +authorValue.toFixed(2)
					: +authorValue.toFixed(1);
			}
			currentObj[field] = currentValue;

			break;
		case 'roles':
			const newArr = e.map((item) => (item.logo ? item.logo : item));
			currentObj[field] = newArr;
			break;
		case 'controlled_by_submitter':
			currentObj[field] = e?.target?.checked;
			break;
		default:
			currentObj[field] = currentValue;
			break;
	}

	return selectPerson;
};

export default changeField;
