export const getOutletInfo = (outlet) => {
	const lang = localStorage.getItem('lang');
	const { description_en, description_ru } = outlet;

	switch (lang) {
		case 'ru':
			return description_ru;
		case 'en':
			return description_en;
		default:
			return '';
	}
};
