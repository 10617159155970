import { useState, useEffect } from 'react';

export const useContainerWidth = (
	ref,
	cardWidth,
	gapBetweenCards,
	releases
) => {
	const [containerWidth, setContainerWidth] = useState(0);

	useEffect(() => {
		const updateContainerWidth = () => {
			if (ref.current) {
				setContainerWidth(ref.current.offsetWidth);
			}
		};

		if (ref.current) {
			setContainerWidth(ref.current.offsetWidth);
		}

		window.addEventListener('resize', updateContainerWidth);

		return () => {
			window.removeEventListener('resize', updateContainerWidth);
		};
	}, [ref]);

	const cardsFit =
		containerWidth >= (cardWidth + gapBetweenCards) * releases.length;

	return { cardsFit };
};
