// Core
import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import { withAuth, withUI } from 'hocs';

// UI
import { Loading } from 'components';

class Logout extends PureComponent {
	componentDidMount() {
		const {
			authContext: { logout },
		} = this.props;
		const token = localStorage.getItem('token');
		if (token) {
			logout();
		}
	}

	render() {
		return <Loading non_margin={true} />;
	}
}

Logout.propTypes = {};

Logout.defaultProps = {};

export default compose(withAuth, withUI)(Logout);
