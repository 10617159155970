// Core
import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

// Utils
import { withAuth, withLang, withRoot } from 'hocs';
import { compose } from 'recompose';
import api from 'services/interceptor';

// UI
import { Bullet } from 'components/Bullet';
import { Button } from 'components/Buttons';
import { Checkbox } from 'components/Checkboxes';
import { Label } from 'components/Labels';
import Loading from 'components/Loading';
import Table from 'components/Table/Table';

// Constants
import accountTypes from 'constants/accountTypes';
import paymentTypes from 'constants/paymentTypes';

// Styles
import styles from './Confirm.module.css';

class ConfirmAll extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			confirmed: false,
		};
	}

	componentDidMount() {
		api
			.get(`/accounts/${this.props.id}/summary`)
			.then((res) => {
				this.setState({
					loading: false,
					data: res.data.data,
				});
			})
			.catch((error) => {
				console.error('Confirm - api summary error: ', error);
				console.error('Confirm - api summary error.response: ', error.response);
			});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.id !== prevProps.id) {
			api
				.get(`/accounts/${this.props.id}/summary`)
				.then((res) => {
					this.setState({
						loading: false,
						data: res.data.data,
					});
				})
				.catch((error) => {
					console.error('Confirm - api summary error: ', error);
					console.error(
						'Confirm - api summary error.response: ',
						error.response
					);
				});
		}
	}

	handleConfirm = (e) => {
		this.setState({ confirmed: e.currentTarget.checked });
	};

	correctDataView = (data) => {
		return data
			? data.replace(/(\d{1,4})-(\d{1,2})-(\d{1,2})/g, '$3/$2/$1')
			: '';
	};

	render() {
		if (this.state.loading) {
			return <Loading non_margin={true} />;
		}

		const {
			rootContext: { getCountryById, getCountryDialingCode },
		} = this.props;
		const {
			personal_data,
			contact_data,
			files,
			pseudonyms,
			payment_info,
			societies,
		} = this.state.data;

		let personalSectionData = [
			{
				title: (
					<FormattedHTMLMessage
						id={'rod.account.create.confirm.business_type'}
					/>
				),
				value: (
					<FormattedHTMLMessage
						id={`rod.account.create.sign_as.${personal_data.business_type}`}
					/>
				),
			},
			{
				title: (
					<FormattedHTMLMessage id={'rod.account.create.confirm.scope_group'} />
				),
				value: (
					<FormattedHTMLMessage
						id={`rod.account.create.who_are_you.${this.state.data.scope_group}`}
					/>
				),
			},
			{
				title: <FormattedHTMLMessage id={'rod.account.create.confirm.roles'} />,
				value: (
					<ul className={styles.Roles}>
						{this.state.data.roles.map((role, index) => (
							<li key={index}>
								<Bullet className={styles.Bullet}>
									<FormattedHTMLMessage
										id={`rod.ref.roles.${role.keyword}.summary`}
									/>
								</Bullet>
							</li>
						))}
					</ul>
				),
			},
		];

		if (
			personal_data.business_type === accountTypes.PERSONAL ||
			personal_data.business_type === accountTypes.PERSONAL_ENTREPRENEUR
		) {
			personalSectionData.push(
				{
					title: <FormattedHTMLMessage id={'rod.field.firstname'} />,
					value: personal_data.first_name,
				},
				{
					title: <FormattedHTMLMessage id={'rod.field.patronymic'} />,
					value: personal_data.patronymic ? personal_data.patronymic : '-',
				},
				{
					title: <FormattedHTMLMessage id={'rod.field.lastname'} />,
					value: personal_data.last_name,
				},
				{
					title: <FormattedHTMLMessage id={'rod.field.sex'} />,
					value: (
						<FormattedHTMLMessage id={`rod.field.sex.${personal_data.sex}`} />
					),
				},
				{
					title: <FormattedHTMLMessage id={'rod.field.registration_address'} />,
					value: personal_data.birth_place,
				},
				{
					title: <FormattedHTMLMessage id={'rod.field.birthdate'} />,
					value: this.correctDataView(personal_data.birth_date),
				},
				{
					title: <FormattedHTMLMessage id={'rod.field.passport'} />,
					value: this.correctDataView(personal_data.national_id_number),
				}
			);
			if (personal_data.business_type === accountTypes.PERSONAL) {
				personalSectionData.push(
					{
						title: <FormattedHTMLMessage id={'rod.field.passport_issuer'} />,
						value: personal_data.national_id_issued_by,
					},
					{
						title: (
							<FormattedHTMLMessage id={'rod.field.passport_issue_date'} />
						),
						value: personal_data.national_id_issued_date
							? this.correctDataView(personal_data.national_id_issued_date)
							: '-',
					},
					{
						title: (
							<FormattedHTMLMessage id={'rod.field.passport_expire_date'} />
						),
						value: personal_data.national_id_expiration_date
							? this.correctDataView(personal_data.national_id_expiration_date)
							: '-',
					},
					{
						title: <FormattedHTMLMessage id={'rod.field.tax_number'} />,
						value: personal_data.itin,
					},
					{
						title: <FormattedHTMLMessage id={'rod.field.residence_country'} />,
						value: getCountryById(personal_data.country_id),
					}
				);
			} else if (
				personal_data.business_type === accountTypes.PERSONAL_ENTREPRENEUR
			) {
				personalSectionData.push(
					{
						title: (
							<FormattedHTMLMessage
								id={'rod.field.personal_entrepreneur.name'}
							/>
						),
						value: personal_data.title,
					},
					{
						title: (
							<FormattedHTMLMessage
								id={'rod.field.personal_entrepreneur.signer'}
							/>
						),
						value: personal_data.behalf,
					},
					{
						title: (
							<FormattedHTMLMessage
								id={'rod.field.personal_entrepreneur.signer_short_name'}
							/>
						),
						value: personal_data.behalf_short,
					},
					{
						title: (
							<FormattedHTMLMessage
								id={'rod.field.personal_entrepreneur.registration_date'}
							/>
						),
						value: personal_data.company_registration_date
							? this.correctDataView(personal_data.company_registration_date)
							: '-',
					},
					{
						title: (
							<FormattedHTMLMessage
								id={'rod.field.personal_entrepreneur.registration_number'}
							/>
						),
						value: personal_data.company_registration_number,
					},
					{
						title: (
							<FormattedHTMLMessage
								id={'rod.field.personal_entrepreneur.registration_place'}
							/>
						),
						value: getCountryById(personal_data.country_id),
					},
					{
						title: (
							<FormattedHTMLMessage
								id={'rod.field.personal_entrepreneur.tax_number'}
							/>
						),
						value: personal_data.itin,
					}
				);
			}
		} else if (personal_data.business_type === accountTypes.COMPANY) {
			personalSectionData.push(
				{
					title: <FormattedHTMLMessage id={'rod.field.company.name'} />,
					value: personal_data.title,
				},
				{
					title: <FormattedHTMLMessage id={'rod.field.company.signer'} />,
					value: personal_data.behalf,
				},
				{
					title: (
						<FormattedHTMLMessage id={'rod.field.company.signer_short_name'} />
					),
					value: personal_data.behalf_short,
				},
				{
					title: (
						<FormattedHTMLMessage id={'rod.field.company.signer_position'} />
					),
					value: personal_data.signature_position,
				},
				{
					title: (
						<FormattedHTMLMessage id={'rod.field.company.signer_rights'} />
					),
					value: (
						<FormattedHTMLMessage id={`rod.field.${personal_data.acting_on}`} />
					),
				},
				{
					title: <FormattedHTMLMessage id={'rod.field.company.attorney'} />,
					value: personal_data.attorney ? personal_data.attorney : '-',
				},
				{
					title: (
						<FormattedHTMLMessage id={'rod.field.company.registration_date'} />
					),
					value: personal_data.company_registration_date
						? this.correctDataView(personal_data.company_registration_date)
						: '-',
				},
				{
					title: (
						<FormattedHTMLMessage id={'rod.field.company.registration_place'} />
					),
					value: getCountryById(personal_data.country_id),
				},
				{
					title: (
						<FormattedHTMLMessage
							id={'rod.field.company.registration_number'}
						/>
					),
					value: personal_data.company_registration_number,
				},
				{
					title: <FormattedHTMLMessage id={'rod.field.company.tax_number2'} />,
					value: personal_data.company_vat_number,
				},
				{
					title: <FormattedHTMLMessage id={'rod.field.company.tax_number'} />,
					value: personal_data.itin,
				}
			);
		}

		let contactsSectionData = [
			{
				title: <FormattedHTMLMessage id={'rod.field.country'} />,
				value: getCountryById(contact_data.country_id),
			},
			{
				title: <FormattedHTMLMessage id={'rod.admin.account.city'} />,
				value: contact_data.city,
			},
			{
				title: <FormattedHTMLMessage id={'rod.field.address_short'} />,
				value: contact_data.address,
			},
			{
				title: <FormattedHTMLMessage id={'rod.field.email'} />,
				value: contact_data.email,
			},

			{
				title: <FormattedHTMLMessage id={'rod.field.phone'} />,
				value: `+${
					getCountryDialingCode(contact_data.phone_code_country_id) +
					contact_data.phone_number
				}`,
			},
			{
				title: <FormattedHTMLMessage id={'rod.field.zip'} />,
				value: contact_data.zip_code,
			},
			{
				title: <FormattedHTMLMessage id={'rod.field.website'} />,
				value: contact_data.site_url,
			},
		];

		let documentsSectionData;
		if (personal_data.business_type === accountTypes.PERSONAL) {
			documentsSectionData = [
				{
					title: (
						<FormattedHTMLMessage id={`rod.document.passport_photo_page`} />
					),
					value: (
						<a
							href={files['passport_photo_page'].url}
							target={'_blank'}
							rel="noreferrer"
						>
							{files['passport_photo_page'].client_name}
						</a>
					),
				},
				{
					title: (
						<FormattedHTMLMessage
							id={`rod.document.passport_registration_page`}
						/>
					),
					value: (
						<a
							href={files['passport_registration_page'].url}
							target={'_blank'}
							rel="noreferrer"
						>
							{files['passport_registration_page'].client_name}
						</a>
					),
				},
			];
		} else if (personal_data.business_type === accountTypes.COMPANY) {
			documentsSectionData = [
				{
					title: (
						<FormattedHTMLMessage id="rod.document.company.registration_certificate" />
					),
					value: (
						<a
							href={files['company_registration_certificate'].url}
							target={'_blank'}
							rel="noreferrer"
						>
							{files['company_registration_certificate'].client_name}
						</a>
					),
				},
				{
					title: (
						<FormattedHTMLMessage
							id={`rod.document.company_tax_residency_certificate`}
						/>
					),
					value: (
						<a
							href={files['company_tax_residency_certificate'].url}
							target={'_blank'}
							rel="noreferrer"
						>
							{files['company_tax_residency_certificate'].client_name}
						</a>
					),
				},
			];
		} else if (
			personal_data.business_type === accountTypes.PERSONAL_ENTREPRENEUR
		) {
			documentsSectionData = [
				{
					title: (
						<FormattedHTMLMessage id={`rod.document.passport_photo_page`} />
					),
					value: (
						<a
							href={files['passport_photo_page'].url}
							target={'_blank'}
							rel="noreferrer"
						>
							{files['passport_photo_page'].client_name}
						</a>
					),
				},
				{
					title: (
						<FormattedHTMLMessage
							id={`rod.document.passport_registration_page`}
						/>
					),
					value: (
						<a
							href={files['passport_registration_page'].url}
							target={'_blank'}
							rel="noreferrer"
						>
							{files['passport_registration_page'].client_name}
						</a>
					),
				},
				{
					title: (
						<FormattedHTMLMessage
							id={`rod.document.sole_entrepreneur_registration_certificate`}
						/>
					),
					value: (
						<a
							href={files['sole_entrepreneur_registration_certificate'].url}
							target={'_blank'}
							rel="noreferrer"
						>
							{files['sole_entrepreneur_registration_certificate'].client_name}
						</a>
					),
				},
			];
		}

		let societiesSectionData = societies.map((society) => ({
			title: society.title_en,
			value: this.correctDataView(society.mebership_since),
		}));

		let identifiersSectionData = pseudonyms.map((pseudonym) => ({
			title: pseudonym.title,
			value: [
				pseudonym.ipi && <span>IPI: {pseudonym.ipi}</span>,
				pseudonym.isni && <span> ISNI: {pseudonym.isni}</span>,
			].reduce((previousValue, cur) =>
				previousValue && cur
					? [previousValue, ',', cur]
					: previousValue && !cur
					? [previousValue]
					: !previousValue && cur
					? [cur]
					: '-'
			),
		}));

		let paymentsSectionData = [];
		if (payment_info.type === paymentTypes.PAYPAL) {
			paymentsSectionData = [
				{
					title: <FormattedHTMLMessage id={'rod.field.paypal_account'} />,
					value: payment_info.paypal_account,
				},
			];
		} else {
			paymentsSectionData = [
				{
					title: <FormattedHTMLMessage id={'rod.field.beneficiary_name'} />,
					value: payment_info.beneficiary_name,
				},
				{
					title: <FormattedHTMLMessage id={'rod.field.bank_account_name'} />,
					value: payment_info.bank_account_name,
				},
				{
					title: <FormattedHTMLMessage id={'rod.field.bank_name'} />,
					value: payment_info.bank_name,
				},
				{
					title: <FormattedHTMLMessage id={'rod.field.swift_code'} />,
					value: payment_info.swift_code,
				},
				{
					title: <FormattedHTMLMessage id={'rod.field.bank_country'} />,
					value: getCountryById(payment_info.bank_country),
				},
				{
					title: <FormattedHTMLMessage id={'rod.field.bank_address'} />,
					value: payment_info.bank_address,
				},
				{
					title: <FormattedHTMLMessage id={'rod.field.cor_account'} />,
					value: payment_info.bank_correspondent_account,
				},
				{
					title: <FormattedHTMLMessage id={'rod.field.cor_swift'} />,
					value: payment_info.bank_correspondent_account_swift_code,
				},
			];
		}

		return (
			<div className={styles.Form}>
				<Container fluid>
					<Row>
						<Col sm={10} offset={{ sm: 1 }}>
							<Label
								text={
									<FormattedHTMLMessage
										id={'rod.account.create.confirm.header'}
									/>
								}
								black
								font="--gilroy-Medium-40"
							/>

							<Label
								text={
									<FormattedHTMLMessage
										id={'rod.account.create.confirm.subheader'}
									/>
								}
								black
								font="--gilroy-Medium-24"
							/>
							{personal_data.business_type === accountTypes.PERSONAL &&
								this.renderSection(
									<FormattedHTMLMessage
										id={'rod.account.create.confirm.account'}
									/>,
									personalSectionData
								)}

							{personal_data.business_type ===
								accountTypes.PERSONAL_ENTREPRENEUR &&
								this.renderSection(
									<FormattedHTMLMessage
										id={'rod.account.create.confirm.personal_entrepreneur'}
									/>,
									personalSectionData
								)}

							{personal_data.business_type === accountTypes.COMPANY &&
								this.renderSection(
									<FormattedHTMLMessage
										id={'rod.account.create.confirm.company'}
									/>,
									personalSectionData
								)}

							{this.renderSection(
								<FormattedHTMLMessage
									id={'rod.account.create.confirm.contacts'}
								/>,
								contactsSectionData
							)}
							{this.renderSection(
								<FormattedHTMLMessage
									id={'rod.account.create.confirm.documents'}
								/>,
								documentsSectionData
							)}
							{this.renderSection(
								<FormattedHTMLMessage
									id={'rod.account.create.confirm.societies'}
								/>,
								societiesSectionData
							)}
							{this.renderSection(
								<FormattedHTMLMessage
									id={'rod.account.create.confirm.identifiers'}
								/>,
								identifiersSectionData
							)}
							{this.renderSection(
								<FormattedHTMLMessage
									id={'rod.account.create.confirm.payments'}
								/>,
								paymentsSectionData
							)}

							<div className={styles.Controls}>
								<Checkbox
									className={styles.Checkbox}
									required
									onChange={this.handleConfirm}
								>
									<FormattedMessage id={'rod.field.confirm.checkbox'} />
								</Checkbox>
								<Button
									text={<FormattedHTMLMessage id={'rod.action.confirm'} />}
									variant={'primary'}
									disabled={!this.state.confirmed}
									onClick={this.props.onChange}
								/>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}

	renderSection(title, data) {
		return (
			<Table
				className={styles.Table}
				title={title}
				body={
					data && data.length > 0 ? (
						data.map((item) => (
							<tr>
								<td
									className="title"
									title={
										item.title && typeof item.title === 'string'
											? item.title
											: ''
									}
									style={{ wordBreak: 'break-word' }}
								>
									{item.title}
								</td>
								<td className="value">{item.value ? item.value : '-'}</td>
							</tr>
						))
					) : (
						<tr>
							<td className="title">-</td>
							<td className="value">-</td>
						</tr>
					)
				}
			/>
		);
	}
}

export default compose(withRoot, withAuth, withLang)(ConfirmAll);
