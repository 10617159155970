/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 02/03/2021, 20:27
 */

import cn from 'classnames';
import attachmentImg from 'images/attachment.svg';
import closeImg from 'images/close_black.svg';
import errorImg from 'images/error.svg';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Dropzone from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import styles from './DropZone.module.css';

const ERROR_TYPES = {
	FILE: 'file',
	FILE_TYPE: 'file_type',
	SIZE: 'size',
};

class DropZone extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			error: props.error,
			errorType: props.errorType,
			moderation: props.moderation,
		};
	}

	reset = () => {
		this.setState({
			loading: false,
			error: false,
			moderation: null,
		});
	};

	upload = (files) => {
		for (let i = 0; i < files.length; i++) {
			if (files[i].size > this.props.maxSize) {
				this.setState({
					error: true,
					errorType: ERROR_TYPES.SIZE,
				});
				return;
			}
		}
		this.setState(
			{
				loading: true,
			},
			() => {
				const upload = this.props.onUpload(files);
				if (upload && upload instanceof Promise) {
					upload
						.then((res) => {
							this.setState({
								loading: false,
								error: false,
							});
						})
						.catch((err) => {
							this.setState({
								loading: false,
								error: true,
								errorType:
									err.errors.file[0].rule === 'max_size'
										? ERROR_TYPES.SIZE
										: ERROR_TYPES.FILE,
								errorSize: err.errors.file[0].value,
							});
						});
				} else {
					this.setState({
						loading: false,
						error: false,
					});
				}
			}
		);
	};

	remove = () => {
		const res = this.props.onRemove();
		if (res && res.hasOwnProperty('then')) {
			this.setState(
				{
					loading: true,
				},
				() => {
					res
						.then((res) => {
							this.setState({
								loading: false,
								error: false,
							});
						})
						.catch((res) => {
							this.setState({
								loading: false,
								error: true,
								errorType: ERROR_TYPES.FILE,
							});
						});
				}
			);
		}
	};

	renderDropzone() {
		return (
			<Dropzone onDrop={this.upload}>
				{({ getRootProps, getInputProps, isDragActive }) => (
					<section
						className={cn(styles.Dropzone, { [styles.Active]: isDragActive })}
					>
						<div {...getRootProps()}>
							<input {...getInputProps()} accept={this.props.accept} />
							<div className={styles.Area}>
								<img src={attachmentImg} alt="attachment" />
								<div className={styles.Text}>
									<FormattedMessage
										// id={'rod.field.upload_v2'}
										values={{
											a: (msg) => <span>{msg}</span>,
										}}
									/>
								</div>
							</div>
						</div>
					</section>
				)}
			</Dropzone>
		);
	}

	renderUploaded() {
		const { client_name, size, url } = this.props.value;
		return (
			<div className={styles.Uploaded}>
				<div className={styles.Line}>
					<img src={attachmentImg} alt="attachment" />
					<div className="filename">{client_name}</div>
					<div className={styles.Remove} onClick={this.remove}>
						<img src={closeImg} alt="" />
					</div>
				</div>
				<div className="size">
					{numeral(this.props.kb ? size : size * 1000).format('0b')}
				</div>
				{url && this.props.showUrl && (
					<a href={url} target="_blank" rel="noreferrer">
						{url}
					</a>
				)}
			</div>
		);
	}

	renderLoader() {
		return (
			<div className={styles.Loading}>
				<i className="fa fa-spinner fa-pulse fa-2x fa-fw" />
			</div>
		);
	}

	renderError() {
		const { errorType, errorSize } = this.state;
		const { maxSize } = this.props;
		return (
			<div className={styles.Error}>
				<div className="icon">
					<img src={errorImg} alt="err" />
				</div>
				<div className="content">
					<div className="header">
						<b>
							<FormattedMessage id={'rod.field.upload.error.title'} />{' '}
						</b>
					</div>
					<FormattedMessage
						id={`rod.field.upload.error.${errorType}`}
						values={{
							size: numeral(errorSize || maxSize).format('0b'),
							a: (msg) => (
								<span onClick={this.reset} className="link">
									{msg}
								</span>
							),
						}}
					/>
				</div>
			</div>
		);
	}

	renderModeration() {
		// const { errorType, errorSize } = this.state;
		const { moderation } = this.props;
		return (
			<div className={styles.Error}>
				<div className="icon">
					<img src={errorImg} alt="err" />
				</div>
				<div className="content">
					{!moderation.valid && !moderation.quality ? (
						<div className="moderation-error-header">
							<b>
								<FormattedMessage
									id={'rod.field.upload.moderation.title.invalid_quality'}
								/>{' '}
							</b>
						</div>
					) : (
						<React.Fragment>
							{!moderation.valid && (
								<div className="moderation-error-header">
									<b>
										<FormattedMessage
											id={'rod.field.upload.moderation.title.invalid'}
										/>{' '}
									</b>
								</div>
							)}
							{!moderation.quality && (
								<div className="moderation-error-header">
									<b>
										<FormattedMessage
											id={'rod.field.upload.moderation.title.quality'}
										/>{' '}
									</b>
								</div>
							)}
						</React.Fragment>
					)}
					<FormattedMessage
						id={`rod.field.upload.moderation.text`}
						values={{
							a: (msg) => (
								<span onClick={this.reset} className="link">
									{msg}
								</span>
							),
						}}
					/>
				</div>
			</div>
		);
	}

	render() {
		if (
			this.state.moderation &&
			!Object.keys(this.state.moderation)
				.map((key) => this.state.moderation[key])
				.reduce((acc, state) => acc && state)
		) {
			return this.renderModeration();
		} else if (this.props.value) {
			return this.renderUploaded();
		} else if (this.state.loading) {
			return this.renderLoader();
		} else if (this.state.error) {
			return this.renderError();
		} else {
			return this.renderDropzone();
		}
	}
}

DropZone.propTypes = {
	onUpload: PropTypes.func,
	onRemove: PropTypes.func,
	value: PropTypes.object,
	accept: PropTypes.string,
	showUrl: PropTypes.bool,
	maxSize: PropTypes.number,
	moderation: PropTypes.shape({
		valid: PropTypes.bool,
		quality: PropTypes.bool,
	}),
};

DropZone.defaultProps = {
	maxSize: 1024 * 1024 * 20,
	onUpload: () => {},
	onRemove: () => {},
	accept: 'image/png, image/jpeg, application/pdf',
	error: false,
	errorType: ERROR_TYPES.FILE,
};

export default DropZone;
