/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 09/03/2021, 20:42
 */

import { Checkbox } from 'components/Checkboxes';
import accountCompletionSteps from 'constants/accountCompletionSteps';
import { withAuth, withRoot, withUI } from 'hocs';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { admin } from 'services';
import styles from './List.module.css';
import { Button } from '../../components/Buttons/Button';

const STATUSES = {
	ERROR: 'error',
	CHECKED: 'checked',
	PENDING: 'pending',
	COMPLETE: 'complete',
};
// CONTACT_DATA: 'contact_data',
// 	DOCUMENTS: 'documents',
// 	IDENTITY: 'identity',
// 	MONEY: 'money',
// 	PENDING: 'pending',
// 	APPROVED: 'approved',
// 	DECLINED: 'declined',
// 	SIGN_PHYSICAL: 'sign_physical',
// 	SIGN_SMS: 'sign_sms',
// 	COMPLETE: 'complete',
// 	PERSONAL_AREA: 'personal_area',
// 	LIST: 'list',
function getIcon(status = STATUSES.CHECKED) {
	let content;
	switch (status) {
		case accountCompletionSteps.EMAIL_CONFIRM:
		case accountCompletionSteps.PHONE_CONFIRM:
			content = (
				<path
					d="M9 5C9 7.20914 7.20914 9 5 9C2.79086 9 1 7.20914 1 5C1 2.79086 2.79086 1 5 1C7.20914 1 9 2.79086 9 5Z"
					stroke="#F8C510"
					strokeWidth="2"
				/>
			);
			break;

		case STATUSES.COMPLETE:
		case STATUSES.CHECKED:
			content = (
				<path
					d="M0 4.31906L3.42444 7.75004L10 1.18098L9.05597 0.25L3.42444 5.875L0.930956 3.38153L0 4.31906Z"
					fill="#BFBFC1"
				/>
			);
			break;

		case STATUSES.ERROR:
			content = (
				<path
					d="M9 5C9 7.20914 7.20914 9 5 9C2.79086 9 1 7.20914 1 5C1 2.79086 2.79086 1 5 1C7.20914 1 9 2.79086 9 5Z"
					stroke="#DE4535"
					strokeWidth="2"
				/>
			);
			break;

		default:
			content = (
				<path
					d="M9 5C9 7.20914 7.20914 9 5 9C2.79086 9 1 7.20914 1 5C1 2.79086 2.79086 1 5 1C7.20914 1 9 2.79086 9 5Z"
					stroke="#50813D"
					strokeWidth="2"
				/>
			);
	}
	return (
		<svg
			width="10"
			height="10"
			viewBox="0 0 10 10"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			{content}
		</svg>
	);
}

const STEPS = {
	[accountCompletionSteps.EMAIL_CONFIRM]: 1,
	[accountCompletionSteps.PHONE_CONFIRM]: 2,
	[accountCompletionSteps.BEGIN]: 3,
	[accountCompletionSteps.ROLE]: 3,
};

export function SignupList(props) {
	let [filter, setFilter] = useState(false);
	let [list, setList] = useState([]);

	useEffect(() => {
		if (!list || list.length === 0) {
			admin
				.listSignups()
				.then((res) => {
					res = res.data.data;
					setList(res);
				})
				.catch((error) => {
					console.error('signupList - api -  error.response: ', error.response);
				});
		}
	});

	const filtred = list?.filter(
		(item) => item.completion_step === accountCompletionSteps.PHONE_CONFIRM
	);

	function getMark(step) {
		let marks = [];
		let index = STEPS[step];
		for (let i = 0; i < 3; i++) {
			marks.push(
				<td className="mark checked">
					{index > i
						? getIcon(STATUSES.CHECKED)
						: index === i
						? getIcon(step)
						: ''}
				</td>
			);
		}
		return marks;
	}

	function extendCode(signupId) {
		admin
			.extendSignupCode(signupId)
			.then((response) => {
				response = response.data.data;
				return alert(
					`SMS код: ${response.code}. Действует до: ${response.expires}`
				);
			})
			.catch((error) => {
				console.error('signupList - api - error.response: ', error.response);
				alert(
					`Не удалось продлить код регистрации. ${error?.response?.toString()}`
				);
			});
	}

	const handleFilterToggle = (e) => {
		e.currentTarget.checked ? setFilter(true) : setFilter(false);
	};

	let data = filter ? filtred : list;
	return (
		<div className={styles.Page}>
			<Checkbox
				className={styles.Checkbox}
				text={'Заявки на шаге проверки номера'}
				onClick={handleFilterToggle}
			/>
			<table className={styles.Table}>
				<tr>
					<th rowSpan={2}>НАИМЕНОВАНИЕ</th>
					<th rowSpan={2}>Email заявки</th>
					<th rowSpan={2}>Телефон заявки</th>
					<th colSpan={3}>Пользователь</th>
					<th rowSpan={2}>Действия</th>
				</tr>
				<tr className="second">
					<th>
						<div className="rotate-270">форма регистрации</div>
					</th>
					<th>
						<div className="rotate-270">email</div>
					</th>
					<th>
						<div className="rotate-270">телефон</div>
					</th>
				</tr>
				{data?.length === 0 && <div>Ничего нет</div>}
				{data?.map((item, index) => (
					<tr>
						<td className="title">{item.name || item.id}</td>
						<td className="title">{item.email}</td>
						<td className="title">{item.phone}</td>
						{getMark(item.completion_step)}
						<td>
							{item.completion_step ===
								accountCompletionSteps.PHONE_CONFIRM && (
								<Button
									primary
									text={'Продлить код'}
									className={styles.Button}
									onClick={() => extendCode(item.id)}
								/>
							)}
						</td>
						{/*<td className="mark checked">{getIcon(STATUSES.CHECKED)}</td>*/}
						{/*<td className="mark checked">{getIcon(STATUSES.CHECKED)}</td>*/}
						{/*<td className="mark checked">{getIcon(STATUSES.CHECKED)}</td>*/}
						{/*<td className="mark checked">{getIcon(STATUSES.CHECKED)}</td>*/}
						{/*<td className="mark checked">{getIcon(STATUSES.CHECKED)}</td>*/}
						{/*<td className="mark checked">{getIcon(STATUSES.CHECKED)}</td>*/}
						{/*<td className="mark checked">{getIcon(STATUSES.CHECKED)}</td>*/}
						{/*<td className="mark checked">{getIcon(STATUSES.CHECKED)}</td>*/}
						{/*<td className="mark checked">{getIcon(STATUSES.CHECKED)}</td>*/}
						{/*<td className="mark checked">{getIcon(STATUSES.CHECKED)}</td>*/}
						{/*<td className="mark checked">{getIcon(STATUSES.CHECKED)}</td>*/}
						{/*<td className="mark checked">{getIcon(STATUSES.CHECKED)}</td>*/}
						{/*<td className="mark error">{getIcon(STATUSES.ERROR)}</td>*/}
						{/*<td className="mark pending">{getIcon(STATUSES.PENDING)}</td>*/}
						{/*<td className="mark pending">{getIcon(STATUSES.COMPLETE)}</td>*/}
					</tr>
				))}
			</table>
		</div>
	);
}

export default compose(withRoot, withAuth, withUI, withRouter)(SignupList);
