// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Utils
import { getGenres } from 'utils';

// Icons
import nota_in_square from 'images/nota_in_square.svg';

// Styles
import styles from './Recordings.module.css';

const Recordings = ({ data, lang, genresArr }) => {
	return (
		<div className={styles.recordings_wrapper}>
			<span className={styles.recordings_title}>
				<FormattedMessage id={'rod.main_page.phonograms'} />
			</span>
			<div className={styles.recordings_table_row}>
				<div className={styles.recordings_table_title_cell}>
					<span>
						<FormattedMessage id={'rod.for_all.title'} />
					</span>
				</div>
				<div className={styles.recordings_table_title_cell}>
					<span>
						<FormattedMessage id={'rod.for_all.performer'} />
					</span>
				</div>
				<div className={styles.recordings_table_title_cell}>
					<span>
						<FormattedMessage id={'rod.composition.recordery.Genre'} />
					</span>
				</div>
				<div
					className={`${styles.recordings_table_title_cell} ${styles.text_uppercase}`}
				>
					<span>isrc</span>
				</div>
			</div>
			{data &&
				data.recordings &&
				data.recordings.map((recording) => {
					return (
						<div
							key={recording.id}
							className={`${styles.recordings_table_row} ${styles.background_white}`}
						>
							<div className={styles.recordings_table_data_cell}>
								<img src={nota_in_square} width="24px" height="24px" alt="" />
								<span>{recording.title ? recording.title : '-'}</span>
							</div>
							<div className={styles.recordings_table_data_cell}>
								<span>
									{recording.main_performer ? recording.main_performer : '-'}
								</span>
							</div>
							<div className={styles.recordings_table_data_cell}>
								<span>
									{Array.isArray(recording.genres) &&
									recording.genres?.length > 0
										? getGenres(lang, genresArr, recording.genres)
										: '-'}
								</span>
							</div>
							<div className={styles.recordings_table_data_cell}>
								<span>{recording.isrc ? recording.isrc : '-'}</span>
							</div>
						</div>
					);
				})}
		</div>
	);
};

export default Recordings;
