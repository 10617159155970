// Core
import { FormattedHTMLMessage } from 'react-intl';

// Utils
import { getDaysDifference } from './getDaysDifference';
import { getOneYearPeriod } from './getOneYearPeriod';

export const checkRangeInOneYear = (
	statisticData,
	setShowRangeModal,
	showModal,
	startDate = statisticData.start_date,
	endDate = statisticData.end_date
) => {
	const isRangeMoreOneYear =
		getDaysDifference(startDate, endDate) > getOneYearPeriod();
	setShowRangeModal(isRangeMoreOneYear);

	if (isRangeMoreOneYear) {
		showModal({
			text: (
				<>
					<FormattedHTMLMessage id={'rod.repertoire.help.own_col_rev'} />
				</>
			),
		});
	}
};
