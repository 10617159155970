// Core
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

// UI
import { FormInput } from 'components/Form/FormInput';
import { Label } from 'components/Labels/Label';
import { InfoButton } from 'components/Buttons/InfoButton';

// Styles
import styles from '../../ContractTerms.module.css';

const ContractsDuration = ({
	state,
	changeField,
	contractPeriods,
	showModal,
}) => {
	return (
		<div className={styles.Field}>
			<Label className={'header'} black font="--gilroy-Bold-24">
				<FormattedMessage id={'rod.field.contracts.duration.header'} />
			</Label>
			<div className={styles.periodWrapper}>
				<FormattedMessage id={'rod.field.contracts.duration.placeholder'}>
					{(placeholder) => (
						<FormInput
							type={'dropdown'}
							placeholder={placeholder}
							name={'period'}
							className="input"
							defaultEmpty
							onChange={changeField}
							errors={{}}
							data={state.data}
							required
							items={contractPeriods}
							getItem={(item) => {
								return (
									<FormattedHTMLMessage
										id={'rod.field.contracts.duration'}
										values={{ count: +item.title }}
									/>
								);
							}}
							getListItem={(item) => (
								<FormattedHTMLMessage
									id={'rod.field.contracts.duration'}
									values={{ count: +item.title }}
								/>
							)}
							getItemValue={(item) => item.id}
						/>
					)}
				</FormattedMessage>
				<InfoButton
					className={styles.infoButtonPeriod}
					onClick={showModal({
						text: (
							<FormattedHTMLMessage
								id={'rod.admin.account.contracts.period-info'}
							/>
						),
					})}
				></InfoButton>
			</div>
		</div>
	);
};

export default ContractsDuration;
