import { getReleaseTypeById } from 'utils';

export const getReleaseTitle = (data, releases, lang, releaseInfo) => {
	const release = releases.find((release) => release.id === +data[0]);
	if (release) {
		return `${release.title} (${
			lang === 'ru' ? release.release_type_ru : release.release_type_en
		})`;
	} else {
		return releaseInfo?.title
			? `${releaseInfo.title} (${getReleaseTypeById(
					releaseInfo?.release_type_id
			  )})`
			: '';
	}
};
