/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 25/05/2020, 20:02
 */

import { CloseButton } from 'components/Buttons';
// import { Button } from 'components/Buttons';
// import { Checkbox } from 'components/Checkboxes';
// import { FormInput } from 'components/Form/FormInput';
// import { Label } from 'components/Labels/Label';
// import Admin from 'services/api/admin';
import { Lang } from 'components';
import { Col, Container, Row } from 'components/Grid';
import { compose } from 'recompose';
import { withLang, withRoot, withUI } from 'hocs';

import React, { Component } from 'react';
import posed, { PoseGroup } from 'react-pose';
import { FormattedHTMLMessage } from 'react-intl';
import BaseModal from './BaseModal';
import styles from './Modal.module.css';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

const Modal = posed.div({
	enter: {
		x: 0,
		opacity: 1,
		// delay: 200,
		transition: {
			x: {
				ease: 'easeIn',
			},
			default: { duration: 300 },
		},
	},
	exit: {
		x: '100%',
		opacity: 0,
		transition: { duration: 250 },
	},
});

const Shade = posed.div({
	enter: { opacity: 0.5 },
	exit: { opacity: 0 },
});

class NotifyAdminReadModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isVisible: false,
		};
	}

	componentDidMount() {
		this.setState({
			isVisible: true,
			onCloseFn: this.props.onClose,
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {}

	willUnmount(e) {
		this.setState({
			isVisible: false,
		});
		setTimeout(() => {
			this.state.onCloseFn();
		}, 300);
	}

	htmlParsing = (text) => {
		return parse(
			DOMPurify.sanitize(text, {
				ADD_ATTR: ['target'],
			})
		);
	};

	// id_mass_mailing;

	render() {
		const { notify } = this.props;

		return (
			<BaseModal className={styles.Modal}>
				<PoseGroup>
					{this.state.isVisible && [
						<Shade key="shade" className={styles.Overlay} />,

						<Modal
							key="modal"
							style={{
								marginLeft: 'auto',
							}}
						>
							<Container fluid>
								<Row>
									<Col
										md={12 - 4}
										onClick={(e) => {
											this.willUnmount(e);
										}}
									/>
									<Col md={4} className={styles.notify}>
										<div className={styles.notify__wrapper}>
											<div className={styles.notify__top_wrapper}>
												<div className={styles.notify__title}>
													<ul className={styles.Tray}>
														<li>
															{this.props.langContext.isShowLang && (
																<Lang
																	className={styles.Lang}
																	authContext={this.props.authContext}
																/>
															)}
														</li>
													</ul>
												</div>
												<CloseButton
													black
													small
													className={styles.CloseButtonNotify}
													onClick={(e) => {
														this.willUnmount(e);
													}}
												/>
											</div>
											<div>
												<div className={styles.readAdminModalTitle}>
													{notify.heading}
												</div>
												<ul>
													<li className={styles.readAdminModalListItem}>
														<div className={styles.readAdminModalItemTitle}>
															<FormattedHTMLMessage
																id={'rod.modal.notify.admin.main-text'}
															/>
															:
														</div>
														<div className={styles.readAdminModalItemText}>
															{this.htmlParsing(notify.text)}
														</div>
													</li>
													<li className={styles.readAdminModalListItem}>
														<div className={styles.readAdminModalItemTitle}>
															<FormattedHTMLMessage
																id={'rod.modal.notify.admin.send-to-whom'}
															/>
															:
														</div>
														<div>
															{notify.id_mass_mailing ? (
																<FormattedHTMLMessage
																	id={'rod.contracts.all_recipients'}
																/>
															) : (
																notify.recipients.join(', ')
															)}
														</div>
													</li>

													{notify.recipients.length === 1 && (
														<li className={styles.readAdminModalListItem}>
															<div className={styles.readAdminModalItemTitle}>
																<FormattedHTMLMessage
																	id={'rod.modal.notify.admin.status'}
																/>
																:
															</div>
															<div>
																{notify.read_user === 1 ? (
																	<FormattedHTMLMessage
																		id={'rod.modal.notify.already-read'}
																	/>
																) : (
																	<FormattedHTMLMessage
																		id={'rod.modal.notify.not-read'}
																	/>
																)}
															</div>
														</li>
													)}
													<li className={styles.readAdminModalListItem}>
														<div className={styles.readAdminModalItemTitle}>
															<FormattedHTMLMessage
																id={'rod.modal.notify.admin.created-date'}
															/>
															:
														</div>
														<div>{notify.created_at}</div>
													</li>
												</ul>
											</div>
										</div>
									</Col>
								</Row>
							</Container>
						</Modal>,
					]}
				</PoseGroup>
			</BaseModal>
		);
	}
}

export default compose(withLang, withUI, withRoot)(NotifyAdminReadModal);
