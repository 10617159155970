// Core
import { FormattedHTMLMessage } from 'react-intl';

// Icons
import close from 'images/close_button.svg';

// Styles
import styles from './PrimaryBanner.module.css';

const PrimaryBanner = ({ setBanner, title, text, hideClose }) => {
	return (
		<div className={styles.banner}>
			{!hideClose && (
				<img onClick={() => setBanner(false)} src={close} alt="close" />
			)}
			{title && (
				<h3 className={styles.title}>
					<FormattedHTMLMessage id={title} />
				</h3>
			)}
			<span className={styles.text}>
				<FormattedHTMLMessage id={text} />
			</span>
		</div>
	);
};

export default PrimaryBanner;
