// Core
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

export const getBannerData = () => {
	let data = {};

	data = {
		title: (
			<FormattedMessage
				id={
					process.env.REACT_APP_CLIENT === 'hitmaker'
						? 'rod.main_page.hitmaker.off.title'
						: 'rod.repertoire.welcome.title'
				}
			/>
		),
		text: (
			<FormattedHTMLMessage
				id={
					process.env.REACT_APP_CLIENT === 'dgtal'
						? 'rod.main_page.greetings.body.dgtal'
						: process.env.REACT_APP_CLIENT === 'hitmaker'
						? 'rod.main_page.hitmaker.off.body'
						: 'rod.main_page.greetings.body'
				}
			/>
		),
	};

	return data;
};
