/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

import classNames from 'classnames';
import Accordion from 'pages/contracts/Confirm/components/Accordion/Accordion';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styles from './AccordionGroup.module.css';

class AccordionGroup extends Component {
	static propTypes = {
		accordions: PropTypes.array,
	};

	render() {
		const { accordions, className, onClick } = this.props;
		const classes = classNames(
			className,
			styles['AccordionGroup'],
		);
		return (
			<div className={classes}>
				{accordions.map(acc => (
					<Accordion {...acc} onClick={onClick}/>
				))}
			</div>
		);
	}
}

export default AccordionGroup;
