// Core
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose } from 'recompose';
import { withForwardRef, withLang, withRoot } from 'hocs';

// UI
import { DropdownCombo } from 'components/DropdownCombo';
import Flag from 'components/Flag';
import { Input } from 'components/Inputs/Input';

// Icons
import chevronDown from 'images/chevron_down.svg';
import chevronUp from 'images/chevron_up.svg';

// Styles
import styles from './PhoneInput.module.css';

class TelInput extends PureComponent {
	static propTypes = {
		focus: PropTypes.bool,
		/**
		 * Defines HTML input type attribute.
		 *
		 * @default 'text'
		 */
		type: PropTypes.string,
		error: PropTypes.object,
		showRequired: PropTypes.bool,
		showError: PropTypes.func,
	};

	static defaultProps = {
		type: 'text',
	};

	constructor(props) {
		super(props);
		this.ref = React.createRef();
		this.state = {
			code: props.countryId || 18,
			number: props.phone || '',
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (
			this.props.countryId !== prevProps.countryId ||
			this.props.phone !== prevProps.phone
		) {
			this.setState({
				code: this.props.countryId,
				number: this.props.phone,
			});
		}
	}

	componentDidMount() {
		const {
			rootContext: { countries },
		} = this.props;

		const countryCode = countries.find((item) => item.id === this.state.code);
		if (countryCode) {
			localStorage.setItem('code', countryCode.dialing_code);
			localStorage.setItem('countryCode', countryCode.code);
			localStorage.setItem('countryId', countryCode.id);
		}

		if (this.props.focus) {
			this.ref.current.focus();
		}
	}

	validate = (value, isValid) => {
		return value.length < 11 && isValid;
	};

	changeHandler = (value, data, isValid) => {};

	blurHandler = (event, countryData) => {};

	handleChangeNumber = (value) => {
		this.setState({ number: value }, () => {
			this.props.onChange(this.state);
		});
	};

	handleChangeCode = (id) => {
		const {
			rootContext: { countries },
		} = this.props;

		const countryCode = countries.find((item) => item.id === id);
		localStorage.setItem('countryCode', countryCode.code);
		localStorage.setItem('code', countryCode.dialing_code);
		localStorage.setItem('countryId', countryCode.id);

		this.setState({ code: id }, () => {
			this.props.onChange(this.state);
		});
	};

	// Add a method to calculate inputMaxLength
	calculateInputMaxLength = () => {
		const {
			rootContext: { countries },
		} = this.props;

		const selectedCountry = countries.find(
			(item) => item.id === this.state.code
		);

		// Check if a country with the selected code is found
		if (selectedCountry) {
			// Calculate inputMaxLength as 15 - length of dialing code
			return 15 - selectedCountry.dialing_code.toString().length;
		} else {
			// Default to 15 if the country is not found
			return 15;
		}
	};

	render() {
		const {
			rootContext: { countries },
			errors,
			phone,
			countryId,
			placeholder,
			helper,
			className,
			forwardRef,
			showRequired,
			showError,
			onBlur,
			disabled,
			registerPhone,
			forbidEnter,
			...props
		} = this.props;
		const { number } = this.state;
		const classes = classnames(
			className,
			errors && styles.Error,
			styles.PhoneInput
		);

		if (forwardRef) {
			this.ref = forwardRef;
		}

		if (countries.length === 0) {
			return null;
		}

		const country = countries.find((item) => item.id === this.state.code);
		const lang = localStorage.getItem('lang');
		const countriesWithCorrectDialingCode = countries.filter(
			(item) => item.dialing_code !== 0
		);

		const inputMaxLength = this.calculateInputMaxLength();

		return (
			<div
				className={classes}
				style={registerPhone ? { maxWidth: '100%' } : {}}
			>
				<div>
					<DropdownCombo
						showChevron={false}
						items={countriesWithCorrectDialingCode}
						value={country?.id}
						getListItem={(item) => (
							<React.Fragment>
								<Flag code={item.code} dialing_code={item.dialing_code} />{' '}
								<span>+{item.dialing_code}</span>
								<span>{lang === 'ru' ? item.title_ru : item.title_en}</span>
							</React.Fragment>
						)}
						getItem={(item, isOpen) => (
							<React.Fragment>
								<Flag code={item.code} dialing_code={item.dialing_code} />
								{isOpen ? (
									<img src={chevronUp} alt="" />
								) : (
									<img src={chevronDown} alt="" />
								)}
								+{item.dialing_code}
							</React.Fragment>
						)}
						getItemValue={(item) => item.id}
						defaultItemIndex={countries.findIndex(
							(item) => item?.id === country?.id
						)}
						onChange={this.handleChangeCode}
						disabled={disabled}
						registerPhone={registerPhone}
					/>
				</div>
				<FormattedMessage id={'rod.field.phone'}>
					{(placeholder) => (
						<Input
							errors={errors}
							placeholder={`${placeholder}${
								showRequired && props.required ? '*' : ''
							}`}
							className={disabled ? `${styles.disabled}` : ``}
							onChange={this.handleChangeNumber}
							type={'number'}
							onBlur={onBlur}
							value={number}
							showError={showError}
							min={0}
							inputMaxLength={inputMaxLength}
							disabled={disabled}
							helper={helper}
							registerPhone={registerPhone}
							forbidEnter={forbidEnter}
							required
						/>
					)}
				</FormattedMessage>
				<input
					type="hidden"
					ref={this.ref}
					value={
						this.state.number ? country?.dialing_code + this.state.number : ''
					}
				/>
				<input
					type="hidden"
					name="phone_code_country_id"
					value={this.state.code}
				/>
				<input type="hidden" name="phone_number" value={this.state.number} />
			</div>
		);
	}
}

TelInput.defaultProps = {
	onChange: () => {},
};

export default compose(withForwardRef, withLang, withRoot)(TelInput);
