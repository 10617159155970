// Core
import React, { useContext, useEffect, useState } from 'react';
import { FormattedHTMLMessage } from 'react-intl';

// Utils
import { adjustFontSize, checkOffsetWidth } from 'utils';

// Context
import { UIContext } from 'contexts/UIContext';

// UI
import { InfoButton } from 'components/Buttons';

// Styles
import s from './MainStatsReleases.module.css';

const MainStatsReleases = ({ data, changeStatus }) => {
	const { showModal } = useContext(UIContext);

	const [total, setTotal] = useState('');
	const [notReady, setNotReady] = useState('');
	const [rejected, setRejected] = useState('');
	const [delivered, setDelivered] = useState('');
	const [takendown, setTakendown] = useState('');
	const [onVerify, setOnVerify] = useState('');

	const containersIds = [
		'total',
		'notReady',
		'rejected',
		'delivered',
		'takendown',
		'onVerify',
	];

	useEffect(() => {
		if (!isEmpty(data)) {
			setTotal(data.total);
			setOnVerify(data.verify || '');
			setRejected(data.rejected);
			setNotReady(data.not_ready);
			setDelivered(data.delivered);
			setTakendown(data.takendown);
		}
	}, [data]);

	useEffect(() => {
		const isNotFit = containersIds.some((item) =>
			checkOffsetWidth(`${item}_container`, `${item}_content`)
		);

		if (isNotFit) {
			containersIds.forEach((item) =>
				adjustFontSize(`${item}_container`, `${item}_content`, '24px')
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [total, notReady, rejected, delivered, takendown, onVerify]);

	const isEmpty = (obj) => {
		for (var key in obj) {
			return false;
		}
		return true;
	};

	const onStatClickHandler = (status) => {
		changeStatus(status);
	};

	return (
		<div className={s.repertoire__mainStatsItems}>
			<div
				className={s.repertoire__mainStatsItem}
				onClick={() => onStatClickHandler('')}
				id="total_container"
			>
				<FormattedHTMLMessage id={'rod.repertoire.all'} />
				{total.length === 0 ? (
					<span>-</span>
				) : (
					<span id="total_content" className={s.stats}>
						{total.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1 `)}
					</span>
				)}
			</div>
			<div className={s.repertoire__mainStatsItem}>
				<InfoButton
					className={s.form__fragment_help_btn}
					onClick={showModal({
						text: (
							<FormattedHTMLMessage
								id={'rod.release.on_verify.fragment.help'}
							/>
						),
					})}
				/>
				<div
					className={s.repertoire__mainStatsVerifyItem}
					onClick={() => onStatClickHandler('verify')}
					id="onVerify_container"
				>
					<FormattedHTMLMessage id="rod.repertoire.draft_processing_or_verify" />
					{onVerify.length === 0 ? (
						<span>-</span>
					) : (
						<span id="onVerify_content" className={s.stats}>
							{onVerify.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1 `)}
						</span>
					)}
				</div>
			</div>
			<div
				className={s.repertoire__mainStatsItem}
				onClick={() => onStatClickHandler('rejected')}
				id="rejected_container"
			>
				<FormattedHTMLMessage id={'rod.repertoire.rejected.tab'} />
				{rejected.length === 0 ? (
					<span>-</span>
				) : (
					<span id="rejected_content" className={`${s.rejected} ${s.stats}`}>
						{rejected.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1 `)}
					</span>
				)}
			</div>
			<div
				className={s.repertoire__mainStatsItem}
				onClick={() => onStatClickHandler('not_ready')}
				id="notReady_container"
			>
				<FormattedHTMLMessage id={'rod.repertoire.not_ready'} />
				{notReady.length === 0 ? (
					<span>-</span>
				) : (
					<span id="notReady_content" className={`${s.notReady} ${s.stats}`}>
						{notReady.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1 `)}
					</span>
				)}
			</div>
			<div
				className={s.repertoire__mainStatsItem}
				onClick={() => onStatClickHandler('shipped')}
				id="delivered_container"
			>
				<FormattedHTMLMessage id={'rod.repertoire.delivered.tab'} />
				{delivered.length === 0 ? (
					<span>-</span>
				) : (
					<span id="delivered_content" className={s.stats}>
						{delivered.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1 `)}
					</span>
				)}
			</div>
			<div
				className={s.repertoire__mainStatsItem}
				onClick={() => onStatClickHandler('takendown')}
				id="takendown_container"
			>
				<FormattedHTMLMessage id={'rod.repertoire.takendown.tab'} />
				{takendown.length === 0 ? (
					<span>-</span>
				) : (
					<span id="takendown_content" className={s.stats}>
						{takendown.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1 `)}
					</span>
				)}
			</div>
		</div>
	);
};

export default MainStatsReleases;
