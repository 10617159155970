export const cumulativeDataToUnitsThousMill = (
	cumulativeData,
	setAmountYAxisTitle
) => {
	let divIndex;

	const maximalMaxY = Math.max(
		...Object.values(cumulativeData).map(({ maxY }) => maxY)
	);

	if (maximalMaxY <= 10000) {
		divIndex = 1;
		setAmountYAxisTitle('rod.statistic.chart-amount-units');
	} else if (maximalMaxY > 10000 && maximalMaxY <= 10000000) {
		divIndex = 1000;
		setAmountYAxisTitle('rod.statistic.chart-amount-thousands');
	} else {
		divIndex = 1000000;
		setAmountYAxisTitle('rod.statistic.chart-amount-millions');
	}

	if (divIndex !== 1) {
		for (const key in cumulativeData) {
			const element = cumulativeData[key];
			const a = element.data.map((obj) => ({
				x: obj.x,
				y: obj.y > 0 ? (obj.y = obj.y / divIndex) : obj.y,
			}));

			element.data = a;
		}
	}
};
