// Core
import lscache from 'lscache';

export const selectCountryHandler = (
	countryProps,
	setContent,
	data,
	setAuditionsBySelectedCountry,
	sumByCountry
) => {
	const countriesCache = lscache.get('countriesCashe');
	const lang = localStorage.getItem('lang');

	if (countriesCache) {
		const country = countriesCache.filter(
			(item) => item.code === countryProps.code
		);
		if (country && country.length > 0) {
			countryProps.title_ru = country[0].title_ru;
			countryProps.title_en = country[0].title_en;
			const countryName =
				lang === 'ru' ? countryProps.title_ru : countryProps.title_en;
			setContent(countryName);
		}
	} else {
		setContent(`${countryProps.name}`);
	}

	if (data) {
		setAuditionsBySelectedCountry(sumByCountry(countryProps));
	}
};
