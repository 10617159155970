import { Button } from 'components/Buttons/Button';
import { Label } from 'components/Labels';
import { Success as SuccessForm } from 'forms/common';

import React, { Component } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import styles from './ForgotPasswordError.module.css';

class ForgotPasswordError extends Component {
	handleClick = () => {
		this.props.history.push('/');
	};

	render() {
		return (
			<SuccessForm>
				<Label black className={styles.Header} font="--gilroy-Medium-40">
					{/* <FormattedHTMLMessage id={'rod.text.forgot.fail'} /> */}
					<FormattedHTMLMessage id={'rod.error_page.not_found.header'} />
				</Label>
				<div className={styles.errorText}>
					<FormattedHTMLMessage id={'rod.text.forgot.fail'} />
				</div>
				<Button
					className={styles.Button}
					onClick={this.handleClick}
					text={<FormattedMessage id={'rod.action.to_index_page'} />}
					variant={'primary'}
				/>
			</SuccessForm>
		);
	}
}

export default ForgotPasswordError;
