// UI
import { Label } from 'components/Labels';

// Styles
import styles from './Field.module.css';

const Field = (props) => {
	return (
		<div className={styles.field}>
			<Label
				text={props.title}
				font={props.font || '--gilroy-Medium-24'}
				className="title"
			/>
			<span>{props.value || props.children}</span>
		</div>
	);
};

export default Field;
