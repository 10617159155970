// Core
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

// UI
import { Button } from 'components/Buttons/Button';

// Styles
import styles from '../ContractTerms.module.css';

const Controls = ({ state, submit, contracts, disabledSubmitBtn }) => {
	return (
		<div className={styles.Controls}>
			<Button
				text={<FormattedMessage id={'rod.action.next'} />}
				variant={'primary'}
				onClick={submit}
				disabled={disabledSubmitBtn}
			/>

			{!state.isConditionsChecked && (!contracts || !contracts.length) && (
				<div className={styles.errMessage}>
					<FormattedHTMLMessage
						className={styles.err}
						style={{ color: 'red' }}
						id={`rod.error.contracts.add.conditions`}
					/>
				</div>
			)}

			{disabledSubmitBtn && (
				<div className={styles.errMessage}>
					<FormattedHTMLMessage
						className={styles.err}
						style={{ color: 'red' }}
						id={`rod.error.contracts.add.conditions`}
					/>
				</div>
			)}

			{!state.isConditionsCheckedwithLyric && (
				<div className={styles.errMessage}>
					<FormattedHTMLMessage
						style={{ color: 'red' }}
						id={`rod.error.contracts.add.conditions_with_lyric`}
					/>
				</div>
			)}
		</div>
	);
};

export default Controls;
