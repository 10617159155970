// Core
import { FormattedHTMLMessage } from 'react-intl';
import { Link } from 'react-router-dom';

// UI
import plusImg from 'images/plus.svg';

// Styles
import styles from './ActionButtons.module.css';

const ActionButtons = ({ rubrics }) => {
	return (
		<div className={styles.topBtnsWrapper}>
			<Link
				className={styles.rubricSettingsLink}
				to="/admin/v2/articles/rubric-settings"
			>
				<FormattedHTMLMessage id={'rod.admin.articles.rubric_settings'} />
			</Link>
			<Link className={styles.createArticleBtn} to="/admin/v2/articles/create">
				<img src={plusImg} alt="add" className={styles.iconPlus} />
				<FormattedHTMLMessage
					id={
						rubrics && rubrics.length > 0
							? 'rod.admin.articles_add'
							: 'rod.admin.articles.add_new'
					}
				/>
			</Link>
		</div>
	);
};

export default ActionButtons;
