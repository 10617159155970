// Core
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

// Icons
import { Loop } from 'material-design/svg';
import remove_white from 'images/remove_white.svg';

// Styles
import s from './InputMenu.module.css';

const InputMenu = ({
	handleOnChange,
	setIsFocus,
	filter,
	curRef,
	placeholder,
	lowerCasePlaceholder,
	removeSearch,
}) => {
	filter = filter ? filter : '';
	// поменять потом
	const [inputValue, setInputValue] = useState('');
	setIsFocus = setIsFocus ? setIsFocus : () => {};

	const inputChange = handleOnChange ? handleOnChange : () => {};
	const handleInput = (e) => {
		setInputValue(e.target.value);
		inputChange(e.target.value);
	};

	const handleRemove = () => {
		setInputValue('');
		inputChange('');
		removeSearch();
	};

	return (
		<div className={s.mainFilter}>
			<div
				className={`${s.mainFilterInput} ${
					inputValue && s.inputFilled
				}`}
			>
				<FormattedMessage id={placeholder}>
					{(msg) => (
						<input
							className={`${lowerCasePlaceholder} ${
								s.inputLowercasePlaceholder
							} ${inputValue && s.inputFilled}`}
							ref={curRef}
							value={inputValue}
							type="text"
							placeholder={msg}
							onChange={handleInput}
							onClick={() => setIsFocus(true)}
						/>
					)}
				</FormattedMessage>
				<Loop />
				{inputValue.length > 0 && (
					<img
						src={remove_white}
						alt="remove"
						className={s.remove}
						onClick={handleRemove}
						ref={curRef}
					/>
				)}
			</div>
		</div>
	);
};

export default InputMenu;
