// Core
import { parse, isBefore, isEqual, addDays, format } from 'date-fns';

export const isDateInPast = (firstDate, days) => {
	const now = new Date();
	const dateDaysFromNow = addDays(now, days);
	const secondDate = format(dateDaysFromNow, 'yy-MM-dd');

	// Parse the first date from "yyyy-MM-dd HH:mm:ss" format
	const parsedFirstDate = parse(firstDate, 'yyyy-MM-dd HH:mm:ss', new Date());

	// Parse the second date from "yy-MM-dd" format
	const parsedSecondDate = parse(secondDate, 'yy-MM-dd', new Date());

	// Compare the dates
	if (
		isBefore(parsedFirstDate, parsedSecondDate) ||
		isEqual(parsedFirstDate, parsedSecondDate)
	) {
		return true;
	} else {
		return false;
	}
};
