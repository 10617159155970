// Core
import { useContext } from 'react';
import { FormattedHTMLMessage } from 'react-intl';

// Context
import { UIContext } from 'contexts/UIContext';

// UI
import { InfoButton } from '../InfoButton';

// Styles
import styles from './HelpButton.module.css';

const HelpButton = ({ helpTitle }) => {
	const { showModal } = useContext(UIContext);

	return (
		<InfoButton
			className={styles.help_btn}
			onClick={showModal({
				text: <FormattedHTMLMessage id={helpTitle} />,
			})}
		/>
	);
};

export default HelpButton;
