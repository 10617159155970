/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 12/11/2020, 22:18
 */
// import http from '../http';
import api from '../interceptor';

export default class Contracts {
	static getContractData(id) {
		//++
		return api.get(`/accounts/${id}/contracts`);
	}

	static updateContractData(id, data) {
		//++ res is not used in requests
		return api.post(`/accounts/${id}/contracts`, data);
	}

	static submitContractData(id, data) {
		//++ res is not used in requests
		return api.post(`/accounts/${id}/contracts/submit`, data);
	}

	static getContractsTerritories(id, useType, mediaType) {
		//++
		return api.get(
			`/accounts/${id}/contracts/${useType}/resource/${mediaType}/territories/exclude`
		);
	}

	static updateContractsTerritories(id, useType, mediaType, data) {
		return api.post(
			`/accounts/${id}/contracts/${useType}/resource/${mediaType}/territories/exclude`,
			data
		);
	}

	static getContracts(id) {
		//++
		return api.get(`/accounts/${id}/contracts/signs`);
	}

	static getAdditionalContracts(id) {
		return api.get(`/accounts/${id}/contracts/additional/signs`);
	}

	static acceptContract(id, contractType) {
		//++ res is not used in requests
		return api.post(`/accounts/${id}/contracts/${contractType}/accept`, {
			have_permission: true,
			is_agreed: true,
		});
	}

	static uploadContract(id, contractType, fd) {
		//++
		return api.post(`/accounts/${id}/contracts/${contractType}/file`, fd);
	}

	static deleteUploadedContract(id, contractType, fileId) {
		//++
		return api.delete(
			`/accounts/${id}/contracts/${contractType}/file/${fileId}`
		);
	}

	static completeSigning(id) {
		//++ res is not used in requests
		return api.post(`/accounts/${id}/contracts/signs/submit`);
	}

	static resendSignSMS(id, contractType) {
		//  ???? - no direct request - only via props ... contract.js 105
		return api.post(
			`/accounts/${id}/contracts/${contractType}/sign/sms/resend`,
			{}
		);
	}

	static signContract(id, contractType, data) {
		//++
		return api.post(`/accounts/${id}/contracts/${contractType}/sign`, data);
	}

	static noCodeSignSMS(id, contractType) {
		//  ??? contract.js 111
		return api.post(`/support/account/${id}/contract/${contractType}/sign`, {});
	}

	static getAccountContracts(licensor_account_id) {
		// return api.get(`/contracts?licensor_account_id=${licensor_account_id}`);
		return api.get(`/contracts`);
	}

	static getContractCondition(contractId) {
		return api.get(`/contracts/${contractId}/conditions`);
	}

	static getContractConditionDetail(contractId, conditionId) {
		return api.get(
			`/contracts/${contractId}/conditions/${conditionId}/details`
		);
	}

	static getContractAttachments(contractId) {
		return api.get(`/contracts/${contractId}/attachments`);
	}

	static getContractById(contractId) {
		return api.get(`/contracts/${contractId}`);
	}
	static changeContractsSignType(id) {
		return api.post(`/accounts/${id}/contracts/change/sign/`);
	}
}
