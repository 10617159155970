// Core
import { FormattedMessage } from 'react-intl';

// Icons
import { Dynamic } from 'material-design/svg';

// Styles
import styles from '../RepertoireRecordingSection.module.css';

const ContentEmpty = ({ upload }) => {
	return (
		<div className={styles.repertoire__mainContentEmpty}>
			{!upload.recordings && (
				<>
					<Dynamic />
					<span className={styles.noData}>
						<FormattedMessage id={'rod.no_data'} />
					</span>
				</>
			)}

			{upload.recordings && (
				<span>
					<FormattedMessage
						id={'rod.recordery.empty'}
						values={{
							// eslint-disable-next-line jsx-a11y/anchor-is-valid
							a: (msg) => <a onClick={() => {}}>{msg}</a>,
						}}
					/>
				</span>
			)}
		</div>
	);
};

export default ContentEmpty;
