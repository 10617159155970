// UI
import TabUnstyled from '@mui/base/TabUnstyled';

// Styles
import { styled } from '@mui/material/styles';

const HeaderButton = styled(TabUnstyled)(({ theme }) => ({
	backgroundColor: 'var(--color-light-grey)',
	color: 'var(--color-dark-grey)',
	font: 'var(--gilroy-Bold-16-19)',
	flex: '0 0 25%',
	padding: '13px 0',
	border: 'none',
	textAlign: 'center',
	margin: 0,
	'&.Mui-selected': {
		backgroundColor: '#FAFAFA',
		'& > *:first-child': {
			textDecoration: 'line-through',
		},
	},
	'& > *:first-child': {
		marginRight: '8px',
	},
}));

export default HeaderButton;
