import { Button } from 'components/Buttons/Button';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import s from './HandlingRelease.module.css';
import { useContext, useState } from 'react';
import { RootContext } from 'contexts/RootContext';
import { UIContext } from 'contexts/UIContext';
import { compose } from 'recompose';
import { withUI } from 'hocs';
import { InfoButton } from 'components/Buttons';

import { FullScreenModalConfirm } from 'containers/Modal';

const HandlingRelease = () => {
	const { handleExportRelease } = useContext(RootContext);
	const [isShowConfirm, setIsShowConfirm] = useState(false);

	const { showModal } = useContext(UIContext);

	const handleExport = () => {
		handleExportRelease()
			.then()
			.catch((err) => console.info('Error:', err));
	};

	const handleModalShow = () => {
		setIsShowConfirm(true);
	};

	const closeModal = () => {
		setIsShowConfirm(false);
	};
	return (
		<>
			<div className={s.MainWrapper}>
				<div className={s.TextWrapper}>
					<FormattedMessage id={'rod.handling.release_text'} />
				</div>
				<div className={s.HelpWrapper}>
					<InfoButton
						className={s.InfoBtn}
						onClick={showModal({
							text: (
								<FormattedHTMLMessage id={'rod.handling.release_info_text'} />
							),
						})}
					/>
					<span
						onClick={showModal({
							text: (
								<FormattedHTMLMessage id={'rod.handling.release_info_text'} />
							),
						})}
						style={{ cursor: 'pointer' }}
					>
						<FormattedMessage id={'rod.handling.release_help'} />
					</span>
				</div>
				<Button
					className={s.SubmitBtn}
					text={<FormattedMessage id={'rod.action.export_release'} />}
					type={'submit'}
					variant={'primary'}
					onClick={handleModalShow}
				/>
			</div>
			{isShowConfirm && (
				<FullScreenModalConfirm
					title={
						<FormattedMessage id={'rod.modal.notify.admin.confirm-question'} />
					}
					confirmBtnTxt={
						<FormattedMessage id={'rod.modal.notify.admin.confirm-yes'} />
					}
					declineBtnTxt={
						<FormattedMessage id={'rod.modal.notify.admin.confirm-no'} />
					}
					onAction={handleExport}
					onClose={closeModal}
				/>
			)}
		</>
	);
};

export default compose(withUI)(HandlingRelease);
