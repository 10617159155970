const CompositionCompletionsSteps = {
	BASIC_INFO: 'info',
	AUTHORS_SHARES: 'authors_shares',
	LYRICS: 'lyrics',
	PERFORMANCE_WORK: 'composition_recordings',
	REVIEW: 'confirm',
	COMPLETED: 'completed',
};

export default CompositionCompletionsSteps;
