// Core
import { FormattedMessage } from 'react-intl';

export const radioItems = [
	{
		text: (
			<FormattedMessage
				id={'rod.release.release.takendown.releases.radio.first'}
			/>
		),
		value: 'current',
		disabled: false,
	},
	{
		text: (
			<FormattedMessage
				id={'rod.release.release.takendown.releases.radio.second'}
			/>
		),
		value: 'chosen',
		disabled: false,
	},
];

export const radioItemsNoReleases = [
	{
		text: (
			<FormattedMessage
				id={'rod.release.release.takendown.releases.radio.first'}
			/>
		),
		value: 'current',
		disabled: false,
	},
	{
		text: (
			<FormattedMessage
				id={'rod.release.release.takendown.releases.radio.second'}
			/>
		),
		value: 'chosen',
		disabled: true,
	},
];
