// Core
import React, { useState, useEffect } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { Link } from 'react-router-dom';

// Components
import { Loading, NoData } from 'components';

// Icons
import nota from 'images/nota.svg';
import eye from 'images/eye_black.svg';

// Styles
import s from './RepertoireRecordingComposition.module.css';

export default function RepertoireRecordingComposition({
	accountId,
	recordingId,
	getRecordingsCompositions,
}) {
	const [loading, setLoading] = useState(true);
	const [items, setItems] = useState([]);

	useEffect(() => {
		getRecordingsCompositions(accountId, recordingId).then((res) => {
			setItems(res);
			setLoading(false);
		});
	}, []);

	return loading ? (
		<Loading />
	) : (
		<>
			{items && items.length > 0 ? (
				<div className={s.body}>
					<div className={`${s.table__header} ${s.table}`}>
						<FormattedHTMLMessage id={'rod.for_all.title'} />
						<FormattedHTMLMessage
							id={'rod.recording.tab.composition.authors'}
						/>
					</div>
					<ul className={s.main}>
						{items.map((i) => (
							<li key={i.id}>
								<Link key={i.id} to={`/repertoire/compositions/${i.id}`}>
									<div className={`${s.table__item} ${s.table}`}>
										<span className={s.item__name}>{i.title}</span>
										<span
											className={s.item__authors}
											title={i.authors?.join(', ')}
										>
											{i.authors?.join(', ')}
										</span>
										<img
											src={nota}
											width={22}
											height={22}
											className={s.nota}
											alt=""
										/>
										<img src={eye} className={s.eye} alt="" />
									</div>
								</Link>
							</li>
						))}
					</ul>
				</div>
			) : (
				<div>
					<NoData />
				</div>
			)}
		</>
	);
}
