/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 02/10/2020, 16:10
 */

import removeImg from 'images/remove-item.svg';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styles from './Token.module.css';

class Token extends PureComponent {
	render() {
		const { title, removeItem, ...props } = this.props;
		//const {} = this.state;
		return (
			<li {...props} className={styles.Token}>{title}
				{typeof removeItem === 'function' && <button className="remove" onClick={removeItem}>
					<img src={removeImg} alt=""/></button>}
			</li>
		);
	}
}

Token.propTypes = {
	title: PropTypes.string.isRequired,
	removeItem: PropTypes.func,
};

Token.defaultProps = {};

export default Token;