/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 10/16/19 1:29 PM
 */

import React from 'react';
import getDisplayName from 'recompose/getDisplayName';
import { LangConsumer } from '../contexts/LangContext';

export const withLang = WrappedComponent => {

	const LangComponent = ((props) => (
		<LangConsumer>
			{state => (
				<WrappedComponent langContext={state} {...props} />
			)}
		</LangConsumer>
	));

	const ForwardRef = React.forwardRef((props, ref) => (
		<LangComponent {...props} withFocusRef={ref}/>
	));

	ForwardRef.displayName = getDisplayName(WrappedComponent);
	ForwardRef.name = ForwardRef.displayName;
	ForwardRef.defaultProps = WrappedComponent.defaultProps;

	return ForwardRef;
};
