export const getContractTypeNumberForTableMarkup = (
	CONTRACT_TYPE,
	setContractProductsNumber,
	setCotractExploitationTypesNumber,
	setCotractRightTypesNumber
) => {
	let contractProductsGroupNumber;
	contractProductsGroupNumber = ['distribution_neighboring'].includes(
		CONTRACT_TYPE
	)
		? 1
		: ['distribution_authors'].includes(CONTRACT_TYPE)
		? 2
		: [
				'sub_publishing',
				'co_publishing',
				'exclusive_publishing',
				'single_song_publishing',
				'collection_authors',
		  ].includes(CONTRACT_TYPE)
		? 3
		: ['administrative'].includes(CONTRACT_TYPE)
		? 4
		: 0;

	let contractExploitationTypesGroupNumber;
	contractExploitationTypesGroupNumber = [
		'license',
		'collection_neighboring',
		'sub_publishing',
		'collection_authors',
		'production',
	].includes(CONTRACT_TYPE)
		? 1
		: ['co_publishing'].includes(CONTRACT_TYPE)
		? 2
		: ['exclusive_publishing', 'single_song_publishing'].includes(CONTRACT_TYPE)
		? 3
		: ['administrative'].includes(CONTRACT_TYPE)
		? 4
		: 0;

	let contractRightTypesGroupNumber;
	contractRightTypesGroupNumber = [
		'society_authors',
		'society_performers',
	].includes(CONTRACT_TYPE)
		? 1
		: [
				'society_publishers',
				'society_producers',
				'collection_authors',
				'sub_publishing',
		  ].includes(CONTRACT_TYPE)
		? 2
		: ['co_publishing'].includes(CONTRACT_TYPE)
		? 3
		: ['exclusive_publishing', 'single_song_publishing'].includes(CONTRACT_TYPE)
		? 4
		: ['administrative'].includes(CONTRACT_TYPE)
		? 5
		: 0;
	setContractProductsNumber(contractProductsGroupNumber);
	setCotractExploitationTypesNumber(contractExploitationTypesGroupNumber);
	setCotractRightTypesNumber(contractRightTypesGroupNumber);

	if ('isExploitationData') {
		switch (contractExploitationTypesGroupNumber) {
			case 1:
				// *exploitation_type
				// *licensor_share
				// *licensee_share
				break;
			case 2:
				// *exploitation_type
				// *collect_assignor_share
				// *collect_author_share_at_source
				// *licensee_share
				break;
			case 3:
				// *exploitation_type
				// *collect_assignor_share
				// *collect_author_share_at_source
				// *licensor_share
				// *licensee_share

				break;
			case 4:
				// *exploitation_type
				// *collect_licensor_share
				// *licensor_share
				// *licensee_share

				break;
			default:
				console.error('UNKNOWN contract type!');
				break;
		}
	} else {
		switch (contractRightTypesGroupNumber) {
			case 1:
				// *	right
				// *	licensor_share_via…
				// *	licensor_share
				// *	licensee_share

				break;
			case 2:
				// *	right
				// *	licensor_share
				// *	licensee_share

				break;
			case 3:
				// *	right
				// *	licensee_share

				break;
			case 4:
				// *	right
				// *	collect_assignor_share
				// *	collect_author_share_at_source
				// *	licensor_share
				// *	licensee_share

				break;
			case 5:
				// *	right
				// *	collect_licensor_share
				// *	licensor_share
				// *	licensee_share

				break;
			default:
				console.error('UNKNOWN contract type!');
				break;
		}
	}
};
