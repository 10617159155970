// UI
import Types from './Types/Types';
import Ids from './Ids/Ids';
import Fragment from './Fragment/Fragment';
import DistributionDate from './DistributionDate/DistributionDate';
import DeleteButton from './DeleteButton/DeleteButton';

// Styles
import styles from './AdditionalReleaseForm.module.css';

const AdditionalReleaseForm = ({
	additionalRelease,
	changeField,
	changeMask,
	checkIsValidMask,
	errors,
	additionalErrors,
	setAdditionalErrors,
	deleteAdditionalRelease,
	deleteButtonDisabled,
	handleSetId,
	additionalReleases,
	setAdditionalReleases,
	transferRelease
}) => {
	return (
		<div className={styles.flex}>
			<Types
				additionalRelease={additionalRelease}
				changeField={changeField}
				additionalErrors={additionalErrors}
			/>
			<Ids
				additionalRelease={additionalRelease}
				changeField={changeField}
				changeMask={changeMask}
				checkIsValidMask={checkIsValidMask}
				errors={errors}
				additionalErrors={additionalErrors}
				handleSetId={handleSetId}
				transferRelease={transferRelease}
			/>
			<DistributionDate
				additionalRelease={additionalRelease}
				changeField={changeField}
				additionalErrors={additionalErrors}
				setAdditionalErrors={setAdditionalErrors}
			/>
			{additionalRelease.release_type_id && (
				<Fragment
					additionalRelease={additionalRelease}
					additionalErrors={additionalErrors}
					setAdditionalErrors={setAdditionalErrors}
					additionalReleases={additionalReleases}
					setAdditionalReleases={setAdditionalReleases}
				/>
			)}
			<DeleteButton
				onClick={() => deleteAdditionalRelease(additionalRelease.id)}
				deleteButtonDisabled={deleteButtonDisabled}
			/>
		</div>
	);
};

export default AdditionalReleaseForm;
