// Core
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

// UI
import { FormInput } from 'components/Form/FormInput';
import { Label } from 'components/Labels/Label';

// Styles
import styles from '../ContractTerms.module.css';

const SignType = ({ state, changeField }) => {
	return (
		<div className={styles.Field} style={{ marginBottom: '64px' }}>
			<Label className={'header'} black font="--gilroy-Bold-24">
				<FormattedMessage id={'rod.field.contracts.sign_type.header'} />
			</Label>

			{state?.contractSignTypes.length > 0 &&
				Object.keys(state.data).length > 0 && (
					<FormattedMessage id={'rod.field.contracts.sign_type.placeholder'}>
						{(placeholder) => (
							<FormInput
								type={'dropdown'}
								placeholder={placeholder}
								name={'sign'}
								className="input"
								defaultEmpty
								onChange={changeField}
								errors={state.errors}
								data={state.data}
								required
								items={state.contractSignTypes}
								getItem={(item) => (
									<FormattedHTMLMessage
										id={`rod.field.contracts.sign_types.${item.title}`}
									/>
								)}
								getListItem={(item) => (
									<FormattedHTMLMessage
										id={`rod.field.contracts.sign_types.${item.title}`}
									/>
								)}
								getItemValue={(item) => item.id}
							/>
						)}
					</FormattedMessage>
				)}
		</div>
	);
};

export default SignType;
