/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

import { Button } from 'components/Buttons/Button';
import { Input } from 'components/Inputs/Input';
import { Label } from 'components/Labels/Label';
import { Select } from 'components/Select/Select';
import menu from 'images/menu.svg';
import mic from 'images/mic.svg';
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import styles from './Add.module.css';

class Add extends Component {

	state = {
		items: [
			{
				title: '123',
				date: '10.10.2012',
				linked: false,
			},
			{
				title: '123',
				date: '10.10.2012',
				linked: false,
			},
			{
				title: '123',
				date: '10.10.2012',
				linked: false,
			},
			{
				title: '123',
				date: '10.10.2012',
				linked: false,
			},
		],
	};

	render() {
		return (
			<Container>
				<Row>
					<Col md={12}>
						<Label text={'присоединение к услуге'} variant={'text-30-140'} className={styles.Title}/>
					</Col>
				</Row>
				<Row>
					<Col md={6}>
						<Input placeholder={'найти произведение, фонограму или лирику'}/>
					</Col>
					<Col md={2}/>
					<Col md={4}>
						<Select/>
					</Col>
				</Row>
				<Row>
					<Col md={6}>
						<div className={styles.Repertoire}>
							<Row>
								<Col md={4}><Select/></Col>
								<Col md={4}><Select/></Col>
								<Col md={4}><Select/></Col>
							</Row>
							<Row>
								<Col md={12}>
									<ul>
										{this.state.items.map(item => (
											<li className={styles.Item}>
												<div className={'mic'}><img src={mic} alt=""/></div>
												<div className={'title'}>{item.title}</div>
												<div className={'status'}>-</div>
												<div className={'date'}>{item.date}</div>
												<div className={'menu'}><img src={menu} alt=""/></div>
											</li>
										))}
									</ul>
								</Col>
							</Row>
						</div>
					</Col>
					<Col md={2}>
						<div className={styles.Controls}>
							<span className={styles.Line}/>
							<Button variant={'primary'} text={'присоединить все'}/>
						</div>
					</Col>
					<Col md={4}>
						<div className={styles.Contract}>
							<p>
								<Label variant={'text-18-140'}>контракт включает следующие территории: <b>весь мир,
									кроме: Россия, Узбекистан</b></Label>
							</p>
							<p>
								<Label variant={'text-18-140'}>срок действия контракта: <b>30.10.19 —
									30.10.22</b></Label>
							</p>
							<p>
								<Label variant={'text-18-140'}>тип прав: <b>авторские</b></Label>
							</p>
							<div className={styles.LinkedItems}>
								<ul>
									<li className={styles.Item}>
										<div className={'mic'}><img src={mic} alt=""/></div>
										<div className={'title'}>sdfsdfsdfd sdfsdfsdf</div>
									</li>
								</ul>
							</div>

						</div>

						<Button variant={'link'} text={'сохранить изменения'}/>
					</Col>
				</Row>
			</Container>
		);
	}
}

export default Add;
