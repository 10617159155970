// Core
import { FormattedMessage } from 'react-intl';

// UI
import { TitleButton } from 'components/Buttons';

// Styles
import styles from './TitleWithHelpBtn.module.css';

const TitleWithHelpBtn = ({ title }) => {
	return (
		<span className={styles.title}>
			<FormattedMessage id={title} />
			<TitleButton />
		</span>
	);
};

export default TitleWithHelpBtn;
