import { withAuth, withRoot, withUI } from 'hocs';
import React, { PureComponent } from 'react';
import { Button } from 'components/Buttons';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import styles from './EmailNoCode.module.css';
import { Label } from '../../../../components/Labels/Label';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

class EmailNoCode extends PureComponent {
	static propTypes = {};

	componentDidMount() {
		// this.props.UIContext.showBackNavi();
	}

	componentWillUnmount() {
		// this.props.UIContext.hideBackNavi();
	}

	handleBack = () => {
		this.props.history.push('/');
	};

	render() {
		return (
			<div className={styles.Page}>
				<div className={styles.Form}>
					<div className={styles.Title}>
						<Label black>
							<FormattedMessage id={'rod.no_code.header.email'} />
						</Label>
						<Label black>
							<FormattedHTMLMessage id={'rod.no_code.subheader'} />
						</Label>
					</div>

					<div className={styles.Controls}>
						<Link to={'/logout'}>
							<Button
								className={styles.Button}
								text={<FormattedMessage id={'rod.action.to_index_page'} />}
								primary
							/>
						</Link>
					</div>
				</div>
			</div>
		);
	}
}

export default compose(withRoot, withAuth, withUI)(EmailNoCode);
