// Core
import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

// Utils
import { getCountryName } from '../utils/getCountryName';

// Styles
import styles from './TopTen.module.css';

const TopTen = ({ tenTopCountries }) => {
	const lang = localStorage.getItem('lang');

	return (
		<div
			className={styles.topTen}
			// style={{ height: `${tenTopCountries.length * 28 + 37}px` }}
		>
			<div className={styles.tenTopTableHeading}>
				<span>
					<FormattedHTMLMessage id={'rod.statistic.top-ten-table-country'} />
				</span>
				<span>
					<FormattedHTMLMessage id={'rod.statistic.top-ten-table-listening'} />
				</span>
			</div>
			<ul className={styles.topTenData}>
				{tenTopCountries.map((item, index) => (
					<li>
						<span className={styles.countryPlace}>{index + 1}</span>
						<span className={styles.countryName}>
							{getCountryName(item, lang)}
						</span>
						<span className={styles.countryValue}>
							{item.auditions.toLocaleString('ru-RU')}
						</span>
					</li>
				))}
			</ul>
		</div>
	);
};
export default TopTen;
