// Core
import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { Col, Row } from 'react-grid-system';
import { Checkbox } from 'components/Checkboxes';

// Styles
import styles from './TableHeader.module.css';

const TableHeader = ({ checkedAll, checkedNotAll, selectAll }) => {
	return (
		<Row className={styles.row_header}>
			<Col width={'5%'}>
				<div style={{ paddingLeft: '14px' }}>
					<Checkbox
						checked={checkedAll}
						checkedNotAll={checkedNotAll}
						onChange={selectAll}
					/>
				</div>
			</Col>
			<Col width={'5%'}>
				<FormattedHTMLMessage id={'rod.for_all.title'} />
			</Col>
			<Col width={'20%'}></Col>
			<Col width={'20%'}>
				<FormattedHTMLMessage id={'rod.for_all.performer'} />
			</Col>
			<Col width={'15%'}>
				<FormattedHTMLMessage id={'rod.for_all.type'} />
			</Col>
			<Col width={'15%'}>
				<FormattedHTMLMessage id={'rod.for_all.status'} />
			</Col>
			<Col width={'15%'}>
				<FormattedHTMLMessage id={'rod.for_all.date'} />
			</Col>
			<Col width={'5%'}></Col>
		</Row>
	);
};
export default TableHeader;
