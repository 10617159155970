export const personalChecksWithItin = (data) => {
	return (
		data.first_name &&
		data.last_name &&
		data.birth_date &&
		data.birth_place &&
		data.itin &&
		data.national_id_number &&
		data.national_id_issued_by &&
		data.national_id_issued_date
	);
};

export const personalChecksWithoutItin = (data) => {
	return (
		data.first_name &&
		data.last_name &&
		data.birth_date &&
		data.birth_place &&
		data.national_id_number &&
		data.national_id_issued_by &&
		data.national_id_issued_date
	);
};

export const companyChecksWithItin = (data) => {
	return (
		data.title &&
		data.behalf &&
		data.behalf_short &&
		data.signature_position &&
		data.company_registration_date &&
		data.company_registration_number &&
		data.company_vat_number &&
		data.acting_on &&
		data.country_id &&
		data.itin
	);
};

export const companyChecksWithoutItin = (data) => {
	return (
		data.title &&
		data.behalf &&
		data.behalf_short &&
		data.signature_position &&
		data.company_registration_date &&
		data.company_registration_number &&
		data.company_vat_number &&
		data.acting_on &&
		data.country_id
	);
};
