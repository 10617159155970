// Core
import { FormattedMessage } from 'react-intl';

// UI
import trash from 'images/trash.svg';

// Styles
import styles from './DeleteButton.module.css';

const DeleteButton = ({ onClick, deleteButtonDisabled }) => {
	const buttonClass = deleteButtonDisabled
		? `${styles.delete__button} ${styles.disabled}`
		: styles.delete__button;

	return (
		<button
			disabled={deleteButtonDisabled}
			className={buttonClass}
			onClick={onClick}
		>
			<img src={trash} alt="trash" />
			<FormattedMessage
				id={'rod.release.create.step.tracks.additional_release.delete_button'}
			/>
		</button>
	);
};

export default DeleteButton;
