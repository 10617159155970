// Core
import { FormattedMessage } from 'react-intl';

export const ckeckErrorsBeforeSave = ( errors ) => {
	if (errors.sum === '') {
		return <FormattedMessage id={`rod.release.create.sum`} />;
	}

	if (errors.roles && errors.roles.includes('required_roles')) {
		return <FormattedMessage id={`rod.error.required_roles`} />;
	}

	if (errors?.controlled_by_submitter) {
		return <FormattedMessage id={`rod.error.required_submitter`} />;
	}
};
