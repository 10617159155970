// UI
import { Label } from 'components/Labels';

// Styles
import styles from './Field.module.css';

const Field = (props) => {
	return (
		<div className={styles.Field}>
			<Label text={props.title} font="--gilroy-Medium-24" className="title" />
			{props.value || props.children}
		</div>
	);
};

export default Field;
