// Core
import { FormattedHTMLMessage } from 'react-intl';

// Styles
import styles from './NoDataStats.module.css';

const NoDataStat = () => {
  return (
    <div
      className={styles.noData}
    >
      <div className={styles.noData_title}>
        <FormattedHTMLMessage
          tagName='h2'
          id={'rod.statistic.no-data-fetched'}
        />
        <FormattedHTMLMessage
          id={'rod.statistic.no-data-fetched_description'}
        />
      </div>
    </div>
  )
}

export default NoDataStat;