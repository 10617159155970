// Utils
import { isDateUnavailable } from './isDateUnavailable';

export const checkFieldIsValid = (
	data,
	setAdditionalErrors,
	transferRelease,
	isSlaPrivelegesForAccount
) => {
	const validationErrors = {};
	validationErrors.id = data.id;

	if (!data.release_type_id) {
		validationErrors.release_type_id = [{ rule: 'required' }];
		setAdditionalErrors(validationErrors);
		return false;
	}

	if (!data.snippet_start) {
		validationErrors.snippet_start = [{ rule: 'required' }];
		setAdditionalErrors(validationErrors);
		return false;
	}

	if (!data.generate_ean && !data.ean) {
		validationErrors.ean = [{ rule: 'required_if', value: false }];
		setAdditionalErrors(validationErrors);
		return false;
	}

	if (!data.generate_ean && data.ean.length < 8) {
		validationErrors.ean = [{ rule: 'min_length', value: 8 }];
		setAdditionalErrors(validationErrors);
		return false;
	}

	if (!data.generate_ean && data.ean.length > 18) {
		validationErrors.ean = [{ rule: 'max_length', value: 18 }];
		setAdditionalErrors(validationErrors);
		return false;
	}

	if (
		!transferRelease &&
		!data.generate_catalog_number &&
		!data.catalog_number
	) {
		validationErrors.catalog_number = [{ rule: 'invalid' }];
		setAdditionalErrors(validationErrors);
		return false;
	}

	if (data.sale_start_date) {
		const minDateValue = !isSlaPrivelegesForAccount
			? Date.now() + 604800000 // 7+ days
			: isSlaPrivelegesForAccount
			? Date.now() + 86400000 // 1+ day
			: null;

		const isUnavailable = isDateUnavailable(data.sale_start_date, minDateValue);
		if (isUnavailable) {
			validationErrors.sale_start_date = [{ rule: 'wrong_format' }];
			setAdditionalErrors(validationErrors);
			return false;
		}
		return true;
	}

	return true;
};
