// Core
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { withUI, withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';
import DOMPurify from 'dompurify';
import { getHelpMessage } from 'messages/helper';

// Utils
import { sanitizeData } from 'utils';

// UI
import { Button } from 'components/Buttons/Button';
import { FormInput } from 'components/Form/FormInput';
import { SettingsLayout } from '../SettingsLayout';

// Styles
import styles from './SettingsContactDataSection.module.css';

const SettingsContactDataSection = (props) => {
	const lang = localStorage.getItem('lang');
	const [errors, setErrors] = useState({});
	const [personal, setPersonal] = useState({});
	const [isEdit, setIsEdit] = useState(false);

	const [isChangedSmth, setIsChangedSmth] = useState(false);

	const isAllRequiredFilled = (data) => {
		if (
			data.country_id &&
			data.zip_code &&
			data.address &&
			data.email &&
			data.phone_code_country_id &&
			data.phone_number
		) {
			return true;
		} else {
			return false;
		}
	};

	useEffect(() => {
		!isAllRequiredFilled(personal)
			? props.isAllDone('contact')(false)
			: props.isAllDone('contact')(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [personal]);

	useEffect(() => {
		setErrors(props.errors);
		if (!Object.keys(props.errors).length) {
			props.isAllDone('contact')(true);
		}

		setIsEdit(Object.keys(props.errors).length > 0);
		setIsChangedSmth(Object.keys(props.errors).length > 0);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.errors]);

	useEffect(() => {
		setErrors({});
		const { data } = props;

		setPersonal((prev) => ({
			...prev,
			...data,
		}));
		props.isAllDone('contact')(isAllRequiredFilled(data));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.data]);

	const {
		rootContext: { countries },
		UIContext: { showModal },
	} = props;

	const message = DOMPurify.sanitize(...getHelpMessage(lang, 'contacts'));

	const changeField = (field) => (value) => {
		if (!isChangedSmth) {
			setIsChangedSmth(true);
		}

		// Clear the error for the specific field
		setErrors((prevErrors) => {
			const newErrors = { ...prevErrors };
			delete newErrors[field];
			return newErrors;
		});

		setPersonal((prev) => ({ ...prev, [field]: value }));
	};

	const changePhoneFields = (query) => {
		if (!isChangedSmth) {
			setIsChangedSmth(true);
		}

		setPersonal((prev) => ({ ...prev, ...query }));
	};

	const saveHandler = (e) => {
		e.preventDefault();
		setIsChangedSmth(false);

		// Sanitize the personal data
		const sanitizedPersonal = sanitizeData(personal);
		props.onChange({
			...sanitizedPersonal,
		});
	};

	const handleEditToggle = () => {
		setIsEdit(!isEdit);
	};

	return (
		<>
			{personal && (
				<SettingsLayout
					isEdit={isEdit}
					handleEditToggle={handleEditToggle}
					showModal={showModal}
					message={message}
					title="rod.account.contact.title"
				>
					<div>
						<FormattedMessage id={'rod.field.country'}>
							{(placeholder) => (
								<FormInput
									type="country"
									countries={countries}
									placeholder={placeholder}
									name={'country_id'}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
									className={styles.country}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.zip'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'zip_code'}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.admin.account.city'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'city'}
									onChange={changeField}
									errors={errors}
									data={personal}
									className={styles.residentialAddress}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.address_noCity'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'address'}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
									className={styles.residentialAddress}
								/>
							)}
						</FormattedMessage>
						{isEdit && (
							<div className={styles.save_btn}>
								<Button
									text={<FormattedMessage id={'rod.action.save'} />}
									variant={'primary'}
									onClick={saveHandler}
									disabled={!isEdit || !isChangedSmth}
									style={{
										pointerEvents: isChangedSmth ? 'all' : 'none',
									}}
								/>
							</div>
						)}
					</div>
					<div>
						<FormattedMessage id={'rod.field.phone'}>
							{(placeholder) => (
								<FormInput
									type={'phoneFunc'}
									placeholder={placeholder}
									names={['phone_number', 'phone_code_country_id']}
									onChanges={changePhoneFields}
									errors={errors}
									data={personal}
									required
									className={styles.dropDownPhone}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.email.en'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'email'}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
									className={styles.email}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.website'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'site_url'}
									onChange={changeField}
									errors={errors}
									data={personal}
									className={styles.webSite}
								/>
							)}
						</FormattedMessage>
					</div>
				</SettingsLayout>
			)}
		</>
	);
};

export default compose(withUI, withAuth, withRoot)(SettingsContactDataSection);
