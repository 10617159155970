// Core
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { withAuth, withUI } from 'hocs';

// UI
import { Label } from 'components/Labels';
import chevronUp from '../../../images/chevron_up.svg';
import chevronDown from '../../../images/chevron_down.svg';

// Styles
import styles from './Unavailable.module.css';

class Unavailable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isChevronOpen: false,
		};
	}

	componentDidMount() {
		const {
			authContext: { isAccountLoading, setIsAccountLoading },
		} = this.props;

		this.props.UIContext.showBackNavi();

		if (isAccountLoading) {
			setTimeout(() => {
				setIsAccountLoading(false);
			}, 500);
		}
	}

	componentWillUnmount() {
		this.props.UIContext.hideBackNavi();
	}

	handleOnClick = () => {
		!this.state.isChevronOpen
			? this.setState({ isChevronOpen: true })
			: this.setState({ isChevronOpen: false });
	};

	render() {
		const { err } = this.props;

		return (
			<div className={styles.Page}>
				<div className={styles.Form}>
					<div className={styles.Title}>
						<Label font="--gilroy-Medium-40" black>
							<FormattedMessage id={'rod.error_page.unavailable.header'} />
						</Label>
						<Label font="--gilroy-Medium-24" black>
							<FormattedMessage id={'rod.error_page.unavailable.body'} />
						</Label>
					</div>
					{err && (
						<>
							<div
								className={styles.detailsWrapper}
								onClick={this.handleOnClick}
							>
								<Label
									className={styles.detailsHeader}
									font="--gilroy-Medium-22"
									black
								>
									<FormattedMessage id={'rod.error_page.unavailable.datails'} />
								</Label>
								<img
									src={!this.state.isChevronOpen ? chevronUp : chevronDown}
									alt=""
								/>
							</div>
							{this.state.isChevronOpen && (
								<div className={styles.detailsText} font="--gilroy-Medium-20">
									{err}
								</div>
							)}
						</>
					)}
				</div>
			</div>
		);
	}
}

export default compose(withAuth, withUI)(Unavailable);
