// Core
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Col, Container, Row } from 'react-grid-system';
import { Loading, Wizard } from 'components';
import { Label } from 'components/Labels/Label';
import accountCompletionSteps from 'constants/accountCompletionSteps';
import { ContractListItem } from 'components/ContractListItem';
import { withAuth, withRoot, withUI } from 'hocs';
import update from 'immutability-helper';
import styles from 'pages/contracts/AddContract/steps/06-Complete/Complete.module.css';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import { RootContext } from 'contexts/RootContext';
import { contracts } from 'services';

export class Pending extends PureComponent {
	static contextType = RootContext;
	constructor(props) {
		super(props);

		this.steps = [
			{
				id: 1,
				status: 'complete',
				title: <FormattedMessage id={'rod.account.create.step_3_1.title'} />,
			},
			{
				id: 2,
				status: 'active',
				title: <FormattedMessage id={'rod.account.create.step_3_2.title'} />,
			},
		];

		this.state = {
			loading: true,
			data: {},
		};
	}

	componentDidMount() {
		console.info('pending');

		const accId = this.props.match
			? this.props.match.params.id
			: this.props.account && this.props.account.id;

		if (this.props.isAdditional) {
			contracts.getAdditionalContracts(accId).then((res) => {
				res = res.data.data;

				this.setState(
					update(this.state, {
						data: { $set: res },
						loading: { $set: false },
					})
				);

				if (
					!this.props.sms &&
					!this.props.showSigned &&
					this.props.completionStep !== accountCompletionSteps.PENDING_CONTRACT
				) {
					this.submit();
				}
			});
		} else {
			contracts.getContracts(accId).then((res) => {
				res = res.data.data;

				this.setState(
					update(this.state, {
						data: { $set: res },
						loading: { $set: false },
					})
				);

				const isOnPending = res.find(
					(item) => item.moderation_status === 'pending_contracts'
				);

				if (!isOnPending) {
					return this.props.history.push(`/accounts/${accId}/contracts`);
				}
				if (
					!this.props.sms &&
					!this.props.showSigned &&
					this.props.completionStep !== accountCompletionSteps.PENDING_CONTRACT
				) {
					this.submit();
				}
			});
		}
	}

	submit = () => {
		if (typeof this.props.onSubmit === 'function' && this.props.isAdditional) {
			this.props.onSubmit(this.state.data);
		}
	};

	render() {
		if (this.state.loading) {
			return <Loading non_margin={true} />;
		}

		const { sms, showSigned } = this.props;
		const { data } = this.state;

		return (
			<Container fluid className={styles.Page}>
				<Wizard steps={this.steps} className={styles.Wizard} />
				<div className={styles.Form}>
					<Row>
						<Col sm={10} offset={{ sm: 1 }} className={styles.Header}>
							<Label
								text={
									<FormattedHTMLMessage id={'rod.contracts.complete.header'} />
								}
								font="--gilroy-Medium-40"
								black
							/>
							{sms ? (
								<Label
									className="sub"
									text={
										<FormattedHTMLMessage
											id={'rod.contracts.complete.subheader_sms'}
										/>
									}
									font="--gilroy-Medium-24"
									black
								/>
							) : (
								<React.Fragment>
									{showSigned ? (
										<Label
											className="sub"
											text={
												<FormattedHTMLMessage
													id={'rod.contracts.complete_signed.subheader'}
												/>
											}
											font="--gilroy-Medium-24"
											black
										/>
									) : (
										<Label
											className="sub"
											text={
												<FormattedHTMLMessage
													id={'rod.contracts.complete.subheader'}
												/>
											}
											font="--gilroy-Medium-24"
											black
										/>
									)}
								</React.Fragment>
							)}

							<ul className={styles.List}>
								{data.map((item, index) => (
									<li>
										<ContractListItem
											{...item}
											index={index}
											showDownloadSigned={sms || this.props.showSigned}
											showDate
											date={item.signed_at || Date.now()}
											showHash
											hash={item.hash}
										/>
									</li>
								))}
							</ul>
						</Col>
					</Row>
				</div>
			</Container>
		);
	}
}

Pending.propTypes = {
	onSubmit: PropTypes.func,
};

Pending.defaultProps = {};

export default compose(withAuth, withUI, withRoot)(Pending);
