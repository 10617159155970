export const isDone = (toCheck, transferRelease, isH11Edit, errors, setErrors, isSnippedRender, releaseData ) => {
  let errorCount = 0;
  const newError = {};

  if (!toCheck.title) {
    newError.title = 'обязательное поле';
    errorCount++;
  }

  if (!toCheck.created_date) {
    newError.created_date = [{ rule: 'required' }];
    errorCount++;
  }

  if (!toCheck.genres || toCheck.genres.length === 0) {
    newError.genres = 'обязательное поле';
    errorCount++;
  }

  if (typeof toCheck.created_country_id !== 'number') {
    newError.created_country_id = 'обязательное поле';
    errorCount++;
  }

  if (!toCheck.isrc && !toCheck.generate_isrc) {
    newError.isrc = 'обязательное поле';
    errorCount++;
  }

  if (toCheck.isrc?.includes('_') && !toCheck.generate_isrc) {
    newError.isrc = 'обязательное поле';
    errorCount++;
  }

  if (
    !transferRelease &&
    !isH11Edit &&
    !toCheck.catalog_number &&
    !toCheck.generate_catalog_number
  ) {
    newError.catalog_number = 'обязательное поле';
    errorCount++;
  }

  if (
    !transferRelease &&
    !isH11Edit &&
    toCheck.catalog_number?.includes('_') &&
    !toCheck.generate_catalog_number
  ) {
    newError.catalog_number = 'обязательное поле';
    errorCount++;
  }

  if (!toCheck.main_performer) {
    newError.main_performer = 'обязательное поле';
    errorCount++;
  }

  if (!toCheck.producer && !toCheck.party_id) {
    newError.party_id = 'обязательное поле';
    errorCount++;
  }

  if (errors.created_date) {
    errorCount++;
    newError.created_date = [{ rule: 'wrong_format' }];
  }

  if (
    isSnippedRender &&
    !toCheck.snippet_start &&
    releaseData.step !== 'h11'
  ) {
    errorCount++;
    newError.snippet_start = [{ rule: 'required' }];
  }

  if (!errorCount) {
    return true;
  } else {
    return false;
  }
};