// Core
import React from 'react';
import parse from 'html-react-parser';
import { Col, Row } from 'react-grid-system';
import { FormattedMessage } from 'react-intl';

// UI
import { Bullet } from 'components/Bullet';
import { Label } from 'components/Labels/Label';
import { DropZone } from 'components/Upload/DropZone';
import { InfoButton } from 'components/Buttons';

// Types
import documentTypes from 'constants/documentTypes';

// Styles
import styles from '../Documents.module.css';

const Company = ({
	showModal,
	files,
	message,
	moderationStatus,
	handleFileUpload,
	handleFileRemove,
	children,
}) => {
	return (
		<Row nogutter nowrap className={styles.Row}>
			<Col sm={6} offset={{ sm: 1 }} className={styles.Left}>
				<div className={styles.Form}>
					<div className={styles.Header}>
						<InfoButton
							className={styles.infoButton}
							onClick={showModal({
								text: parse(message),
							})}
						>
							<Label
								text={
									<FormattedMessage
										id={'rod.account.create.documents.company.title'}
									/>
								}
								font="--gilroy-Medium-40"
								display="inline"
								black
							/>
						</InfoButton>
					</div>
					<div className={styles.FormLine}>
						<div className={styles.Subheader}>
							<Label
								text={
									<FormattedMessage
										id={'rod.account.create.documents.company.registration'}
									/>
								}
								font="--gilroy-Medium-24"
								black
							/>
						</div>
						<DropZone
							onUpload={handleFileUpload(
								documentTypes.COMPANY_REGISTRATION_CERTIFICATE
							)}
							value={files[documentTypes.COMPANY_REGISTRATION_CERTIFICATE]}
							onRemove={handleFileRemove(
								files[documentTypes.COMPANY_REGISTRATION_CERTIFICATE]
							)}
							moderation={
								moderationStatus[documentTypes.COMPANY_REGISTRATION_CERTIFICATE]
							}
							kb
						/>
					</div>
					<div className={styles.FormLine}>
						<div className={styles.Subheader}>
							<Label
								text={
									<FormattedMessage
										id={'rod.account.create.documents.company.tax'}
									/>
								}
								font="--gilroy-Medium-24"
								black
							/>
						</div>
						<DropZone
							onUpload={handleFileUpload(
								documentTypes.COMPANY_TAX_RESIDENCY_CERTIFICATE
							)}
							value={files[documentTypes.COMPANY_TAX_RESIDENCY_CERTIFICATE]}
							onRemove={handleFileRemove(
								files[documentTypes.COMPANY_TAX_RESIDENCY_CERTIFICATE]
							)}
							moderation={
								moderationStatus[
									documentTypes.COMPANY_TAX_RESIDENCY_CERTIFICATE
								]
							}
							kb
						/>
					</div>
				</div>
			</Col>
			<Col sm={5} className={styles.Right}>
				<div>
					<Label
						text={
							<FormattedMessage
								id={'rod.account.create.documents.list_title'}
							/>
						}
						font="--gilroy-Medium-32"
						black
						className={styles.Header}
					/>
					<Label
						text={
							<FormattedMessage
								id={'rod.account.create.documents.company.title'}
							/>
						}
						font="--gilroy-Medium-24"
						black
						className={styles.Header}
					/>
					<ul>
						<li>
							<Bullet
								outline={!files[documentTypes.COMPANY_REGISTRATION_CERTIFICATE]}
								text={
									<FormattedMessage
										id={'rod.account.create.documents.company.registration'}
									/>
								}
							/>
						</li>
						<li>
							<Bullet
								outline={
									!files[documentTypes.COMPANY_TAX_RESIDENCY_CERTIFICATE]
								}
								text={
									<FormattedMessage
										id={'rod.account.create.documents.company.tax'}
									/>
								}
							/>
						</li>
					</ul>
					{children}
				</div>
			</Col>
		</Row>
	);
};

export default Company;
