export const determineDisable = (
	screenWidth,
	length,
	currentIndex,
	setDisable
) => {
	if (screenWidth < 640) {
		if (length === 5) {
			if (currentIndex === 4) {
				setDisable(true);
			} else {
				setDisable(false);
			}
		} else {
			setDisable(false);
		}
	} else if (screenWidth >= 640 && screenWidth < 1140) {
		if (length === 5) {
			if (currentIndex === 3) {
				setDisable(true);
			} else {
				setDisable(false);
			}
		} else {
			setDisable(false);
		}
	} else if (screenWidth >= 1140 && screenWidth < 1460) {
		if (length === 5) {
			if (currentIndex === 2) {
				setDisable(true);
			} else {
				setDisable(false);
			}
		} else {
			setDisable(false);
		}
	} else if (screenWidth >= 1460) {
		if (length === 5) {
			if (currentIndex === 1) {
				setDisable(true);
			} else {
				setDisable(false);
			}
		} else {
			setDisable(false);
		}
	} else {
		setDisable(false);
	}
};
