// Core
import { FormattedMessage } from 'react-intl';

export const radioItems = [
	{
		text: (
			<FormattedMessage
				id={'rod.release.cmo.settings.service_territories.all'}
			/>
		),
		value: '17',
	},
	{
		text: (
			<FormattedMessage
				id={'rod.release.cmo.settings.service_territories.selected'}
			/>
		),
		value: 'selected-territories',
	},
	{
		text: (
			<FormattedMessage
				id={'rod.release.cmo.settings.service_territories.except'}
			/>
		),
		value: 'exclusive-territories',
	},
];
