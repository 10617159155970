/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 25/05/2020, 20:02
 */

import { CloseButton } from 'components/Buttons';
import { Button } from 'components/Buttons';
import { Lang } from 'components';
import { Col, Container, Row } from 'components/Grid';
import { FormInput } from 'components/Form/FormInput';
import { compose } from 'recompose';
import { withLang, withRoot, withUI } from 'hocs';
import Admin from 'services/api/admin';
import union from 'lodash/union';

import React, { Component } from 'react';
import posed, { PoseGroup } from 'react-pose';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import BaseModal from './BaseModal';

import RichTextEditor from 'react-rte';

import styles from './Modal.module.css';
import debounce from 'lodash.debounce';
import { showError } from 'validators/showError';

const Modal = posed.div({
	enter: {
		x: 0,
		opacity: 1,
		// delay: 200,
		transition: {
			x: {
				ease: 'easeIn',
			},
			default: { duration: 300 },
		},
	},
	exit: {
		x: '100%',
		opacity: 0,
		transition: { duration: 250 },
	},
});

const Shade = posed.div({
	enter: { opacity: 0.5 },
	exit: { opacity: 0 },
});

class NotifyScreenModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: RichTextEditor.createEmptyValue(),
			isTextAreaReady: false,
			confirmed: false,
			isConfirmedBtnActive: true,
			sendAll: false,
			isVisible: false,
			isShowConfirmQuestion: false,
			errors: { recipients: {}, title: '' },
			title: '',
			bodyText: '',
			data: {
				selectedData: { recipients: [] },
				recipients: [
					{
						id: 1,
						title_ru: '1',
						title_en: '1',
					},
					{
						id: 2,
						title_ru: '2',
						title_en: '2',
					},
					{ id: 3, title_ru: '3', title_en: '3' },
					{ id: 4, title_ru: '4', title_en: '4' },
				],
			},
		};
	}

	componentDidMount() {
		const { /* title, text, children, width,  */ users } = this.props;

		this.setState({
			isVisible: true,
			onCloseFn: this.props.onClose,
			data: { ...this.state.data, recipients: users },
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (
			this.state.title &&
			this.state.value &&
			(this.state.data.selectedData.recipients.length || this.state.sendAll) &&
			!this.state.confirmed
		) {
			this.setState({ confirmed: true });
		} else if (prevState.confirmed) {
			this.setState({ confirmed: false });
		}
	}

	willUnmount(e) {
		this.setState({
			isVisible: false,
		});
		setTimeout(() => {
			this.state.onCloseFn();
		}, 300);
	}

	onChange = (value) => {
		this.setState({ value });
		if (this.props.onChange) {
			this.props.onChange(value.toString('html'));
		}
	};

	changeField = (field) => (e) => {
		this.setState({ errors: {} });
		if (e && e.target) {
			this.setState({ [field]: e.target.value });
		} else {
			this.setState({ [field]: e });
		}
	};

	preConfirmData = () => {
		this.setState({ isShowConfirmQuestion: true });
	};

	debouncedSendData = debounce(() => this.sendData(), 500);

	sendData = (e) => {
		const {
			langContext: { lang },
			UIContext: { setNotificationsSent, notificationsSent },
		} = this.props;

		const recipients = this.state.sendAll
			? this.state.data.recipients.map((recepient) => recepient.id)
			: this.state.data.selectedData.recipients.map(
					(recepient) => recepient.id
			  );

		Admin.createAdminNotifications(
			lang,
			this.state.title,
			this.state.value.toString('html'),
			this.state.sendAll ? 1 : 0,
			this.state.sendAll ? '' : recipients
		)
			.then((res) => {
				this.setState({
					isShowConfirmQuestion: false,
				});
				setNotificationsSent(union(notificationsSent, recipients));
				this.willUnmount();
				this.props.isNotificationSend(true);
			})
			.catch((err) => {
				this.setState({ errors: err.response.data.errors });
			})
			.finally(() => {
				this.setState({
					isConfirmedBtnActive: true,
				});
			});
	};

	changeSelectUsersField = (e) => {
		this.setState({
			data: { ...this.state.data, selectedData: { recipients: e } },
		});
	};

	prepareWysiwyg = () => {
		if (this.state.isTextAreaReady) {
		}
	};

	render() {
		if (this.state.isTextAreaReady) {
			this.prepareWysiwyg();
		}

		const toolbarConfig = {
			// Optionally specify the groups to display (displayed in the order listed).
			display: [
				'INLINE_STYLE_BUTTONS',
				'BLOCK_TYPE_BUTTONS',
				'LINK_BUTTONS',
				// 'BLOCK_TYPE_DROPDOWN',
				'HISTORY_BUTTONS',
			],
			INLINE_STYLE_BUTTONS: [
				{ label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
				{ label: 'Italic', style: 'ITALIC' },
				{ label: 'Underline', style: 'UNDERLINE' },
			],
			// BLOCK_TYPE_DROPDOWN: [
			// 	{ label: 'Normal', style: 'unstyled' },
			// 	{ label: 'Heading Large', style: 'header-one' },
			// 	{ label: 'Heading Medium', style: 'header-two' },
			// 	{ label: 'Heading Small', style: 'header-three' },
			// ],
			BLOCK_TYPE_BUTTONS: [
				{ label: 'UL', style: 'unordered-list-item' },
				{ label: 'OL', style: 'ordered-list-item' },
			],
		};

		return (
			<BaseModal className={styles.Modal}>
				<PoseGroup>
					{this.state.isVisible && [
						<Shade key="shade" className={styles.Overlay} />,

						<Modal
							key="modal"
							style={{
								marginLeft: 'auto',
							}}
						>
							<Container fluid>
								<Row>
									<Col
										md={12 - 4}
										onClick={(e) => {
											this.willUnmount(e);
										}}
									/>
									<Col md={4} className={styles.notify}>
										<div
											className={styles.notify__wrapper}
											id={'notificationModalContainer'}
										>
											<div className={styles.notify__top_wrapper}>
												<div className={styles.notify__title}>
													<FormattedHTMLMessage
														id={'rod.modal.notify.admin.create_notification'}
													/>
												</div>
												<CloseButton
													black
													small
													className={styles.CloseButtonNotify}
													onClick={(e) => {
														this.willUnmount(e);
													}}
												/>
											</div>
											<div className={styles.notify__lang}>
												<ul className={styles.Tray}>
													<li>
														{this.props.langContext.isShowLang && (
															<Lang
																className={styles.Lang}
																authContext={this.props.authContext}
															/>
														)}
													</li>
												</ul>
											</div>
											<div className={styles.notify__header}>
												<FormattedMessage
													id={'rod.modal.notify.admin.placeholder-header'}
												>
													{/* {(placeholder) => (
														<input
															type="text"
															name="notifyTitle"
															className={styles.input}
															placeholder={placeholder}
															onChange={(e) => {
																this.changeField('title', e);
															}}
														/>
													)} */}
													{(placeholder) => (
														<FormInput
															placeholder={placeholder}
															name={'title'}
															onChange={this.changeField}
															errors={this.state.errors}
															required
															data={this.state}
															className={styles.articleNameInput}
														/>
													)}
												</FormattedMessage>
												{Object.keys(this.state.errors).includes('heading') && (
													<span className={styles.Helper}>
														{showError('notifyTitle')(
															this.state.errors.heading[0]
														)}
													</span>
												)}
											</div>
											<div className={styles.notify__body}>
												<RichTextEditor
													toolbarClassName={styles.toolbarEditor}
													editorClassName={styles.textEditor}
													placeholder={
														<FormattedMessage
															id={'rod.modal.notify.admin.placeholder-body'}
														></FormattedMessage>
													}
													value={this.state.value}
													onChange={this.onChange}
													toolbarConfig={toolbarConfig}
												/>
											</div>

											<div
												className={`styles.notify__recipients ${
													this.state.sendAll ? styles.disable : ''
												}`}
											>
												<FormInput
													type={'muiAutocompleteMultipleUsers'}
													name={'recipients'}
													onChange={(e) => {
														this.changeSelectUsersField(e);
													}}
													errors={this.state.errors}
													data={this.state.data.selectedData}
													items={this.state.data.recipients}
												/>
												{/* </Portal> */}
											</div>

											<div className={styles.notify__sendAllMark}>
												{/* <FormControlLabelCheckbox
													control={
														<Checkbox
															checked={this.state.sendAll}
															onChange={() => {
																this.setState({ sendAll: !this.state.sendAll });
															}}
														/>
													}
													label={
														<FormattedMessage
															id={'rod.modal.notify.admin.send-all'}
														/>
													}
												/> */}

												{/* <Checkbox
													className={styles.checkboxSendAll}
													onChange={() => {
														this.setState({ sendAll: !this.state.sendAll });
													}}
												>
													<FormattedMessage
														id={'rod.modal.notify.admin.send-all'}
													/>
												</Checkbox> */}
												<div
													className={styles.checkbox_wrapper}
													onClick={() => {
														this.setState({ sendAll: !this.state.sendAll });
													}}
												>
													<div
														className={
															this.state.sendAll
																? styles.checked
																: styles.notChecked
														}
													/>
													<div className={styles.checkbox_label}>
														<FormattedMessage
															id={'rod.modal.notify.admin.send-all'}
														/>
													</div>
												</div>
											</div>
											{!this.state.isShowConfirmQuestion ? (
												<div className={styles.notify__confirm}>
													<Button
														text={
															<FormattedHTMLMessage id={'rod.action.confirm'} />
														}
														className={
															!this.state.confirmed
																? styles.inactiveBtn
																: styles.activeBtn
														}
														variant={'primary'}
														disabled={!this.state.confirmed}
														onClick={this.preConfirmData}
													/>
												</div>
											) : (
												<div
													className={styles.notify__confirm_question_wrapper}
												>
													<div className={styles.notify__confirm_question}>
														<FormattedHTMLMessage
															id={'rod.modal.notify.admin.confirm-question'}
														/>
													</div>
													<div className={styles.notify__confirm}>
														<Button
															text={
																<FormattedHTMLMessage
																	id={'rod.modal.notify.admin.confirm-yes'}
																/>
															}
															className={styles.activeBtnConfirmQuestion}
															variant={
																this.state.isConfirmedBtnActive
																	? 'primary'
																	: 'disabled'
															}
															onClick={() => {
																if (this.state.isConfirmedBtnActive) {
																	this.setState({
																		isConfirmedBtnActive: false,
																	});
																	this.debouncedSendData();
																}
															}}
														/>
														<Button
															text={
																<FormattedHTMLMessage
																	id={'rod.modal.notify.admin.confirm-no'}
																/>
															}
															className={styles.activeBtnConfirmQuestion}
															variant={
																this.state.isConfirmedBtnActive
																	? 'primary'
																	: 'disabled'
															}
															onClick={() => {
																this.setState({
																	isShowConfirmQuestion: false,
																});
															}}
														/>
													</div>
												</div>
											)}
										</div>
									</Col>
								</Row>
							</Container>
						</Modal>,
					]}
				</PoseGroup>
			</BaseModal>
		);
	}
}

export default compose(withLang, withUI, withRoot)(NotifyScreenModal);
