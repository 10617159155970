// Core
import { useContext } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { UIContext } from 'contexts/UIContext';

// Icons
import { Cd } from 'material-design/svg';
import { Nota } from 'material-design/svg';

// Styles
import styles from './ContentEmpty.module.css';

const ContentEmpty = ({ upload, release, composition }) => {
	const { pathname } = useLocation();
	const {
		isReleaseCreateDisabled,
		isCompositionCreateDisabled,
		isExpiredContract,
	} = useContext(UIContext);
	const {
		isAddReleaseEnableForAccount,
		isAddCompositionEnableForAccount,
	} = useContext(AuthContext);

	const clickToCreateRelease = () => {
		localStorage.removeItem('releaseId');
	};

	const clickToCreateComposition = () => {
		localStorage.removeItem('compositionId');
	};

	return (
		<div className={styles.repertoire__mainContentEmpty}>
			{upload && upload.drafts && (
				<>
					<Cd width={64} />
					<span>
						{process.env.REACT_APP_CLIENT === 'dgtal' ? (
							<FormattedMessage
								id={
									((isCompositionCreateDisabled &&
									!isAddCompositionEnableForAccount) || isExpiredContract)
										? 'rod.no_data'
										: 'rod.composition.empty'
								}
								values={{
									a: (msg) => (
										<Link
											to={{
												pathname: '/compositions/create',
												state: { url: pathname },
											}}
										>
											{msg}
										</Link>
									),
								}}
							/>
						) : (
							<FormattedMessage
								id={
									((isReleaseCreateDisabled && !isAddReleaseEnableForAccount) || isExpiredContract)
										? 'rod.no_data'
										: 'rod.release.empty'
								}
								values={{
									a: (msg) => (
										<Link
											to={{
												pathname: '/release/create',
												state: { url: pathname },
											}}
											onClick={clickToCreateRelease}
										>
											{msg}
										</Link>
									),
								}}
							/>
						)}
					</span>
				</>
			)}

			{release && (
				<>
					<Cd width={64} />
					<span>
						<FormattedMessage
							id={
								((isReleaseCreateDisabled && !isAddReleaseEnableForAccount) || isExpiredContract)
									? 'rod.no_data'
									: 'rod.release.empty'
							}
							values={{
								a: (msg) => (
									<Link
										to={{
											pathname: '/release/create',
											state: { url: pathname },
										}}
										onClick={clickToCreateRelease}
									>
										{msg}
									</Link>
								),
							}}
						/>
					</span>
				</>
			)}

			{composition && (
				<>
					<Nota width={64} />
					<span>
						<FormattedMessage
							id={
								((isCompositionCreateDisabled && !isAddCompositionEnableForAccount) || isExpiredContract)
									? 'rod.no_data'
									: 'rod.composition.empty'
							}
							values={{
								a: (msg) => (
									<Link
										to={{
											pathname: '/compositions/create',
											state: { url: pathname },
										}}
										onClick={clickToCreateComposition}
									>
										{msg}
									</Link>
								),
							}}
						/>
					</span>
				</>
			)}
		</div>
	);
};

export default ContentEmpty;
