export const dropZoneTitle = (lang, releaseTypeId) => {
	if (lang === 'ru') {
		switch (releaseTypeId) {
			case 64:
				return 'Для данного типа релиза должно быть не менее 2-х и не более 7 треков';
			case 69:
			case 2:
				return 'Для данного типа релиза должно быть не менее 2-х и не более 40 треков';
			default:
				return 'Для данного типа релиза доступен только один трек. При необходимости, измените тип релиза';
		}
	} else {
		switch (releaseTypeId) {
			case 64:
				return 'For this type of release there must be at least 2 and no more than 7 tracks';
			case 69:
			case 2:
				return 'For this type of release there must be at least 2 and no more than 40 tracks';
			default:
				return 'Only one track is available for this type of release. If necessary, change the release type';
		}
	}
};
