// Core
import React, { useContext } from 'react';
import { Col } from 'react-grid-system';
import { FormattedMessage } from 'react-intl';

// Context
import { LangContext } from 'contexts/LangContext';

// Data
import { filesRu, filesEn } from '../utils/filesRu';

// UI
import { Label } from 'components/Labels/Label';

// Styles
import styles from '../Dashboard.module.css';

const Help = () => {
	const { lang } = useContext(LangContext);
	const files = lang === 'ru' ? filesRu : filesEn;

	return (
		<Col sm={5} className={styles.Right}>
			<div>
				<Label
					className={styles.Header}
					font="--gilroy-Medium-24"
					text={
						<FormattedMessage id={'rod.repertoire.welcome.download.catalog'} />
					}
					black
				/>
				<div className={styles.Example}>
					{files.map((file) => (
						<div key={file.title} className={styles.File}>
							<a
								href={`/examples/${file.filename}`}
								target="_blank"
								rel="noreferrer"
							>
								<img src={file.icon} alt="" /> <span>{file.title}</span>
							</a>
						</div>
					))}
				</div>
			</div>
		</Col>
	);
};

export default Help;
