/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 25/05/2020, 20:02
 */

import { CloseButton } from 'components/Buttons';
import { Button } from 'components/Buttons';
// import { Checkbox } from 'components/Checkboxes';
// import { Lang } from 'components';
import { Col, Container, Row } from 'components/Grid';

import { compose } from 'recompose';
import { withLang, withRoot, withUI } from 'hocs';

import React, { Component } from 'react';
import posed, { PoseGroup } from 'react-pose';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import BaseModal from './BaseModal';
import styles from './Modal.module.css';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import { InputRubric } from 'material-design/components';
import { FaqAdminLang } from 'material-design/components/FaqAdminLang';

const Modal = posed.div({
	enter: {
		x: 0,
		opacity: 1,
		// delay: 200,
		transition: {
			x: {
				ease: 'easeIn',
			},
			default: { duration: 300 },
		},
	},
	exit: {
		x: '100%',
		opacity: 0,
		transition: { duration: 250 },
	},
});

const Shade = posed.div({
	enter: { opacity: 0.5 },
	exit: { opacity: 0 },
});

class NotifyAdminReadModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isVisible: false,
			isActive: true,
			rubricObj: [
				{
					language: 'ru',
					title:
						this.props.rubric && this.props.lang === 'ru'
							? this.props.rubric.title
							: '',
				},
				{
					language: 'en',
					title:
						this.props.rubric && this.props.lang === 'en'
							? this.props.rubric.title
							: '',
				},
			],
			articleLang: this.props.lang,
			errors: {},
		};
	}

	componentDidMount() {
		const translateLang = this.props.lang === 'ru' ? 'en' : 'ru';
		if (this.props.rubric) {
			this.props
				.getAdminArticlesSectionsTranslate(
					this.props.rubric.slug,
					translateLang
				)
				.then((res) => {
					if (this.props.lang === 'ru') {
						// this.state.rubricObj[0] = this.props.rubric;
						// this.state.rubricObj[1] = res.data[0];

						this.setState({ rubricObj: [this.props.rubric, res.data[0]] });
					}
					if (this.props.lang === 'en') {
						// this.state.rubricObj[1] = this.props.rubric;
						// this.state.rubricObj[0] = res.data[0];
						this.setState({ rubricObj: [res.data[0], this.props.rubric] });
					}
				});

			this.setState({ rubricObj: this.state.rubricObj });
		}

		this.setState({
			isVisible: true,
			onCloseFn: this.props.onClose,
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {}

	willUnmount(e) {
		this.setState({
			isVisible: false,
		});
		setTimeout(() => {
			this.state.onCloseFn();
		}, 300);
		this.props.getRubrics();
	}

	htmlParsing = (text) => {
		return parse(
			DOMPurify.sanitize(text, {
				ADD_ATTR: ['target'],
			})
		);
	};

	handleOnChange = (e) => {
		this.setState({ errors: {} });

		if (this.state.articleLang === 'ru') {
			// this.state.rubricObj[0].title = e;
			// this.setState({ rubricObj: this.state.rubricObj });
			this.setState((state, props) => ({
				rubricObj: [
					{ ...state.rubricObj[0], title: e },
					{ ...state.rubricObj[1] },
				],
			}));
		} else if (this.state.articleLang === 'en') {
			// this.state.rubricObj[1].title = e;
			// this.setState({ rubricObj: this.state.rubricObj });
			this.setState((state, props) => ({
				rubricObj: [
					{ ...state.rubricObj[0] },
					{ ...state.rubricObj[1], title: e },
				],
			}));
		}
	};

	langChange = () => {
		this.state.articleLang === 'en'
			? this.setState({
					articleLang: 'ru',
			  })
			: this.setState({
					articleLang: 'en',
			  });
	};

	confirm = () => {
		if (this.state.articleLang === 'ru' && !this.state.rubricObj[0].title)
			return this.setState({ errors: { input: [{ rule: 'required' }] } });
		if (this.state.articleLang === 'en' && !this.state.rubricObj[1].title)
			return this.setState({ errors: { input: [{ rule: 'required' }] } });
		if (
			(this.state.rubricObj[0].title && !this.state.rubricObj[1].title) ||
			(!this.state.rubricObj[0].title && this.state.rubricObj[1].title)
		) {
			return this.setState({ errors: { both_lang: [{ rule: 'required' }] } });
		}
		this.setState({ isActive: false });

		this.props.rubric ? this.updateRubric() : this.sendRubric();
	};

	sendRubric = async () => {
		if (!Object.keys(this.state.errors).length) {
			await this.props
				.addAdminArticlesRubric(this.state.rubricObj)
				.then((res) => {
					// setIsNewRubric(false);
					this.willUnmount();
					this.props.getRubrics();
				})
				.catch((err) => console.info('Error', err))
				.finally(() => {
					// setIsSavingProcess(false);
				});
		}
	};

	// sectionId, lang, title

	updateRubric = async () => {
		const { updateAdminArticlesRubric } = this.props;
		if (!Object.keys(this.state.errors).length) {
			const ruVersion = [
				{
					title: this.state.rubricObj[0].title,
					language: this.state.rubricObj[0].language,
				},
			];
			const enVersion = [
				{
					title: this.state.rubricObj[1].title,
					language: this.state.rubricObj[1].language,
				},
			];

			await Promise.all([
				updateAdminArticlesRubric(this.state.rubricObj[0].id, ruVersion),
				updateAdminArticlesRubric(this.state.rubricObj[1].id, enVersion),
			])
				.then((res) => {
					this.willUnmount();
				})
				.catch((err) => console.info('Error', err));
		}
	};

	render() {
		return (
			<BaseModal className={styles.Modal}>
				<PoseGroup>
					{this.state.isVisible && [
						<Shade key="shade" className={styles.Overlay} />,

						<Modal
							key="modal"
							style={{
								marginLeft: 'auto',
							}}
						>
							<Container fluid>
								<Row>
									<Col
										md={12 - 4}
										onClick={(e) => {
											this.willUnmount(e);
										}}
									/>
									<Col md={4} className={styles.notify}>
										<div className={styles.notify__wrapper}>
											<div className={styles.rubric_modal_wrapper}>
												<div className={styles.notify__title}>
													<ul className={styles.Tray}>
														<li>
															<div className={styles.readAdminModalTitle}>
																{this.props.rubric ? (
																	<FormattedHTMLMessage
																		id={'rod.admin.articles.rubric_edit'}
																	/>
																) : (
																	<FormattedHTMLMessage
																		id={'rod.admin.articles.rubric_create'}
																	/>
																)}
															</div>
														</li>
													</ul>
												</div>
												<CloseButton
													black
													small
													className={styles.CloseButtonNotify}
													onClick={(e) => {
														this.willUnmount(e);
													}}
												/>
											</div>
											<div>
												<ul>
													<li className={styles.rubricAdminModalListItem}>
														<div
															className={styles.readAdminRubricLang}
															onClick={this.langChange}
														>
															<FaqAdminLang lang={this.state.articleLang} />
														</div>
													</li>

													<li className={styles.rubricAdminModalListItem}>
														<div
															className={
																styles.readAdminModalRubricTitleWrapper
															}
														>
															<InputRubric
																handleOnChange={(e) => this.handleOnChange(e)}
																value={
																	this.state.articleLang === 'ru'
																		? this.state.rubricObj[0].title
																		: this.state.rubricObj[1].title
																}
															/>
															{Object.keys(this.state.errors).includes(
																'input'
															) && (
																<span className={styles.Helper}>
																	{
																		<FormattedMessage
																			id={`rod.error.${this.state.errors.input[0].rule}`}
																		/>
																	}
																</span>
															)}
														</div>
													</li>
												</ul>
											</div>
											<div className={styles.btnConfirmRubric}>
												<Button
													text={
														<FormattedHTMLMessage id={'rod.action.confirm'} />
													}
													className={
														this.state.isActive
															? styles.activeBtn
															: styles.inactiveBtn
													}
													variant={'primary'}
													// disabled={!this.state.confirmed}
													onClick={this.state.isActive && this.confirm}
												/>
												{Object.keys(this.state.errors).includes(
													'both_lang'
												) && (
													<span className={styles.Helper_rubric}>
														{
															<FormattedMessage
																id={`rod.error.both_lang.${this.state.errors.both_lang[0].rule}`}
															/>
														}
													</span>
												)}
											</div>
										</div>
									</Col>
								</Row>
							</Container>
						</Modal>,
					]}
				</PoseGroup>
			</BaseModal>
		);
	}
}

export default compose(withLang, withUI, withRoot)(NotifyAdminReadModal);
