export const isKnownStatus = (step) => {
	const knownStatuses = [
		'moderation',
		'completed',
		'parsed',
		'check',
		'update',
	];

	return knownStatuses.includes(step);
};
