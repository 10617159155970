/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

import { Button } from 'components/Buttons/Button';
import { Label } from 'components/Labels/Label';
import { Select } from 'components/Select/Select';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Row } from 'react-grid-system';
import styles from './TerritorySelect.module.css';

class TerritorySelect extends Component {
	static propTypes = {
		onCancel: PropTypes.func,
		onSave: PropTypes.func,
		territories: PropTypes.array,
		selected: PropTypes.array,
	};

	// constructor(props) {
	// 	super(props);
	// 	// props.selected.forEach(sel => {
	// 	// 	this.state.selected[sel] = sel;
	// 	// });
	// 	// this.state.territories = props.territories;
	// }

	state = {
		selected: this.selectedPrepare(),
		territories: this.props.territories,
	};

	selectedPrepare() {
		let result = {};
		this.props.selected.forEach((sel) => {
			result[sel] = sel;
		});

		return JSON.parse(JSON.stringify(result));
	}

	closeHandler = () => {
		if (typeof this.props.onCancel === 'function') {
			this.props.onCancel();
		}
	};

	saveHandler = () => {
		if (typeof this.props.onSave === 'function') {
			this.props.onSave(Object.keys(this.state.selected));
		}
	};

	selectHandler = (territory) => {
		const newState = update(this.state, {
			selected: { [territory.value]: { $set: territory } },
		});
		this.setState(newState);
	};

	removeHandler = (value) => () => {
		const newState = update(this.state, { selected: { $unset: [value] } });
		this.setState(newState);
	};

	render() {
		const selected = this.state.territories.filter((ter) =>
			this.state.selected.hasOwnProperty(ter.value)
		);
		return (
			<React.Fragment>
				<Label variant={'text-30-140'} black className={styles.Label}>
					Выберите страны/территории, на которых вы не хотите передавать права
				</Label>
				<Row>
					<Col md={6}>
						<Select
							placeholder={'Выберите страну'}
							options={this.state.territories}
							onChange={this.selectHandler}
						/>
					</Col>
					<Col md={4} offset={{ md: 2 }}>
						<div className={styles.SelectedContainer}>
							{selected.map((ter) => this.renderSelectedTerritory(ter))}
						</div>
					</Col>
				</Row>
				<Row>
					<Col md={12}>
						<div className={styles.Controls}>
							<Button
								text={'Готово'}
								variant={'primary'}
								onClick={this.saveHandler}
							/>
							<Button
								text={'Отмена'}
								variant={'link'}
								onClick={this.closeHandler}
							/>
						</div>
					</Col>
				</Row>
			</React.Fragment>
		);
	}

	renderSelectedTerritory(ter) {
		return (
			<div className={styles.Selected}>
				{ter.label}
				<button onClick={this.removeHandler(ter.value)}>X</button>
			</div>
		);
	}
}

export default TerritorySelect;
