import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Input, SelectCustom } from 'material-design/MuiStyled/MuiStyled';

const MultipleSelectPlaceholder = ({
	onChange,
	minWidth,
	label,
	items,
	className,
	value,
	required,
}) => {
	// const [genre, setGenre] = React.useState([]);
	const lang = localStorage.getItem('lang');

	if (lang === 'ru') {
		items = items.sort((a, b) => (a.title_ru > b.title_ru ? 1 : -1));
	} else {
		items = items.sort((a, b) => (a.title_en > b.title_en ? 1 : -1));
	}

	return (
		<FormControl
			variant="standard"
			className={className}
			sx={{ m: 0, minWidth: { minWidth } }}
			required={required}
		>
			<Input id="demo-simple-select-standard-label">{label}</Input>

			<SelectCustom
				multiple
				labelId="demo-simple-select-standard-label"
				id="demo-simple-select-standard"
				onChange={onChange}
				label=""
				value={Array.isArray(value) ? value : []}
			>
				{items.map((item) => (
					<MenuItem key={item.id} value={`${item.code}`}>
						{lang === 'ru' ? item.title_ru : item.title_en}
					</MenuItem>
				))}
			</SelectCustom>
		</FormControl>
	);
};

export default MultipleSelectPlaceholder;
