/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 02/02/2021, 16:15
 */

import { Button } from 'components/Buttons/Button';
import { FilteredDropdown } from 'components/FilteredDropdown';
import { Label } from 'components/Labels/Label';
import { DropZone } from 'components/Upload/DropZone';
import accountCompletionSteps from 'constants/accountCompletionSteps';
import { withAuth, withRoot, withUI } from 'hocs';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import http from 'services/http';
import styles from './QAPage.module.css';

function QaPage(props) {
	const {
		UIContext: { showBackNavi, hideBackNavi },
		authContext: { user },
	} = props;

	const [account, setAccount] = useState(
		props.match.params.id || user.accounts[0].id
	);
	const [step, setStep] = useState();
	const [contractType, setContractType] = useState('license');
	const [file, setFile] = useState();

	useEffect(() => {
		showBackNavi();
		handleAccountChange(account);

		return hideBackNavi;
		// eslint-disable-next-line
	}, [account]);

	const handleStepChange = (step) => {
		setStep(step);
		http
			.post(`/qa/account/${account}/step`, { completion_step: step })
			.then((res) => {
				return Promise.resolve(res);
			})
			.catch((err) => {
				console.error(
					'QA Page - error.response - post qa/account/step request: ',
					err.response
				);
				console.error('QA Page - error - post qa/account/step request: ', err);
			});
	};

	const handleContractTypeChange = (type) => {
		setContractType(type);
	};

	const handleResetContractClick = (step) => {
		setStep(step);
		http
			.delete(`/qa/accounts/${account}/contracts/license/accept`)
			.then((res) => {
				return Promise.resolve(res);
			});
	};

	const handleDeleteAccount = () => {
		const {
			authContext: { getUser },
		} = props;
		http.delete(`/qa/account/${account}`).then((res) => {
			getUser();
		});
	};

	const handleAccountChange = (id) => {
		try {
			const acc = user.accounts.find((a) => +a.id === +id);
			props.history.push(`/accounts/${id}/iddqd`);
			setStep(acc.completion_step);
		} catch (err) {
			console.error(
				'QA Page - error acc.completion_step after find funct. ',
				err
			);
		}
	};

	const handleContractRemove = () => {
		setFile();
	};

	const handleDeleteAllAccounts = () => {
		const {
			authContext: { user },
		} = props;

		user.accounts.forEach((acc) => {
			http.delete(`/qa/account/${acc.id}`).then((res) => {
				console.info('acc removed', acc.id);
			});
		});
	};

	const handleContractUpload = (files) => {
		return new Promise((resolve, reject) => {
			const fd = new FormData();
			fd.append('file', files[0]);
			http
				.post(`/qa/account/${account}/contract/${contractType}`, fd)
				.then((res) => {
					setFile(res.result);
					resolve(res.result);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};
	return (
		<div className={styles.Page}>
			<FilteredDropdown
				value={account}
				defaultEmpty
				onChange={setAccount}
				placeholder={'Account'}
				items={user.accounts}
				getItem={(item) => {
					if (item) {
						return item.title || `Account #${item.id}`;
					}
				}}
				valueToString={(item) => {
					return item ? item.title || `Account #${item.id}` : null;
				}}
				getListItem={(item) => {
					if (item) {
						return item.title || `Account #${item.id}`;
					}
				}}
				getItemValue={(item) => {
					if (item) {
						return item.id;
					}
				}}
			/>

			<FilteredDropdown
				value={step}
				defaultEmpty
				onChange={handleStepChange}
				placeholder={'Completion Step'}
				items={Object.keys(accountCompletionSteps).map((key) => ({
					title: key,
					keyword: accountCompletionSteps[key],
				}))}
				getItem={(item) => {
					if (item) {
						return item.title;
					}
				}}
				valueToString={(item) => {
					return item ? item.title : null;
				}}
				getListItem={(item) => {
					if (item) {
						return item.title;
					}
				}}
				getItemValue={(item) => {
					if (item) {
						return item.keyword;
					}
				}}
			/>
			<Button text={'Reset Documents'} primary disabled />
			<Button
				text={'Reset Contracts'}
				primary
				onClick={handleResetContractClick}
			/>
			<Button text={'Reset Account Data'} primary disabled />
			<Button
				text={'Delete All Accounts'}
				variant={'warn'}
				onClick={handleDeleteAllAccounts}
			/>
			<Button
				text={'Delete current account'}
				variant={'warn'}
				onClick={handleDeleteAccount}
			/>
			<Label font="--gilroy-Medium-24" black>
				Contract render:
			</Label>
			<Label font="--gilroy-Medium-16" black>
				Type:
			</Label>
			<FilteredDropdown
				value={contractType}
				defaultEmpty
				onChange={handleContractTypeChange}
				placeholder={'Contract Type'}
				items={[
					{
						id: 1,
						keyword: 'license',
						title: 'License',
					},
					{
						id: 2,
						keyword: 'publishing',
						title: 'publishing',
					},
				]}
				getItem={(item) => {
					if (item) {
						return item.title;
					}
				}}
				valueToString={(item) => {
					return item ? item.title : null;
				}}
				getListItem={(item) => {
					if (item) {
						return item.title;
					}
				}}
				getItemValue={(item) => {
					if (item) {
						return item.keyword;
					}
				}}
			/>
			<Label font="--gilroy-Medium-16" black>
				Template:
			</Label>
			<DropZone
				value={file}
				showUrl
				onUpload={handleContractUpload}
				onRemove={handleContractRemove}
				accept={'application/x-httpd-php'}
			/>
		</div>
	);
}

export default compose(withRoot, withAuth, withUI, withRouter)(QaPage);
