// Core
import React from 'react';

// UI
import { EndConfirmationPage } from 'components/EndConfirmationPage';

const CompositionEnd = () => {
	return (
		<EndConfirmationPage
			title={'rod.composition.create.step.end.title'}
			text={'rod.composition.create.step.end.text'}
			textLinks={{
				link: {
					link: '/repertoire/compositions',
					phrase: 'rod.composition.final.step.link.text',
				},
			}}
			btnText={'rod.action.to_index_page'}
			btnLink={`/`}
		/>
	);
};

export default CompositionEnd;
