// Core
import React, { useEffect, useState } from 'react';
import { PoseGroup } from 'react-pose';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { startOfYear } from 'date-fns';

// Utils
import { compose } from 'recompose';
import { withLang, withRoot, withUI } from 'hocs';
import debounce from 'lodash.debounce';
import { showError } from 'validators/showError';
import { dateFormatEditor } from 'logic';

// UI
import { Modal } from './modal/Modal';
import { Shade } from './shade/Shade';
import BaseModal from '../BaseModal';
import { Button } from 'components/Buttons';
import { CloseButton } from 'components/Buttons';
import { Col, Container, Row } from 'components/Grid';
import { FormInput } from 'components/Form/FormInput';
import { FaqAdminLang } from 'material-design/components/FaqAdminLang';
import TimePicker from 'components/Inputs/TimePicker/TimePicker';

// Styles
import styles from '../Modal.module.css';

const ReportsCreateDistributionModal = (props) => {
	const [isVisible, setIsVisible] = useState(true);
	const [errors, setErrors] = useState({});
	const [reportLang, setReportLang] = useState('en');
	const [distributionData, setDistributionData] = useState({
		heading: '',
		heading_en: '',
	});

	const [publishingDate, setPublishingDate] = useState({
		publishing_date: '',
	});

	const [enConfirmed, setEnConfirmed] = useState(false);
	const [ruConfirmed, setRuConfirmed] = useState(false);
	const [isShowNotice, setIsShowNotice] = useState(false);
	const [isConfirmedBtnActive, setIsConfirmedBtnActive] = useState(true);
	const [isShowConfirmQuestion, setIsShowConfirmQuestion] = useState(false);
	const [timeInHHMM, setTimeInHHMM] = useState('');
	const [initialTimeInHHMM, setInitialTimeInHHMM] = useState('');
	const [isSelectedTimeValid, setIsSelectedTimeValid] = useState(true);

	const { onClose, itemData, editDistribution, createDistribution } = props;

	useEffect(() => {
		if (itemData && editDistribution) {
			const {
				heading_en,
				heading,
				date_publication,
				time_publication,
			} = itemData;
			setDistributionData((prev) => ({
				...prev,
				heading_en,
				heading,
			}));
			setPublishingDate({
				publishing_date: dateFormatEditor(date_publication, 'ymd', '-'),
			});
			setInitialTimeInHHMM(time_publication);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (distributionData.heading_en && publishingDate.publishing_date) {
			setEnConfirmed(true);
		} else setEnConfirmed(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [distributionData.heading_en, publishingDate]);

	useEffect(() => {
		if (distributionData.heading && publishingDate.publishing_date) {
			setRuConfirmed(true);
		} else setRuConfirmed(false);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [distributionData.heading, publishingDate]);

	const willUnmount = (withData = true) => {
		setIsVisible(false);
		setTimeout(() => {
			onClose();
		}, 300);
	};

	const titleChange = (e) => {
		setErrors({});
		if (e.target.value.length > 85) return;
		if (reportLang === 'en') {
			setDistributionData((prev) => ({ ...prev, heading_en: e.target.value }));
		} else {
			setDistributionData((prev) => ({ ...prev, heading: e.target.value }));
		}
	};

	const preConfirmData = () => {
		if (!enConfirmed || !ruConfirmed) {
			setReportLang(enConfirmed ? 'ru' : 'en');
			setIsShowNotice(true);
		} else {
			setIsShowNotice(false);
			setIsShowConfirmQuestion(true);
		}
	};

	const debouncedSendData = debounce(() => prepareToSendData(), 500);

	const prepareToSendData = () => {
		sendArchive();
	};

	const sendArchive = () => {
		const ISOtime = new Date(
			publishingDate.publishing_date.replace(
				/(\d{1,2})\.(\d{1,2})\.(\d{1,4}).*/g,
				'$3/$2/$1'
			) +
				',' +
				(timeInHHMM ? timeInHHMM : '00:00') +
				':00'
		).toISOString();

		if (editDistribution) {
			editDistribution(itemData.id, {
				...distributionData,
				date_publication: ISOtime,
			});
		} else createDistribution(distributionData, ISOtime);

		willUnmount();
	};

	const langChange = () => {
		setReportLang((prev) => (prev === 'en' ? 'ru' : 'en'));
	};

	useEffect(() => {
		if (!publishingDate['publishing_date']) return;
		setErrors((prev) => {
			const err = { ...prev };
			delete err.publishing_date;
			return { ...err };
		});
	}, [timeInHHMM]);

	const changeField = (field) => (e) => {
		if (!e) {
			setPublishingDate({ publishing_date: '' });
		}

		if (errors.publishing_date) {
			setErrors((prev) => {
				const err = { ...prev };
				delete err.publishing_date;
				return { ...err };
			});
		}

		switch (field) {
			case 'publishing_date':
				if (e.length === 10) {
					const userDate = new Date(e.replace(/-/g, '/'));

					if (!isNaN(userDate)) {
						const firstDayOfCurrentYear = new Date(
							new Date().getFullYear(),
							0,
							1
						);

						if (userDate >= firstDayOfCurrentYear) {
							setPublishingDate({ [field]: e });
						} else {
							setErrors({
								publishing_date: [{ rule: 'date_past' }],
							});
						}
					} else {
						setErrors({
							publishing_date: [{ rule: 'date_format' }],
						});
					}
				}

				break;
			default:
				break;
		}
	};

	// eslint-disable-next-line no-unused-vars
	const handleOnBlurDateInput = (err, value) => {};

	const handleIsSelectedTimeValid = (data) => {
		setIsSelectedTimeValid(data);
	};

	// Calculate the minimum date (first day of the current year)
	const currentDate = new Date();
	const minDate = startOfYear(currentDate);

	return (
		<BaseModal className={styles.Modal}>
			<PoseGroup>
				{isVisible && [
					<Shade key="shade" className={styles.Overlay} />,

					<Modal
						key="modal"
						style={{
							marginLeft: 'auto',
						}}
					>
						<Container fluid>
							<Row>
								<Col onClick={() => willUnmount(false)} />
								<Col width={'1'} className={styles.notify}>
									<div
										className={styles.notify__wrapper}
										style={{ width: '400px' }}
									>
										<div className={styles.notify__top_wrapper}>
											<div className={styles.notify__title}>
												<FormattedHTMLMessage
													id={
														editDistribution
															? 'rod.admin.distribution.edit'
															: 'rod.admin.distribution.create'
													}
												/>
											</div>
											<CloseButton
												black
												small
												className={styles.CloseButtonNotify}
												onClick={() => willUnmount(false)}
											/>
										</div>
										<div className={styles.notify__lang}>
											<ul className={styles.Tray}>
												<li>
													<div className={styles.createArticle__lang}>
														<ul className={styles.Tray}>
															<li
																onClick={langChange}
																style={{ maxWidth: '50px' }}
															>
																<FaqAdminLang
																	lang={reportLang}
																	className={styles.Lang}
																/>
															</li>
														</ul>
													</div>
												</li>
											</ul>
										</div>
										<div className={styles.reports__title}>
											<FormattedMessage id={'rod.admin.distribution.name'}>
												{(placeholder) => (
													<input
														type="muiInput"
														name="reportTitle"
														value={
															reportLang === 'en'
																? distributionData.heading_en
																: distributionData.heading
														}
														placeholder={placeholder}
														disabled={false}
														onChange={titleChange}
													/>
												)}
											</FormattedMessage>
											{Object.keys(errors).includes('heading') && (
												<span className={styles.Helper}>
													{showError('reportTitle')(errors.heading[0])}
												</span>
											)}
										</div>
										<div className={styles.distributionsDatesTimeTitle}>
											<FormattedMessage id={'rod.distribution.date_time'} />
										</div>
										<div className={styles.distributionsDatesWrapper}>
											<div
												className={styles.reportsDistributionDate}
												style={{ marginBottom: '0' }}
											>
												<FormattedMessage
													id={'rod.field.admin.reports.publishing_date'}
												>
													{(placeholder) => (
														<FormInput
															type={'datePicker'}
															placeholder={placeholder}
															name={'publishing_date'}
															onChange={changeField}
															errors={errors}
															data={publishingDate}
															minDate={minDate}
														/>
													)}
												</FormattedMessage>
											</div>
											<div style={{ width: '10px' }}></div>
											<div
												className={styles.reportsDistributionDate}
												style={{
													marginTop: '0',
												}}
											>
												<TimePicker
													setTimeInHHMM={setTimeInHHMM}
													initialTimeInHHMM={initialTimeInHHMM}
													handleIsSelectedTimeValid={handleIsSelectedTimeValid}
												/>
											</div>
										</div>

										{!isShowConfirmQuestion ? (
											<>
												<div className={styles.reports__confirm}>
													{isShowNotice && (
														<div className={styles.notify__confirm_question}>
															<FormattedHTMLMessage
																id={'rod.admin.reports.confirm_one_article'}
															/>
														</div>
													)}
													<Button
														text={
															<FormattedHTMLMessage
																id={'rod.performance_work.save'}
															/>
														}
														className={
															((reportLang === 'en' && enConfirmed) ||
																(reportLang === 'ru' && ruConfirmed)) &&
															!Object.keys(errors).length &&
															publishingDate.publishing_date.length === 10 &&
															isSelectedTimeValid
																? styles.activeReportsBtn
																: styles.inactiveReportsBtn
														}
														variant={'primary'}
														disabled={
															!(
																(reportLang === 'en' && enConfirmed) ||
																(reportLang === 'ru' && ruConfirmed)
															) ||
															Object.keys(errors).length ||
															publishingDate.publishing_date.length !== 10 ||
															!isSelectedTimeValid
														}
														onClick={preConfirmData}
													/>
												</div>
											</>
										) : (
											<div className={styles.notify__confirm_question_wrapper}>
												<div className={styles.notify__confirm_question}>
													<FormattedHTMLMessage
														id={
															enConfirmed && ruConfirmed
																? 'rod.modal.notify.admin.confirm-question'
																: 'rod.faq.confirm_one_article'
														}
													/>
												</div>
												<div className={styles.notify__confirm}>
													<Button
														text={
															<FormattedHTMLMessage
																id={'rod.modal.notify.admin.confirm-yes'}
															/>
														}
														className={styles.activeBtnConfirmQuestion}
														variant={
															isConfirmedBtnActive ? 'primary' : 'disabled'
														}
														onClick={() => {
															if (isConfirmedBtnActive) {
																setIsConfirmedBtnActive(false);
																debouncedSendData();
															}
														}}
													/>
													<Button
														text={
															<FormattedHTMLMessage
																id={'rod.modal.notify.admin.confirm-no'}
															/>
														}
														className={styles.activeBtnConfirmQuestion}
														variant={
															isConfirmedBtnActive ? 'primary' : 'disabled'
														}
														onClick={() => {
															if (isConfirmedBtnActive) {
																setIsShowConfirmQuestion(false);
															}
														}}
													/>
												</div>
											</div>
										)}
									</div>
								</Col>
							</Row>
						</Container>
					</Modal>,
				]}
			</PoseGroup>
		</BaseModal>
	);
};

export default compose(
	withLang,
	withUI,
	withRoot
)(ReportsCreateDistributionModal);
