// Icons
import checkbox_bd from 'images/checkbox_bd.svg';
import checkbox from 'images/checkbox.svg';
import checkbox_bd_dgtal from 'images/checkbox_bd_dgtal.svg';
import checkbox_dgtal from 'images/checkbox_dgtal.svg';
import checkbox_disabled from 'images/checkbox_disabled.svg';

// Styles
import s from './CheckBoxOutlets.module.css';

const CheckBoxOutlets = ({ item, allChecked, getCheckedOutlets, isLocked }) => {
	const handleChecked = () => {
		if (allChecked) return;
		item.checked ? (item.checked = false) : (item.checked = true);
		getCheckedOutlets(item);
	};

	return !isLocked ? (
		<div className={s.table__item} onClick={handleChecked} checked>
			{process.env.REACT_APP_CLIENT === 'dgtal' ? (
				<img
					src={
						!item.checked && !allChecked ? checkbox_bd_dgtal : checkbox_dgtal
					}
					alt=""
				/>
			) : (
				<img
					src={!item.checked && !allChecked ? checkbox_bd : checkbox}
					alt=""
				/>
			)}

			<span>{item.title}</span>
		</div>
	) : (
		<div className={s.table__item_locked} checked>
			<img src={checkbox_disabled} alt="" />
			<span>{item.title}</span>
		</div>
	);
};

export default CheckBoxOutlets;
