import classnames from 'classnames';
import React from 'react';
import styles from './Loading.module.css';
import { useState, useEffect } from 'react';

let Loading = function (props) {
	const [topPosLoader, setTopPosLoader] = useState(0);
	useEffect(() => {
		const data = document
			.getElementById('loader_ancor')
			.getBoundingClientRect();
		const topPos = data ? data.top : 0;
		setTopPosLoader(Math.floor((window.innerHeight - topPos) / 2) - 80);
	}, []);
	const child = () => {
		return (
			<div className={styles.LoadingCube}>
				<div className={classnames(styles.Cube, styles.C1)} />
				<div className={classnames(styles.Cube, styles.C2)} />
				<div className={classnames(styles.Cube, styles.C4)} />
				<div className={classnames(styles.Cube, styles.C3)} />
			</div>
		);
	};

	return (
		<>
			{!props.non_margin ? (
				<div
					className={classnames(styles.LoadingScreen, props.className)}
					id="loader_ancor"
					style={{
						marginTop: `${topPosLoader}px`,
					}}
				>
					{child()}
				</div>
			) : (
				<div
					className={classnames(styles.LoadingScreenNonMargin, props.className)}
					id="loader_ancor"
				>
					{child()}
				</div>
			)}
		</>
	);
};

export default Loading;
