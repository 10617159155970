/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 15/02/2021, 10:53
 */
import { getGlobalId } from 'App';
import classNames from 'classnames';
import { withForwardRef } from 'hocs';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import styles from './Button.module.css';

class Button extends React.Component {
	static propTypes = {
		/**
		 * One or more button variant combinations
		 *
		 * buttons may be one of a variety of visual variants such as:
		 *
		 * `'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark', 'light', 'link'`
		 *
		 * as well as "outline" versions (prefixed by 'outline-*')
		 *
		 * `'outline-primary', 'outline-secondary', 'outline-success', 'outline-danger', 'outline-warning', 'outline-info', 'outline-dark', 'outline-light'`
		 */
		variant: PropTypes.string,

		/** Manually set the visual state of the button to `:active` */
		active: PropTypes.bool,

		/**
		 * Disables the Button, preventing mouse events,
		 * even if the underlying component is an `<a>` element
		 */
		disabled: PropTypes.bool,

		/**
		 * Defines HTML button type attribute.
		 *
		 * @default 'button'
		 */
		type: PropTypes.oneOf(['button', 'reset', 'submit', null]),

		/**
		 * Defines HTML button content.
		 */
		text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	};

	static defaultProps = {
		active: false,
		disabled: false,
		type: 'button',
	};

	static displayName = 'Button';
	P;

	render() {
		const {
			variant,
			primary,
			link,
			upload,
			icon,
			active,
			className,
			type,
			disabled,
			forwardRef,
			children,
			text,
			withFocusRef,
			...props
		} = this.props;
		const classes = classNames(
			className,
			styles['Button'],
			active && 'active',
			disabled && 'disabled',
			link && 'link',
			icon && 'icon',
			primary && 'primary',
			upload && 'upload',
			variant
		);

		if (link) {
			return (
				<Link
					{...props}
					ref={forwardRef}
					className={classes}
					disabled={disabled}
				>
					<span>{children || text || ''}</span>
				</Link>
			);
		} else if (upload) {
			const id = getGlobalId('file');
			return (
				<div className={styles.Upload}>
					<input
						id={id}
						{...props}
						disabled={disabled}
						ref={forwardRef}
						type="file"
					/>
					<label className={classes} htmlFor={id}>
						{children || text || ''}
					</label>
				</div>
			);
		}

		return (
			<button
				{...props}
				ref={forwardRef}
				type={type}
				className={classes}
				disabled={disabled}
				style={disabled ? { cursor: 'unset', pointerEvents: 'none' } : {}}
			>
				<span>{children || text || ''}</span>
			</button>
		);
	}
}

export default compose(withForwardRef)(Button);
