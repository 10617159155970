/*
 *  Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *  * Unauthorized copying of this file, via any medium is strictly prohibited
 *  * Proprietary and confidential
 *  * Written by Leonid Artemev <me@artemev.it>, 6/27/19 2:16 PM
 *
 */

import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { withAuth, withLang } from '../../hocs';
import styles from './Footer.module.css';

class Footer extends Component {
	static propTypes = {};
	static defaultProps = {};
	static displayName = 'Footer';

	render() {
		return (
			<footer className={styles.Footer}>
				<div>Link</div>
				<div>Link</div>
				<div>Link</div>
			</footer>
		);
	}
}

export default compose(
	withAuth,
	withLang,
)(Footer);
