// Core
import React from 'react';

// UI
import {
	TextFieldInput,
	AutocompleteInput,
} from 'material-design/MuiStyled/MuiStyled';
import { Popper } from '@mui/material';
import isItemSelected from 'logic/multiselectHighlight.js';

// Styles
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			'& .MuiAutocomplete-listbox': {
				margin: 0,
				padding: 0,
				'& li': {
					backgroundColor: '#FFF',
					font: 'var(--gilroy-Medium-16-150)',
					padding: '12px  8px',
					'&:hover': {
						backgroundColor: 'var(--color-light-grey) !important',
					},
				},
			},
		},
	})
);

const CustomPopper = function (props) {
	const classes = useStyles();
	return <Popper {...props} className={classes.root} placement="bottom" />;
};

const MuiSelect = ({ onChange, label, items, value, required, errors, disabled }) => {
	items = items.sort((a, b) => (a.code > b.code ? 1 : -1));

	if (value) {
		for (let j = 0; j < items.length; j++) {
			if (value === items[j].id) {
				value = {
					id: items[j].id,
					title: items[j].title,
					code: items[j].code,
					is_compound: items[j].is_compound,
				};
			}
		}
	}

	const filterOptions = (_, state) => {
		const newOptions = [];
		if (state.inputValue) {
			items.forEach((item) => {
				const word = item.title;
				if (
					word.toLowerCase().includes(state.inputValue.toLowerCase()) &&
					word.toLowerCase().indexOf(state.inputValue.toLowerCase()) === 0
				)
					newOptions.push(item);
			});
			return newOptions;
		} else return items;
	};

	return (
		<AutocompleteInput
			id="disable-clearable"
			disabled={disabled}
			disableClearable
			onChange={(_, obj) => onChange(obj)}
			options={items}
			value={value}
			error={errors}
			getOptionLabel={(option) => {
				return option.title || '';
			}}
			isOptionEqualToValue={(option, value) => option.title === value.title}
			renderInput={(params) => (
				<TextFieldInput
					{...params}
					label={label}
					variant="standard"
					required={required}
					error={errors}
				/>
			)}
			renderOption={(option, item) => {
				return (
					<li
						key={item}
						{...option}
						style={{
							backgroundColor: isItemSelected(item, value)
								? 'var(--color-green)'
								: 'var(--color-white)',
							color: isItemSelected(item, value)
								? 'var(--color-white)'
								: 'unset',
						}}
					>
						{item.title}
					</li>
				);
			}}
			PopperComponent={CustomPopper}
			filterOptions={filterOptions}
		/>
	);
};

export default MuiSelect;
