/*
 *  Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *  * Unauthorized copying of this file, via any medium is strictly prohibited
 *  * Proprietary and confidential
 *  * Written by Leonid Artemev <me@artemev.it>, 6/27/19 11:49 AM
 *
 */

import React from 'react';
import getDisplayName from 'recompose/getDisplayName';
import { RootConsumer } from '../contexts/RootContext';

export const withRoot = WrappedComponent => {

	const RootComponent = ((props) => (
		<RootConsumer>
			{state => (
				<WrappedComponent rootContext={state} {...props} />
			)}
		</RootConsumer>
	));

	const ForwardRef = React.forwardRef((props, ref) => (
		<RootComponent {...props} withFocusRef={ref}/>
	));

	ForwardRef.displayName = getDisplayName(WrappedComponent);
	ForwardRef.name = ForwardRef.displayName;
	ForwardRef.defaultProps = WrappedComponent.defaultProps;

	return ForwardRef;
};