// Core
import { FormattedMessage } from 'react-intl';

// UI
import { FullScreenModalConfirm } from 'containers/Modal';

const ModalConfirm = ({ releaseTypeId, closeModal }) => {
	return (
		<FullScreenModalConfirm
			title={
				releaseTypeId === 2 || releaseTypeId === 64 || releaseTypeId === 69 ? (
					<FormattedMessage id={'rod.modal.track.ep.title'} />
				) : (
					<FormattedMessage id={'rod.modal.leave_only_one_track.title'} />
				)
			}
			text={
				<FormattedMessage
					id={
						releaseTypeId === 64
							? 'rod.field.upload.error.track.ep'
							: releaseTypeId === 2
							? 'rod.field.upload.error.track.album'
							: releaseTypeId === 69
							? 'rod.field.upload.error.track.compilation'
							: releaseTypeId === 51
							? 'rod.modal.leave_only_one_track.text.single'
							: releaseTypeId === 43
							? 'rod.modal.leave_only_one_track.text.rt'
							: releaseTypeId === 42
							? 'rod.modal.leave_only_one_track.text.rbt'
							: 'rod.modal.leave_only_one_track.text.tkt'
					}
				/>
			}
			confirmBtnTxt={<FormattedMessage id={'rod.modal.understood'} />}
			onClose={closeModal}
		/>
	);
};

export default ModalConfirm;
