// Core
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

// Icon
import exit from 'images/menu/exit.svg';

// Styles
import styles from './LogoutBtn.module.css';

const LogoutBtn = ({ hide }) => {
	return (
		<div className={styles.item_wrapper} onClick={hide}>
			<div className={styles.item}>
				<Link to={'/logout'} onClick={hide}>
					<div className={styles.flexContainer}>
						<img src={exit} alt="" />
						<span>
							<FormattedMessage id={'rod.menu.action.logout'} />
						</span>
					</div>
				</Link>
			</div>
		</div>
	);
};

export default LogoutBtn;
