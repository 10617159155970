/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/06/2020, 07:11
 */

// import { RegisterNoCode as NoCodeForm } from 'forms/index';

import { withAuth, withRoot, withUI } from 'hocs';
import React, { PureComponent } from 'react';
// import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './SMSNoCode.module.css';
import { Label } from '../../../../components/Labels/Label';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Button } from '../../../../components/Buttons/Button';

class SMSNoCode extends PureComponent {
	static propTypes = {};

	componentDidMount() {
		// this.props.UIContext.showBackNavi();
	}

	componentWillUnmount() {
		// this.props.UIContext.hideBackNavi();
	}

	handleBack = () => {
		if (this.props.authContext.isAuth) {
			this.props.history.push('/logout');
		} else {
			this.props.history.push('/');
		}
	};

	render() {
		// const { authContext: { isAuth, noCode, errors } } = this.props;

		// if (isAuth) {
		// 	return (<Redirect to={'/'}/>);
		// }

		return (
			<div className={styles.Page}>
				<div className={styles.Form}>
					<div className={styles.Title}>
						<Label font="--gilroy-Medium-40" black>
							<FormattedMessage id={'rod.no_code.header'} />
						</Label>
					</div>
					<div className={styles.subtitle}>
						<Label font="--gilroy-Medium-18-140" black>
							<FormattedHTMLMessage id={'rod.no_code.subheader'} />
						</Label>
					</div>

					<div className={styles.Controls}>
						<Button
							className={styles.button}
							text={<FormattedMessage id={'rod.action.to_index_page'} />}
							type={'submit'}
							variant={'primary'}
							onClick={this.handleBack}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default compose(withRoot, withAuth, withUI)(SMSNoCode);
