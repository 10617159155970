// Langs
import pagesEn from 'messages/EN/pages';
import pagesRu from 'messages/RU/pages';

export const filterReleaseSettingsData = [
	{
		id: 1,
		title_ru: 'статус релиза',
		title_en: 'release status',
		label_ru: 'выберете статус',
		label_en: 'select status',
		query: 'status',
		list: [
			{
				id: 1,
				title_ru: pagesRu['rod.draft.release.moderation'],
				title_en: pagesEn['rod.draft.release.moderation'],
				code: 'draft_processing',
				checked: false,
				extra_ru: 'rod.draft.release.moderation.help-text',
				extra_en: 'rod.draft.release.moderation.help-text',
			},
			{
				id: 2,
				title_ru: pagesRu['rod.repertoire.draft_verify'],
				title_en: pagesEn['rod.repertoire.draft_verify'],
				code: 'draft_verify',
				checked: false,
				extra_ru: 'rod.repertoire.draft_verify.help-text',
				extra_en: 'rod.repertoire.draft_verify.help-text',
			},
			{
				id: 12,
				title_ru: pagesRu['rod.repertoire.draft'],
				title_en: pagesEn['rod.repertoire.draft'],
				code: 'draft',
				checked: false,
				extra_ru: 'rod.repertoire.draft.help-text',
				extra_en: 'rod.repertoire.draft.help-text',
			},
			{
				id: 3,
				title_ru: pagesRu['rod.repertoire.not_ready'],
				title_en: pagesEn['rod.repertoire.not_ready'],
				code: 'not_ready',
				checked: false,
				extra_ru: 'rod.repertoire.not_ready.help-text',
				extra_en: 'rod.repertoire.not_ready.help-text',
			},
			{
				id: 4,
				title_ru: pagesRu['rod.release.info.ready_for_delivery'],
				title_en: pagesEn['rod.release.info.ready_for_delivery'],
				code: 'ready',
				checked: false,
				extra_ru: 'rod.release.info.ready_for_delivery.help-text',
				extra_en: 'rod.release.info.ready_for_delivery.help-text',
			},
			{
				id: 5,
				title_ru: pagesRu['rod.repertoire.pending'],
				title_en: pagesEn['rod.repertoire.pending'],
				code: 'pending',
				checked: false,
			},
			{
				id: 6,
				title_ru: pagesRu['rod.repertoire.rejected'],
				title_en: pagesEn['rod.repertoire.rejected'],
				code: 'rejected',
				checked: false,
			},
			{
				id: 7,
				title_ru: pagesRu['rod.repertoire.approved'],
				title_en: pagesEn['rod.repertoire.approved'],
				code: 'approved',
				checked: false,
			},

			{
				id: 8,
				title_ru: pagesRu['rod.repertoire.shipping_queue'],
				title_en: pagesEn['rod.repertoire.shipping_queue'],
				code: 'shipping_queue',
				checked: false,
			},
			{
				id: 9,
				title_ru: pagesRu['rod.repertoire.delivered'],
				title_en: pagesEn['rod.repertoire.delivered'],
				code: 'shipped',
				checked: false,
			},
			{
				id: 10,
				title_ru: pagesRu['rod.repertoire.takedown_queue'],
				title_en: pagesEn['rod.repertoire.takedown_queue'],
				code: 'takedown_queue',
				checked: false,
			},
			{
				id: 11,
				title_ru: pagesRu['rod.repertoire.takendown'],
				title_en: pagesEn['rod.repertoire.takendown'],
				code: 'takendown',
				checked: false,
			},
		],
	},
];
