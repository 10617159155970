// Core
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

// Utils
import changeField from 'material-design/helper/helper';
import { accounts } from 'services';
import cloneDeep from 'lodash/cloneDeep';
import { ckeckErrorsBeforeSave } from './utils/ckeckErrorsBeforeSave';

// UI
import { withUI, withRoot } from 'hocs';
import { compose } from 'recompose';
import AuthorForm from './AuthorForm';
import { BottomNavi, InfoHelper, Loading } from 'components';
import { RepertoireCmoModal } from 'material-design/modals/RepertoireCmoModal';

// Styles
import s from './AuthorsShares.module.css';

function AuthorsShares(props) {
	const [personal, setPersonal] = useState({});
	const [selectPerson, setSelectPerson] = useState({});
	const [errors, setErrors] = useState({});
	const [tmpInputValue, setTmpInputValue] = useState({});
	const [completionStep, setCompletionStep] = useState(false);
	const [disabledNextBtn, setDisabledNextBtn] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [modal, setModal] = useState(false);
	const [disableBtn, setDisableBtn] = useState(false);

	const compositionId = localStorage.getItem('compositionId');

	const {
		UIContext: {
			showModal,
			showBackNavi,
			setContributor,
			hideBackNavi,
			setIsBottomNaviShow,
		},
	} = props;

	const handleOnChange = (index) => (field) => (e) => {
		const selectedData = changeField({
			index,
			field,
			e,
			selectPerson,
			tmpInputValue,
		});

		selectedData.contributors.forEach((item) => {
			if (item.ownership && item.ownership.toString().length > 4) {
				if (item.publisher_share && +item.publisher_share > 0) {
					item.ownership = Number(item.ownership).toFixed(2);
				} else {
					item.ownership = Number(item.ownership).toFixed(1);
				}
			}
			if (item.publisher_share && item.publisher_share.toString().length > 5) {
				item.publisher_share = Number(item.publisher_share).toFixed(2);
			}
		});

		const data = selectedData.contributors.map((item) => {
			if (typeof item.ownership === 'string' && item.ownership.length > 0) {
				item.ownership = Number(item.ownership);
			}
			if (
				typeof item.publisher_share === 'string' &&
				item.publisher_share.length > 0
			) {
				item.publisher_share = Number(item.publisher_share);
			}

			if (item.publisher_share < 0) {
				item.publisher_share = 0.01;
			}
			return item;
		});

		const controlledBySubmitterAuthors = selectedData.contributors.filter(
			(item) => item.controlled_by_submitter
		);

		checkControlledBySubmitter(controlledBySubmitterAuthors);
		personal.contributors = data;
		setSelectPerson({ ...selectedData });
		setPersonal(personal);
	};

	const checkControlledBySubmitter = (controlled) => {
		if (!controlled.length) {
			setErrors({
				controlled_by_submitter: [{ rule: 'required_submitter' }],
			});
		} else {
			setErrors({});
		}
	};

	const handleOnFocus = (index) => (field) => (e) => {
		const value = e.target.value;
		setTmpInputValue({ index, field, value });
	};

	const handleOnBlur = (contributorsIndex) => (field) => (e) => {
		const value = e.target.value;
		if (value === '') {
			selectPerson.contributors[contributorsIndex][field] = tmpInputValue.value;
			personal.contributors = selectPerson.contributors;
			setSelectPerson({ ...selectPerson });
			setPersonal(personal);
		}
		if (tmpInputValue.field === 'publisher_share' && value === '') {
			const contributors = selectPerson.contributors.filter(
				(item, index) => index !== contributorsIndex
			);

			const maxValue = contributors.reduce(
				(acc, item) => acc + item.ownership + +item.publisher_share,
				0
			);

			let data = 100 - maxValue - tmpInputValue.value;
			if (+data < 0) {
				data = 0.1;
			}

			selectPerson.contributors[contributorsIndex].ownership = +data.toFixed(2);

			setSelectPerson({ ...selectPerson });
			setPersonal(personal);
			setTmpInputValue({});
		}
		if (tmpInputValue.field === 'ownership' && value === '') {
			const contributors = selectPerson.contributors.filter(
				(item, index) => index !== contributorsIndex
			);

			const maxValue = contributors.reduce(
				(acc, item) => acc + item.ownership + item.publisher_share,
				0
			);

			const data = 100 - maxValue - tmpInputValue.value;

			selectPerson.contributors[
				contributorsIndex
			].publisher_share = +data.toFixed(2);

			setSelectPerson({ ...selectPerson });
			setPersonal(personal);
			setTmpInputValue({});
		}
	};

	const nextHandler = () => {
		setDisableBtn(true);
		if (typeof props.onChange === 'function') {
			if (isValid(selectPerson.contributors, true)) return;

			const clone = cloneDeep(selectPerson.contributors);

			clone.forEach((item) => {
				if ('id' in item && typeof item.id === 'string') {
					delete item.id;
				}

				if (item.roles.length > 0) {
					item.roles = item.roles.map((role) => role.logo);
				}
			});

			props
				.onChange(clone)
				.then()
				.catch()
				.finally(() => setDisableBtn(false));
		}
	};

	const backHandler = () => {
		if (typeof props.handlePrevStep === 'function') {
			props.handlePrevStep();
		}
	};

	const isValid = (toCheck, showErr = false) => {
		let errorCount = 0;
		const newError = {};
		newError.title = [];
		newError.roles = [];
		newError.ownership = [];
		newError.publisher = [];
		newError.publisher_share = [];
		let sum = 0;

		toCheck.forEach((item, index) => {
			if (!item.title) {
				newError.title.push({ type: 'required', key: index });
				errorCount++;
			}

			if (item.title.length > 190) {
				newError.title.push({ type: 'max_length', key: index, value: 190 });
				errorCount++;
			}

			if (item.title && !item.roles.length) {
				newError.roles.push(index);
				errorCount++;
			}

			if (item.roles.length) {
				const requiredRoles = toCheck.map((item) =>
					item.roles.filter((itemRole) => {
						switch (itemRole.logo ? itemRole.logo : itemRole) {
							case 'A':
								return itemRole;
							case 'C':
								return itemRole;
							case 'CA':
								return itemRole;
							default:
								break;
						}
						return false;
					})
				);

				if (!requiredRoles.flat().length) {
					newError.roles.push('required_roles');
					errorCount++;
				}
			}

			if (item.title && Number(item.ownership) === 0) {
				newError.ownership.push(index);
				errorCount++;
			}

			if (item.publisher_show && !item.publisher) {
				newError.publisher.push({ type: 'required', key: index });
				errorCount++;
			}

			if (item.publisher?.length > 190) {
				newError.publisher.push({ type: 'max_length', key: index, value: 190 });
				errorCount++;
			}

			if (item.publisher_show && Number(item.publisher_share) === 0) {
				newError.publisher_share.push(index);
				errorCount++;
			}

			if (item.publisher_share > 0) {
				sum += +item.publisher_share;
			}

			if (item.ownership > 0) {
				sum += +item.ownership;
			}
		});

		if (!errorCount && sum >= 99.94 && sum <= 100.06) {
			setErrors({});
			return false;
		} else {
			if (showErr) {
				if (sum <= 99.93 || sum >= 100.07) {
					newError.sum = '';
				}
				setErrors(newError);
			}
			return true;
		}
	};

	useEffect(() => {
		setIsBottomNaviShow(true);

		return () => {
			setIsBottomNaviShow(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		accounts
			.getCompositionData(compositionId)
			.then((res) => {
				res = res.data.data;

				setPersonal(res);
				let first = true;

				if (!res?.contributors.length) {
					res.contributors = [
						{
							title: '',
							society_id: null,
							roles: [],
							ownership: 100,
							publisher: '',
							publisher_share: 0,
							controlled_by_submitter: true,
						},
					];
				} else {
					res.contributors.forEach((item) => {
						item.publisher_show =
							item.publisher === null || item.publisher === ''
								? false
								: item.publisher
								? true
								: false;
						item.publisher = item.publisher === null ? '' : item.publisher;
					});
				}

				if (!isValid(res.contributors)) {
					res.done = true;
				} else if (first) {
					first = false;
				}

				setPersonal(res);
				setSelectPerson(res);
				setCompletionStep(true);
			})
			.catch((error) => {
				console.info('Error', error);
			});

		showBackNavi();
		return function cleanup() {
			hideBackNavi();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (personal && personal.contributors && selectPerson) {
			const isDisabledPersonal = isValid(personal.contributors, false);
			const isDisabledPerson = isValid(selectPerson.contributors, false);
			const isDisabled = isDisabledPersonal || isDisabledPerson;

			setDisabledNextBtn(isDisabled);
		}
	}, [
		personal,
		personal.contributors,
		selectPerson,
		selectPerson.contributors,
	]);

	const recordingSave = (withModal = false, type, idx) => {
		if (isValid(selectPerson.contributors, true)) return;
		const clone = cloneDeep(selectPerson.contributors);
		clone.forEach((item) => {
			if (item.roles.length > 0) {
				item.roles = item.roles.map((role) => role.logo);
			}
		});
		setIsLoading(true);

		accounts
			.uploadCompositionContributors(compositionId, {
				contributors: clone,
			})
			.then((res) => {
				if (withModal) {
					setModal(true);
					const { contributors } = selectPerson;
					selectPerson.done = true;
					selectPerson.contributors = res.data.data.map((item) => {
						const existItem = contributors?.find(
							(contributor) => contributor.title === item.title
						);
						if (existItem && existItem.publisher_show) {
							return {
								...item,
								publisher_show: true,
							};
						}
						return item;
					});

					setSelectPerson({
						...selectPerson,
						contributors: res.data.data.map((item) => {
							const existItem = contributors?.find(
								(contributor) => contributor.title === item.title
							);
							if (existItem && existItem.publisher_show) {
								return {
									...item,
									publisher_show: true,
								};
							}
							return item;
						}),
					});

					const currentContributor = res.data.data[idx];
					setContributor({ ...currentContributor, type });
					setModal(true);
					setPersonal(personal);
				} else {
					const { contributors } = selectPerson;

					selectPerson.done = true;
					selectPerson.contributors = res.data.data;
					setSelectPerson({
						...selectPerson,
						contributors: res.data.data.map((item) => {
							const existItem = contributors?.find(
								(contributor) => contributor.title === item.title
							);
							if (existItem && existItem.publisher_show) {
								return {
									...item,
									publisher_show: true,
								};
							}
							return item;
						}),
					});
					setPersonal(personal);
				}
			})
			.catch((error) => {
				console.error('Error', error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<>
			<div className={s.page}>
				{!completionStep ? (
					<Loading className={s.loader} />
				) : (
					<div className={s.main}>
						<h2 className={s.title}>
							<FormattedMessage id={'rod.release.create.step.author.title'} />
						</h2>
						<AuthorForm
							errors={errors}
							handleOnChange={handleOnChange}
							selectPerson={selectPerson}
							setSelectPerson={setSelectPerson}
							showModal={showModal}
							handleOnFocus={handleOnFocus}
							handleOnBlur={handleOnBlur}
							tmpInputValue={tmpInputValue}
							checkControlledBySubmitter={checkControlledBySubmitter}
							setModal={setModal}
							onSave={(type, idx) => {
								recordingSave(true, type, idx);
							}}
							isLoading={isLoading}
						/>
					</div>
				)}
				<InfoHelper text="rod.composition.create.step.authors.helper_body" />
			</div>
			<div className={s.bottom_container}>
				<div className={s.error_container}>
					{Object.keys(errors).length !== 0 && (
						<span className={s.error}>{ckeckErrorsBeforeSave(errors)}</span>
					)}
				</div>
				<BottomNavi
					showPrevBtn
					disabled={disabledNextBtn || disableBtn}
					back={backHandler}
					next={nextHandler}
				/>
			</div>
			{modal && (
				<RepertoireCmoModal
					type="composition"
					active={modal}
					setActive={(val) => setModal(val)}
					selectPersonId={selectPerson.id}
				/>
			)}
		</>
	);
}
export default compose(withUI, withRoot)(AuthorsShares);
