// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Styles
import s from '../TreatiesPage.module.css';

const NewContractBtn = ({
	btnDisabled,
	btnDisabledWithoutMessage,
	handleClickNewContract,
}) => {
	return (
		<div className={s.btn__wrapper}>
			<button
				className={
					btnDisabled || btnDisabledWithoutMessage
						? ` ${s.btn__newContract} ${s.disabled}`
						: s.btn__newContract
				}
				disabled={btnDisabled || btnDisabledWithoutMessage}
				onClick={handleClickNewContract}
			>
				<FormattedMessage id={'rod.treaties_page.sign_additional_contract'} />
			</button>
			{btnDisabled ? (
				<div className={s.btn__message}>
					<FormattedMessage id={'rod.treaties_page.notify'} />
				</div>
			) : (
				''
			)}
		</div>
	);
};

export default NewContractBtn;
