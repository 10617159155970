// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withForwardRef, withLang } from 'hocs';
import moment from 'moment';
import { compose } from 'recompose';
import Calendar from 'react-calendar';
import DateInputIcon from './DateInputIcon/DateInputIcon';

// UI
import { Input } from 'components/Inputs/Input';

// Styles
import styles from './DateInput.module.css';

class DateInput extends PureComponent {
	static propTypes = {
		focus: PropTypes.bool,

		/**
		 * Defines HTML input type attribute.
		 *
		 * @default 'text'
		 */
		type: PropTypes.string,
		error: PropTypes.object,
		onChange: PropTypes.func,
		showRequired: PropTypes.bool,
		lang: PropTypes.string,
	};

	static defaultProps = {
		type: 'text',
		onChange: () => {},
	};

	constructor(props) {
		super(props);
		this.ref = React.createRef();
		this.container = React.createRef();

		this.state = {
			date: props.value || '',
			isOpen: false,
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.value !== this.props.value) {
			this.setState({
				value: this.props.value,
				date: this.props.value,
			});
		}
	}

	componentDidMount() {
		if (this.props.focus) {
			this.ref.current.focus();
		}
	}

	componentWillUnmount() {
		window.removeEventListener('click', this.outsideClick, true);
	}

	outsideClick = (e) => {
		e.preventDefault();
		if (!this.container.current.contains(e.target)) {
			this.setState(
				{
					isOpen: false,
				},
				() => {
					window.removeEventListener('click', this.outsideClick, true);
				}
			);
		}
	};

	handleFocus = () => {
		this.setState(
			{
				isOpen: true,
			},
			() => {
				window.addEventListener('click', this.outsideClick, true);
			}
		);
	};

	handleChange = (value) => {
		if (typeof value === 'object') {
			this.props.onChange(moment(value).format('YYYY-MM-DD'));
			this.setState({ isOpen: false });
			return;
		}

		value = value.replace(/\./g, '');
		if (
			![1, 2, 3, 4, 5, 6, 7, 8, 9, 0].includes(
				+value.slice(value.length - 1)
			) ||
			value.slice(value.length - 1) === ' '
		) {
			return;
		}
		if (value.length > 8) return;

		this.setState(
			{
				date: value,
				isOpen: false,
			},
			() => {
				window.removeEventListener('click', this.outsideClick, true);
			}
		);

		if (value.length === 3 || value.length === 4) {
			value = value.replace(/(\d{1,2})(\d{1,2})/g, '$1.$2');
		} else if (value.length > 4) {
			value = value.replace(/(\d{1,2})(\d{1,2})(\d{1,4})/g, '$1.$2.$3');
		}

		this.setState({
			date: value,
		});

		this.props.onChange(
			value.length === 10
				? value.replace(/(\d{1,2})\.(\d{1,2})\.(\d{1,4})/g, '$3-$2-$1')
				: value
		);
	};

	handleClear = () => {
		this.setState({ date: '' });
		this.props.onChange('');
	};

	getDate = () => {
		const date = moment(this.state.date);
		if (date && date.isValid()) {
			return date.format('L');
		} else {
			return '';
		}
	};

	render() {
		const {
			errors,
			value,
			placeholder,
			helper,
			className,
			forwardRef,
			name,
			showRequired,
			...props
		} = this.props;
		const { date } = this.state;

		const classes = classnames(className, errors && styles.Error, styles.Input);
		if (forwardRef) {
			this.ref = forwardRef;
		}

		return (
			<div className={classes} ref={this.container}>
				<Input
					{...props}
					errors={errors}
					value={
						this.state.date && this.state.date.length === 10
							? this.state.date.replace(
									/(\d{1,4})-(\d{1,2})-(\d{1,2})/g,
									'$3.$2.$1'
							  )
							: this.state.date
					}
					placeholder={`${placeholder}${
						showRequired && props.required ? '*' : ''
					}`}
					onChange={this.handleChange}
					onFocus={this.handleFocus}
					className={styles.dateInput}
				/>

				<DateInputIcon
					date={date}
					errors={errors}
					hideCross={props.hideCross}
					isEdit={props.isEdit}
					handleFocus={this.handleFocus}
					handleClear={this.handleClear}
				/>

				<input type="hidden" ref={this.ref} name={name} value={value} />
				{this.state.isOpen && this.props.minDate ? (
					<Calendar
						locale={this.props.lang}
						onChange={this.handleChange}
						minDate={this.props.minDate}
					/>
				) : (
					this.state.isOpen && (
						<Calendar locale={this.props.lang} onChange={this.handleChange} />
					)
				)}
			</div>
		);
	}
}

export default compose(withForwardRef, withLang)(DateInput);
