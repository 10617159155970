import React from 'react';
import s from './ButtonAdd.module.css';

const ButtonAdd = ({ title, src, disabled = false, className, onClick }) => {
	return (
		<button
			className={className ? className : s.btn}
			disabled={disabled}
			onClick={onClick}
		>
			{src ? <img className={s.img} src={src} alt="" /> : ''}

			<p className={s.text}>{title}</p>
		</button>
	);
};

export default ButtonAdd;
