// Core
import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { withUI, withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';

// Constants
import ModalTypes from 'constants/modalTypes';

// Utils
import { checkForErrors } from './utils/checkForErrors';
import { hasBankDataMessage } from './utils/hasBankDataMessage';
import { formatErrors } from './utils/formatErrors';
import { transformData } from './utils/transformData';
import { capitalizeWords, replaceSpaces } from 'utils';
import { isNeedSpacesReplace } from './utils/isNeedSpacesReplace';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { RootContext } from 'contexts/RootContext';
import { UIContext } from 'contexts/UIContext';

// UI
import DataConfirm from 'material-design/UserReportsWithPayment/DataConfirm/DataConfirm';
import { ReportsPrivateData } from './ReportsPrivateData';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import TabUnstyled from '@mui/base/TabUnstyled';
import { BottomNavi, Loading } from 'components';

// Styles
import { styled } from '@mui/material/styles';
import styles from './ReportsCheck.module.css';

const HeaderButton = styled(TabUnstyled)(({ theme }) => ({
	width: '50%',
	cursor: 'default !important',
	backgroundColor: 'var(--color-white)',
	color: 'var(--color-dark-grey)',
	font: 'var(--gilroy-Medium-16)',
	fontWeight: '700',
	lineHeight: '20px',
	letterSpacing: '1px',
	padding: '20px 24px',
	border: 'none',
	textAlign: 'center',
	margin: 0,
	'&.Mui-selected': {
		'& > *:first-child': {
			textDecoration: 'line-through',
		},
	},
}));

const ReportsCheck = (props) => {
	const { accountId } = useContext(AuthContext);
	const { getReportsPersonalInfo, updateReportsPersonalInfo } = useContext(
		RootContext
	);
	const {
		showModal,
		showTitle,
		hideTitle,
		selectSettingsStep,
		setSelectedSettingsStep,
	} = useContext(UIContext);

	const [loading, setLoading] = useState(false);
	const [transformedData, setTransformedData] = useState({});
	const [selectTab, setSelectTab] = useState('check');
	const [isErrors, setIsErrors] = useState(false);
	const [errors, setErrors] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [personalFromBackFlag, setPersonalFromBackFlag] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [bankFromBackFlag, setBankFromBackFlag] = useState(false);

	const match = useRouteMatch();
	const history = useHistory();

	useEffect(() => {
		setLoading(true);
		getReportsPersonalInfo(accountId)
			.then((res) => {
				const data = res.data.data;
				setTransformedData(transformData(data));
				setIsErrors(checkForErrors(data));
				// Check for errors and format them
				const formattedErrors = formatErrors(data);
				if (Object.keys(formattedErrors).length > 0) {
					setIsErrors(true);
				}
				setErrors(formattedErrors);
			})
			.catch((err) => {
				console.error('personalData get error:', err);
			})
			.finally(() => {
				setLoading(false);
			});

		if (match.path.includes('check')) {
			setSelectTab('check');
			setSelectedSettingsStep('check');
		} else if (match.path.includes('confirmation')) {
			setSelectTab('confirmation');
			setSelectedSettingsStep('confirmation');
		} else {
			setSelectTab('check');
			setSelectedSettingsStep('check');
		}

		showTitle('rod.navbar.settings');
		return function cleanup() {
			hideTitle();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [match.path]);

	useEffect(() => {
		if (personalFromBackFlag && bankFromBackFlag) {
			setLoading(false);
		}
	}, [loading, personalFromBackFlag, bankFromBackFlag]);

	const getLink = (name) => {
		switch (name) {
			case 'check':
				setSelectTab('check');
				setSelectedSettingsStep('check');
				return history.push(`/accounts/${accountId}/reports/check`);
			case 'confirmation':
				setSelectTab('confirmation');
				setSelectedSettingsStep('confirmation');
				return history.push(`/accounts/${accountId}/reports/confirmation`);
			default:
				history.push(`/accounts/${accountId}/reports/check`);
				break;
		}
	};

	const changeField = (field) => (value) => {
		// Clear the error for the specific field
		setErrors((prevErrors) => {
			const newErrors = { ...prevErrors };
			delete newErrors[field];
			return newErrors;
		});

		if (
			field === 'first_name' ||
			field === 'last_name' ||
			field === 'patronymic'
		) {
			setTransformedData((prev) => ({
				...prev,
				[field]: capitalizeWords(value),
			}));
		} else {
			setTransformedData((prev) => ({
				...prev,
				[field]: isNeedSpacesReplace(field) ? replaceSpaces(value) : value,
			}));
		}
	};

	const submitData = async () => {
		try {
			const response = await updateReportsPersonalInfo(
				accountId,
				transformedData
			);
			const resultData = response.data.data;
			setTransformedData(transformData(resultData));
			setErrors({});
			getLink('confirmation');
		} catch (error) {
			console.error(
				'Submit - updateReportsPersonalInfo error.response:',
				error.response
			);
			setIsErrors(true);
			setErrors(error.response?.data?.errors);
			if (hasBankDataMessage(error.response?.data?.errors)) {
				showModal(
					{
						title: (
							<FormattedMessage
								id={'rod.modal.payments.wrong_currency_title'}
							/>
						),
						text: (
							<FormattedMessage id={'rod.modal.payments.wrong_currency_text'} />
						),
						onAction: () => {
							history.push(`/accounts/${accountId}/reports/check`);
						},
						withClose: true,
						confirmBtnTxt: (
							<FormattedMessage
								id={'rod.modal.payments.wrong_currency_action'}
							/>
						),
					},
					ModalTypes.FULL_MODAL
				)();
			}
		}
	};

	return (
		<>
			{loading ? (
				<Loading non_margin={true} />
			) : (
				<>
					<TabsUnstyled
						defaultValue={selectSettingsStep ? selectSettingsStep : selectTab}
						className={styles.settings}
					>
						<TabsListUnstyled className={styles.header}>
							<HeaderButton value={'check'} disabled>
								<FormattedHTMLMessage id={'rod.reports_page.check_data_tab'} />
								{isErrors && <span className={styles.notReady}></span>}
							</HeaderButton>
							<HeaderButton value={'confirmation'} disabled>
								<FormattedHTMLMessage
									id={'rod.reports_page.confirm_data_tab'}
								/>
							</HeaderButton>
						</TabsListUnstyled>
						<div className={styles.main}>
							<TabPanelUnstyled value={'check'}>
								<ReportsPrivateData
									transformedData={transformedData}
									changeField={changeField}
									submitData={submitData}
									accountType={transformedData?.business_type}
									errors={errors}
								/>
							</TabPanelUnstyled>
							<TabPanelUnstyled value={'confirmation'}>
								<DataConfirm />
							</TabPanelUnstyled>
						</div>
					</TabsUnstyled>
					{selectTab === 'check' && (
						<BottomNavi
							value={'check'}
							showPrevBtn
							back={() => history.push(`/accounts/${accountId}/reports`)}
							next={submitData}
						/>
					)}
				</>
			)}
		</>
	);
};

export default compose(withUI, withAuth, withRoot)(ReportsCheck);
