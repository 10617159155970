export const listOfCountries = [
	{
		type: 'Polygon',
		arcs: [[0, 1, 2, 3, 4, 5]],
		id: 'AF',
		properties: {
			name: 'Afghanistan',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [[[6, 7, 8, 9]], [[10, 11, 12]]],
		id: 'AO',
		properties: {
			name: 'Angola',
		},
	},
	{
		type: 'Polygon',
		arcs: [[13, 14, 15, 16, 17]],
		id: 'AL',
		properties: {
			name: 'Albania',
		},
	},
	{
		type: 'Polygon',
		arcs: [[18, 19, 20, 21, 22]],
		id: 'AE',
		properties: {
			name: 'United Arab Emirates',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [[[23, 24]], [[25, 26, 27, 28, 29, 30]]],
		id: 'AR',
		properties: {
			name: 'Argentina',
		},
	},
	{
		type: 'Polygon',
		arcs: [[31, 32, 33, 34, 35]],
		id: 'AM',
		properties: {
			name: 'Armenia',
		},
	},
	{
		type: 'Polygon',
		arcs: [[36]],
		id: 'TF',
		properties: {
			name: 'French Southern Territories',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [[[37]], [[38]]],
		id: 'AU',
		properties: {
			name: 'Australia',
		},
	},
	{
		type: 'Polygon',
		arcs: [[39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49]],
		id: 'AT',
		properties: {
			name: 'Austria',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [[[50, -35]], [[51, 52, -33, 53, 54]]],
		id: 'AZ',
		properties: {
			name: 'Azerbaijan',
		},
	},
	{
		type: 'Polygon',
		arcs: [[55, 56, 57, 58]],
		id: 'BI',
		properties: {
			name: 'Burundi',
		},
	},
	{
		type: 'Polygon',
		arcs: [[59, 60, 61, 62, 63]],
		id: 'BE',
		properties: {
			name: 'Belgium',
		},
	},
	{
		type: 'Polygon',
		arcs: [[64, 65, 66, 67, 68]],
		id: 'BJ',
		properties: {
			name: 'Benin',
		},
	},
	{
		type: 'Polygon',
		arcs: [[69, 70, 71, -67, 72, 73]],
		id: 'BF',
		properties: {
			name: 'Burkina Faso',
		},
	},
	{
		type: 'Polygon',
		arcs: [[74, 75, 76]],
		id: 'BD',
		properties: {
			name: 'Bangladesh',
		},
	},
	{
		type: 'Polygon',
		arcs: [[77, 78, 79, 80, 81, 82]],
		id: 'BG',
		properties: {
			name: 'Bulgaria',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [[[83]], [[84]], [[85]]],
		id: 'BS',
		properties: {
			name: 'Bahamas',
		},
	},
	{
		type: 'Polygon',
		arcs: [[86, 87, 88, 89]],
		id: 'BA',
		properties: {
			name: 'Bosnia and Herzegovina',
		},
	},
	{
		type: 'Polygon',
		arcs: [[90, 91, 92, 93, 94, 95, 96, 97]],
		id: 'BY',
		properties: {
			name: 'Belarus',
		},
	},
	{
		type: 'Polygon',
		arcs: [[98, 99, 100]],
		id: 'BZ',
		properties: {
			name: 'Belize',
		},
	},
	{
		type: 'Polygon',
		arcs: [[101, 102, 103, 104, -31]],
		id: 'BO',
		properties: {
			name: 'Bolivia',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-27, 105, -104, 106, 107, 108, 109, 110, 111, 112, 113]],
		id: 'BR',
		properties: {
			name: 'Brazil',
		},
	},
	{
		type: 'Polygon',
		arcs: [[114, 115]],
		id: 'BN',
		properties: {
			name: 'Brunei',
		},
	},
	{
		type: 'Polygon',
		arcs: [[116, 117]],
		id: 'BT',
		properties: {
			name: 'Bhutan',
		},
	},
	{
		type: 'Polygon',
		arcs: [[118, 119, 120, 121]],
		id: 'BW',
		properties: {
			name: 'Botswana',
		},
	},
	{
		type: 'Polygon',
		arcs: [[122, 123, 124, 125, 126, 127, 128]],
		id: 'CF',
		properties: {
			name: 'Central African Republic',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [
			[[129]],
			[[130]],
			[[131]],
			[[132]],
			[[133]],
			[[134]],
			[[135]],
			[[136]],
			[[137]],
			[[138]],
			[[139, 140, 141, 142, 143, 144]],
			[[145]],
			[[146]],
			[[147]],
			[[148]],
			[[149]],
			[[150]],
			[[151]],
			[[152]],
			[[153]],
			[[154]],
			[[155]],
			[[156]],
			[[157]],
			[[158]],
			[[159]],
			[[160]],
			[[161]],
			[[162]],
			[[163]],
		],
		id: 'CA',
		properties: {
			name: 'Canada',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-47, 164, 165, 166, -43, 167, 168, 169]],
		id: 'CH',
		properties: {
			name: 'Switzerland',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [[[-24, 170]], [[-30, 171, 172, -102]]],
		id: 'CL',
		properties: {
			name: 'Chile',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [
			[[173]],
			[
				[
					174,
					175,
					176,
					177,
					178,
					179,
					180,
					181,
					182,
					183,
					184,
					185,
					186,
					187,
					-118,
					188,
					189,
					190,
					191,
					-4,
					192,
					193,
					194,
					195,
					196,
					197,
					198,
					199,
					200,
					201,
					202,
				],
			],
		],
		id: 'CN',
		properties: {
			name: 'China',
		},
	},
	{
		type: 'Polygon',
		arcs: [[203, 204, 205, 206, -70, 207]],
		id: 'CI',
		properties: {
			name: "Cote d'Ivoire",
		},
	},
	{
		type: 'Polygon',
		arcs: [[208, 209, 210, 211, 212, 213, 214, -129, 215]],
		id: 'CM',
		properties: {
			name: 'Cameroon',
		},
	},
	{
		type: 'Polygon',
		arcs: [
			[
				216,
				217,
				218,
				219,
				-56,
				220,
				221,
				222,
				-10,
				223,
				-13,
				224,
				-127,
				225,
				226,
			],
		],
		id: 'CD',
		properties: {
			name: 'Democratic Republic of Congo',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-12, 227, 228, -216, -128, -225]],
		id: 'CG',
		properties: {
			name: 'Congo',
		},
	},
	{
		type: 'Polygon',
		arcs: [[229, 230, 231, 232, 233, -108, 234]],
		id: 'CO',
		properties: {
			name: 'Colombia',
		},
	},
	{
		type: 'Polygon',
		arcs: [[235, 236, 237, 238]],
		id: 'CR',
		properties: {
			name: 'Costa Rica',
		},
	},
	{
		type: 'Polygon',
		arcs: [[239]],
		id: 'CU',
		properties: {
			name: 'Cuba',
		},
	},
	{
		type: 'Polygon',
		arcs: [[240]],
		id: 'CY',
		properties: {
			name: 'Cyprus',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-49, 241, 242, 243]],
		id: 'CZ',
		properties: {
			name: 'Czechia',
		},
	},
	{
		type: 'Polygon',
		arcs: [[244, 245, -242, -48, -170, 246, 247, -61, 248, 249, 250]],
		id: 'DE',
		properties: {
			name: 'Germany',
		},
	},
	{
		type: 'Polygon',
		arcs: [[251, 252, 253, 254]],
		id: 'DJ',
		properties: {
			name: 'Djibouti',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [[[255]], [[-251, 256]]],
		id: 'DK',
		properties: {
			name: 'Denmark',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [[[257]]],
		id: 'GL',
		properties: {
			name: 'Greenland',
		},
	},
	{
		type: 'Polygon',
		arcs: [[258, 259]],
		id: 'DO',
		properties: {
			name: 'Dominican Republic',
		},
	},
	{
		type: 'Polygon',
		arcs: [[260, 261, 262, 263, 264, 265, 266, 267]],
		id: 'DZ',
		properties: {
			name: 'Algeria',
		},
	},
	{
		type: 'Polygon',
		arcs: [[268, -230, 269]],
		id: 'EC',
		properties: {
			name: 'Ecuador',
		},
	},
	{
		type: 'Polygon',
		arcs: [[270, 271, 272]],
		id: 'EG',
		properties: {
			name: 'Egypt',
		},
	},
	{
		type: 'Polygon',
		arcs: [[273, 274, 275, 276, 277, 278, 279, -255]],
		id: 'ER',
		properties: {
			name: 'Eritrea',
		},
	},
	{
		type: 'Polygon',
		arcs: [[280, 281, 282, 283]],
		id: 'ES',
		properties: {
			name: 'Spain',
		},
	},
	{
		type: 'Polygon',
		arcs: [[284, 285, 286, 287]],
		id: 'EE',
		properties: {
			name: 'Estonia',
		},
	},
	{
		type: 'Polygon',
		arcs: [
			[288, 289, -274, -254, 290, 291, 292, 293, 294, 295, 296, 297, -277],
		],
		id: 'ET',
		properties: {
			name: 'Ethiopia',
		},
	},
	{
		type: 'Polygon',
		arcs: [[298, 299, 300, 301]],
		id: 'FI',
		properties: {
			name: 'Finland',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [[[302]], [[303]], [[304]]],
		id: 'FJ',
		properties: {
			name: 'Fiji',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [[[305]], [[306, -247, -169, 307, 308, -282, 309, -63]]],
		id: 'FR',
		properties: {
			name: 'France',
		},
	},
	{
		type: 'Polygon',
		arcs: [[310, 311, 312, -112]],
		id: 'GF',
		properties: {
			name: 'French Guiana',
		},
	},
	{
		type: 'Polygon',
		arcs: [[313, 314, -209, -229]],
		id: 'GA',
		properties: {
			name: 'Gabon',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [[[315, 316]], [[317]]],
		id: 'GB',
		properties: {
			name: 'United Kingdom',
		},
	},
	{
		type: 'Polygon',
		arcs: [[318, 319, 320, 321, 322, -54, -32, 323]],
		id: 'GE',
		properties: {
			name: 'Georgia',
		},
	},
	{
		type: 'Polygon',
		arcs: [[324, -208, -74, 325]],
		id: 'GH',
		properties: {
			name: 'Ghana',
		},
	},
	{
		type: 'Polygon',
		arcs: [[326, 327, 328, 329, 330, 331, -206]],
		id: 'GN',
		properties: {
			name: 'Guinea',
		},
	},
	{
		type: 'Polygon',
		arcs: [[332, 333]],
		id: 'GM',
		properties: {
			name: 'Gambia',
		},
	},
	{
		type: 'Polygon',
		arcs: [[334, 335, -330]],
		id: 'GW',
		properties: {
			name: 'Guinea-Bissau',
		},
	},
	{
		type: 'Polygon',
		arcs: [[336, -210, -315]],
		id: 'GQ',
		properties: {
			name: 'Equatorial Guinea',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [[[337]], [[338, -14, 339, -81, 340]]],
		id: 'GR',
		properties: {
			name: 'Greece',
		},
	},
	{
		type: 'Polygon',
		arcs: [[341, 342, -101, 343, 344, 345]],
		id: 'GT',
		properties: {
			name: 'Guatemala',
		},
	},
	{
		type: 'Polygon',
		arcs: [[346, 347, -110, 348]],
		id: 'GY',
		properties: {
			name: 'Guyana',
		},
	},
	{
		type: 'Polygon',
		arcs: [[349, 350, -345, 351, 352]],
		id: 'HN',
		properties: {
			name: 'Honduras',
		},
	},
	{
		type: 'Polygon',
		arcs: [[353, 354, 355, -90, 356, 357, 358]],
		id: 'HR',
		properties: {
			name: 'Croatia',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-260, 359]],
		id: 'HT',
		properties: {
			name: 'Haiti',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-40, 360, 361, 362, 363, 364, -359, 365]],
		id: 'HU',
		properties: {
			name: 'Hungary',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [
			[[366]],
			[[367, 368]],
			[[369]],
			[[370]],
			[[371]],
			[[372]],
			[[373]],
			[[374]],
			[[375, 376]],
			[[377]],
			[[378]],
			[[379, 380]],
			[[381]],
		],
		id: 'ID',
		properties: {
			name: 'Indonesia',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-191, 382, -189, -117, -188, 383, -77, 384, 385]],
		id: 'IN',
		properties: {
			name: 'India',
		},
	},
	{
		type: 'Polygon',
		arcs: [[386, -316]],
		id: 'IE',
		properties: {
			name: 'Ireland',
		},
	},
	{
		type: 'Polygon',
		arcs: [[387, -6, 388, 389, 390, 391, 392, -51, -34, -53, 393]],
		id: 'IR',
		properties: {
			name: 'Iran',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-391, 394, 395, 396, 397, 398, 399, 400]],
		id: 'IQ',
		properties: {
			name: 'Iraq',
		},
	},
	{
		type: 'Polygon',
		arcs: [[401]],
		id: 'IS',
		properties: {
			name: 'Iceland',
		},
	},
	{
		type: 'Polygon',
		arcs: [[402, 403, 404, 405, 406, 407]],
		id: 'IL',
		properties: {
			name: 'Israel',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [[[408]], [[409]], [[410, 411, -308, -168, -42]]],
		id: 'IT',
		properties: {
			name: 'Italy',
		},
	},
	{
		type: 'Polygon',
		arcs: [[412]],
		id: 'JM',
		properties: {
			name: 'Jamaica',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-403, 413, -398, 414, 415, -405, 416]],
		id: 'JO',
		properties: {
			name: 'Jordan',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [[[417]], [[418]], [[419]]],
		id: 'JP',
		properties: {
			name: 'Japan',
		},
	},
	{
		type: 'Polygon',
		arcs: [
			[
				420,
				421,
				422,
				423,
				424,
				425,
				426,
				427,
				428,
				429,
				430,
				431,
				432,
				-195,
				433,
			],
		],
		id: 'KZ',
		properties: {
			name: 'Kazakhstan',
		},
	},
	{
		type: 'Polygon',
		arcs: [[434, 435, 436, 437, -295, 438]],
		id: 'KE',
		properties: {
			name: 'Kenya',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-434, -194, 439, 440]],
		id: 'KG',
		properties: {
			name: 'Kyrgyzstan',
		},
	},
	{
		type: 'Polygon',
		arcs: [[441, 442, 443, 444]],
		id: 'KH',
		properties: {
			name: 'Cambodia',
		},
	},
	{
		type: 'Polygon',
		arcs: [[445, 446]],
		id: 'KR',
		properties: {
			name: 'South Korea',
		},
	},
	{
		type: 'Polygon',
		arcs: [[447, 448, 449, 450, 451]],
		id: 'XXK',
		properties: {
			name: 'Kosovo',
		},
	},
	{
		type: 'Polygon',
		arcs: [[452, 453, -396]],
		id: 'KW',
		properties: {
			name: 'Kuwait',
		},
	},
	{
		type: 'Polygon',
		arcs: [[454, 455, -186, 456, -443]],
		id: 'LA',
		properties: {
			name: 'Laos',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-407, 457, 458]],
		id: 'LB',
		properties: {
			name: 'Lebanon',
		},
	},
	{
		type: 'Polygon',
		arcs: [[459, 460, -327, -205]],
		id: 'LR',
		properties: {
			name: 'Liberia',
		},
	},
	{
		type: 'Polygon',
		arcs: [[461, -268, 462, 463, -272, 464, 465]],
		id: 'LY',
		properties: {
			name: 'Libya',
		},
	},
	{
		type: 'Polygon',
		arcs: [[466]],
		id: 'LK',
		properties: {
			name: 'Sri Lanka',
		},
	},
	{
		type: 'Polygon',
		arcs: [[467]],
		id: 'LS',
		properties: {
			name: 'Lesotho',
		},
	},
	{
		type: 'Polygon',
		arcs: [[468, 469, 470, -91, 471]],
		id: 'LT',
		properties: {
			name: 'Lithuania',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-248, -307, -62]],
		id: 'LU',
		properties: {
			name: 'Luxembourg',
		},
	},
	{
		type: 'Polygon',
		arcs: [[472, -288, 473, -92, -471]],
		id: 'LV',
		properties: {
			name: 'Latvia',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-265, 474, 475]],
		id: 'MA',
		properties: {
			name: 'Morocco',
		},
	},
	{
		type: 'Polygon',
		arcs: [[476, 477]],
		id: 'MD',
		properties: {
			name: 'Moldova',
		},
	},
	{
		type: 'Polygon',
		arcs: [[478]],
		id: 'MG',
		properties: {
			name: 'Madagascar',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-99, -343, 479, 480, 481]],
		id: 'MX',
		properties: {
			name: 'Mexico',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-452, 482, -82, -340, 483]],
		id: 'MK',
		properties: {
			name: 'North Macedonia',
		},
	},
	{
		type: 'Polygon',
		arcs: [[484, -262, 485, -71, -207, -332, 486]],
		id: 'ML',
		properties: {
			name: 'Mali',
		},
	},
	{
		type: 'Polygon',
		arcs: [[487, -75, -384, -187, -456, 488]],
		id: 'MM',
		properties: {
			name: 'Myanmar',
		},
	},
	{
		type: 'Polygon',
		arcs: [[489, -89, 490, -450, -16]],
		id: 'ME',
		properties: {
			name: 'Montenegro',
		},
	},
	{
		type: 'Polygon',
		arcs: [[491, 492, 493, 494, 495, 496, 497, 498, -197]],
		id: 'MN',
		properties: {
			name: 'Mongolia',
		},
	},
	{
		type: 'Polygon',
		arcs: [[499, 500, 501, 502, 503, 504, 505, 506, 507]],
		id: 'MZ',
		properties: {
			name: 'Mozambique',
		},
	},
	{
		type: 'Polygon',
		arcs: [[508, 509, 510, -263, -485]],
		id: 'MR',
		properties: {
			name: 'Mauritania',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-508, 511, 512, 513]],
		id: 'MW',
		properties: {
			name: 'Malawi',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [[[514, 515, 516, 517]], [[-380, 518, -116, 519]]],
		id: 'MY',
		properties: {
			name: 'Malaysia',
		},
	},
	{
		type: 'Polygon',
		arcs: [[520, -8, 521, -120, 522]],
		id: 'NA',
		properties: {
			name: 'Namibia',
		},
	},
	{
		type: 'Polygon',
		arcs: [[523]],
		id: 'NC',
		properties: {
			name: 'New Caledonia',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-72, -486, -261, -462, 524, 525, 526, 527, 528, -213, 529, -68]],
		id: 'NE',
		properties: {
			name: 'Niger',
		},
	},
	{
		type: 'Polygon',
		arcs: [[530, -69, -530, -212]],
		id: 'NG',
		properties: {
			name: 'Nigeria',
		},
	},
	{
		type: 'Polygon',
		arcs: [[531, -353, 532, -237]],
		id: 'NI',
		properties: {
			name: 'Nicaragua',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-249, -60, 533]],
		id: 'NL',
		properties: {
			name: 'Netherlands',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [[[-302, 534, 535]], [[536]], [[537]], [[538]]],
		id: 'NO',
		properties: {
			name: 'Norway',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-383, -190]],
		id: 'NP',
		properties: {
			name: 'Nepal',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [[[539]], [[540]]],
		id: 'NZ',
		properties: {
			name: 'New Zealand',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [[[541, 542, -22, 543]], [[-20, 544]]],
		id: 'OM',
		properties: {
			name: 'Oman',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-192, -386, 545, -389, -5]],
		id: 'PK',
		properties: {
			name: 'Pakistan',
		},
	},
	{
		type: 'Polygon',
		arcs: [[546, -239, 547, -232]],
		id: 'PA',
		properties: {
			name: 'Panama',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-173, 548, -270, -235, -107, -103]],
		id: 'PE',
		properties: {
			name: 'Peru',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [[[549]], [[550]], [[551]], [[552]], [[553]], [[554]], [[555]]],
		id: 'PH',
		properties: {
			name: 'Philippines',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [[[556]], [[557]], [[-376, 558]], [[559]]],
		id: 'PG',
		properties: {
			name: 'Papua New Guinea',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-246, 560, 561, -472, -98, 562, 563, -243]],
		id: 'PL',
		properties: {
			name: 'Poland',
		},
	},
	{
		type: 'Polygon',
		arcs: [[564]],
		id: 'PR',
		properties: {
			name: 'Puerto Rico',
		},
	},
	{
		type: 'Polygon',
		arcs: [[565, -447, 566, -183]],
		id: 'KP',
		properties: {
			name: 'North Korea',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-284, 567]],
		id: 'PT',
		properties: {
			name: 'Portugal',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-105, -106, -26]],
		id: 'PY',
		properties: {
			name: 'Paraguay',
		},
	},
	{
		type: 'Polygon',
		arcs: [[568, 569]],
		id: 'QA',
		properties: {
			name: 'Qatar',
		},
	},
	{
		type: 'Polygon',
		arcs: [[570, -478, 571, 572, -78, 573, -363]],
		id: 'RO',
		properties: {
			name: 'Romania',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [
			[[574]],
			[[-562, 575, -469]],
			[[576]],
			[[577]],
			[[578]],
			[[579]],
			[[580]],
			[[581]],
			[[582]],
			[
				[
					-181,
					583,
					584,
					585,
					-177,
					586,
					-175,
					587,
					-202,
					588,
					-200,
					589,
					-198,
					-499,
					590,
					591,
					-496,
					592,
					-494,
					593,
					-492,
					-196,
					-433,
					594,
					-431,
					595,
					596,
					-428,
					597,
					-426,
					598,
					599,
					600,
					-55,
					601,
					-322,
					602,
					-320,
					603,
					604,
					605,
					606,
					607,
					608,
					609,
					610,
					611,
					-95,
					612,
					-93,
					-474,
					-287,
					613,
					614,
					-299,
					615,
				],
			],
			[[616]],
			[[617]],
			[[618]],
		],
		id: 'RU',
		properties: {
			name: 'Russia',
		},
	},
	{
		type: 'Polygon',
		arcs: [[619, 620, -57, -220, 621]],
		id: 'RW',
		properties: {
			name: 'Rwanda',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-475, -264, -511, 622]],
		id: 'EH',
		properties: {
			name: 'Western Sahara',
		},
	},
	{
		type: 'Polygon',
		arcs: [[623, -415, -397, -454, 624, -570, 625, -23, -543, 626]],
		id: 'SA',
		properties: {
			name: 'Saudi Arabia',
		},
	},
	{
		type: 'Polygon',
		arcs: [[627, 628, -124, 629, -465, -271, 630, -279, 631, -298, 632]],
		id: 'SD',
		properties: {
			name: 'Sudan',
		},
	},
	{
		type: 'Polygon',
		arcs: [[633, -296, -438, 634, 635, -226, -126, 636, -628]],
		id: 'SS',
		properties: {
			name: 'South Sudan',
		},
	},
	{
		type: 'Polygon',
		arcs: [[637, -509, -487, -331, -336, 638, -334]],
		id: 'SN',
		properties: {
			name: 'Senegal',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [[[639]], [[640]], [[641]], [[642]], [[643]]],
		id: 'SB',
		properties: {
			name: 'Solomon Islands',
		},
	},
	{
		type: 'Polygon',
		arcs: [[644, -328, -461]],
		id: 'SL',
		properties: {
			name: 'Sierra Leone',
		},
	},
	{
		type: 'Polygon',
		arcs: [[645, -346, -351]],
		id: 'SV',
		properties: {
			name: 'El Salvador',
		},
	},
	{
		type: 'Polygon',
		arcs: [[646, 647, -291, -253, 648, -439, -294]],
		id: 'SO',
		properties: {
			name: 'Somalia',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-83, -483, -451, -491, -88, 649, -355, 650, -364, -574]],
		id: 'RS',
		properties: {
			name: 'Serbia',
		},
	},
	{
		type: 'Polygon',
		arcs: [[651, -312, 652, -111, -348]],
		id: 'SR',
		properties: {
			name: 'Suriname',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-564, 653, -361, -50, -244]],
		id: 'SK',
		properties: {
			name: 'Slovakia',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-41, -366, -358, 654, -411]],
		id: 'SI',
		properties: {
			name: 'Slovenia',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-535, -301, 655]],
		id: 'SE',
		properties: {
			name: 'Sweden',
		},
	},
	{
		type: 'Polygon',
		arcs: [[656, -504]],
		id: 'SZ',
		properties: {
			name: 'Eswatini',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-414, -408, -459, 657, 658, -399]],
		id: 'SY',
		properties: {
			name: 'Syria',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-529, 659, -527, 660, -525, -466, -630, -123, -215, 661]],
		id: 'TD',
		properties: {
			name: 'Chad',
		},
	},
	{
		type: 'Polygon',
		arcs: [[662, -326, -73, -66]],
		id: 'TG',
		properties: {
			name: 'Togo',
		},
	},
	{
		type: 'Polygon',
		arcs: [[663, -518, 664, -489, -455, -442]],
		id: 'TH',
		properties: {
			name: 'Thailand',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-440, -193, -3, 665]],
		id: 'TJ',
		properties: {
			name: 'Tajikistan',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-388, 666, -422, 667, -1]],
		id: 'TM',
		properties: {
			name: 'Turkmenistan',
		},
	},
	{
		type: 'Polygon',
		arcs: [[668, -368]],
		id: 'TL',
		properties: {
			name: 'Timor',
		},
	},
	{
		type: 'Polygon',
		arcs: [[669]],
		id: 'TT',
		properties: {
			name: 'Trinidad and Tobago',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-267, 670, -463]],
		id: 'TN',
		properties: {
			name: 'Tunisia',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [[[-324, -36, -393, 671, -400, -659, 672]], [[-341, -80, 673]]],
		id: 'TR',
		properties: {
			name: 'Turkey',
		},
	},
	{
		type: 'Polygon',
		arcs: [[674]],
		id: 'TW',
		properties: {
			name: 'Taiwan',
		},
	},
	{
		type: 'Polygon',
		arcs: [
			[-436, 675, 676, -500, 677, 678, 679, 680, -221, -59, 681, -620, 682],
		],
		id: 'TZ',
		properties: {
			name: 'Tanzania',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-622, -219, 683, -217, 684, -635, -437, -683]],
		id: 'UG',
		properties: {
			name: 'Uganda',
		},
	},
	{
		type: 'Polygon',
		arcs: [
			[
				685,
				-611,
				686,
				-609,
				687,
				688,
				689,
				-605,
				690,
				-572,
				-477,
				-571,
				-362,
				-654,
				-563,
				-97,
			],
		],
		id: 'UA',
		properties: {
			name: 'Ukraine',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-114, 691, -28]],
		id: 'UY',
		properties: {
			name: 'Uruguay',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [
			[[692]],
			[[693]],
			[[694]],
			[[695]],
			[[696]],
			[[697, -481, 698, -140]],
			[[699]],
			[[700]],
			[[701]],
			[[-144, 702, -142, 703]],
		],
		id: 'US',
		properties: {
			name: 'United States',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-668, -421, -441, -666, -2]],
		id: 'UZ',
		properties: {
			name: 'Uzbekistan',
		},
	},
	{
		type: 'Polygon',
		arcs: [[704, -349, -109, -234]],
		id: 'VE',
		properties: {
			name: 'Venezuela',
		},
	},
	{
		type: 'Polygon',
		arcs: [[705, -444, -457, -185]],
		id: 'VN',
		properties: {
			name: 'Vietnam',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [[[706]], [[707]]],
		id: 'VU',
		properties: {
			name: 'Vanuatu',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-417, -404]],
		id: 'PSX',
		properties: {
			name: 'West Bank',
		},
	},
	{
		type: 'Polygon',
		arcs: [[708, -627, -542]],
		id: 'YE',
		properties: {
			name: 'Yemen',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-523, -119, 709, -505, -657, -503, 710], [-468]],
		id: 'ZA',
		properties: {
			name: 'South Africa',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-512, -507, 711, -121, -522, -7, -223, 712, -680]],
		id: 'ZM',
		properties: {
			name: 'Zambia',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-710, -122, -712, -506]],
		id: 'ZW',
		properties: {
			name: 'Zimbabwe',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [
			[[713]],
			[[714]],
			[[715]],
			[[716]],
			[[717]],
			[[718]],
			[[719]],
			[[720]],
		],
		id: 'CV',
		properties: {
			name: 'Cape Verde',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [[[721]], [[722]], [[723]]],
		id: 'KM',
		properties: {
			name: 'Comoros',
		},
	},
	{
		type: 'Polygon',
		arcs: [[724]],
		id: 'MU',
		properties: {
			name: 'Mauritius',
		},
	},
	{
		type: 'Polygon',
		arcs: [[725]],
		id: 'SC',
		properties: {
			name: 'Seychelles',
		},
	},
	{
		type: 'Polygon',
		arcs: [[726]],
		id: 'BH',
		properties: {
			name: 'Bahrain',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [[[727]], [[728]]],
		id: 'MV',
		properties: {
			name: 'Maldives',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [[[729]], [[730]]],
		id: 'MH',
		properties: {
			name: 'Marshall Islands',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [[[731]], [[732]], [[733]], [[734]], [[735]]],
		id: 'FM',
		properties: {
			name: 'Micronesia (country)',
		},
	},
	{
		type: 'Polygon',
		arcs: [[736]],
		id: 'NR',
		properties: {
			name: 'Nauru',
		},
	},
	{
		type: 'Polygon',
		arcs: [[737]],
		id: 'PW',
		properties: {
			name: 'Palau',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [[[738]], [[739]]],
		id: 'WS',
		properties: {
			name: 'Samoa',
		},
	},
	{
		type: 'Polygon',
		arcs: [[515, 740]],
		id: 'SG',
		properties: {
			name: 'Singapore',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [[[741]], [[742]], [[743]]],
		id: 'TO',
		properties: {
			name: 'Tonga',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [[[744]], [[745]], [[746]], [[747]], [[748]], [[749]], [[750]]],
		id: 'TV',
		properties: {
			name: 'Tuvalu',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [[[751]], [[752]]],
		id: 'AG',
		properties: {
			name: 'Antigua and Barbuda',
		},
	},
	{
		type: 'Polygon',
		arcs: [[753]],
		id: 'BB',
		properties: {
			name: 'Barbados',
		},
	},
	{
		type: 'Polygon',
		arcs: [[754]],
		id: 'DM',
		properties: {
			name: 'Dominica',
		},
	},
	{
		type: 'Polygon',
		arcs: [[755]],
		id: 'GD',
		properties: {
			name: 'Grenada',
		},
	},
	{
		type: 'MultiPolygon',
		arcs: [[[756]], [[757]]],
		id: 'KN',
		properties: {
			name: 'Saint Kitts and Nevis',
		},
	},
	{
		type: 'Polygon',
		arcs: [[758]],
		id: 'LC',
		properties: {
			name: 'Saint Lucia',
		},
	},
	{
		type: 'Polygon',
		arcs: [[759]],
		id: 'VC',
		properties: {
			name: 'Saint Vincent and the Grenadines',
		},
	},
	{
		type: 'Polygon',
		arcs: [[760]],
		id: 'AD',
		properties: {
			name: 'Andorra',
		},
	},
	{
		type: 'Polygon',
		arcs: [[-45, 761, -166, 762]],
		id: 'LI',
		properties: {
			name: 'Liechtenstein',
		},
	},
	{
		type: 'Polygon',
		arcs: [[763]],
		id: 'MT',
		properties: {
			name: 'Malta',
		},
	},
	{
		type: 'Polygon',
		arcs: [[764]],
		id: 'MC',
		properties: {
			name: 'Monaco',
		},
	},
	{
		type: 'Polygon',
		arcs: [[765]],
		id: 'SM',
		properties: {
			name: 'San Marino',
		},
	},
	{
		type: 'Polygon',
		arcs: [[766]],
		id: 'KI',
		properties: {
			name: 'Kiribati',
		},
	},
	{
		type: 'Polygon',
		arcs: [[767]],
		id: 'ST',
		properties: {
			name: 'Sao Tome and Principe',
		},
	},
];
