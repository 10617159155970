// Core
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

// Context
import { UIContext } from 'contexts/UIContext';

// Utils
import { getTooltip } from './utils/getTooltip';

// Icons
import close_black from 'images/close_black.svg';

// Styles
import s from './CmoBtn.module.css';

const CmoBtn = ({
	selectPerson,
	index,
	deleteContributor,
	type,
	onSave,
	isLoading,
	// release,
	// composition,
}) => {
	// TODO
	// Окрашивание зеленым кнопки работает некорректно, когда несколько контрибьюторов
	const {
		// isReleaseAuthorSocieties,
		// isCompositionAuthorSocieties,
		setContributor,
	} = useContext(UIContext);
	const [currentLoading, setCurrentLoading] = useState(false);

	useEffect(() => {
		if (currentLoading && !isLoading) {
			setCurrentLoading(false);
		}
	}, [isLoading, currentLoading]);

	return (
		<div className={s.cmoContainer}>
			<button
				className={
					!selectPerson?.contributors[index].title ||
					(type === 'publisher' && !selectPerson?.contributors[index].publisher)
						? `${s.cmoBtn} ${s.disabled}`
						: // TODO
						  // Окрашивание зеленым кнопки работает некорректно, когда несколько контрибьюторов
						  // : (isReleaseAuthorSocieties && release) ||
						  //   (isCompositionAuthorSocieties && composition)
						  // ? `${s.cmoBtn} ${s.green}`
						  s.cmoBtn
				}
				title={getTooltip(!selectPerson?.contributors[index].id)}
				disabled={
					currentLoading ||
					!selectPerson?.contributors[index].title ||
					(type === 'publisher' && !selectPerson?.contributors[index].publisher)
				}
				onClick={() => {
					setCurrentLoading(true);
					const contributor = selectPerson?.contributors[index];
					setContributor({ ...contributor, type: type });
					onSave(type, index);
					return;
				}}
			>
				<FormattedMessage id={'rod.release.create.step.author_share.cmo'} />
			</button>
			<button
				className={s.clearBtn}
				testid={type === 'publisher' ? 'publisherClose' : 'authorClose'}
				onClick={() => deleteContributor(index)}
			>
				<img src={close_black} alt="" />
			</button>
			{isLoading && currentLoading && (
				<div className={s.preparing}>
					<span>
						<FormattedMessage id={'rod.statistic.preparing'} />
					</span>
				</div>
			)}
		</div>
	);
};

export default CmoBtn;
