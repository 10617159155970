export function mapReleasesTypesToFilterSettings(releaseTypes, prevFilterSettings, langChange) {
  return [
    prevFilterSettings[0],
    {
      id: prevFilterSettings.length + 1,
      title_ru: 'тип релиза',
      title_en: 'release type',
      query: 'release_types',
      label_ru: 'выберете тип релиза',
      label_en: 'select release type',
      list: releaseTypes.map((releaseType) => {
        return {
          id: releaseType.id,
          title_ru: releaseType.title,
          title_en: releaseType.title,
          code: releaseType.id,
          checked: false,
        }
      })
    }
  ]
}