import s from './RepertoireCompositionItem.module.css';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

// data =  { title_en: currentTitle, tracks: reasonsByTitle };

export default function RepertoireCompositionItem({ data /* , lang */ }) {
	let itemId = 0;
	const htmlParsing = (text) => {
		return parse(DOMPurify.sanitize(text));
	};

	return (
		<>
			<ul className={s.issue_wrapper}>
				{data &&
					data['reasons'].map((item) => (
						<li key={itemId++} className={s.issue_element}>
							<span className={s.issue_marker}>
								<svg
									width="10"
									height="10"
									viewBox="0 0 10 10"
									fill={item['crucial'] ? '#F75E63' : '#FFD28E'}
									xmlns="http://www.w3.org/2000/svg"
								>
									<rect width="10" height="10" />
								</svg>
							</span>

							{/* <span>{htmlParsing(item[`text_${lang}`])}</span> */}
							<span>{htmlParsing(item[`text`])}</span>
						</li>
					))}
			</ul>
		</>
	);
}
