/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

import classnames from 'classnames';
import React from 'react';
import styles from './Form.module.css';

const Form = (props) => {
	const { children, className, ...rest } = props;
	return (
		<form className={classnames(styles.Form, className)} {...rest} noValidate>
			{children}
		</form>
	);
};

export default Form;
