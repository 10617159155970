// UI
import TabUnstyled from '@mui/base/TabUnstyled';

// Styles
import { styled } from '@mui/material/styles';

const HeaderButton = styled(TabUnstyled)(({ theme }) => ({
	width: '50%',
	cursor: 'default !important',
	backgroundColor: 'var(--color-white)',
	color: 'var(--color-dark-grey)',
	font: 'var(--gilroy-Medium-16)',
	fontWeight: '700',
	lineHeight: '20px',
	letterSpacing: '1px',
	padding: '20px 24px',
	border: 'none',
	textAlign: 'center',
	margin: 0,
	'&.Mui-selected': {
		'& > *:first-child': {
			textDecoration: 'line-through',
		},
	},
}));

export default HeaderButton;
