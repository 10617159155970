// Core
import { FormattedMessage } from 'react-intl';

export const getTracksCountError = (max) => {
	switch (max) {
		case 1:
			return <FormattedMessage id={'rod.field.upload.error.track.single'} />;

		case 7:
			return <FormattedMessage id={'rod.field.upload.error.track.ep'} />;

		case 40:
			return <FormattedMessage id={'rod.field.upload.error.track.album'} />;

		default:
			break;
	}
};