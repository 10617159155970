// Core
import { FormattedMessage } from 'react-intl';

// UI
import { FullScreenModalConfirm } from 'containers/Modal';

const ModalWarning = ({ onClose }) => {
	return (
		<FullScreenModalConfirm
			title={
				<FormattedMessage id={'rod.modal.type.additional_release.title'} />
			}
			text={<FormattedMessage id={'rod.modal.type.additional_release.text'} />}
			confirmBtnTxt={
				<FormattedMessage id={'rod.modal.leave_only_one_track.btn'} />
			}
			onClose={onClose}
		/>
	);
};

export default ModalWarning;
