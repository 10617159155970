// Core
import React from 'react';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

// Icons
import svg1 from 'images/list.svg';

// Styles
import styles from './NotifyItem.module.css';

const NotifyItem = (props) => {
	const item = props.item;

	const htmlParsing = (text) => {
		return parse(DOMPurify.sanitize(text));
	};

	return (
		<div className={styles.notifyItem}>
			<img src={svg1} alt="" />
			<p className={styles.notifyItem__text}>{htmlParsing(item.heading)}</p>
			<p className={styles.notifyItem__date}>{item.created_at}</p>
			{!item.read_user && props.viewedCount !== 0 && <span className={styles.notifyItem__dots}></span>}
		</div>
	);
};
export default NotifyItem;
