// Core
import { FormattedMessage } from 'react-intl';

// Styles
import styles from './BottomError.module.css';

const BottomError = ({ errors, error, messageId }) => {
	return (
		<>
			{Object.keys(errors).includes(error) && (
				<div className={styles.error_container}>
					<span className={styles.error}>
						<FormattedMessage id={messageId} />
					</span>
				</div>
			)}
		</>
	);
};

export default BottomError;
