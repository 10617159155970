/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

import { Button } from 'components/Buttons/Button';
import { Label } from 'components/Labels/Label';
import update from 'immutability-helper';
import Conditions from 'pages/contracts/AddContract/steps/01-Terms/components/Conditions/Conditions';
import TerritorySelect from 'pages/contracts/AddContract/steps/01-Terms/components/Conditions/TerritorySelect/TerritorySelect';
import React, { Component } from 'react';
import styles from './NewCondition.module.css';

class NewCondition extends Component {
	state = {
		isTerritorySelectOpen: false,
		editOptions: null,
		conditions: {
			useTypes: [
				{
					mediaTypes: [
						{
							checked: false,
							keyword: 'composition',
							territories: ['france'],
						},
						{
							checked: false,
							keyword: 'recordings',
							territories: [],
						},
					],
					checked: false,
					keyword: 'digital_exploitation',
				},
				{
					mediaTypes: [
						{
							checked: false,
							keyword: 'composition',
							territories: [],
						},
						{
							checked: false,
							keyword: 'recordings',
							territories: [],
						},
					],
					checked: false,
					keyword: 'public_performance',
				},
				{
					mediaTypes: [
						{
							checked: true,
							keyword: 'composition',
							territories: [],
						},
						{
							checked: false,
							keyword: 'recordings',
							territories: [],
						},
					],
					checked: false,
					keyword: 'synchronization',
				},
				{
					mediaTypes: [],
					checked: false,
					keyword: 'lyrics',
				},
			],
		},
		territories: [
			{
				value: 'russia',
				label: 'Russia',
			},
			{
				value: 'france',
				label: 'France',
			},
			{
				value: 'germany',
				label: 'Germany',
			},
			{
				value: 'uk',
				label: 'UK',
			},
		],
	};

	openEditTerritoriesHandler = (options) => {
		if (options.useType && options.mediaType) {
			const useTypeIndex = this.state.conditions.useTypes.findIndex(
				(useType) => useType.keyword === options.useType.keyword
			);
			const mediaTypeIndex = this.state.conditions.useTypes[
				useTypeIndex
			].mediaTypes.findIndex(
				(mediaType) => mediaType.keyword === options.mediaType.keyword
			);
			this.setState({
				editOptions: {
					...options,
					useTypeIndex,
					mediaTypeIndex,
				},
				isTerritorySelectOpen: true,
			});
		}
	};

	changeHandler = (options) => {
		if (options.useType) {
			const useTypeIndex = this.state.conditions.useTypes.findIndex(
				(useType) => useType.keyword === options.useType.keyword
			);
			let newState = update(this.state, {
				conditions: { useTypes: { [useTypeIndex]: { $toggle: ['checked'] } } },
			});
			if (options.mediaType) {
				const mediaTypeIndex = this.state.conditions.useTypes[
					useTypeIndex
				].mediaTypes.findIndex(
					(mediaType) => mediaType.keyword === options.mediaType.keyword
				);
				newState = update(this.state, {
					conditions: {
						useTypes: {
							[useTypeIndex]: {
								mediaTypes: { [mediaTypeIndex]: { $toggle: ['checked'] } },
							},
						},
					},
				});
			}
			this.setState(newState);
		}
	};
	saveTerritoriesHandler = (values) => {
		if (this.state.editOptions.useType && this.state.editOptions.mediaType) {
			const useTypeIndex = this.state.conditions.useTypes.findIndex(
				(useType) => useType.keyword === this.state.editOptions.useType.keyword
			);
			const mediaTypeIndex = this.state.conditions.useTypes[
				useTypeIndex
			].mediaTypes.findIndex(
				(mediaType) =>
					mediaType.keyword === this.state.editOptions.mediaType.keyword
			);
			const newState = update(this.state, {
				conditions: {
					useTypes: {
						[useTypeIndex]: {
							mediaTypes: {
								[mediaTypeIndex]: { territories: { $set: values } },
							},
						},
					},
				},
			});
			this.setState({
				...newState,
				isTerritorySelectOpen: false,
				editOptions: null,
			});
		}
	};
	closeEditTerritoriesHandler = (event) => {
		this.setState({
			isTerritorySelectOpen: false,
			editOptions: null,
		});
	};

	render() {
		const { isTerritorySelectOpen } = this.state;
		return (
			<React.Fragment>
				<div className={styles.Page}>
					{isTerritorySelectOpen
						? this.renderTerritorySelect()
						: this.renderContract()}
				</div>
			</React.Fragment>
		);
	}

	renderTerritorySelect() {
		const selected = this.state.conditions.useTypes[
			this.state.editOptions.useTypeIndex
		].mediaTypes[this.state.editOptions.mediaTypeIndex].territories;
		return (
			<TerritorySelect
				selected={selected}
				territories={this.state.territories}
				onCancel={this.closeEditTerritoriesHandler}
				onSave={this.saveTerritoriesHandler}
			/>
		);
	}

	renderContract() {
		return (
			<React.Fragment>
				<Conditions
					onTerritoryEdit={this.openEditTerritoriesHandler}
					onChange={this.changeHandler}
					conditions={this.state.conditions}
				/>

				<Label
					text={
						'Нужны более сложные условия? Создайте их на платформе Heaven11'
					}
					variant={'text-16-140'}
					className={styles.Label}
				/>

				<div className={styles.Controls}>
					<Button text={'Сохранить'} variant={'primary'} />
					<Button text={'Отмена'} variant={'link'} />
				</div>
			</React.Fragment>
		);
	}
}

export default NewCondition;
