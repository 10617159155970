import React from 'react';

import s from './FaqInput.module.css';

import { Loop } from 'material-design/svg';
import { FormattedMessage } from 'react-intl';

export default function FaqInput({
	handleOnChange,
	handleOnEnterPress,
	setIsFocus,
	filter,
	setActive,
	curRef,
	parent,
	initialValue,
	placeholder,
}) {
	filter = filter ? filter : '';
	// поменять потом

	setIsFocus = setIsFocus ? setIsFocus : () => {};

	const inputChange = handleOnChange ? handleOnChange : () => {};
	const enterHandling = (e) => {
		if (e.charCode === 13) {
			setIsFocus(true);
			handleOnEnterPress(e.char);
		}
	};
	return (
		<div className={s.repertoire__mainFilter}>
			{initialValue ? (
				<div className={s.repertoire__mainFilterInput}>
					<FormattedMessage
						id={placeholder ? placeholder : 'rod.statistic.search'}
					>
						{(msg) => (
							<input
								value={initialValue}
								ref={curRef}
								type="text"
								placeholder={msg}
								onChange={inputChange}
								onClick={() => setIsFocus(true)}
								onKeyPress={enterHandling}
							/>
						)}
					</FormattedMessage>
					<Loop />
				</div>
			) : (
				<div className={s.repertoire__mainFilterInput}>
					<FormattedMessage
						id={placeholder ? placeholder : 'rod.statistic.search'}
					>
						{(msg) => (
							<input
								ref={curRef}
								type="text"
								placeholder={msg}
								onChange={inputChange}
								onClick={() => setIsFocus(true)}
								onKeyPress={enterHandling}
							/>
						)}
					</FormattedMessage>
					<Loop />
				</div>
			)}
		</div>
	);
}
