// Core
import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Col, Row, Container } from 'react-grid-system';

// Utils
import { getDraftStatus } from '../utils/getDraftStatus';
import { getRedirectLink } from '../utils/getRedirectLink';
import { isUnknownStep } from '../utils/isUnknownStep';
import { isKnownStatus } from '../utils/isKnownStatus';

// Icons
import RemoveButton from '../RemoveButton/RemoveButton';
import { Cd } from 'material-design/svg';
import { CdWhite } from 'material-design/svg';
import { Nota } from 'material-design/svg';
import { NotaWhite } from 'material-design/svg';
import pencil from 'images/pencil.svg';

// Styles
import styles from '../RepertoireDraftSection.module.css';

const DraftsWrapper = ({ drafts, releases, getReleasesAfterRemove }) => {
	const handleClick = (item) => {
		switch (item.draft_type) {
			case 'release':
				const releaseId = localStorage.getItem('releaseId');
				if (item.id !== releaseId) {
					localStorage.setItem('releaseId', item.id);
				}

				return `/release/${item.id}/${item.step}`;
			case 'composition':
				const compositionId = localStorage.getItem('compositionId');
				if (item.id !== compositionId) {
					localStorage.setItem('compositionId', item.id);
				}

				if (item.step === 'info') {
					return `/compositions/create`;
				} else {
					return `/compositions/${item.id}/${item.step}`;
				}

			default:
				break;
		}
	};

	return (
		<ul className={styles.repertoire__songItemsWrapper}>
			{drafts.map((item, index) => {
				return isKnownStatus(item.step) || isUnknownStep(item.step) ? (
					<li key={index} className={styles.itemWrapper}>
						<div className={styles.itemInactive}>
							<div className={styles.adaptive__item}>
								<Container fluid>
									<Row style={{ marginTop: '10px' }}>
										<div style={{ height: '29px' }}></div>
									</Row>
									<Row>
										<Col xs={4}>
											<div>
												{item.draft_type === 'release' ? (
													<CdWhite className={styles.adaptive__songItemLogo} />
												) : (
													<NotaWhite
														className={styles.adaptive__songItemLogo}
													/>
												)}
											</div>
										</Col>
										<Col xs={8}>
											<div className={styles.adaptive__itemText_firstLine}>
												<span title={item.title}>{item.title}</span>
											</div>
										</Col>
									</Row>

									<Row>
										<Col xs={4}>
											<div className={styles.adaptive__itemTitle}>
												<FormattedHTMLMessage id={'rod.for_all.performers'} />
											</div>
											<div className={styles.adaptive__itemTitle}>
												<FormattedHTMLMessage id={'rod.for_all.type'} />
											</div>
											<div className={styles.adaptive__itemTitle}>
												<FormattedHTMLMessage id={'rod.for_all.status'} />
											</div>
										</Col>
										<Col xs={8}>
											<div className={styles.adaptive__itemText}>
												<span title={item.performers ? item.performers : ''}>
													{item.performers ? item.performers : ''}
												</span>
											</div>
											<div className={styles.adaptive__itemText}>
												<span title={item.type}>{item.type}</span>
											</div>
											<div className={styles.adaptive__itemText}>
												<span>
													<FormattedMessage
														id={`rod.draft.release.${
															isUnknownStep(item.step)
																? 'unknownstatus'
																: item.step
														}`}
													/>
												</span>
												{item.step && (
													<span>
														<FormattedMessage
															id={`rod.draft.release.${
																isUnknownStep(item.step)
																	? 'unknownstatus'
																	: item.step
															}`}
														/>
													</span>
												)}
											</div>
										</Col>
									</Row>
								</Container>
							</div>

							<div
								className={`${styles.draft_moderation} ${styles.song__table} ${styles.adaptive__songTable}`}
							>
								<div>
									<span title={item.title}>{item.title}</span>
								</div>
								<div>
									<span title={item.performers}>
										{item.performers && item.performers}
									</span>
								</div>
								<div>
									<span title={item.type}>{item.type}</span>
								</div>
								<div>
									<span>
										<FormattedMessage
											id={`rod.draft.release.${
												isUnknownStep(item.step) ? 'unknownstatus' : item.step
											}`}
										/>
									</span>
									{item.step && (
										<span>
											<FormattedMessage
												id={`rod.draft.release.${
													isUnknownStep(item.step) ? 'unknownstatus' : item.step
												}`}
											/>
										</span>
									)}
								</div>

								{item.draft_type === 'release' ? (
									<CdWhite className={styles.repertoire__songItemLogo} />
								) : (
									<NotaWhite className={styles.repertoire__songItemLogo} />
								)}
							</div>
						</div>
						<div className={styles.adaptive__menuMoreWrapper}></div>
					</li>
				) : (
					<li className={styles.itemWrapper}>
						<Link
							key={item.id}
							to={() => getRedirectLink(item)}
							onClick={() => handleClick(item)}
							className={styles.itemLink}
						>
							<div className={styles.adaptive__item}>
								<Container fluid>
									<Row style={{ marginTop: '10px' }}>
										<div style={{ marginLeft: 'auto' }}>
											<img className={styles.edit} src={pencil} alt="" />
										</div>
									</Row>
									<Row className={styles.adaptive__firstRow}>
										<Col xs={4}>
											<div>
												{item.draft_type === 'release' ? (
													<Cd className={styles.adaptive__songItemLogo} />
												) : (
													<Nota className={styles.adaptive__songItemLogo} />
												)}
											</div>
										</Col>
										<Col xs={10}>
											<div className={styles.adaptive__itemText_firstLine}>
												<span title={item.title}>{item.title}</span>
											</div>
										</Col>
									</Row>
									<Row>
										<Col xs={4}>
											<div className={styles.adaptive__itemTitle}>
												<FormattedHTMLMessage id={'rod.for_all.performers'} />
											</div>
											<div className={styles.adaptive__itemTitle}>
												<FormattedHTMLMessage id={'rod.for_all.type'} />
											</div>
											<div className={styles.adaptive__itemTitle}>
												<FormattedHTMLMessage id={'rod.for_all.status'} />
											</div>
										</Col>
										<Col xs={6}>
											<div className={styles.adaptive__itemText}>
												<span title={item.performers ? item.performers : ''}>
													{item.performers ? item.performers : ''}
												</span>
											</div>
											<div className={styles.adaptive__itemText}>
												<span title={item.type}>{item.type}</span>
											</div>
											<div className={styles.adaptive__itemText}>
												<span>{getDraftStatus(item)}</span>
											</div>
										</Col>
									</Row>
								</Container>
							</div>

							<div
								className={
									item.status_title === 'disputed' ||
									item.status_title === 'not_ready'
										? `${styles.repertoire__songItemError} ${styles.song__table} ${styles.adaptive__songTable}`
										: `${styles.song__table} ${styles.adaptive__songTable}`
								}
							>
								<div>
									<span title={item.title}>{item.title}</span>
								</div>
								<div>
									<span title={item.performers}>
										{item.performers && item.performers}
									</span>
								</div>
								<div>
									<span title={item.type}>{item.type}</span>
								</div>
								<div>
									<span>{getDraftStatus(item)}</span>
								</div>
								<div>
									<img className={styles.edit} src={pencil} alt="" />
								</div>
								{item.draft_type === 'release' ? (
									<Cd className={styles.repertoire__songItemLogo} />
								) : (
									<Nota className={styles.repertoire__songItemLogo} />
								)}
							</div>
						</Link>

						<RemoveButton
							item={item}
							releases={releases}
							getReleasesAfterRemove={getReleasesAfterRemove}
						/>
					</li>
				);
			})}
		</ul>
	);
};

export default DraftsWrapper;
