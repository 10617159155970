// Core
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

// Utils
import { withUI } from 'hocs';
import { compose } from 'recompose';
import { adjustFontSize, checkOffsetWidth } from 'utils';

// UI
import Balance from './Balance/Balance';
import Disputes from './Disputes/Disputes';
import NotReady from './NotReady/NotReady';
import StatLoader from './StatLoader/StatLoader';

// Styles
import s from './Statistics.module.css';

const Statistics = (props) => {
	const statistics = props.statistics;
	const {
		UIContext: { selectRepertoirePage, isHideCountersNotreadyDisputes },
	} = props;

	const [releaseSum, setReleaseSum] = useState('');
	const [recordingsSum, setRecoringsSum] = useState('');
	const [compositionsSum, setCompositionsSum] = useState('');
	const [notReady, setNotReady] = useState('');
	const [disputes, setDisputes] = useState('');
	const [balance, setBalance] = useState({});

	const containersIds = [
		'releaseSum',
		'recordingsSum',
		'compositionsSum',
		'notReady',
		'disputes',
		'balance',
	];

	function isEmpty(obj) {
		for (var key in obj) {
			return false;
		}
		return true;
	}

	useEffect(() => {
		if (!isEmpty(statistics)) {
			setCompositionsSum(statistics['compositions'].total);
			setRecoringsSum(statistics['recordings'].total);
			setReleaseSum(statistics['releases'].total);
			setNotReady(statistics['combined'].not_ready);
			setDisputes(statistics['combined'].disputed);
			setBalance(statistics['balance']);
		}
	}, [statistics]);

	useEffect(() => {
		const isNotFit = containersIds.some((item) =>
			checkOffsetWidth(`${item}_container`, `${item}_content`)
		);

		if (isNotFit) {
			containersIds.forEach((item) =>
				adjustFontSize(`${item}_container`, `${item}_content`, '24px')
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [releaseSum, recordingsSum, compositionsSum, notReady, disputes, balance]);

	const repertoireRedirect = (value) => {
		switch (value) {
			case 'recordings':
				selectRepertoirePage(value);
				break;
			case 'compositions':
				selectRepertoirePage(value);
				break;
			case 'releases':
				selectRepertoirePage(value);
				break;
			default:
				break;
		}
	};

	return (
		<div
			className={
				!isHideCountersNotreadyDisputes
					? s.container__items_all
					: s.container__items_hidden
			}
		>
			{props.isLoading ? (
				<StatLoader label="rod.main_page.releases" />
			) : (
				<NavLink
					to="/repertoire/releases"
					className={s.container__item}
					onClick={() => repertoireRedirect('releases')}
					id="releaseSum_container"
				>
					<span>
						<FormattedMessage id={'rod.main_page.releases'} />
					</span>
					{releaseSum && releaseSum.length !== 0 ? (
						<span
							id="releaseSum_content"
							className={Number(releaseSum) !== 0 ? s.stats : null}
						>
							{releaseSum.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1 `)}
						</span>
					) : (
						<span>-</span>
					)}
				</NavLink>
			)}

			{props.isLoading ? (
				<StatLoader label="rod.main_page.phonograms" />
			) : (
				<NavLink
					to="/repertoire/recordings"
					className={s.container__item}
					onClick={() => repertoireRedirect('recordings')}
					id="recordingsSum_container"
				>
					<span>
						<FormattedMessage id={'rod.main_page.phonograms'} />
					</span>
					{recordingsSum && recordingsSum.length !== 0 ? (
						<span
							id="recordingsSum_content"
							className={Number(recordingsSum) !== 0 ? s.stats : null}
						>
							{recordingsSum
								.toString()
								.replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1 `)}
						</span>
					) : (
						<span>-</span>
					)}
				</NavLink>
			)}

			{props.isLoading ? (
				<StatLoader label="rod.main_page.compositions" />
			) : (
				<NavLink
					to="/repertoire/compositions"
					className={s.container__item}
					onClick={() => repertoireRedirect('compositions')}
					id="compositionsSum_container"
				>
					<span>
						<FormattedMessage id={'rod.main_page.compositions'} />
					</span>
					{compositionsSum && compositionsSum.length !== 0 ? (
						<span
							id="compositionsSum_content"
							className={Number(compositionsSum) !== 0 ? s.stats : null}
						>
							{compositionsSum
								.toString()
								.replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1 `)}
						</span>
					) : (
						<span>-</span>
					)}
				</NavLink>
			)}

			{!isHideCountersNotreadyDisputes && (
				<>
					{props.isLoading ? (
						<StatLoader label="rod.main_page.mistakes" />
					) : (
						<NotReady notReady={notReady} />
					)}
					{props.isLoading ? (
						<StatLoader label="rod.main_page.disputes" />
					) : (
						<Disputes disputes={disputes} />
					)}
				</>
			)}

			{props.isLoading ? (
				<StatLoader label="rod.main_page.balance" />
			) : (
				<Balance balance={balance} />
			)}
		</div>
	);
};

export default compose(withUI)(Statistics);
