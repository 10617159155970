// Core
import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

// Styles
import styles from './Lyrics.module.css';

const Lyrics = ({ lyrics }) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const [isOverflowing, setIsOverflowing] = useState(false);
	const lyricsRef = useRef(null);

	useEffect(() => {
		if (lyricsRef.current.scrollHeight > 304) {
			setIsOverflowing(true);
		}
	}, [lyrics]);

	return (
		<div className={styles.container}>
			<span className={styles.title}>
				<FormattedMessage id={'rod.composition.info.lyrics'} />
			</span>
			<div className={styles.lyricsContainer}>
				<div
					className={styles.lyrics}
					style={{ height: isExpanded ? 'auto' : '304px' }}
				>
					<div
						className={`${styles.lyricsText} ${
							isOverflowing && !isExpanded ? styles.ellipsis : ''
						}`}
						ref={lyricsRef}
						dangerouslySetInnerHTML={{
							__html: lyrics.replace(/\n/g, '<br />'),
						}}
					></div>
				</div>
				{isOverflowing && !isExpanded && (
					<button
						className={styles.showMoreBtn}
						onClick={() => setIsExpanded(true)}
					>
						<FormattedMessage id={'rod.composition.info.lyrics.button'} />
					</button>
				)}
				{isOverflowing && isExpanded && (
					<button
						className={styles.hideBtn}
						onClick={() => setIsExpanded(false)}
					>
						<FormattedMessage id={'rod.composition.info.lyrics.hide.button'} />
					</button>
				)}
			</div>
		</div>
	);
};

export default Lyrics;
