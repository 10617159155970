// Core
import React, { useContext, useEffect } from 'react';

// Context
import { AuthContext } from 'contexts/AuthContext';

// UI
import { RegisterVerifySMS as VerifyForm } from 'forms/index';

// Styles
import styles from './SMSRegaCheck.module.css';

const SMSRegaCheck = () => {
	const {
		getSmsRetryTime,
		resendSMS,
		verifySMS,
		regData,
		user,
		addCodeErrors,
	} = useContext(AuthContext);

	useEffect(() => {
		getSmsRetryTime('register')
			.then()
			.catch((err) => {
				console.error('Error of get sms retry request', err);
				addCodeErrors(err?.response?.data?.errors);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const resendSmsRequest = (phone) => {
		return resendSMS(phone)
			.then()
			.catch((err) => {
				if (err.status && err.status === 'validation_error') {
					addCodeErrors(err.errors);
					return Promise.reject(err);
				}
			});
	};

	return (
		<div className={styles.page}>
			<VerifyForm
				onSubmit={verifySMS}
				onResend={resendSmsRequest}
				phone={regData.phone || user.phone}
			/>
		</div>
	);
};

export default SMSRegaCheck;
