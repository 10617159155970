import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import { RepertoireCompositionItem } from './RepertoireCompositionItem';
// import { LangContext } from 'contexts/LangContext';
import { withAuth, withRoot } from 'hocs';
import { Loading, NoData } from 'components';
import { compose } from 'recompose';

import s from './RepertoireCompositionNoReady.module.css';

function RepertoireCompositionNoReady(props) {
	const match = useRouteMatch();
	const compositionId = match.params.id;
	// const { lang } = useContext(LangContext);

	const [loading, setLoading] = useState(true);
	const [notices, setNotices] = useState([]);
	const lang = localStorage.getItem('lang');
	const {
		authContext: { accountId },
		rootContext: { getCompositionIssues },
	} = props;
	const scopeList = {
		audio: { en: 'Audio', ru: 'Аудио' },
		code: { en: 'Code', ru: 'Код' },
		date: { en: 'Date', ru: 'Дата' },
		image: { en: 'Cover image', ru: 'Обложка' },
		genre: { en: 'Genre', ru: 'Жанр' },
		contributors: { en: 'Contributors', ru: 'Контрибьюторы' },
		lyrics: { en: 'Lyrics', ru: 'Лирика' },
		field_lyrics: { en: 'Lyrics', ru: 'Лирика' },
	};
	// const errors = [
	// 	{
	// 		cat: 'audio',
	// 		id: 1,
	// 		list: [{ id: 1, song: '«My Lovely Song»', error: '123' }],
	// 	},
	// 	{
	// 		cat: 'cover',
	// 		id: 2,
	// 		list: [{ id: 1, error: '124' }],
	// 	},
	// 	{
	// 		cat: 'genre',
	// 		id: 3,
	// 		list: [{ id: 1, song: '«My Lovely Song»', error: '125' }],
	// 	},
	// ];

	useEffect(() => {
		getCompositionIssues(accountId, compositionId, lang)
			.then((res) => {
				setNotices(res);
				setLoading(false);
			})
			.catch((error) => {
				console.info('Error', error);
				setLoading(false);
			});
	}, [lang]);

	const structuredList = () => {
		let result = [];
		let scopeList = [];
		notices.forEach((currentReason) => {
			if (!scopeList.includes(currentReason.scope)) {
				scopeList.push(currentReason.scope);
			}
		});
		/* change titleList from a to z - add such code here*/
		result = scopeList.map((currentScope) => {
			let reasonsByScope = notices.filter((currentReason) => {
				return currentReason.scope === currentScope;
			});
			return { scope: currentScope, reasons: reasonsByScope }; //example of each obj inside the resulted arr
		});
		return result;
	};

	// function transformError(error) {
	// 	switch (error.error) {
	// 		case '123':
	// 			return (
	// 				<span>
	// 					Под фонограмму{' '}
	// 					<span className={s.song__errorSong}>{error.song}</span> необходимо
	// 					загрузить аудио.
	// 				</span>
	// 			);
	// 		case '124':
	// 			return <span>Под релизом необходимо загрузить обложку.</span>;
	// 		case '125':
	// 			return (
	// 				<span>
	// 					Жанр «hip-hop» фонограммы{' '}
	// 					<span className={s.song__errorSong}>{error.song}</span> необходимо
	// 					привязать к Phonofile.
	// 				</span>
	// 			);
	// 		default:
	// 			return 'no error code';
	// 	}
	// }

	// function transformTitle(item) {
	// 	switch (item.cat) {
	// 		case 'audio':
	// 			return <FormattedMessage id={'rod.composition.recordery.Audio'} />;
	// 		case 'cover':
	// 			return <FormattedMessage id={'rod.composition.recordery.Cover'} />;
	// 		case 'genre':
	// 			return <FormattedMessage id={'rod.composition.recordery.Genre'} />;
	// 		default:
	// 			return 'error category name';
	// 	}
	// }

	// return (
	// 	<>
	// 		<div className={s.song__errorList}>
	// 			{errors.length &&
	// 				errors.map((item) => (
	// 					<Fragment key={item.id}>
	// 						<span className={s.song__errorTitle}>{transformTitle(item)}</span>
	// 						<ul key={item.id} className={s.song__errorBody}>
	// 							{item.list.map((error) => (
	// 								<li key={error.id} className={s.song__errorItem}>
	// 									{transformError(error)}
	// 								</li>
	// 							))}
	// 						</ul>
	// 					</Fragment>
	// 				))}
	// 		</div>
	// 	</>
	// );

	return loading ? (
		<Loading />
	) : (
		<div>
			{notices.length ? (
				<ul className={s.table__body}>
					{structuredList().map((item) => (
						<li>
							{/* {item.scope} */}
							<p className={s.scope_title}>{scopeList[item.scope][lang]}</p>
							<RepertoireCompositionItem data={item} /*  lang={lang}  */ />
						</li>
					))}
				</ul>
			) : (
				<div>
					<NoData />
				</div>
			)}
		</div>
	);
}
export default compose(withAuth, withRoot)(RepertoireCompositionNoReady);
