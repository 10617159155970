/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 15/02/2021, 10:53
 */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'recompose';
import { withForwardRef } from '../../../hocs';
import styles from './Label.module.css';

class Label extends React.Component {
	static propTypes = {
		/**
		 * One or more button variant combinations
		 *
		 * buttons may be one of a variety of visual variants such as:
		 *
		 * `'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark', 'light', 'link'`
		 *
		 * as well as "outline" versions (prefixed by 'outline-*')
		 *
		 * `'outline-primary', 'outline-secondary', 'outline-success', 'outline-danger', 'outline-warning', 'outline-info', 'outline-dark', 'outline-light'`
		 */
		variant: PropTypes.string,

		/** Manually set the visual state of the button to `:active` */
		active: PropTypes.bool,

		/**
		 * Disables the Button, preventing mouse events,
		 * even if the underlying component is an `<a>` element
		 */
		disabled: PropTypes.bool,

		/**
		 * Defines HTML button type attribute.
		 *
		 * @default 'button'
		 */
		type: PropTypes.oneOf(['button', 'reset', 'submit', null]),

		/**
		 * Defines HTML button content.
		 */
		text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

		font: PropTypes.oneOf([
			'--gilroy-Medium-24-140p',
			'--gilroy-Medium-64',
			'--gilroy-Medium-40',
			'--gilroy-Medium-32',
			'--gilroy-Medium-24',
			'--gilroy-Bold-24',
			'--gilroy-Medium-18',
			'--gilroy-Bold-18',
			'--gilroy-Medium-16-19',
			'--gilroy-Medium-16',
			'--gilroy-Medium-14',
			'--gilroy-Medium-12',
			'--gilroy-Medium-11',

			null,
		]),
	};

	static defaultProps = {
		active: false,
		disabled: false,
	};

	static displayName = 'Label';

	render() {
		const {
			className,
			forwardRef,
			black,
			variant,
			children,
			text,
			withFocusRef,
			font,
			display,
			active,
			...props
		} = this.props;
		const classes = classNames(className, styles['Label'], variant, 'label', {
			black: black,
		});

		return (
			<div
				{...props}
				ref={forwardRef}
				className={classes}
				style={{
					font: font ? `var(${font})` : undefined,
					display: display ? `${display}` : 'block',
				}}
			>
				{children || text || ''}
			</div>
		);
	}
}

export default compose(withForwardRef)(Label);
