/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 21/12/2020, 11:56
 */

import { Button } from 'components/Buttons';
import { Checkbox } from 'components/Checkboxes';
import { Form } from 'components/Form';
import { Input, PhoneInput } from 'components/Inputs';
import { Label } from 'components/Labels';
import styles from 'forms/auth/Registration/SMSNoCodeForm/RegisterNoCode.module.css';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { showError } from 'validators/showError';

class RegisterNoCode extends PureComponent {
	static propTypes = {
		onSubmit: PropTypes.func,
	};

	form = React.createRef();
	email = React.createRef();
	phone = React.createRef();
	contactMe = React.createRef();

	handleSubmit = (event) => {
		event.preventDefault();
		if (this.props.onSubmit) {
			this.props.onSubmit({
				want_to_be_contacted: this.contactMe.current.checked,
				type: this.props.email ? 'email' : 'sms',
				email: this.email.current.value,
				phone: this.phone.current.value,
			});
		}
	};

	render() {
		const { errors } = this.props;
		return (
			<Form
				ref={this.form}
				onSubmit={this.handleSubmit}
				className={styles.Form}
			>
				<div className={styles.Title}>
					<Label font="--gilroy-Medium-40" black>
						{this.props.email ? (
							<FormattedMessage id={'rod.no_code.header.email'} />
						) : (
							<FormattedMessage id={'rod.no_code.header'} />
						)}
					</Label>
					<Label font="--gilroy-Medium-24" black>
						<FormattedHTMLMessage id={'rod.no_code.subheader'} />
					</Label>
				</div>

				<div className={styles.Fields}>
					<FormattedMessage id={'rod.field.email'}>
						{(placeholder) => (
							<Input
								className={styles.Input}
								placeholder={placeholder}
								forwardRef={this.email}
								errors={errors.email}
								showError={showError('email')}
							/>
						)}
					</FormattedMessage>

					<FormattedMessage id={'rod.field.phone'}>
						{(placeholder) => (
							<PhoneInput
								placeholder={placeholder}
								showError={showError('phone')}
								ref={this.phone}
								required
								errors={errors.phone}
							/>
						)}
					</FormattedMessage>

					<Checkbox className={styles.Input} ref={this.contactMe}>
						<FormattedMessage id={'rod.no_code.text.i_want_you_contact_me'} />
					</Checkbox>
				</div>
				<div className={styles.Controls}>
					<Button
						className={styles.Button}
						text={<FormattedMessage id={'rod.action.send_request'} />}
						type={'submit'}
						variant={'primary'}
					/>
				</div>
			</Form>
		);
	}
}

export default RegisterNoCode;
