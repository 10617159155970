export default function NotaWhite({ className }) {
	return (
		<svg
			className={className}
			width="64"
			viewBox="0 0 64 64"
			fill="#c4c4c4"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M63.4972 0.349929C63.1818 0.0743382 62.7557 -0.0336618 62.341 0.00902L21.6136 5.82766C20.8949 5.92993 20.3636 6.54357 20.3636 7.26807V43.6346C20.3636 45.2399 19.0596 46.5439 17.4546 46.5439H11.6363C5.21877 46.5441 0 50.4592 0 55.272C0 60.0849 5.21877 64 11.6363 64C18.0539 64 23.2726 60.0849 23.2726 55.272V20.1673L61.0909 14.7643V37.816C61.0909 39.4213 59.7869 40.7253 58.1819 40.7253H52.3637C45.946 40.7253 40.7273 44.6404 40.7273 49.4533C40.7273 54.2661 45.9461 58.1812 52.3637 58.1812C58.7812 58.1812 64 54.2661 64 49.4533V1.44943C64 1.02902 63.8182 0.62552 63.4972 0.349929ZM20.3636 55.272C20.3636 58.4257 16.3664 61.0907 11.6363 61.0907C6.90628 61.0907 2.90905 58.4257 2.90905 55.272C2.90905 52.1184 6.90614 49.4534 11.6363 49.4534H17.4546C18.5142 49.4534 19.5086 49.1694 20.3636 48.672V55.272ZM61.0909 49.4534C61.0909 52.6071 57.0937 55.272 52.3637 55.272C47.6336 55.272 43.6364 52.6071 43.6364 49.4534C43.6364 46.2997 47.6336 43.6348 52.3637 43.6348H58.1819C59.2416 43.6348 60.2359 43.3507 61.0909 42.8534V49.4534ZM61.0909 11.8249L23.2728 17.2279V8.52957L61.0909 3.12575V11.8249Z"
				fill="#c4c4c4"
			/>
		</svg>
	);
}
