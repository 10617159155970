// Core
import { FormattedMessage } from 'react-intl';

// UI
import ActionButtons from '../ActionButtons/ActionButtons';

// Styles
import styles from './NoData.module.css';

const NoData = ({ rubrics }) => {
	return (
		<div className={styles.noData}>
			{rubrics && rubrics.length > 0 ? (
				<span className={styles.noData__search}>
					<FormattedMessage id={'rod.admin.no-data'} />
				</span>
			) : (
				<>
					<p className={styles.noData__title}>
						<FormattedMessage id={'rod.faq.article.no_data'} />
					</p>
					<p className={styles.noData__text}>
						<FormattedMessage
							id={
								rubrics.length > 0
									? 'rod.faq.article.no_data.no_articles.text'
									: 'rod.faq.article.no_data.text'
							}
						/>
					</p>
					<ActionButtons rubrics={rubrics} />
				</>
			)}
		</div>
	);
};

export default NoData;
