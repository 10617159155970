// Icons
import explicit from 'images/explicit_content.svg';

// Styles
import styles from './Explicit.module.css';

const Explicit = () => {
	return (
		<img
			className={styles.explicit}
			src={explicit}
			width="24px"
			height="24px"
			alt="explicit"
		/>
	);
};

export default Explicit;
