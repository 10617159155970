import { useContext, useEffect, useMemo, useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

// Context
import { RootContext } from 'contexts/RootContext';
import { UIContext } from 'contexts/UIContext';

// Styles
import styles from './Sign.module.css';

// Components
import { Loading } from 'components';
import { Button, InfoButton } from 'components/Buttons';
import { Col, Container } from 'components/Grid';
import { Label } from 'components/Labels';
import { SignListItem } from 'components/SignListItem';
import DocumentToSign from './DocumentToSign/DocumentToSign';

// Constants
import errorRules from 'constants/errorRules';
import ModalTypes from 'constants/modalTypes';
import { SIGN_PHYSICAL, SIGN_SMS } from './constants/constants';

const UserSign = () => {
	const location = useLocation();
	const history = useHistory();
	const {
		getPaymentDataById,
		contractSignTypes,
		getContractSignTypes,
		getAccountContactsData,
		countries,
		onNoCode,
		noCodeSignSMS,
		acceptPayment,
		changePaymentSign,
		signPayment,
	} = useContext(RootContext);

	const {
		showModal,
		hideModal,
		putResendSMSTime,
		showToast,
	} = useContext(UIContext);
	const [isButtonLoading, setIsButtonLoading] = useState(false);
	const [signType, setSignType] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [paymentData, setPaymentData] = useState(null);
	const [docConfirmed, setDocConfirmed] = useState(false);
	const [signStep, setSignStep] = useState(1);
	const [file, setFile] = useState(null);

	const [errors, setErrors] = useState(null);

	const [contacts, setContacts] = useState(null);

	const [accountId] = useState(localStorage.getItem('accountId'));

	const getStepByStatus = (step) => {
		switch (step) {
			case 'new': {
				return 1;
			}
			case 'signing': {
				return 3;
			}
			default: {
				return 4;
			}
		}
	};

	useEffect(() => {
		setIsLoading(true);
		const fetchPaymentData = async () => {
			try {
				await getContractSignTypes();
				const { data } = await getPaymentDataById(accountId, paymentId);
				setSignType(data.sign);
				if (data.sign === 2) {
					getContactsData();
				}
				if (data.sign === 2 && data.status === 'signing') {
					setSignStep(2);
				} else {
					setSignStep(getStepByStatus(data.status));
				}
				setPaymentData(data);
			} catch (error) {
				console.info('Error', error);
			} finally {
				setIsLoading(false);
			}
		};

		const searchParams = new URLSearchParams(location.search);
		const paymentId = searchParams.get('id');
		fetchPaymentData();
		hideModal();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const signTypesMap = useMemo(() => {
		return Object.fromEntries(contractSignTypes.map((i) => [i.id, i.title]));
	}, [contractSignTypes]);

	const onDocumentUpload = (file) => {
		setFile(file);
	};

	const onDocumentRemove = (clearAllErrors = false) => {
		setFile(null);
		if (clearAllErrors) {
			clearErrors();
		}
	};

	const clearErrors = () => {
		setErrors(null);
	};

	const getContactsData = () => {
		getAccountContactsData(accountId).then((res) => {
			res = res.data.data;
			const contactCountry = countries.filter(
				(item) => item.id === res.phone_code_country_id
			);
			res.dialing_code = contactCountry[0].dialing_code;
			setContacts(res);
		});
	};

	const changeSignContractsType = async () => {
		setIsButtonLoading(true);
		try {
			const { data } = await changePaymentSign(accountId, paymentData.id);
			setPaymentData(data);
			setSignStep(3);
		} catch (error) {
			console.info('Error', error);
			setErrors(error.response.data);
		} finally {
			hideModal();
			setIsButtonLoading(false);
		}
	};

	const signedBySMSHandler = async () => {
		setIsButtonLoading(true);

		try {
			const { data } = await getPaymentDataById(accountId, paymentData.id);
			setPaymentData(data);
			history.push('../reports?tab=invoice');
		} catch (error) {
			console.info('Error', error);
			setErrors(error.response.data);
		} finally {
			setIsButtonLoading(false);
		}
	};

	const resendSMSHandler = async () => {
			return await acceptPayment(accountId, paymentData.id);
	};

	const signViaSMS = async (caughtErrors = null) => {
		const { dialing_code, phone_number } = contacts;
		showModal(
			{
				phone: contacts['user-phone']
					? contacts['user-phone']
					: `+${dialing_code}${phone_number}`,
				id: accountId,
				contract: paymentData,
				type: 'payment',
				resendSignSMS: resendSMSHandler,
				signContractFromModal: signedBySMSHandler,
				signPaymentFromApi: signPayment,
				onNoCode: onNoCode,
				hash: paymentData.generated_url.id,
				noCodeSignSMS: noCodeSignSMS,
				onSubmit: () => {},
				anotherSignType: changeSignContractsType,
				changeSignContractsType: changeSignContractsType,
				errors: caughtErrors || null,
			},
			ModalTypes.CONTRACT_SMS_SIGN
		)();
	};

	const handleClick = async () => {
		switch (signStep) {
			case 2: {
				try {
					setIsButtonLoading(true);
					const { data } = await acceptPayment(accountId, paymentData.id);
					setPaymentData(data);
					setIsButtonLoading(false);
					if (signTypesMap[paymentData.sign] === SIGN_SMS) {
						putResendSMSTime()
						signViaSMS();
						return;
					}
					setSignStep(signStep + 1);
				} catch (error) {
					if (signTypesMap[paymentData.sign] === SIGN_SMS) {
						signViaSMS(error.response.data.errors);
						setIsButtonLoading(false);
						return;
					}
					setErrors(error.response.data);
					setIsButtonLoading(false);
				}
				break;
			}
			case 3: {
				if (signTypesMap[paymentData.sign] === SIGN_PHYSICAL) {
					try {
						setIsButtonLoading(true);
						const { data } = await signPayment(
							accountId,
							paymentData.id,
							null,
							file
						);
						setPaymentData(data);
						setIsButtonLoading(false);
						setSignStep(signStep + 1);
					} catch (error) {
						setErrors(error.response.data);
						setIsButtonLoading(false);
					}
					return;
				}
				if (signTypesMap[paymentData.sign] === SIGN_SMS) {
					setIsButtonLoading(true);
					try {
						await acceptPayment(accountId, paymentData.id);
						setIsButtonLoading(false);
						putResendSMSTime();
						signViaSMS();
					} catch (error) {
						setIsButtonLoading(false);
						signViaSMS();
					}
				}
				break;
			}
			case 4: {
				history.push('../reports?tab=invoice');
				setTimeout(() => {
					showToast({
						title: 'rod.reports_page.payment.toast.title',
						text: 'rod.reports_page.payment.toast.text',
						type: 'info',
					});
				}, 1000);
				break;
			}
			default: {
				setSignStep(signStep + 1);
			}
		}
	};

	const isDisabled = () => {
		if (isButtonLoading) {
			return true;
		}
		if (errors) {
			return true;
		}
		switch (signStep) {
			case 2: {
				return !docConfirmed;
			}
			case 3: {
				if (signTypesMap[paymentData.sign] !== SIGN_PHYSICAL) {
					return false;
				}
				return !!!file;
			}
			default: {
				return false;
			}
		}
	};

	const getButtonTitle = () => {
		switch (signStep) {
			case 1: {
				return 'rod.action.goto_sign_contract';
			}
			case 2: {
				return 'rod.action.confirm';
			}
			case 3: {
				if (signTypesMap[paymentData.sign] !== SIGN_PHYSICAL) {
					return 'rod.action.goto_sign_contract';
				}
				return 'rod.action.send_payment';
			}
			case 4: {
				return 'rod.action.go_to_invoices';
			}
			default: {
				return 'rod.action.goto_sign_contract';
			}
		}
	};

	if (isLoading && !paymentData) {
		return <Loading />;
	}

	const getErrorMessage = () => {
		if (errors?.errors?.file) {
			return (
				<FormattedHTMLMessage
					id={'rod.field.upload.error.sign_doc.file_type'}
				/>
			);
		}
		switch (errors?.errors?.code?.[0].rule) {
			case errorRules.RETRY:
				return <FormattedHTMLMessage id={'rod.error.code.retry'} />;

			case errorRules.FORBIDDEN:
				return <FormattedHTMLMessage id={'rod.error.code.forbidden'} />;

			default:
				return (
					<FormattedHTMLMessage
						id={`rod.error.${errors?.errors?.code?.[0].rule.rule}`}
					/>
				);
		}
	};

	const getPaymentDataToState = async () => {
		const { data } = await acceptPayment(accountId, paymentData.id);
		setPaymentData(data);
	}

	return (
		<section
			className={`${styles['sign-invoice__wrapper']} ${
				signStep === 2 ? styles['document-preview'] : ''
			} `}
		>
			<Col
				sm={signStep === 2 ? 12 : 10}
				offset={{ sm: signStep === 2 ? 0 : 1 }}
			>
				{signStep !== 2 ? (
					<Container fluid>
						<div>
							<Label black display="flex" font="--gilroy-Medium-40">
								<FormattedHTMLMessage
									id={
										signTypesMap[paymentData?.sign] === SIGN_PHYSICAL
											? 'rod.account.invoice-sign.title'
											: 'rod.account.invoice-sign.title_online'
									}
								/>
								{signTypesMap[paymentData?.sign] === SIGN_PHYSICAL && (
									<InfoButton
										onClick={showModal({
											text: (
												<FormattedHTMLMessage id="rod.account.invoice-sign.tooltip" />
											),
										})}
									/>
								)}
							</Label>
							<Label
								text={
									<FormattedHTMLMessage
										id={'rod.account.invoice-sign.description'}
									/>
								}
								black
								font="--gilroy-Medium-24"
							/>
							<div className={styles['sign-invoice__list']}>
								<SignListItem
									{...paymentData}
									showStatus
									showDownload={signStep > 2}
									withoutIndex
									showFiles
									date={
										new Date(paymentData?.signed_at).getTime() ||
										new Date().getTime()
									}
									showDate={signStep === 4}
									showHash={signStep === 4}
									hash={paymentData?.generated_url?.id}
									showUpload={
										signStep === 3 &&
										signTypesMap[paymentData?.sign] === SIGN_PHYSICAL
									}
									onDocumentUpload={onDocumentUpload}
									canRemoveFile={signStep === 3}
									errors={errors}
									onDocumentRemove={onDocumentRemove}
								/>
							</div>
						</div>
						<div className={styles.error_message}>
							{errors && getErrorMessage()}
						</div>
						<Button
							variant={'primary'}
							onClick={handleClick}
							disabled={isDisabled()}
						>
							<FormattedMessage id={getButtonTitle()} />
						</Button>
					</Container>
				) : (
					<>
						<DocumentToSign
							documentTitle={paymentData.title}
							generated_url={paymentData.generated_url}
							handleDocConfirm={(val) => setDocConfirmed(val)}
							onDocumentRenew={() => getPaymentDataToState()}
						>
							<>
								<div className={styles.error_message}>
									{errors && getErrorMessage()}
								</div>
								<Button
									variant={'primary'}
									onClick={handleClick}
									disabled={isDisabled()}
								>
									<FormattedMessage id={getButtonTitle()} />
								</Button>
							</>
						</DocumentToSign>
					</>
				)}
			</Col>
		</section>
	);
};

export default UserSign;
