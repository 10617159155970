// Core
import { FormattedMessage } from 'react-intl';
import { withUI, withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';
import { useState, useEffect } from 'react';
import { useHistory, Link, useRouteMatch } from 'react-router-dom';
import { Col, Row } from 'react-grid-system';

// UI
import { Loading } from 'components';
import { NoData } from 'components';
import { FaqAutosuggestInput } from 'components/Inputs';

// Styles
import styles from './FaqGeneral.module.css';

const FaqGeneral = (props) => {
	const lang = localStorage.getItem('lang');
	let { url } = useRouteMatch();

	const [loading, setLoading] = useState(false);
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	const [faqData, setFaqData] = useState([]);

	const history = useHistory();
	const {
		UIContext: {
			showTitle,
			hideTitle,
			setParentFaq,
			setFaqSearchResult,
			showBackNavi,
			hideBackNavi,
			isTitle,
		},
		rootContext: { getFaqList },
	} = props;

	useEffect(() => {
		history.push(`/faq`);
		showBackNavi();
		showTitle('rod.nav.link.faq');
		setParentFaq('/faq');

		return function cleanup() {
			hideTitle();
			hideBackNavi();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isTitle]);

	useEffect(() => {
		setLoading(true);
		getFaqList(lang).then((res) => {
			setFaqData(res.data.sort((a, b) => a.sort - b.sort));
			setLoading(false)
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang]);

	window.addEventListener('resize', () => {
		setScreenWidth(window.innerWidth);
	});

	const updateSearchResult = (result, searchText) => {
		setFaqSearchResult({
			text: searchText,
			data: Array.isArray(result) ? result : [result],
		});
		history.push(`/faq/search`);
	};

	return (
		<>
			<div className={styles.mainWrapper}>
				<div className={styles.search}>
					<FormattedMessage id={'rod.statistic.search'}>
						{(placeholder) => (
							<FaqAutosuggestInput
								className={styles.Input}
								required
								value={{ title: '' }}
								lang={lang}
								onChange={updateSearchResult}
							/>
						)}
					</FormattedMessage>
				</div>
				{loading ? (
					<Loading className={styles.loader} />
				) : (
					<>
						{faqData.length > 0 ? (
							faqData.map((item) => (
								<>
									<Row className={styles.subTitle__wrapper}>
										<Link to={`${url}/section/${item.slug}`}>
											<div className={styles.subTitle__text}>{item.title}</div>
										</Link>
									</Row>
									<div className={styles.section__wrapper}>
										{item.articles.map((subItem, index, array) => (
											<>
												{screenWidth >= 768 ? (
													(index + 1) % 2 !== 0 && (
														<Row className={styles.textRow}>
															{index % 2 === 0 && (
																<Col className={styles.columnLeft}>
																	<Link
																		to={`${url}/section/${item.slug}/article/${subItem.slug}`}
																	>
																		<span className={styles.articlesList}>
																			{subItem.heading}
																		</span>
																	</Link>
																</Col>
															)}
															{index + 1 < array.length &&
																(index + 1) % 2 !== 0 && (
																	<Col className={styles.columnRight}>
																		<Link
																			to={`${url}/section/${
																				item.slug
																			}/article/${array[index + 1].slug}`}
																		>
																			<span className={styles.articlesList}>
																				{array[index + 1].heading}
																			</span>
																		</Link>
																	</Col>
																)}
														</Row>
													)
												) : (
													<Row className={styles.textRow}>
														<Col className={styles.columnLeft}>
															<Link
																to={`${url}/section/${item.slug}/article/${subItem.slug}`}
															>
																{subItem.heading}
															</Link>
														</Col>
													</Row>
												)}
											</>
										))}
									</div>
								</>
							))
						) : (
							<NoData />
						)}
					</>
				)}
			</div>
		</>
	);
};
export default compose(withUI, withAuth, withRoot)(FaqGeneral);
