// Core
import React, { useState, useEffect, useContext } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { withAuth } from 'hocs';
import { compose } from 'recompose';
import { accounts } from 'services';
import { format } from 'date-fns';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { LangContext } from 'contexts/LangContext';

// Utils
import { correctedEventTitle } from './utils/correctedEventTitle';

// UI
import { NoData } from 'components';
import { Loading } from 'components';

// Styles
import s from './RepertoireReleaseLogs.module.css';

const RepertoireReleaseLogs = ({ releaseId, getInfo, ...props }) => {
	const { accountId } = useContext(AuthContext);
	const { lang } = useContext(LangContext);
	const [loading, setLoading] = useState(true);
	const [logs, setLogs] = useState([]);

	useEffect(() => {
		setLoading(true);
		accounts
			.getReleaseDeliveriesLogs(accountId, releaseId)
			.then((res) => {
				const sortedLogs = res.data.data.sort(
					(a, b) => new Date(b.created_date) - new Date(a.created_date)
				);
				setLogs(sortedLogs);
			})
			.catch((err) => console.error(err))
			.finally(() => setLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{loading ? (
				<Loading className={s.loader} />
			) : logs?.length > 0 ? (
				<div className={s.body}>
					<div className={`${s.table__header} ${s.table}`}>
						<FormattedHTMLMessage id={'rod.for_all.event'} />
						<FormattedHTMLMessage id={'rod.for_all.date'} />
					</div>
					<ul className={s.main}>
						{logs.length > 0 && (
							<div className={s.logs}>
								{logs
									.filter((item) => item.event_title_ru && item.event_title_en)
									.map((item) => {
										const created_date = format(
											new Date(item.created_date),
											'dd.MM.yyyy, HH:mm'
										);
										return (
											<li
												key={item.recipient_id}
												className={`${s.table__item} ${s.table}`}
											>
												<span>
													{lang === 'ru'
														? correctedEventTitle(item.event_title_ru)
														: correctedEventTitle(item.event_title_en)}
												</span>
												<span>{created_date}</span>
											</li>
										);
									})}
							</div>
						)}
					</ul>
				</div>
			) : (
				<NoData />
			)}
		</>
	);
};
export default compose(withAuth)(RepertoireReleaseLogs);
