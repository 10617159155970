// Core
import { FormattedMessage } from 'react-intl';

// Data
import { narrative } from './narrative';

// UI
import LyricWrapper from './LyricWrapper/LyricWrapper';
import { FormInput } from 'components/Form/FormInput';
import InstrumentalTrackSwitcher from 'components/InstrumentalTrackSwitcher/InstrumentalTrackSwitcher';
import { NewHelpInfo } from 'components';
import { Divider } from '@mui/material';

// Styles
import s from './LyricsForm.module.css';

const LyricsForm = ({
	errors,
	changeField,
	selectPerson,
	languages,
	recordingSave,
	handleInstrumental,
	disableSaveBtn,
}) => {
	return (
		<div className={s.form}>
			<div className={s.formTitleWrapper}>
				<span className={s.form__title} id="general">
					<FormattedMessage id={'rod.release.create.step.lyrics.main.title'} />
				</span>
				<InstrumentalTrackSwitcher
					data={selectPerson}
					handler={handleInstrumental}
				/>
			</div>
			<div className={s.helpInfo}>
				<NewHelpInfo text="rod.lyrics.help" />
			</div>

			{!selectPerson?.is_instrumental && (
				<>
					<div className={`${s.form__section} ${s.first__section}`}>
						<div className={s.input_leftCol}>
							<FormInput
								type="muiInputSelect"
								name={'language'}
								onChange={changeField}
								errors={errors}
								data={selectPerson}
								label={
									<FormattedMessage
										id={'rod.release.create.step.recording_info.label.lang'}
									/>
								}
								items={languages}
								languages={true}
								required
							/>
							{errors && Object.keys(errors).includes('language') && (
								<span className={s.Helper}>
									{
										<FormattedMessage
											id={`rod.release_create.error.required`}
										/>
									}
								</span>
							)}
						</div>
						<div className={s.input_rightCol}>
							<FormInput
								className={s.narrative}
								type={'MuiSelectNarrative'}
								name={'parental_warning_type'}
								onChange={changeField}
								errors={errors}
								data={selectPerson}
								label={
									<FormattedMessage
										id={
											'rod.release.create.step.recording_info.label.non_narrative'
										}
									/>
								}
								items={narrative}
								required
							/>
							{errors &&
								Object.keys(errors).includes('parental_warning_type') && (
									<span className={s.Helper}>
										{
											<FormattedMessage
												id={`rod.release_create.error.required`}
											/>
										}
									</span>
								)}
						</div>
					</div>
					<LyricWrapper
						changeField={changeField}
						errors={errors}
						selectPerson={selectPerson}
					/>
				</>
			)}

			<Divider
				sx={{
					borderWidth: '1.5px',
					borderColor: 'var(--color-grey)',
					marginBottom: '16px',
				}}
			/>
			<button
				disabled={disableSaveBtn}
				className={s.form__submit}
				style={
					disableSaveBtn ? { opacity: 0.2, cursor: 'initial' } : { opacity: 1 }
				}
				onClick={recordingSave}
			>
				<FormattedMessage
					id={'rod.release.create.step.recording_info.form_save'}
				/>
			</button>
		</div>
	);
};

export default LyricsForm;
