import React from 'react';
import {
	TextFieldInput,
	AutocompleteInput,
} from 'material-design/MuiStyled/MuiStyled';
import { makeStyles, createStyles } from '@mui/styles';
import { Paper } from '@mui/material';
import { Add } from 'material-design/svg';
import styles from './MuiAutocompleteAuthor.module.css';

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			'& .MuiAutocomplete-listbox': {
				background: '#fff',
				margin: 0,
				padding: 0,
				letterSpacing: '0.15px',
				'& .MuiAutocomplete-groupLabel': {
					padding: '12px 16px 0',
					font: 'var(--gilroy-Medium-12-20)',
					color: '#888888',
				},
				'& .MuiAutocomplete-groupUl': {
					borderBottom: '1px solid #F0F0F0',
					'& li': {
						padding: '12px 16px',
						font: 'var(--gilroy-Medium-16-150)',
						color: '#1E1E1E',
						'&:hover': {
							backgroundColor: '#F0F0F0;',
						},
					},
				},
			},
		},
		list__button: {
			display: 'flex',
			width: '100%',
			alignItems: 'center',
			padding: '12px 16px',
			'& span': {
				color: 'var(--color-dark-grey)',
				display: 'inline-block',
				marginLeft: '12px',
				font: 'var(--gilroy-Medium-14-24)',
				textDecoration: 'underline',
			},
		},
	})
);

const PaperComponentCustom = (options) => {
	const classes = useStyles();
	const { containerProps, children } = options;

	return (
		<Paper className={classes.root} {...containerProps}>
			{children}
			<div
				className={classes.list__button}
				onMouseDown={() => alert('clicked')}
			>
				<Add length={24} color="#000000" />
				<span>Добавить нового автора</span>
			</div>
		</Paper>
	);
};

const MuiAutocompleteAuthor = ({ onChange, label, value }) => {
	const items = [
		{ type: 'Часто используемые', title: 'Tyler the Creator' },
		{ type: 'Часто используемые', title: 'Eminem' },
		{ type: 'Часто используемые', title: 'Slim Shady' },
		{ type: 'Прочее', title: 'Marshall' },
		{ type: 'Прочее', title: 'Marshall Bruce' },
		{ type: 'Прочее', title: 'Marshall Bruce Mathers III' },
	];

	return (
		<div className={styles.autoAuthor_wrapper}>
			<AutocompleteInput
				options={items}
				onChange={(_, obj) => onChange(obj?.title || '')}
				groupBy={(option) => option.type}
				PaperComponent={PaperComponentCustom}
				disablePortal
				renderTags={() => null}
				renderOption={(option, item) => (
					<li key={item} {...option}>
						{item.title}
					</li>
				)}
				value={value ? { title: value } : null}
				getOptionLabel={(option) => option.title}
				renderInput={(params) => (
					<TextFieldInput {...params} label={label} variant="standard" />
				)}
			/>
			{value.length > 2 && (
				<span className={styles.autoTag}>+{value.length - 2}</span>
			)}
		</div>
	);
};

export default MuiAutocompleteAuthor;
