// Core
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

// Context
import { UIContext } from 'contexts/UIContext';

// UI
import trash from 'images/trash.svg';

// Types
import modalTypes from 'constants/modalTypes';

// Styles
import styles from './ArticleHeader.module.css';

const ArticleHeader = ({ isEdit, deleteArticleHandler }) => {
	const { showModal } = useContext(UIContext);

	return (
		<div className={styles.createArticle__header}>
			<span>
				<FormattedMessage
					id={isEdit ? 'rod.faq.edit_article' : 'rod.faq.create_article'}
				/>
			</span>
			{isEdit && (
				<button
					className={styles.deleteBtn}
					onClick={showModal(
						{
							title: <FormattedMessage id="rod.article.modal.delete.title" />,
							text: <FormattedMessage id="rod.article.modal.delete.text" />,
							onAction: () => {
								deleteArticleHandler();
							},
							confirmBtnTxt: <FormattedMessage id={'rod.modal.yes_delete'} />,
							declineBtnTxt: <FormattedMessage id={'rod.modal.cancel'} />,
						},
						modalTypes.FULL_MODAL
					)}
				>
					<img src={trash} alt="trash" />
					<span>
						<FormattedMessage id={'rod.article.delete'} />
					</span>
				</button>
			)}
		</div>
	);
};

export default ArticleHeader;
