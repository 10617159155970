import React, { useState, useEffect } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { withUI, withRoot, withLang } from 'hocs';
import { Col, Row } from 'react-grid-system';
import { Loading, NoData } from 'components';
import styles from './ReportsUserPage.module.css';
import { compose } from 'recompose';
import download from 'images/download.svg';
import { dateFormatEditor } from 'logic';
import { Pagination } from 'material-design/components';
import { InfoButton } from 'components/Buttons';
import { useLocation } from 'react-router-dom';

const ReportsUserPage = (props) => {
	const [loading, setLoading] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [page, setPage] = useState(1);
	const [total, setTotal] = useState(0);
	const [reports, setReports] = useState([]);
	const [nextReportDate, setNextReportDate] = useState('');
	const [royaltiToPay, setRoyaltiToPay] = useState('');

	const location = useLocation();

	const {
		UIContext: {
			showTitle,
			showBackNavi,
			hideBackNavi,
			hideTitle,
			showModal,
			isPrivatePolicyShow,
		},
		rootContext: {
			getAccountReports,
			aboutReportsDownloadData,
			getAdminReportsNextDate,
		},
		langContext: { lang },
	} = props;
	const accountId = localStorage.getItem('accountId');

	useEffect(() => {
		showTitle('rod.navbar.reports');
		showBackNavi();
		setLoading(true);

		getAccountReports(accountId, page).then((res) => {
			if (res.data.length) {
				setTotal(res.total);
				getRoyaltiToPay(res.data);

				// const data = res.data.map((item) => {
				// 	const time = item.date_publication.match(/\d{1,2}:\d{2}/)[0];
				// 	const date = item.date_publication
				// 		.slice(0, item.date_publication.indexOf('T'))
				// 		.replace(/(\d{4})-(\d{2})-(\d{2})/gm, '$3.$2.$1');
				// 	item.date_publication = time + ', ' + date;

				// 	return item;
				// });

				setReports(res.data);
			}
			setLoading(false);
		});

		getAdminReportsNextDate().then((res) => {
			if (res) {
				const date = dateFormatEditor(res.next_date, 'dmy', '.');
				setNextReportDate(date);
			}
		});

		return function cleanup() {
			hideBackNavi();
			hideTitle();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	const getRoyaltiToPay = (allReports) => {
		if (allReports.length) {
			allReports = allReports.sort(
				(a, b) => new Date(b.created_at) - new Date(a.created_at)
			);

			setRoyaltiToPay(
				Array.isArray(allReports) &&
					allReports.length &&
					allReports[0].royalties
					? allReports[0].royalties
					: null
			);
		}
	};

	const delay = (milliseconds) =>
		new Promise((resolve) => {
			setTimeout(resolve, milliseconds);
		});

	const handleDownloadReport = async (report) => {
		aboutReportsDownloadData(accountId, report.report_archives);

		if (report.files.length) {
			report.files.forEach(async (item, index) => {
				await delay(index * 1000);

				window.open(item, '_parent');
			});
		}
	};

	return (
		<div className={styles.mainWrapper}>
			<>
				{loading ? (
					<Loading className={styles.loader} />
				) : (
					<>
						<div className={styles.infoWrapper}>
							<div className={styles.infoRoyalty}>
								<div className={styles.infoTitle}>
									{process.env.REACT_APP_CLIENT === 'broma' ? (
										<>
											<FormattedHTMLMessage
												id={
													'rod.field.admin.reports.royalty_to_pay.whithout_partners'
												}
											/>

											<InfoButton
												className={styles.form__fragment_help_btn}
												onClick={showModal({
													text: document.domain.includes('ru') ? (
														<FormattedHTMLMessage
															id={
																'rod.user_reports.royalty_to_pay.ru.fragment.help'
															}
														/>
													) : (
														<FormattedHTMLMessage
															id={
																'rod.user_reports.royalty_to_pay.com.fragment.help'
															}
														/>
													),
												})}
											></InfoButton>
										</>
									) : (
										<FormattedHTMLMessage
											id={'rod.field.admin.reports.royalty_to_pay'}
										/>
									)}
								</div>
								<div className={styles.infoValue}>
									{reports.length && royaltiToPay
										? `${royaltiToPay.replace(
												/(\d)(?=(\d{3})+(?!\d))/g,
												`$1\u00A0`
										  )} ${reports[0].currency}`
										: '-'}
								</div>
							</div>
							<div className={styles.infoNextReport}>
								<div className={styles.infoTitle}>
									<FormattedHTMLMessage
										id={'rod.field.admin.reports.next_report_date'}
									/>
								</div>
								<div className={styles.infoValue}>
									{nextReportDate ? nextReportDate : '-'}
								</div>
							</div>
						</div>
						{reports.length ? (
							<>
								<div className={styles.RepertoireSongInfo__mainInfoTable}>
									<Row className={styles.row_header}>
										<Col width={'25%'}>
											<FormattedHTMLMessage
												id={'rod.field.admin.reports.publishing_date'}
											/>
										</Col>
										<Col width={'25%'}>
											<FormattedHTMLMessage id={'rod.field.admin.title'} />
										</Col>
										<Col width={'16%'}>
											<FormattedHTMLMessage
												id={'rod.field.admin.reports.sum'}
											/>
										</Col>
										<Col width={'17%'}>
											<FormattedHTMLMessage
												id={'rod.field.admin.reports.currency'}
											/>
										</Col>
										<Col width={'17%'}>
											<FormattedHTMLMessage id={'rod.field.admin.actions'} />
										</Col>
									</Row>

									<div className={styles.RepertoireMainInfoTable__body}>
										<ul>
											{reports.map((item, index) => (
												<li className={styles.articlesListItem}>
													<Row className={styles.row_data}>
														<Row className={styles.row_title}>
															<Col width={'25%'} className={styles.shell}>
																{item.date_publication &&
																	dateFormatEditor(
																		item.date_publication,
																		'dmy',
																		'.'
																	)}
															</Col>
															<Col width={'25%'} className={styles.heading}>
																{lang === 'ru' ? item.heading : item.heading_en}
															</Col>
															<Col width={'16%'} className={styles.shell}>
																{item.royalties
																	? item.royalties.replace(
																			/(\d)(?=(\d{3})+(?!\d))/g,
																			`$1\u00A0`
																	  )
																	: '-'}
															</Col>
															<Col width={'17%'} className={styles.shell}>
																{item.currency}
															</Col>
															<Col width={'17%'}>
																<div
																	className={styles.action}
																	onClick={() => handleDownloadReport(item)}
																>
																	<img
																		src={download}
																		alt=""
																		// style={{ paddingBottom: '11px' }}
																	/>
																	<div
																		className={styles.actionTitle}
																		// style={{ paddingTop: '1px' }}
																	>
																		<FormattedHTMLMessage
																			id={'rod.action.download'}
																		/>
																	</div>
																</div>
															</Col>
														</Row>
													</Row>
												</li>
											))}
										</ul>
									</div>
								</div>
							</>
						) : (
							<NoData />
						)}
					</>
				)}
				{total > 10 && !loading && (
					<Pagination
						page={page}
						total={total}
						paginate={(pageNumber) => {
							setPage(pageNumber);
						}}
						items_on_page={10}
					/>
				)}
			</>
		</div>
	);
};

export default compose(withUI, withRoot, withLang)(ReportsUserPage);
