// Utils
import { fm } from 'utils';
import { formatDate } from '../utils/formatDate';

// UI
import { Button } from 'components/Buttons';

// Styles
import styles from './VerificationSection.module.css';

const VerificationSection = ({
	status,
	isVerified,
	verifySubmit,
	emailVerificationCode,
	smsVerificationData,
	isVerificationError,
}) => {
	const formattedDate = smsVerificationData?.expired_at?.date
		? formatDate(smsVerificationData.expired_at.date)
		: formatDate(smsVerificationData?.expired_at);

	return (
		<div className={styles.verifyWrapper}>
			<Button
				primary
				text={
					status === 'confirm_phone'
						? fm('rod.admin.signup.sms_verify')
						: fm('rod.admin.signup.email_verify')
				}
				disabled={isVerified}
				onClick={() => {
					verifySubmit(status);
				}}
			/>
			{emailVerificationCode && (
				<div className={styles.verifyText}>
					{fm('rod.admin.signup.email_code')}&nbsp;
					<span style={{ fontWeight: 'bold' }}>{emailVerificationCode}</span>
				</div>
			)}
			{smsVerificationData && (
				<div className={styles.verifyText}>
					{fm('rod.admin.signup.sms_code')}&nbsp;
					<span style={{ fontWeight: 'bold' }}>
						{`${smsVerificationData?.code}. `}
					</span>
					{fm('rod.admin.signup.sms_valid_until')}&nbsp;
					<span style={{ fontWeight: 'bold' }}>{formattedDate}</span>
				</div>
			)}

			{isVerificationError && (
				<div className={styles.verifyText}>{fm('rod.admin.signup.err422')}</div>
			)}
		</div>
	);
};

export default VerificationSection;
