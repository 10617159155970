/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

import { Label } from 'components/Labels/Label';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Condition } from './Condition';
import styles from './Conditions.module.css';
import { UseType } from './UseType';

class Conditions extends Component {
	static propTypes = {
		onTerritoryEdit: PropTypes.func,
		onChange: PropTypes.func,
		conditions: PropTypes.object,
		isAdditional: PropTypes.bool,
	};

	static defaultType = {};
	isAllTypesHide = [];

	editHandler = ({ useType, mediaType }) => () => {
		this.props.isAdditional
			? this.props.history.push({
					pathname: `/treaties/form/${this.props.match.params.id}/${useType}/${mediaType}/excluded`,
					search: '',
					state: {
						isAdditional: this.props.isAdditional,
					},
			  })
			: this.props.history.push(
					`/accounts/${this.props.match.params.id}/contracts/add/${useType}/${mediaType}/excluded`
			  );
	};

	changeHandler = (options) => () => {
		if (typeof this.props.onChange === 'function') {
			this.props.onChange(options);
		}
	};

	renderUseType(keyword) {
		const useType = this.props.conditions[keyword];

		let isLyricHide = false;
		let isRecordingHide = false;
		let isCompositionHide = false;
		let isAllPropertiesChecked = false;

		if (!this.props.isAdditional) {
			if (useType.resources.recording && useType.resources.composition) {
				const recordingEnabled = useType.resources.recording.enabled;
				const compositionEnabled = useType.resources.composition.enabled;

				if (recordingEnabled || compositionEnabled) {
					useType.enabled = true;
				}
			}
		}

		if (this.props.isAdditional) {
			isLyricHide =
				useType.resources &&
				!Object.keys(useType.resources).length &&
				useType.signed_properties
					? true
					: false;

			if (!isLyricHide) this.isAllTypesHide.push({ [keyword]: isLyricHide });

			isRecordingHide =
				useType.resources &&
				Object.keys(useType.resources).length &&
				useType.resources.recording &&
				useType.resources.recording.signed_properties
					? true
					: false;

			if (!isRecordingHide)
				this.isAllTypesHide.push({
					[keyword]: isRecordingHide,
				});

			isCompositionHide =
				useType.resources &&
				Object.keys(useType.resources).length &&
				useType.resources.composition &&
				useType.resources.composition.signed_properties
					? true
					: false;

			if (!isCompositionHide)
				this.isAllTypesHide.push({
					[keyword]: isCompositionHide,
				});

			if (keyword === 'lyrics') {
				isLyricHide
					? (isAllPropertiesChecked = true)
					: (isAllPropertiesChecked = false);
			} else {
				if (useType.resources && Object.keys(useType.resources).length) {
					switch (Object.keys(useType.resources).length) {
						case 2:
							isAllPropertiesChecked =
								useType.resources.recording &&
								useType.resources.recording.signed_properties &&
								useType.resources.composition &&
								useType.resources.composition.signed_properties
									? true
									: false;
							break;
						case 1:
							isAllPropertiesChecked =
								(useType.resources.recording &&
									useType.resources.recording.signed_properties) ||
								(useType.resources.composition &&
									useType.resources.composition.signed_properties)
									? true
									: false;
							break;

						default:
							isAllPropertiesChecked = false;
							break;
					}
				}
			}
		}

		return (
			<UseType
				title={
					<FormattedMessage id={`rod.contracts.use_types.${keyword}.title`} />
				}
				checked={
					isRecordingHide ||
					isCompositionHide ||
					(keyword === 'lyrics' && isLyricHide)
						? false
						: useType.enabled
				}
				onSelect={this.changeHandler({ useType: keyword })}
				isAddService={this.props.isAddService}
				isAllPropertiesChecked={isAllPropertiesChecked}
				conditionType={keyword}
				hiddenCheckBox={
					isRecordingHide ||
					isCompositionHide ||
					(keyword === 'lyrics' && isLyricHide)
				}
			>
				{useType.resources.recording && (
					<Condition
						title={
							<FormattedMessage
								id={`rod.contracts.media_types.recording.title`}
							/>
						}
						included={useType.resources.recording.territories_including}
						excluded={useType.resources.recording.territories_excluding}
						checked={
							isRecordingHide ? false : useType.resources.recording.enabled
						}
						onSelect={this.changeHandler({
							useType: keyword,
							mediaType: 'recording',
						})}
						onEditClick={this.editHandler({
							useType: keyword,
							mediaType: 'recording',
						})}
						conditionType={keyword}
						conditionMediaType={'recording'}
						hiddenCheckBox={isRecordingHide}
						isAddService={this.props.isAddService}
					/>
				)}

				{useType.resources.composition && (
					<Condition
						title={
							<FormattedMessage
								id={`rod.contracts.media_types.composition.title`}
							/>
						}
						included={useType.resources.composition.territories_including}
						excluded={useType.resources.composition.territories_excluding}
						checked={
							isCompositionHide ? false : useType.resources.composition.enabled
						}
						onSelect={this.changeHandler({
							useType: keyword,
							mediaType: 'composition',
						})}
						onEditClick={this.editHandler({
							useType: keyword,
							mediaType: 'composition',
						})}
						conditionType={keyword}
						conditionMediaType={'composition'}
						hiddenCheckBox={isCompositionHide}
						isAddService={this.props.isAddService}
					/>
				)}
			</UseType>
		);
	}

	render() {
		this.props.getActiveTypes(this.isAllTypesHide);
		this.isAllTypesHide = [];

		return (
			<React.Fragment>
				<div className={styles.Header}>
					<Label font="--gilroy-Medium-40" black>
						<FormattedMessage id={`rod.contracts.add.conditions.header`} />
					</Label>
					<Label font="--gilroy-Medium-24" black className="sub">
						<FormattedMessage id={`rod.contracts.add.conditions.subheader`} />
					</Label>
				</div>
				{this.props.conditions.digital && this.renderUseType('digital')}
				{this.props.conditions.public_performance &&
					this.renderUseType('public_performance')}
				{this.props.conditions.synchronization &&
					this.renderUseType('synchronization')}
				{this.props.conditions.lyrics && this.renderUseType('lyrics')}
			</React.Fragment>
		);
	}
}

export default compose(withRouter)(Conditions);
