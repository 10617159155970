// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const radioItems = [
	{
		text: <FormattedMessage id={'rod.performance_work.yes'} />,
		value: 'yes',
	},
	{
		text: <FormattedMessage id={'rod.performance_work.no'} />,
		value: 'no',
	},
];
