// Core
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { PoseGroup } from 'react-pose';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { RootContext } from 'contexts/RootContext';

// UI
import { Modal, Shade } from './Modal/Modal';
import BaseModal from './BaseModal';
import NotifyItem from './NotifyItem/NotifyItem';
import { Col, Container, Row } from 'components/Grid';

// Icons
import close from 'images/close.svg';
import bell from 'images/bell_dash.svg';

// Styles
import styles from './Notify.module.css';

const Notify = ({ active, setActive }) => {
	const authContext = useContext(AuthContext);
	const { getUserNotifications, putReadUserNotification } = useContext(
		RootContext
	);
	const accountId = authContext.user?.account_id;

	const [viewedCount, setViewedCount] = useState(0);
	const [notifications, setNotifycations] = useState([]);
	const [isVisible, setIsVisible] = useState(false);
	const [showInfo, setShowInfo] = useState(false);
	const [currentItem, setCurrentItem] = useState({});

	useEffect(() => {
		if (accountId) {
			getUserNotifications(accountId).then((res) => {
				const updatedData = res.map((item) => {
					const time = item.created_at.match(/\d{1,2}:\d{2}/)[0];
					const date = item.created_at
						.slice(0, item.created_at.indexOf('T'))
						.replace(/(\d{4})-(\d{2})-(\d{2})/gm, '$3.$2.$1');
					item.created_at = time + ', ' + date;

					return item;
				});
				setNotifycations(updatedData);
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setIsVisible(active);
	}, [active]);

	useEffect(() => {
		if (notifications) {
			const newViewedCount = notifications.reduce((a, n) => {
				if (!n.read_user) return a + 1;
				return a;
			}, 0);

			setViewedCount(newViewedCount);
		}
	}, [notifications]);

	const handleReadAll = () => {
		let readNotifications = [];

		notifications
			.filter((el) => !el.read_user)
			.forEach((item) => {
				putReadUserNotification(accountId, item.id).then((res) => {
					readNotifications.push({ ...item, read_user: true });
					setViewedCount(0);
				});
			});

		const intervalId = setInterval(() => {
			if (readNotifications.length === notifications.length) {
				setNotifycations(readNotifications);
				setViewedCount(0);
				clearInterval(intervalId);
			}
		}, 500);
	};

	const handleReadOne = (selectedItemId) => {
		notifications.find((item) => item.id === selectedItemId)[
			'read_user'
		] = true;

		putReadUserNotification(accountId, selectedItemId).then((res) => {
			setViewedCount((prev) => prev - 1);
		});
	};

	const willUnmount = (e) => {
		setIsVisible(false);
		setTimeout(() => {
			setActive(false);
		}, 300);
	};

	const backHandling = () => {
		setShowInfo(false);
	};

	const htmlParsing = (text) => {
		return parse(DOMPurify.sanitize(text));
	};

	const showInfoHandling = (id) => {
		setShowInfo(true);
		setCurrentItem(notifications.find((item) => item.id === id));
	};

	return (
		<BaseModal className={styles.Modal}>
			<PoseGroup>
				{isVisible && [
					<Shade key="shade" className={styles.Overlay} />,
					<Modal
						key="modal"
						style={{
							marginLeft: 'auto',
						}}
					>
						<Container fluid>
							<Row>
								<Col
									md={12 - 3}
									onClick={(e) => {
										willUnmount(e);
									}}
								/>
								{!showInfo && (
									<Col md={3} className={styles.LeftSide}>
										<div className={styles.notifyHeader}>
											<div className={styles.notifyTitle}>
												<span className={styles.notifyTitle__titleWrapper}>
													<span className={styles.notifyTitle__title}>
														<FormattedMessage id={'rod.notification.title'} />
													</span>
													{viewedCount ? (
														<span className={styles.notifyTitle__notesCount}>
															{viewedCount}
														</span>
													) : (
														''
													)}
												</span>
												<button
													type="button"
													className={styles.close__btn}
													onClick={() => willUnmount()}
													style={{ cursor: 'pointer' }}
												>
													<img src={close} alt="" />
												</button>
											</div>
											<button
												onClick={() => handleReadAll()}
												className={styles.notifyHeader__flagAll}
												style={viewedCount ? {} : { display: 'none' }}
											>
												<FormattedMessage id={'rod.notification.mark_all'} />
											</button>
										</div>
										{notifications.length ? (
											<div>
												{notifications.map((item) => (
													<div
														className={styles.itemWrapper}
														onClick={() => {
															showInfoHandling(item.id);
														}}
													>
														<NotifyItem
															key={item.id}
															item={item}
															viewedCount={viewedCount}
														/>
													</div>
												))}
											</div>
										) : (
											<div className={styles.notifyEmpty}>
												<img
													className={styles.notifyEmpty__img}
													src={bell}
													alt=""
												/>
												<p className={styles.notifyEmpty__text}>
													<FormattedMessage id={'rod.notification.empty'} />
												</p>
											</div>
										)}
									</Col>
								)}
								{showInfo && (
									<Col md={3} className={styles.LeftSide}>
										<div className={styles.notifyHeader}>
											<div className={styles.notifyTitle}>
												<div
													className={styles.notifyTitle__back}
													onClick={backHandling}
												>
													<svg
														width="41"
														height="15"
														viewBox="0 0 41 15"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M0.292893 6.79289C-0.0976295 7.18341 -0.0976296 7.81658 0.292892 8.2071L6.65685 14.5711C7.04738 14.9616 7.68054 14.9616 8.07107 14.5711C8.46159 14.1805 8.46159 13.5474 8.07107 13.1569L2.41422 7.5L8.07107 1.84314C8.46159 1.45262 8.46159 0.819454 8.07107 0.428929C7.68054 0.0384049 7.04738 0.0384048 6.65685 0.428929L0.292893 6.79289ZM41 6.5L1 6.5L1 8.5L41 8.5L41 6.5Z"
															fill="white"
														/>
													</svg>
													<span style={{ marginLeft: '10px' }}>
														<FormattedMessage id={'rod.action.back'} />
													</span>
												</div>
												<button
													type="button"
													className={styles.close__btn}
													onClick={() => willUnmount()}
													style={{ cursor: 'pointer' }}
												>
													<img src={close} alt="" />
												</button>
											</div>
										</div>
										{Object.keys(currentItem).length > 0 && (
											<div className={styles.notifyMain}>
												<div className={styles.itemHeading}>
													{currentItem.heading}
												</div>
												<span
													onClick={() => handleReadOne(currentItem.id)}
													className={styles.notifyHeader__flagOne}
													style={
														!currentItem['read_user']
															? {}
															: { visibility: 'hidden' }
													}
												>
													<FormattedMessage id={'rod.notification.mark_one'} />
												</span>
												<div className={styles.itemText}>
													{htmlParsing(currentItem.text)}
												</div>
												<div className={styles.itemDate}>
													{currentItem.created_at}
												</div>
											</div>
										)}
									</Col>
								)}
							</Row>
						</Container>
					</Modal>,
				]}
			</PoseGroup>
		</BaseModal>
	);
};
export default Notify;
