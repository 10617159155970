/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 09/07/2020, 13:55
 */
// eslint-disable-next-line
const AccountTypes = {
	PERSONAL: 'individual',
	PERSONAL_ENTREPRENEUR: 'sole_proprietor',
	COMPANY: 'legal_entity',
};

export default AccountTypes;
