// Core
import { FormattedMessage } from 'react-intl';

// Icons
import release_nota from 'images/release_nota.svg';

// Styles
import styles from './Header.module.css';

const Header = ({ title }) => {
	return (
		<div className={styles.header}>
			<img src={release_nota} alt="" />
			<span className={styles.headerTitle__type}>
				<FormattedMessage id={'rod.composition'} />
			</span>
			<span className={styles.headerTitle__name} title={title ?? '-'}>{title ?? '-'}</span>
		</div>
	);
};

export default Header;
