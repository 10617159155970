// Core
import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

// UI
import { Col, Row, Container } from 'react-grid-system';
import PlayActive from './PlayActive/PlayActive';
import PlayInactive from './PlayInactive/PlayInactive';

// Icons
import { Dynamic } from 'material-design/svg';

// Utils
import { showStatus } from '../utils/showStatus';

// Styles
import styles from '../RepertoireRecordingSection.module.css';

const RecordingLine = ({ item, index }) => {
	return (
		<>
			<div
				className={
					showStatus(item.statuses) === 'disputed'
						? `${styles.repertoire__phonogramItemError} ${styles.adaptive__item}`
						: `${styles.adaptive__item}`
				}
			>
				<Container fluid>
					<Row>
						<Col xs={4}>
							<div>
								<Dynamic className={styles.adaptive__songItemLogo} />
							</div>
						</Col>
						<Col xs={8}>
							<div className={styles.adaptive__itemText}>
								<span title={item.title}>{item.title}</span>
							</div>
						</Col>
					</Row>

					<Row>
						<Col xs={4}>
							<div className={styles.adaptive__itemTitle}>
								<FormattedHTMLMessage id={'rod.for_all.subtitle'} />
							</div>
							<div className={styles.adaptive__itemTitle}>
								<FormattedHTMLMessage id={'rod.for_all.performers'} />
							</div>
							<div className={styles.adaptive__itemTitle}>
								<FormattedHTMLMessage id={'rod.for_all.status'} />
							</div>
						</Col>
						<Col xs={8}>
							<div className={styles.adaptive__itemText}>
								{item.subtitle ? (
									<span title={item.subtitle}>{item.subtitle}</span>
								) : (
									<span>-</span>
								)}
							</div>
							<div className={styles.adaptive__itemText}>
								{item.performers.length ? (
									<span title={item.performers.join(', ')}>
										{item.performers.join(', ')}
									</span>
								) : (
									<span>-</span>
								)}
							</div>
							<div className={styles.adaptive__itemText}>
								<span>
									<FormattedMessage
										id={`rod.repertoire.${showStatus(item.statuses)}`}
									/>
								</span>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			<div
				className={
					showStatus(item.statuses) === 'disputed'
						? `${styles.repertoire__phonogramItemError} ${styles.song__table} ${styles.adaptive__songTable}`
						: `${styles.song__table} ${styles.adaptive__songTable}`
				}
			>
				<Dynamic className={styles.repertoire__phonogramItemLogo} />
				<div>
					<span title={item.title}>{item.title}</span>
				</div>
				<div>
					{item.subtitle ? (
						<span title={item.subtitle}>{item.subtitle}</span>
					) : (
						<span>-</span>
					)}
				</div>
				<div>
					{item.performers.length ? (
						<span title={item.performers.join(', ')}>
							{item.performers.join(', ')}
						</span>
					) : (
						<span>-</span>
					)}
				</div>
				<div>
					<span>
						<FormattedMessage
							id={`rod.repertoire.${showStatus(item.statuses)}`}
						/>
					</span>
				</div>

				{item.audio.data.status === 'ready' ? (
					<PlayActive item={item} index={index} />
				) : (
					<PlayInactive />
				)}
			</div>
		</>
	);
};

export default RecordingLine;
