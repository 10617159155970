//******************************/
// SPACES SENSITIVE IN BRACKETS!
//******************************/

let partner, platform;
switch (process.env.REACT_APP_CLIENT) {
	case 'broma':
		partner = {
			title: ' Broma&nbsp;16',
			title_space: ' Broma 16',
			title_solid: ' Broma16',
			title_rod_company: 'ROD Broma 16',
		};
		platform = {
			title: ' Heaven&nbsp;11',
			title_space: 'Heaven 11',
			title_solid: 'Heaven11',
		};
		break;
	case 'hitmaker':
		partner = {
			title: ' Hitmaker&nbsp;Music',
			title_space: ' Hitmaker Music',
			title_solid: ' Hitmaker Music',
			title_rod_company: 'Hitmaker Music',
		};
		platform = {
			title: '', //' Heaven&nbsp;11',
			title_space: '', //'Heaven 11',
			title_solid: '', //'Heaven11',
		};
		break;
	case 'dgtal':
		partner = {
			title: ' DGTal&nbsp;Solutions',
			title_space: ' DGTal solutions',
			title_solid: ' DGTal Solutions',
			title_rod_company: 'DGTal Solutions',
		};
		platform = {
			title: ' Heaven&nbsp;11',
			title_space: 'Heaven 11',
			title_solid: 'Heaven11',
		};
		break;
	case 'mts':
		partner = {
			title: ' MTS&nbsp;Label',
			title_space: ' MTS Label',
			title_solid: ' MTS Label',
			title_rod_company: 'MTS Label',
		};
		platform = {
			title: ' Heaven&nbsp;11',
			title_space: 'Heaven 11',
			title_solid: 'Heaven11',
		};
		break;
	default:
		partner = {
			title: ' Broma&nbsp;16',
			title_space: ' Broma 16',
			title_solid: ' Broma16',
			title_rod_company: ' ROD Broma 16',
		};
		platform = {
			title: ' Heaven&nbsp;11',
			title_space: 'Heaven 11',
			title_solid: 'Heaven11',
		};
}

const compound =
	process.env.REACT_APP_CLIENT === 'broma'
		? {
				title: `${partner.title} or${platform.title}`,
				title_with: ` with${partner.title} or${platform.title}`,
		  }
		: process.env.REACT_APP_CLIENT === 'hitmaker'
		? {
				title: `${partner.title}`,
				title_with: ` with${partner.title}`,
		  }
		: {
				title: `${partner.title} or${platform.title}`,
				title_with: ` with${partner.title} or${platform.title}`,
		  };

const partner_compound = {
	title_solid_of: ` of${partner.title_solid}`,
	title_space_to: ` to${partner.title_space}`,
};

const platform_compound =
	process.env.REACT_APP_CLIENT === 'broma'
		? {
				title_powered: `powered by${platform.title}`,
				title_space_with: ` with ${platform.title_space}`,
		  }
		: process.env.REACT_APP_CLIENT === 'hitmaker' || process.env.REACT_APP_CLIENT === 'mts'
		? {
				title_powered: ` `,
				title_space_with: ``,
		  }
		: {
				title_powered: `powered by${platform.title}`,
				title_space_with: ` with ${platform.title_space}`,
		  };

export { partner, platform, compound, partner_compound, platform_compound };
