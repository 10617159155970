// Core
import { FormattedMessage } from 'react-intl';

// Styles
import styles from '../Statistics.module.css';

const NotReady = ({ notReady }) => {
	return (
		<div id="notReady_container" className={styles.container__item}>
			<span>
				<FormattedMessage id={'rod.main_page.mistakes'} />
			</span>
			{notReady && notReady.length !== 0 ? (
				<span
					id="notReady_content"
					className={Number(notReady) !== 0 ? styles.stats : null}
				>
					{notReady.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1 `)}
				</span>
			) : (
				<span>-</span>
			)}
		</div>
	);
};

export default NotReady;
