// Core
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-grid-system';
import DOMPurify from 'dompurify';

// Styles
import styles from './TableBody.module.css';

const TableBody = ({ articles, page }) => {
	const htmlParsing = (text) => {
		const securedText = DOMPurify.sanitize(text, {
			ADD_ATTR: ['target'],
		});

		const floatingElement = new DOMParser().parseFromString(
			securedText,
			'text/html'
		);

		return floatingElement.firstChild.lastChild.textContent;
	};

	return (
		<div className={styles.tableBody}>
			<ul>
				{articles.map((item, index) => (
					<li className={styles.articlesListItem}>
						<Row className={styles.rowData}>
							<Link
								className={styles.modifyArticle}
								to={`/admin/v2/articles/edit/${item.id}`}
							>
								<Row className={styles.rowTitle}>
									<Col width={'2%'} className={styles.rowTitleNumber}>
										{page * 10 - 10 + index + 1}
									</Col>
									<Col width={'23%'} className={styles.overflow}>
										{item.heading}
									</Col>
									<Col width={'23%'}>
										<div className={styles.itemTextWrapper}>
											<div className={styles.innerBlockText}>
												{htmlParsing(item.description)}
											</div>
										</div>
									</Col>
									<Col width={'12%'} className={styles.overflow}>
										{item.section ? item.section.title : '-'}
									</Col>
									<Col width={'20%'}>{item.created_at}</Col>
									<Col width={'20%'}>{item.updated_at}</Col>
								</Row>
							</Link>
						</Row>
					</li>
				))}
			</ul>
		</div>
	);
};

export default TableBody;
