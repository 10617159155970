/*
 *  Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *  * Unauthorized copying of this file, via any medium is strictly prohibited
 *  * Proprietary and confidential
 *  * Written by Leonid Artemev <me@artemev.it>, 6/26/19 4:27 PM
 *
 */

import React from 'react';
import getDisplayName from 'recompose/getDisplayName';
import { AuthConsumer } from '../contexts/AuthContext';

export const withAuth = WrappedComponent => {

	const AuthComponent = ((props) => (
		<AuthConsumer>
			{state => (
				<WrappedComponent authContext={state} {...props} />
			)}
		</AuthConsumer>
	));

	const ForwardRef = React.forwardRef((props, ref) => (
		<AuthComponent {...props} withFocusRef={ref}/>
	));

	ForwardRef.displayName = getDisplayName(WrappedComponent);
	ForwardRef.name = ForwardRef.displayName;
	ForwardRef.defaultProps = WrappedComponent.defaultProps;

	return ForwardRef;
};
