// Core
import { useParams } from 'react-router-dom';

// UI
import { EndConfirmationPage } from 'components/EndConfirmationPage';

const TakedownConfirmationPage = () => {
	const { id: releaseId } = useParams();

	return (
		<EndConfirmationPage
			title={'rod.release.takedown.end.title'}
			text={'rod.release.takedown.end.text'}
			textLinks={{
				one: {
					link: `/repertoire/releases/${releaseId}/deliveries`,
					phrase: 'rod.link_word.on_page',
				},
				two: { link: '/faq', phrase: 'rod.link_word.faq' },
			}}
			btnText={'rod.action.to_index_page'}
			btnLink={`/`}
		/>
	);
};

export default TakedownConfirmationPage;
