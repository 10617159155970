export const showStatus = (statuses) => {
	const importancy = ['disputed', 'ready', 'not_ready'];

	if (statuses.length > 1) {
		let resultedStatus = 'not_ready';
		statuses.forEach((stat) => {
			if (importancy.indexOf(stat) < importancy.indexOf(resultedStatus)) {
				resultedStatus = stat;
			}
		});
		return resultedStatus;
	} else return Array.isArray(statuses) ? statuses[0] : statuses;
};
