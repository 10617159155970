// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Utils
import { showError } from 'validators/showError';

// UI
import { FormInput } from 'components/Form/FormInput';
import { AutoWrapper } from 'components';

// Styles
import s from './CatalogNumber.module.css';

const CatalogNumber = ({
	personal,
	setPersonal,
	changeField,
	transferRelease,
	handleSetCatalogNumber,
	errors,
}) => {
	return (
		<div className={s.item}>
			<div
				className={
					personal.generate_catalog_number || personal?.step?.includes('h11')
						? `${s.input} ${s.disabled}`
						: s.input
				}
			>
				<FormInput
					type="muiInput"
					name={'catalog_number'}
					onChange={changeField}
					errors={errors}
					data={personal}
					required={!transferRelease && !personal?.step?.includes('h11')}
					disabled={
						personal.generate_catalog_number || personal?.step?.includes('h11')
					}
					label={
						<FormattedMessage
							id={'rod.release.create.step.release.label.catalog_number'}
						/>
					}
				/>
				{Object.keys(errors).includes('catalog_number') && (
					<span className={s.helper}>
						{showError(errors.catalog_number[0].rule)(errors.catalog_number[0])}
					</span>
				)}
			</div>

			{!transferRelease && !personal?.step?.includes('h11') && (
				<AutoWrapper
					onClick={() =>
						handleSetCatalogNumber(
							personal.generate_catalog_number,
							personal,
							setPersonal
						)
					}
					check={personal.generate_catalog_number}
				/>
			)}
		</div>
	);
};

export default CatalogNumber;
