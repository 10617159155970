// Core
import { subDays, addDays, isWithinInterval } from 'date-fns';

export const shouldDisableDate = (date) => {
	const today = new Date();
	const startDate = subDays(today, 2);
	const endDate = addDays(today, 2);

	return isWithinInterval(date, { start: startDate, end: endDate });
};
