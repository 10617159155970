/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 28/01/2021, 10:48
 */

import { AuthProvider } from 'contexts/AuthContext';
import { LangProvider } from 'contexts/LangContext';
import { RootProvider } from 'contexts/RootContext';
import { UIProvider } from 'contexts/UIContext';

import React from 'react';
import { NotificationContainer } from 'react-notifications';
import { BrowserRouter as Router } from 'react-router-dom';

const Root = (props) => {
	return (
		<React.Fragment>
			<Router>
				<LangProvider>
					<UIProvider>
						<AuthProvider>
							<RootProvider>{props.children}</RootProvider>
						</AuthProvider>
					</UIProvider>
				</LangProvider>
			</Router>
			<NotificationContainer />
		</React.Fragment>
	);
};
export default Root;
