// Utils
import { fm } from 'utils';
import { isDataValidOrDash } from './isDataValidOrDash';

// UI
import { Col, Row } from 'react-grid-system';
import Field from '../../../components/Field/Field';

export const renderSoleProprietorData = (account, getCountryById) => {
	return (
		<>
			<Row>
				<Col sm={4}>
					<Field
						title={fm('rod.admin.account.personal_data.title')}
						value={isDataValidOrDash(account.personal_data.title)}
					/>
				</Col>
				<Col sm={4}>
					<Field
						title={fm('rod.admin.account.personal_data.behalf')}
						value={isDataValidOrDash(account.personal_data.behalf)}
					/>
				</Col>
				<Col sm={4}>
					<Field
						title={fm('rod.admin.account.personal_data.behalf_short')}
						value={isDataValidOrDash(account.personal_data.behalf_short)}
					/>
				</Col>
			</Row>
			<Row>
				<Col sm={4}>
					<Field
						title={fm('rod.admin.account.personal_data.company_reg_number')}
						value={isDataValidOrDash(
							account.personal_data.company_registration_number
						)}
					/>
				</Col>
				<Col sm={4}>
					<Field
						title={fm('rod.admin.account.personal_data.company_reg_date')}
						value={isDataValidOrDash(
							account.personal_data.company_registration_date
						)}
					/>
				</Col>
				<Col sm={4}>
					<Field
						title={fm('rod.admin.account.personal_data.country_id')}
						value={
							account.personal_data.country_id
								? getCountryById(account.personal_data.country_id)
								: '\u2212'
						}
					/>
				</Col>
			</Row>
			<Row>
				<Col sm={4}>
					<Field
						title={fm('rod.admin.account.personal_data.last_name')}
						value={isDataValidOrDash(account.personal_data.last_name)}
					/>
				</Col>
				<Col sm={4}>
					<Field
						title={fm('rod.admin.account.personal_data.first_name')}
						value={isDataValidOrDash(account.personal_data.first_name)}
					/>
				</Col>

				<Col sm={4}>
					{account.personal_data.patronymic && (
						<Field
							title={fm('rod.admin.account.personal_data.patronymic')}
							value={isDataValidOrDash(account.personal_data.patronymic)}
						/>
					)}
				</Col>
			</Row>
			<Row>
				<Col sm={4}>
					<Field
						title={fm('rod.admin.account.personal_data.sex')}
						value={
							account.personal_data.sex === 'male'
								? fm('rod.admin.account.personal_data.sex.male')
								: account.personal_data.sex === 'female'
								? fm('rod.admin.account.personal_data.sex.female')
								: '\u2212'
						}
					/>
				</Col>
				<Col sm={4}>
					<Field
						title={fm('rod.admin.account.personal_data.birth_date')}
						value={isDataValidOrDash(account.personal_data.birth_date)}
					/>
				</Col>
				<Col sm={4}>
					<Field
						title={fm('rod.admin.account.personal_data.birth_place')}
						value={isDataValidOrDash(account.personal_data.birth_place)}
					/>
				</Col>
			</Row>
			<Field
				title={fm('rod.admin.account.personal_data.itin')}
				value={isDataValidOrDash(account.personal_data.itin)}
			/>
		</>
	);
};
