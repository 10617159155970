export const addTranslation = (data, compositionTypes, releaseTypes) => {
	const result = data.map((item) => {
		const titlesData =
			item.draft_type === 'composition'
				? compositionTypes.find((type) => type.id === +item.type_id)
				: releaseTypes.find((type) => type.id === +item.type_id);
		item['typeColumn'] = {
			title_ru:
				item.draft_type === 'composition'
					? titlesData?.title_ru
					: titlesData?.title,
			title_en:
				item.draft_type === 'composition'
					? titlesData?.title_en
					: titlesData?.title,
		};
		return item;
	});
	return result;
};
