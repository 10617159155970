export const splitPhoneNumber = (phoneNumber) => {
	// Step 1: Remove the plus sign if it exists
	if (phoneNumber.startsWith('+')) {
		phoneNumber = phoneNumber.slice(1);
	}

	// Step 2: Remove all brackets
	phoneNumber = phoneNumber.replace(/[()]/g, '');

	// Step 3: Remove all dashes
	phoneNumber = phoneNumber.replace(/-/g, '');

	// Step 4: Handle the case where there's no space
	if (!phoneNumber.includes(' ')) {
		return { code: null, phone: phoneNumber };
	}

	// Step 5: Remove all spaces except the first one
	const firstSpaceIndex = phoneNumber.indexOf(' ');
	if (firstSpaceIndex !== -1) {
		// Split into two parts, remove spaces in the second part
		const firstPart = phoneNumber.slice(0, firstSpaceIndex);
		const secondPart = phoneNumber
			.slice(firstSpaceIndex + 1)
			.replace(/\s+/g, '');
		phoneNumber = firstPart + ' ' + secondPart;
	}

	// Step 6: Split into code and phone
	const [code, phone] = phoneNumber.split(' ');

	// If code doesn't contain only digits, return null for code
	const finalCode = /^\d+$/.test(code) ? code : null;

	return { code: finalCode, phone };
};
