// Core
const { subMonths, format } = require('date-fns');

export const getPreviousMonth = (dateString) => {
	// Parse the input date string into a Date object
	const date = new Date(dateString);

	// Subtract one month from the parsed date
	const previousMonth = subMonths(date, 1);

	// Format the previous month in the same format as the input date string
	const formattedPreviousMonth = format(previousMonth, 'yyyy-MM-dd');

	// Return the formatted previous month
	return formattedPreviousMonth;
};
