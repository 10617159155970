/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 08/06/2020, 17:46
 */

import { Button } from 'components/Buttons/Button';
import { Label } from 'components/Labels';
import { Success as SuccessForm } from 'forms/common';
import { withUI } from 'hocs';
import React, { Component } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import styles from './ForgotPasswordSend.module.css';

class ForgotPasswordSend extends Component {
	componentDidMount() {
		//this.props.UIContext.showBackNavi();
	}

	componentWillUnmount() {
		//this.props.UIContext.hideBackNavi();
	}

	handleClick = () => {
		this.props.history.push('/');
	};

	render() {
		return (
			<SuccessForm>
				<Label black className={styles.Header} font="--gilroy-Medium-40">
					<FormattedHTMLMessage id={'rod.restore_password.email.header'}/></Label>
				<Label black
							 className={styles.Label}
							 font="--gilroy-Medium-24"
				><FormattedHTMLMessage id={'rod.restore_password.email.subheader'}/></Label>
				<Button className={styles.Button} onClick={this.handleClick}
								text={<FormattedMessage id={'rod.action.to_index_page'}/>}
								variant={'primary'}
				/>
			</SuccessForm>

		);
	}
}

export default compose(
	withUI,
	withRouter,
)(ForgotPasswordSend);
