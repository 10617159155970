/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

import GoogleMapReact from 'google-map-react';
import React, { Component } from 'react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Map extends Component {
	static defaultProps = {
		center: {
			lat: 59.95,
			lng: 30.33,
		},
		zoom: 11,
	};

	render() {
		return (
			// Important! Always set the container height explicitly
			<div style={{
				height: '100vh',
				width: '100%',
			}}
			>
				<GoogleMapReact
					bootstrapURLKeys={{ key: '' }}
					defaultCenter={this.props.center}
					defaultZoom={this.props.zoom}
				>
					<AnyReactComponent
						lat={59.955413}
						lng={30.337844}
						text="My Marker"
					/>
				</GoogleMapReact>
			</div>
		);
	}
}

export default Map;