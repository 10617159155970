// Core
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

// Utils
import {
	colorRange,
	assignColorIndexToOutlet,
} from '../../utils/assignColorIndexToOutlet';

// Icons
import question from 'images/question.svg';

// Styles
import styles from '../StatisticByDatesChart.module.css';

const CrosshairData = ({ legend }) => {
	const [showTooltip, setShowTooltip] = useState(false);

	return (
		<div className={styles.crosshairData__legend}>
			{legend &&
				legend.map((item, index) => {
					return (
						<div key={index} className={styles.crosshairData__legendItem}>
							<div
								className={styles.crosshairData__legendItemColor}
								style={{
									background: item?.code
										? colorRange[assignColorIndexToOutlet(item?.code)]
										: 'transparent',
									borderTop: `2px ${item?.color} solid`,
								}}
							/>
							<div className={styles.titleContainer}>
								<span className={styles.crosshairData__legendItemTitle}>
									{legend[index]?.title}
								</span>
								{legend[index]?.title === 'YouTube' && (
									<span
										className={styles.question}
										onMouseEnter={() => setShowTooltip(true)}
										onMouseLeave={() => setShowTooltip(false)}
									>
										<img src={question} alt="question" />
									</span>
								)}
							</div>
							{legend[index]?.title === 'YouTube' && showTooltip && (
								<div className={styles.tooltip}>
									<FormattedMessage id={'rod.statistic.youtube.help'} />
								</div>
							)}
						</div>
					);
				})}
		</div>
	);
};

export default CrosshairData;
