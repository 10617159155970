// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';

// UI
import { Check, NoCheck } from 'material-design/svg';

// Styles
import styles from './AutoWrapper.module.css';

const AutoWrapper = ({ onClick, check }) => {
	return (
		<div className={styles.auto__wrapper}>
			<div className={styles.auto} onClick={onClick}>
				{check ? (
					<Check className={styles.auto__check} />
				) : (
					<NoCheck className={styles.auto__noCheck} />
				)}
				<span>
					<FormattedMessage id={'rod.release.create.step.release.label.auto'} />
				</span>
			</div>
		</div>
	);
};

export default AutoWrapper;
