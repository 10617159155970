

export default function Dynamic({className}){
   return(
      <svg className={className} width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M62.2649 21.426C62.012 20.666 61.1952 20.247 60.4253 20.5069C59.664 20.7612 59.2521 21.5836 59.5063 22.3464C60.5561 25.4956 61.0887 28.7442 61.0887 31.9999C61.0887 35.2556 60.5561 38.5042 59.5064 41.6534C59.2521 42.4162 59.664 43.2386 60.4255 43.4929C60.5789 43.5441 60.7336 43.5682 60.8857 43.5682C61.4951 43.5682 62.0618 43.1832 62.265 42.5738C63.4156 39.1264 63.9979 35.5695 63.9979 31.9999C63.9979 28.4303 63.4155 24.8734 62.2649 21.426Z" fill="#1E1E1E"/>
         <path d="M54.8131 22.3486C54.0518 22.6014 53.6398 23.4253 53.894 24.1882C54.7434 26.7392 55.1753 29.3686 55.1753 32.0035C55.1753 34.6385 54.7434 37.2677 53.894 39.8188C53.6397 40.5816 54.0517 41.4054 54.8131 41.6584C54.9665 41.7095 55.1213 41.7336 55.2733 41.7336C55.8827 41.7336 56.4495 41.3487 56.6527 40.7393C57.603 37.8898 58.0845 34.9509 58.0845 32.0035C58.0845 29.0562 57.603 26.1171 56.6527 23.2677C56.3998 22.5062 55.5845 22.0943 54.8131 22.3486Z" fill="#1E1E1E"/>
         <path d="M43.7528 0C42.3921 0 41.0738 0.487228 40.0427 1.37359L21.2798 17.4546H5.81823C2.60932 17.4546 0 20.0639 0 23.2728V40.7273C0 43.9361 2.60932 46.5456 5.81823 46.5456H21.2798L40.0412 62.6264C41.0738 63.5128 42.3921 64 43.7528 64C46.8963 64 49.4546 61.4418 49.4546 58.2982V5.70164C49.4546 2.55818 46.8963 0 43.7528 0ZM46.5454 58.2984C46.5454 59.8382 45.2925 61.091 43.7528 61.091C43.0867 61.091 42.4403 60.8523 41.9347 60.4191L22.7643 43.9873C22.5014 43.7599 22.1648 43.6364 21.8182 43.6364H5.81823C4.21459 43.6364 2.90918 42.331 2.90918 40.7273V23.2728C2.90918 21.6692 4.21459 20.3637 5.81823 20.3637H21.8182C22.1648 20.3637 22.5014 20.2402 22.7643 20.0129L41.9362 3.58105C42.4405 3.14782 43.0868 2.90918 43.753 2.90918C45.2928 2.90918 46.5456 4.16209 46.5456 5.70178V58.2984H46.5454Z" fill="#1E1E1E"/>
      </svg>
   )
}