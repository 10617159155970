// Constants
import releaseCompletionSteps from 'material-design/constants/releaseCompletionSteps';

export const getPrevStep = (
	completionStep,
	defaultSteps,
	setDefaultSteps,
	setConfirm
) => {
	switch (completionStep) {
		case releaseCompletionSteps.CONFIRM:
			defaultSteps[0].status = 'complete';
			defaultSteps[1].status = 'complete';
			defaultSteps[2].status = 'complete';
			defaultSteps[3].status = 'complete';
			defaultSteps[4].status = 'complete';
			defaultSteps[4].status = 'complete';
			defaultSteps[5].status = 'complete';
			defaultSteps[6].status = 'active';
			defaultSteps[7].status = 'default';
			setConfirm(false);
			setDefaultSteps(defaultSteps);
			return releaseCompletionSteps.DISTRIBUTION;
		case releaseCompletionSteps.DISTRIBUTION:
			defaultSteps[5].status = 'active';
			defaultSteps[6].status = 'default';
			setDefaultSteps(defaultSteps);
			return releaseCompletionSteps.COVER;
		case releaseCompletionSteps.COVER:
			defaultSteps[4].status = 'active';
			defaultSteps[5].status = 'default';
			setDefaultSteps(defaultSteps);
			return releaseCompletionSteps.LYRICS;
		case releaseCompletionSteps.LYRICS:
			defaultSteps[3].status = 'active';
			defaultSteps[4].status = 'default';
			setDefaultSteps(defaultSteps);
			return releaseCompletionSteps.AUTHORS;
		case releaseCompletionSteps.AUTHORS:
			defaultSteps[2].status = 'active';
			defaultSteps[3].status = 'default';
			setDefaultSteps(defaultSteps);
			return releaseCompletionSteps.TRACKS;
		case releaseCompletionSteps.TRACKS:
			defaultSteps[1].status = 'active';
			defaultSteps[2].status = 'default';
			setDefaultSteps(defaultSteps);
			return releaseCompletionSteps.FILE;
		case releaseCompletionSteps.FILE:
			defaultSteps[0].status = 'active';
			defaultSteps[1].status = 'default';
			setDefaultSteps(defaultSteps);
			return releaseCompletionSteps.RELEASE;
		default:
			return completionStep;
	}
};
