export const getOneYearPeriod = () => {
	const today = new Date();
	const year = today.getFullYear();
	const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

	if (isLeapYear && today.getMonth() >= 1 && today.getDate() >= 29) {
		return 366; // If it's a leap year and after February 29th
	} else {
		return 365; // Otherwise, it's either not a leap year or before February 29th
	}
};
