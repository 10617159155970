export const getTooltip = (disabled) => {
	const lang = localStorage.getItem('lang');
	if (disabled) {
		if (lang === 'en') {
			return 'first create and save the author';
		}

		if (lang === 'ru') {
			return 'сначала создайте и сохраните автора';
		}
	} else {
		return '';
	}
};
