// Constants
import accountCompletionSteps from 'constants/accountCompletionSteps';

const STEPS = {
	[accountCompletionSteps.EMAIL_CONFIRM]: 0,
	[accountCompletionSteps.PHONE_CONFIRM]: 1,
	[accountCompletionSteps.BEGIN]: 2,
	[accountCompletionSteps.ACCOUNT_CREATED]: 2,
	[accountCompletionSteps.ROLE]: 3,
	[accountCompletionSteps.PERSONAL_DATA]: 4,
	[accountCompletionSteps.CONTACT_DATA]: 5,
	[accountCompletionSteps.DOCUMENTS]: 6,
	[accountCompletionSteps.IDENTITY]: 7,
	[accountCompletionSteps.MONEY]: 8,
	[accountCompletionSteps.PENDING]: 9,
	[accountCompletionSteps.DECLINED]: 9,
	[accountCompletionSteps.DECLINED_DOCUMENTS]: 9,
	[accountCompletionSteps.APPROVED]: 10,
	[accountCompletionSteps.SIGN_SMS]: 12,
	[accountCompletionSteps.SIGN_PHYSICAL]: 12,
	[accountCompletionSteps.SMS_CONFIRM]: 12,
	[accountCompletionSteps.PENDING_CONTRACT]: 13,
	[accountCompletionSteps.APPROVED_CONTRACTS]: 13,
	[accountCompletionSteps.DECLINED_CONTRACTS]: 13,
	[accountCompletionSteps.COMPLETE]: 14,
	[accountCompletionSteps.PERSONAL_AREA]: 14,
	[accountCompletionSteps.REPERTOIRE_APPLY]: 14,
};

export default STEPS;
