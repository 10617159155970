export const sanitizeData = (data) => {
	const sanitizedData = {};
	for (const key in data) {
		if (data.hasOwnProperty(key)) {
			let value = data[key];
			if (typeof value === 'string') {
				// Sanitize the string value to remove special characters
				// eslint-disable-next-line no-control-regex
				value = value.replace(/[\u0000-\u0002]/g, '');
			}
			sanitizedData[key] = value;
		}
	}
	return sanitizedData;
};
