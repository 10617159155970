// Core
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import cn from 'classnames';

// Utils
import { sortByTitle } from 'utils';

// Icons
import removeImg from 'images/close_background.svg';

// Styles
import styles from './MultipleSelectDropdown.module.css';

class MultipleSelectDropdown extends PureComponent {
	constructor(props) {
		super(props);
		this.search = React.createRef();
		this.field = React.createRef();
		this.list = React.createRef();
		this.state = {
			isOpen: false,
			filtered: [],
			search: '',
			prev: '',
			items: [],
			selectedItems: [],
		};
	}

	componentDidMount() {
		let items = this.props.items;
		this.props.selected.forEach((item) => {
			const index = items.findIndex((i) => +i.id === +item.id);
			if (~index) {
				items.splice(index, 1);
			}
		});

		this.setState(
			update(this.state, {
				selectedItems: { $set: this.props.selected },
				items: { $set: items },
			})
		);
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.selected.length && this.props.selected.length) {
			this.setState(
				update(this.state, {
					selectedItems: { $set: this.props.selected },
				})
			);
		}
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.close, true);
	}

	close = (e) => {
		if (!this.field.current.contains(e.target)) {
			this.setState(
				update(this.state, {
					isOpen: { $set: false },
				})
			);
		}
	};

	selectItem = (item) => (e) => {
		e.preventDefault();

		const index = this.state.items.findIndex((i) => i.id === item.id);
		this.setState(
			update(this.state, {
				items: { $splice: [[index, 1]] },
				selectedItems: { $push: [item] },
				search: { $set: '' },
				isOpen: { $set: false },
				prev: { $set: this.state.search },
			}),
			() => {
				this.onChange();
			}
		);
	};

	removeItem = (item) => () => {
		const index = this.state.selectedItems.findIndex((i) => i.id === item.id);

		this.setState(
			update(this.state, {
				selectedItems: { $splice: [[index, 1]] },
				items: { $push: [item] },
			}),
			() => {
				this.onChange();
			}
		);
	};

	onChange = () => {
		if (typeof this.props.onChange === 'function') {
			this.props.onChange(this.state.selectedItems);
		}
	};

	inputChange = (e) => {
		const search = e.currentTarget.value;
		const isOpen = !!search;

		this.setState(
			update(this.state, {
				isOpen: { $set: isOpen },
				search: { $set: search },
				prev: { $set: this.state.search },
			}),
			() => {
				if (isOpen) {
					document.addEventListener('click', this.close, true);
				} else {
					document.removeEventListener('click', this.close, true);
				}
			}
		);
	};

	focus = () => {
		this.search.current.focus();
	};

	keyUp = (e) => {
		if (
			e.keyCode === 8 &&
			this.state.prev === '' &&
			this.state.selectedItems.length > 0
		) {
			const item = this.state.selectedItems[
				this.state.selectedItems.length - 1
			];
			this.removeItem(item)();
		} else {
			this.setState(
				update(this.state, {
					prev: { $set: this.state.search },
				})
			);
		}
	};

	render() {
		const { placeholder, className, isCountriesData = false } = this.props;
		const { isOpen, items, selectedItems, search } = this.state;
		const lang = localStorage.getItem('lang');

		const sorted = sortByTitle(items);
		const filtered = sorted.filter((i) =>
			lang === 'ru'
				? i.title_ru.toLowerCase().includes(search.toLowerCase())
				: i.title_en.toLowerCase().includes(search.toLowerCase())
		);

		const empty = selectedItems.length === 0 && search === '';
		return (
			<div
				className={cn(styles.Field, className)}
				onClick={this.focus}
				ref={this.field}
			>
				<div className={styles.Content}>
					{empty && <div className={styles.Placeholder}>{placeholder}</div>}
					<ul className={styles.Selected}>
						{selectedItems.map((item) => (
							<li key={item.id} className={styles.SelectedItem}>
								<span>
									{lang === 'ru'
										? item.title_ru.toLowerCase()
										: item.title_en.toLowerCase()}
								</span>
								<button className="remove" onClick={this.removeItem(item)}>
									<img src={removeImg} alt="" />
								</button>
							</li>
						))}
						<input
							ref={this.search}
							type="text"
							value={search}
							className={cn(styles.Input, { empty })}
							onKeyUp={this.keyUp}
							onChange={this.inputChange}
						/>
					</ul>
				</div>
				<div ref={this.list}>
					{isOpen && (
						<ul className={styles.List} onClick={this.close}>
							{filtered.map((item) =>
								isCountriesData ? (
									<li
										key={item.id}
										className={styles.CountryItem}
										onClick={this.selectItem(item)}
									>
										<span>{lang === 'ru' ? item.title_ru : item.title_en}</span>
									</li>
								) : (
									<li
										key={item.id}
										className={styles.Item}
										onClick={this.selectItem(item)}
									>
										{lang === 'ru'
											? item.title_ru.toLowerCase()
											: item.title_en.toLowerCase()}
									</li>
								)
							)}
							{filtered.length === 0 && (
								<li>
									<FormattedMessage id={'rod.no_items_found'} />
								</li>
							)}
						</ul>
					)}
				</div>
			</div>
		);
	}
}

MultipleSelectDropdown.propTypes = {
	items: PropTypes.array.isRequired,
	selected: PropTypes.array.isRequired,
	onChange: PropTypes.func,
};

MultipleSelectDropdown.defaultProps = {
	selected: [],
	items: [],
};

export default MultipleSelectDropdown;
