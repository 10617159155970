export default function Nota({className}){
   return(
      <svg className={className} width="64" height="64" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M19.8 0H2.20002C0.98665 0 0 0.98665 0 2.19997V19.8C0 21.0133 0.98665 22 2.20002 22H19.8C21.0133 22 22 21.0133 22 19.8V2.19997C22 0.98665 21.0133 0 19.8 0ZM20.9 19.8C20.9 20.4064 20.4064 20.9 19.8 20.9H2.20002C1.59364 20.9 1.10004 20.4064 1.10004 19.8V2.19997C1.09998 1.59359 1.59359 1.09998 2.20002 1.09998H19.8C20.4064 1.09998 20.9 1.59359 20.9 2.19997V19.8Z" fill="black"/>
         <path d="M10.9994 9.89844C10.393 9.89844 9.89941 10.392 9.89941 10.9984C9.89941 11.6048 10.393 12.0984 10.9994 12.0984C11.6058 12.0984 12.0994 11.6048 12.0994 10.9984C12.0994 10.392 11.6058 9.89844 10.9994 9.89844Z" fill="black"/>
         <path d="M11.0002 2.19922C6.14798 2.19922 2.2002 6.147 2.2002 10.9992C2.2002 15.8515 6.14798 19.7993 11.0002 19.7993C15.8525 19.7993 19.8003 15.8515 19.8003 10.9992C19.8003 6.147 15.8525 2.19922 11.0002 2.19922ZM11.0002 18.6992C6.75441 18.6992 3.30023 15.2451 3.30023 10.9992C3.30023 6.75338 6.75441 3.29925 11.0002 3.29925C15.246 3.29925 18.7003 6.75338 18.7003 10.9992C18.7003 15.2451 15.2461 18.6992 11.0002 18.6992Z" fill="black"/>
         <path d="M11.0002 7.69922C9.18045 7.69922 7.7002 9.17948 7.7002 10.9992C7.7002 12.819 9.18045 14.2992 11.0002 14.2992C12.8199 14.2992 14.3002 12.819 14.3002 10.9992C14.3002 9.17948 12.8199 7.69922 11.0002 7.69922ZM11.0002 13.1992C9.78688 13.1992 8.80018 12.2126 8.80018 10.9992C8.80018 9.78585 9.78683 8.7992 11.0002 8.7992C12.2136 8.7992 13.2002 9.7859 13.2002 10.9992C13.2002 12.2125 12.2136 13.1992 11.0002 13.1992Z" fill="black"/>
         <path d="M11.0011 5.49847C11.3045 5.49847 11.5511 5.25247 11.5511 4.94846C11.5511 4.64501 11.3051 4.39844 11.0011 4.39844C11.0006 4.39844 11 4.39844 10.9989 4.39844C9.23666 4.39844 7.57965 5.08489 6.33302 6.33152C6.11816 6.54638 6.11816 6.89443 6.33302 7.10924C6.44043 7.21664 6.58119 7.27037 6.72191 7.27037C6.86262 7.27037 7.00333 7.21664 7.11079 7.10924C8.14957 6.07046 9.53047 5.49842 10.9989 5.49842C10.9995 5.49847 11 5.49847 11.0011 5.49847Z" fill="black"/>
      </svg>
   )
}