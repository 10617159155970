// Core
import React, { useEffect, useRef, useState } from 'react';
import { PoseGroup } from 'react-pose';
import { FormattedMessage } from 'react-intl';

// Services
import { auth } from 'services';

// Utils
import { splitPhoneNumber } from 'components/Inputs/PhoneInputFormatted/utils/splitPhoneNumber';

// UI
import { Modal, Shade } from './Posed';
import { CloseButton } from 'components/Buttons';
import { Container } from 'components/Grid';
import BaseModal from '../BaseModal';
import { FormInput } from 'components/Form/FormInput';
import { Button } from 'components/Buttons/Button';
import EmailBlock from './EmailBlock/EmailBlock';
import PhoneBlock from './PhoneBlock/PhoneBlock';

// Icons
import pencil from 'images/pencil.svg';

// Styles
import styles from './UserEditModal.module.css';

const UserEditModal = ({ onClose, user, getUser, countries, ...props }) => {
	const emailRef = useRef(null);
	const emaiCodeRef = useRef(null);
	const phoneCodeRef = useRef(null);
	const MINUTE = 60;

	const [isVisible, setIsVisible] = useState(false);
	const [userData, setUserData] = useState(user);
	const [edit, setEdit] = useState(props.edit);
	const [errors, setErrors] = useState({});

	// Email states
	const [emailDisabled, setEmailDisabled] = useState(false);
	const [emailSecondsLeft, setEmailSecondsLeft] = useState(0);
	const [showEmailCodeButton, setShowEmailCodeButton] = useState(false);
	const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);
	const [isResendButton, setIsResendButton] = useState(false);
	const [hideEmailEdition, setHideEmailEdition] = useState(true);
	const [showConfirmCodeButton, setShowConfirmCodeButton] = useState(false);
	const [showCodeInput, setShowCodeInput] = useState(false);
	const [resendErrors, setResendErrors] = useState({});

	// Phone states
	const [phoneDisabled, setPhoneDisabled] = useState(false);
	const [phoneSecondsLeft, setPhoneSecondsLeft] = useState(0);
	const [showPhoneCodeButton, setShowPhoneCodeButton] = useState(false);
	const [isPhoneConfirmed, setIsPhoneConfirmed] = useState(false);
	const [isResendPhoneButton, setIsResendPhoneButton] = useState(false);
	const [hidePhoneEdition, setHidePhoneEdition] = useState(true);
	const [showPhoneConfirmCodeButton, setShowPhoneConfirmCodeButton] = useState(
		false
	);
	const [showPhoneCodeInput, setShowPhoneCodeInput] = useState(false);
	const [resendPhoneErrors, setResendPhoneErrors] = useState({});

	const [disableTitleEdition, setDisableTitleEdition] = useState(false);
	const [disableSaveBtn, setDisableSaveBtn] = useState(false);

	// Derived state to check if there are changes
	const hasChanges =
		userData.title !== user.title ||
		userData.login !== user.login ||
		userData.phone !== user.phone;

	useEffect(() => {
		setIsVisible(true);
		localStorage.removeItem('userEditEmail');
		localStorage.removeItem('userEditPhone');
	}, []);

	// Timer for email code
	useEffect(() => {
		if (emailSecondsLeft > 0) {
			const timer = setInterval(() => {
				setEmailSecondsLeft((prevSecondsLeft) => prevSecondsLeft - 1);
			}, 1000);
			return () => clearInterval(timer);
		} else {
			if (
				errors &&
				Object.keys(errors) &&
				errors?.email &&
				errors?.email[0]?.rule === 'forbidden'
			) {
				setShowEmailCodeButton(false);
			} else {
				setShowEmailCodeButton(true);
				setEmailDisabled(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [emailSecondsLeft, errors]);

	// Timer for phone code
	useEffect(() => {
		if (phoneSecondsLeft > 0) {
			const timer = setInterval(() => {
				setPhoneSecondsLeft((prevSecondsLeft) => prevSecondsLeft - 1);
			}, 1000);
			return () => clearInterval(timer);
		} else {
			if (
				errors &&
				Object.keys(errors) &&
				errors?.phone &&
				errors?.phone[0]?.rule === 'forbidden'
			) {
				setShowPhoneCodeButton(false);
			} else {
				setShowPhoneCodeButton(true);
				setPhoneDisabled(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [phoneSecondsLeft, errors]);

	// Disable email input on error
	useEffect(() => {
		if (
			errors &&
			Object.keys(errors) &&
			errors?.email &&
			errors?.email[0]?.rule === 'forbidden'
		) {
			setEmailDisabled(true);
		}
	}, [errors]);

	// Disable phone input on error
	useEffect(() => {
		if (
			errors &&
			Object.keys(errors) &&
			errors?.phone &&
			errors?.phone[0]?.rule === 'forbidden'
		) {
			setPhoneDisabled(true);
		}
	}, [errors]);

	const willUnmount = (e) => {
		setIsVisible(false);
		localStorage.removeItem('userEditEmail');
		localStorage.removeItem('userEditPhone');
		setTimeout(() => {
			onClose();
		}, 300);
	};

	const changeTitle = (field) => (e) => {
		setErrors({});
		setDisableSaveBtn(false);
		setShowCodeInput(false);
		setHideEmailEdition(true);
		setHidePhoneEdition(true);
		setUserData({
			...userData,
			[field]: e.target.value,
			login: isEmailConfirmed ? userData.login : user.login,
			phone: isPhoneConfirmed ? userData.phone : user.phone,
		});
	};

	// Handle email change
	const onChangeEmail = () => {
		setErrors({});
		if (emailRef.current.value !== user.login) {
			setShowEmailCodeButton(true);
			setHideEmailEdition(false);
			setDisableSaveBtn(true);

			const prevEmail = localStorage.getItem('userEditEmail');
			if (emailRef.current.value !== prevEmail) {
				setIsResendButton(false);
				setShowCodeInput(false);
			} else {
				setIsResendButton(true);
				setShowCodeInput(true);
			}
		} else {
			setHideEmailEdition(true);
			setShowEmailCodeButton(false);
		}

		setUserData({
			...userData,
			login: emailRef.current.value,
		});
	};

	// Handle phone change
	const onChangePhone = (data) => {
		setErrors({});
		if (!data.number) {
			setShowPhoneCodeButton(false);
		}
		let formatPhone;
		if (data.dialingCode) {
			formatPhone = data.dialingCode + ' ' + data.number;
		} else {
			formatPhone = data.number;
		}
		const userPhone = splitPhoneNumber(user.phone);
		if (
			data.number && formatPhone.length >=7 &&
			(data.number !== userPhone.phone || data.dialingCode !== userPhone.code)
		) {
			setShowPhoneCodeButton(true);
			setHidePhoneEdition(false);
			setDisableSaveBtn(true);
			const prevPhone = localStorage.getItem('userEditPhone');
			if (formatPhone !== prevPhone) {
				setIsResendPhoneButton(false);
				setShowPhoneCodeInput(false);
			} else {
				setIsResendPhoneButton(true);
				setShowPhoneCodeInput(true);
			}
		} else {
			setHidePhoneEdition(true);
			setShowPhoneCodeButton(false);
		}

		setUserData({
			...userData,
			phone: formatPhone,
			login: localStorage.getItem('userEditEmail') || user.login,
		});
	};

	// Handle code change for email
	const onChangeCode = () => {
		setErrors({});
		setShowConfirmCodeButton(true);
	};

	// Handle code change for phone
	const onChangePhoneCode = () => {
		setErrors({});
		setShowPhoneConfirmCodeButton(true);
	};

	const handleSendCode = () => {
		setDisableTitleEdition(true);
		const payload = {};
		if (user.login !== userData.login) {
			payload.email = userData.login;
		}
		if (payload.email) {
			setErrors({});
			setEmailDisabled(true);
			setEmailSecondsLeft(MINUTE);
			setShowEmailCodeButton(false);
			if (!isResendButton) {
				auth
					.userUpdate(payload)
					.then(() => {
						setShowCodeInput(true);
						setIsResendButton(true);
						localStorage.setItem('userEditEmail', payload.email);
					})
					.catch((err) => {
						setEmailSecondsLeft(0);
						setErrors(err?.response?.data?.errors);
						setDisableTitleEdition(false);
						if (
							err?.response?.data?.errors?.email[0]?.rule === 'not_uniques' ||
							err?.response?.data?.errors?.email[0]?.rule === 'email_format' ||
							err?.response?.data?.errors?.email[0]?.rule === 'invalid'
						) {
							setEmailSecondsLeft(0);
							setShowEmailCodeButton(false);
							setHideEmailEdition(true);
						}
					});
			} else {
				auth
					.resendEmailCodeWithoutCode()
					.then((res) => {
						setShowCodeInput(true);
					})
					.catch((err) => {
						setEmailSecondsLeft(0);
						setResendErrors(err?.errors);
						setDisableTitleEdition(false);
						if (
							err?.errors &&
							err.errors?.code &&
							(err.errors.code[0]?.rule === 'code_exhausted' ||
								err.errors.code[0]?.rule === 'forbidden' ||
								(err.errors.code[0]?.rule === 'invalid' &&
									err.errors.code[0]?.remaining === 0))
						) {
							setShowCodeInput(false);
							setHideEmailEdition(true);
						}
					});
			}
		}
	};

	const handleSendPhoneCode = () => {
		setDisableTitleEdition(true);
		setEmailDisabled(true);
		const payload = {};
		if (user.phone !== userData.phone) {
			payload.phone = userData.phone;
		}
		if (payload.phone) {
			setErrors({});
			setResendPhoneErrors({});
			setPhoneDisabled(true);
			setPhoneSecondsLeft(MINUTE);
			setShowPhoneCodeButton(false);
			if (!isResendPhoneButton) {
				auth
					.userUpdate(payload)
					.then(() => {
						setShowPhoneCodeInput(true);
						setIsResendPhoneButton(true);
						localStorage.setItem('userEditPhone', payload.phone);
					})
					.catch((err) => {
						setPhoneSecondsLeft(0);
						setErrors(err?.response?.data?.errors);
						setDisableTitleEdition(false);
						setEmailDisabled(false);
						if (
							err &&
							err?.response &&
							err?.response?.data?.errors &&
							(err?.response?.data?.errors?.phone[0]?.rule === 'not_uniques' ||
								err?.response?.data?.errors?.phone[0]?.rule === 'min_length' ||
								err?.response?.data?.errors?.phone[0]?.rule === 'max_length')
						) {
							setPhoneSecondsLeft(0);
							setShowPhoneCodeButton(false);
							setHidePhoneEdition(true);
						}
					});
			} else {
				auth
					.resendPhoneCodeWithoutCode()
					.then((res) => {
						setShowPhoneCodeInput(true);
					})
					.catch((err) => {
						setPhoneSecondsLeft(0);
						setResendPhoneErrors(err?.errors);
						setDisableTitleEdition(false);
						setEmailDisabled(false);
						if (
							err?.errors &&
							err.errors?.code &&
							(err.errors.code[0]?.rule === 'code_exhausted' ||
								err.errors.code[0]?.rule === 'forbidden' ||
								(err.errors.code[0]?.rule === 'invalid' &&
									err.errors.code[0]?.remaining === 0))
						) {
							setShowPhoneCodeInput(false);
							setHidePhoneEdition(true);
						}
					});
			}
		}
	};

	const handleConfirm = () => {
		if (emaiCodeRef.current.value) {
			setShowConfirmCodeButton(false);
			auth
				.userConfirmEdit({
					email: userData.login,
					email_code: emaiCodeRef.current.value,
				})
				.then(() => {
					setShowCodeInput(false);
					setShowEmailCodeButton(false);
					setIsEmailConfirmed(true);
					setDisableSaveBtn(false);
					setEmailSecondsLeft(0);
					setHideEmailEdition(true);
					setDisableTitleEdition(false);
					getUser();
				})
				.catch((err) => setErrors(err?.response?.data?.errors));
		}
	};

	const handlePhoneConfirm = () => {
		if (phoneCodeRef.current.value) {
			setShowPhoneConfirmCodeButton(false);
			auth
				.userConfirmEdit({
					phone: userData.phone,
					sms_code: phoneCodeRef.current.value,
				})
				.then(() => {
					setShowPhoneCodeInput(false);
					setShowPhoneCodeButton(false);
					setIsPhoneConfirmed(true);
					setDisableSaveBtn(false);
					setPhoneSecondsLeft(0);
					setHidePhoneEdition(true);
					setDisableTitleEdition(false);
					setEmailDisabled(false);
					getUser();
				})
				.catch((err) => setErrors(err?.response?.data?.errors));
		}
	};

	const submitHandler = () => {
		auth
			.userUpdate({ title: userData.title })
			.then(() => {
				getUser();
				willUnmount();
			})
			.catch((err) => setErrors(err?.response?.data?.errors));
	};

	return (
		<BaseModal className={styles.modal}>
			<PoseGroup>
				{isVisible && [
					<Shade key="shade" className={styles.overlay} />,
					<Modal
						key="modal"
						style={{
							marginLeft: 'auto',
						}}
					>
						<Container className={styles.container} fluid>
							<div>
								<div
									md={12 - 4}
									onClick={(e) => {
										willUnmount(e);
									}}
								/>
								<div md={4} className={styles.user}>
									<div className={styles.user__wrapper}>
										<div className={styles.user__top_wrapper}>
											<div className={styles.user__title}></div>
											<CloseButton
												black
												small
												onClick={(e) => {
													willUnmount(e);
												}}
											/>
										</div>
										<div className={styles.user__header}>
											<span className={styles.user__title}>
												<FormattedMessage
													id={
														edit
															? 'rod.admin.user.title.edit'
															: 'rod.admin.user.title'
													}
												/>
											</span>
											{!edit && (
												<button onClick={() => setEdit(true)}>
													<img src={pencil} alt="pencil" />
												</button>
											)}
										</div>
										<div className={styles.form} style={{ width: '100%' }}>
											<FormInput
												type="muiInput"
												name={'title'}
												onChange={changeTitle}
												errors={{}}
												data={userData}
												disabled={!edit || disableTitleEdition}
												style={{ width: '100%' }}
											/>
											<EmailBlock
												user={user}
												emailRef={emailRef}
												emaiCodeRef={emaiCodeRef}
												edit={edit}
												userData={userData}
												onChangeEmail={onChangeEmail}
												onChangeCode={onChangeCode}
												emailSecondsLeft={emailSecondsLeft}
												showEmailCodeButton={showEmailCodeButton}
												handleSendCode={handleSendCode}
												showCodeInput={showCodeInput}
												showConfirmCodeButton={showConfirmCodeButton}
												handleConfirm={handleConfirm}
												errors={errors}
												resendErrors={resendErrors}
												isEmailConfirmed={isEmailConfirmed}
												isResendButton={isResendButton}
												emailDisabled={emailDisabled}
												hideEmailEdition={hideEmailEdition}
												phoneSecondsLeft={phoneSecondsLeft}
												showPhoneCodeInput={showPhoneCodeInput}
											/>
											<PhoneBlock
												countries={countries}
												user={user}
												phoneCodeRef={phoneCodeRef}
												edit={edit}
												userData={userData}
												onChangePhone={onChangePhone}
												onChangePhoneCode={onChangePhoneCode}
												phoneSecondsLeft={phoneSecondsLeft}
												showPhoneCodeButton={showPhoneCodeButton}
												handleSendPhoneCode={handleSendPhoneCode}
												showPhoneCodeInput={showPhoneCodeInput}
												showPhoneConfirmCodeButton={showPhoneConfirmCodeButton}
												handlePhoneConfirm={handlePhoneConfirm}
												errors={errors}
												resendErrors={resendPhoneErrors}
												isPhoneConfirmed={isPhoneConfirmed}
												isResendPhoneButton={isResendPhoneButton}
												phoneDisabled={phoneDisabled}
												hidePhoneEdition={hidePhoneEdition}
												emailSecondsLeft={emailSecondsLeft}
												showCodeInput={showCodeInput}
											/>
										</div>
										{edit && (
											<div
												className={styles.note}
												style={
													errors && Object.keys(errors)?.length > 0
														? { marginTop: '32px' }
														: { marginTop: '10px' }
												}
											>
												<FormattedMessage id="rod.admin.user.edit.confirm" />
											</div>
										)}
										{edit && (
											<Button
												text={<FormattedMessage id={'rod.action.save'} />}
												variant={'primary'}
												onClick={submitHandler}
												className={styles.saveBtn}
												disabled={
													!userData.title ||
													(errors && Object.keys(errors)?.length > 0) ||
													showCodeInput ||
													!hasChanges ||
													disableSaveBtn
												}
											/>
										)}
									</div>
								</div>
							</div>
						</Container>
					</Modal>,
				]}
			</PoseGroup>
		</BaseModal>
	);
};

export default UserEditModal;
