/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 03/03/2021, 16:02
 */
// eslint-disable-next-line
const fields = {
	/* Auth */
	'rod.field.firstname': 'Firstname',
	'rod.field.firstname-small_letter': 'firstname',
	'rod.field.lastname': 'Lastname',
	'rod.field.lastname-small_letter': 'lastname',
	'rod.field.id': 'id',
	'rod.field.patronymic': 'Patronymic',
	'rod.field.no_patronymic': 'I do not have a patronymic',
	'rod.field.birthdate': 'Birthdate',
	'rod.field.birthplace': 'Birthplace',
	'rod.field.birth_place': 'Birthplace (city, street)',
	'rod.field.registration_address': 'Registration address',
	'rod.field.registration_address.street_house':
		'Registration address (street, house)',
	'rod.field.living_place': 'Residential address (city, street)',
	'rod.field.passport': 'Passport number',
	'rod.field.passport_issuer': 'Issued by',
	'rod.field.passport_issue_date': 'Date of issue',
	'rod.field.passport_expire_date': 'Expiration date',
	'rod.field.tax_number': 'Tax number (TIN)',
	'rod.field.name': 'Name',
	'rod.field.email': 'Email address',
	'rod.field.email.en': 'Email',
	'rod.field.phone': 'Phone number',
	'rod.field.password': 'Password',
	'rod.field.password.confirmation': 'Confirm password',
	'rod.field.password.tip':
		'password requirements:<br/><br/>— min length 6 symbols<br/> — only latin letters <br/> — at least one digit<br/> — use upper and lower case letters',
	'rod.field.new_password': 'New password',
	'rod.field.new_password_again': 'Re-type new password',
	'rod.field.confirm': 'confirm',
	'rod.field.sms_code': 'SMS code',
	'rod.field.verify_email_code': 'code from email',
	'rod.field.comment': 'comment',
	'rod.field.society': 'Collection society name',
	'rod.field.ipi': 'IPI',
	'rod.field.ipi.help':
		'<b>IPI number</b> - the international identification number of the Author of the words, the Author of the music or the Music Publisher.',
	'rod.field.ipn': 'IPN number',
	'rod.field.ipn.help':
		'<b>IPN number</b> - international identification number of the Artist.',
	'rod.field.isni': 'ISNI number',
	'rod.field.isni.help':
		'ISNI (International Standard Name Identifier) is an ISO standard, in use by numerous libraries, publishers, databases, and rights management organizations around the world. It is used to uniquely identify persons and organizations involved in creative activities, as well as public personas of both, such as pseudonyms, stage names, record labels or publishing imprints. As an open standard, ISNI is not a proprietary "walled garden" - it is diffused widely on the open web, and is a critical component in Linked Data and Semantic Web applications.',
	'rod.field.number_ipi': 'IPI number',
	'rod.field.number': 'number',

	'rod.field.country': 'Country',
	'rod.field.residence_country': 'Country of residence',
	'rod.field.zip': 'Area code',
	'rod.field.website': 'Web address',
	'rod.field.company_website': 'Web address',
	'rod.field.territories.included': 'Territories',
	'rod.field.territories.excluded': 'Excluded',
	'rod.field.edit': 'Edit',
	'rod.field.sms.helper': 'send code again within',
	'rod.field.email.change': 'change e-mail',
	'rod.field.email.helper': 'change e-mail within',
	'rod.field.phone.change': 'change phone',
	'rod.field.phone.helper': 'change phone within',
	'rod.field.repertoire_count': 'I have xxx compositions',
	'rod.field.company': 'Company',
	'rod.field.sex.male': 'Male',
	'rod.field.sex.female': 'Female',
	'rod.field.upload': '<a>browse</a> and select or drag and drop document file',
	'rod.field.upload.title': 'upload repertoire',
	'rod.field.upload.catalog.type': 'type of repertoire',

	'rod.field.upload.catalog.type.new': 'new repertoire',
	'rod.field.upload.catalog.type.update': 'update repertoire',
	'rod.field.upload.catalog.type.remove': 'withdrawal of repertoire',

	'rod.field.upload.files.title': 'upload files',
	'rod.field.upload.zip': '<a>upload</a> or drag document files',
	'rod.field.upload_v2': '<a>Select</a> or drag & drop recording file',
	'rod.field.upload_server_error':
		'Failed to upload file due to network error. <a>Try again</a>',
	'rod.field.upload_repetroire': '<a>Upload</a> or drag&drop repertoire file',
	'rod.field.update.error.file':
		'Failed to load document. Check the file format.',
	'rod.field.upload.error.title': 'Failed to upload document',
	'rod.field.upload.error.file': 'Please check file type, and <a>try again</a>',
	'rod.field.upload.error.file_type':
		'Please check file type, and <a>try again</a>',
	'rod.field.upload.error.sign_doc.file_type': 'Invalid format. <a>Use</a> pdf',
	'rod.field.upload.error.track_format': 'Failed to upload file {name}',
	'rod.field.upload.error.track.single':
		'Only one track is available for this type of release. If necessary, change the release type',
	'rod.field.upload.error.track.album':
		'For release type "Album" there must be at least 2 tracks and no more than 40 tracks. You can go back and select another release type.',
	'rod.field.upload.error.track.compilation':
		'For release type "Compilation" there must be at least 2 tracks and no more than 40 tracks. You can go back and select another release type.',
	'rod.field.upload.error.track.ep':
		'For release type "EP" there must be at least 2 tracks and no more than 7 tracks. You can go back and select another release type.',

	'rod.field.upload.error.size-mb':
		'Maximum file size is {size} mb, please <a>try again</a>',
	'rod.field.upload.error.size':
		'Maximum file size is {size}, please <a>try again</a>',
	'rod.field.upload.textarea.error.max_count':
		'{value} character limit exceeded',

	'rod.field.upload.error.required':
		'Please upload a signed contract in pdf format and <a>try again</a>',
	'rod.field.personal_entrepreneur.name': 'Company name',
	'rod.field.personal_entrepreneur.name.helper':
		'For example: Sole trader John David Smith',
	'rod.field.personal_entrepreneur.signer': 'Signer',
	'rod.field.personal_entrepreneur.signer.helper':
		'For example: John David Smith',
	'rod.field.personal_entrepreneur.signer_short_name': 'Short name of Signer',
	'rod.field.personal_entrepreneur.signer_short_name.helper':
		'For example: J.D.Smith',
	'rod.field.personal_entrepreneur.registration_date':
		'Date of Sole trader registration',
	'rod.field.personal_entrepreneur.registration_place':
		'Country of sole trader registration',
	'rod.field.personal_entrepreneur.registration_number': 'Registration number',
	'rod.field.personal_entrepreneur.tax_number': 'Tax number (TIN)',
	'rod.field.company.name': 'Company name',
	'rod.field.company.name.helper': 'For example:  Music Service Ltd.',
	'rod.field.company.signer': 'Signer',
	'rod.field.company.signer_position': 'Position of Signer',
	'rod.field.company.signer.helper': 'For example: John David Smith',
	'rod.field.company.signer_short_name': 'Short name of Signer',
	'rod.field.company.signer_short_name.helper': 'For example: J.D. Smith',
	'rod.field.company.attorney': 'Number and Date of  Power of Attorney',
	'rod.field.company.signer_rights': 'Acting as',
	'rod.field.company.registration_date': 'Date of incorporation',
	'rod.field.company.registration_place': 'Country of incorporation',
	'rod.field.company.registration_number': 'Registration number',
	'rod.field.company.tax_number': 'Tax number (TIN)',
	'rod.field.company.tax_number2': 'VAT number',
	'rod.field.company.tax_number_register': 'Tax number (TIN)',

	'rod.field.address': 'Residential address (city, street)',
	'rod.field.address_noCity': 'Residential address (street)',
	'rod.field.address_withCity':
		' Residential address (city, street, home, office number)',
	'rod.field.address_short': 'Address',
	'rod.field.agreement': 'Agreement',
	'rod.field.statute': 'Memorandum of Incorporation',
	'rod.field.attorney': 'Power of Attorney',
	'rod.field.skip_step': 'Skip this info',
	'rod.field.contract_territories': 'Start fill in country to choose',

	'rod.field.payment_type': 'Payment method',
	'rod.field.bank': 'Banking details',
	'rod.field.paypal': 'PayPal',
	'rod.field.paypal_account': 'PayPal account',
	'rod.field.paypal_account.helper': 'Example: paypal.me/UserName',
	'rod.field.paypal_account.pay_method': 'Payment method',
	'rod.field.beneficiary_name': 'Account name',
	'rod.field.bank_account_name': 'Account number (IBAN) ',
	'rod.field.bank_name': 'Bank name',
	'rod.field.bank_country': 'Bank Country',
	'rod.field.swift_code': 'SWIFT/BIC',
	'rod.field.swift': 'SWIFT',
	'rod.field.bank_address': 'Bank address',
	'rod.field.bank_department_address': 'Bank address',
	'rod.field.cor_account': 'Correspondent bank account',
	'rod.field.cor_swift': 'SWIFT/BIC of correspondent bank',

	'rod.field.pseudonym': 'Pseudonym',
	'rod.field.pseudonym.error': 'Maximum length is 255 characters',

	'rod.field.pseudonym-small_letter': 'pseudonym',
	'rod.field.add_outlet': 'add outlet',
	'rod.field.from_date': 'Agreement start date',
	'rod.field.contracts.duration':
		'{count, number} {count, plural, one {month} few {months} other {months}}',
	'rod.field.contracts.currency.RUB': 'Russian Rubles',
	'rod.field.contracts.currency.EUR': 'Euro',
	'rod.field.contracts.currency.USD': 'US Dollars',
	'rod.field.contracts.currency.GBP': 'British Pounds',

	'rod.field.contracts.sign_types.physical': 'Manual',
	'rod.field.contracts.sign_types.sms': 'SMS',

	'rod.field.contracts.agreed1':
		'I have the authority and have no obstacles to sign the agreement',
	'rod.field.contracts.agreed2': 'I agree with terms of the agreement',

	'rod.field.payments.agreed1':
		'I have the authority and have no impediment to signing the invoice',
	'rod.field.payments.agreed2': 'I agree with terms of the agreement',

	'rod.field.contracts.contract_status.error': 'Errors',
	'rod.field.confirm.checkbox':
		'I confirm, that all data provided is valid and correct',
	'rod.field.lang': 'Communication language',
	'rod.field.lang.ru': 'Russian',
	'rod.field.lang.en': 'English',
	'rod.field.role': 'roles',
	'rod.field.outlet': 'outlet',

	// -------------adminV2----------------

	'rod.field.admin.acc-created': 'account created',
	'rod.field.admin.phone': 'phone',
	'rod.field.admin.email': 'email',
	'rod.field.admin.role-selection': 'role(s) selected',
	'rod.field.admin.personal-data': 'personal data',
	'rod.field.admin.contact-data': 'contact data',
	'rod.field.admin.documents': 'documents',
	'rod.field.admin.identifiers': 'identifiers',
	'rod.field.admin.payment-details': 'payment details',
	'rod.field.admin.check-documents': 'check documents',
	'rod.field.admin.conditions': 'conditions',
	'rod.field.admin.revision': 'revision',
	'rod.field.admin.signing': 'signing',
	'rod.field.admin.contracts-revision': 'contracts revision',
	'rod.field.admin.repertoire': 'repertoire',

	'rod.field.admin.user': 'user',
	'rod.field.admin.account-creation': 'account creation',
	'rod.field.admin.contract': 'contract',

	'rod.field.admin.title': 'title',

	'rod.field.admin.last-active': 'last active',

	'rod.field.admin.accounts-to-check': 'accounts to check',
	'rod.field.admin.accounts-moderation-requests':
		'request for invoices moderation',

	'rod.field.admin.removal-and-reloading': 'removal and reloading',
	'rod.field.admin.release-editing': 'release editing',

	'rod.field.admin.status_history.change': 'Change',
	'rod.field.admin.status_history.date_time': 'Date and time',
	'rod.field.admin.status_history.change_type': 'Change type',
	'rod.field.admin.status_history.changed_by': 'Changed by',

	'rod.field.right_type': 'right type',
	'rod.field.admin.statistics_service.not_ready': 'show not ready only',
	'rod.field.admin.statistics_service.outlet': 'outlet',
	'rod.field.admin.statistics_service.date': 'date',
	'rod.field.admin.statistics_service.files': 'files',
	'rod.field.admin.statistics_service.identification': 'identification',
	'rod.field.admin.statistics_service.statistic': 'statistics',
	'rod.field.admin.statistics_service.statistic_upper': 'Statistics',
	'rod.field.admin.statistics_service.rules': 'access&nbsp;rights',
	'rod.field.admin.statistics_service.processing_date': 'date parsed',
	'rod.field.admin.statistics_service.errors': 'errors',

	'rod.field.admin.language': 'Language',
	'rod.field.admin.actions': 'actions',

	'rod.field.admin.reports.publishing_date': 'Publishing date',
	'rod.field.admin.report.published': 'published',
	'rod.field.admin.reports.made': 'made',
	'rod.field.admin.reports.release_date': 'release date',
	'rod.field.admin.reports.sum': 'sum',
	'rod.field.admin.reports.status': 'status',
	'rod.field.admin.reports.currency': 'currency',
	'rod.field.admin.reports.royalty_to_pay': 'royalty to pay',
	'rod.field.admin.reports.royalty_to_pay.whithout_partners':
		'latest period royalty',
	'rod.field.admin.reports.next_report_date': 'next report date',
	'rod.field.admin.reports.acc_id': 'account&nbsp;ID',
	'rod.account.payment.status.new': 'new',
	'rod.account.payment.status.signing': 'signing',
	'rod.account.payment.status.signed': 'signed',
	'rod.account.payment.status.moderation': 'moderation',
	'rod.account.payment.status.rejected': 'rejected',
	'rod.account.payment.status.in_processing': 'in processing',
	'rod.account.payment.status.paid': 'paid',
	'rod.account.payment.status.not_paid': 'not paid',
	'rod.account.payment.amount': 'amount',
	'rod.account.payment.payment_date': 'invoice date',
	'rod.account.payment.invoice_date': 'invoice date',
	'rod.account.payment.invoice_title': 'invoice title',
	'rod.account.payment.payment_title': 'report name',
	'rod.account.payment.payment_type': 'invoice',
};

export default fields;
