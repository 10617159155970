// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'react-grid-system';
import parse from 'html-react-parser';

// UI
import { PrimaryBanner } from 'components';
import { EditButton, InfoButton } from 'components/Buttons';
import { Label } from 'components/Labels/Label';

// Styles
import styles from './SettingsLayout.module.css';

const SettingsLayout = ({
	isEdit,
	handleEditToggle,
	showModal,
	message,
	title,
	banner,
	children,
}) => {
	return (
		<div className={styles.form}>
			<div className={styles.editButton}>
				{!isEdit && <EditButton handleEditToggle={handleEditToggle} />}
			</div>
			<Row>
				<Col sm={9}>
					{message ? (
						<InfoButton
							onClick={showModal({
								text: parse(message),
							})}
						>
							<Label
								text={<FormattedMessage id={title} />}
								font="--gilroy-Medium-40"
								display="inline"
								black
							/>
						</InfoButton>
					) : (
						<Label
							text={<FormattedMessage id={title} />}
							font="--gilroy-Medium-40"
							display="inline"
							black
						/>
					)}
					<Label
						className={
							banner ? `${styles.subTitle} ${styles.banner}` : styles.subTitle
						}
						text={<FormattedMessage id={'rod.form.required_title'} />}
						font="--gilroy-Medium-18"
					/>
				</Col>
			</Row>
			{banner && (
				<PrimaryBanner
					setBanner={() => {}}
					title="rod.account.bank.banner.title"
					text="rod.account.bank.banner.text"
					hideClose
				/>
			)}
			<div
				className={styles.container}
				style={{
					pointerEvents: isEdit ? 'all' : 'none',
					opacity: isEdit ? '1' : '0.6',
					userSelect: isEdit ? 'default' : 'none',
				}}
			>
				{children}
			</div>
		</div>
	);
};

export default SettingsLayout;
