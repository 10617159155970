import React from 'react';
import s from './MuiSliderShares.module.css';
import Box from '@mui/material/Box';

import {
	SharesSlider,
	TypographySlider,
	InputSlider,
} from 'material-design/MuiStyled/MuiStyled';

// import wtf from 'images/wtf.svg';
import remove from 'images/close_grey.svg';
import plus_in_square from 'images/plus_in_square.svg';

export const MuiSliderShares = ({
	value,
	onChange,
	onFocus,
	onBlur,
	onAddPublisherClick,
	labelBtn,
	labelSlider,
	width,
	onRemoveClick,
	bind,
}) => {
	const handleChange = (e) => {
		const res = Number(e.target.value);
		onChange({ target: { value: res } });
		if (bind) {
			if (res >= 100) {
				onChange({ target: { value: '100' } });
			} else if (res === 0) {
				onChange({ target: { value: '0' } });
			} else if (res === 0.01) {
				onChange({ target: { value: '0.1' } });
			} else {
				onChange({ target: { value: res } });
			}
		} else {
			if (res >= 100) {
				onChange({ target: { value: 100 } });
			} else if (res <= 0) {
				onChange({ target: { value: 0 } });
			} else if (res === 0.01) {
				onChange({ target: { value: 0.1 } });
			} else {
				onChange({ target: { value: res } });
			}
		}
	};

	const handleOnFocus = (e) => {
		const res = Number(e.target.value);
		onFocus({ target: { value: res } });
		if (bind) {
			if (res >= 100) {
				onFocus({ target: { value: '100' } });
			} else if (res === 0) {
				onFocus({ target: { value: '0' } });
			} else if (res === 0.01) {
				onFocus({ target: { value: '0.1' } });
			} else {
				onFocus({ target: { value: res } });
			}
		} else {
			if (res >= 100) {
				onFocus({ target: { value: 100 } });
			} else if (res <= 0) {
				onFocus({ target: { value: 0 } });
			} else if (res === 0.01) {
				onFocus({ target: { value: 0.1 } });
			} else {
				onFocus({ target: { value: res } });
			}
		}
	};

	const handleOnBlur = (e) => {
		const res = e.target.value;

		if (res === 0.01) {
			onBlur({ target: { value: '0.1' } });
		} else if (res === 0.01) {
			onBlur({ target: { value: 0.1 } });
		} else {
			onBlur({ target: { value: res } });
		}
	};

	return (
		<>
			<div className={s.wrapper}>
				<Box width={width} className={s.slider}>
					<TypographySlider gutterBottom>{labelSlider}</TypographySlider>
					<InputSlider
						className={s.value}
						value={value}
						size="small"
						onChange={(e) => handleChange(e)}
						onFocus={(e) => handleOnFocus(e)}
						onBlur={(e) => {
							handleOnBlur(e);
						}}
						inputProps={{
							step: 0.01,
							min: 0,
							max: 100,
							type: 'number',
							'aria-labelledby': 'input-slider',
						}}
					/>
					<SharesSlider
						value={value}
						onChange={onChange}
						defaultValue={0}
						aria-label="Default"
						valueLabelDisplay="auto"
					/>
				</Box>
				{onAddPublisherClick && (
					<button onClick={onAddPublisherClick} className={s.btn_add}>
						<img src={plus_in_square} alt="" />
						<p className={s.main_add_btn_text}>{labelBtn}</p>
					</button>
				)}
				{onRemoveClick && (
					<img
						onClick={onRemoveClick}
						className={s.remove_svg}
						alt=""
						src={remove}
					/>
				)}
			</div>
		</>
	);
};
