// Core
import { FormattedMessage } from 'react-intl';

// Utils
import { thousandDiv } from '../../utils/thousandDiv';
import {
	colorRange,
	assignColorIndexToOutlet,
} from '../../utils/assignColorIndexToOutlet';

// Styles
import styles from '../StatisticByDatesChart.module.css';

const CrosshairDataWrapper = ({
	crosshairData,
	legend,
	isCumulativeBttActive,
	total,
}) => {
	return (
		<div className={styles.crosshairData__wrapper}>
			<p className={styles.crosshairData__title}>
				{crosshairData[0] &&
					crosshairData[0]['x']
						.toISOString()
						.slice(0, 10)
						.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3.$2.$1')}
			</p>
			{crosshairData.map((item, index) => {
				return (
					<div key={index} className={styles.crosshairData__item}>
						<div
							className={styles.crosshairData__itemColor}
							style={{
								background: item?.code
									? colorRange[assignColorIndexToOutlet(item.code)]
									: 'transparent',
								borderTop: `2px ${
									legend.find((i) => i.code === item.code)?.color
								} solid`,
							}}
						></div>
						<div>
							<span className={styles.crosshairData__itemTitle}>
								{item.title}:
							</span>
							<span className={styles.crosshairData__itemStatistic}>
								&nbsp;{thousandDiv(item.y)}
								{item.y !== 0 &&
									isCumulativeBttActive &&
									` (${((+item.y * 100) / +total).toFixed(2)}%)`}
							</span>
						</div>
					</div>
				);
			})}
			<div className={styles.crosshairData__total}>
				<span className={styles.crosshairData__itemTitle}>
					<FormattedMessage id={'rod.statistic.chart-total'} />:
				</span>
				<span className={styles.crosshairData__itemStatistic}>
					&nbsp;{thousandDiv(total)}
				</span>
			</div>
		</div>
	);
};

export default CrosshairDataWrapper;
