// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Data
import { radioItems } from './radioItems';

// UI
import { RadioGroup } from 'components/Radio';

// Styles
import styles from './RadioContainer.module.css';

const RadioContainer = ({ selectedValue, handleRadioGroup }) => {
	return (
		<div className={styles.radio_container}>
			<p className={styles.text}>
				<FormattedMessage
					id={'rod.performance_work.whether_work_was_perfomed'}
				/>
			</p>
			<RadioGroup
				className={styles.radio_container}
				name="signAs"
				value={selectedValue}
				onChange={handleRadioGroup}
				items={radioItems}
			/>
		</div>
	);
};

export default RadioContainer;
