// Core
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'react-grid-system';
import parse from 'html-react-parser';

// Context
import { UIContext } from 'contexts/UIContext';

// UI
import { InfoButton } from 'components/Buttons';
import { Label } from 'components/Labels/Label';

// Styles
import styles from './ReportsCheckLayout.module.css';

const ReportsCheckLayout = ({ message, title, children }) => {
	const { showModal } = useContext(UIContext);

	return (
		<div className={styles.form}>
			<Row>
				<Col sm={9}>
					{message ? (
						<InfoButton
							onClick={showModal({
								text: parse(message),
							})}
						>
							<Label
								text={<FormattedMessage id={title} />}
								font="--gilroy-Medium-40"
								display="inline"
								black
							/>
						</InfoButton>
					) : (
						<Label
							text={<FormattedMessage id={title} />}
							font="--gilroy-Medium-40"
							display="inline"
							black
						/>
					)}
					<Label
						className={styles.subTitle}
						text={<FormattedMessage id={'rod.form.required_title'} />}
						font="--gilroy-Medium-18"
					/>
				</Col>
			</Row>
			<div
				className={styles.container}
				// style={{
				// 	pointerEvents: isEdit ? 'all' : 'none',
				// 	opacity: isEdit ? '1' : '0.6',
				// 	userSelect: isEdit ? 'default' : 'none',
				// }}
			>
				{children}
			</div>
		</div>
	);
};

export default ReportsCheckLayout;
