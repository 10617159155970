export const correctDateInTracks = (tracks) => {
	try {
		const validDates = tracks.map((track) => ({
			...track,
			created_date: track.created_date
				? track.created_date.slice(0, 10)
				: track.created_date,
		}));
		return validDates;
	} catch (err) {
		console.error('incorrect/missing date in tracks from back');
		return tracks;
	}
};
