import axios from 'axios';
export default function downloadFromAmazon(url) {
	const fileName = url.slice(0, url.indexOf('?')).split('/').pop();

	// const resultedBlob = new Blob([filePath], {
	// 	type: 'text/csv',
	// });
	// const reader = new FileReader();
	// reader.onloadend = () => {
	// 	const a = document.createElement('a');
	// 	a.href = reader.result;
	// 	a.style.display = 'none';
	// 	a.download = fileName;
	// 	// a.code = 'UTF-8';
	// 	document.body.appendChild(a);
	// 	a.click();
	// 	a.parentNode.removeChild(a);
	// };
	// reader.readAsDataURL(resultedBlob);
	// reader.readAsText(resultedBlob /* , 'UTF-8' */);
	const isSafari = /^(?!.*chrome).*safari/i.test(navigator.userAgent);
	if (isSafari) {
		console.info('Safari browser');
		axios
			.get(url)
			.then((res) => {
				const resultedBlob = new Blob([res.data], {
					type: 'text/csv',
				});
				const reader = new FileReader();
				reader.onloadend = () => {
					const a = document.createElement('a');
					a.href = reader.result;
					a.style.display = 'none';
					a.download = fileName;
					document.body.appendChild(a);
					a.click();
					a.parentNode.removeChild(a);
				};
				reader.readAsDataURL(resultedBlob);
				// reader.readAsText(resultedBlob, 'utf8');
			})
			.catch((error) => console.error('Axios error: ', error));
	} else {
		const link = document.createElement('a');
		link.href = url;
		link.download = true;
		link.click();
		window.open(url, '_parent');
	}
}
