// Styles
import styles from './Card.module.css';

const Card = () => {
	return (
		<div className={styles.card}>
			<div className={styles.image}></div>
      <div className={styles.album}></div>
      <div className={styles.title}></div>
      <div className={styles.streams}></div>
		</div>
	);
};

export default Card;
