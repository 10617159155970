// Core
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { withRoot, withAuth } from 'hocs';

// UI
import { FormInput } from 'components/Form/FormInput';

// Styles
import styles from './RepertoireInfoFilter.module.css';

function RepertoireInfoFilter(props) {
	const { repertoireData, filtersData, changeField, errors, countries } = props;

	return (
		<>
			<div className={styles.filter__container}>
				<div className={styles.headerInfo__item}>
					<div className={styles.headerInfoItemFilter__countryTitle}>
						<FormattedMessage id={'rod.composition.info.territory'} />
					</div>
					<FormInput
						type="muiInputSelect"
						name={'country_id'}
						value={filtersData.country_id}
						onChange={changeField}
						errors={errors}
						data={filtersData}
						className={styles.select}
						items={countries}
					/>
				</div>

				<div className={styles.headerInfo__item}>
					<span className={styles.headerInfoItemFilter__title}>
						<FormattedMessage id={'rod.composition.info.exploitation_type'} />
					</span>

					{repertoireData.exploitationTypes && (
						<FormInput
							type="muiInputSelect"
							value={filtersData.exploitation_type}
							name={'exploitation_type'}
							onChange={changeField}
							errors={errors}
							data={filtersData}
							className={styles.select}
							items={repertoireData.exploitationTypes}
						/>
					)}
				</div>

				<div className={styles.headerInfo__item}>
					<span className={styles.headerInfoItemFilter__title}>
						<FormattedMessage id={'rod.field.right_type'} />
					</span>
					{repertoireData.rightTypes && (
						<FormInput
							type="muiInputSelect"
							value={filtersData.right_type}
							name={'right_type'}
							isNotSelectedRowExists={true}
							onChange={changeField}
							errors={errors}
							data={filtersData}
							className={styles.select}
							items={repertoireData.rightTypes}
						/>
					)}
				</div>

				<div className={styles.headerInfo__item}>
					<span className={styles.headerInfoItemFilter__title}>
						<FormattedMessage id={'rod.release.info.outlet'} />
					</span>
					{repertoireData.repertoireOutlets && (
						<FormInput
							type="muiInputSelect"
							value={filtersData.outlet}
							name={'outlet'}
							isNotSelectedRowExists={true}
							onChange={changeField}
							errors={errors}
							data={filtersData}
							className={styles.select}
							items={repertoireData.repertoireOutlets}
						/>
					)}
				</div>

				<div className={styles.headerInfo__item}>
					<span className={styles.headerInfoItemFilter__title}>
						<FormattedMessage id={'rod.composition.info.product'} />
					</span>
					{repertoireData.repertoireProducts && (
						<FormInput
							type="muiInputSelect"
							value={filtersData.product}
							name={'product'}
							isNotSelectedRowExists={true}
							onChange={changeField}
							errors={errors}
							data={filtersData}
							className={styles.select}
							items={repertoireData.repertoireProducts}
						/>
					)}
				</div>

				<div className={styles.headerInfo__item}>
					<span className={styles.headerInfoItemFilter__title}>
						<FormattedMessage id={'rod.composition.info.commercial_model'} />
					</span>
					{repertoireData.commercialModels && (
						<FormInput
							type="muiInputSelect"
							value={filtersData.commercial_model}
							name={'commercial_model'}
							isNotSelectedRowExists={true}
							onChange={changeField}
							errors={errors}
							data={filtersData}
							className={styles.select}
							items={repertoireData.commercialModels}
						/>
					)}
				</div>
			</div>
		</>
	);
}
export default compose(withAuth, withRoot)(RepertoireInfoFilter);
