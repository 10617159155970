// Core
import { FormattedMessage } from 'react-intl';
import { useContext } from 'react';

// Context
import { LangContext } from 'contexts/LangContext';

// Data
import { radioItems, radioItemsNoReleases } from './radioItems';

// UI
import { BottomNavi, NewHelpInfo } from 'components';
import { RadioGroup } from 'components/Radio';
import { Checkbox } from 'components/Checkboxes/Checkbox';

// Styles
import styles from './ReleasesTakeDown.module.css';

const ReleasesTakeDown = ({
	releases,
	releasesRadio,
	setReleasesRadio,
	checkedReleases,
	setCheckedReleases,
	getLink,
}) => {
	const { lang } = useContext(LangContext);

	const onChangeHandler = (e) => {
		setReleasesRadio(e.target.value);
		if (e.target.value === 'current') {
			setCheckedReleases([]);
		}
	};

	const handleCheckedRelease = (id) => {
		if (checkedReleases.includes(id)) {
			const data = checkedReleases.filter((item) => item !== id);
			setCheckedReleases(data);
		} else {
			setCheckedReleases((prev) => [...prev, id]);
		}
	};

	return (
		<>
			<div className={styles.container}>
				<div className={styles.title}>
					<FormattedMessage id={'rod.release.release.takendown.releases'} />
				</div>
				<NewHelpInfo text="rod.release.release.takendown.releases.help" />
				<RadioGroup
					className={styles.radioGroup}
					name={'releases'}
					value={releasesRadio}
					onChange={onChangeHandler}
					items={!releases.length ? radioItemsNoReleases : radioItems}
					takendown
				/>
				<div className={styles.releases}>
					{releases &&
						releases.length > 0 &&
						releases.map((release) => (
							<Checkbox
								key={release.id}
								text={`${release.title} (${
									lang === 'ru'
										? release.release_type_ru
										: release.release_type_en
								})`}
								onChange={() => {
									handleCheckedRelease(release.id);
								}}
								checked={checkedReleases.includes(release.id)}
								disabled={releasesRadio === 'current'}
								className={releasesRadio === 'current' ? styles.disabled : ''}
							/>
						))}
				</div>
			</div>
			<BottomNavi
				next={() => getLink('outlets')}
				disabled={releasesRadio === 'chosen' && !checkedReleases.length}
			/>
		</>
	);
};

export default ReleasesTakeDown;
