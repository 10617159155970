// Core
import { useContext } from 'react';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { UIContext } from 'contexts/UIContext';
import { RootContext } from 'contexts/RootContext';

// UI
import { Auth } from 'components/Auth';

// Icons
import info from 'images/menu/info.svg';
import pencil from 'images/menu/pencil.svg';

// Styles
import styles from './UserInfo.module.css';

const UserInfo = () => {
	const { user, getUser } = useContext(AuthContext);
	const { showModal } = useContext(UIContext);
	const { countries } = useContext(RootContext);

	return (
		<>
			<div className={styles.container}>
				<div className={styles.container__user}>
					<Auth className={styles.userLogo} userInfo />
					<div className={styles.container__userInfo}>
						<span className={styles.userTitle}>{user.title}</span>
						<span className={styles.userLogin}>{user.login}</span>
					</div>
				</div>
				<div className={styles.iconsContainer}>
					<button
						onClick={showModal(
							{ user, edit: false, getUser, countries },
							'user_edit'
						)}
					>
						<img
							className={styles.iconsContainer__info}
							src={info}
							alt="information"
						/>
					</button>
					<button
						onClick={showModal(
							{ user, edit: true, getUser, countries },
							'user_edit'
						)}
					>
						<img src={pencil} alt="pencil" />
					</button>
				</div>
			</div>
		</>
	);
};

export default UserInfo;
