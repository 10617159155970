export const checkShowTab = (moderationStatus, isReleaseInQueue) => {
	if (
		(['pending', 'rejected'].includes(moderationStatus) || isReleaseInQueue) &&
		moderationStatus !== 'approved'
	) {
		return true;
	} else {
		return false;
	}
};
