/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

import { Col, Container, Row } from 'components/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
// import posed from 'react-pose';
import styles from './Layout.module.css';

class Form extends Component {
	static propTypes = {
		left: PropTypes.node,
		right: PropTypes.node,
	};
	static defaultProps = {};
	static displayName = 'Layout';

	render() {
		return (
			<Container fluid className={styles.TwoColsContainer}>
				<Row className={styles.TwoColsRow}>
					<Col md={6} offset={{ md: 1 }}>
						{this.props.left}
						{this.props.children}

					</Col>

					<Col md={5}>
						{this.props.right}
					</Col>
				</Row>
			</Container>
		);
	}
}

export default Form;
