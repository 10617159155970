export const getTooltip = (disabled) => {
	const lang = localStorage.getItem('lang');
	if (disabled) {
		if (lang === 'en') {
			return 'save the current soundtrack for further actions';
		}

		if (lang === 'ru') {
			return 'сохраните текущую фонограмму для дальнейших действий';
		}
	} else {
		return '';
	}
};
