/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 06/11/2020, 14:03
 */
// eslint-disable-next-line
const ContractStates = {
	NEW: 'new',
	SIGNING: 'signing',
	SIGNED: 'signed',
};

export default ContractStates;
