// Core
import React, { useEffect } from 'react';
import { Container } from 'react-grid-system';
import { FormattedMessage } from 'react-intl';

// UI
import { CloseButton } from 'components/Buttons';
import { Label } from 'components/Labels';

// Icons
import logo_broma from './../../partner/broma/logo.svg';
import logo_hitmaker from './../../partner/hitmaker/logo2.png';
import logo_dgtal from './../../partner/dgtal/logo_dgtal.png';
import logo_mts from './../../partner/mts/logo_mts.svg';
import logo_default_partner from './../../partnerDefault/logo.svg';

// Styles
import s from './AdaptiveModal.module.css';

let logo;

switch (process.env.REACT_APP_CLIENT) {
	case 'broma':
		logo = logo_broma;
		break;
	case 'hitmaker':
		logo = logo_hitmaker;
		break;
	case 'dgtal':
		logo = logo_dgtal;
		break;
	case 'mts':
		logo = logo_mts;
		break;

	default:
		logo = logo_default_partner;
		break;
}

const AdaptiveModal = (props) => {
	useEffect(() => {
		document.body.style.position = 'fixed';

		return () => {
			document.body.style.position = '';
		};
	}, []);

	return (
		<div className={s.ModalWrapper}>
			<Container className={s.Modal} fluid>
				<div className={s.ModalHeader}>
					<div
						style={{ backgroundImage: `url(${logo})` }}
						className={
							process.env.REACT_APP_CLIENT === 'hitmaker'
								? s.Image_partner
								: s.Image_broma
						}
					/>
					<div className={s.closeWrapper}>
						<Label font="--gilroy-Medium-16-19" white className={s.CloseTitle}>
							<FormattedMessage id={`rod.for_all.close`} />
						</Label>
						<CloseButton onClick={props.onClose} small />
					</div>
				</div>
				<div className={s.content} onClick={props.onClose}>
					{props.children}
				</div>
			</Container>
		</div>
	);
};

export default AdaptiveModal;
