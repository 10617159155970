// Core
import React, { useState, useEffect, useContext } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { withAuth } from 'hocs';
import { compose } from 'recompose';
import { Pagination } from 'material-design/components';
import { accounts } from 'services';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { LangContext } from 'contexts/LangContext';

// UI
import { NoData } from 'components';
import { Loading } from 'components';

// Styles
import s from './RepertoireReleaseShipments.module.css';

const RepertoireReleaseShipments = ({ releaseId, getInfo, ...props }) => {
	const { accountId } = useContext(AuthContext);
	const { lang } = useContext(LangContext);
	const [loading, setLoading] = useState(true);
	const [deliveryData, setDeliveryData] = useState([]);
	const [deliveryDataByPages, setDeliveryDataByPages] = useState([]);
	const [renderData, setRenderData] = useState([]);
	const [outlets, setOutlets] = useState([]);
	const [page, setPage] = useState(1);
	const [total, setTotal] = useState(0);

	const status = {
		success: { en: 'success', ru: 'отгружено' },
		error: { en: 'error', ru: 'Ошибка отгрузки' },
	};

	const ITEMS_ON_PAGE = 100;

	useEffect(() => {
		accounts
			.getReleaseInfoForBrowsing(accountId, releaseId)
			.then((res) => {
				res = res.data.data;
				getInfo({ title: res.title, ean: res.ean });
			})
			.catch((error) => {
				console.error('Error', error);
			});
		accounts
			.getReleaseDelivery(accountId, releaseId)
			.then((res) => {
				setDeliveryData(res.data.data);
				setTotal(res.data.total);

				accounts
					.getOutlets(lang)
					.then((res) => {
						res = res.data.data;
						setOutlets(res);
						setLoading(false);
					})
					.catch((error) => {
						setLoading(false);
						console.error('Error', error);
					});
			})
			.catch((error) => {
				setLoading(false);
				console.error('Error', error);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		accounts
			.getOutlets(lang)
			.then((res) => {
				res = res.data.data;
				setOutlets(res);
			})
			.catch((error) => {
				console.error('Error', error);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang]);

	useEffect(() => {
		if (total > 0) {
			setDataByPages();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [total]);

	useEffect(() => {
		if (deliveryDataByPages) setRender();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deliveryDataByPages]);

	const setRender = () => {
		setRenderData(deliveryDataByPages[0]);
	};

	const setDataByPages = (itemsByPage = ITEMS_ON_PAGE) => {
		const data = deliveryData.data;
		let result = [];
		if (itemsByPage > data.length) {
			setDeliveryDataByPages([data]);
		} else {
			for (let i = 0; i < Math.ceil(data.length / itemsByPage); i++) {
				const dataByPage = data.slice(
					i * itemsByPage,
					i * itemsByPage + itemsByPage
				);
				result.push(dataByPage);
			}
			setDeliveryDataByPages(result);
		}
	};

	useEffect(() => {
		setRenderData(deliveryDataByPages[page - 1]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	const getTitleById = (receiver_id) => {
		const resultedObjWithId = outlets.find(
			(element) => +element.id === +receiver_id
		);
		if (resultedObjWithId) {
			return resultedObjWithId.title;
		} else return null;
	};

	return (
		<>
			{loading ? (
				<Loading className={s.loader} />
			) : deliveryData?.length !== 0 ? (
				<div className={s.body}>
					<div className={`${s.table__header} ${s.table}`}>
						<FormattedHTMLMessage id={'rod.release.info.outlet'} />
						<FormattedHTMLMessage id={'rod.for_all.status'} />
						<FormattedHTMLMessage id={'rod.for_all.date'} />
					</div>
					<ul className={s.main}>
						{deliveryData?.length &&
							deliveryData?.map((item) => {
								const title = getTitleById(item.recipient_id);
								const delivery_date = item.date
									.slice(0, 11)
									.replace(/(\d{1,4})-(\d{1,2})-(\d{1,2})/g, '$3.$2.$1');
								return (
									<li
										key={item.recipient_id}
										className={`${s.table__item} ${s.table}`}
									>
										<span>{title ? title : item.recipient_title}</span>
										<span>
											<FormattedMessage
												id={`rod.repertoire.outlets.status.${item.status}`}
											/>
										</span>
										<span>{delivery_date}</span>
									</li>
								);
							})}
					</ul>
					{total > ITEMS_ON_PAGE && (
						<Pagination
							page={page}
							total={total}
							items_on_page={ITEMS_ON_PAGE}
							paginate={(pageNumber) => setPage(pageNumber)}
						/>
					)}
				</div>
			) : (
				<NoData />
			)}
		</>
	);
};
export default compose(withAuth)(RepertoireReleaseShipments);
