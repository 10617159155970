// Core
import { Col, Row } from 'react-grid-system';
import { FormattedHTMLMessage } from 'react-intl';

// Styles
import tableStyles from '../../UserReports/UserReports.module.css';

const TableHeader = () => {
	return (
		<Row className={tableStyles.row_header}>
			<Col width={'15%'}>
				<FormattedHTMLMessage id={'rod.account.payment.invoice_date'} />
			</Col>
			<Col width={'25%'}>
				<FormattedHTMLMessage id={'rod.account.payment.invoice_title'} />
			</Col>
			<Col width={'15%'}>
				<FormattedHTMLMessage id={'rod.field.admin.reports.status'} />
			</Col>
			<Col width={'15%'}>
				<FormattedHTMLMessage id={'rod.account.payment.payment_date'} />
			</Col>
			<Col width={'15%'}>
				<FormattedHTMLMessage id={'rod.account.payment.amount'} />
			</Col>
			<Col width={'10%'}></Col>
		</Row>
	);
};

export default TableHeader;
