// Core
import { FormattedMessage } from 'react-intl';

export const getDraftStatus = (item) => {
	switch (item.step) {
		case 'release':
			return <FormattedMessage id={'rod.release.create.step.release'} />;
		case 'file':
			return <FormattedMessage id={'rod.release.create.step.files'} />;
		case 'tracks':
			return <FormattedMessage id={'rod.release.create.step.tracks'} />;
		case 'authors':
			return <FormattedMessage id={'rod.release.create.step.authors'} />;
		case 'lyrics':
			return <FormattedMessage id={'rod.release.create.step.lyrics'} />;
		case 'cover':
			return <FormattedMessage id={'rod.release.create.step.cover'} />;
		case 'distribution':
			return <FormattedMessage id={'rod.release.create.step.distribution'} />;
		case 'confirm':
			return <FormattedMessage id={'rod.release.create.step.confirm'} />;
		case 'info':
			return <FormattedMessage id={'rod.composition.create.step.info'} />;
		case 'authors_shares':
			return (
				<FormattedMessage id={'rod.composition.create.step.authors_shares'} />
			);
		case 'composition_recordings':
			return (
				<FormattedMessage
					id={'rod.composition.create.step.composition_recordings'}
				/>
			);

		default:
			break;
	}
};
