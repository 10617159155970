export default function Bell({ className }) {
	return (
		<svg
			width="24"
			className={className}
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M21.3644 17.6636L22.0964 16.9823L2.63581 17.6634C2.6863 17.6092 2.74174 17.5511 2.80129 17.4887C3.23903 17.0299 3.89845 16.3387 4.43993 15.2423C5.05807 13.9906 5.50378 12.2681 5.50378 9.75C5.50378 6.60764 7.69903 4.08028 10.7044 3.45597L11.501 3.29049V2.47688V1.5C11.501 1.22339 11.7251 1 12 1C12.2749 1 12.4991 1.22339 12.4991 1.5V2.47688V3.29049L13.2957 3.45597C16.301 4.08028 18.4963 6.60764 18.4963 9.75C18.4963 12.2681 18.942 13.9906 19.5601 15.2423C20.1016 16.3387 20.761 17.0299 21.1988 17.4887C21.2584 17.5512 21.3139 17.6093 21.3644 17.6636ZM2.50001 17.9934C2.50001 17.9933 2.50001 17.9933 2.50001 17.9933L1.50003 18L2.50001 17.9934ZM13.7312 22C13.3855 22.5979 12.7394 23 12 23C11.2607 23 10.6146 22.5979 10.2689 22H13.7312Z"
				stroke="black"
				strokeWidth="2"
			/>
		</svg>
	);
}
