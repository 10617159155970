// Core
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

// Services
import debounce from 'lodash.debounce';
import { compose } from 'recompose';
import { withLang, withRoot } from 'hocs';
import { showError } from 'validators/showError';

// UI
import { Input } from 'components/Inputs/Input';
import Skeleton from 'material-design/components/MuiMultipleCheckedTracks/MuiMultipleCheckedAnimation';
import clearImg from 'images/close_button.svg';

// Styles
import styles from './AutosuggestInput.module.css';

class AutosuggestInput extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			value: props.value,
			items: [],
			text: props.value.title,
			isLoading: false,
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.value !== this.props.value) {
			this.setState({
				value: this.props.value,
				text: this.props.value.title,
			});
		}
	}

	sendRequest = (value) => {
		if (value.length < 2) {
			this.setState({ items: [] });
			return;
		}
		const {
			rootContext: { suggestSociety },
		} = this.props;

		suggestSociety(value)
			.then((res) => {
				this.setState({ items: res });
			})
			.catch((err) => {
				console.error('Error:', err);
			})
			.finally(() => {
				this.setState({ isLoading: false });
			});
	};

	clear = () => {
		this.setState({
			value: null,
			text: '',
		});
		this.props.onChange({});
	};

	change = (item) => () => {
		this.setState({
			items: [],
			value: item,
			text: item.title_ru,
		});
		this.props.onChange(item);
	};

	debouncedChangeHandler = debounce((value) => this.sendRequest(value), 500);

	changeValue = (value) => {
		if (value.length > 2) {
			this.setState({ isLoading: true });
		} else if (value.length < 2 && this.state.isLoading) {
			this.setState({ isLoading: false });
		}

		this.setState({
			text: value,
			value: null,
		});

		this.debouncedChangeHandler(value);
	};

	render() {
		const { onChange, langContext, ...props } = this.props;
		const { lang } = langContext;
		const { items, value, text } = this.state;

		return (
			<div className={styles.Component}>
				<Input
					{...props}
					showError={showError('society')}
					required
					value={text}
					onChange={this.changeValue}
				/>
				{text && (
					<button className={styles.Icon} onClick={this.clear}>
						<img src={clearImg} alt="clear" />
					</button>
				)}
				{this.state.isLoading && (
					<div className={styles.Skeleton}>
						<Skeleton />
					</div>
				)}
				{!this.state.isLoading &&
					text &&
					!value &&
					text.length > 2 &&
					items.length === 0 && (
						<div className={styles.Dropdown}>
							<ul className={styles.List}>
								<li>
									<FormattedMessage id={'rod.autosuggest.nothing_to_show'} />
								</li>
							</ul>
						</div>
					)}
				{items.length > 0 && (
					<div className={styles.Dropdown}>
						<ul className={styles.List}>
							{items.map((item) => (
								<li key={item.title_ru} onClick={this.change(item)}>
									{lang === 'ru' ? item.title_ru : item.title_en}
								</li>
							))}
						</ul>
					</div>
				)}
			</div>
		);
	}
}

AutosuggestInput.propTypes = {};

AutosuggestInput.defaultProps = {};

export default compose(withRoot, withLang)(AutosuggestInput);
