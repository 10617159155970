export const colorRange = [
	'#F3C7CC',
	'#F5DCC1',
	'#CBECD7',
	'#C5E0FF',
	'#F7ECC0',
	'#F0CEF8',
	'#EEDAFF',
	'#CBF8F7',
	'#F6CFE1',
	'#CFE3FC',
];

export const strokeColorRange = [
	'#FB233B',
	'#FF9220',
	'#1ED760',
	'#0077FF',
	'#FCCA00',
	'#DC51FC',
	'#A237FF',
	'#2AF0EA',
	'#DD2A7B',
	'#1778F2',
];

export const assignColorIndexToOutlet = (outlet) => {
	let colorIndex = 0;

	switch (outlet) {
		case 'apple':
			colorIndex = 0;
			break;
		case 'ok':
			colorIndex = 1;
			break;
		case 'spotify':
			colorIndex = 2;
			break;
		case 'vk':
			colorIndex = 3;
			break;
		case 'yandex':
			colorIndex = 4;
			break;
		case 'zvuk':
			colorIndex = 5;
			break;
		case 'deezer':
			colorIndex = 6;
			break;
		case 'tiktok':
			colorIndex = 7;
			break;
		case 'instagram':
			colorIndex = 8;
			break;
		case 'facebook':
			colorIndex = 9;
			break;
		default:
			break;
	}
	return colorIndex;
};
