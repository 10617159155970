// Core
import React, { useState, useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { UIContext } from 'contexts/UIContext';

// Services
import { accounts } from 'services';
import { withUI } from 'hocs';

// UI
import { Wizard } from 'components/Wizard';
import { EndConfirmationPage } from 'components/EndConfirmationPage';
import compositionCompletionsSteps from 'material-design/constants/compositionCompletionsSteps';

// Steps
import { initialSteps } from './initialSteps/initialSteps';
import {
	AuthorsShares,
	BasicInfo,
	PerformanceWork,
	CompositionLyrics,
	Review,
} from './steps';

// Styles
import s from './AddCompositionPage.module.css';

const AddCompositionPage = (props) => {
	const [confirm, setConfirm] = useState(false);
	const [errors, setErrors] = useState({});
	const [compositionTypeId] = useState('');
	const [completionStep, setCompletionStep] = useState('');
	const [lastStep, setLastStep] = useState(null);
	const [defaultSteps, setDefaultSteps] = useState(initialSteps);

	const compositionId = localStorage.getItem('compositionId');
	const authContext = useContext(AuthContext);
	const history = useHistory();
	const location = useLocation();
	const params = useParams();
	const {
		reloadWhenCreateComposition,
		showBackNavi,
		hideBackNavi,
		backTargetUrl,
	} = useContext(UIContext);

	useEffect(() => {
		const url = history.location?.state?.url || backTargetUrl;
		showBackNavi(url);

		return () => {
			hideBackNavi();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const prevStep = () => {
		switch (completionStep) {
			case compositionCompletionsSteps.COMPLETED:
				setConfirm(false);
				return compositionCompletionsSteps.REVIEW;
			case compositionCompletionsSteps.REVIEW:
				defaultSteps[3].status = 'active';
				defaultSteps[0].status = 'complete';
				defaultSteps[1].status = 'complete';
				defaultSteps[2].status = 'complete';
				defaultSteps[4].status = 'default';
				setDefaultSteps(defaultSteps);
				return compositionCompletionsSteps.PERFORMANCE_WORK;
			case compositionCompletionsSteps.PERFORMANCE_WORK:
				defaultSteps[2].status = 'active';
				defaultSteps[0].status = 'complete';
				defaultSteps[1].status = 'complete';
				defaultSteps[3].status = 'default';
				defaultSteps[4].status = 'default';
				setDefaultSteps(defaultSteps);
				return compositionCompletionsSteps.LYRICS;
			case compositionCompletionsSteps.LYRICS:
				defaultSteps[1].status = 'active';
				defaultSteps[0].status = 'complete';
				defaultSteps[2].status = 'default';
				defaultSteps[3].status = 'default';
				defaultSteps[4].status = 'default';
				setDefaultSteps(defaultSteps);
				return compositionCompletionsSteps.AUTHORS_SHARES;
			case compositionCompletionsSteps.AUTHORS_SHARES:
				defaultSteps[0].status = 'active';
				defaultSteps[1].status = 'default';
				defaultSteps[2].status = 'default';
				defaultSteps[3].status = 'default';
				defaultSteps[4].status = 'default';
				setDefaultSteps(defaultSteps);
				return compositionCompletionsSteps.BASIC_INFO;
			default:
				return completionStep;
		}
	};

	const getBasicInfo = (data) => {
		return new Promise((resolve, reject) => {
			setErrors({});
			const id = authContext.accountId;
			const clone = cloneDeep(data);
			if (clone.created_date.length > 10) {
				clone.created_date = clone.created_date.substring(0, 10);
				update();
			}

			function executeAfterPromise(
				res,
				history,
				update,
				setLastStep,
				setCompletionStep,
				compositionCompletionsSteps,
				accounts
			) {
				const compositionId = res.data.data.id;
				accounts.updateCompositionStep(compositionId, {
					step: compositionCompletionsSteps.AUTHORS_SHARES,
				});
				setLastStep(compositionCompletionsSteps.AUTHORS_SHARES);
				history.push(`/compositions/${compositionId}/authors_shares`);
				setCompletionStep(compositionCompletionsSteps.AUTHORS_SHARES);
				update();
			}

			if (!compositionId) {
				clone.account_id = id;
				accounts
					.createComposition(clone)
					.then((res) => {
						localStorage.setItem('compositionId', res.data.data.id);
						resolve(res);
						executeAfterPromise(
							res,
							history,
							update,
							setLastStep,
							setCompletionStep,
							compositionCompletionsSteps,
							accounts
						);
						resolve(res);
					})
					.catch((error) => {
						setErrors(error.response.data.errors);
						reject(error);
					});
			} else {
				const cloneData = { ...clone, account_id: authContext.accountId };
				accounts
					.updateComposition(compositionId, cloneData)
					.then((res) => {
						resolve(res);
						executeAfterPromise(
							res,
							history,
							update,
							setLastStep,
							setCompletionStep,
							compositionCompletionsSteps,
							accounts
						);
						resolve(res);
					})
					.catch((error) => {
						setErrors(error.response.data.errors);
						reject(error);
					});
			}
		});
	};

	const getAuthorsShares = (clone) => {
		return new Promise((resolve, reject) => {
			const compositionId = localStorage.getItem('compositionId');

			accounts
				.uploadCompositionContributors(compositionId, {
					contributors: clone,
				})
				.then(() => {
					return accounts.updateCompositionStep(compositionId, {
						step: compositionCompletionsSteps.LYRICS,
					});
				})
				.then(() => {
					setLastStep(compositionCompletionsSteps.LYRICS);
					setCompletionStep(compositionCompletionsSteps.LYRICS);
					history.push(`/compositions/${compositionId}/lyrics`);
					update();
					resolve();
				})
				.catch((error) => {
					console.error('Error', error);
					reject(error);
				});
		});
	};

	const setLyrics = (data) => {
		return new Promise((resolve, reject) => {
			const compositionId = localStorage.getItem('compositionId');

			accounts
				.addCompositionLyrics(compositionId, data)
				.then((res) => {
					return accounts.updateCompositionStep(compositionId, {
						step: compositionCompletionsSteps.PERFORMANCE_WORK,
					});
				})
				.then(() => {
					setLastStep(compositionCompletionsSteps.PERFORMANCE_WORK);
					history.push(`/compositions/${compositionId}/composition_recordings`);
					setCompletionStep(compositionCompletionsSteps.PERFORMANCE_WORK);
					update();
					resolve();
				})
				.catch((error) => {
					console.error('Error', error);
					reject(error);
				});
		});
	};

	const getPerformanceWork = (data) => {
		return new Promise((resolve, reject) => {
			accounts
				.updateCompositionStep(compositionId, {
					step: compositionCompletionsSteps.REVIEW,
				})
				.then(() => {
					setLastStep(compositionCompletionsSteps.REVIEW);
					history.push(`/compositions/${compositionId}/review`);
					setCompletionStep(compositionCompletionsSteps.REVIEW);
					update();
					resolve();
				})
				.catch((error) => {
					console.error('Error', error);
					reject(error);
				});
		});
	};

	const getReview = () => {
		return new Promise((resolve, reject) => {
			accounts
				.updateCompositionStep(compositionId, {
					step: 'completed',
				})
				.then(() => {
					setLastStep('completed');
					history.push(`/compositions/moderation`);
					setCompletionStep(compositionCompletionsSteps.COMPLETED);
					update();
					resolve();
				})
				.catch((err) => {
					if (err.response && err.response.status === 422) {
						setErrors({ code: '422' });
						console.error(err.response.data.errors);
					}
					reject(err);
				});
		});
	};

	const handlePrevStep = () => {
		let currStep = prevStep();
		setCompletionStep(currStep);
		const compositionId = localStorage.getItem('compositionId');
		if (currStep === 'info') currStep = 'create';
		history.push(`/compositions/${compositionId}/${currStep}`);
	};

	const checkDateErrors = (error) => {
		if (error && errors.created_date) {
			delete errors.created_date;
			setErrors({ ...errors });
		}
	};

	const handleOpenPrevTabs = (id) => {
		switch (id) {
			case 1:
				setCompletionStep(compositionCompletionsSteps.BASIC_INFO);
				setDefaultSteps([
					{
						id: 1,
						status: 'active',
						title: <FormattedMessage id={'rod.add_composition_page.step1'} />,
					},
					{
						id: 2,
						status: 'default',
						title: <FormattedMessage id={'rod.add_composition_page.step2'} />,
					},
					{
						id: 3,
						status: 'default',
						title: <FormattedMessage id={'rod.add_composition_page.step3'} />,
					},
					{
						id: 4,
						status: 'default',
						title: <FormattedMessage id={'rod.add_composition_page.step4'} />,
					},
					{
						id: 5,
						status: 'default',
						title: <FormattedMessage id={'rod.add_composition_page.step5'} />,
					},
				]);

				break;
			case 2:
				setCompletionStep(compositionCompletionsSteps.AUTHORS_SHARES);
				setDefaultSteps([
					{
						id: 1,
						status: 'complete',
						title: <FormattedMessage id={'rod.add_composition_page.step1'} />,
					},
					{
						id: 2,
						status: 'active',
						title: <FormattedMessage id={'rod.add_composition_page.step2'} />,
					},
					{
						id: 3,
						status: 'default',
						title: <FormattedMessage id={'rod.add_composition_page.step3'} />,
					},
					{
						id: 4,
						status: 'default',
						title: <FormattedMessage id={'rod.add_composition_page.step4'} />,
					},
					{
						id: 5,
						status: 'default',
						title: <FormattedMessage id={'rod.add_composition_page.step5'} />,
					},
				]);
				break;
			case 3:
				setCompletionStep(compositionCompletionsSteps.LYRICS);
				setDefaultSteps([
					{
						id: 1,
						status: 'complete',
						title: <FormattedMessage id={'rod.add_composition_page.step1'} />,
					},
					{
						id: 2,
						status: 'complete',
						title: <FormattedMessage id={'rod.add_composition_page.step2'} />,
					},
					{
						id: 3,
						status: 'active',
						title: <FormattedMessage id={'rod.add_composition_page.step3'} />,
					},
					{
						id: 4,
						status: 'default',
						title: <FormattedMessage id={'rod.add_composition_page.step4'} />,
					},
					{
						id: 5,
						status: 'default',
						title: <FormattedMessage id={'rod.add_composition_page.step5'} />,
					},
				]);
				break;
			case 4:
				setCompletionStep(compositionCompletionsSteps.PERFORMANCE_WORK);
				setDefaultSteps([
					{
						id: 1,
						status: 'complete',
						title: <FormattedMessage id={'rod.add_composition_page.step1'} />,
					},
					{
						id: 2,
						status: 'complete',
						title: <FormattedMessage id={'rod.add_composition_page.step2'} />,
					},
					{
						id: 3,
						status: 'complete',
						title: <FormattedMessage id={'rod.add_composition_page.step3'} />,
					},
					{
						id: 4,
						status: 'active',
						title: <FormattedMessage id={'rod.add_composition_page.step4'} />,
					},
					{
						id: 5,
						status: 'default',
						title: <FormattedMessage id={'rod.add_composition_page.step5'} />,
					},
				]);
				break;
			case 5:
				setCompletionStep(compositionCompletionsSteps.REVIEW);
				setDefaultSteps([
					{
						id: 1,
						status: 'complete',
						title: <FormattedMessage id={'rod.add_composition_page.step1'} />,
					},
					{
						id: 2,
						status: 'complete',
						title: <FormattedMessage id={'rod.add_composition_page.step2'} />,
					},
					{
						id: 3,
						status: 'complete',
						title: <FormattedMessage id={'rod.add_composition_page.step3'} />,
					},
					{
						id: 4,
						status: 'complete',
						title: <FormattedMessage id={'rod.add_composition_page.step4'} />,
					},
					{
						id: 5,
						status: 'active',
						title: <FormattedMessage id={'rod.add_composition_page.step5'} />,
					},
				]);
				break;
			default:
				break;
		}
	};

	const renderStep = () => {
		switch (completionStep) {
			case compositionCompletionsSteps.BASIC_INFO:
				return (
					<BasicInfo
						onChange={getBasicInfo}
						errors={errors}
						checkDateErrors={checkDateErrors}
					/>
				);
			case compositionCompletionsSteps.AUTHORS_SHARES:
				return (
					<AuthorsShares
						onChange={getAuthorsShares}
						handlePrevStep={handlePrevStep}
						compositionTypeId={compositionTypeId}
					/>
				);
			case compositionCompletionsSteps.LYRICS:
				return (
					<CompositionLyrics
						onChange={setLyrics}
						handlePrevStep={handlePrevStep}
					/>
				);
			case compositionCompletionsSteps.PERFORMANCE_WORK:
				return (
					<PerformanceWork
						lastStep={lastStep}
						setStep={setStep}
						onChange={getPerformanceWork}
						handlePrevStep={handlePrevStep}
					/>
				);
			case compositionCompletionsSteps.REVIEW:
				return (
					<Review
						handlePrevStep={handlePrevStep}
						errors={errors}
						onChange={getReview}
					/>
				);
			case compositionCompletionsSteps.COMPLETED:
				return (
					<EndConfirmationPage
						title={'rod.composition.create.step.end.title'}
						text={'rod.composition.create.step.end.text'}
						textLinks={{
							link: {
								link: '/repertoire/compositions',
								phrase: 'rod.treaties_page.composition',
							},
						}}
						btnText={'rod.action.to_index_page'}
						btnLink={`/`}
					/>
				);
			default:
				return;
		}
	};

	const setStep = (step) => {
		setLastStep(step);
	};

	const update = () => {
		switch (completionStep) {
			case compositionCompletionsSteps.BASIC_INFO:
				setDefaultSteps([
					{
						id: 1,
						status: 'complete',
						title: <FormattedMessage id={'rod.add_composition_page.step1'} />,
					},
					{
						id: 2,
						status: 'active',
						title: <FormattedMessage id={'rod.add_composition_page.step2'} />,
					},
					{
						id: 3,
						status: 'default',
						title: <FormattedMessage id={'rod.add_composition_page.step3'} />,
					},
					{
						id: 4,
						status: 'default',
						title: <FormattedMessage id={'rod.add_composition_page.step4'} />,
					},
					{
						id: 5,
						status: 'default',
						title: <FormattedMessage id={'rod.add_composition_page.step5'} />,
					},
				]);
				return;
			case compositionCompletionsSteps.AUTHORS_SHARES:
				setDefaultSteps([
					{
						id: 1,
						status: 'complete',
						title: <FormattedMessage id={'rod.add_composition_page.step1'} />,
					},
					{
						id: 2,
						status: 'complete',
						title: <FormattedMessage id={'rod.add_composition_page.step2'} />,
					},
					{
						id: 3,
						status: 'active',
						title: <FormattedMessage id={'rod.add_composition_page.step3'} />,
					},
					{
						id: 4,
						status: 'default',
						title: <FormattedMessage id={'rod.add_composition_page.step4'} />,
					},
					{
						id: 5,
						status: 'default',
						title: <FormattedMessage id={'rod.add_composition_page.step5'} />,
					},
				]);
				return;
			case compositionCompletionsSteps.LYRICS:
				setDefaultSteps([
					{
						id: 1,
						status: 'complete',
						title: <FormattedMessage id={'rod.add_composition_page.step1'} />,
					},
					{
						id: 2,
						status: 'complete',
						title: <FormattedMessage id={'rod.add_composition_page.step2'} />,
					},
					{
						id: 3,
						status: 'complete',
						title: <FormattedMessage id={'rod.add_composition_page.step3'} />,
					},
					{
						id: 4,
						status: 'active',
						title: <FormattedMessage id={'rod.add_composition_page.step4'} />,
					},
					{
						id: 5,
						status: 'default',
						title: <FormattedMessage id={'rod.add_composition_page.step5'} />,
					},
				]);
				return;
			case compositionCompletionsSteps.PERFORMANCE_WORK:
				setDefaultSteps([
					{
						id: 1,
						status: 'complete',
						title: <FormattedMessage id={'rod.add_composition_page.step1'} />,
					},
					{
						id: 2,
						status: 'complete',
						title: <FormattedMessage id={'rod.add_composition_page.step2'} />,
					},
					{
						id: 3,
						status: 'complete',
						title: <FormattedMessage id={'rod.add_composition_page.step3'} />,
					},
					{
						id: 4,
						status: 'complete',
						title: <FormattedMessage id={'rod.add_composition_page.step4'} />,
					},
					{
						id: 5,
						status: 'active',
						title: <FormattedMessage id={'rod.add_composition_page.step5'} />,
					},
				]);

				return;
			case compositionCompletionsSteps.REVIEW:
				setDefaultSteps([
					{
						id: 1,
						status: 'complete',
						title: <FormattedMessage id={'rod.add_composition_page.step1'} />,
					},
					{
						id: 2,
						status: 'complete',
						title: <FormattedMessage id={'rod.add_composition_page.step2'} />,
					},
					{
						id: 3,
						status: 'complete',
						title: <FormattedMessage id={'rod.add_composition_page.step3'} />,
					},
					{
						id: 4,
						status: 'complete',
						title: <FormattedMessage id={'rod.add_composition_page.step4'} />,
					},
					{
						id: 5,
						status: 'complete',
						title: <FormattedMessage id={'rod.add_composition_page.step5'} />,
					},
				]);

				return;
			case compositionCompletionsSteps.COMPLETED:
				setConfirm(true);
				return;
			default:
				return;
		}
	};

	useEffect(() => {
		if (reloadWhenCreateComposition) window.location.reload();
	}, [reloadWhenCreateComposition]);

	useEffect(() => {
		const {
			UIContext: { showTitle },
		} = props;

		const compositionId = localStorage.getItem('compositionId');
		let url_step = location.pathname.replace(/.+\/(.+)/gm, '$1');

		if (!compositionId) {
			history.push('/compositions/create');
			setCompletionStep(compositionCompletionsSteps.BASIC_INFO);
		}

		if (compositionId) {
			accounts.getCompositionData(compositionId).then((res) => {
				setLastStep(res.data.data.step);
				const _promise = new Promise((resolve, reject) => {
					if (url_step === 'create') resolve(res.data.data.step);
					if (compositionId && params.id === compositionId) {
						accounts.getCompositionData(compositionId).then((res) => {
							const arrSteps = [
								'info',
								'authors_shares',
								'lyrics',
								'composition_recordings',
								'confirm',
							];
							arrSteps.forEach((item, index) => {
								if (
									url_step === item &&
									index <= arrSteps.indexOf(res.data.data.step)
								) {
									resolve(item);
								}
							});
							resolve(res.data.data.step);
						});
					} else {
						resolve(res.data.data.step);
					}
				});
				_promise.then((current_step) => {
					setCompletionStep(current_step);
					switch (current_step) {
						case 'info':
							history.push(`/compositions/create`);
							break;
						case 'authors_shares':
							setDefaultSteps([
								{
									id: 1,
									status: 'complete',
									title: (
										<FormattedMessage id={'rod.add_composition_page.step1'} />
									),
								},
								{
									id: 2,
									status: 'active',
									title: (
										<FormattedMessage id={'rod.add_composition_page.step2'} />
									),
								},
								{
									id: 3,
									status: 'default',
									title: (
										<FormattedMessage id={'rod.add_composition_page.step3'} />
									),
								},
								{
									id: 4,
									status: 'default',
									title: (
										<FormattedMessage id={'rod.add_composition_page.step4'} />
									),
								},
								{
									id: 5,
									status: 'default',
									title: (
										<FormattedMessage id={'rod.add_composition_page.step5'} />
									),
								},
							]);
							history.push(`/compositions/${res.data.data.id}/authors_shares`);
							break;
						case 'lyrics':
							setDefaultSteps([
								{
									id: 1,
									status: 'complete',
									title: (
										<FormattedMessage id={'rod.add_composition_page.step1'} />
									),
								},
								{
									id: 2,
									status: 'complete',
									title: (
										<FormattedMessage id={'rod.add_composition_page.step2'} />
									),
								},
								{
									id: 3,
									status: 'active',
									title: (
										<FormattedMessage id={'rod.add_composition_page.step3'} />
									),
								},
								{
									id: 4,
									status: 'default',
									title: (
										<FormattedMessage id={'rod.add_composition_page.step4'} />
									),
								},
								{
									id: 5,
									status: 'default',
									title: (
										<FormattedMessage id={'rod.add_composition_page.step5'} />
									),
								},
							]);
							history.push(`/compositions/${res.data.data.id}/lyrics`);
							break;
						case 'composition_recordings':
							setDefaultSteps([
								{
									id: 1,
									status: 'complete',
									title: (
										<FormattedMessage id={'rod.add_composition_page.step1'} />
									),
								},
								{
									id: 2,
									status: 'complete',
									title: (
										<FormattedMessage id={'rod.add_composition_page.step2'} />
									),
								},
								{
									id: 3,
									status: 'complete',
									title: (
										<FormattedMessage id={'rod.add_composition_page.step3'} />
									),
								},
								{
									id: 4,
									status: 'active',
									title: (
										<FormattedMessage id={'rod.add_composition_page.step4'} />
									),
								},
								{
									id: 5,
									status: 'default',
									title: (
										<FormattedMessage id={'rod.add_composition_page.step5'} />
									),
								},
							]);
							history.push(
								`/compositions/${compositionId}/composition_recordings`
							);
							break;
						case 'confirm':
							setDefaultSteps([
								{
									id: 1,
									status: 'complete',
									title: (
										<FormattedMessage id={'rod.add_composition_page.step1'} />
									),
								},
								{
									id: 2,
									status: 'complete',
									title: (
										<FormattedMessage id={'rod.add_composition_page.step2'} />
									),
								},
								{
									id: 3,
									status: 'complete',
									title: (
										<FormattedMessage id={'rod.add_composition_page.step3'} />
									),
								},
								{
									id: 4,
									status: 'complete',
									title: (
										<FormattedMessage id={'rod.add_composition_page.step4'} />
									),
								},
								{
									id: 5,
									status: 'active',
									title: (
										<FormattedMessage id={'rod.add_composition_page.step5'} />
									),
								},
							]);
							history.push(`/compositions/${compositionId}/review`);
							break;
						default:
							history.push(`/compositions/create`);
							completionStep('info');
							break;
					}
				});
			});
		}

		showTitle('rod.btn.add_composition');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={s.container}>
			{!confirm && (
				<Wizard
					steps={defaultSteps}
					className={s.Wizard}
					handleOpenPrevTabs={handleOpenPrevTabs}
				/>
			)}

			<div className={s.form_container}>{renderStep()}</div>
		</div>
	);
};

export default withUI(AddCompositionPage);
