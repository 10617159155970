/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 04/02/2021, 20:26
 */

import enMessages from 'messages/en';
import ruMessages from 'messages/ru';
import React from 'react';
import { IntlProvider } from 'react-intl';

export const DEFAULT_LANG = ~navigator.language.search('ru') ? 'ru' : 'en';
export const DEFAULT_LEXEMES = ruMessages;
const LangContext = React.createContext();

class LangProvider extends React.Component {
	constructor(props) {
		super(props);
		const lang =
			process.env.REACT_APP_CLIENT === 'dgtal'
				? 'en'
				: localStorage.getItem('lang') || DEFAULT_LANG;

		this.state = {
			lang,
			lexemes: lang === 'ru' ? ruMessages : enMessages,
			isShowLang: true,
		};
	}

	setLanguage = (lang, flag = true) => {
		if (process.env.REACT_APP_CLIENT === 'dgtal') {
			return this.setState(
				{
					lang: 'en',
					lexemes: enMessages,
					isShowLang: false,
				},
				() => localStorage.setItem('lang', 'en')
			);
		}

		switch (lang) {
			case 'ru':
				this.setState(
					{
						lang: 'ru',
						lexemes: ruMessages,
						isShowLang: flag,
					},
					() => localStorage.setItem('lang', lang)
				);
				break;
			case 'en':
				this.setState(
					{
						lang: 'en',
						lexemes: enMessages,
						isShowLang: flag,
					},
					() => localStorage.setItem('lang', lang)
				);
				break;

			default:
				this.setState({
					lang: DEFAULT_LANG,
					lexemes: DEFAULT_LEXEMES,
					isShowLang: flag,
				});
		}
	};

	getTranslations = (scope) => {
		// this.setState({ lexemes: ['foo', scope] });
	};

	getLanguages = (languages = []) => {
		const langArr = [];

		if (languages.length) {
			languages.forEach((item) => {
				if (item.toLowerCase() === 'ru' || item.toLowerCase() === 'en')
					langArr.push(item.toLowerCase());
			});

			if (langArr.length && langArr.length < 2) {
				this.setLanguage(langArr[0], false);
			}
		}
	};

	render() {
		window.handleLanguageChange(this.state.lang);

		return (
			<IntlProvider locale={this.state.lang} messages={this.state.lexemes}>
				<LangContext.Provider
					value={{
						lang: this.state.lang,
						lexemes: this.state.lexemes,
						isShowLang: this.state.isShowLang,
						setLanguage: this.setLanguage,
						getTranslations: this.getTranslations,
						getLanguages: this.getLanguages,
					}}
				>
					{this.props.children}
				</LangContext.Provider>
			</IntlProvider>
		);
	}
}

const LangConsumer = LangContext.Consumer;

export { LangProvider, LangConsumer, LangContext };
