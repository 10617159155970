//******************************/
// SPACES SENSITIVE IN BRACKETS!
//******************************/

let partner, platform;
switch (process.env.REACT_APP_CLIENT) {
	case 'broma':
		partner = {
			title: ' Broma&nbsp;16',
			title_space: ' Broma 16',
			title_solid: ' Broma16',
			title_rod_company: ' ROD Broma 16',
		};
		platform = {
			title: ' Heaven&nbsp;11',
			title_space: 'Heaven 11',
			title_solid: 'Heaven11',
		};
		break;
	case 'hitmaker':
		partner = {
			title: ' Hitmaker&nbsp;Music',
			title_space: ' Hitmaker Music',
			title_solid: ' Hitmaker Music',
			title_rod_company: 'Hitmaker Music',
		};
		platform = {
			title: '', //' Heaven&nbsp;11',
			title_space: '', //'Heaven 11',
			title_solid: '', //'Heaven11',
		};
		break;
	case 'dgtal':
		partner = {
			title: ' DGTal&nbsp;Solutions',
			title_space: ' DGTal Solutions',
			title_solid: ' DGTal Solutions',
			title_rod_company: 'DGTal Solutions',
		};
		platform = {
			title: ' Heaven&nbsp;11',
			title_space: 'Heaven 11',
			title_solid: 'Heaven11',
		};
		break;
	case 'mts':
		partner = {
			title: ' МТС&nbsp;Лейбл',
			title_space: ' МТС Лейбл',
			title_solid: ' МТС Лейбл',
			title_rod_company: 'МТС Лейбл',
		};
		platform = {
			title: ' Heaven&nbsp;11',
			title_space: 'Heaven 11',
			title_solid: 'Heaven11',
		};
		break;
	default:
		partner = {
			title: ' Broma&nbsp;16',
			title_space: ' Broma 16',
			title_solid: ' Broma16',
			title_rod_company: ' ROD Broma 16',
		};
		platform = {
			title: ' Heaven&nbsp;11',
			title_space: 'Heaven 11',
			title_solid: 'Heaven11',
		};
}

const compound =
	process.env.REACT_APP_CLIENT === 'broma'
		? { title: ` ${partner.title} или${platform.title}` }
		: process.env.REACT_APP_CLIENT === 'hitmaker'
		? {
				title: ` ${partner.title}`,
		  }
		: { title: ` ${partner.title} или${platform.title}` };

const partner_compound = {
	title_to: ` к ${partner.title}`,
};

const platform_compound =
	process.env.REACT_APP_CLIENT === 'broma'
		? {
				title_powered: `powered by${platform.title}`,
				title_in: ` в${platform.title}`,
		  }
		: process.env.REACT_APP_CLIENT === 'hitmaker' ||
		  process.env.REACT_APP_CLIENT === 'mts'
		? {
				title_powered: ` `,
				title_in: ``,
		  }
		: {
				title_powered: `powered by${platform.title}`,
				title_in: ` в${platform.title}`,
		  };

export { partner, platform, compound, partner_compound, platform_compound };
