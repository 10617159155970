/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

import classNames from 'classnames';
import { Button } from 'components/Buttons/Button';
import { Row } from 'components/Grid';
import upload from 'images/upload.svg';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { FormattedHTMLMessage } from 'react-intl';
import styles from './DNDUpload.module.css';

class DNDUpload extends Component {
	static propTypes = {
		id: PropTypes.any,
		title: PropTypes.string,
		complete: PropTypes.bool,
		open: PropTypes.bool,
		content: PropTypes.element,
		onClick: PropTypes.func,
	};

	state = {
		files: [],
	};

	render() {
		const { complete, className } = this.props;
		const classes = classNames(
			className,
			styles['DNDUpload'],
			complete && 'complete'
		);
		return (
			<div className={classes}>
				<Row>
					<Dropzone>
						{({ getRootProps, getInputProps }) => (
							<div {...getRootProps()} className={styles.Drop}>
								<img src={upload} alt="" />
								<input {...getInputProps()} />
								<p>
									<FormattedHTMLMessage id={'rod.contracts.upload.text'} />
								</p>
							</div>
						)}
					</Dropzone>
				</Row>
				<Row>
					<div className={styles.Controls}>
						<Button text={'Отправить'} />
						<Button text={'Отмена'} variant={'link'} />
					</div>
				</Row>
			</div>
		);
	}
}

export default DNDUpload;
