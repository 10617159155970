import React from 'react';
import {
	RadioCustom,
	FormControlLabelCustom,
} from 'material-design/MuiStyled/MuiStyled';

const MuiRadioButton = ({ label, onChange, checked, id }) => {
	return (
		<FormControlLabelCustom
			control={
				<RadioCustom
					checked={checked}
					onChange={onChange}
					value={id}
					name="radio-buttons"
				/>
			}
			label={label}
		/>
	);
};

export default MuiRadioButton;
