export const handleFilterClear = (
	item,
	filterSettings,
	setFilterSettings,
	currentFilterSettings,
	setCurrentFilterSettings
) => {
	const newFilterSettings = [...filterSettings];
	newFilterSettings[item.parentId - 1].list[item.currentId - 1].checked = false;
	item.show = false;
	setCurrentFilterSettings({
		...currentFilterSettings,
	});
	setFilterSettings(newFilterSettings);
	localStorage.removeItem('prevFilters');
};
