/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

import { Button } from 'components/Buttons/index';
import { Col, Container, Row } from 'components/Grid';
import { Label } from 'components/Labels/index';
import { Login as LoginForm } from 'forms/index';
import { withAuth, withRoot, withUI } from 'hocs';
import React, { PureComponent } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { Redirect, withRouter } from 'react-router-dom';
// import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './Login.module.css';
// import { LangContext } from 'contexts/LangContext';
import { DEFAULT_LANG } from 'contexts/LangContext';

class Login extends PureComponent {
	static propTypes = {};

	submitHandler = ({ username, password }) => {
		const {
			authContext: { login },
		} = this.props;
		login({
			username,
			password,
		});
	};

	setLanguageAfterRender = () => {
		const lang = localStorage.getItem('lang');
		if (lang) return;
		localStorage.setItem('lang', DEFAULT_LANG);
	};

	render() {
		this.setLanguageAfterRender();

		const {
			authContext: { isAuth, login, errors },
		} = this.props;

		if (isAuth) {
			return <Redirect to={'/'} />;
		}

		return (
			<Container className={styles.LoginPage} fluid>
				<Row className={styles.LoginPage}>
					<Col xs={12} sm={6} md={7} className={styles.Login} extra>
						<LoginForm onSubmit={login} errors={errors} />
					</Col>
					{this.props.UIContext.isAllowSignup && (
						<Col xs={12} sm={6} md={5} className={styles.Register}>
							<div className={'form'}>
								<Label font="--gilroy-Medium-40" black>
									<FormattedHTMLMessage id={'rod.login.register.header'} />
								</Label>
								<Label font="--gilroy-Medium-24" black>
									<FormattedHTMLMessage
										id={'rod.login.text.have_not_account_yet'}
									/>
								</Label>
								<Button
									className={styles.Button}
									onClick={() => {
										this.props.history.push('/registration');
									}}
								>
									<FormattedHTMLMessage id={'rod.action.register'} />
								</Button>
							</div>
						</Col>
					)}
				</Row>
			</Container>
		);
	}
}

export default compose(withRoot, withUI, withAuth, withRouter)(Login);
