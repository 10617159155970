/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

import React from 'react';
import { Row as WrappedRow } from 'react-grid-system';

const Row = (props) => {
	const { children, ...restProps } = props;
	return (
		<WrappedRow {...restProps}>
			{children}
		</WrappedRow>
	);
};

export default Row;
