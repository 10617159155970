import React from 'react';
import { Unavailable } from 'pages/errors/Unavailable';
import { Layout } from 'containers';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false, error: null };
	}

	static getDerivedStateFromError(error) {
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		const err = window.btoa(unescape(encodeURIComponent(error)));
		this.setState({ error: err });
	}

	render() {
		if (this.state.hasError) {
			return (
				<Layout>
					<Unavailable err={this.state.error} />
				</Layout>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
