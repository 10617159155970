// Core
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { withRoot, withUI } from 'hocs';
import update from 'immutability-helper';
import { compose } from 'recompose';

// UI
import { Loading } from 'components';
import { Button } from 'components/Buttons/Button';
import { Label } from 'components/Labels/Label';
import { MultipleSelectDropdown } from 'components/MultipleSelectDropdown';

// Styles
import styles from './TerritorySelect.module.css';

class TerritorySelect extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			selected: [],
			loading: true,
		};
	}

	componentDidMount() {
		const {
			UIContext: { showBackNavi },
			rootContext: { getContractsTerritories },
		} = this.props;
		if (!this.props.location.state?.isAdditional) {
			showBackNavi(`/accounts/${this.props.match.params.id}`);
		}
		const { id, useType, mediaType } = this.props.match.params;

		getContractsTerritories(id, useType, mediaType).then((res) => {
			res = res.data.data;

			this.setState(
				update(this.state, {
					selected: { $set: res },
					loading: { $set: false },
				})
			);
		});
	}

	submit = () => {
		const {
			rootContext: { updateContractsTerritories, getTerritories },
		} = this.props;
		const { id, useType, mediaType } = this.props.match.params;

		updateContractsTerritories(
			id,
			useType,
			mediaType,
			this.state.selected.map((item) => item.id)
		).then(() => {
			getTerritories();
			this.props.history.push(
				this.props.location.state?.isAdditional
					? `/treaties/form/${this.props.match.params.id}`
					: `/accounts/${this.props.match.params.id}`
			);
		});
	};

	change = (selected) => {
		this.setState({
			selected,
		});
	};

	render() {
		const { selected, loading } = this.state;
		const {
			rootContext: { territories },
		} = this.props;

		if (loading) {
			return <Loading />;
		}

		return (
			<div className={styles.Page}>
				<div className={styles.Form}>
					<div className={styles.Header}>
						<Label black font="--gilroy-Medium-40">
							<FormattedHTMLMessage
								id={'rod.contracts.territory_select.header'}
							/>
						</Label>
					</div>
					<div className={styles.Content}>
						<FormattedMessage id={'rod.field.contract_territories'}>
							{(placeholder) => (
								<MultipleSelectDropdown
									isCountriesData
									className={styles.Input}
									placeholder={placeholder}
									items={territories}
									selected={selected}
									onChange={this.change}
								/>
							)}
						</FormattedMessage>
					</div>
					<div className={styles.Controls}>
						<Button
							className={styles.Button}
							text={<FormattedHTMLMessage id={'rod.action.save'} />}
							onClick={this.submit}
							variant={'primary'}
						/>
					</div>
				</div>
			</div>
		);
	}
}

TerritorySelect.propTypes = {};

TerritorySelect.defaultProps = {};

export default compose(withRoot, withRouter, withUI)(TerritorySelect);
