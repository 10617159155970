// Core
import { FormattedMessage } from 'react-intl';

export const coverSteps = [
	{
		id: 0,
		status: 'complete',
		title: <FormattedMessage id={'rod.release.create.step.release'} />,
	},
	{
		id: 1,
		status: 'complete',
		title: <FormattedMessage id={'rod.release.create.step.files'} />,
	},
	{
		id: 2,
		status: 'complete',
		title: <FormattedMessage id={'rod.release.create.step.tracks'} />,
	},
	{
		id: 3,
		status: 'complete',
		title: <FormattedMessage id={'rod.release.create.step.authors'} />,
	},
	{
		id: 4,
		status: 'complete',
		title: <FormattedMessage id={'rod.release.create.step.lyrics'} />,
	},
	{
		id: 5,
		status: 'active',
		title: <FormattedMessage id={'rod.release.create.step.cover'} />,
	},
	{
		id: 6,
		status: 'default',
		title: <FormattedMessage id={'rod.release.create.step.distribution'} />,
	},
	{
		id: 7,
		status: 'default',
		title: <FormattedMessage id={'rod.release.create.step.confirm'} />,
	},
];
