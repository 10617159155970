// Core
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

// UI
import { FormInput } from 'components/Form/FormInput';
import { Label } from 'components/Labels/Label';

// Styles
import styles from '../../ContractTerms.module.css';

const ContractsCurrency = ({ state, changeField, currencies }) => {
	return (
		<div className={styles.Field}>
			<Label className={'header'} black font="--gilroy-Bold-24">
				<FormattedMessage id={'rod.field.contracts.currency.header'} />
			</Label>
			<FormattedMessage id={'rod.field.contracts.currency.placeholder'}>
				{(placeholder) => (
					<FormInput
						type={'dropdown'}
						placeholder={placeholder}
						name={'currency'}
						className="input"
						defaultEmpty
						onChange={changeField}
						errors={state.errors}
						required
						data={state.data}
						items={currencies.filter((curr) => curr.available)}
						getItem={(item) => {
							return (
								<FormattedHTMLMessage
									id={`rod.field.contracts.currency.${item.title}`}
								/>
							);
						}}
						getListItem={(item) => (
							<FormattedHTMLMessage
								id={`rod.field.contracts.currency.${item.title}`}
							/>
						)}
						getItemValue={(item) => item.id}
					/>
				)}
			</FormattedMessage>
		</div>
	);
};

export default ContractsCurrency;
