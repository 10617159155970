export const getPerformers = (data) => {
	if (
		data.performers &&
		Array.isArray(data.performers) &&
		data.performers.length
	) {
		return (
			<span title={data.performers.join(', ')}>
				{data.performers.join(', ')}
			</span>
		);
	}

	if (
		data.performers &&
		!Array.isArray(data.performers) &&
		typeof data.performers === 'object'
	) {
		return (
			<span title={Object.values(data.performers).join(', ')}>
				{Object.values(data.performers).join(', ')}
			</span>
		);
	}

	return <span>-</span>;
};

export const getDraftPerformers = (data) => {
	if (data.authors && data.authors[0].title) {
		return data.authors[0].title;
	}

	if (data.performers && typeof data.performers === 'string') {
		return data.performers;
	}

	return '-';
};
