export const paymentMethods = [
	{
		id: 'paypal',
		title_ru: 'PayPal (недоступен)',
		title_en: 'PayPal (not available)',
	},
	{
		id: 'bank',
		title_ru: 'Банковский счет',
		title_en: 'Bank account',
	},
];
