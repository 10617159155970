/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 11/11/2020, 08:52
 */
// eslint-disable-next-line
const RepertoireOwn = {
	MYSELF: 'self',
	ANOTHER_PERSON: 'shared',
	MYSELF_AND_ANOTHER: 'all',
};

export default RepertoireOwn;
