// Core
import { FormattedMessage } from 'react-intl';
import { useContext, useEffect } from 'react';

// Context
import { RootContext } from 'contexts/RootContext';

// UI
import LoaderWhithoutProgress from 'components/LoaderWhithoutProgress';

// Styles
import styles from './Action.module.css';

const Action = ({ item, isAdmin }) => {
	const {
		reportStatus,
		setReportStatus,
		getStatisticAdminReportFile,
		getStatisticAccountReportFile,
	} = useContext(RootContext);

	const handleDownload = (reportId, accountId) => {
		setReportStatus(reportId, 'in progress');
		
		if (isAdmin) {
			getStatisticAdminReportFile(accountId, reportId).then((file) => {
				window.open(file.data.output_file, '_parent');
				setReportStatus(reportId, 'ready');
			});
		} else {
			getStatisticAccountReportFile(accountId, reportId).then((file) => {
				window.open(file.data.output_file, '_parent');
				setReportStatus(reportId, 'ready');
			});
		}
	};

	useEffect(() => {
		return () => {
			setReportStatus('', '');
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{item.status === 'error' && (
				<div className={styles.error}>
					<FormattedMessage id={'rod.repertoire_file_download.error'} />
				</div>
			)}

			{item.status === 'pending' ||
			item.status === 'in progress' ||
			(reportStatus.id === item.id && reportStatus.status === 'in progress') ? (
				<div className={styles.preLoader}>
					<LoaderWhithoutProgress />
				</div>
			) : (
				<>
					{item.status !== 'error' && (
						<div
							className={styles.download}
							onClick={() =>
								handleDownload(item.id, item.parameters.account_id)
							}
						>
							<FormattedMessage id={'rod.statistic.download'} />
						</div>
					)}
				</>
			)}
		</>
	);
};

export default Action;
