// Core
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { dateFormat } from 'logic';
import cloneDeep from 'lodash/cloneDeep';
import lscache from 'lscache';
import moment from 'moment';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { UIContext } from 'contexts/UIContext';

// UI
import { BottomNavi } from 'components';
import ModalWarning from './ModalWarning/ModalWarning';
import TracksForm from './TracksForm/TracksForm';
import Sidebar from './Sidebar/Sidebar';

// Utils
import { compose } from 'recompose';
import { withUI, withRoot, withAuth } from 'hocs';
import { setCache } from 'services/cacheHelper/setCache';
import { correctDateInTracks } from './utils/correctDateInTracks';
import { isDone } from './utils/isDone';

// Services
import { accounts, dictionaries } from 'services';

// Styles
import s from './Tracks.module.css';

const Tracks = (props) => {
	const history = useHistory();
	const { accountId } = useContext(AuthContext);
	const { setIsBottomNaviShow } = useContext(UIContext);

	const idTypesHasSnippet = ['4', '42', '43', '63', '66', '70'];
	const releaseId = localStorage.getItem('releaseId');
	const isH11Edit = window.location.href.includes('/edit/');
	const releaseEdit = JSON.parse(localStorage.getItem('releaseEdit'));
	const h11EditId = releaseEdit?.id;

	const [personal, setPersonal] = useState([]);
	const [releaseData, setReleaseData] = useState();
	const [selectPerson, setSelectPerson] = useState({});
	const [additionalReleases, setAdditionalReleases] = useState([]);
	const [errors, setErrors] = useState({});
	const [transferRelease, setTransferRelease] = useState(false);
	const [toogleMenu, setToogleMenu] = useState('');
	const [takeByLastPerson, setTakeByLastPerson] = useState(false);
	const [lastSavePerson, setLastSavePerson] = useState({});
	const [audio] = useState(new Audio());
	const [isReqLive] = useState(false);
	const [languages] = useState([]);
	const [countries, setCountries] = useState([]);
	const [isSnippedRender, setIsSnippedRender] = useState(false);
	const [releaseTypeId, setReleaseTypeId] = useState('');
	const [showRangeModal, setShowRangeModal] = useState(false);
	const [disabledNextBtn, setDisabledNextBtn] = useState(true);
	const [disableBtn, setDisableBtn] = useState(false);
	const [disableSaveBtn, setDisableSaveBtn] = useState(false);

	const handleForm = async (index) => {
		toogleMenu === index ? setToogleMenu('') : setToogleMenu(index);

		try {
			const res = await accounts.getReleaseData(
				h11EditId && isH11Edit ? h11EditId : releaseId
			);
			const additional = res.data.data.tracks[index].additional;

			if (!additional) {
				setAdditionalReleases([]);
			} else {
				setAdditionalReleases(additional);
			}
		} catch (error) {
			console.error('An error occurred:', error);
		}
	};

	const handleISRC = (boolean) => {
		selectPerson.generate_isrc = !boolean;
		if (selectPerson.generate_isrc) {
			selectPerson.isrc = '';
		}
		personal[toogleMenu] = selectPerson;
		setPersonal([...personal]);
	};

	const handleCatalogNumber = (boolean) => {
		selectPerson.generate_catalog_number = !boolean;
		if (selectPerson.generate_catalog_number) {
			selectPerson.catalog_number = '';
		}
		personal[toogleMenu] = selectPerson;
		setPersonal([...personal]);
	};

	const changeMask = (e) => {
		setErrors({});
		selectPerson.isrc = e.target.value;
		personal[toogleMenu] = selectPerson;
		setPersonal([...personal]);
	};

	const snippetValidation = (data) => {
		if (parseInt(data[0]) > 59 || parseInt(data[1]) > 59) {
			setErrors({ snippet_start: [{ rule: 'date_format' }] });
		}
	};

	const changeSnippet = (field) => (e) => {
		setErrors({});
		let endTime;

		switch (releaseTypeId.toString()) {
			case '42':
				delete errors.snippet_start;
				setErrors({ ...errors });

				selectPerson.snippet_start = e.target.value;
				snippetValidation(selectPerson.snippet_start.split(':'));

				endTime = moment(`${e.target.value}`, 'mm:ss:SSS')
					.add(45, 'seconds')
					.format('mm:ss:SSS');
				selectPerson.snippet_end = endTime.toString();

				personal[toogleMenu] = selectPerson;
				setPersonal([...personal]);
				break;
			case '43':
				delete errors.snippet_start;
				setErrors({ ...errors });

				selectPerson.snippet_start = e.target.value;
				snippetValidation(selectPerson.snippet_start.split(':'));

				endTime = moment(`${e.target.value}`, 'mm:ss:SSS')
					.add(29.99, 'seconds')
					.format('mm:ss:SSS');
				selectPerson.snippet_end = endTime.toString();

				personal[toogleMenu] = selectPerson;
				setPersonal([...personal]);
				break;
			case '70':
				delete errors.snippet_start;
				setErrors({ ...errors });

				selectPerson.snippet_start = e.target.value;
				snippetValidation(selectPerson.snippet_start.split(':'));

				endTime = moment(`${e.target.value}`, 'mm:ss:SSS')
					.add(60, 'seconds')
					.format('mm:ss:SSS');
				selectPerson.snippet_end = endTime.toString();

				personal[toogleMenu] = selectPerson;
				setPersonal([...personal]);
				break;

			default:
				break;
		}
	};

	const handleOnBlurDateInput = (err, value) => {
		setErrors({});

		if (err) {
			setErrors({ created_date: [{ rule: 'wrong_format' }] });
		}
	};

	const changeField = (field) => (e) => {
		setErrors({});

		switch (field) {
			case 'created_date':
				if (!selectPerson.created_date) {
					if (e && e.getFullYear().toString().length === 4) {
						selectPerson[field] = new Date(e.getTime()).toISOString();
						setSelectPerson({ ...selectPerson });
					}
				} else if (e && e.getFullYear().toString().length === 4) {
					e = dateFormat(e);
				}
				selectPerson[field] = e;
				break;

			case 'genres':
				const genresArr = e.map((item) => (item.code ? item.code : item));
				selectPerson[field] = genresArr;
				break;
			case 'created_country_id':
				selectPerson[field] = e;
				break;

			default:
				selectPerson[field] = e.target.value;
				break;
		}

		personal[toogleMenu] = selectPerson;
		setPersonal([...personal]);
	};

	const toogleLastPerson = (boolean) => {
		if (boolean) {
			setErrors({});
			const lastPerson = personal[toogleMenu - 1];

			setSelectPerson({
				...selectPerson,
				genres: lastPerson.genres,
				created_country_id: lastPerson.created_country_id,
				main_performer: lastPerson.main_performer,
				party_id: lastPerson.party_id,
				producer: lastPerson.producer,
				created_date: lastPerson.created_date,
			});
		} else {
			setSelectPerson(personal[toogleMenu]);
		}

		setTakeByLastPerson(boolean);
	};

	const isValid = (toCheck, showErr = false) => {
		let errorCount = 0;
		const newError = {};

		if (!toCheck.title) {
			newError.title = 'обязательное поле';
			errorCount++;
		}

		if (!toCheck.created_date) {
			newError.created_date = [{ rule: 'required' }];
			errorCount++;
		}

		if (!toCheck.genres || toCheck.genres.length === 0) {
			newError.genres = 'обязательное поле';
			errorCount++;
		}

		if (typeof toCheck.created_country_id !== 'number') {
			newError.created_country_id = 'обязательное поле';
			errorCount++;
		}

		if (!toCheck.isrc && !toCheck.generate_isrc) {
			newError.isrc = 'обязательное поле';
			errorCount++;
		}

		if (toCheck.isrc?.includes('_') && !toCheck.generate_isrc) {
			newError.isrc = 'обязательное поле';
			errorCount++;
		}

		if (
			!transferRelease &&
			!isH11Edit &&
			!toCheck.catalog_number &&
			!toCheck.generate_catalog_number
		) {
			newError.catalog_number = 'обязательное поле';
			errorCount++;
		}

		if (
			!transferRelease &&
			!isH11Edit &&
			toCheck.catalog_number?.includes('_') &&
			!toCheck.generate_catalog_number
		) {
			newError.catalog_number = 'обязательное поле';
			errorCount++;
		}

		if (!toCheck.main_performer) {
			newError.main_performer = 'обязательное поле';
			errorCount++;
		}

		if (!toCheck.producer && !toCheck.party_id) {
			newError.party_id = 'обязательное поле';
			errorCount++;
		}

		if (errors.created_date) {
			errorCount++;
			newError.created_date = [{ rule: 'wrong_format' }];
		}

		if (
			isSnippedRender &&
			!toCheck.snippet_start &&
			releaseData.step !== 'h11'
		) {
			errorCount++;
			newError.snippet_start = [{ rule: 'required' }];
		}

		if (!errorCount) {
			setErrors({});
			return false;
		} else {
			if (showErr) {
				setErrors(newError);
			}
			return true;
		}
	};

	const recordingSave = () => {
		if (isValid(selectPerson, true)) return;
		setDisableSaveBtn(true);

		if (!isSnippedRender) {
			selectPerson.snippet_start && delete selectPerson.snippet_start;
			selectPerson.snippet_end && delete selectPerson.snippet_end;
		}

		const clone = cloneDeep(selectPerson);
		clone.account_id = accountId;

		if (clone.genres.length > 0) {
			clone.genres = clone.genres.map((item) => item.code);
		}

		if (clone.created_date.length) {
			const correctedTime = new Date(
				new Date(clone.created_date).getTime() -
					new Date().getTimezoneOffset() * 60000
			).toISOString();
			clone.created_date = correctedTime.slice(0, correctedTime.indexOf('T'));
		}

		if (clone.isrc) {
			const preparedIsrc = clone.isrc.split('-').join('').toLowerCase();
			clone.isrc = preparedIsrc;
		}

		if (additionalReleases.length) {
			clone.additional = additionalReleases;
		}

		accounts
			.uploadRecordingInfo(
				h11EditId && isH11Edit ? h11EditId : releaseId,
				selectPerson.id,
				clone
			)
			.then((res) => {
				res = res.data.data;
				if (res.isrc) {
					const data = res.isrc.replace(
						/(\d{2})\.(\d{3})\.(\d{2})\.(\d{5})/,
						'$1-$2-$3-$4'
					);

					selectPerson.isrc = data;
				}
				selectPerson.done = true;
				personal[toogleMenu] = selectPerson;
				setPersonal(personal);

				if (personal[toogleMenu + 1]) {
					if (!isValid(personal[toogleMenu + 1])) {
						setToogleMenu('');
					} else {
						setToogleMenu(toogleMenu + 1);
					}
				} else {
					setToogleMenu('');
					setDisabledNextBtn(false);
				}
				setDisableSaveBtn(false);
			})
			.then(() => {
				const ellementToScroll = document.getElementById('general');
				if (ellementToScroll) {
					ellementToScroll.scrollIntoView({
						behavior: 'smooth',
						block: 'end',
						inline: 'nearest',
					});
				}
			})
			.catch((err) => {
				console.error('Error', err.response.data.errors);
				console.error('Error', err.response.data);
				setErrors(err.response.data.errors);
				setDisableSaveBtn(false);
			});
	};

	const nextHandler = () => {
		setDisableBtn(true);
		if (typeof props.onChange === 'function') {
			const validDate = personal[0]?.created_date?.includes('/')
				? personal[0]?.created_date?.replace(/\//g, '-')
				: personal[0]?.created_date;
			personal.created_date = validDate;
			setPersonal(personal);
			audio.currentTime = 0;
			audio.pause();
			props
				.onChange(personal)
				.then()
				.catch()
				.finally(() => setDisableBtn(false));
		}
	};

	const backHandler = () => {
		if (typeof props.handlePrevStep === 'function') {
			if (h11EditId && isH11Edit) {
				history.push(`/release/${h11EditId}/edit/file`);
			} else {
				history.push(`/release/${releaseId}/file`);
			}
			audio.currentTime = 0;
			audio.pause();
			props.handlePrevStep();
		}
	};

	useEffect(() => {
		setIsBottomNaviShow(true);

		return () => {
			setIsBottomNaviShow(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		accounts
			.getReleaseData(h11EditId && isH11Edit ? h11EditId : releaseId)
			.then((res) => {
				if (res.data?.data) {
					setReleaseData(res.data.data);
				}

				if (res.data.data.isTransferRelease === 1) {
					setTransferRelease(true);
				}

				const tracks = correctDateInTracks(res.data.data.tracks);
				const releaseTypeId = res.data.data.release_type_id;

				if (
					releaseTypeId !== 51 &&
					releaseTypeId !== 2 &&
					releaseTypeId !== 64 &&
					releaseTypeId !== 69 &&
					res.data.data.tracks.some((track) => track.additional)
				) {
					setShowRangeModal(true);
				}

				setPersonal(tracks);
				setReleaseTypeId(releaseTypeId);

				let first = true;
				const {
					subtitle,
					created_country_id,
					performers,
					label,
					genres,
					additional,
				} = res.data.data;
				let countValid = 0;

				tracks.forEach((track, i) => {
					track.subtitle = track.subtitle ?? subtitle ?? null;
					track.created_country_id =
						track.created_country_id ?? created_country_id;
					track.main_performer = track.main_performer ?? performers;
					track.party_id = track.party_id ?? label ?? null;
					track.genres = track.genres ?? genres;
					track.additional = track.additional ?? additional;

					if (
						isDone(
							track,
							res.data.data.isTransferRelease,
							isH11Edit,
							errors,
							setErrors,
							isSnippedRender,
							releaseData
						)
					) {
						track.done = true;
						countValid++;
					} else if (first) {
						setToogleMenu(i);
						first = false;
					}

					if (countValid === tracks.length) {
						setToogleMenu('');
					}
				});
			})
			.catch((error) => {
				console.error('Error', error);
			});

		const countriesCache = lscache.get('countriesCashe');
		if (countriesCache && !isReqLive) {
			setCountries(countriesCache);
		} else {
			dictionaries
				.getCountries()
				.then((res) => {
					res = res.data.data;
					setCache('countries', 'countriesCashe', res);
					setCountries(res);
				})
				.catch((error) => {
					console.error('Error', error);
				});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setTakeByLastPerson(false);
		if (toogleMenu === '') {
			return;
		}
		if (toogleMenu - 1 >= 0) {
			if (!isValid(personal[toogleMenu - 1])) {
				setLastSavePerson(personal[toogleMenu - 1]);
			} else {
				setLastSavePerson({});
			}
		} else {
			setLastSavePerson({});
		}

		setSelectPerson(personal[toogleMenu]);
		setErrors({});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toogleMenu]);

	useEffect(() => {
		idTypesHasSnippet.includes(releaseTypeId.toString())
			? setIsSnippedRender(true)
			: setIsSnippedRender(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [releaseTypeId]);

	useEffect(() => {
		if (selectPerson.additional) {
			setAdditionalReleases(selectPerson.additional);
		} else {
			setAdditionalReleases([]);
		}
	}, [toogleMenu, selectPerson.additional]);

	useEffect(() => {
		for (const item of personal) {
			if (!item.done || isValid(item, false)) {
				setDisabledNextBtn(true);
				break;
			} else {
				setDisabledNextBtn(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [personal, selectPerson, disabledNextBtn]);

	return (
		<div>
			<div className={s.page}>
				{showRangeModal && (
					<ModalWarning onClose={() => setShowRangeModal(false)} />
				)}
				<div className={s.main__wrapper}>
					<Sidebar
						toogleMenu={toogleMenu}
						personal={personal}
						handleForm={handleForm}
					/>

					{toogleMenu !== '' && (
						<TracksForm
							releaseTypeId={releaseTypeId}
							transferRelease={transferRelease}
							errors={errors}
							setErrors={setErrors}
							changeField={changeField}
							handleForm={handleForm}
							toogleMenu={toogleMenu}
							selectPerson={selectPerson}
							setSelectPerson={setSelectPerson}
							recordingSave={recordingSave}
							lastSavePerson={lastSavePerson}
							toogleLastPerson={toogleLastPerson}
							takeByLastPerson={takeByLastPerson}
							handleOnBlurDateInput={handleOnBlurDateInput}
							setTakeByLastPerson={setTakeByLastPerson}
							languages={languages}
							handleISRC={handleISRC}
							handleCatalogNumber={handleCatalogNumber}
							countries={countries}
							isSnippedRender={isSnippedRender}
							changeMask={changeMask}
							changeSnippet={changeSnippet}
							additionalReleases={additionalReleases}
							setAdditionalReleases={setAdditionalReleases}
							setShowRangeModal={setShowRangeModal}
							isMainValid={isValid}
							releaseData={releaseData}
							disableSaveBtn={disableSaveBtn}
							setDisableSaveBtn={setDisableSaveBtn}
						/>
					)}
				</div>
			</div>
			<BottomNavi
				showPrevBtn
				disabled={disabledNextBtn || disableBtn}
				back={backHandler}
				next={nextHandler}
			/>
		</div>
	);
};

export default compose(withUI, withRoot, withAuth)(Tracks);
