// Core
import React, { useContext } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withAuth, withRoot, withUI } from 'hocs';

// Context
import { AuthContext } from 'contexts/AuthContext';

// UI
import { EmailCheckForm } from 'forms';

// Styles
import styles from './EmailCheck.module.css';

const EmailCheck = () => {
	const { isAuth, verifyEmail } = useContext(
		AuthContext
	);
	const history = useHistory();

	if (!isAuth) {
		history.push('/login');
	}

	return (
		<div className={styles.page}>
			<EmailCheckForm
				onSubmit={verifyEmail}
			/>
		</div>
	);
};

export default compose(withRoot, withAuth, withUI, withRouter)(EmailCheck);
