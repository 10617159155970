/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 15/03/2021, 12:19
 */

import { Button } from 'components/Buttons';
import { withRoot, withUI } from 'hocs';
import update from 'immutability-helper';
import React, { useEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { useHistory, useLocation } from 'react-router-dom';
import { compose } from 'recompose';
import { admin } from 'services';
import accountCompletionSteps from 'constants/accountCompletionSteps';
import { FormattedMessage } from 'react-intl';
import styles from './SignPosition.module.css';

const SignPosition = (props) => {
	const {
		UIContext: { showBackNavi },
		rootContext: { getMvpUserData },
	} = props;
	const pageEl = useRef(null);
	const [numPages, setNumPages] = useState(0);
	const [marks, setMarks] = useState([]);
	const [account, setAccount] = useState({});
	const [error, setError] = useState({});
	const formWidth = 600;
	// let { path } = useRouteMatch();
	let { pathname } = useLocation();
	let history = useHistory();
	// let { search } = useHistory();

	const loadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

	useEffect(() => {
		showBackNavi(props.backUrl);
		const id = pathname.split('/')[4];

		getMvpUserData(id)
			.then((res) => {
				if (!Object.keys(res.contracts).length) {
					history.push(props.backUrl);
				} else {
					setAccount(res);
				}
			})
			.catch((err) => console.info('error', err));

		// eslint-disable-next-line
	}, []);

	// const contractType = 'contract-' + pathname.split('/')[6];

	const contractType = pathname.split('/')[7];

	const handlePageClick = (page) => (e) => {
		//
		// if(e.target !== e.currentTarget){
		// 	return
		// }

		const docPages = pageEl.current.querySelectorAll(`.${styles.DocPage}`);
		const docPage = docPages[page - 1];
		const docPageRect = docPage.getBoundingClientRect();

		setMarks(
			update(marks, {
				$push: [
					{
						page,
						left: (e.clientX - docPageRect.left - 20) / docPageRect.width,
						top: (e.clientY - docPageRect.top - 20) / docPageRect.height,
						x: e.clientX - docPageRect.left - 20,
						y: e.clientY - docPageRect.top - 20,
					},
				],
			})
		);
	};

	const handleRemoveMark = (index) => (e) => {
		e.stopPropagation();
		setMarks(
			update(marks, {
				$splice: [[index, 1]],
			})
		);
	};

	const handleSubmit = () => {
		admin
			.setContractSigns({
				accountId: props.id,
				contractType: pathname.split('/')[6],
				payload: marks,
			})
			.then((res) => {
				// res = res.data.data;
				history.push(props.backUrl);
			})
			.catch((error) =>
				console.error(
					'signPosition - admin.setContractSigns - api - error: ',
					error
				)
			);
	};

	const submitBtnLayout = (status) => {
		const {
			APPROVED_CONTRACTS,
			PERSONAL_AREA,
			DECLINED_CONTRACTS,
		} = accountCompletionSteps;

		if (Object.keys(error).length) {
			return (
				<Button
					disabled
					text={<FormattedMessage id={'rod.admin.sign-contract.sent'} />}
					className={styles.Button}
					onClick={handleSubmit}
				/>
			);
		}

		switch (status) {
			case APPROVED_CONTRACTS:
				return (
					<Button
						disabled
						text={<FormattedMessage id={'rod.admin.sign-contract.sent'} />}
						className={styles.Button}
						onClick={handleSubmit}
					/>
				);
			case PERSONAL_AREA:
				return (
					<Button
						disabled
						text={<FormattedMessage id={'rod.admin.sign-contract.sent'} />}
						className={styles.Button}
						onClick={handleSubmit}
					/>
				);
			case DECLINED_CONTRACTS:
				return (
					<Button
						disabled
						text={<FormattedMessage id={'rod.admin.sign-contract.rejected'} />}
						className={styles.Button}
						onClick={handleSubmit}
					/>
				);
			default:
				return (
					<Button
						primary
						text={<FormattedMessage id={'rod.admin.account.files.to_send'} />}
						className={styles.Button}
						onClick={handleSubmit}
					/>
				);
		}
	};

	return (
		<div ref={pageEl}>
			<div className={styles.Content}>
				{!Object.keys(error).length ? (
					<Document
						file={
							Object.keys(account).length &&
							account.contracts[contractType]?.url
						}
						className={styles.Document}
						onLoadSuccess={loadSuccess}
						onLoadError={(err) => setError({ errors: err.message })}
					>
						{Array.from(new Array(numPages), (el, index) => (
							<Page
								style={{ position: 'relative' }}
								pageNumber={index + 1}
								key={`page_${index + 1}`}
								className={styles.DocPage}
								width={formWidth}
								renderTextLayer={false}
								onClick={handlePageClick(index + 1)}
							>
								{marks
									.filter((mark) => mark.page === index + 1)
									.map((mark, index) => (
										<span
											onClickCapture={handleRemoveMark(index)}
											style={{
												position: 'absolute',
												left: mark.x,
												top: mark.y,
												cursor: 'pointer',
											}}
										>
											<i
												className="fa fa-close"
												style={{
													fontSize: 48,
													color: 'red',
												}}
											/>
										</span>
									))}
							</Page>
						))}
					</Document>
				) : (
					<>
						<p className={styles.error}>
							{<FormattedMessage id={'rod.error.failed_to_load_file'} />}:
						</p>
						<p className={styles.error}>{error.errors}</p>
					</>
				)}
			</div>
			<div className={styles.Controls}>
				{account.step && submitBtnLayout(account.step)}
			</div>
		</div>
	);
};

SignPosition.propTypes = {};

export default compose(withUI, withRoot)(SignPosition);
