/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 02/03/2021, 20:12
 */

import classnames from 'classnames';
import chevronDownImg from 'images/chevron_down.svg';
import chevronUpImg from 'images/chevron_up.svg';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './Dropdown.module.css';

class Dropdown extends PureComponent {
	constructor(props) {
		super(props);
		this.ref = React.createRef();
		this.state = {
			isOpen: false,
			item: props.defaultEmpty ? null : props.items[props.defaultItemIndex],
		};
		if (props.value) {
			this.state.item = props.items.find(
				(item) =>
					item.keyword === props.value ||
					(item.id && item.id.toString() === props.value.toString())
			);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.value !== this.props.value) {
			this.setState({
				item: this.props.items.find(
					(item) =>
						item.keyword === this.props.value || item.id === this.props.value
				),
			});
		}
	}

	handleSelect = (e) => {
		const item = this.props.items[+e.currentTarget.dataset.index];
		this.setState({
			item,
			isOpen: false,
		});
		this.props.onChange(this.props.getItemValue(item));
	};

	handleOpen = () => {
		this.setState({ isOpen: this.state.isOpen ? false : true });
	};

	showError = (error) => {
		if (typeof this.props.showError === 'function') {
			return this.props.showError(error);
		} else {
			if (this.props.name) {
				return (
					<FormattedMessage id={`rod.error.${this.props.name}_${error.rule}`} />
				);
			} else {
				return <FormattedMessage id={`rod.error.${error.rule}`} />;
			}
		}
	};

	render() {
		const {
			items,
			helper,
			errors,
			className,
			getItem,
			getItemValue,
			getListItem,
			children,
			onChange,
			name,
			placeholder,
			showChevron,
			showRequired,
			defaultItemIndex,
			defaultEmpty,
			forwardRef,
			...props
		} = this.props;
		const { item, isOpen } = this.state;
		const classes = classnames(
			className,
			styles.Dropdown,
			errors.length > 0 && styles.Error
		);

		if (forwardRef) {
			this.ref = forwardRef;
		}

		const value = item ? getItem(item, isOpen) : null;
		return (
			<div className={classes} {...props}>
				{value ? (
					<div className={styles.Value} onClick={this.handleOpen}>
						{value}
					</div>
				) : (
					<div
						className={styles.Placeholder}
						onClick={this.handleOpen}
					>{`${placeholder}${showRequired && props.required ? '*' : ''}`}</div>
				)}
				{showChevron && (
					<div className={styles.Chevron} onClick={this.handleOpen}>
						{isOpen ? (
							<img src={chevronUpImg} alt="" />
						) : (
							<img src={chevronDownImg} alt="" />
						)}
					</div>
				)}

				{isOpen && (
					<ul className={styles.List}>
						{items.map((item, index) => {
							return (
								<li
									key={index}
									onClick={this.handleSelect}
									data-index={index}
									className={`${styles.Item} ${styles.ItemHover}`}
									style={{
										backgroundColor:
											value?.props?.id && item.lexeme === value.props.id
												? 'var(--color-green)'
												: 'var(--color-white)',
										color:
											value?.props?.id && item.lexeme === value.props.id
												? 'var(--color-white)'
												: 'unset',
									}}
								>
									{getListItem(item)}
								</li>
							);
						})}
					</ul>
				)}
				{helper && errors.length === 0 && (
					<div className={styles.Helper}> {helper} </div>
				)}
				{errors.length > 0 && (
					<div className={styles.Helper}> {this.showError(errors[0])} </div>
				)}
				<input
					type="hidden"
					name={name}
					value={item ? getItemValue(item) : ''}
					ref={forwardRef}
				/>
			</div>
		);
	}
}

Dropdown.propTypes = {
	items: PropTypes.array.isRequired,
	defaultItemIndex: PropTypes.number,
	defaultEmpty: PropTypes.bool,
	getItem: PropTypes.func,
	getListItem: PropTypes.func,
	getItemValue: PropTypes.func,
	onChange: PropTypes.func,
	showError: PropTypes.func,
	showChevron: PropTypes.bool,
	showRequired: PropTypes.bool,
};

Dropdown.defaultProps = {
	errors: [],
	showChevron: true,
	defaultItemIndex: 0,
	getItem: (item) => item,
	getListItem: (item) => item,
	getItemValue: (item) => item,
	onChange: () => {},
};

export default Dropdown;
