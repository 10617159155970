/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

import classNames from 'classnames';
import { Row } from 'components/Grid';
import upload from 'images/upload.svg';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { FormattedHTMLMessage } from 'react-intl';
import styles from './DNDUpload.module.css';

class DNDUpload extends Component {
	static propTypes = {
		id: PropTypes.any,
		title: PropTypes.string,
		complete: PropTypes.bool,
		open: PropTypes.bool,
		content: PropTypes.element,
		onClick: PropTypes.func,
	};

	state = {
		files: [],
	};

	selectFileHandler = (acceptedFiles) => {
		this.setState({ files: [acceptedFiles] });
		if (typeof this.props.onFileSelect === 'function') {
			this.props.onFileSelect(acceptedFiles);
		}
	};

	render() {
		const { complete, className } = this.props;
		const classes = classNames(
			className,
			styles['DNDUpload'],
			complete && 'complete'
		);
		return (
			<div className={classes}>
				<Row>
					<Dropzone onDrop={this.selectFileHandler}>
						{({ getRootProps, getInputProps }) => (
							<div {...getRootProps()} className={styles.Drop}>
								<img src={upload} alt="" />
								<input {...getInputProps()} />
								<p>
									<FormattedHTMLMessage
										id={'rod.user.repertoire.upload.text'}
									/>
								</p>
							</div>
						)}
					</Dropzone>
				</Row>
			</div>
		);
	}
}

export default DNDUpload;
