// Core
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Context
import { RootContext } from 'contexts/RootContext';

// Components
import { Loading, NoData } from 'components';
import TableHeader from './TableHeader/TableHeader';
import TableBody from './TableBody/TableBody';
import { Pagination } from 'material-design/components';

// Styles
import styles from './UserPayments.module.css';
import tableStyles from '../UserReports/UserReports.module.css';

// Utils
import { checkPaymentStatus } from '../utils/check-payment-status.util';

const UserPayments = (props) => {
	const history = useHistory();

	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [total, setTotal] = useState(0);
	const [invoices, setPayments] = useState([]);
	const [accountId] = useState(localStorage.getItem('accountId'));

	const { getPaymentInvoice, getAccountPayments } = useContext(RootContext);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			const { data, total } = await getAccountPayments(accountId, page);
			const notCompletedPayment = data.find((payment) => {
				return checkPaymentStatus(payment.status);
			});
			if (notCompletedPayment) {
				history.push(
					`./reports/sign?id=${notCompletedPayment.id}&status=${notCompletedPayment.status}`
				);
				return;
			}
			setPayments(data);
			setTotal(total);
			setLoading(false);
		};

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	const handleDownload = async (paymentId) => {
		const invoiceData = await getPaymentInvoice(accountId, paymentId);
		if (invoiceData) {
			window.open(invoiceData, '_blank');
		}
	};

	return (
		<div className={`${tableStyles.mainWrapper} ${styles.payments__wrapper}`}>
			{loading ? (
				<Loading className={tableStyles.loader} />
			) : (
				<>
					{invoices.length ? (
						<>
							<div className={tableStyles.RepertoireSongInfo__mainInfoTable}>
								<TableHeader />
								<TableBody
									invoices={invoices}
									handleDownload={handleDownload}
								/>
							</div>
						</>
					) : (
						<NoData />
					)}
				</>
			)}
			{total > 10 && !loading && (
				<Pagination
					page={page}
					total={total}
					paginate={(pageNumber) => {
						setPage(pageNumber);
					}}
					items_on_page={10}
				/>
			)}
		</div>
	);
};

export default UserPayments;
