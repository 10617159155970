export const getDateInYYYYMMDD = (date) => {
	let dateInMs = new Date(new Date(date).getTime());
	return (
		dateInMs.getFullYear() +
		'-' +
		(dateInMs.getMonth() + 1).toString().padStart(2, '0') +
		'-' +
		dateInMs.getDate().toString().padStart(2, '0')
	);
};
