// Core
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

// Context
import { RootContext } from 'contexts/RootContext';
import { UIContext } from 'contexts/UIContext';

// UI
import { Label } from 'components/Labels';
import { InfoButton } from 'components/Buttons';
import ReportItem from './ReportItem/ReportItem';
import { MultipleUploader } from 'components';

// Styles
import styles from './ReportsStatement.module.css';

const ReportsStatement = ({
	reports,
	fetchPaymentsReports,
	errors,
	setErrors,
	showDownloadError,
	setShowDownloadError,
}) => {
	const { showModal } = useContext(UIContext);
	const { saveReportFiles } = useContext(RootContext);

	const [uploadedFiles, setUploadedFiles] = useState([]);
	const [countFilesError, setCountFilesError] = useState(false);

	const handleFileUpload = (files) => {
		setCountFilesError(false);
		setErrors(false);

		if (files.length === 0) {
			const error = new Error('No file was uploaded.');
			console.error('Documents - uploadAccountDocument error: ', error);
			return Promise.reject(error);
		}

		if (files.length > 20 || uploadedFiles.length + files.length > 20) {
			const error = new Error('The maximum number of files to upload is 20.');
			console.error('Documents - uploadAccountDocument error: ', error);
			setCountFilesError(true);
			return;
		}

		for (const file of files) {
			const fileExtension = `.${file.name.split('.').pop()}`;
			if (
				fileExtension.toLowerCase() !== '.pdf' &&
				fileExtension.toLowerCase() !== '.zip'
			) {
				const error = new Error(`Unsupported file format: ${fileExtension}.`);
				console.error('Documents - uploadAccountDocument error: ', error);
				return Promise.reject(error);
			}
			file.id = uuidv4();
		}

		const newFiles = [...uploadedFiles, ...files];
		setUploadedFiles(newFiles);
		saveReportFiles(newFiles);
		return Promise.resolve();
	};

	const handleFileRemove = (id) => {
		const updatedFiles = uploadedFiles.filter((file) => file.id !== id);
		setUploadedFiles(updatedFiles);
		saveReportFiles(updatedFiles);

		if (updatedFiles.length <= 20) {
			setCountFilesError(false);
		}

		setErrors(false);
	};

	useEffect(() => {
		return () => {
			saveReportFiles([]);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<InfoButton
				onClick={showModal({
					text: (
						<FormattedHTMLMessage
							id={'rod.account.data.invoice.statement_of_reports.help'}
						/>
					),
				})}
			>
				<Label
					text={
						<FormattedHTMLMessage
							id={'rod.account.data.invoice.statement_of_reports'}
						/>
					}
					black
					font="--gilroy-Bold-24"
				/>
			</InfoButton>
			<Label
				className={styles.subheader}
				text={
					<FormattedHTMLMessage
						id={'rod.account.data.invoice.statement_of_reports.subheader'}
					/>
				}
				black
				font="--gilroy-Medium-18"
			/>
			<div className={styles.reportsBlock}>
				{reports.map((report) => (
					<ReportItem
						report={report}
						setShowDownloadError={setShowDownloadError}
					/>
				))}
				{showDownloadError && (
					<span className={styles.helper}>
						<FormattedMessage id="rod.account.data.invoice.statement_of_reports.download_error" />
					</span>
				)}
			</div>
			<MultipleUploader
				maxFilesCount={20}
				accept=".zip,.pdf"
				maxSize={1024 * 20480 * 20}
				handleFileUpload={handleFileUpload}
				countFilesError={countFilesError}
				handleFileRemove={handleFileRemove}
				uploadedFiles={uploadedFiles}
				errors={errors}
			/>
		</>
	);
};

export default ReportsStatement;
