/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 24/02/2021, 11:39
 */

import 'isomorphic-fetch';
import md5 from 'md5';
// import HeaderStories from 'stories/Header.stories';

export class Request {
	getApiUrl() {
		// return '/api/v1';
		return (window.B16APIURL = process.env.REACT_APP_API_URL || '/api');
	}

	makeRequest(method, url, body, headers, onprogress) {
		return new Promise((resolve, reject) => {
			let headers = {
				'Content-Language': localStorage.getItem('lang') || 'en',
				'X-Requested-With': 'XMLHttpRequest',
				'X-Access-Token': localStorage.getItem('token') || null,
				'X-Client-Version': window.COMMIT_SHA || null,
			};

			if (
				(method === 'PUT' || method === 'POST' || method === 'DELETE') &&
				body &&
				!(body instanceof FormData)
			) {
				body = JSON.stringify(body);
				headers['Content-Type'] = 'application/json';
			}

			const done = (res) => () => {
				res.json().then((json) => {
					if (
						json?.status?.toLowerCase() === 'ok' ||
						json?.status?.toLowerCase() === 'success'
					) {
						resolve(json.data);
					} else {
						reject(json);
					}
				});
			};

			const fail = (res) => () => {
				res.json().then((json) => {
					reject(json);
				});
			};

			fetch(url, {
				method,
				mode: 'cors', // no-cors, *cors, same-origin
				cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
				credentials: 'include', // include, *same-origin, omit
				headers,
				redirect: 'follow', // manual, *follow, error
				referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
				body, // body data type must match "Content-Type" header
			}).then((res) => {
				if (res.headers.has('x-access-token')) {
					localStorage.setItem('token', res.headers.get('x-access-token'));
				}

				const resolve = done(res);
				const reject = fail(res);
				if (res.ok) {
					resolve();
				} else {
					this.error(res, resolve, reject);
				}
			});
		});
	}

	get(url, headers = {}, { absolute = false } = {}) {
		let finalUrl = this.getApiUrl() + url;
		if (absolute) {
			finalUrl = url;
		}
		return this.makeRequest('GET', finalUrl, null, headers, undefined);
	}

	post(url, body, headers = {}, { absolute = false, onprogress } = {}) {
		let finalUrl = this.getApiUrl() + url;
		if (absolute) {
			finalUrl = url;
		}
		return this.makeRequest('POST', finalUrl, body, headers, onprogress);
	}

	put(url, body, headers = {}, { absolute = false, onprogress } = {}) {
		let finalUrl = this.getApiUrl() + url;
		if (absolute) {
			finalUrl = url;
		}
		return this.makeRequest('PUT', finalUrl, body, headers, onprogress);
	}

	delete(url, body, headers = {}, { absolute = false } = {}) {
		let finalUrl = this.getApiUrl() + url;
		if (absolute) {
			finalUrl = url;
		}
		return this.makeRequest('DELETE', finalUrl, body, headers);
	}

	error(res, resolve, reject) {
		res.json().then((json) => {
			reject(json);
		});
	}
}

export class CachedRequest extends Request {
	constructor({ ttl = 1000 } = {}) {
		super();
		this.cache = {};
		this.options = { ttl };
	}

	get(query, ...args) {
		const queryChecksum = md5(query);
		const cachedResponce = this.cache[queryChecksum];
		if (cachedResponce) {
			if (Date.now() - cachedResponce.time < this.options.ttl) {
				return cachedResponce.promise;
			}
		}
		this.cache[queryChecksum] = {
			promise: super.get(query, ...args),
			time: Date.now(),
		};
		return this.cache[queryChecksum].promise;
	}
}

export class RODRequest extends Request {
	getInstace() {
		return this;
	}

	error(...args) {
		if (typeof this.showError === 'function') {
			return this.showError(...args);
		} else {
			return super.error(...args);
		}
	}
}

const http = new RODRequest();

export default http;
