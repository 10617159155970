// Core
import { useCallback } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import debounce from 'lodash.debounce';

// UI
import { Input } from 'material-design/components';

// Styles
import styles from './Search.module.css';

const Search = ({ handleOnChange }) => {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedChangeHandler = useCallback(debounce(handleOnChange, 500), []);

	return (
		<div className={styles.search}>
			<FormattedHTMLMessage id={'rod.admin.articles.search'}>
				{(placeholder) => (
					<Input
						handleOnChange={debouncedChangeHandler}
						lowerCasePlaceholder={true}
						placeholder={placeholder}
					/>
				)}
			</FormattedHTMLMessage>
		</div>
	);
};

export default Search;
