// Utils
import { getOneYearPeriod } from './getOneYearPeriod';

export const getStartEndDatesForOneYear = (statisticData, isAdmin) => {
	const isValidStartDate =
		Date.parse(statisticData.end_date) - Date.parse(statisticData.start_date) <
		getOneYearPeriod() * 86400000;

	const correctedStartDate =
		!isAdmin && !isValidStartDate
			? new Date(Date.parse(statisticData.end_date) - getOneYearPeriod() * 86400000)
					.toISOString()
					.slice(0, 10)
			: statisticData.start_date;

	return [
		correctedStartDate.includes('T')
			? correctedStartDate.slice(0, correctedStartDate.indexOf('T'))
			: correctedStartDate,
		statisticData.end_date.includes('T')
			? statisticData.end_date.slice(0, statisticData.end_date.indexOf('T'))
			: statisticData.end_date,
	];
};
