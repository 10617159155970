export const getPhone = (phone) => {
	const countryCode = localStorage.getItem('code');

	if (phone.startsWith(countryCode)) {
		return phone.slice(countryCode.length);
	} else {
		// Handle the case where the phone number doesn't start with the country code
		console.error('Phone number does not start with the country code');
		return phone;
	}
};
