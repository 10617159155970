export const getArrayOfInvoiceDocs = (data) => {
	const arrayOfObjects = [];

	// Loop through each key-value pair in the data object
	for (const key in data) {
		// Create an object with the structure you provided
		const obj = {
			url: data[key].url,
			id: data[key].id,
			name: data[key].name,
		};
		// Push this object into the array
		arrayOfObjects.push(obj);
	}

	return arrayOfObjects;
};
