// Core
import { FormattedMessage } from 'react-intl';

// UI
import LoaderWhithoutProgress from 'components/LoaderWhithoutProgress';

// Styles
import styles from '../Statistics.module.css';

const StatLoader = ({ label }) => {
	return (
		<div className={styles.container__item}>
			<span>
				<FormattedMessage id={label} />
			</span>
			<div>
				<div className={styles.preLoader}>
					<LoaderWhithoutProgress />
				</div>
			</div>
		</div>
	);
};

export default StatLoader;
