// Core
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import { withUI } from 'hocs';

// Utils
import { getHelpMessage } from '../../../../../../../../messages/helper';

// UI
import { InfoButton } from 'components/Buttons/InfoButton';
import { Checkbox } from 'components/Checkboxes/Checkbox';
import checkGreen from 'images/check-green.svg';

// Styles
import styles from './UseType.module.css';

const UseType = ({
	title,
	children,
	onSelect,
	checked,
	hiddenCheckBox,
	isAddService,
	UIContext: { showModal },
	isAllPropertiesChecked,
	conditionType,
	...props
}) => {
	const [message, setMessage] = useState('');

	useEffect(() => {
		const lang = localStorage.getItem('lang');

		const selectHelpMessage = () => {
			switch (conditionType) {
				case 'digital':
					return 'condition_type_digital';
				case 'public_performance':
					return 'condition_public_performance';
				case 'synchronization':
					return 'condition_synchronization';
				case 'lyrics':
					return 'condition_lyrics';
				default:
					break;
			}
		};

		const sanitizedMessage = DOMPurify.sanitize(
			...getHelpMessage(lang, selectHelpMessage())
		);
		setMessage(sanitizedMessage);
	}, [conditionType]);

	return (
		<div
			className={styles.useType}
			style={{
				opacity:
					hiddenCheckBox && isAllPropertiesChecked
						? '0.6'
						: '1',
				pointerEvents:
					hiddenCheckBox && isAllPropertiesChecked
						? 'none'
						: 'all',
			}}
		>
			<InfoButton
				className={styles.infoButton}
				onClick={() => showModal({ text: parse(message) })}
			>
				{hiddenCheckBox && isAddService && isAllPropertiesChecked && (
					<img src={checkGreen} alt="" className={styles.checkGreen} />
				)}
				<Checkbox
					{...props}
					className={styles.checkbox}
					text={title}
					onChange={onSelect}
					checked={checked}
					forHeaders={true}
					hiddenCheckBox={hiddenCheckBox ? hiddenCheckBox : false}
				/>
			</InfoButton>
			<div className={styles.content}>{children}</div>
		</div>
	);
};

UseType.propTypes = {
	title: PropTypes.string,
	children: PropTypes.node,
	onSelect: PropTypes.func,
	checked: PropTypes.bool,
	hiddenCheckBox: PropTypes.bool,
	isAddService: PropTypes.bool,
	isAllPropertiesChecked: PropTypes.bool,
	conditionType: PropTypes.string,
	UIContext: PropTypes.object,
};

export default withUI(UseType);
