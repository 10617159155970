// Core
import React from 'react';
import numeral from 'numeral';

// Icons
import attachmentImg from 'images/attachment.svg';
import closeImg from 'images/close_black.svg';

// Styles
import styles from './UploadedFile.module.css';

const UploadedFile = ({ file, handleFileRemove }) => {
	return (
		<div className={styles.file}>
			<img src={attachmentImg} alt="attachment" />
			<div className={styles.fileDetails}>
				<div className={styles.fileName}>{file.name}</div>
				<span>{numeral(file.size).format('0b')}</span>
			</div>
			<div className={styles.fileDelete} onClick={handleFileRemove}>
				<img src={closeImg} alt="" />
			</div>
		</div>
	);
};

export default UploadedFile;
