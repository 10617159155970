// Constants
import { isForCom } from 'constants/urlConstants';

export const getRoyaltyAmount = (royaltyAmount) => {
	// Split the input into currency and balance parts
	const parts = royaltyAmount.trim().split(/\s+/);
	const currencyFirst = isNaN(parseInt(parts[0]));

	if (
		isForCom &&
		!currencyFirst &&
		(parts[1] === '$' ||
			parts[1] === '£' ||
			parts[1] === '€' ||
			parts[1] === '₽')
	) {
		return `${parts[1]} ${parts[0].replace(
			/(\d)(?=(\d{3})+(?!\d))/g,
			`$1\u00A0`
		)}`;
	}
	return royaltyAmount.replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1\u00A0`) ?? '-';
};
