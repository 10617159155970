export function hasBankDataMessage(obj) {
	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			for (const error of obj[key]) {
				if (
					error.message &&
					error.message ===
						'Bank data does not meet the requirements of the system'
				) {
					return true;
				}
			}
		}
	}
	return false;
}
