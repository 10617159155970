// Core
import { useContext } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

// Context
import { LangContext } from 'contexts/LangContext';

// UI
import { PhoneInputFormatted } from 'components/Inputs/PhoneInputFormatted';
import CodeInput from '../CodeInput/CodeInput';

// Utils
import { showError as showPhoneError } from 'validators/showError';
import { formatTimerTime } from 'utils';

// Styles
import styles from '../UserEditModal.module.css';

const PhoneBlock = ({
	countries,
	user,
	phoneCodeRef,
	edit,
	userData,
	onChangePhone,
	onChangePhoneCode,
	phoneSecondsLeft,
	showPhoneCodeButton,
	handleSendPhoneCode,
	showPhoneCodeInput,
	showPhoneConfirmCodeButton,
	handlePhoneConfirm,
	errors,
	isPhoneConfirmed,
	isResendPhoneButton,
	phoneDisabled,
	hidePhoneEdition,
	resendErrors,
	emailSecondsLeft,
	showCodeInput,
}) => {
	const { lang } = useContext(LangContext);
	const showError = (error) => {
		if (error.rule) {
			switch (error.rule) {
				case 'invalid':
					if (error.hasOwnProperty('remaining')) {
						if (error.remaining === 0) {
							return <FormattedHTMLMessage id={'rod.error.code_exhausted'} />;
						} else {
							return (
								<FormattedHTMLMessage
									id={'rod.error.code.invalid'}
									values={{ count: error.remaining }}
								/>
							);
						}
					} else {
						return;
					}
				case 'forbidden':
					return <FormattedHTMLMessage id={'rod.error.code_exhausted'} />;
				case 'retry':
					return <FormattedHTMLMessage id={'rod.error.code.retry'} />;

				default:
					return <FormattedHTMLMessage id={`rod.error.${error.rule}`} />;
			}
		} else if (error.phone) {
			return <FormattedHTMLMessage id={`rod.error.${error.phone}`} />;
		} else {
			return <FormattedHTMLMessage id={`rod.error.${error}`} />;
		}
	};

	return (
		<div
			className={styles.phoneBlock}
			style={errors.code ? { marginTop: '24px' } : { marginTop: '14px' }}
		>
			<div className={styles.phoneInputContainer}>
				<div className={styles.phoneInput}>
					<FormattedMessage id={'rod.field.sms.helper'}>
						{(helper) => (
							<PhoneInputFormatted
								name={'phone'}
								phone={userData.phone}
								showError={showPhoneError('phone')}
								placeholder={
									user.phone
										? user.phone
										: lang === 'ru'
										? 'телефон'
										: 'phone number'
								}
								errors={errors?.phone || resendErrors?.code}
								helper={
									!showPhoneCodeButton && !hidePhoneEdition
										? `${helper} ${
												phoneSecondsLeft > 0
													? formatTimerTime(phoneSecondsLeft)
													: '0:00'
										  }`
										: undefined
								}
								onChange={onChangePhone}
								disabled={
									!edit ||
									phoneDisabled ||
									isPhoneConfirmed ||
									emailSecondsLeft > 0 ||
									showCodeInput ||
									(resendErrors &&
										resendErrors?.code &&
										(resendErrors.code[0]?.rule === 'code_exhausted' ||
											resendErrors.code[0]?.rule === 'forbidden' ||
											(resendErrors.code[0]?.rule === 'invalid' &&
												resendErrors.code[0]?.remaining === 0)))
								}
								countries={countries}
								forbidEnter
								doneIcon={isPhoneConfirmed}
							/>
						)}
					</FormattedMessage>
					{showPhoneCodeButton && edit && !hidePhoneEdition && (
						<button
							className={styles.resendBtn}
							style={
								errors?.phone || resendErrors?.code
									? { bottom: 0 }
									: { bottom: '20px' }
							}
							onClick={handleSendPhoneCode}
						>
							<FormattedMessage
								id={
									isResendPhoneButton
										? 'rod.verify_sms.send_again'
										: 'rod.verify.send_code'
								}
							/>
						</button>
					)}
				</div>
			</div>
			{showPhoneCodeInput && (
				<div
					style={
						showPhoneCodeButton && edit && !hidePhoneEdition
							? { marginTop: 0 }
							: { marginTop: '-20px' }
					}
				>
					<CodeInput
						showCodeButton={showPhoneCodeButton}
						codeRef={phoneCodeRef}
						errors={errors}
						onChangeCode={onChangePhoneCode}
						showError={showError}
						showConfirmCodeButton={showPhoneConfirmCodeButton}
						handleConfirm={handlePhoneConfirm}
						phone
					/>
				</div>
			)}
		</div>
	);
};

export default PhoneBlock;
