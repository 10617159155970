// Core
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

// Context
import { UIContext } from 'contexts/UIContext';

// Styles
import s from './InfoToast.module.css';

const InfoToast = (props = '') => {
	const [isToastClose, setIsToastClose] = useState(false);
	const { hideToast } = useContext(UIContext);

	const handleOnClose = () => {
		setIsToastClose(true);
	};

	useEffect(() => {
		const closeToast = () => {
			handleOnClose();

			setTimeout(() => {
				hideToast();
			}, 1000);
		};

		const closeTimeout = setTimeout(closeToast, 3000);

		return () => {
			clearTimeout(closeTimeout);
		};
	}, []);

	useEffect(() => {
		if (isToastClose && props.onClose) {
			const closeToast = () => {
				props.onClose();
				hideToast();
			};

			const closeTimeout = setTimeout(closeToast, 1000);

			return () => {
				clearTimeout(closeTimeout);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isToastClose, props]);

	return (
		<div className={!isToastClose ? s.toastWrapper : s.toastWrapperClose}>
			{props.title && (
				<span className={s.toastTitle}>
					<FormattedMessage id={props.title} />
				</span>
			)}
			{props.text && (
				<span className={s.toastText}>
					<FormattedMessage id={props.text} />
				</span>
			)}
			{props.isCloseBtn && (
				<div className={s.toastClose} onClick={handleOnClose}>
					<FormattedMessage id={'rod.action.close'} />
				</div>
			)}
		</div>
	);
};

export default InfoToast;
