// Core
import { FormattedMessage } from 'react-intl';

export const contractTermsSteps = [
	{
		id: 1,
		status: 'active',
		title: <FormattedMessage id={'rod.account.create.step_3_1.title'} />,
	},
	{
		id: 2,
		status: 'default',
		title: <FormattedMessage id={'rod.account.create.step_3_2.title'} />,
	},
];
