// Core
import React, { useEffect } from 'react';

// UI
import { EndConfirmationPage } from 'components/EndConfirmationPage';

const ReleaseEnd = () => {
	const isH11Edit = window.location.href.includes('/edit/');

	useEffect(() => {
		return () => {
			if (isH11Edit) {
				localStorage.removeItem('releaseEdit');
			} else {
				localStorage.removeItem('releaseId');
			}
		};
	}, [isH11Edit]);

	return (
		<EndConfirmationPage
			title={
				isH11Edit
					? 'rod.release.create.step.end.edit_h11.title'
					: 'rod.release.create.step.end.title'
			}
			text={
				isH11Edit
					? 'rod.release.create.step.end.edit_h11.text'
					: 'rod.release.create.step.end.text'
			}
			textLinks={{
				link: {
					link: '/repertoire/releases',
					phrase: 'rod.release.final.step.link.text',
				},
			}}
			btnText={'rod.action.to_index_page'}
			btnLink={`/`}
		/>
	);
};

export default ReleaseEnd;
