// Core
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Col, Row } from 'react-grid-system';
import { useContext, useEffect, useState } from 'react';

// UI
import { Loading } from 'components';
import { Pagination } from 'material-design/components';
import { RubricLang } from '../RubricLang';

// Icons
import plusImg from 'images/plus.svg';
import edit from 'images/edit.svg';
import remove from 'images/remove.svg';

// Context
import { RootContext } from 'contexts/RootContext';
import { UIContext } from 'contexts/UIContext';

// Types
import modalTypes from 'constants/modalTypes';

// Styles
import styles from './RubricSettings.module.css';

const RubricSettings = () => {
	const [loading, setLoading] = useState(false);
	const [rubrics, setRubrics] = useState([]);

	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [isNewRubric, setIsNewRubric] = useState(false);
	const [currentCard, setCurrentCard] = useState(null);

	const rubricLang = localStorage.getItem('rubric_lang');
	const [lang, setLang] = useState(rubricLang || 'ru');

	const {
		getAdminArticlesRubrics,
		addAdminArticlesRubric,
		updateAdminArticlesRubric,
		getAdminArticlesSectionsTranslate,
		postAdminRubricsList,
		deleteAdminArticlesRubric,
	} = useContext(RootContext);
	const { showBackNavi, hideBackNavi, showModal } = useContext(UIContext);
	const [currentCardIndex, setCurrentCardIndex] = useState();

	useEffect(() => {
		showBackNavi('/admin/v2/articles');

		return function cleanup() {
			hideBackNavi();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang]);

	useEffect(() => {
		getRubrics();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang, page]);

	const getRubrics = () => {
		if (isNewRubric) setIsNewRubric(false);
		setLoading(true);
		getAdminArticlesRubrics(lang, page)
			.then((res) => {
				setTotal(res.total);
				setRubrics(res.data.sort((a, b) => a.sort - b.sort));
			})
			.catch((err) => {
				console.error('Error', err);
			})
			.finally(() => setLoading(false));
	};

	const dragStartHandler = (e, item, index) => {
		item.index = index;
		setCurrentCardIndex(index);
		setCurrentCard(item);
	};

	const dragEndHandler = (e) => {
		e.preventDefault();
		e.currentTarget.style.background = 'var(--color-white)';
	};

	const dragOverHandler = (e) => {
		e.preventDefault();
		e.currentTarget.style.background = 'var(--color-light-green)';
	};

	const getArrayIndexOfRubricById = (id, array) => {
		let result = -1;
		array.find((item, index) => {
			if (item.id === id) {
				result = index;
				return true;
			} else return false;
		});

		return result;
	};

	const dropHandler = (e, item, index) => {
		e.preventDefault();
		const isTopDirection = currentCardIndex > index;
		if (index === currentCard.index) return;

		let rubricsSortWithoutTakenRecord = [...rubrics].filter(
			(rubric) => rubric.id !== currentCard.id
		);

		const targetRubricId = getArrayIndexOfRubricById(
			item.id,
			rubricsSortWithoutTakenRecord
		);

		const beforeTarget = rubricsSortWithoutTakenRecord.slice(
			0,
			targetRubricId + (isTopDirection ? 0 : 1)
		);
		const afterTarget = rubricsSortWithoutTakenRecord.slice(
			targetRubricId + (isTopDirection ? 0 : 1)
		);
		setRubrics([...beforeTarget, currentCard, ...afterTarget]);
		postRubricsListToBack([...beforeTarget, currentCard, ...afterTarget]);

		e.currentTarget.style.background = 'var(--color-white)';
	};

	const postRubricsListToBack = (rubricsList) => {
		const rubricsIdList = rubricsList.map((item) => item.id);
		postAdminRubricsList({ sections: rubricsIdList }).then((res) => {});
	};

	const handleRemoveRubric = (id) => {
		deleteAdminArticlesRubric(id).then(() => getRubrics());
	};

	return (
		<div className={styles.mainWrapper}>
			<div className={styles.sectionTitleWrapper}>
				<div className={styles.sectionTitle}>
					<FormattedHTMLMessage id={'rod.admin.articles.rubrics'} />
				</div>

				<div className={styles.createArticleBtnWrapper}>
					<div
						className={styles.notifyBtn}
						onClick={showModal(
							{
								lang: lang,
								addAdminArticlesRubric: addAdminArticlesRubric,
								getRubrics: getRubrics,
							},
							'rubric_modal'
						)}
					>
						<img src={plusImg} alt="add" className={styles.iconPlus} />
						<div>
							<FormattedHTMLMessage id={'rod.admin.articles.rubric_add'} />
						</div>
					</div>
				</div>
			</div>
			{rubrics && rubrics.length > 0 && (
				<div>
					<RubricLang className={styles.lang} lang={lang} setLang={setLang} />
				</div>
			)}

			{loading ? (
				<Loading className={styles.loader} />
			) : (
				<>
					{rubrics && rubrics.length > 0 ? (
						<div className={styles.RepertoireSongInfo__mainInfoTable}>
							<Row className={styles.row_header}>
								<Col width={'2%'}></Col>
								<Col width={'98%'}>
									<FormattedHTMLMessage
										id={'rod.admin.articles.rubric_settings_title'}
									/>
								</Col>
							</Row>
							<div className={styles.RepertoireMainInfoTable__body}>
								<ul className={styles.table}>
									{rubrics.map((item, index) => (
										<li
											onDragStart={(e) => dragStartHandler(e, item, index)}
											onDragLeave={(e) => dragEndHandler(e)}
											onDragEnd={(e) => dragEndHandler(e)}
											onDragOver={(e) => dragOverHandler(e)}
											onDrop={(e) => dropHandler(e, item, index)}
											draggable={true}
											key={item?.file?.id || item.name}
											className={styles.table__item}
										>
											<Row className={styles.row_data}>
												<Row className={styles.row_title}>
													<Col width={'2%'} className={styles.row_title_number}>
														{page * 10 - 10 + index + 1}
													</Col>
													<Col width={'98%'}>
														<div className={styles.rubricWrapper}>
															{item.title}
															<div className={styles.ActionsWrapper}>
																<img
																	src={edit}
																	alt="edit"
																	onClick={showModal(
																		{
																			rubric: item,
																			lang: lang,
																			updateAdminArticlesRubric: updateAdminArticlesRubric,
																			getAdminArticlesSectionsTranslate: getAdminArticlesSectionsTranslate,
																			getRubrics: getRubrics,
																		},
																		'rubric_modal'
																	)}
																/>
																<img
																	src={remove}
																	alt="delete"
																	onClick={showModal(
																		item.articles.length > 0
																			? {
																					text: (
																						<FormattedMessage
																							id={'rod.rubric.delete-help'}
																						/>
																					),
																					confirmBtnTxt: (
																						<FormattedMessage
																							id={'rod.modal.understood'}
																						/>
																					),
																			  }
																			: {
																					title: (
																						<FormattedMessage
																							id={'rod.rubric.delete-confirm'}
																						/>
																					),
																					onAction: () => {
																						handleRemoveRubric(item.id);
																					},
																					confirmBtnTxt: (
																						<FormattedMessage
																							id={'rod.modal.yes_delete'}
																						/>
																					),
																					declineBtnTxt: (
																						<FormattedMessage
																							id={'rod.modal.cancel'}
																						/>
																					),
																			  },
																		modalTypes.FULL_MODAL
																	)}
																/>
															</div>
														</div>
													</Col>
												</Row>
											</Row>
										</li>
									))}
								</ul>
							</div>
						</div>
					) : (
						<div className={styles.noData}>
							<FormattedMessage id={'rod.admin.no-data'} />
						</div>
					)}
				</>
			)}

			{total > 10 && !loading && (
				<Pagination
					page={page}
					total={total}
					paginate={(pageNumber) => {
						setPage(pageNumber);
					}}
					items_on_page={10}
				/>
			)}
		</div>
	);
};

export default RubricSettings;
