export const languageName = (lang) => {
	switch (lang) {
		case 'en':
			return 'rod.field.lang.en';
		case 'ru':
			return 'rod.field.lang.ru';
		default:
			return 'rod.field.lang.ru';
	}
};
