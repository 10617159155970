import { useState, useEffect } from 'react';
import { withUI, withRoot, withAuth } from 'hocs';
import { compose } from 'recompose';
import { Loading } from 'components';

import styles from './ContractInfo.module.css';
import { ContractDataMarkup } from './ContractDataMarkup';

const ContractInfo = (props) => {
	const [loading, setLoading] = useState(true);
	const [contractData, setContractData] = useState('');
	const [contractCondition, setContractCondition] = useState({});
	const [contractConditionDetails, setContractConditionDetails] = useState({});

	const {
		UIContext: { showBackNavi, hideBackNavi },
		rootContext: {
			getContractById,
			getContractCondition,
			getContractConditionDetail,
		},
	} = props;
	const contractId = props.match.params.id;
	const conditionId = props.match.params.conditionid;

	async function getBackData() {
		let contractData = await getContractById(contractId);
		contractData = contractData.data.data;
		setContractData(contractData);
		let contractCondition = await getContractCondition(contractId);
		contractCondition = contractCondition.data.data;
		const selectedCondition = contractCondition.find(
			(condition) => condition.id === parseInt(conditionId)
		);
		setContractCondition(selectedCondition ? selectedCondition : {});

		let contractConditionDetails = await getContractConditionDetail(
			contractId,
			conditionId
		);
		contractConditionDetails = contractConditionDetails.data;
		setContractConditionDetails(contractConditionDetails);

		setLoading(false);
	}

	useEffect(() => {
		showBackNavi('/treaties');
		getBackData();
		return function cleanup() {
			hideBackNavi();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return loading ? (
		<Loading non_margin />
	) : (
		<div className={styles.mainWrapper}>
			<div className={styles.info}>
				<ContractDataMarkup
					contractData={contractData}
					contractCondition={contractCondition}
					contractConditionDetails={contractConditionDetails}
				/>
			</div>
		</div>
	);
};

export default compose(withUI, withRoot, withAuth)(ContractInfo);
