// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';

// MaterialComponents
import { SwitchSave } from 'material-design/MuiStyled/MuiStyled';

// Styles
import styles from './LastSavePerson.module.css';

const LastSavePerson = ({ takeByLastPerson, toogleLastPerson }) => {
	return (
		<div className={styles.lastSave}>
			<div className={styles.lastSave__switch}>
				<SwitchSave
					checked={takeByLastPerson}
					onChange={(_, c) => toogleLastPerson(c)}
				/>
			</div>
			<span>
				<FormattedMessage
					id={'rod.release.create.step.author_info.form.save'}
				/>
			</span>
		</div>
	);
};

export default LastSavePerson;
