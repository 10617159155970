export const formatErrors = (data) => {
	const errors = {};

	for (let key in data) {
		if (data[key].error) {
			if (!errors[key]) {
				errors[key] = [];
			}
			errors[key].push({
				rule: data[key].error?.rule ? data[key].error?.rule : data[key].error,
				message: data[key].error?.message ? data[key].error?.message : '',
			});
		}
	}

	return errors;
};
