/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

// import PropTypes from 'prop-types';
import React from 'react';
import ReactSelect from 'react-select';
import { compose } from 'recompose';
import { withForwardRef } from '../../../hocs';
import styles from './Select.module.css';

class Select extends React.Component {

	static propTypes = {};

	static defaultProps = {};

	static displayName = 'Select';

	render() {
		const { className, disabled, forwardRef, placeholder, options, withFocusRef, helper, ...props } = this.props;

		return (
			<div>
				<ReactSelect {...props} className={styles.Select}
										 placeholder={placeholder}
										 classNamePrefix={'select'}
										 options={options}
				/>
				{helper && <span className={styles.Helper}>helper text</span>}
			</div>
		);
	}
}

export default compose(
	withForwardRef,
)(Select);
