// Core
import { FormattedMessage } from 'react-intl';

// Styles
import styles from './Error.module.css';

const Error = ({ id }) => {
	return <span className={styles.helper}>{<FormattedMessage id={id} />}</span>;
};

export default Error;
