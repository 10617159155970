/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 08/05/2020, 15:26
 */

import { ForgotPassword as ForgotPasswordForm } from 'forms/index';
import { withUI } from 'hocs';

import { withAuth, withRoot } from 'hocs/index';
import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
// import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './ForgotPassword.module.css';

class ForgotPassword extends PureComponent {
	static propTypes = {};

	// submitHandler = ({ username, password }) => {
	// 	const { authContext: { login } } = this.props;
	// 	login({
	// 		username,
	// 		password,
	// 	});
	// };

	componentDidMount() {
		this.props.UIContext.showBackNavi();
	}

	componentWillUnmount() {
		this.props.UIContext.hideBackNavi();
	}

	render() {
		const {
			authContext: { isAuth, forgotPassword },
		} = this.props;

		if (isAuth) {
			return <Redirect to={'/'} />;
		}

		return (
			<div className={styles.Page}>
				<ForgotPasswordForm onSubmit={forgotPassword} />
			</div>
		);
	}
}

export default compose(withRoot, withAuth, withUI)(ForgotPassword);
