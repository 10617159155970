export const getRedirectLink = (item) => {
	switch (item.draft_type) {
		case 'release':
			return `/release/${item.id}/${item.step}`;
		case 'composition':
			if (item.step === 'info' || item.step === 'confirm') {
				return `/compositions/create`;
			} else {
				return `/compositions/${item.id}/${item.step}`;
			}

		default:
			break;
	}
};
