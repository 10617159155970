import { FaqInput } from './FaqInput';
import { withRoot } from 'hocs';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import debounce from 'lodash.debounce';
import styles from './FaqAutosuggestInput.module.css';

class FaqAutosuggestInput extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			value: props.value,
			values: [],
			listItems: [],
			text: props.value.title,
			isBackResponse: false,
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.value !== this.props.value) {
			this.setState({
				value: this.props.value,
				text: this.props.value.title,
			});
		}
	}

	sendRequest = (value) => {
		if (value.length < 3) {
			this.setState({ listItems: [] });
			return;
		}
		const {
			rootContext: { /* suggestSociety ,*/ getFaqSearchByText },
		} = this.props;
		getFaqSearchByText(value, this.props.lang).then((res) => {
			this.setState({ listItems: res.data });
			this.setState({ isBackResponse: true });
		});
	};

	clear = () => {
		this.setState({
			value: null,
			text: '',
		});
		this.props.onChange({});
	};

	changeBySelect = (item) => () => {
		this.setState({
			listItems: [],
			value: item,
			text: item.heading, //todo
		});
		this.props.onChange(item, this.state.text);
	};

	debouncedChangeHandler = debounce((value) => this.sendRequest(value), 500);

	changeValue = (e) => {
		this.setState({ isBackResponse: false });
		const value = e.target.value;
		this.setState({
			text: value,
			value: null,
		});
		this.debouncedChangeHandler(value);
	};

	getMultiplyResult = () => {
		if (this.state.isBackResponse) {
			this.setState({ isBackResponse: false });
			const result =
				this.state.listItems.length > 0
					? this.state.listItems.map((item) => item)
					: [];
			this.setState({
				listItems: [],
				values: result,
			});
			this.props.onChange(result, this.state.text);
		}
	};

	render() {
		const { listItems, value, values, text } = this.state;
		return (
			<div className={styles.Component}>
				<FaqInput
					value={text}
					handleOnChange={this.changeValue}
					handleOnEnterPress={this.getMultiplyResult}
				/>
				{text &&
					!value &&
					!values.length &&
					text.length > 2 &&
					listItems.length === 0 && (
						<div className={styles.Dropdown}>
							<ul className={styles.List}>
								<li>
									<FormattedMessage id={'rod.autosuggest.nothing_to_show'} />
								</li>
							</ul>
						</div>
					)}

				{listItems.length > 0 && (
					<div className={styles.Dropdown}>
						<ul className={styles.List}>
							{listItems.map((item) => (
								<li key={item.slug} onClick={this.changeBySelect(item)}>
									<span dangerouslySetInnerHTML={{ __html: item.heading }} />
									<span className={styles.helpTitle}>
										&nbsp;-&nbsp;
										<FormattedMessage id={'rod.statistic.help-title'} />
									</span>
								</li>
							))}
						</ul>
					</div>
				)}
			</div>
		);
	}
}

FaqAutosuggestInput.propTypes = {};

FaqAutosuggestInput.defaultProps = {};

export default compose(withRoot)(FaqAutosuggestInput);
