// Data
import { monthsEN, monthsRU } from '../data/months/months';

const getRuDayOfWeek = (value) => {
	switch (value) {
		case 1:
			return ' в понедельник';
		case 2:
			return ' во вторник';
		case 3:
			return ' в среду';
		case 4:
			return ' в четверг';
		case 5:
			return ' в пятницу';
		case 6:
			return ' в субботу';
		case 7:
			return ' в воскресенье';
		default:
			return '';
	}
};

const getEnDayOfWeek = (value) => {
	switch (value) {
		case 1:
			return 'monday';
		case 2:
			return 'tuesday';
		case 3:
			return 'wednesday';
		case 4:
			return 'thursday';
		case 5:
			return 'friday';
		case 6:
			return 'saturday';
		case 7:
			return 'sunday';
		default:
			return '';
	}
};

const getRuPeriod = (value) => {
	switch (value) {
		case 1:
			return 'еженедельно';
		case 2:
			return 'ежемесячно';
		case 3:
			return 'поквартально';
		case 4:
			return 'каждые полгода';
		case 5:
			return 'раз в год';
		default:
			return '';
	}
};

const getEnPeriod = (value) => {
	switch (value) {
		case 1:
			return 'weekly';
		case 2:
			return 'monthly';
		case 3:
			return 'quarterly';
		case 4:
			return 'every six months';
		case 5:
			return 'once a year';
		default:
			return '';
	}
};

export const makeReportSchedule = (data, lang) => {
	// Example of response report_schedule is "3/45/31/12": period/days-during/day/month
	const text = data.split('/');

	return lang === 'ru' ? (
		<div>
			<span>{getRuPeriod(+text[0])}</span>
			{+text[0] === 1 && <span>{getRuDayOfWeek(+text[1])}</span>}
			{+text[0] !== 1 && (
				<>
					<span>
						, в течение {text[1]} {+text[1] !== 1 ? ' дней' : ' дня'}
					</span>
					<span>
						, начиная с {text[2]}{' '}
						{monthsRU[+text[3] - 1] ? monthsRU[+text[3] - 1] : 'числа'}
					</span>
				</>
			)}
		</div>
	) : (
		<div>
			<span>{getEnPeriod(+text[0])}</span>
			{+text[0] === 1 && <span>{' '} on {getEnDayOfWeek(+text[1])}</span>}
			{+text[0] !== 1 && (
				<>
					<span>
						, during {text[1]} {+text[1] !== 1 ? ' days' : ' day'}
					</span>
					<span>
						, starting from {text[2]}{' '}
						{monthsEN[+text[3] - 1] ? monthsEN[+text[3] - 1] : 'date'}
					</span>
				</>
			)}
		</div>
	);
};
