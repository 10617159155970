const ReleaseCompletionSteps = {
	RELEASE: 'release',
	FILE: 'file',
	TRACKS: 'tracks',
	AUTHORS: 'authors',
	LYRICS: 'lyrics',
	COVER: 'cover',
	DISTRIBUTION: 'distribution',
	CONFIRM: 'confirm',
	MODERATION: 'moderation',
};

export default ReleaseCompletionSteps;
