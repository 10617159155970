// Services
import { dateFormatEditor } from 'logic';

export const correctDatesView = (res) => {
	const result = res.map((item, index) => {
		const created_at = new Date(item.created_at);
		const date_publication_corrected =
			item.date_publication.replace(' ', 'T') + 'Z';
		const date_publication = new Date(date_publication_corrected);
		const time_publication =
			date_publication.getHours().toString().padStart(2, '0') +
			':' +
			date_publication.getMinutes().toString().padStart(2, '0');
		return {
			...item,
			created_at: dateFormatEditor(created_at, 'dmy', '.'),
			date_publication: dateFormatEditor(date_publication, 'dmy', '.'),
			time_publication: time_publication,
		};
	});
	return result;
};
