// Utils
import { fm } from "utils";

export const translateStatus = (status) => {
	switch (status) {
		case 'rejected':
			return fm('rod.admin.account.status.payment.rejected');
		case 'paid':
			return fm('rod.admin.account.status.payment.paid');
		case 'moderation':
			return fm('rod.admin.account.status.payment.moderation');
      case 'in_processing':
        return fm('rod.admin.account.status.payment.in_processing');
		default:
			return status;
	}
};
