// Core
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

// UI
import { Button } from 'components/Buttons/Button';
import { Label } from 'components/Labels/Label';

// Styles
import styles from './Feedback.module.css';

const Feedback = ({ uploaded }) => {
	const { push } = useHistory();

	return (
		<div className={styles.centeredContainer}>
			<div className={styles.centeredContainer__title}>
				<Label
					text={<FormattedMessage id="rod.repertoire.uploaded.title" />}
					font="--gilroy-Medium-40"
					black
				/>
			</div>
			<div className={styles.centeredContainer__content}>
				<Label
					text={<FormattedHTMLMessage id={'rod.repertoire.uploaded.content'} />}
					font="--gilroy-Medium-18-140"
					black
				/>
			</div>
			<Button
				text={<FormattedMessage id={'rod.action.to_index_page'} />}
				variant={'primary'}
				onClick={() => push('/')}
			/>
		</div>
	);
};

export default Feedback;
