// Core
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

// Utils
import { getPerformers, showGenres, formatPublishDate } from 'utils';

// Context
import { RootContext } from 'contexts/RootContext';
import { LangContext } from 'contexts/LangContext';

// Styles
import s from './InfoTable.module.css';

const InfoTable = ({ info, allGenres }) => {
	const langContext = useContext(LangContext);
	const { getCountryById } = useContext(RootContext);
	const lang = langContext.lang;

	return (
		<div className={s.info__content}>
			<div className={s.info__table}>
				<div className={s.info__item}>
					<span>
						<FormattedMessage id={'rod.for_all.performer'} />
					</span>
					<span>{getPerformers(info) || '-'}</span>
				</div>
				<div className={s.info__item}>
					<span>
						<FormattedMessage id={'rod.release.info.label'} />
					</span>
					<span
						className={s.item__ellipsis}
						title={info.label ? info.label : '-'}
					>
						{info.label ? info.label : '-'}
					</span>
				</div>
				<div className={s.info__item}>
					<span>
						<FormattedMessage id={'rod.composition.info.genres'} />
					</span>
					<span>
						{info.genres?.length
							? showGenres(allGenres, info.genres, lang)
							: '-'}
					</span>
				</div>
				<div className={s.info__item}>
					<span>
						<FormattedMessage id={'rod.release.info.created_in_country'} />
					</span>
					<span>
						{info.created_country_id
							? getCountryById(info.created_country_id)
							: '-'}
					</span>
				</div>
				<div className={s.info__item}>
					<span>
						<FormattedMessage id={'rod.composition.info.catalog_number'} />
					</span>
					<span>{info.catalogue_number ? info.catalogue_number : '-'}</span>
				</div>
				<div className={s.info__item}>
					<span>
						<FormattedMessage id={'GRID'} />
					</span>
					<span>{info.grid ? info.grid : '-'}</span>
				</div>
				<div className={s.info__item}>
					<span>
						<FormattedMessage id={'rod.composition.info.creation_date'} />
					</span>
					<span>
						{info.published_date ? formatPublishDate(info.published_date) : '-'}
					</span>
				</div>
				<div className={s.info__item}>
					<span>
						<FormattedMessage id={'rod.composition.info.sales_start_date'} />
					</span>
					<span>
						{info.sale_start_dates.length
							? info.sale_start_dates.map((item) => (
									<p>
										{item.date}&nbsp;
										{lang === 'en' ? (
											<span>{'(' + item.territories[0]?.title_en + ')'}</span>
										) : (
											<span>{'(' + item.territories[0]?.title_ru + ')'}</span>
										)}
									</p>
							  ))
							: '-'}
					</span>
				</div>
				<div className={s.info__item}>
					<span>
						<FormattedMessage id={'rod.for_all.subtitle'} />
					</span>
					<span>{info.subtitle ? info.subtitle : '-'}</span>
				</div>
			</div>
		</div>
	);
};

export default InfoTable;
