// Core
import { FormattedMessage } from 'react-intl';

export const radioOutlets = [
	{
		text: (
			<FormattedMessage
				id={'rod.release.create.step.distribution.input_radio3'}
			/>
		),
		value: 'all',
		disabled: false,
	},
	{
		text: (
			<FormattedMessage
				id={'rod.release.create.step.distribution.input_radio4'}
			/>
		),
		value: 'inc',
		disabled: false,
	},
];
