// Core
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

// Context
// import { RootContext } from 'contexts/RootContext';
// import { UIContext } from 'contexts/UIContext';

// UI
import { FormInput } from 'components/Form/FormInput';
import CmoBtn from 'material-design/ReleaseCreate/steps/Authors/AuthorForm/CmoBtn/CmoBtn';

// Icons
import plus_in_square from 'images/plus_in_square.svg';

// Styles
import s from '../AuthorsShares.module.css';

const Contributor = ({
	index,
	handleOnChange,
	errors,
	contributors,
	handleOnFocus,
	handleOnBlur,
	tmpInputValue,
	addPublisher,
	selectPerson,
	onSave,
	setModal,
	isLoading,
	deleteAuthor,
	deletePublisher,
}) => {
	// TODO
	// Окрашивание зеленым кнопки работает некорректно, когда несколько контрибьюторов
	// const { setCompositionAuthorSocieties } = useContext(UIContext);
	// const compositionId = localStorage.getItem('compositionId');
	// const { getCompositionAuthorSocieties } = useContext(RootContext);

	// useEffect(() => {
	// 	if (contributors && contributors[index].id) {
	// 		try {
	// 			getCompositionAuthorSocieties(
	// 				compositionId,
	// 				contributors[index].id,
	// 				'author'
	// 			).then((res) => {
	// 				if (res.data.data?.length > 0) {
	// 					setCompositionAuthorSocieties(true);
	// 				}
	// 			});
	// 		} catch (error) {
	// 			console.error('Error getAuthorSocieties');
	// 		}
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	return (
		<div key={index} className={s.author__item}>
			<div className={s.grid}>
				<div className={s.input}>
					<FormInput
						type="muiInput"
						name={'title'}
						onChange={handleOnChange(index)}
						errors={errors}
						data={contributors[index]}
						label={
							<FormattedMessage
								id={'rod.release.create.step.recording_info.label.author'}
								values={{ id: index + 1 }}
							/>
						}
						required={index === 0}
					/>
					{Object.keys(errors).includes('title') &&
						errors['title'].map(
							(item) =>
								item.key === index &&
								(item.type === 'max_length' ? (
									<span className={s.Helper}>
										<FormattedMessage
											id={`rod.error.max_length`}
											values={{
												num: item.value,
											}}
										/>
									</span>
								) : (
									<span className={s.Helper}>
										<FormattedMessage
											id={`rod.release_create.error.required`}
										/>
									</span>
								))
						)}
				</div>

				<div className={s.input}>
					<FormInput
						type={'muiAutocompleteMultiple'}
						name={'roles'}
						onChange={handleOnChange(index)}
						errors={errors}
						data={contributors[index]}
						label={
							<FormattedMessage
								id={'rod.release.create.step.recording_info.label.author_role'}
								values={{ id: index + 1 }}
							/>
						}
					/>

					{Object.keys(errors).includes('roles') &&
						errors['roles'].includes(index) && (
							<span className={s.Helper}>
								{<FormattedMessage id={`rod.release_create.error.required`} />}
							</span>
						)}
				</div>

				<div className={`${s.info} ${s.title__info} ${s.authorData}`}>
					<FormInput
						type={'muiSlider'}
						name={'ownership'}
						onChange={handleOnChange(index)}
						onFocus={handleOnFocus(index)}
						onBlur={handleOnBlur(index)}
						tmpInputValue={tmpInputValue}
						errors={errors}
						data={contributors[index]}
						labelSlider={
							<FormattedMessage
								id={'rod.release.create.step.recording_info.label.author_share'}
								values={{ id: index + 1 }}
							/>
						}
						width={92}
						required
						bind={selectPerson?.contributors[index]?.publisher_show}
					/>
					{Object.keys(errors).includes('ownership') &&
						errors['ownership'].includes(index) && (
							<span className={s.Helper}>
								{<FormattedMessage id={`rod.release_create.error.required`} />}
							</span>
						)}
				</div>
				<CmoBtn
					selectPerson={selectPerson}
					index={index}
					onSave={onSave}
					deleteContributor={deleteAuthor}
					setModal={setModal}
					isLoading={isLoading}
					type="author"
					composition
				/>
			</div>
			{contributors[index]?.publisher_show && (
				<div className={s.publisher_item}>
					<div className={s.publisher_author}>
						<FormInput
							type="muiInput"
							name={`publisher`}
							onChange={handleOnChange(index)}
							errors={errors}
							data={contributors[index]}
							label={
								<FormattedMessage
									id={'rod.authors_shares.author_publisher_id'}
									values={{ id: index + 1 }}
								/>
							}
						/>
						{Object.keys(errors).includes('publisher') &&
							errors['publisher'].map(
								(item) =>
									item.key === index &&
									(item.type === 'max_length' ? (
										<span className={s.Helper}>
											<FormattedMessage
												id={`rod.error.max_length`}
												values={{
													num: item.value,
												}}
											/>
										</span>
									) : (
										<span className={s.Helper}>
											<FormattedMessage
												id={`rod.release_create.error.required`}
											/>
										</span>
									))
							)}
					</div>
					<div className={s.input}>
						<FormInput
							type={'muiSlider'}
							name={`publisher_share`}
							onChange={handleOnChange(index)}
							onFocus={handleOnFocus(index)}
							onBlur={handleOnBlur(index)}
							tmpInputValue={tmpInputValue}
							errors={errors}
							data={contributors[index]}
							labelSlider={
								<FormattedMessage
									id={'rod.authors_shares.publisher_share_id'}
									values={{ id: index + 1 }}
								/>
							}
							width={128}
							bind={selectPerson?.contributors[index]?.publisher_show}
						/>
						{Object.keys(errors).includes('publisher_share') &&
							errors['publisher_share'].includes(index) && (
								<span className={s.Helper}>
									{
										<FormattedMessage
											id={`rod.release_create.error.required`}
										/>
									}
								</span>
							)}
					</div>
					<CmoBtn
						selectPerson={selectPerson}
						index={index}
						onSave={onSave}
						deleteContributor={deletePublisher}
						setModal={setModal}
						isLoading={isLoading}
						type="publisher"
					/>
				</div>
			)}
			<div className={s.addPublisher}>
				{!contributors[index]?.publisher_show && (
					<div
						className={s.button__addPublisher}
						onClick={() => addPublisher(index)}
					>
						<img src={plus_in_square} alt="" />
						<FormattedHTMLMessage
							id={'rod.release.create.step.recording_info.label.add_publisher'}
						/>
					</div>
				)}
				<FormInput
					checked={contributors[index]}
					className={s.author__checkbox}
					type={'muiCheckbox'}
					name={'controlled_by_submitter'}
					onChange={handleOnChange(index)}
					errors={errors}
					data={contributors[index]}
					label={<FormattedMessage id={'rod.authors_shares.my_shares'} />}
				/>
			</div>
		</div>
	);
};

export default Contributor;
