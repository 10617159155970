// Core
import { FormattedMessage } from 'react-intl';

export const checkStatus = (item) => {
	if (item.includes('disputed')) {
		return 'disputed';
	} else if (item.includes('not_ready')) {
		return 'not_ready';
	} else if (item.includes('delivered')) {
		return 'delivered';
	} else return 'ready';
};

export const getItemStatus = (item) => {
	let status = '';
	if (item.length > 0) {
		status = checkStatus(item);
	} else status = item[0];

	switch (status) {
		case 'disputed':
			return <FormattedMessage id={'rod.repertoire.status.disputed'} />;
		case 'not_ready':
			return <FormattedMessage id={'rod.repertoire.status.not_ready'} />;
		case 'delivered':
			return <FormattedMessage id={'rod.repertoire.status.delivered'} />;
		case 'ready':
			return <FormattedMessage id={'rod.repertoire.status.ready'} />;
		default:
			return;
	}
};
