// Core
import { useContext, useEffect, useState } from 'react';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { FormattedHTMLMessage } from 'react-intl';

// Utils
import { accounts } from 'services';

// UI
import { HeaderButton } from 'components/Buttons';
import { Loading } from 'components';
import ReleasesTakeDown from './ReleasesTakeDown/ReleasesTakeDown';
import OutletsTakeDown from './OutletsTakeDown/OutletsTakeDown';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';

// Styles
import s from './RepertoireReleaseTakenDown.module.css';
import { UIContext } from 'contexts/UIContext';

const RepertoireReleaseTakenDown = (props) => {
	const accountId = localStorage.getItem('accountId');
	const { id: releaseId } = useParams();
	const match = useRouteMatch();
	const history = useHistory();

	const [loading, setLoading] = useState(false);
	const [selectedTab, setSelectedTab] = useState('releases');
	const [releasesRadio, setReleasesRadio] = useState('current');
	const [releases, setReleases] = useState([]);
	const [checkedReleases, setCheckedReleases] = useState([]);

	const { showBackNavi, hideBackNavi, showTitle, hideTitle } = useContext(
		UIContext
	);

	useEffect(() => {
		showTitle('rod.release.release_fallback_enable');
		showBackNavi(
			`/repertoire/releases/${releaseId}/info`,
			null,
			'rod.action.back_to_release'
		);

		return function cleanup() {
			hideBackNavi();
			hideTitle();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (accountId && releaseId) {
			setLoading(true);
			accounts
				.getReleaseTakedownedReleases(accountId, releaseId)
				.then((res) => {
					const filteredReleases = res.data?.data.filter(
						(release) =>
							release.status !== 'takendown' &&
							release.status !== 'takedown_queued' &&
							release.status !== 'shipping_queued'
					);
					setReleases(filteredReleases);
				})
				.catch((error) => {
					console.error('Error', error);
				})
				.finally(() => setLoading(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (match.path.includes('/takendown/releases')) {
			setSelectedTab('releases');
		} else if (match.path.includes('/takendown/outlets')) {
			setSelectedTab('outlets');
		} else {
			setSelectedTab('releases');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [match.path]);

	const getLink = (name) => {
		switch (name) {
			case 'releases':
				setSelectedTab('releases');
				history.push(`/repertoire/releases/${releaseId}/takendown/releases`);
				break;
			case 'outlets':
				setSelectedTab('outlets');
				history.push(`/repertoire/releases/${releaseId}/takendown/outlets`);
				break;
			default:
				setSelectedTab('releases');
				history.push(`/repertoire/releases/${releaseId}/takendown/releases`);
				break;
		}
	};

	return (
		<>
			{loading ? (
				<Loading non_margin={true} />
			) : (
				<>
					<TabsUnstyled value={selectedTab}>
						<TabsListUnstyled className={s.header}>
							<HeaderButton value={'releases'} disabled>
								<FormattedHTMLMessage
									id={'rod.release.release.takendown.releases'}
								/>
							</HeaderButton>
							<HeaderButton value={'outlets'} disabled>
								<FormattedHTMLMessage
									id={'rod.release.release.takendown.outlets'}
								/>
							</HeaderButton>
						</TabsListUnstyled>
						<div className={s.main}>
							<TabPanelUnstyled value={'releases'}>
								<ReleasesTakeDown
									releases={releases}
									releasesRadio={releasesRadio}
									setReleasesRadio={setReleasesRadio}
									checkedReleases={checkedReleases}
									setCheckedReleases={setCheckedReleases}
									getLink={getLink}
								/>
							</TabPanelUnstyled>
							<TabPanelUnstyled value={'outlets'}>
								<OutletsTakeDown
									releases={releases}
									releasesRadio={releasesRadio}
									checkedReleases={checkedReleases}
								/>
							</TabPanelUnstyled>
						</div>
					</TabsUnstyled>
				</>
			)}
		</>
	);
};

export default RepertoireReleaseTakenDown;
