// Core
import React, { useContext, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import lscache from 'lscache';

// Utils
import { convertDataSale, showGenres, getOutletTitleById } from 'utils';
import { setCache } from 'services/cacheHelper/setCache';
import { accounts } from 'services';

// UI
import { Loading } from 'components';
import { FullScreenModalConfirm } from 'containers/Modal';
import Info from './Info/Info';
import Phonograms from './Phonograms/Phonograms';

// Context
import { RootContext } from 'contexts/RootContext';
import { LangContext } from 'contexts/LangContext';

// Styles
import s from './RepertoireReleaseInfo.module.css';

const RepertoireReleaseInfo = ({ getInfo, tracks }) => {
	const [loading, setLoading] = useState(false);
	const [info, setInfo] = useState({});
	const [cover, setCover] = useState('');
	const [isCoverLoaded, setIsCoverLoaded] = useState(false);
	const [allGenres, setAllGenres] = useState([]);
	const [isReqLive] = useState(false);
	const [isInfoModalActive, setIsInfoModalActive] = useState(false);
	const [infoModalActiveData, setInfoModalActiveData] = useState({});
	const [outlets, setOutlets] = useState();

	const rootContext = useContext(RootContext);
	const langContext = useContext(LangContext);

	const match = useRouteMatch();
	const { territoriesWithCommond } = rootContext;

	const lang = langContext.lang;
	const releaseId = match.params.id;
	const accountId = localStorage.getItem('accountId');

	useEffect(() => {
		setLoading(true);

		const genresWithoutTypesCache = lscache.get('genresWithoutTypesCache');
		if (genresWithoutTypesCache && !isReqLive) {
			setAllGenres(genresWithoutTypesCache);
		} else {
			accounts
				.getGenresWithoutType()
				.then((res) => {
					setCache(
						'genresWithoutTypes',
						'genresWithoutTypesCache',
						res.data.data
					);
					setAllGenres(res.data.data);
				})
				.catch((error) => {
					console.error('Error', error);
				});
		}

		accounts
			.getOutlets(lang)
			.then((res) => {
				res = res.data.data;
				setOutlets(res);
			})
			.catch((error) => {
				console.error('Error', error);
			});

		accounts
			.getReleaseCoverForBrowsing(accountId, releaseId)
			.then((res) => {
				if (res.data.data.path_xm) {
					setCover(res.data.data.path_xm);
				}
			})
			.catch((error) => {
				console.error('Error', error);
			})
			.finally(() => {
				setLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (outlets && outlets.length > 0) {
			accounts
				.getReleaseInfoForBrowsing(accountId, releaseId)
				.then((res) => {
					res = res.data.data;
					getInfo({ title: res.title, ean: res.ean });

					if (
						(Array.isArray(res.queue_statuses) &&
							res.queue_statuses.length > 0) ||
						(typeof res.queue_statuses === 'object' &&
							Object.keys(res.queue_statuses).length > 0)
					) {
						const showModalsArray = getInfoModals(res);

						if (showModalsArray.length > 0) {
							showInfoModals(showModalsArray);
						}
					}

					if (res.sale_start_dates.length) {
						res.sale_start_dates.sort(
							(a, b) => convertDataSale(a.date) - convertDataSale(b.date)
						);
						res.sale_start_dates.forEach((item) => {
							item.date = item.date.replace(
								/(\d{4})-(\d{2})-(\d{2})/,
								'$3.$2.$1'
							);
							item.territories = territoriesWithCommond
								.filter((country) => country.id === item.territories[0])
								.flat();
						});
					}

					setInfo(res);
				})
				.catch((error) => {
					console.info('Error', error);
				});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [outlets]);

	const getInfoModals = ({ queue_statuses, statuses }) => {
		const status = statuses.some(
			(s) => s === 'shipment' || s === 'shipping' || s === 'shipping_queue'
		)
			? 'shipment'
			: 'withdrawal';
		let queueStatuses = [];

		for (let stat in queue_statuses) {
			queueStatuses.push({
				id: stat,
				...queue_statuses[stat],
			});
		}
		let result = [];

		const metadataStatuses = queueStatuses.filter(
			(item) => item.status === 'skipped' && item.reason === 'metadata'
		);

		if (metadataStatuses.length) {
			result.push({
				title: `rod.modal.release.${status}.rejected_title`,
				text: `rod.modal.release.${status}.rejected_text`,
			});
		}

		if (!queueStatuses.find((item) => item.reason !== 'metadata')) {
			return [
				{
					title: `rod.modal.release.${status}.rejected_title`,
					text: `rod.modal.release.${status}.rejected_text`,
				},
			];
		}

		const group_outlets = queueStatuses.filter(
			(item) =>
				item.status === 'skipped' &&
				(item.reason === 'no_outlets' ||
					item.reason === 'no_conditions' ||
					item.reason === 'no_channel_conditions')
		);

		if (group_outlets.length > 0) {
			result.push({
				title: `rod.modal.release.${status}.error_title`,
				text: `rod.modal.release.${status}.error_text`,
				outlets: group_outlets.map((item) =>
					getOutletTitleById(item.id, outlets)
				).filter((item) => item !== ''),
			});
		}

		const delivery_status_not_supportable = queueStatuses.filter(
			(item) =>
				item.status === 'skipped' &&
				item.reason === 'delivery_status_not_supportable'
		);

		if (delivery_status_not_supportable.length) {
			result.push({
				title: `rod.modal.release.${status}.outlets_failed_title`,
				text: `rod.modal.release.${status}.outlets_failed_text`,
				outlets: delivery_status_not_supportable.map((item) =>
					getOutletTitleById(item.id, outlets)
				).filter((item) => item !== ''),
			});
		}

		return result;
	};

	const closeModal = (data, counter) => {
		setIsInfoModalActive(false);

		if (counter < data.length) {
			showInfoModals(data, counter);
		} else {
			let shownReleases = localStorage.getItem('queue_statuses');
			shownReleases = shownReleases ? JSON.parse(shownReleases) : [];
			const newValue = [...(shownReleases ? shownReleases : []), releaseId];
			localStorage.setItem('queue_statuses', JSON.stringify(newValue));

			setInfoModalActiveData({});
		}
	};

	const showInfoModals = (infoModalsArray, counter = 0) => {
		let i = counter;

		setInfoModalActiveData({
			title: <FormattedMessage id={infoModalsArray[i].title} />,
			text: infoModalsArray[i].outlets ? (
				<FormattedMessage
					id={infoModalsArray[i].text}
					values={{
						outlets: infoModalsArray[i].outlets.join(', '),
					}}
				/>
			) : (
				<FormattedMessage id={infoModalsArray[i].text} />
			),
			onClose: () => {
				closeModal(infoModalsArray, ++i);
			},
		});
	};

	const handleImageLoad = () => {
		setIsCoverLoaded(true);
	};

	const handleImageErrored = (err) => {
		if (err) setCover('');
		setIsCoverLoaded(false);
	};

	useEffect(() => {
		if (Object.keys(infoModalActiveData).length > 0) {
			let shownReleases = localStorage.getItem('queue_statuses');
			shownReleases = shownReleases ? JSON.parse(shownReleases) : [];
			if (
				shownReleases &&
				shownReleases.length &&
				shownReleases.find((item) => item === releaseId)
			) {
				setIsInfoModalActive(false);
				return;
			} else {
				setIsInfoModalActive(true);
			}
		}
	}, [infoModalActiveData, releaseId]);

	useEffect(() => {
		showGenres(allGenres, info.genres, lang);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang]);

	return (
		<>
			{loading || !Object.keys(info).length ? (
				<Loading className={s.loader} />
			) : (
				<>
					<Info
						info={info}
						allGenres={allGenres}
						cover={cover}
						isCoverLoaded={isCoverLoaded}
						handleImageLoad={handleImageLoad}
						handleImageErrored={handleImageErrored}
					/>
					<Phonograms tracks={tracks} />
					{isInfoModalActive && (
						<FullScreenModalConfirm
							title={infoModalActiveData.title}
							text={infoModalActiveData.text}
							confirmBtnTxt={<FormattedMessage id={'rod.modal.understood'} />}
							onClose={infoModalActiveData.onClose}
						/>
					)}
				</>
			)}
		</>
	);
};

export default RepertoireReleaseInfo;
