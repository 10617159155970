// Core
import { FormattedMessage } from 'react-intl';

// Styles
import styles from './BottomNavi.module.css';

const BottomNavi = ({ showPrevBtn, disabled, back, next, nextText }) => {
	return (
		<div
			className={styles.container}
			style={
				showPrevBtn
					? { justifyContent: 'space-between' }
					: { justifyContent: 'flex-end' }
			}
		>
			{showPrevBtn && (
				<button className={styles.prev_btn} onClick={back}>
					<FormattedMessage id={'rod.release.create.step.handler_back'} />
				</button>
			)}
			<button
				disabled={disabled}
				className={
					!disabled ? styles.next_btn : `${styles.next_btn} ${styles.disabled}`
				}
				onClick={next}
			>
				<FormattedMessage
					id={!nextText ? 'rod.release.create.step.handler_next' : nextText}
				/>
			</button>
		</div>
	);
};

export default BottomNavi;
