// Core
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';

// UI
import { Button } from 'components/Buttons/Button';
import { Label } from 'components/Labels';
import { withUI } from 'hocs';
import { withAuth } from 'hocs';
import { compose } from 'recompose';

// Styles
import styles from './AccessDenied.module.css';

class AccessDenied extends Component {
	componentDidMount() {
		const {
			UIContext: { hideNavAndButtons },
		} = this.props;

		this.props.UIContext.showBackNavi();
		hideNavAndButtons();
	}

	componentWillUnmount() {
		const {
			UIContext: { showNavAndButtons },
		} = this.props;

		this.props.UIContext.hideBackNavi();
		showNavAndButtons();
	}

	handleBack = () => {
		this.props.history.push('/');
	};

	render() {
		return (
			<div className={styles.page}>
				<div className={styles.form}>
					<div className={styles.title}>
						<Label font="--gilroy-Medium-40" black>
							<FormattedMessage id={'rod.error_page.denied.header'} />
						</Label>
						<Label font="--gilroy-Medium-24" black>
							<FormattedMessage id={'rod.error_page.denied.body'} />
						</Label>
					</div>
					<div className={styles.controls}>
						<Button
							className={styles.button}
							text={<FormattedMessage id={'rod.action.to_index_page'} />}
							type={'submit'}
							variant={'primary'}
							onClick={this.handleBack}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default compose(withUI, withAuth, withRouter)(AccessDenied);
