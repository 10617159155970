import { withAuth, withRoot, withUI } from 'hocs';
import { compose } from 'recompose';
import styles from './Issue.module.css';
import ReactJson from 'react-json-view';
// import { style } from '@mui/system';
import { useEffect, useState } from 'react';

function Issue(props) {
	const [checkedArray, setCheckedArray] = useState([]);
	const {
		// UIContext: { adminV2StatisticIssue },
		authContext: { adminV2StatisticIssue },
		UIContext: {
			showBackNavi,
			changeBackTargetUrl,
			hideBackNavi /* , isReleaseNotReady  */,
		},
	} = props;

	useEffect(() => {
		changeBackTargetUrl('/admin/v2/statistics_service');
		showBackNavi(
			'/admin/v2/statistics_service',
			null,
			'rod.action.back_to_list'
		);
		const result = adminV2StatisticIssue.data.map((item) => {
			try {
				JSON.stringify(item);
				return item;
			} catch (err) {
				return item.toString();
			}
		});
		setCheckedArray(result);
		return function cleanup() {
			hideBackNavi();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// const res = { BOOM: adminV2StatisticIssue };
	return (
		<>
			{checkedArray.length > 0 && (
				<div className={styles.wrapper}>
					<ReactJson
						// src={adminV2StatisticIssue.data}
						src={checkedArray}
						groupArraysAfterLength={20}
						name={`${adminV2StatisticIssue.outlet} (${adminV2StatisticIssue.date})`}
					/>
				</div>
			)}
		</>
	);
}

export default compose(withRoot, withAuth, withUI)(Issue);
