// Core
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

// Context
import { AuthContext } from 'contexts/AuthContext';

// Services
import { compose } from 'recompose';
import { withUI } from 'hocs';

// UI
import { Button } from 'components/Buttons/Button';
import { Label } from 'components/Labels';
import { Loading } from 'components';

// Icons
import notfoundImg from 'images/404.svg';

// Styles
import styles from './NotFound.module.css';

const NotFound = ({ UIContext }) => {
	const [loading, setLoading] = useState(true);

	const history = useHistory();
	const { getUserData } = useContext(AuthContext);

	useEffect(() => {
		const fetchData = async () => {
			try {
				await getUserData();
				setLoading(false);
				UIContext.showBackNavi();
			} catch (error) {
				console.error('Error fetching user data:', error);
				setLoading(false);
			}
		};

		fetchData();

		return () => {
			UIContext.hideBackNavi();
		};
	}, []);

	const handleBack = () => {
		history.push('/');
	};

	return (
		<>
			{loading ? (
				<Loading />
			) : (
				<div className={styles.Page}>
					<div className={styles.Form}>
						<div className={styles.Title}>
							<Label font="--gilroy-Medium-40" black>
								<FormattedMessage id={'rod.error_page.not_found.header'} />
							</Label>
							<Label font="--gilroy-Medium-24" black>
								<FormattedMessage id={'rod.error_page.not_found.body'} />
							</Label>
						</div>
						<div className={styles.Controls}>
							<Button
								className={styles.Button}
								text={<FormattedMessage id={'rod.action.to_index_page'} />}
								type={'submit'}
								variant={'primary'}
								onClick={handleBack}
							/>
						</div>
					</div>
					<img src={notfoundImg} className={styles.Image} alt="" />
				</div>
			)}
		</>
	);
};

export default compose(withUI)(NotFound);
