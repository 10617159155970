// Core
import { FormattedMessage } from 'react-intl';
import { useContext } from 'react';

// Context
import { LangContext } from 'contexts/LangContext';

// Utils
import { getReleaseTitle } from '../utils/getReleaseTitle';
import { getOutletTitle } from '../utils/getOutletTitle';

// UI
import { Checkbox } from 'components/Checkboxes/Checkbox';

// Styles
import styles from '../OutletsTakeDown.module.css';

const OutletsBlock = ({
	relatedOutlets,
	handleCheckedRelatedOutlet,
	outletsRadio,
	releases,
	releaseInfo,
	allOutlets,
}) => {
	const { lang } = useContext(LangContext);

	return (
		<div className={styles.outletsBlock}>
			{relatedOutlets.length > 0 &&
				relatedOutlets.map((item, index) => (
					<div key={index}>
						<div className={styles.releaseTitle}>
							{getReleaseTitle(item, releases, lang, releaseInfo)}
						</div>
						<div className={styles.table}>
							{item[1] && item[1].length > 0 ? (
								item[1].map((outlet) => (
									<Checkbox
										key={outlet.id}
										className={styles.outletCheckbox}
										text={getOutletTitle(outlet.id, allOutlets) || outlet.title}
										onChange={() =>
											handleCheckedRelatedOutlet(item[0], outlet.id)
										}
										checked={outlet.checked}
										disabled={
											outlet.status === 'takedown' || outletsRadio === 'all'
										}
										isLocked={outlet.status === 'takedown'}
									/>
								))
							) : (
								<div className={styles.noData}>
									<FormattedMessage
										id={'rod.release.release.takendown.outlets.noData'}
									/>
								</div>
							)}
						</div>
					</div>
				))}
		</div>
	);
};

export default OutletsBlock;
