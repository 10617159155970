// Core
import React, { useEffect, useState } from 'react';
import {
	XYPlot,
	XAxis,
	YAxis,
	HorizontalGridLines,
	LineSeries,
	AreaSeries,
	Borders,
	Crosshair,
	MarkSeries,
} from 'react-vis';
import { withUI, withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';

// Utils
import {
	assignColorIndexToOutlet,
	colorRange,
	strokeColorRange,
} from '../utils/assignColorIndexToOutlet';

// UI
import { Loading } from 'components';
import MainContentHeader from './MainContentHeader/MainContentHeader';
import CrosshairData from './CrosshairData/CrosshairData';
import CrosshairDataWrapper from './CrosshairDataWrapper/CrosshairDataWrapper';
import Helper from './Helper/Helper';

// Styles
import styles from './StatisticByDatesChart.module.css';

const StatisticByDatesChart = ({
	data,
	selectedOutlets,
	xAxisSize,
	amountYAxisTitle,
}) => {
	const [loading, setLoading] = useState(false);
	const [isCumulativeBttActive, setIsCumulativeBttActive] = useState(true);
	const [helper, setHelper] = useState(false);
	const [comparisonChartData, setComparisonChartData] = useState(data ?? null);
	const [width, setWidth] = useState(0);
	const [legend, setLegend] = useState([]);
	const [total, setTotal] = useState(0);
	const [crosshairData, setCrosshairData] = useState([]);

	const lang = localStorage.getItem('lang');
	const colorDomain = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

	const makeLegend = () => {
		let result = Object.values(data).map((element) => {
			const outlet = selectedOutlets.find((outlet) => {
				return outlet.code === element.code;
			});

			if (outlet) {
				return {
					title: outlet[`title_${lang}`],
					code: outlet.code,
					color: strokeColorRange[assignColorIndexToOutlet(outlet.code)],
				};
			}
		});

		setLegend(result.reverse());
	};

	const updateWindowDimensions = () => {
		setWidth(window.innerWidth);
	};

	const countTotal = () => {
		let result = 0;

		if (crosshairData) {
			crosshairData?.forEach((item) => {
				result = Math.round((result + item.y) * 1000000) / 1000000;
			});
			setTotal(result);
		}
	};

	useEffect(() => {
		countTotal();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCumulativeBttActive]);

	useEffect(() => {
		countTotal();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [crosshairData]);

	useEffect(() => {
		setComparisonChartData(data);

		if (data && Object.values(data).length === selectedOutlets.length) {
			makeLegend();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	useEffect(() => {
		if (data && Object.values(data).length === selectedOutlets.length) {
			makeLegend();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang]);

	useEffect(() => {
		setLoading(false);
		updateWindowDimensions();
		window.addEventListener('resize', updateWindowDimensions);

		return () => {
			window.removeEventListener('resize', updateWindowDimensions);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const chartModeSwitch = (e) => {
		e.preventDefault();
		setIsCumulativeBttActive(!isCumulativeBttActive);
	};

	const onNearestX = (value, { index }) => {
		let resultedCrosshairData = Object.values(comparisonChartData).map(
			(item) => {
				const itemData = item.data;
				return { ...itemData[index], code: item.code, title: item.title };
			}
		);
		setCrosshairData(resultedCrosshairData.sort((a, b) => b.y - a.y));
	};

	return (
		<>
			{helper && <Helper setHelper={setHelper} />}
			{loading ? (
				<Loading className={styles.loader} />
			) : (
				comparisonChartData && (
					<div className={styles.statistic__mainContent}>
						<MainContentHeader
							amountYAxisTitle={amountYAxisTitle}
							isCumulativeBttActive={isCumulativeBttActive}
							chartModeSwitch={chartModeSwitch}
						/>
						{xAxisSize &&
							comparisonChartData &&
							Object.values(comparisonChartData)[0]?.data && (
								<XYPlot
									xType="time-utc"
									width={width > 1024 ? width - 246 : width}
									margin={{
										left: 80,
										right: 40,
										top: 10,
										bottom: 40,
									}}
									height={536}
									stackBy={isCumulativeBttActive ? 'y' : ''}
									onMouseLeave={() => setCrosshairData([])}
									style={{ background: 'white' }}
									colorType="category"
									colorDomain={colorDomain}
									colorRange={colorRange}
								>
									<MarkSeries
										data={[
											{
												x: Object.values(comparisonChartData)[0].data[0].x,
												y: 0,
											},
										]}
										style={{ display: 'none' }}
									/>

									{!isCumulativeBttActive
										? Object.values(
												comparisonChartData
										  ).map((dataByOutlet, index) => (
												<LineSeries
													key={index}
													color={assignColorIndexToOutlet(dataByOutlet.code)}
													opacity={1}
													stroke={
														strokeColorRange[
															assignColorIndexToOutlet(dataByOutlet.code)
														]
													}
													data={dataByOutlet.data}
													onNearestX={onNearestX}
												/>
										  ))
										: Object.values(comparisonChartData).map(
												(dataByOutlet, index) => {
													return (
														<AreaSeries
															key={index}
															color={assignColorIndexToOutlet(
																dataByOutlet.code
															)}
															opacity={1}
															data={dataByOutlet.data}
															className={styles.areaSeries}
														/>
													);
												}
										  )}
									{isCumulativeBttActive &&
										Object.values(comparisonChartData).map(
											(dataByOutlet, index) => {
												return (
													<LineSeries
														style={{ zIndex: -index }}
														key={index}
														color={assignColorIndexToOutlet(dataByOutlet.code)}
														opacity={1}
														stroke={
															strokeColorRange[
																assignColorIndexToOutlet(dataByOutlet.code)
															]
														}
														data={dataByOutlet.data}
														onNearestX={onNearestX}
													/>
												);
											}
										)}
									<HorizontalGridLines />
									<Borders
										style={{
											bottom: { fill: '#fff' },
											left: { fill: '#fff' },
											right: { fill: '#fff' },
											top: { fill: '#fff' },
										}}
									/>
									<Crosshair values={crosshairData}>
										<CrosshairDataWrapper
											crosshairData={crosshairData}
											legend={legend}
											isCumulativeBttActive={isCumulativeBttActive}
											total={total}
										/>
									</Crosshair>
									<XAxis
										tickFormat={(v) =>
											v.toLocaleDateString('en-US', {
												day: 'numeric',
												month: 'short',
											})
										}
										tickTotal={xAxisSize < 10 ? xAxisSize : 10}
									/>
									<YAxis
										tickFormat={(v) =>
											v >= 0 ? (v.toString().includes('.') ? `` : `${v}`) : ``
										}
									/>
								</XYPlot>
							)}

						<CrosshairData legend={legend} />
					</div>
				)
			)}
		</>
	);
};

export default compose(withUI, withAuth, withRoot)(StatisticByDatesChart);
