// Core
import { FormattedMessage } from 'react-intl';

// Styles
import styles from './DataLoadingError.module.css';

const DataLoadingError = () => {
	return (
		<div className={styles.dataError}>
			<FormattedMessage id={'rod.error.load.data.error'} />
		</div>
	);
};

export default DataLoadingError;
