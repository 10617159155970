// Core
import { FormattedMessage } from 'react-intl';

export const generateDocumentsSteps = (businessType) => {
	return [
		{
			id: 1,
			status: 'complete',
			title: (
				<FormattedMessage
					id={`rod.account.create.step_2_1_${businessType}.title`}
				/>
			),
		},
		{
			id: 2,
			status: 'complete',
			title: <FormattedMessage id={'rod.account.create.step_2_2.title'} />,
		},
		{
			id: 3,
			status: 'active',
			title: <FormattedMessage id={'rod.account.create.step_2_3.title'} />,
		},
		{
			id: 4,
			status: 'default',
			title: <FormattedMessage id={'rod.account.create.step_2_4.title'} />,
		},
		{
			id: 5,
			status: 'default',
			title: <FormattedMessage id={'rod.account.create.step_2_5.title'} />,
		},
	];
};
