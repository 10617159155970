export const getHorizontalBarColor = (highlightedIndex, idx) => {
	if (process.env.REACT_APP_CLIENT === 'hitmaker') {
		return highlightedIndex?.index === idx
			? '#e6b122'
			: 'var(--color-light-green)';
	} else {
		return highlightedIndex?.index === idx
			? 'var(--color-green)'
			: 'var(--color-light-green)';
	}
};
