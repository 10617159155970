// Core
import { FormattedHTMLMessage } from 'react-intl';
import { Col, Row } from 'react-grid-system';

// Styles
import styles from './TableHeader.module.css';

const TableHeader = () => {
	return (
		<Row className={styles.rowHeader}>
			<Col width={'2%'}></Col>
			<Col width={'23%'}>
				<FormattedHTMLMessage id={'rod.admin.notifications.title'} />
			</Col>
			<Col width={'23%'}>
				<FormattedHTMLMessage id={'rod.admin.notifications.text'} />
			</Col>
			<Col width={'12%'}>
				<FormattedHTMLMessage id={'rod.admin.articles.rubric'} />
			</Col>
			<Col width={'20%'}>
				<FormattedHTMLMessage id={'rod.admin.articles.date'} />
			</Col>
			<Col width={'20%'}>
				<FormattedHTMLMessage id={'rod.admin.articles.last_corrected_date'} />
			</Col>
		</Row>
	);
};

export default TableHeader;
