// Core
import { FormattedMessage } from 'react-intl';

// Styles
import styles from './ErrorsBlock.module.css';

const ErrorsBlock = ({ countFilesError, maxFilesCount, errors }) => {
	const hasErrors =
		errors &&
		Object.values(errors).length > 0 &&
		Object.values(errors)[0].length > 0;

	const errorConditions = [
		{
			rule: 'max_size',
			message: 'rod.multiple.upload.file.max_size',
		},
		{
			rule: 'file_type',
			message: 'rod.multiple.upload.file.file_type',
		},
		{
			rule: 'required',
			message: 'rod.multiple.upload.file.required',
		},
	];

	return (
		<div className={styles.errors}>
			{countFilesError && (
				<span className={styles.helper}>
					<FormattedMessage
						id="rod.multiple.upload.max_count"
						values={{
							value: maxFilesCount,
						}}
					/>
				</span>
			)}

			{hasErrors &&
				errorConditions.map(
					(condition) =>
						Object.values(errors)[0].some(
							(el) => el.rule === condition.rule
						) && (
							<span key={condition.rule} className={styles.helper}>
								<FormattedMessage id={condition.message} />
							</span>
						)
				)}

			{hasErrors &&
				!errorConditions.some((condition) =>
					Object.values(errors)[0].some((el) => el.rule === condition.rule)
				) && (
					<span className={styles.helper}>
						<FormattedMessage id="rod.field.upload.error.title" />
					</span>
				)}
		</div>
	);
};

export default ErrorsBlock;
