/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

import styles from 'pages/account/Accounts/components/Create.module.css';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

class Create extends Component {
	render() {
		return (
			<div className={styles.Create}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="120"
					height="120"
					viewBox="0 0 120 120"
				>
					<path
						className={styles.svg_branding}
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M61.5789 0H58.4211V58.421H0V61.5789L58.4211 61.5789V120H61.5789V61.5789L120 61.579V58.4211H61.5789V0Z"
					/>
				</svg>
				<span>
					<FormattedMessage id={'rod.action.create'} />
				</span>
			</div>
		);
	}
}

export default Create;
