import React from 'react';
import {
	TextFieldInput,
	TextFieldInputAdminRubrics,
	AutocompleteInput,
} from 'material-design/MuiStyled/MuiStyled';
import { makeStyles, createStyles } from '@mui/styles';
import { Popper } from '@mui/material';
import isItemSelected from 'logic/multiselectHighlight.js';

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			'& .MuiAutocomplete-listbox': {
				margin: 0,
				padding: 0,
				'& li': {
					backgroundColor: '#FFF',
					font: 'var(--gilroy-Medium-16-150)',
					padding: '12px 8px',
					'&:hover': {
						backgroundColor: 'var(--color-light-grey) !important',
					},
				},
			},
			'& 	.MuiAutocomplete-popupIndicator': {
				backgroundColor: 'var(--color-green)',
				color: 'white',
			},
		},
	})
);

const CustomPopper = function (props) {
	const classes = useStyles();
	return <Popper {...props} className={classes.root} placement="bottom" />;
};
const MuiSelect = ({
	onChange,
	label,
	items,
	value,
	required,
	placeholder,
}) => {
	// const lang = localStorage.getItem('lang');
	items = items.sort((a, b) => (a.title > b.title ? 1 : -1));
	if (value) {
		for (let j = 0; j < items.length; j++) {
			if (value === items[j].id) {
				value = {
					id: items[j].id,
					title: items[j].title,
					language: items[j].language,
				};
			}
		}
	}

	const filterOptions = (_, state) => {
		const newOptions = [];
		if (state.inputValue) {
			items.forEach((item) => {
				const word = item.title;
				if (
					word.toLowerCase().includes(state.inputValue.toLowerCase()) &&
					word.toLowerCase().indexOf(state.inputValue.toLowerCase()) === 0
				)
					newOptions.push(item);
			});
			return newOptions;
		} else return items;
	};

	return (
		<AutocompleteInput
			id="disable-clearable"
			disableClearable
			onChange={(_, obj) => onChange(obj)}
			// options={items}
			options={{
				filter: items.filter,
				suggestionsContainerProps: {
					style: {
						zIndex: 99999,
					},
				},
			}}
			value={value}
			getOptionLabel={(option) => {
				return option.title || '';
			}}
			isOptionEqualToValue={(option, value) => option.title === value.title}
			renderInput={(params) => (
				<TextFieldInput
					{...params}
					label={label}
					variant="standard"
					required={required}
					placeholder={placeholder}
				/>
			)}
			renderOption={(option, item) => (
				<li
					key={item}
					{...option}
					// style={{ paddingLeft: '8px' }}
					style={{
						backgroundColor: isItemSelected(item, value)
							? 'var(--color-green)'
							: 'var(--color-white)',
						color: isItemSelected(item, value) ? 'var(--color-white)' : 'unset',
						zIndex: '10000000000 !important',
					}}
				>
					{item.title}
				</li>
			)}
			PopperComponent={CustomPopper}
			filterOptions={filterOptions}
		/>
	);
};

export default MuiSelect;
