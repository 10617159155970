/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

import React, { Component } from 'react';
import styles from './Success.module.css';

class Success extends Component {

	render() {
		return (
			<div className={styles.Container}>
				{this.props.children}
				{/*<Link to={'/'} black><FormattedMessage id={'rod.action.to_index_page'}/></Link>*/}
			</div>
		);
	}
}

export default Success;
