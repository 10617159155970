export const correctedEventTitle = (title) => {
	const lowerCaseTitle = title.toLowerCase();
	const moderationKeywords = [
		'повторная модерация',
		'модерация отгруженных',
		'модерация отклоненных',
	];
	const remoderationKeywords = [
		'release remoderation',
		'delivered release remoderation',
		'rejected release remoderation',
	];

	if (moderationKeywords.some((keyword) => lowerCaseTitle.includes(keyword))) {
		return 'Повторная модерация';
	} else if (
		remoderationKeywords.some((keyword) => lowerCaseTitle.includes(keyword))
	) {
		return 'Release remoderation';
	} else {
		return title;
	}
};
