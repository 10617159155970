// Core
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

// Context
import { UIContext } from 'contexts/UIContext';
import { RootContext } from 'contexts/RootContext';

// Types
import modalTypes from 'constants/modalTypes';

// Styles
import styles from './Preview.module.css';

const Preview = ({ articleLang, enData, ruData, setEnData, setRuData }) => {
	const { showModal } = useContext(UIContext);
	const { deleteAdminArticlePicFile } = useContext(RootContext);

	const [hovered, setHovered] = useState(false);
	const [loading, setLoading] = useState(true);

	const deleteImgHandler = () => {
		if (articleLang === 'en') {
			if (!enData.pic_identifier) {
				setEnData((prev) => ({
					...prev,
					pic: null,
					pic_preview: null,
				}));
			} else {
				deleteAdminArticlePicFile(enData.pic_identifier)
					.then((res) => {
						setEnData((prev) => ({
							...prev,
							pic: null,
							pic_preview: null,
						}));
					})
					.catch((err) => {
						console.error('Error:', err);
					});
			}
		} else {
			if (!ruData.pic_identifier) {
				setRuData((prev) => ({
					...prev,
					pic: null,
					pic_preview: null,
				}));
			} else {
				deleteAdminArticlePicFile(ruData.pic_identifier)
					.then((res) => {
						setRuData((prev) => ({
							...prev,
							pic: null,
							pic_preview: null,
						}));
					})
					.catch((err) => {
						console.error('Error:', err);
					});
			}
		}
	};

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 1500);
	});

	return (
		<div
			className={styles.preview}
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
		>
			{loading && <div className={styles.preLoader} />}
			<img
				style={{ display: loading ? 'none' : 'block' }}
				src={articleLang === 'en' ? enData.pic_preview : ruData.pic_preview}
				alt=""
			/>
			{!loading && hovered && (
				<button
					className={styles.delete}
					onClick={showModal(
						{
							title: (
								<FormattedMessage id="rod.article.img.modal.delete.title" />
							),
							onAction: () => {
								deleteImgHandler();
							},
							confirmBtnTxt: <FormattedMessage id={'rod.modal.yes_delete'} />,
							declineBtnTxt: <FormattedMessage id={'rod.modal.cancel'} />,
						},
						modalTypes.FULL_MODAL
					)}
				>
					<FormattedMessage id={'rod.article.cover.delete'} />
				</button>
			)}
		</div>
	);
};

export default Preview;
