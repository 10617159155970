// Utils
import { fm } from './fm';

export const typeLocalization = (type) => {
	switch (type) {
		case 'manual':
			return fm('rod.admin.status_history.manual_type');
		case 'automatic':
			return fm('rod.admin.status_history.auto_type');
		default:
			return type;
	}
};
