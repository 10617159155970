// Styles
import styles from './TopTracksSkeleton.module.css';

const TopTracksSkeleton = () => {
	return (
		<>
			<div className={`${styles.container} ${styles.fadeIn}`}></div>
		</>
	);
};

export default TopTracksSkeleton;
