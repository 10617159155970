// Core
import posed from 'react-pose';

export const Modal = posed.div({
	enter: {
		x: 0,
		opacity: 1,
		transition: {
			x: {
				ease: 'easeIn',
			},
			default: { duration: 300 },
		},
	},
	exit: {
		x: '100%',
		opacity: 0,
		transition: { duration: 250 },
	},
});
