// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';

// UI
import ContractsCurrency from './ContractsCurrency/ContractsCurrency';
import ContractsDuration from './ContractsDuration/ContractsDuration';
import { Label } from 'components/Labels/Label';

const DurationCurrency = ({
	state,
	changeField,
	isAdditional,
	contractPeriods,
	showModal,
	currencies,
}) => {
	return (
		<div
			style={{
				opacity: isAdditional ? '0.7' : '1',
				pointerEvents: isAdditional ? 'none' : 'all',
			}}
		>
			<Label black font="--gilroy-Medium-18">
				<FormattedMessage id={'rod.label.required_fields'} />
			</Label>

			<ContractsDuration
				state={state}
				changeField={changeField}
				contractPeriods={contractPeriods}
				showModal={showModal}
			/>

			<ContractsCurrency
				state={state}
				changeField={changeField}
				currencies={currencies}
			/>
		</div>
	);
};

export default DurationCurrency;
