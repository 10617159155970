// Core
import { useContext, useEffect, useState } from 'react';
import { withUI } from 'hocs';
import { compose } from 'recompose';

// UI
import { Loading } from 'components';
import { Pagination } from 'material-design/components';
import { RubricLang } from '../RubricLang';
import Search from './Search/Search';
import ActionButtons from './ActionButtons/ActionButtons';
import TableHeader from './Table/TableHeader/TableHeader';
import TableBody from './Table/TableBody/TableBody';
import NoData from './NoData/NoData';

// Context
import { RootContext } from 'contexts/RootContext';

// Styles
import styles from './ArticlesPage.module.css';

const ArticlesPage = (props) => {
	const { getAdminArticles, getAdminArticlesRubrics } = useContext(RootContext);
	const rubricLang = localStorage.getItem('rubric_lang');

	const [lang, setLang] = useState(rubricLang || 'ru');
	const [articles, setArticles] = useState([]);
	const [rubrics, setRubrics] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searchValue, setSearchValue] = useState('');
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [noData, setNoData] = useState(false);

	const getRubrics = () => {
		setLoading(true);
		getAdminArticlesRubrics(lang, 1)
			.then((res) => {
				setRubrics(res.data.sort((a, b) => a.sort - b.sort));
			})
			.catch((err) => {
				console.error('Error', err);
			})
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		setLoading(true);
		getRubrics();
		getAdminArticles(page, searchValue, lang)
			.then((res) => {
				if (res.data.length) {
					setTotal(res.total);

					const data = res.data.map((item) => {
						const timeCreated = item.created_at.match(/\d{1,2}:\d{2}/)[0];
						const timeUpdated = item.updated_at.match(/\d{1,2}:\d{2}/)[0];
						const dateCreated = item.created_at
							.slice(0, item.created_at.indexOf('T'))
							.replace(/(\d{4})-(\d{2})-(\d{2})/gm, '$3.$2.$1');
						item.created_at = timeCreated + ' ' + dateCreated;
						const dateUpdated = item.updated_at
							.slice(0, item.updated_at.indexOf('T'))
							.replace(/(\d{4})-(\d{2})-(\d{2})/gm, '$3.$2.$1');
						item.updated_at = timeUpdated + ' ' + dateUpdated;

						return item;
					});
					setNoData(false);
					setArticles(data);
				} else {
					setNoData(true);
				}
			})
			.catch((err) => console.error('Error:', err))
			.finally(() => {
				setLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, lang, searchValue]);

	const handleOnChange = (value) => {
		setSearchValue(value);
		if (!value) setPage(1);
	};

	return (
		<div className={styles.mainWrapper}>
			{rubrics && rubrics.length > 0 && (
				<div className={styles.sectionTitleWrapper}>
					<Search handleOnChange={handleOnChange} />
					<ActionButtons rubrics={rubrics} />
				</div>
			)}
			{loading ? (
				<div>
					<Loading className={styles.loader} />
				</div>
			) : !loading && noData ? (
				<NoData rubrics={rubrics} />
			) : (
				<>
					<RubricLang className={styles.lang} lang={lang} setLang={setLang} />
					<div className={styles.RepertoireSongInfo__mainInfoTable}>
						<TableHeader />
						<TableBody articles={articles} page={page} />
					</div>
					{total > 10 && !loading && (
						<Pagination
							page={page}
							total={total}
							paginate={(pageNumber) => {
								setPage(pageNumber);
							}}
							items_on_page={10}
						/>
					)}
				</>
			)}
		</div>
	);
};
export default compose(withUI)(ArticlesPage);
