// Core
import React, { useContext, useEffect, useState } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { UIContext } from 'contexts/UIContext';
import { LangContext } from 'contexts/LangContext';
import { RootContext } from 'contexts/RootContext';

// Utils
import { checkShowTab } from './Tabs/utils/checkShowTab';

// Services
import { accounts } from 'services';
import { compose } from 'recompose';
import { withUI, withAuth, withRoot } from 'hocs';
import { editableStatuses } from './editableStatuses';

// UI
import { Loading } from 'components';
import Header from './Header/Header';
import Tabs from './Tabs/Tabs';

// Styles
import styles from './RepertoireRelease.module.css';

const RepertoireRelease = (props) => {
	const history = useHistory();
	const { accountId } = useContext(AuthContext);
	const {
		showBackNavi,
		changeBackTargetUrl,
		setPrevRepertoireParams,
		hideBackNavi,
		isFeatureStatisticsReleaseTab,
	} = useContext(UIContext);
	const { lang } = useContext(LangContext);
	const { getReleaseShipments, getReleaseTakedowns } = useContext(RootContext);

	const [loading, setLoading] = useState(true);
	const [deliveryData, setDeliveryData] = useState([]);
	const [infoFromRelease, setInfoFromRelease] = useState({});
	const [isReleaseReady, setIsReleaseReady] = useState(false);
	const [defaultTab, setDefaultTab] = useState('');
	const [releaseTypeId, setReleaseTypeId] = useState('');
	const [shipmentsArr, setShipmentsArr] = useState([]);
	const [takenDownsArr, setTakenDownsArr] = useState([]);
	// eslint-disable-next-line no-unused-vars
	const [isShowDistributionBtns, setIsShowDistributionBtns] = useState(true);
	const [isShipmentsBtnEnable, setIsShipmentsBtnEnable] = useState(true);
	const [isShipmentsInProcess, setIsShipmentsInProcess] = useState(false);
	const [isTakenDownsBtnEnable, setIsTakenDownsBtnEnable] = useState(true);
	const [isTakenDownsInProcess, setIsTakenDownsInProcess] = useState(false);
	const [disabledBtnsReasonText, setDisabledBtnsReasonText] = useState('');
	const [allOutlets, setAllOutlets] = useState([]);
	const [isActionMenuVisible, setIsActionMenuVisible] = useState(false);
	const [isReleaseInQueue, setIsReleaseInQueue] = useState(false);
	const [moderationStatus, setModerationStatus] = useState('');
	const [tracks, setTracks] = useState([]);
	const [hasPriveleges, setHasPriveleges] = useState(false);
	const [isEnableToEdit, setIsEnableToEdit] = useState(false);
	const [isDraftStatus, setIsDraftStatus] = useState(false);

	const { id: releaseId } = useParams();
	const match = useRouteMatch();
	const hasPrivelegesTabs = [
		'notices',
		'moderation',
		'deliveries',
		'logs',
		'statistics',
	];
	const shouldShowModerationTab =
		isReleaseReady &&
		checkShowTab(moderationStatus, isReleaseInQueue) &&
		hasPriveleges;
	const shouldShowDeliveriesTab =
		deliveryData && deliveryData.length > 0 && hasPriveleges;
	const shouldShowLogsTab = isReleaseReady && hasPriveleges;
	const shouldShowStatisticsTab =
		shouldShowDeliveriesTab && isFeatureStatisticsReleaseTab;

	useEffect(() => {
		accounts
			.getReleaseInfoForBrowsing(accountId, releaseId)
			.then((res) => {
				res = res.data.data;
				getInfo({ title: res.title, ean: res.ean });

				if (res.hasOwnProperty('has_privileges')) {
					setHasPriveleges(res.has_privileges);
				}

				if (!res['has_privileges']) {
					if (
						hasPrivelegesTabs.some((substring) =>
							match.path.includes(substring)
						)
					) {
						history.push(`/repertoire/releases/${releaseId}/info`);
					}
				}

				if (res.statuses.includes('draft')) {
					setIsDraftStatus(true);
				}

				if (res.statuses.includes('not_ready')) {
					setIsShipmentsBtnEnable(false);
					setIsTakenDownsBtnEnable(false);
				}

				const isSomeOutletsWaitingQueue = Object.values(
					res.queue_statuses
				).find((item) => item.status === 'queued');

				if (isSomeOutletsWaitingQueue) {
					setIsShipmentsBtnEnable(false);
					setIsTakenDownsBtnEnable(false);
				}

				setReleaseTypeId(res.release_type_id);
				setIsReleaseReady(res?.statuses?.includes('not_ready') ? false : true);
				// доступность релиза для редактирования после отгрузки
				if (
					!res.update_initialized &&
					res.moderation_status !== 'pending' &&
					res?.statuses?.some((status) => editableStatuses.includes(status))
				) {
					setIsEnableToEdit(true);
				} else {
					setIsEnableToEdit(false);
				}

				switch (true) {
					case res?.moderation_status === 'not_ready':
						setIsShipmentsBtnEnable(false);
						setIsTakenDownsBtnEnable(false);
						setDisabledBtnsReasonText('not_ready');
						setIsShowDistributionBtns(false);
						break;
					case res?.moderation_status === 'ready':
						setIsShipmentsBtnEnable(false);
						setIsTakenDownsBtnEnable(false);
						setDisabledBtnsReasonText('ready');
						setIsShowDistributionBtns(false);
						break;
					case res?.moderation_status === 'rejected':
						setIsShipmentsBtnEnable(false);
						break;
					case res?.moderation_status === 'pending':
						setIsShipmentsBtnEnable(false);
						setIsTakenDownsBtnEnable(false);
						setDisabledBtnsReasonText('pending');
						setIsShowDistributionBtns(false);
						break;
					case res?.moderation_status === 'disputed':
						setIsShipmentsBtnEnable(false);
						setIsTakenDownsBtnEnable(false);
						setDisabledBtnsReasonText('disputed');
						setIsShowDistributionBtns(false);
						break;

					default:
						break;
				}
				accounts
					.getReleaseModeration(accountId, releaseId, lang)
					.then((res) => {
						res = res.data.data;
						setModerationStatus(res.status);
					})
					.catch((error) => {
						console.error('Error', error);
					})
					.finally(() => {
						setLoading(false);
					});
			})
			.catch((error) => {
				console.error('Error', error);
				setLoading(false);
			});

		accounts
			.getReleaseTracksForBrowsing(accountId, releaseId)
			.then((res) => {
				res = res.data.data;
				res.sort((a, b) => a.track_number - b.track_number);
				setTracks(res);
			})
			.catch((error) => {
				console.error('Error', error);
			});

		accounts
			.getReleaseDelivery(accountId, releaseId)
			.then((res) => {
				setDeliveryData(res.data.data);
			})
			.catch((error) => {
				console.error('Error', error);
			});

		changeBackTargetUrl('/repertoire/releases');
		showBackNavi('/repertoire/releases', null, 'rod.action.back_to_list');

		return function cleanup() {
			hideBackNavi();
			setPrevRepertoireParams({ page: 1 });
			setIsShowDistributionBtns(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (moderationStatus) {
			if (match.path.includes('info')) {
				setDefaultTab(0);
			} else if (match.path.includes('notices')) {
				setDefaultTab(1);
			} else if (match.path.includes('moderation')) {
				setDefaultTab(1);
			} else if (match.path.includes('deliveries')) {
				setDefaultTab(shouldShowModerationTab || !isReleaseReady ? 2 : 1);
			} else if (match.path.includes('logs')) {
				setDefaultTab(
					shouldShowModerationTab
						? shouldShowDeliveriesTab
							? 3
							: 2
						: shouldShowDeliveriesTab
						? 2
						: 1
				);
			} else if (match.path.includes('statistics')) {
				setDefaultTab(
					shouldShowModerationTab
						? shouldShowDeliveriesTab
							? shouldShowLogsTab
								? 4
								: 3
							: 2
						: shouldShowDeliveriesTab
						? shouldShowLogsTab
							? 3
							: 2
						: 1
				);
			} else {
				setDefaultTab(0);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [moderationStatus]);

	useEffect(() => {
		if (releaseTypeId) {
			accounts
				.getOutletsWithTypes(lang, releaseTypeId)
				.then((outlets) => {
					outlets = outlets.data.data;
					setAllOutlets(outlets);
				})
				.catch((error) => {
					console.error('Error', error);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [releaseTypeId]);

	useEffect(() => {
		if (allOutlets.length) {
			const shipmentsMatchingWithId = [];
			const shipmentsMatchingWithIdAndStatus = [];
			const takeDownsMatchingWithId = [];
			const takeDownsMatchingWithIdAndStatus = [];

			getReleaseShipments(accountId, releaseId)
				.then((res) => {
					if (!res || !res.length) {
						setIsShipmentsBtnEnable(false);
						setIsReleaseInQueue(false);
					} else {
						if (res.every((item) => !item.status)) {
							setIsShipmentsBtnEnable(false);
						}
						const isInProcess = res.find(
							(item) =>
								item.status === 'shipping_queue' ||
								item.status === 'takedown_queue'
						);
						setIsReleaseInQueue(isInProcess ? true : false);
						const shipmentIds = res.map((item) => item.id);

						allOutlets.forEach((item) => {
							if (shipmentIds.includes(item.id)) {
								shipmentsMatchingWithId.push(item.id);
								const outlet = res.find((outlet) => outlet.id === item.id);
								if (outlet.status === 'shipping')
									shipmentsMatchingWithIdAndStatus.push(outlet.id);
							}
						});

						if (
							shipmentsMatchingWithId.length ===
							shipmentsMatchingWithIdAndStatus.length
						) {
							setIsShipmentsBtnEnable(false);
						} else if (isInProcess && Object.keys(isInProcess).length) {
							setIsShipmentsBtnEnable(false);

							isInProcess.status === 'shipping_queue'
								? setIsShipmentsInProcess(true)
								: setIsShipmentsInProcess(false);

							setDefaultTab(1);
						} else {
							setShipmentsArr(res);
							setIsShipmentsInProcess(false);
						}
					}
				})
				.catch((err) => {
					console.error('Error:', err);
				})
				.finally(() => {
					setLoading(false);
				});

			getReleaseTakedowns(accountId, releaseId)
				.then((res) => {
					if (!res || !res.length) {
						setIsTakenDownsBtnEnable(false);
						setIsReleaseInQueue(false);
					} else {
						if (res.every((item) => !item.status)) {
							setIsTakenDownsBtnEnable(false);
						}

						const isInProcess = res.find(
							(item) =>
								item.status === 'takedown_queue' ||
								item.status === 'shipping_queue'
						);
						setIsReleaseInQueue(isInProcess ? true : false);
						const takeDownsIds = res.map((item) => item.id);

						allOutlets.forEach((item) => {
							if (takeDownsIds.includes(item.id)) {
								takeDownsMatchingWithId.push(item.id);
								const outlet = res.find((outlet) => outlet.id === item.id);
								if (
									outlet.status !== 'shipping' &&
									outlet.status !== 'takedown_queue'
								)
									takeDownsMatchingWithIdAndStatus.push(outlet.id);
							}
						});

						if (
							takeDownsMatchingWithId.length ===
							takeDownsMatchingWithIdAndStatus.length
						) {
							setIsTakenDownsBtnEnable(false);
						} else if (isInProcess && Object.keys(isInProcess).length) {
							setIsTakenDownsBtnEnable(false);

							isInProcess.status === 'takedown_queue'
								? setIsTakenDownsInProcess(true)
								: setIsTakenDownsInProcess(false);
						} else {
							setTakenDownsArr(res);
							setIsTakenDownsInProcess(false);
						}
					}
				})
				.catch((err) => {
					console.error('Error:', err);
				})
				.finally(() => {
					setLoading(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allOutlets]);

	const getInfo = (data) => {
		setInfoFromRelease(data);
	};

	const handleActionBtn = () => {
		setIsActionMenuVisible((prev) => !prev);
	};

	const handleBlur = (e) => {
		if (!e.relatedTarget?.attributes?.value) {
			handleActionBtn();
		}
	};

	return (
		<>
			{loading ? (
				<Loading className={styles.loader} />
			) : (
				<>
					<Header
						infoFromRelease={infoFromRelease}
						tracks={tracks}
						handleActionBtn={handleActionBtn}
						handleBlur={handleBlur}
						isActionMenuVisible={isActionMenuVisible}
						isShipmentsBtnEnable={isShipmentsBtnEnable}
						releaseTypeId={releaseTypeId}
						shipmentsArr={shipmentsArr}
						disabledBtnsReasonText={disabledBtnsReasonText}
						isShipmentsInProcess={isShipmentsInProcess}
						isTakenDownsBtnEnable={isTakenDownsBtnEnable}
						takenDownsArr={takenDownsArr}
						isTakenDownsInProcess={isTakenDownsInProcess}
						hasPriveleges={hasPriveleges}
						isEnableToEdit={isEnableToEdit}
						isDraftStatus={isDraftStatus}
					/>

					{defaultTab !== '' && (
						<Tabs
							props={props}
							defaultTab={defaultTab}
							isReleaseReady={isReleaseReady}
							isReleaseInQueue={isReleaseInQueue}
							moderationStatus={moderationStatus}
							tracks={tracks}
							getInfo={getInfo}
							hasPriveleges={hasPriveleges}
							shouldShowModerationTab={shouldShowModerationTab}
							shouldShowDeliveriesTab={shouldShowDeliveriesTab}
							shouldShowLogsTab={shouldShowLogsTab}
							shouldShowStatisticsTab={shouldShowStatisticsTab}
						/>
					)}
				</>
			)}
		</>
	);
};

export default compose(withUI, withAuth, withRoot)(RepertoireRelease);
