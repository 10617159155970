// Core
import React, { useState, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRoot } from 'hocs';
import { compose } from 'recompose';
import downloadFromAmazon from 'logic/downloadFromAmazon';

// Icons
import download from 'images/download_square.svg';

// Hooks
import { useClickOutside } from 'hooks';

// Styles
import styles from './StatisticDownloadBtn.module.css';

const StatisticDownloadBtn = (props) => {
	const [isReportReady, setIsReportReady] = useState(true);
	const [open, setOpen] = useState(false);

	const containerRef = useRef(null);
	const buttonRef = useRef(null);

	useClickOutside(containerRef, () => setOpen(false));

	const handleButtonClick = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const {
		accountId,
		statisticData,
		accountOutlets,
		isAdmin,
		tracksIds,
		v2,
		chartMode,
	} = props;

	const {
		rootContext: {
			createStatisticAccountReport,
			createStatisticAccountSummaryReport,
			getRespStatisticAccountReports,
			getStatisticAccountReportFile,
			createStatisticAdminReport,
			getRespStatisticAdminReports,
			getStatisticAdminReportFile,
			setIsReportDownloaded,
		},
	} = props;

	const downloadHandling = async (reportType) => {
		setIsReportReady(false);
		setOpen(false);

		const outlets =
			statisticData['outlets']?.length > 0
				? statisticData['outlets']
				: accountOutlets;

		const outletsCodes = outlets.map((outlet) => outlet.code);
		const startEndDates = [
			statisticData.start_date.includes('T')
				? statisticData.start_date.slice(
						0,
						statisticData.start_date.indexOf('T')
				  )
				: statisticData.start_date,
			statisticData.end_date.includes('T')
				? statisticData.end_date.slice(0, statisticData.end_date.indexOf('T'))
				: statisticData.end_date,
		];

		if (!isAdmin) {
			let filePath = '';
			let res;
			if (reportType === 'summary') {
				res = await createStatisticAccountSummaryReport(
					accountId,
					startEndDates[0],
					startEndDates[1],
					outletsCodes,
					tracksIds,
					props.releaseId ? [props.releaseId] : [],
					chartMode === 'compo' ? 'copyrighting' : ''
				);
			} else {
				res = await createStatisticAccountReport(
					accountId,
					startEndDates[0],
					startEndDates[1],
					outletsCodes,
					tracksIds,
					chartMode === 'compo' ? 'copyrighting' : ''
				);
			}

			getRespStatisticAccountReports(accountId).then((reports) => {
				const intervalId = setInterval(() => {
					getStatisticAccountReportFile(accountId, res.data).then((file) => {
						if (file.data.status === 'done') {
							console.info('file is ready');
							setIsReportDownloaded(true);
							setIsReportReady(true);
							filePath = file.data.output_file;
							downloadFromAmazon(filePath);
							clearInterval(intervalId);
						} else {
							setIsReportDownloaded(false);
							console.info('in progress...');
						}
					});
				}, 2000);
			});
		} else {
			let filePath = '';
			createStatisticAdminReport(
				accountId,
				startEndDates[0],
				startEndDates[1],
				outletsCodes
			).then((res) => {
				getRespStatisticAdminReports(accountId).then((reports) => {
					const intervalId = setInterval(() => {
						getStatisticAdminReportFile(accountId, res.data).then((file) => {
							if (file.data.status === 'done') {
								setIsReportReady(true);
								filePath = file.data.output_file;
								downloadFromAmazon(filePath);
								// const link = document.createElement('a');
								// link.href = filePath;
								// link.download = 'statistic';
								// link.click();
								// window.open(filePath, '_parent');
								clearInterval(intervalId);
							} else {
								console.info('in progress...');
							}
						});
					}, 2000);
				});
			});
		}
	};

	return (
		<div ref={containerRef} className={styles.btnContainer}>
			<button
				ref={buttonRef}
				className={
					!v2 &&
					(!isReportReady ||
						!statisticData.outlets.length ||
						!statisticData.tracks.length)
						? `${styles.disabled} ${styles.button}`
						: `${styles.enabled} ${styles.button}`
				}
				disabled={
					!v2 &&
					(!isReportReady ||
						!statisticData.outlets.length ||
						!statisticData.tracks.length)
				}
				onClick={handleButtonClick}
			>
				<img
					style={!props.releaseId ? { width: '24px', height: '24px' } : {}}
					src={download}
					alt=""
				/>
				{props.withTitle && (
					<div className={styles.downloadBtnTitle}>
						<FormattedMessage id={'rod.action.download.report'} />
					</div>
				)}
				{!isReportReady && (
					<div className={styles.downloadStatus}>
						<FormattedMessage id={'rod.statistic.preparing'} />
					</div>
				)}
			</button>
			{open && (
				<div className={styles.menuContainer}>
					<ul>
						<li
							className={styles.listItem}
							onClick={() => downloadHandling('summary')}
						>
							<FormattedMessage id={'rod.statistic.summary.report'} />
						</li>
						<li
							className={styles.listItem}
							onClick={() => downloadHandling('detailed')}
						>
							<FormattedMessage id={'rod.statistic.detailed.report'} />
						</li>
					</ul>
				</div>
			)}
		</div>
	);
};

export default compose(withRoot)(StatisticDownloadBtn);
