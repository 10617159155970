// Core
import React from 'react';
import classnames from 'classnames';

// UI
import { Label } from 'components/Labels';

// Styles
import styles from './Table.module.css';

const Table = (props) => (
	<div className={`${styles.Table} ${props.noBorder ? styles.withoutBorder : ''}`}>
		<Label text={props.title}
			black
			font="--gilroy-Medium-24"
		/>
		<table className={classnames(props.className)}>
			<thead>
				{props.head}
			</thead>
			<tbody>
				{props.body}
			</tbody>
		</table>
	</div>

);

export default Table;
