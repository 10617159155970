/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink, NavLink } from 'react-router-dom';
import { compose } from 'recompose';
import { withForwardRef } from '../../../hocs';
import styles from './Link.module.css';

class Link extends React.Component {

	static propTypes = {
		/**
		 * One or more button variant combinations
		 *
		 * buttons may be one of a variety of visual variants such as:
		 *
		 * `'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark', 'light', 'link'`
		 *
		 * as well as "outline" versions (prefixed by 'outline-*')
		 *
		 * `'outline-primary', 'outline-secondary', 'outline-success', 'outline-danger', 'outline-warning', 'outline-info', 'outline-dark', 'outline-light'`
		 */
		variant: PropTypes.string,

		/** Manually set the visual state of the button to `:active` */
		active: PropTypes.bool,

		/**
		 * Disables the Button, preventing mouse events,
		 * even if the underlying component is an `<a>` element
		 */
		disabled: PropTypes.bool,

		/**
		 * Defines HTML button type attribute.
		 *
		 * @default 'button'
		 */
		type: PropTypes.oneOf(['button', 'reset', 'submit', null]),

		/**
		 * Defines HTML button content.
		 */
		text: PropTypes.string,

	};

	static defaultProps = {
		disabled: false,
		black: true,
		underline: true,

	};

	static displayName = 'Link';

	render() {
		const { className, disabled, to, nav, a, underline, black, forwardRef, children, text, withFocusRef, ...props } = this.props;
		const classes = classNames(
			className,
			styles['Link'],
			disabled && 'disabled',
			underline && 'underline',
			black && 'black',
		);

		if (nav) {
			return (
				<NavLink {...props} ref={forwardRef} className={classes} to={to}>
					{children || text || ''}
				</NavLink>

			);
		}

		if (a) {
			return (
				<a {...props} href={to} ref={forwardRef} className={classes}>
					{children || text || ''}
				</a>

			);
		}

		return (
			<RouterLink {...props} ref={forwardRef} className={classes} to={to}>
				{children || text || ''}
			</RouterLink>
		);
	}
}

export default compose(
	withForwardRef,
)(Link);
