// Core
import { FormattedMessage } from 'react-intl';

// UI
import addPlus from 'images/addPlus.svg';

// Styles
import styles from './AddButton.module.css';

const AddButton = ({onClick}) => {
	return (
		<button className={styles.add__button} onClick={onClick}>
      <img src={addPlus} alt="plus" />
			<FormattedMessage
				id={'rod.release.create.step.tracks.additional_release.add_button'}
			/>
		</button>
	);
};

export default AddButton;
