export const handleClear = (
	filterSettings,
	setFilterSettings,
	setCurrentFilterSettings,
	setSelectFilters
) => {
	const newFilterSettings = [...filterSettings];
	newFilterSettings.map((item) =>
		item.list.map((list_item) => (list_item.checked = false))
	);
	setCurrentFilterSettings({});
	setFilterSettings(newFilterSettings);
	setSelectFilters({});
	localStorage.removeItem('prevFilters');
};
