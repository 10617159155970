export const groupTracksByReleaseType = (tracks) => {
	const groupedTracks = {};

	for (const track of tracks) {
		const trackName = track.title;

		if (track.additional && Array.isArray(track.additional)) {
			for (const additionalInfo of track.additional) {
				const releaseTypeId = additionalInfo.release_type_id;

				if (!groupedTracks[releaseTypeId]) {
					groupedTracks[releaseTypeId] = {
						release_type_id: releaseTypeId,
						additional: [],
					};
				}

				additionalInfo.title = trackName;
				groupedTracks[releaseTypeId].additional.push(additionalInfo);
			}
		}
	}

	const result = Object.values(groupedTracks).map((group) => {
		return {
			release_type_id: group.release_type_id,
			additional: group.additional,
		};
	});

	return result;
};
