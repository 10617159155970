// Messages
import tips from '../messages/tips.json';

// Constants
import { DEFAULT_LANG } from 'contexts/LangContext';

export const getHelpMessage = (lang, code) => {
	const id = tips?.map((item) => Object.keys(item[lang ?? DEFAULT_LANG][code]));
	return tips?.map((item) => item[lang ?? DEFAULT_LANG][code][id]);
};
