/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 11/12/2020, 13:48
 */

import { Button } from 'components/Buttons/Button';
import { Label } from 'components/Labels/Label';
import { withAuth, withLang, withRoot } from 'hocs';
import styles from 'pages/account/Create/steps/08-Complete/Complete.module.css';
import Success from 'pages/common/Success';
import React, { PureComponent } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
// import { Redirect } from 'react-router-dom';
// import PropTypes from 'prop-types';
import { compose } from 'recompose';

class SpecialSuccess extends PureComponent {
	static propTypes = {};

	state = {
		isPersonal: true,
	};

	render() {
		// const { authContext: { isAuth, login } } = this.props;

		return (
			<Success>
				<Label
					text={
						<FormattedHTMLMessage
							id={'rod.registration.special.success.header'}
						/>
					}
					className={styles.specialSuccess_header}
				/>

				<Label
					text={
						<FormattedHTMLMessage
							id={'rod.registration.special.success.body'}
						/>
					}
					className={styles.specialSuccess_body}
				/>
				<div className={styles.Controls}>
					<Button
						text={<FormattedHTMLMessage id={'rod.action.to_index_page'} />}
						variant={'primary'}
						onClick={() => this.props.history.push('/')}
					/>
				</div>
			</Success>
		);
	}
}

export default compose(
	withRoot,
	withAuth,
	withLang,
	withRouter
)(SpecialSuccess);
