// Core
import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { compose } from 'recompose';
import { withAuth, withRoot, withUI } from 'hocs';

// UI
import { Loading, TitleWithHelpBtn, Wizard } from 'components';
import { Button } from 'components/Buttons/Button';
import { ContractListItem } from 'components/ContractListItem';
import { Label } from 'components/Labels/Label';

// Styles
import styles from './List.module.css';

class List extends PureComponent {
	constructor(props) {
		super(props);
		this.steps = [
			{
				id: 1,
				status: 'complete',
				title: <FormattedMessage id={'rod.account.create.step_3_1.title'} />,
			},
			{
				id: 2,
				status: 'active',
				title: <FormattedMessage id={'rod.account.create.step_3_2.title'} />,
			},
		];
		this.state = {
			loading: true,
			data: {},
		};
	}

	componentDidMount() {
		const {
			authContext: { setIsAccountLoading },
			rootContext: { getContracts, getAdditionalContracts },
		} = this.props;

		setIsAccountLoading(true);
		if (this.props.isAdditional) {
			getAdditionalContracts(this.props.account.id).then((res) => {
				res = res.data.data;
				this.setState(
					update(this.state, {
						data: { $set: res },
						loading: { $set: false },
					})
				);
				setIsAccountLoading(false);
			});
		} else {
			getContracts(this.props.account.id).then((res) => {
				res = res.data.data;
				this.setState(
					update(this.state, {
						data: { $set: res },
						loading: { $set: false },
					})
				);
				setIsAccountLoading(false);
			});
		}
	}

	submit = () => {
		if (typeof this.props.onSubmit === 'function') {
			this.props.onSubmit(this.state.data);
		}
	};

	renderHeaderSignPhysical() {
		return (
			<React.Fragment>
				<TitleWithHelpBtn title="rod.contracts.sign.header" />

				<Label
					className="sub"
					text={<FormattedHTMLMessage id={'rod.contracts.sign.subheader'} />}
					font="--gilroy-Medium-24"
					black
				/>
			</React.Fragment>
		);
	}

	renderHeaderSignSMS() {
		return (
			<React.Fragment>
				<Label
					text={<FormattedHTMLMessage id={'rod.contracts.sign_sms.header'} />}
					font="--gilroy-Medium-40"
					black
				/>
				<Label
					className="sub"
					text={
						<FormattedHTMLMessage id={'rod.contracts.sign_sms.subheader'} />
					}
					font="--gilroy-Medium-24"
					black
				/>
			</React.Fragment>
		);
	}

	render() {
		if (this.state.loading) {
			return <Loading non_margin={true} />;
		}

		const { sms } = this.props;
		const { data } = this.state;

		return (
			<Container fluid className={styles.Page}>
				<Wizard steps={this.steps} className={styles.Wizard} />
				<div className={styles.Form}>
					<Row>
						<Col sm={10} offset={{ sm: 1 }} className={styles.Header}>
							{sms
								? this.renderHeaderSignSMS()
								: this.renderHeaderSignPhysical()}
						</Col>

						<Col sm={10} offset={{ sm: 1 }}>
							<ul className={styles.List}>
								{data.map((item, index) => (
									<li>
										<ContractListItem {...item} index={index} />
									</li>
								))}
							</ul>
							<div className={styles.Controls}>
								<Button
									text={
										<FormattedMessage id={'rod.action.goto_sign_contract'} />
									}
									variant={'primary'}
									onClick={this.submit}
								/>
							</div>
						</Col>
					</Row>
				</div>
			</Container>
		);
	}
}

List.propTypes = {
	onSubmit: PropTypes.func,
};

List.defaultProps = {};

export default compose(withAuth, withUI, withRoot)(List);
