// Core
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

// Context
import { AuthContext } from 'contexts/AuthContext';

// Utils
import { getCheckedOutlets } from '../utils/getCheckedOutlets';

// Styles
import s from './ConfirmDistributions.module.css';
import AdditionalDistributions from './AdditionalDistributions/AdditionalDistributions';

const ConfirmDistributions = ({ personal, recommendedOutlets }) => {
	const { isAdditionalReleaseEnable } = useContext(AuthContext);

	return (
		<>
			<div>
				<span className={s.title}>
					<FormattedMessage
						id={'rod.release.create.step.submit.distribution'}
					/>
				</span>
				<div className={s.distributions}>
					<div className={s.item}>
						<span className={s.item__title}>
							<FormattedMessage
								id={'rod.release.create.step.submit.distribution_date'}
							/>
						</span>
						<span className={s.item__text}>
							{personal?.distributions?.type === 'asap' ? (
								<FormattedMessage
									id={'rod.release.create.step.distribution.asap-date'}
								/>
							) : (
								personal?.distributions?.sale_start_date
							)}
						</span>
					</div>
					<div className={s.item}>
						<span className={s.item__title}>
							<FormattedMessage
								id={'rod.release.create.step.submit.outlets_available'}
							/>
						</span>
						<span className={s.item__textLh}>
							{personal?.distributions?.outlets &&
								personal.distributions.outlets.split(',').length -
									getCheckedOutlets(personal, recommendedOutlets)}{' '}
						</span>
					</div>
				</div>
			</div>

			{isAdditionalReleaseEnable &&
				personal.tracks.some((track) => track.additional && track.additional.length > 0) && (
					<AdditionalDistributions tracks={personal.tracks} />
				)}
		</>
	);
};

export default ConfirmDistributions;
