// Core
import { useContext, useEffect, useState, useCallback } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Col, Row } from 'react-grid-system';
import { withRoot, withUI } from 'hocs';
import { compose } from 'recompose';
import debounce from 'lodash.debounce';

// Types
import modalTypes from 'constants/modalTypes';

// Context
import { LangContext } from 'contexts/LangContext';

// Services
import admin from 'services/api/admin';
import pagesEn from 'messages/EN/pages';
import pagesRu from 'messages/RU/pages';
import { dateFormatEditor } from 'logic';

// Utils
import { correctDatesView } from './utils/correctDatesView';

// UI
import Loader from 'components/Loader';
import { Loading, NoData } from 'components';
import { Pagination, InputWithClear } from 'material-design/components';
import { ReportsAdminModal } from 'material-design/modals/ReportsAdminModal';

// Icons
import plusImg from 'images/plus.svg';
import edit from 'images/edit.svg';
import download from 'images/download.svg';
import error from 'images/error.svg';
import pendingIcon from 'images/pending-icon.svg';
import arrowDown from 'images/arrow_down.svg';
import arrowUp from 'images/arrow_up.svg';
import more from 'images/more.svg';
import moreWhite from 'images/moreWhite.svg';
import filter_svg from 'images/filter.svg';
import filter_dot_svg from 'images/filter_dot.svg';
import { FullScreenModalConfirm } from 'containers/Modal';

// Styles
import styles from './DistributionPage.module.css';

const DistributionPage = (props) => {
	const { lang } = useContext(LangContext);

	const [reports, setReports] = useState([]);
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [noData, setNoData] = useState(false);
	const [isShowMenuMore, setIsShowMenuMore] = useState(false);
	const [isShowMenuMoreId, setIsShowMenuMoreId] = useState('');
	const [accountsList, setAccountsList] = useState([]);
	const [nextReportDate, setNextReportDate] = useState({});
	const [uploadArchiveData, setUploadArchiveData] = useState([]);
	const [uploadProgressPercents, setUploadProgressPercents] = useState({});
	const [filterValue, setFilterValue] = useState('');
	const [modalFilter, setModalFilter] = useState(false);
	const [filteredDataLoading, setFilteredDataLoading] = useState(false);
	const [noFilteredData, setNoFilteredData] = useState(false);
	const [modal, setModal] = useState(false);
	const [isFilterEnable, setIsFilterEnable] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [distribution, setDistribution] = useState({ name: '' });
	const [sortData, setSortData] = useState({ columnName: '' });
	const [tmpSortingOrder, setTmpSortingOrder] = useState([]);
	const [distributionNames, setDistributionNames] = useState({
		heading_en: '',
		heading: '',
	});
	const [isParsingStart, setIsParsingStart] = useState(false);
	const [isParsingError, setIsParsingError] = useState(false);
	const [filterSettings, setFilterSettings] = useState([
		{
			id: 1,
			title_ru: 'статус',
			title_en: 'status',
			list: [
				{
					id: 1,
					name_ru: pagesRu['rod.admin.reports.reports_with_errors'],
					name_en: pagesEn['rod.admin.reports.reports_with_errors'],
					code: 'withError',
					checked: false,
				},
			],
		},
	]);

	const {
		UIContext: { showModal, showBackNavi, isManualReports, hideBackNavi },
		rootContext: {
			getAdminReportsByFilter,
			postReportArchiveRequest,
			removeReport,
			reloadReport,
			postReportArchiveFileS3,
			postReportXlsFile,
			putFileToS3,
			editManualReportFile,
			startReportParsing,
			getAllAdminDistributions,
			getAdminDistributionNextDate,
			putAdminDistributionNextDate,
			getAdminDistributionReports,
			putAdminDistributionReportArchive,
			postAdminDistributionReportFile,
			putAdminDistributionEditContentRequest,
		},
		distributionId,
	} = props;

	useEffect(() => {
		admin.getAccountsAdminV2().then((res) => setAccountsList(res.data.data));
		getAllAdminDistributions().then((res) => {
			const selectedDistribution = res.data.find(
				(item) => item.id === parseInt(distributionId)
			);
			if (selectedDistribution) {
				setDistribution({
					name:
						lang === 'en'
							? selectedDistribution.heading_en
							: selectedDistribution.heading,
				});
				setDistributionNames({
					heading_en: selectedDistribution.heading_en,
					heading: selectedDistribution.heading,
				});
			}
		});
		if (distributionId) {
			getAdminDistributionNextDate(distributionId).then((res) => {
				res = res.data;
				if (res) {
					const markupNextDate =
						dateFormatEditor(res.next_date, 'dmy') +
						res.next_date.slice(
							res.next_date.indexOf(' '),
							res.next_date.length === 19 ? -3 : res.next_date.length
						);
					setNextReportDate({
						...res,
						next_date: markupNextDate,
					});
				}
			});
		}
		showBackNavi('/admin/v2/reports');
		return hideBackNavi;

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (accountsList.length) {
			if (!isFilterEnable) {
				getReports();
			} else if (isFilterEnable && page !== 1) {
				getReportsWithErrorFilter(isFilterEnable);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountsList, page]);

	const getReports = (isErrorsCheck) => {
		setLoading(true);
		if (distributionId) {
			getAdminDistributionReports(distributionId, page)
				.then((res) => {
					setTotal(res.total);
					if (isErrorsCheck && res.error && res.error.length > 0) {
						setShowErrorModal(true);
					}
					if (res.data.length) {
						if (noData) setNoData(false);
						res = res.data.sort(
							(a, b) => new Date(b.created_at) - new Date(a.created_at)
						);
						const correctedDates = correctDatesView(res);
						setReports(correctedDates);
						setTmpSortingOrder([...res]);
					} else {
						setNoData(true);
					}
				})
				.catch((err) => {
					console.error('Error', err);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	const getReportsWithErrorFilter = (filter, fromFilter) => {
		setLoading(true);
		let requestPage = fromFilter ? 1 : page;
		getAdminReportsByFilter(distributionId, requestPage, filter, filterValue)
			.then((res) => {
				setTotal(res.total);
				if (res.data.length) {
					if (noData) setNoData(false);
					res = res.data.sort(
						(a, b) => new Date(b.created_at) - new Date(a.created_at)
					);
					const correctedDates = correctDatesView(res);
					setReports(correctedDates);
					setTmpSortingOrder([...res]);
				} else {
					setNoData(true);
				}
			})
			.catch((err) => {
				console.error('Error', err);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const getReportsWithFilterWromModal = (filter) => {
		setPage(1);
		setModalFilter(filter);
		try {
			getReportsWithErrorFilter(filter, true);
		} catch (error) {
			console.error('Error', error);
		} finally {
			modalOpen();
		}
	};

	const modalOpen = () => {
		setModal(!modal);
	};

	const handleCheckedFilter = (index, code) => {
		filterSettings[index].list.map((item) => {
			if (item.code === code) {
				item.checked = !item.checked;
				item.checked ? setIsFilterEnable(true) : setIsFilterEnable(false);
			}
		});

		setFilterSettings([...filterSettings]);
	};

	const handleClearFilter = () => {
		filterSettings.forEach((setting) => {
			setting.list.forEach((item) => {
				if (item.checked) item.checked = false;
			});
		});
		setIsFilterEnable(false);
		setFilterSettings([...filterSettings]);
	};

	const handleClickMore = (index) => {
		setIsShowMenuMoreId(index);
		setIsShowMenuMore(true);
	};

	const handleOnBlurMenuMore = () => {
		setIsShowMenuMoreId('');
		setIsShowMenuMore(false);
	};

	const handleCreateNextReportDate = (date) => {
		putAdminDistributionNextDate(distributionId, date).then((res) => {
			setNextReportDate({
				...res.data,
				next_date:
					dateFormatEditor(res.data.next_date, 'dmy', '.') +
					res.data.next_date.slice(
						res.data.next_date.indexOf(' '),
						res.data.next_date.length === 19 ? -3 : res.data.next_date.length
					),
			});
		});
	};

	const handleEditNextReportDate = (date) => {
		putAdminDistributionNextDate(distributionId, date).then((res) => {
			setNextReportDate({
				...res.data,
				next_date:
					dateFormatEditor(res.data.next_date, 'dmy', '.') +
					res.data.next_date.slice(
						res.data.next_date.indexOf(' '),
						res.data.next_date.length === 19 ? -3 : res.data.next_date.length
					),
			});
		});
	};

	const prepareFileToUpload = async (
		archiveAttachment,
		id,
		reportPrepared,
		xlsAttachment
	) => {
		if (!isManualReports) {
			let createdAt = dateFormatEditor(reportPrepared.created_at, 'dmy', '.');
			let datePublication = dateFormatEditor(
				reportPrepared.date_publication.date,
				'dmy',
				'.'
			);

			setUploadArchiveData([
				{
					...reportPrepared,
					created_at: createdAt,
					date_publication: datePublication,
				},
			]);
		}

		const config = {
			onUploadProgress: (progressEvent) => {
				const percentCompleted = Math.round(
					(progressEvent.loaded * 100) / progressEvent.total
				);

				if (+progressEvent.loaded !== +progressEvent.total) {
					setUploadProgressPercents({ value: percentCompleted });
				}
			},
		};

		const file_name_with_ext = archiveAttachment.fileData.name;
		const file_size = archiveAttachment.fileData.size;

		let response = await postReportArchiveFileS3(
			file_name_with_ext,
			file_size,
			id
		);
		if (noData) setNoData(false);

		await putFileToS3(response.putUrl, archiveAttachment.file, config);
		if (!isManualReports) {
			setIsParsingStart(true);
			await startReportParsing()
				.then((res) => {
					setIsParsingError(res.status === 'error' ? true : false);
				})
				.catch((err) => console.error('Error:', err))
				.finally(() => {
					setIsParsingStart(false);
				});
		}

		if (isManualReports) {
			setIsParsingStart(true);
			await postReportXlsFile(xlsAttachment.file)
				.then((res) => {})
				.catch((err) => console.error('Error:', err))
				.finally(() => {
					setIsParsingStart(false);
				});
		}
		setUploadArchiveData([]);
		setUploadProgressPercents({});
		refreshList(true);
	};

	const handleDelete = (item) => {
		removeReport(item.report_files)
			.then((res) => {
				handleOnBlurMenuMore();
				getReports();
			})
			.catch((err) => {
				console.error('Error', err);
			});
	};

	const handlingReloadReport = (identifier) => {
		reloadReport(identifier);
	};

	const refreshList = (isErrorsCheck) => {
		getReports(isErrorsCheck);
	};

	const onChange = (filter) => {
		setNoFilteredData(false);
		setFilterValue(filter);
		setPage(1);
		if (filter.length >= 1) {
			setFilteredDataLoading(true);
			getAdminReportsByFilter(distributionId, 1, modalFilter, filter)
				.then((res) => {
					setTotal(res.total);
					if (res.data.length) {
						res = res.data.sort(
							(a, b) => new Date(b.created_at) - new Date(a.created_at)
						);

						const correctedDates = correctDatesView(res);
						setReports(correctedDates);
						setTmpSortingOrder([...res]);
					} else {
						setNoFilteredData(true);
					}
				})
				.catch((err) => {
					console.error('Error', err);
				})
				.finally(() => {
					setFilteredDataLoading(false);
				});
		} else if (!filter) {
			setFilteredDataLoading(true);
			getAdminReportsByFilter(distributionId, 1)
				.then((res) => {
					setTotal(res.total);
					setNoFilteredData(false);
					res = res.data.sort(
						(a, b) => new Date(b.created_at) - new Date(a.created_at)
					);

					const correctedDates = correctDatesView(res);
					setReports(correctedDates);
					setTmpSortingOrder([...res]);
				})
				.catch((err) => {
					console.error('Error', err);
				})
				.finally(() => {
					setFilteredDataLoading(false);
				});
		}
	};

	const handleFilter = useCallback(debounce(onChange, 500), []);

	const closeModal = () => {
		setShowErrorModal(false);
	};

	const closeParsingErrorModal = () => {
		isParsingError(false);
		getReports();
	};

	const handleSort = (columnName) => {
		if (sortData.columnName === columnName) {
			setSortData({ columnName: '' });
			const correctedDates = correctDatesView(tmpSortingOrder);
			setReports(correctedDates);
		} else {
			setSortData({ columnName });
			let data = [];
			let correctedDates = [];
			switch (columnName) {
				case 'published':
					data = [...tmpSortingOrder].sort(
						(a, b) =>
							new Date(a.date_publication) - new Date(b.date_publication)
					);
					correctedDates = correctDatesView(data);
					setReports(correctedDates);
					break;
				case 'made':
					data = [...tmpSortingOrder].sort(
						(a, b) => new Date(a.created_at) - new Date(b.created_at)
					);
					correctedDates = correctDatesView(data);
					setReports(correctedDates);
					break;
				case 'title':
					data =
						lang === 'en'
							? reports.sort((a, b) =>
									a.heading_en?.toLowerCase() > b.heading_en?.toLowerCase()
										? 1
										: -1
							  )
							: reports.sort((a, b) =>
									a.heading?.toLowerCase() > b.heading?.toLowerCase() ? 1 : -1
							  );
					setReports([...data]);
					break;
				case 'id':
					data = reports.sort((a, b) => +a.account_id - +b.account_id);
					setReports([...data]);
					break;
				case 'sum':
					data = reports.sort((a, b) => +a.royalties - +b.royalties);
					setReports([...data]);
					break;
				default:
					break;
			}
		}
	};

	return (
		<div
			className={styles.mainWrapper}
			style={
				noFilteredData || noData || loading
					? { overflow: 'hidden' }
					: { overflow: 'unset' }
			}
		>
			<>
				{loading || !accountsList ? (
					<Loading />
				) : (
					<>
						<div className={styles.sectionTitleWrapper}>
							{distribution.name}
						</div>
						<div className={styles.sectionFilterWrapper}>
							<div className={styles.filterWrapper}>
								<FormattedHTMLMessage id={'rod.account_name.find'}>
									{(placeholder) => (
										<InputWithClear
											handleOnChange={handleFilter}
											placeholder={placeholder}
										/>
									)}
								</FormattedHTMLMessage>

								<img
									src={isFilterEnable ? filter_dot_svg : filter_svg}
									alt="filter"
									className={styles.filterIcon}
									onClick={modalOpen}
								/>
							</div>
							<div className={styles.reportDateWrapper}>
								{nextReportDate.next_date ? (
									<ul>
										<li
											style={{
												color: 'var(--color-grey100)',
												font: 'var(--gilroy-Medium-14)',
											}}
										>
											<FormattedHTMLMessage
												id={'rod.field.admin.reports.next_report_date'}
											/>
										</li>
										<li className={styles.iconEditWrapper}>
											<span
												style={{
													font: 'var(--gilroy-Medium-18)',
												}}
											>
												{nextReportDate.next_date}
											</span>

											<img
												src={edit}
												alt="edit"
												className={styles.iconEdit}
												style={{
													cursor: 'pointer',
												}}
												onClick={showModal(
													{
														handleNextReportDate: handleEditNextReportDate,
														date: nextReportDate.next_date,
													},
													'nextReportDate'
												)}
											/>
										</li>
									</ul>
								) : (
									<div
										className={styles.sectionTitle}
										onClick={showModal(
											{
												handleNextReportDate: handleCreateNextReportDate,
											},
											'nextReportDate'
										)}
									>
										<FormattedHTMLMessage
											id={'rod.field.admin.reports.next_report_date'}
										/>
									</div>
								)}
							</div>
							<div className={styles.topBtnsWrapper}>
								<button
									className={styles.createArticleBtn}
									onClick={
										!isManualReports
											? showModal(
													{
														putAdminDistributionReportArchive,
														postAdminDistributionReportFile,
														postReportXlsFile,
														distributionId,
														distributionName: distributionNames,
														editManualReportFile,
														prepareFileToUpload,
														refreshList,
													},
													'reports'
											  )
											: showModal(
													{
														prepareFileToUpload: prepareFileToUpload,
														postReportArchiveRequest: postReportArchiveRequest,
														refreshList,
														distributionId: distributionId,
														updateAdminReportsDistributions: putAdminDistributionReportArchive,
														distributionNames: distributionNames,
														postAdminDistributionReportFile,
														editManualReportFile,
													},
													'reports_manual'
											  )
									}
								>
									<img src={plusImg} alt="add" className={styles.iconPlus} />
									<FormattedHTMLMessage id={'rod.admin.reports.add'} />
								</button>
							</div>
						</div>

						{filteredDataLoading ? (
							<Loading />
						) : noFilteredData ? (
							<NoData
								textId={'rod.filter.no_reports_title'}
								textIdNext={'rod.filter.no_reports_body'}
							/>
						) : noData ? (
							<NoData
								textId={'rod.no_reports_title'}
								textIdNext={'rod.no_reports_body'}
							/>
						) : (
							<div className={styles.TableWrapper}>
								<Row className={styles.row_header}>
									<Col width={'12%'} className={styles.row_header_content}>
										<FormattedHTMLMessage
											id={'rod.field.admin.report.published'}
										/>
										<img
											src={
												sortData.columnName === 'published'
													? arrowUp
													: arrowDown
											}
											alt=""
											onClick={() => handleSort('published')}
										/>
									</Col>
									<Col width={'12%'} className={styles.row_header_content}>
										<FormattedHTMLMessage id={'rod.field.admin.reports.made'} />
										<img
											src={sortData.columnName === 'made' ? arrowUp : arrowDown}
											alt=""
											onClick={() => handleSort('made')}
										/>
									</Col>
									<Col width={'11%'} className={styles.row_header_content}>
										<FormattedHTMLMessage
											id={'rod.field.admin.reports.acc_id'}
										/>
										<img
											src={sortData.columnName === 'id' ? arrowUp : arrowDown}
											alt=""
											onClick={() => handleSort('id')}
										/>
									</Col>
									<Col width={'30%'} className={styles.row_header_content}>
										<FormattedHTMLMessage id={'rod.field.admin.title'} />
										<img
											src={
												sortData.columnName === 'title' ? arrowUp : arrowDown
											}
											alt=""
											onClick={() => handleSort('title')}
										/>
									</Col>
									<Col width={'15%'} className={styles.row_header_content}>
										<FormattedHTMLMessage id={'rod.field.admin.reports.sum'} />
										<img
											src={sortData.columnName === 'sum' ? arrowUp : arrowDown}
											alt=""
											onClick={() => handleSort('sum')}
										/>
									</Col>
									<Col width={'170px'} className={styles.row_header_content}>
										<FormattedHTMLMessage id={'rod.field.admin.actions'} />
									</Col>
									<Col width={'42px'}></Col>
								</Row>
								<div className={styles.RepertoireMainInfoTable__body}>
									<ul>
										{uploadArchiveData.length
											? uploadArchiveData.map((item, index) => (
													<li className={styles.articlesListItem}>
														<Row nowrap className={styles.row_data}>
															<Row nowrap className={styles.row_title}>
																<Col width={'12%'} className={styles.shell}>
																	{item.date_publication}
																</Col>
																<Col width={'12%'} className={styles.shell}>
																	{item.created_at}
																</Col>
																<Col
																	width={'11%'}
																	className={styles.shell}
																></Col>
																<Col width={'30%'} className={styles.shell}>
																	{lang === 'ru'
																		? item.heading
																		: item.heading_en}
																</Col>
																<Col
																	width={'15%'}
																	className={styles.shell}
																></Col>

																<Col width={'170px'} className={styles.shell}>
																	{!isParsingStart ? (
																		<div className={styles.loaderWrapper}>
																			<div className={styles.loader}>
																				<Loader
																					uploadProgressPercents={
																						uploadProgressPercents
																					}
																					width={'64px'}
																					height={'64px'}
																					padding={'0px'}
																				/>
																			</div>
																			{uploadProgressPercents.value && (
																				<span className={styles.percentCount}>
																					{uploadProgressPercents.value + '%'}
																				</span>
																			)}
																		</div>
																	) : (
																		<div>
																			<div className={styles.action}>
																				<img
																					src={pendingIcon}
																					alt=""
																					width="25"
																				/>
																				<div
																					className={styles.actionTitlePending}
																				>
																					<FormattedHTMLMessage
																						id={
																							'rod.admin.reports.pending_review'
																						}
																					/>
																				</div>
																			</div>
																		</div>
																	)}
																</Col>
																<Col width={'42px'}></Col>
															</Row>
														</Row>
													</li>
											  ))
											: ''}
										{reports.map((item, index) => (
											<li className={styles.articlesListItem}>
												<Row nowrap className={styles.row_data}>
													<Row nowrap className={styles.row_title}>
														<Col width={'12%'} className={styles.shell}>
															{item.date_publication}
														</Col>
														<Col width={'12%'} className={styles.shell}>
															{item.created_at}
														</Col>
														<Col width={'11%'} className={styles.shell}>
															<div className={styles.row_title_accId}>
																<span>{item.account_id}</span>
																<span>
																	{item.account_title
																		? item.account_title
																		: '\u2212'}
																</span>
															</div>
														</Col>
														<Col width={'30%'} className={styles.shell}>
															<div className={styles.heading}>
																{lang === 'ru' ? item.heading : item.heading_en}
															</div>
														</Col>
														<Col width={'15%'} className={styles.shell}>
															{item.royalties &&
																item.royalties.replace(
																	/(\d)(?=(\d{3})+(?!\d))/g,
																	`$1\u00A0`
																) +
																	' ' +
																	item.currency}
														</Col>

														<Col width={'170px'} className={styles.shell}>
															{item.status === 'done' && (
																<div
																	className={styles.action}
																	onClick={() => {
																		item.file_id &&
																			window.open(item.file_id, '_parent');
																	}}
																>
																	<img src={download} alt="" width="25" />
																	<div
																		className={styles.actionTitle}
																		style={{ paddingTop: '1px' }}
																	>
																		<FormattedHTMLMessage
																			id={'rod.action.download'}
																		/>
																	</div>
																</div>
															)}
															{item.status === 'error' && (
																<div
																	className={styles.action}
																	onClick={() => {
																		item.file_id &&
																			window.open(item.file_id, '_parent');
																	}}
																>
																	<img src={error} alt="" />
																	<div className={styles.actionTitleError}>
																		<FormattedHTMLMessage
																			id={'rod.error_page.not_found.header'}
																		/>
																	</div>
																</div>
															)}
															{item.status === 'pending' && (
																<div className={styles.action}>
																	<img src={pendingIcon} alt="" width="25" />
																	<div className={styles.actionTitlePending}>
																		<FormattedHTMLMessage
																			id={'rod.admin.reports.pending_review'}
																		/>
																	</div>
																</div>
															)}
														</Col>

														<Col width={'42px'} className={styles.shell}>
															{isShowMenuMore && isShowMenuMoreId === index ? (
																<div
																	className={styles.menuMoreWrapperActive}
																	tabindex="0"
																	onBlur={handleOnBlurMenuMore}
																>
																	<img
																		src={moreWhite}
																		alt=""
																		className={styles.menuMore}
																	/>
																	<ul className={styles.menuMoreList}>
																		<li
																			className={styles.menuMoreListItem}
																			onClick={() => {
																				item.status === 'error' &&
																					item.report_files &&
																					handlingReloadReport(item.file_db);
																			}}
																		>
																			<span
																				style={
																					['pending', 'done'].includes(
																						item.status
																					) || !item.report_files
																						? { color: 'var(--color-grey)' }
																						: { cursor: 'pointer' }
																				}
																			>
																				<FormattedMessage
																					id={`rod.admin.reports.download_again`}
																				/>
																			</span>
																		</li>

																		<li
																			className={styles.menuMoreListItem}
																			onClick={
																				item.status !== 'pending' &&
																				item.report_files &&
																				showModal(
																					{
																						isManualReports,
																						globalLang: lang,
																						reportId: item.report_files,
																						distributionId,
																						data: item,
																						putAdminDistributionReportArchive,
																						postAdminDistributionReportFile,
																						putAdminDistributionEditContentRequest,
																						editManualReportFile,
																						refreshList,
																					},
																					'reports'
																				)
																			}
																		>
																			{
																				<span
																					style={
																						item.status === 'pending' ||
																						!item.report_files
																							? { color: 'var(--color-grey)' }
																							: { cursor: 'pointer' }
																					}
																				>
																					<FormattedMessage
																						id={`rod.admin.reports.edit`}
																					/>
																				</span>
																			}
																		</li>

																		<li
																			className={styles.menuMoreListItem}
																			onClick={showModal(
																				{
																					title: (
																						<FormattedMessage
																							id={
																								'rod.distribution.report.delete-confirm'
																							}
																						/>
																					),
																					text: (
																						<FormattedMessage
																							id={
																								'rod.distribution.report.delete-help'
																							}
																						/>
																					),
																					onAction: () => {
																						item.status !== 'pending' &&
																							item.report_files &&
																							handleDelete(item);
																					},
																					confirmBtnTxt: (
																						<FormattedMessage
																							id={'rod.modal.yes_delete'}
																						/>
																					),
																					declineBtnTxt: (
																						<FormattedMessage
																							id={'rod.modal.cancel'}
																						/>
																					),
																				},
																				modalTypes.FULL_MODAL
																			)}
																		>
																			<span
																				style={
																					item.status === 'pending' ||
																					!item.report_files
																						? { color: 'var(--color-grey)' }
																						: { cursor: 'pointer' }
																				}
																			>
																				<FormattedMessage
																					id={`rod.drafts.delete`}
																				/>
																			</span>
																		</li>
																	</ul>
																</div>
															) : (
																<div
																	className={styles.menuMoreWrapper}
																	tabindex="0"
																	onClick={() => handleClickMore(index)}
																	onBlur={handleOnBlurMenuMore}
																>
																	<img
																		src={more}
																		alt=""
																		className={styles.menuMore}
																	/>
																</div>
															)}
														</Col>
													</Row>
												</Row>
											</li>
										))}
									</ul>
								</div>
							</div>
						)}
					</>
				)}
				{total > 15 && !loading && !filteredDataLoading && !noFilteredData && (
					<div style={{ marginTop: '30px', paddingBottom: '30px' }}>
						<Pagination
							page={page}
							total={total}
							paginate={(pageNumber) => {
								setPage(pageNumber);
							}}
							items_on_page={15}
						/>
					</div>
				)}
			</>

			<ReportsAdminModal
				active={modal}
				setActive={modalOpen}
				filterData={filterSettings}
				handleCheckedFilter={handleCheckedFilter}
				submitFilter={getReportsWithFilterWromModal}
				isFilterEnable={isFilterEnable}
				handleClearFilter={handleClearFilter}
			/>
			{showErrorModal && !isParsingError && (
				<FullScreenModalConfirm
					title={<FormattedMessage id={'rod.modal.admin_reports_title'} />}
					text={<FormattedMessage id={'rod.modal.admin_reports_text'} />}
					confirmBtnTxt={<FormattedMessage id={'rod.modal.understood'} />}
					onClose={closeModal}
				/>
			)}

			{isParsingError && (
				<FullScreenModalConfirm
					title={
						<FormattedMessage id={'rod.modal.admin_reports_parsing.title'} />
					}
					text={
						<FormattedMessage id={'rod.modal.admin_reports_parsing.text'} />
					}
					confirmBtnTxt={<FormattedMessage id={'rod.modal.understood'} />}
					onClose={closeParsingErrorModal}
				/>
			)}
		</div>
	);
};
export default compose(withUI, withRoot)(DistributionPage);
