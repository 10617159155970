/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 10/03/2021, 19:16
 */

import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
// import 'core-js/es6/map';
// import 'core-js/es6/set';
// import 'url-polyfill';
// import 'raf/polyfill';
// import 'nodelist-foreach-polyfill';
// import 'ie-array-find-polyfill';
import 'react-notifications/lib/notifications.css';
/* import Analytics from 'services/analytics'; */

import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';

/* отключили в ЛК услуги datadog */
/* Analytics.getInstance(); */
window.COMMIT_SHA = process.env.REACT_APP_COMMIT_SHA;

window.handleLanguageChange = (lang) => {
	// 	//clear
	// 	const widgetNode = document.querySelector('#usedesk-messenger');
	// 	if (widgetNode) {
	// 		widgetNode.remove();
	// 	}
	// 	if (window.UsedeskMessenger) {
	// 		if (lang === 'en') {
	// 			window.usedeskMessenger = new window.UsedeskMessenger(en);
	// 		} else {
	// 			window.usedeskMessenger = new window.UsedeskMessenger(ru);
	// 		}
	// 	}
};

const root = createRoot(document.getElementById('root'));

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
