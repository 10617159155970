// Core
import { FormattedMessage } from 'react-intl';

// Styles
import '../StatusHistory.module.css';

const Thead = () => {
	return (
		<tr>
			<th className="firstCol">
				<FormattedMessage id={'rod.field.admin.status_history.change'} />
			</th>
			<th>
				<FormattedMessage id={'rod.field.admin.status_history.date_time'} />
			</th>
			<th>
				<FormattedMessage id={'rod.field.admin.status_history.change_type'} />
			</th>
			<th>
				<FormattedMessage id={'rod.field.admin.status_history.changed_by'} />
			</th>
		</tr>
	);
};

export default Thead;
