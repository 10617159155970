export const modifyTitle = (title) => {
	const exeptions = ['the', 'of', 'and', 'и'];
	const lowerTitle = title.toLowerCase();
	if (!lowerTitle.includes(' '))
		return lowerTitle.charAt(0).toUpperCase() + lowerTitle.slice(1);

	const titleAsArray = lowerTitle.split(' ');
	const modifiedTitle = titleAsArray
		.map((word) => {
			return exeptions.includes(word)
				? word
				: word.charAt(0).toUpperCase() + word.slice(1);
		})
		.join(' ');
	return modifiedTitle;
};