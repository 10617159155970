/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

import { Form } from 'containers/Layout/index';
import { ChangePassword as ChangePasswordForm } from 'forms/index';
import { withUI } from 'hocs';

import { withAuth, withRoot } from 'hocs/index';
import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
// import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './ChangePassword.module.css';

class ChangePassword extends PureComponent {
	static propTypes = {};

	submitHandler = ({ username, password }) => {
		const { authContext: { login } } = this.props;
		login({
			username,
			password,
		});
	};

	componentDidMount() {
		this.props.UIContext.showBackNavi();
	}

	componentWillUnmount() {
		this.props.UIContext.hideBackNavi();
	}

	render() {
		const { authContext: { isAuth, changePassword } } = this.props;

		if (isAuth) {
			return (<Redirect to={'/'}/>);
		}

		return (
			<div className={styles.Page}>
				<Form>
					<ChangePasswordForm onSubmit={changePassword}/>
				</Form>
			</div>
		);
	}
}

export default compose(
	withRoot,
	withAuth,
	withUI,
)(ChangePassword);
