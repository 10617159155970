// Core
import { FormattedMessage } from 'react-intl';

// Styles
import styles from '../StatusHistory.module.css';

const NoData = () => {
	return (
		<>
			<tr>
				<td colspan="4" />
			</tr>
			<tr className={styles.noData}>
				<td colspan="4">
					<FormattedMessage id={'rod.admin.no-data'} />
				</td>
			</tr>
			<tr>
				<td colspan="4" />
			</tr>
		</>
	);
};

export default NoData;
