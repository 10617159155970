// Core
import React, { useState, useContext, useEffect } from 'react';
import RichTextEditor from 'react-rte';

// Context
import { LangContext } from 'contexts/LangContext';

// Styles
import styles from './EditableText.module.css';

const EditableText = ({ initialHtml, changeField, selectPerson }) => {
	const { lang } = useContext(LangContext);

	const [editorState, setEditorState] = useState(
		initialHtml
			? RichTextEditor.createValueFromString(initialHtml, 'html')
			: RichTextEditor.createValueFromString('', 'html')
	);

	useEffect(() => {
		const updatedHtml = editorState.toString('html');
		changeField('lyrics')({ target: { value: updatedHtml } });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editorState]);

	// Add effect to reset editor state when selectPerson changes
	useEffect(() => {
		setEditorState(
			initialHtml
				? RichTextEditor.createValueFromString(initialHtml, 'html')
				: RichTextEditor.createValueFromString('', 'html')
		);
	}, [initialHtml, selectPerson]);

	const handleChange = (newState) => {
		setEditorState(newState);
	};

	const toolbarConfig = {
		display: [''],
		INLINE_STYLE_BUTTONS: [],
	};

	return (
		<div>
			<RichTextEditor
				value={editorState}
				onChange={handleChange}
				toolbarConfig={toolbarConfig}
				placeholder={
					lang === 'ru'
						? 'введите текст песни (максимум 10000 символов)'
						: 'enter the lyrics of the song (maximum 10000 characters)'
				}
				className={styles.text_editor}
				toolbarStyle={{ display: 'none' }}
			/>
		</div>
	);
};

export default EditableText;
