// Core
import React, { useEffect, useState } from 'react';
import { withUI, withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';

// UI
import { Individual, Company } from './PrivateDataType';
import { BankData } from './BankData';

// Types
import accountTypes from 'constants/accountTypes';
import { NewHelpInfo } from 'components';

// Styles
import styles from './ReportsPrivateData.module.css';

const ReportsPrivateData = ({
	transformedData,
	changeField,
	submitData,
	accountType,
	...props
}) => {
	const [errors, setErrors] = useState({});

	useEffect(() => {
		setErrors(props.errors);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.errors]);

	return (
		<>
			<div className={styles.helpInfo}>
				<NewHelpInfo text="rod.reports_page.check_data_tab.help" />
			</div>

			{accountType && accountType === accountTypes.PERSONAL && (
				<Individual
					data={transformedData}
					errors={errors}
					changeField={changeField}
				/>
			)}
			{accountType && accountType !== accountTypes.PERSONAL && (
				<Company
					data={transformedData}
					errors={errors}
					changeField={changeField}
					accountType={accountType}
				/>
			)}
			<div className={styles.bankData}>
				<BankData
					data={transformedData}
					accountType={accountType}
					changeField={changeField}
					errors={props.errors}
				/>
			</div>
		</>
	);
};

export default compose(withUI, withAuth, withRoot)(ReportsPrivateData);
