// Core
import { FormattedMessage } from 'react-intl';

// UI
import { HelpButton } from 'components/Buttons';

// Styles
import styles from './InfoTitle.module.css';

const InfoTitle = ({ title, helpTitle }) => {
	return (
		<>
			<div className={styles.info__title}>
				<FormattedMessage id={title} />
				{helpTitle && (
					<HelpButton helpTitle={helpTitle} />
				)}
			</div>
		</>
	);
};

export default InfoTitle;
