// Core
import classnames from 'classnames';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';

// UI
import { Input } from 'components/Inputs/Input';

// Icons
import chevronDownImg from 'images/chevron_down.svg';
import chevronUpImg from 'images/chevron_up.svg';
import close_background from 'images/close_background.svg';

// Styles
import styles from 'components/FilteredDropdown/FilteredDropdown.module.css';

class FilteredDropdown extends PureComponent {
	constructor(props) {
		super(props);
		this.component = React.createRef();
		this.input = React.createRef();
		this.list = React.createRef();
		this.selected = React.createRef();
		this.state = {
			highLightIndex: 0,
			filteredItems: props.items,
			isOpen: false,
			selectedValue: '',
			item: props.defaultEmpty ? null : props.items[props.defaultItemIndex],
		};
		if (props.value) {
			this.state.item = props.items.find(
				(item) => item.keyword === props.value || item.id === props.value
			);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.value !== this.props.value) {
			this.setState({
				item: this.props.items.find(
					(item) =>
						item.keyword === this.props.value || item.id === this.props.value
				),
			});
		}

		if (!isEqual(this.props.items[0], prevProps.items[0])) {
			const filteredItems = this.props.items;
			const item = prevState.item
				? filteredItems.find((item) => item.id === prevState.item.id)
				: null;
			this.setState({
				filteredItems,
				item,
			});
		}
	}

	handleSelect = (item) => (e) => {
		this.setState({
			item,
			isOpen: false,
			selectedValue: this.props.valueToString(item),
		});

		this.state.itemsToShow
			? this.props.onChange(item.logo)
			: this.props.onChange(this.props.getItemValue(item));
	};

	handleOpen = () => {
		this.setState({ isOpen: true }, () => {
			this.input.current.focus();
		});
		document.addEventListener('click', this.handleClose, true);
	};

	handleClose = (e) => {
		e.preventDefault();

		if (!this.component.current.contains(e.target)) {
			this.setState({ isOpen: false });
			document.removeEventListener('click', this.handleClose, true);
		}
	};

	componentWillUnmount() {
		document.removeEventListener('click', this.handleClose, true);
	}

	showError = (error) => {
		if (typeof this.props.showError === 'function') {
			return this.props.showError(error);
		} else {
			if (this.props.name) {
				return (
					<FormattedMessage id={`rod.error.${this.props.name}_${error.rule}`} />
				);
			} else {
				return <FormattedMessage id={`rod.error.${error.rule}`} />;
			}
		}
	};

	handleChange = (value) => {
		this.setState({
			selectedValue: value,
			highLightIndex: 0,
			filteredItems: this.getFiltered(value),
		});
	};

	getFiltered = (value = this.state.selectedValue) => {
		const { valueToString, items } = this.props;
		return value
			? items.filter(
					(item) =>
						~valueToString(item)?.toLowerCase().search(value?.toLowerCase())
			  )
			: items;
	};

	scrollToSelected = () => {
		this.list?.current?.querySelector('.selected').scrollIntoView({
			behavior: 'smooth',
			block: 'nearest',
			inline: 'nearest',
		});
	};

	handleKeyUp = (e) => {
		switch (e.key) {
			case 'ArrowUp':
				this.setState(
					{
						highLightIndex: Math.max(0, this.state.highLightIndex - 1),
					},
					this.scrollToSelected
				);
				break;
			case 'ArrowDown':
				this.setState(
					{
						highLightIndex: Math.min(
							this.state.highLightIndex + 1,
							this.state.filteredItems.length - 1
						),
					},
					this.scrollToSelected
				);
				break;
			case 'Enter':
				const item = this.state.filteredItems[this.state.highLightIndex];
				this.setState(
					{
						item,
						highLightIndex: -1,
						isOpen: false,
						selectedValue: this.props.valueToString(item),
					},
					() => {
						document.removeEventListener('click', this.handleClose, true);
						this.props.onChange(this.props.getItemValue(item));
					}
				);
				break;
			default:
				return;
		}
	};

	handleFocus = () => {
		if (!this.state.isOpen) {
			this.handleOpen();
		}
	};

	render() {
		const {
			items,
			helper,
			errors,
			className,
			getItem,
			getItemValue,
			getListItem,
			children,
			onChange,
			name,
			placeholder,
			showChevron,
			showRequired,
			valueToString,
			getItemId,
			showError,
			defaultEmpty,
			defaultItemIndex,
			itemsToShow,
			itemsHide,
			customError,
			...props
		} = this.props;
		const {
			item,
			isOpen,
			selectedValue,
			highLightIndex,
			filteredItems,
		} = this.state;
		const value = item ? getItem(item, isOpen) : null;
		const classes = classnames(
			className,
			styles.Dropdown,
			errors.length > 0 && styles.Error,
			value && styles.Filled
		);

		return (
			<div className={classes} {...props} ref={this.component}>
				<div className={styles.Input}>
					{isOpen || !value ? (
						<Input
							value={selectedValue}
							onFocus={this.handleFocus}
							onChange={this.handleChange}
							onClick={this.handleOpen}
							onKeyUp={this.handleKeyUp}
							ref={this.input}
							errors={errors}
							className={
								this.props.isCountryData
									? styles.CountryInputElement
									: styles.InputElement
							}
							placeholder={`${placeholder}${
								showRequired && props.required ? '*' : ''
							}`}
							customError={customError}
						/>
					) : itemsToShow ? (
						<ul
							className={styles.ItemsToShow}
							tabIndex={0}
							onFocus={this.handleOpen}
							onClick={this.handleOpen}
						>
							{itemsToShow.map((item) => (
								<li key={item} style={{ background: 'red' }}>
									<span>{item}</span>
									<img
										onClick={() => this.itemsHide(item)}
										src={close_background}
										alt=""
									/>
								</li>
							))}
						</ul>
					) : (
						<div
							className={styles.Value}
							tabIndex={0}
							onFocus={this.handleOpen}
							onClick={this.handleOpen}
							style={{ paddingLeft: this.props.isCountryData && '12px' }}
						>
							{value}
						</div>
					)}
				</div>

				{showChevron && (
					<div className={styles.Chevron} onClick={this.handleOpen}>
						{isOpen ? (
							<img src={chevronUpImg} alt="" />
						) : (
							<img src={chevronDownImg} alt="" />
						)}
					</div>
				)}

				{isOpen && (
					<ul className={styles.List} ref={this.list}>
						{filteredItems.map((item, index) => (
							<li
								key={item.id}
								onClick={this.handleSelect(item)}
								ref={highLightIndex === index ? this.selected : null}
								className={classnames(
									this.props.isCountryData ? styles.ItemCountry : styles.Item,
									{
										selected: highLightIndex === index,
									}
								)}
								style={{
									paddingLeft: this.props.isCountryData && '12px',
									color:
										selectedValue === item.title_ru ||
										selectedValue === item.title_en
											? '#fff'
											: '#000',
									background:
										selectedValue === item.title_ru ||
										selectedValue === item.title_en
											? 'var(--color-green)'
											: '',
								}}
							>
								{getListItem(item)}
							</li>
						))}
					</ul>
				)}
				{helper && errors.length === 0 && (
					<span className={styles.Helper}> {helper} </span>
				)}
				<input
					type="hidden"
					name={name}
					value={item ? getItemValue(item) : ''}
				/>
			</div>
		);
	}
}

FilteredDropdown.propTypes = {
	items: PropTypes.array.isRequired,
	defaultItemIndex: PropTypes.number,
	defaultEmpty: PropTypes.bool,
	getItem: PropTypes.func,
	getListItem: PropTypes.func,
	getItemValue: PropTypes.func,
	valueToString: PropTypes.func,
	onChange: PropTypes.func,
	showError: PropTypes.func,
	showChevron: PropTypes.bool,
	showRequired: PropTypes.bool,
};

FilteredDropdown.defaultProps = {
	errors: [],
	showChevron: true,
	defaultItemIndex: 0,
	getItem: (item) => item,
	getListItem: (item) => (typeof item === 'object' ? item.title : item),
	getItemValue: (item) => (typeof item === 'object' ? item.title : item),
	valueToString: (item) => (typeof item === 'object' ? item.title : item),
	onChange: () => {},
};

export default FilteredDropdown;
