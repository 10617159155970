import React from 'react';
import {
	CheckboxBlack,
	FormControlLabelText,
} from 'material-design/MuiStyled/MuiStyled';

const MuiCheckboxBlack = ({ label, onChange }) => {
	return (
		<FormControlLabelText
			control={<CheckboxBlack defaultChecked />}
			label={label}
			onChange={onChange}
		/>
	);
};

export default MuiCheckboxBlack;
