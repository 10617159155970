// Core
import React, { useContext, useEffect, useState } from 'react';
import { FormattedHTMLMessage } from 'react-intl';

// Utils
import { adjustFontSize, checkOffsetWidth } from 'utils';

// Context
import { UIContext } from 'contexts/UIContext';

// UI
import { InfoButton } from 'components/Buttons';

// Styles
import s from './MainStatsCompositions.module.css';

const MainStatsCompositions = ({ data, changeStatus, parent }) => {
	const { showModal } = useContext(UIContext);

	const [total, setTotal] = useState('');
	const [notReady, setNotReady] = useState('');
	const [ready, setReady] = useState('');
	const [disputes, setDisputes] = useState('');
	const [onVerify, setOnVerify] = useState('');

	const containersIds = ['total', 'notReady', 'ready', 'disputes', 'onVerify'];

	useEffect(() => {
		if (!isEmpty(data)) {
			setTotal(data.total);
			setOnVerify(data.verify || '');
			setNotReady(data.not_ready);
			setReady(data.ready);
			setDisputes(data.disputed);
		}
	}, [data]);

	useEffect(() => {
		const isNotFit = containersIds.some((item) =>
			checkOffsetWidth(`${item}_container`, `${item}_content`)
		);

		if (isNotFit) {
			containersIds.forEach((item) =>
				adjustFontSize(`${item}_container`, `${item}_content`, '24px')
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [total, notReady, ready, disputes, onVerify]);

	const isEmpty = (obj) => {
		for (var key in obj) {
			return false;
		}
		return true;
	};

	const onStatClickHandler = (status) => {
		changeStatus(status);
	};

	return (
		<div
			className={
				parent !== 'recordings'
					? `${s.repertoire__mainStatsItems}`
					: `${s.repertoire__mainStatsItems} ${s.repertoire__mainStatsItems_recordings} `
			}
		>
			<div
				className={s.repertoire__mainStatsItem}
				onClick={() => onStatClickHandler('')}
				id="total_container"
			>
				<FormattedHTMLMessage id={'rod.repertoire.all'} />
				{total.length === 0 ? (
					<span>-</span>
				) : (
					<span id="total_content" className={s.stats}>
						{total.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1 `)}
					</span>
				)}
			</div>
			{parent !== 'recordings' && (
				<div className={s.repertoire__mainStatsItem}>
					<InfoButton
						className={s.form__fragment_help_btn}
						onClick={showModal({
							text: (
								<FormattedHTMLMessage
									id={'rod.compositions.on_verify.fragment.help'}
								/>
							),
						})}
					/>
					<div
						className={s.repertoire__mainStatsVerifyItem}
						onClick={() => onStatClickHandler('verify')}
						id="onVerify_container"
					>
						<FormattedHTMLMessage id="rod.repertoire.draft_processing_or_verify" />
						{onVerify.length === 0 ? (
							<span>-</span>
						) : (
							<span id="onVerify_content" className={s.stats}>
								{onVerify.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1 `)}
							</span>
						)}
					</div>
				</div>
			)}
			<div
				className={s.repertoire__mainStatsItem}
				onClick={() => onStatClickHandler('not_ready')}
				id="notReady_container"
			>
				<FormattedHTMLMessage id={'rod.repertoire.not_ready'} />
				{notReady.length === 0 ? (
					<span>-</span>
				) : (
					<span id="notReady_content" className={s.stats}>
						{notReady.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1 `)}
					</span>
				)}
			</div>
			<div
				className={s.repertoire__mainStatsItem}
				onClick={() => onStatClickHandler('ready')}
				id="ready_container"
			>
				<FormattedHTMLMessage id={'rod.repertoire.ready'} />
				{ready.length === 0 ? (
					<span>-</span>
				) : (
					<span id="ready_content" className={s.stats}>
						{ready.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1 `)}
					</span>
				)}
			</div>
			<div
				className={s.repertoire__mainStatsItem}
				onClick={() => onStatClickHandler('disputed')}
				id="disputes_container"
			>
				<FormattedHTMLMessage id={'rod.repertoire.in_dispute'} />
				{disputes.length === 0 ? (
					<span
						className={Number(disputes) !== 0 ? s.disputes : s.disputes__empty}
					>
						-
					</span>
				) : (
					<span
						id="disputes_content"
						className={Number(disputes) !== 0 ? s.disputes : s.disputes__empty}
					>
						{disputes.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1 `)}
					</span>
				)}
			</div>
		</div>
	);
};

export default MainStatsCompositions;
