// Core
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

// Context
import { UIContext } from 'contexts/UIContext';
import { RootContext } from 'contexts/RootContext';

// UI
import { Loading } from 'components';
import CloseIcon from '@mui/icons-material/Close';
import add_box_line from 'images/add_box_line.svg';
import CmoBlock from './CmoBlock/CmoBlock';

// Styles
import s from './RepertoireCmoModal.module.css';

const RepertoireCmoModal = ({
	active,
	setActive,
	selectPersonId,
	type = 'release',
}) => {
	const {
		contributor,
		setReleaseAuthorSocieties,
		setCompositionAuthorSocieties,
	} = useContext(UIContext);
	const {
		getAuthorSocieties,
		getCompositionAuthorSocieties,
		deleteAuthorSociety,
		deleteCompositionAuthorSociety,
	} = useContext(RootContext);
	const releaseId = localStorage.getItem('releaseId');
	const compositionId = localStorage.getItem('compositionId');
	const accountId = localStorage.getItem('accountId');

	const [loading, setLoading] = useState(false);
	const [cmoSocieties, setCmoSocieties] = useState([]);
	const [expanded, setExpanded] = useState(null);
	const [disabled, setDisabled] = useState(false);

	useEffect(() => {
		if (active) {
			document.body.style.top = `-${window.scrollY}px`;
		}
		if (!active) {
			const scrollY = document.body.style.top;
			document.body.style.position = '';
			document.body.style.top = '';
			window.scrollTo(0, parseInt(scrollY || '0') * -1);
			setDisabled(false);
			setExpanded(null);
		}
	}, [active]);

	useEffect(() => {
		const fetchData = async () => {
			if (!selectPersonId || !contributor || !contributor.id) {
				setCmoSocieties([]);
				setLoading(false);
				return;
			}
			try {
				setLoading(true);
				const { data } =
					type === 'release'
						? await getAuthorSocieties(
								releaseId,
								selectPersonId,
								contributor.id,
								contributor.type
						  )
						: await getCompositionAuthorSocieties(
								compositionId,
								contributor.id,
								contributor.type
						  );
				if (data?.data.length) {
					setCmoSocieties(data.data);
				} else {
					createFirstCmoSociety();
				}
			} catch (error) {
				console.error('Error fetching author societies:', error);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [releaseId, selectPersonId, contributor, compositionId]);

	useEffect(() => {
		if (cmoSocieties.length >= 5) {
			setDisabled(true);
		}
		if (cmoSocieties.length < 5 && expanded === null) {
			setDisabled(false);
		}

		if (!cmoSocieties.length) {
			createFirstCmoSociety();
			setReleaseAuthorSocieties(false);
			setCompositionAuthorSocieties(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cmoSocieties.length, expanded]);

	useEffect(() => {
		setExpanded(null);
	}, []);

	const createFirstCmoSociety = () => {
		setCmoSocieties([
			{
				id: uuidv4(),
				account_id: accountId,
				contributor_id: contributor.id,
				type: contributor.type,
				name: '',
				external_society_id: null,
				contract_date: null,
				// для радиогруппы фронта, на бэк отправляем поля country_codes / exclusive_territories
				territories: '17',
				created_at: '',
				updated_at: '',
			},
		]);
		setExpanded(0);
		setDisabled(true);
	};

	const onAddNewSociety = () => {
		if (cmoSocieties.length < 5) {
			setCmoSocieties((cmoSocieties) => [
				...cmoSocieties,
				{
					id: uuidv4(),
					account_id: accountId,
					contributor_id: contributor.id,
					type: contributor.type,
					name: '',
					external_society_id: null,
					contract_date: null,
					// для радиогруппы фронта, на бэк отправляем поля country_codes / exclusive_territories
					territories: '17',
					created_at: '',
					updated_at: '',
				},
			]);

			setExpanded(cmoSocieties.length);
			setDisabled(true);
		}
	};

	const onDeleteCmoSociety = async (e, id) => {
		e.stopPropagation();
		setDisabled(false);
		if (typeof id === 'string') {
			const filteredSocieties = cmoSocieties.filter((cmo) => cmo.id !== id);
			setCmoSocieties(filteredSocieties);
		} else {
			try {
				type === 'release'
					? await deleteAuthorSociety(
							releaseId,
							selectPersonId,
							contributor.id,
							id
					  )
					: await deleteCompositionAuthorSociety(
							compositionId,
							contributor.id,
							id
					  );
				const filteredSocieties = cmoSocieties.filter((cmo) => cmo.id !== id);
				setCmoSocieties(filteredSocieties);
			} catch (error) {
				console.error('Error of delete author society', error);
			}
		}
	};

	return (
		<>
			<div className={active ? `${s.Overlay} ${s.OverlayActive}` : s.Overlay} />
			<div
				className={active ? `${s.modal} ${s.active}` : s.modal}
				onMouseDown={() => setActive(false)}
			>
				<div className={s.container} onMouseDown={(e) => e.stopPropagation()}>
					<div className={s.modalHeader}>
						<div className={s.modalHeader__titleWrapper}>
							<div className={s.modalHeader__icon}>
								<CloseIcon
									fontSize="small"
									onClick={() => setActive(false)}
									style={{ cursor: 'pointer' }}
								/>
							</div>
						</div>
					</div>
					{loading ? (
						<Loading non_margin={true} />
					) : (
						<div className={s.modalBody}>
							<div className={s.bodyHeader}>
								<span className={s.bodyHeaderTitle}>
									<FormattedMessage id={'rod.release.cmo.settings.title'} />
								</span>
								<button
									className={disabled ? s.disabled : ''}
									onClick={onAddNewSociety}
									disabled={disabled}
								>
									<img src={add_box_line} alt="" className={s.addCmoImg} />
									<span className={s.addCmoBtn}>
										<FormattedMessage id={'rod.release.cmo.settings.add_cmo'} />
									</span>
								</button>
							</div>
							<CmoBlock
								selectPersonId={selectPersonId}
								cmoSocieties={cmoSocieties}
								setCmoSocieties={setCmoSocieties}
								onDeleteCmoSociety={onDeleteCmoSociety}
								expanded={expanded}
								setExpanded={setExpanded}
								setDisabled={setDisabled}
								type={type}
							/>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default RepertoireCmoModal;
