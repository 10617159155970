/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 02/03/2021, 20:12
 */
// eslint-disable-next-line
const fields = {
	/* Auth */
	'rod.field.firstname': 'Имя',
	'rod.field.firstname-small_letter': 'имя',
	'rod.field.lastname': 'Фамилия',
	'rod.field.lastname-small_letter': 'фамилия',
	'rod.field.id': 'id',
	'rod.field.patronymic': 'Отчество',
	'rod.field.no_patronymic': 'у меня нет отчества',
	'rod.field.birthdate': 'Дата рождения',
	'rod.field.birthplace': 'Место рождения',
	'rod.field.birth_place': 'Место рождения (город, страна)',
	'rod.field.registration_address': 'Адрес регистрации',
	'rod.field.registration_address.street_house':
		'Адрес регистрации (улица, дом)',
	'rod.field.living_place': 'Место жительства (город, страна)',
	'rod.field.passport': 'Серия и номер паспорта',
	'rod.field.passport_issuer': 'Kем выдан',
	'rod.field.passport_issue_date': 'Дата выдачи',
	'rod.field.passport_expire_date': 'Действителен до',
	'rod.field.tax_number': 'ИНН',

	// 'rod.field.birthdate':'',
	'rod.field.name': 'Имя',
	'rod.field.email': 'Эл. почта',
	'rod.field.email.en': 'Email',
	'rod.field.phone': 'Телефон',
	'rod.field.password': 'Пароль',
	'rod.field.password.confirmation': 'Подтвердите пароль',
	'rod.field.password.tip':
		'требования к паролю:<br/><br/>— не меньше 6 символов<br/> — буквы латинского алфавита<br/> — хотя бы одну цифру<br/> — используйте заглавные и строчные буквы',
	'rod.field.new_password': 'Новый пароль',
	'rod.field.new_password_again': 'Повторите новый пароль',
	'rod.field.confirm': 'подтвердите',
	'rod.field.sms_code': 'SMS код',
	'rod.field.verify_email_code': 'код из письма',
	'rod.field.comment': 'комментарий',
	'rod.field.society': 'Название авторского общества',
	'rod.field.ipi': 'IPI номер',
	'rod.field.ipi.help':
		'<b>IPI номер</b> - международный идентификационный номер Автора слов, Автора музыки или Музыкального Издателя.',
	'rod.field.number_ipi': 'номер IPI',
	'rod.field.ipn': 'Номер IPN',
	'rod.field.ipn.help':
		'<b>IPN номер</b> - международный идентификационный номер Исполнителя.',
	'rod.field.isni': 'Номер ISNI',
	'rod.field.isni.help':
		'<b>ISNI</b> - международный стандартный идентификатор имени, используемый многочисленными библиотеками, издателями, базами данных и организациями по управлению правами по всему миру, представляет собой 16-значный номер, также известный как «творческий паспорт», который связывает автора с его произведениями. ',
	'rod.field.number': 'номер',
	'rod.field.country': 'Страна',
	'rod.field.residence_country': 'Страна резидентства',

	// 'rod.field.territories': 'Территории',
	'rod.field.territories.included': 'Территории',
	'rod.field.territories.excluded': 'Исключения',
	'rod.field.edit': 'редактировать',
	'rod.field.sms.helper': 'отправить код повторно через',
	'rod.field.email.change': 'изменить e-mail',
	'rod.field.email.helper': 'изменить e-mail можно через',
	'rod.field.phone.change': 'изменить номер',
	'rod.field.phone.helper': 'изменить номер телефона можно через',
	'rod.field.repertoire_count': 'Кол-во произведений',
	'rod.field.company': 'Название компании',
	'rod.field.sex.male': 'Я - Мужчина',
	'rod.field.sex.female': 'Я - Женщина',
	'rod.field.upload': '<a>выберите</a> или перетяните файлы документов',
	'rod.field.upload.title': 'загрузка каталога',
	'rod.field.upload.catalog.type': 'тип каталога',

	'rod.field.upload.catalog.type.new': 'новый каталог',
	'rod.field.upload.catalog.type.update': 'обновление каталога',
	'rod.field.upload.catalog.type.remove': 'изъятие каталога',

	'rod.field.upload.files.title': 'загрузка файлов',
	'rod.field.upload.zip': '<a>загрузите</a> или перетяните файлы документов',
	'rod.field.upload_v2': '<a>Загрузите</a> или перетяните файл фонограммы',
	'rod.field.upload_server_error':
		'Не удалось загрузить файл из-за ошибки сети. <a>Повторить попытку.</a>',
	'rod.field.upload_repetroire':
		'<a>Загрузите</a> или перетяните заполненный репертуарный файл',
	'rod.field.update.error.file':
		'Не удалось загрузить документ. Проверьте формат файла.',
	'rod.field.upload.error.title': 'Не удалось загрузить документ',
	'rod.field.upload.error.file':
		'Проверьте формат файла, а затем <a>повторите попытку</a>',
	'rod.field.upload.error.file_type':
		'Проверьте формат файла, а затем <a>повторите попытку</a>',
	'rod.field.upload.error.sign_doc.file_type':
		'Недопустимый формат. <a>Используйте</a> pdf',
	'rod.field.upload.error.track_format': 'Не удалось загрузить файл {name}',
	'rod.field.upload.error.track.single':
		'Для данного типа релиза доступен только один трек. При необходимости, измените тип релиза',
	'rod.field.upload.error.track.album':
		'Для типа релиза "Альбом" должно быть не менее 2-х и не более 40 треков. Вы можете вернуться на первый шаг и выбрать тип релиза, соответствующий по количеству треков.',
	'rod.field.upload.error.track.compilation':
		'Для типа релиза "Компиляция" должно быть не менее 2-х и не более 40 треков. Вы можете вернуться на первый шаг и выбрать тип релиза, соответствующий по количеству треков.',
	'rod.field.upload.error.track.ep':
		'Для типа релиза "ЕР" должно быть не менее 2-х и не более 7 треков. Вы можете вернуться на первый шаг и выбрать тип релиза, соответствующий по количеству треков.',

	'rod.field.upload.error.size-mb':
		'Максимальный размер файла {size} мегабайт, пожалуйста <a>повторите попытку</a>',
	'rod.field.upload.error.size':
		'Максимальный размер файла {size}, пожалуйста <a>повторите попытку</a>',
	'rod.field.upload.textarea.error.max_count':
		'Превышен лимит {value} символов',

	'rod.field.upload.error.required':
		'Пожалуйста, загрузите файл подписанного контракта в формате pdf и <a>повторите попытку</a>',
	'rod.field.personal_entrepreneur.name': 'Название ИП',
	'rod.field.personal_entrepreneur.name.helper':
		'Например: Иванов Иван Иванович, ИП',
	'rod.field.personal_entrepreneur.signer': 'Подписант в лице',
	'rod.field.personal_entrepreneur.signer.helper':
		'Например: Иванова Ивана Ивановича',
	'rod.field.personal_entrepreneur.signer_short_name': 'Краткое имя подписанта',
	'rod.field.personal_entrepreneur.signer_short_name.helper':
		'Например: Иванов И.И.',
	'rod.field.personal_entrepreneur.registration_date': 'Дата регистрации ИП',
	'rod.field.personal_entrepreneur.registration_place': 'Страна регистрации ИП',
	'rod.field.personal_entrepreneur.registration_number':
		'Регистрационный номер ИП',
	'rod.field.personal_entrepreneur.tax_number': 'ИНН',
	'rod.field.company.name': 'Название организации',
	'rod.field.company.name.helper': 'Например: Брома16, OOO',
	'rod.field.company.signer': 'Подписант в лице',
	'rod.field.company.signer_position': 'Должность подписанта',
	'rod.field.company.signer.helper': 'Например: Иванова Ивана Ивановича',
	'rod.field.company.signer_short_name': 'Краткое имя подписанта',
	'rod.field.company.signer_short_name.helper': 'Например: Иванов И.И.',
	'rod.field.company.attorney': 'Номер и дата доверенности',
	'rod.field.company.signer_rights': 'Действующий на основании',
	'rod.field.company.registration_date': 'Дата регистрации компании',
	'rod.field.company.registration_place': 'Страна регистрации',
	'rod.field.company.registration_number': 'Регистрационный номер компании',
	'rod.field.company.tax_number': 'ИНН',
	'rod.field.company.tax_number2': 'Идентификационный номер НДС',
	'rod.field.company.tax_number_register': 'ИНН / Регистрационный номер',
	'rod.field.zip': 'Индекс',
	'rod.field.website': 'Адрес сайта',
	'rod.field.company_website': 'Адрес сайта компании',
	'rod.field.address': 'Адрес (город, улица, дом, номер офиса)',
	'rod.field.address_noCity': 'Адрес (улица, дом, номер офиса)',
	'rod.field.address_withCity': 'Адрес (город, улица, дом, номер офиса)',
	'rod.field.address_short': 'Адрес',
	'rod.field.agreement': 'Соглашение',
	'rod.field.statute': 'Устав',
	'rod.field.attorney': 'Доверенность',
	'rod.field.skip_step': 'пропустить или заполнить позже',
	'rod.field.contract_territories': 'Начните вписывать название страны',

	'rod.field.payment_type': 'Метод оплаты',
	'rod.field.bank': 'Банковские реквизиты',
	'rod.field.paypal': 'PayPal',
	'rod.field.paypal_account': 'PayPal адрес',
	'rod.field.paypal_account.helper': 'Например: paypal.me/UserName',
	'rod.field.paypal_account.pay_method': 'метод оплаты',
	'rod.field.beneficiary_name': 'Имя получателя',
	'rod.field.bank_account_name': 'Номер счета получателя',
	'rod.field.bank_name': 'Название банка',
	'rod.field.bank_country': 'Страна отделения банка',
	'rod.field.swift_code': 'SWIFT/БИК',
	'rod.field.swift': 'SWIFT/БИК',
	'rod.field.bank_address': 'Адрес банка',
	'rod.field.bank_department_address': 'Адрес банка',
	'rod.field.cor_account': 'Корреспондентский счет',
	'rod.field.cor_swift': 'SWIFT/БИК банка корреспондента',

	'rod.field.pseudonym': 'Псевдоним',
	'rod.field.pseudonym.error': 'Максимальная длина - 255 символов',
	'rod.field.pseudonym-small_letter': 'псевдоним',
	'rod.field.add_outlet': 'добавить витрину',
	'rod.field.from_date': 'С какой даты',
	'rod.field.contracts.duration':
		'{count, number} {count, plural, one {месяц} few {месяца} other {месяцев}}',
	'rod.field.contracts.currency.RUB': 'Рубли',
	'rod.field.contracts.currency.EUR': 'Евро',
	'rod.field.contracts.currency.USD': 'Доллары США',
	'rod.field.contracts.currency.GBP': 'Британские Фунты',

	'rod.field.contracts.sign_types.physical': 'Ручное',
	'rod.field.contracts.sign_types.sms': 'SMS',

	'rod.field.contracts.agreed1':
		'я обладаю полномочиями и не имею препятствий к подписанию контракта',
	'rod.field.contracts.agreed2': 'я согласен с условиями контракта',

	'rod.field.payments.agreed1':
		'Я обладаю полномочиями и не имею препятствий к подписанию счета',
	'rod.field.payments.agreed2': 'Я согласен(-а) с данными счета',

	'rod.field.contracts.contract_status.error': 'Ошибки',
	'rod.field.confirm.checkbox':
		'подтверждаю, что все предоставленные данные корректны и являются действительными',
	'rod.field.lang': 'Язык общения',
	'rod.field.lang.ru': 'Русский',
	'rod.field.lang.en': 'Английский',
	'rod.field.role': 'роли',
	'rod.field.outlet': 'витрина',

	// -------------adminV2----------------

	'rod.field.admin.acc-created': 'аккаунт создан',
	'rod.field.admin.phone': 'телефон',
	'rod.field.admin.email': 'почта',
	'rod.field.admin.role-selection': 'выбор роли',
	'rod.field.admin.personal-data': 'данные',
	'rod.field.admin.contact-data': 'контакты',
	'rod.field.admin.documents': 'документы',
	'rod.field.admin.identifiers': 'идентификаторы',
	'rod.field.admin.payment-details': 'платежная информация',
	'rod.field.admin.check-documents': 'проверка',
	'rod.field.admin.conditions': 'условия',
	'rod.field.admin.revision': 'ревизия',
	'rod.field.admin.signing': 'подписание',
	'rod.field.admin.contracts-revision': 'проверка договора',
	'rod.field.admin.repertoire': 'репертуар',

	'rod.field.admin.user': 'пользователь',
	'rod.field.admin.account-creation': 'создание аккаунта',
	'rod.field.admin.contract': 'договор',

	'rod.field.admin.title': 'наименование',

	'rod.field.admin.last-active': 'активность',

	'rod.field.admin.accounts-to-check': 'аккаунты на проверке',
	'rod.field.admin.accounts-moderation-requests': 'запросы на модерацию счета',

	'rod.field.admin.removal-and-reloading': 'снятия и переотгрузки',
	'rod.field.admin.release-editing': 'редактирование релиза',

	'rod.field.admin.status_history.change': 'Изменение',
	'rod.field.admin.status_history.date_time': 'Дата и время',
	'rod.field.admin.status_history.change_type': 'Тип изменения',
	'rod.field.admin.status_history.changed_by': 'Автор изменения',

	'rod.field.right_type': 'тип прав',
	'rod.field.admin.statistics_service.not_ready': 'показать только не готовые',
	'rod.field.admin.statistics_service.outlet': 'витрина',
	'rod.field.admin.statistics_service.date': 'дата',
	'rod.field.admin.statistics_service.files': 'файлы',
	'rod.field.admin.statistics_service.identification': 'идентификация',
	'rod.field.admin.statistics_service.statistic': 'статистика',
	'rod.field.admin.statistics_service.statistic_upper': 'статистика',
	'rod.field.admin.statistics_service.rules': 'права',
	'rod.field.admin.statistics_service.processing_date': 'дата обработки',
	'rod.field.admin.statistics_service.errors': 'ошибки',

	'rod.field.admin.language': 'Язык',
	'rod.field.admin.actions': 'действия',

	'rod.field.admin.reports.publishing_date': 'Дата публикации',
	'rod.field.admin.report.published': 'опубликован',
	'rod.field.admin.reports.made': 'создан',
	'rod.field.admin.reports.release_date': 'дата релиза',
	'rod.field.admin.reports.sum': 'сумма',
	'rod.field.admin.reports.status': 'статус',
	'rod.field.admin.reports.currency': 'валюта',
	'rod.field.admin.reports.royalty_to_pay': 'роялти к выплате',
	'rod.field.admin.reports.royalty_to_pay.whithout_partners':
		'роялти последнего периода',
	'rod.field.admin.reports.next_report_date': 'дата следующего отчета',
	'rod.field.admin.reports.acc_id': 'ID&nbsp;аккаунта',

	'rod.account.payment.status.new': 'новый',
	'rod.account.payment.status.signing': 'подписание',
	'rod.account.payment.status.signed': 'подписан',
	'rod.account.payment.status.moderation': 'на модерации',
	'rod.account.payment.status.rejected': 'отклонено',
	'rod.account.payment.status.in_processing': 'в обработке',
	'rod.account.payment.status.paid': 'оплачено',
	'rod.account.payment.status.not_paid': 'не оплачено',
	'rod.account.payment.amount': 'сумма к выплате',
	'rod.account.payment.payment_date': 'дата оплаты',
	'rod.account.payment.invoice_date': 'дата создания',
	'rod.account.payment.invoice_title': 'название',
	'rod.account.payment.payment_title': 'наименование отчета',
	'rod.account.payment.payment_type': 'счет',
};

export default fields;
