// Core
import { FormattedMessage } from 'react-intl';

// Utils
import { getBalance } from 'utils';

// Styles
import styles from '../Statistics.module.css';

const Balance = ({ balance }) => {
	return (
		<div id="balance_container" className={styles.container__item}>
			<span>
				<FormattedMessage id={'rod.main_page.balance'} />
			</span>

			<span
				id="balance_content"
				className={
					balance && Object.keys(balance).length !== 0 ? styles.stats : null
				}
			>
				{getBalance(balance)}
			</span>
		</div>
	);
};

export default Balance;
