export const isUnknownStep = (step) => {
	const allKnownStatuses = [
		'moderation',
		'completed',
		'parsed',
		'check',
		'update',
		'release',
		'file',
		'tracks',
		'authors',
		'lyrics',
		'cover',
		'distribution',
		'confirm',
		'info',
		'authors_shares',
		'composition_recordings',
	];
	return !allKnownStatuses.includes(step);
};
