/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/06/2020, 07:11
 */

import { Button } from 'components/Buttons/Button';
import { Label } from 'components/Labels/Label';

import { withAuth, withRoot, withUI } from 'hocs';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
// import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './Success.module.css';

class Success extends PureComponent {
	static propTypes = {};

	componentDidMount() {
		// this.props.UIContext.showBackNavi();
	}

	componentWillUnmount() {
		// this.props.UIContext.hideBackNavi();
	}

	handleBack = () => {
		this.props.history.push('/');
	};

	render() {
		return (
			<div className={styles.Page}>
				<div className={styles.Form}>
					<div className={styles.Title}>
						<Label font="--gilroy-Medium-40" black>
							<FormattedMessage id={'rod.registration.success.header'} />
						</Label>
						<Label font="--gilroy-Medium-24" black>
							<FormattedMessage id={'rod.registration.success.subheader'} />
						</Label>
					</div>
					<div className={styles.Controls}>
						<Button
							className={styles.Button}
							text={<FormattedMessage id={'rod.action.to_index_page'} />}
							type={'submit'}
							variant={'primary'}
							onClick={this.handleBack}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default compose(withRoot, withAuth, withUI, withRouter)(Success);
