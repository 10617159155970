// UI
import { DropZone } from 'components/Upload/DropZone';
import ErrorsBlock from './ErrorsBlock/ErrorsBlock';
import UploadedFile from './UploadedFile/UploadedFile';

// Styles
import styles from './MultipleUploader.module.css';

const MultipleUploader = ({
	maxFilesCount,
	maxSize,
	accept,
	handleFileUpload,
	countFilesError,
	handleFileRemove,
	uploadedFiles,
	errors,
}) => {
	return (
		<div className={styles.uploadBlock}>
			<DropZone
				onUpload={handleFileUpload}
				files={uploadedFiles}
				accept={accept}
				dropzoneId={'rod.field.upload.zip'}
				maxSize={maxSize}
				kb
			/>
			{(countFilesError || (errors && Object.keys(errors).length > 0)) && (
				<ErrorsBlock
					errors={errors}
					countFilesError={countFilesError}
					maxFilesCount={maxFilesCount}
				/>
			)}
			{uploadedFiles && uploadedFiles.length > 0 && (
				<div className={styles.filesBlock}>
					{uploadedFiles.map((file) => (
						<UploadedFile
							key={file.id}
							file={file}
							handleFileRemove={() => handleFileRemove(file.id)}
						/>
					))}
				</div>
			)}
		</div>
	);
};

export default MultipleUploader;
