// Constants
import accountCompletionSteps from 'constants/accountCompletionSteps';

const STATUSES = {
	ERROR: 'error',
	CHECKED: 'checked',
	PENDING: 'pending',
	COMPLETE: 'complete',
};

export const getIcon = (status = STATUSES.CHECKED) => {
	let content;
  
	switch (status) {
		case STATUSES.CHECKED:
			content = (
				<path
					d="M0 4.31906L3.42444 7.75004L10 1.18098L9.05597 0.25L3.42444 5.875L0.930956 3.38153L0 4.31906Z"
					fill="#BFBFC1"
				/>
			);
			break;
		case accountCompletionSteps.PERSONAL_AREA:
		case accountCompletionSteps.PENDING:
		case accountCompletionSteps.PENDING_CONTRACT:
			content = (
				<path
					d="M9 5C9 7.20914 7.20914 9 5 9C2.79086 9 1 7.20914 1 5C1 2.79086 2.79086 1 5 1C7.20914 1 9 2.79086 9 5Z"
					stroke="#F8C510"
					strokeWidth="2"
				/>
			);
			break;

		case accountCompletionSteps.DECLINED_DOCUMENTS:
		case accountCompletionSteps.DECLINED_CONTRACTS:
		case STATUSES.ERROR:
			content = (
				<path
					d="M9 5C9 7.20914 7.20914 9 5 9C2.79086 9 1 7.20914 1 5C1 2.79086 2.79086 1 5 1C7.20914 1 9 2.79086 9 5Z"
					stroke="#DE4535"
					strokeWidth="2"
				/>
			);
			break;

		default:
			content = (
				<path
					d="M9 5C9 7.20914 7.20914 9 5 9C2.79086 9 1 7.20914 1 5C1 2.79086 2.79086 1 5 1C7.20914 1 9 2.79086 9 5Z"
					stroke="#50813D"
					strokeWidth="2"
				/>
			);
	}
	return (
		<svg
			width="10"
			height="10"
			viewBox="0 0 10 10"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			{content}
		</svg>
	);
};
