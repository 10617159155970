// Core
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { RootContext } from 'contexts/RootContext';
import { UIContext } from 'contexts/UIContext';

// Utils
import { fm } from 'utils';
import { isDataValidOrDash } from './utils/isDataValidOrDash';
import { renderPersonalData } from './utils/renderPersonalData';
import { renderSoleProprietorData } from './utils/renderSoleProprietorData';
import update from 'immutability-helper';
import { withUI, withRoot } from 'hocs';
import { compose } from 'recompose';
import { statusLocalization } from './utils/statusLocalization';
import { getTooltip } from './utils/getTooltip';
import { getPhoneNumber } from './utils/getPhoneNumber';

// UI
import TableHead from './TableHead/TableHead';
import Field from 'pages/adminV2/components/Field/Field';
import { Loading } from 'components';
import { Bullet } from 'components/Bullet';
import { Button } from 'components/Buttons';
import { Checkbox } from 'components/Checkboxes';
import { Label } from 'components/Labels';
import PaymentsWithdrawal from './PaymentsWithdrawal/PaymentsWithdrawal';
import RoundedButton from 'components/Buttons/RoundButton/RoundButton';
import PrivilegeModal from './PrivilegeModal/PrivilegeModal';

// Constants
import accountCompletionSteps from 'constants/accountCompletionSteps';
import accountTypes from 'constants/accountTypes';

// Styles
import styles from './AccountData.module.css';

function AccountData(props) {
	const { isAllowLanguageDetection } = useContext(AuthContext);
	const {
		getCountryById,
		getCountryDialingCode,
		getDocumentTitle,
		setAccountStep,
		switchToPhysical,
		getMvpUserData,
		getSignupsUserData,
		isAdminRedirectLocale,
	} = useContext(RootContext);

	const { isPaymentsEnabled } = useContext(UIContext);

	const { isSignup } = props;
	const [modalActive, setModalActive] = useState(false);
	const [modalType, setModalType] = useState('');
	const [loading, setLoading] = useState(true);
	const [account, setAccount] = useState({});
	const [additionalContracts, setAdditionalContracts] = useState([]);
	const [moderationStatus, setModerationStatus] = useState({});
	const [prevCompletionStep, setPrevCompletionStep] = useState(null);
	const [completionStep, setCompletionStep] = useState(null);
	const [isSubmitPressed, setIsSubmitPressed] = useState(false);
	const [additionalSubmitBtnData, setAdditionalSubmitBtnData] = useState({});
	const [warnBlockNum, setWarnBlockNum] = useState(0);
	const [warnBlockNumAddContract, setWarnBlockNumAddContract] = useState(0);

	const updateCompletionStep = (
		account,
		completionStep,
		newModerationStatus
	) => {
		switch (completionStep) {
			case accountCompletionSteps.PENDING:
			case accountCompletionSteps.APPROVED:
			case accountCompletionSteps.DECLINED_DOCUMENTS:
				if (Object.keys(account.files).length > 0) {
					const arr = Object.keys(account.files)
						.map((key) => [
							newModerationStatus[key].valid,
							newModerationStatus[key].quality,
						])
						.flat();
					const result = arr.reduce((acc, mark) => acc && mark);

					if (result) {
						setCompletionStep(accountCompletionSteps.APPROVED);
					} else {
						setCompletionStep(accountCompletionSteps.DECLINED_DOCUMENTS);
					}
				}
				break;
			case accountCompletionSteps.APPROVED_CONTRACTS:
			case accountCompletionSteps.DECLINED_CONTRACTS:
			case accountCompletionSteps.PERSONAL_AREA:
			case accountCompletionSteps.REPERTOIRE_APPLY:
			case accountCompletionSteps.PENDING_CONTRACT:
				if (Object.keys(account.contracts).length > 0) {
					const arr = Object.keys(account.contracts)
						.map((key) => [
							newModerationStatus[key].valid,
							newModerationStatus[key].signature,
							newModerationStatus[key].quality,
						])
						.flat();

					const result = arr.reduce((acc, mark) => acc && mark);

					if (result) {
						setCompletionStep(accountCompletionSteps.APPROVED_CONTRACTS);
					} else {
						setCompletionStep(accountCompletionSteps.DECLINED_CONTRACTS);
					}
				}
				break;
			default:
				return;
		}
	};

	const switchSignToPhysical = (id) => {
		setLoading(true);

		switchToPhysical(id)
			.then((response) => {
				account.step = accountCompletionSteps.SIGN_PHYSICAL;
				account['allow_sign_change'] = false;
				setAccount({ ...account });
			})
			.catch((error) => {
				console.error(
					'switchToPhysical - api - error.response: ',
					error.response
				);
				return alert(`Не удалось изменить тип подписания`);
			})
			.finally(() => setLoading(false));
	};

	const toggleModerationStatus = (field, status) => (event) => {
		const value = event.currentTarget.checked;
		const newModerationStatus = update(moderationStatus, {
			[field]: { [status]: { $set: value } },
		});
		setModerationStatus(newModerationStatus);
		updateCompletionStep(account, completionStep, newModerationStatus);
	};

	const handleSubmit = () => {
		if (isSubmitPressed) return;
		setIsSubmitPressed(true);

		if (additionalContracts.length) {
			setAccountStep(props.id, {
				completion_step: completionStep,
				moderation_status: moderationStatus,
			})
				.then((res) => {
					statusLocalizationAdditional()
						? setAdditionalSubmitBtnData({
								message: fm('rod.admin.account.files.approved'),
								disabled: true,
						  })
						: setAdditionalSubmitBtnData({
								message: fm('rod.admin.account.files.sent'),
								disabled: true,
						  });
				})
				.catch((err) => {
					console.info('Error:', err);
				});
		} else {
			setAccountStep(props.id, {
				completion_step: completionStep,
				moderation_status: moderationStatus,
			})
				.then((res) => {
					setPrevCompletionStep(completionStep);
				})
				.catch((err) => {
					setPrevCompletionStep(completionStep); //todo
				});
		}
	};

	useEffect(() => {
		if (isSignup) {
			getSignupsUserData(props.id).then((res) => console.info('signup', res));
		}

		getMvpUserData(props.id).then((res) => {
			setAccount(res);
			const additional = Object.keys(res.contracts).filter(
				(key) => res.contracts[key].additional
			);
			if (additional.length) setAdditionalContracts(additional);

			setLoading(false);
			setModerationStatus(res.moderation_status);
			setCompletionStep(res.step);
			setPrevCompletionStep(res.step);
			updateCompletionStep(res, res.step, res.moderation_status);
		});
		// eslint-disable-next-line
	}, []);

	if (loading) {
		return <Loading />;
	}

	const isDocumentsControlsEnabled =
		prevCompletionStep === accountCompletionSteps.PENDING;
	const isContractsControlsEnabled =
		prevCompletionStep === accountCompletionSteps.PENDING_CONTRACT;

	const statusLocalizationAdditional = () => {
		let count = [];
		Object.keys(account.contracts).map((key) => {
			if (account.contracts[key].additional) {
				if (
					!moderationStatus[key]['quality'] ||
					!moderationStatus[key]['signature'] ||
					!moderationStatus[key]['valid']
				) {
					count.push(moderationStatus[key]);
				}
			}
		});
		return !count.length ? true : false;
	};

	const isAdditionalBtnDisabled = () => {
		const isSigned = [];
		Object.keys(account.contracts).map((key) => {
			if (account.contracts[key].additional) {
				if (account.contracts[key].additional_contracts_signed) {
					isSigned.push(account.contracts[key]);
				}
			}
		});
		return !isSigned.length || additionalSubmitBtnData.disabled ? true : false;
	};

	const handleChangeLocale = (e) => {
		if (e.target.checked) {
			account.redirect = 1;
			setAccount({ ...account });
		} else {
			account.redirect = 0;
			setAccount({ ...account });
		}
		isAdminRedirectLocale(props.id, account.redirect)
			.then()
			.catch((err) => {
				console.error('Error:', err);
			});
	};

	const handleWarningBlockShow = (e, key) => {
		const isAddintionalContractSelected =
			e.currentTarget.getAttribute('data-name').length === 3;
		const isMainApproved = additionalContracts.length > 0;

		if (isMainApproved && !isAddintionalContractSelected) {
			return;
		}

		if (
			!account.contracts[key].additional_contracts_signed &&
			isAddintionalContractSelected &&
			!moderationStatus[key]['signature']
		) {
			setWarnBlockNumAddContract(e.currentTarget.getAttribute('data-name'));
			setTimeout(() => {
				setWarnBlockNumAddContract('0');
			}, 2000);
		} else if (
			!isMainApproved &&
			!isContractsControlsEnabled &&
			!isAddintionalContractSelected &&
			!moderationStatus[key]['signature']
		) {
			setWarnBlockNum(e.currentTarget.getAttribute('data-name'));
			setTimeout(() => {
				setWarnBlockNum('0');
			}, 2000);
		}
	};

	const setModalData = (type, modal = true) => {
		setModalType(type);
		setModalActive(modal);
	};

	return (
		<Container fluid className={styles.grid}>
			<Row>
				<Col sm={10} offset={{ sm: 1 }} className={styles.container}>
					<div className={styles.Page}>
						<section className={styles.Page__title}>
							<div className={styles.title}>
								{account.personal_data ? account.personal_data.title : ''}
							</div>
							<div className={styles.status}>
								<span>
									{account.personal_data ? (
										<>
											{fm(`rod.admin.account.status_title`)}:{' '}
											{prevCompletionStep &&
												statusLocalization(prevCompletionStep)}
										</>
									) : (
										''
									)}
								</span>
							</div>
						</section>
						<section>
							<div className={styles.HeaderLine}>
								<Label
									text={fm('rod.admin.user.title')}
									font="--gilroy-Medium-32"
								/>
								<RoundedButton
									onClick={() => {
										setModalData('user');
									}}
									classes={styles.actionButton}
								>
									<FormattedMessage id={'rod.admin.account.privilege'} />
								</RoundedButton>
							</div>

							{account.user_data && (
								<>
									<Row>
										<Col sm={4}>
											<Field
												title={fm('rod.admin.account.personal_data.first_name')}
												value={isDataValidOrDash(account.user_data.name)}
											/>
										</Col>
										<Col sm={4}>
											<Field
												title={fm('rod.admin.account.user_data.email')}
												value={isDataValidOrDash(account.user_data.email)}
											/>
										</Col>
										<Col sm={4}>
											<Field
												title={fm('rod.admin.account.user_data.phone')}
												value={isDataValidOrDash(account.user_data.phone)}
											/>
										</Col>
									</Row>
								</>
							)}

							<div className={styles.HeaderLine}>
								<Label
									text={fm('rod.admin.account')}
									font="--gilroy-Medium-32"
								/>
								<RoundedButton
									classes={styles.actionButton}
									onClick={() => {
										setModalData('account');
									}}
								>
									<FormattedMessage id={'rod.admin.account.privilege'} />
								</RoundedButton>
							</div>

							{account.roles && (
								<Field title={fm('rod.admin.account_roles')}>
									<div className={styles.Roles}>
										<ul>
											{account.roles.map((role) => (
												<li>
													<Bullet>
														<Label
															text={
																role.keyword ? (
																	<FormattedMessage
																		id={`rod.ref.roles.${role.keyword}.summary`}
																	/>
																) : (
																	'\u2212'
																)
															}
															font="--gilroy-Medium-18"
															black
														/>
													</Bullet>
												</li>
											))}
										</ul>
									</div>
								</Field>
							)}

							{account.personal_data && (
								<Row>
									<Col sm={4}>
										<Field title={fm('rod.admin.account.category')}>
											<Label
												text={
													account.personal_data.business_type ? (
														<FormattedMessage
															id={`rod.account.create.sign_as.${account.personal_data.business_type}`}
														/>
													) : (
														'\u2212'
													)
												}
												font="--gilroy-Medium-18"
											/>
										</Field>
									</Col>
									<Col sm={8}>
										<Field title={fm('rod.admin.account.repertoire')}>
											<Label
												text={
													account.scope_group ? (
														<FormattedMessage
															id={`rod.account.create.who_are_you.${account.scope_group}`}
														/>
													) : (
														'\u2212'
													)
												}
												font="--gilroy-Medium-18"
											/>
										</Field>
									</Col>
								</Row>
							)}
						</section>

						{account.personal_data &&
							account.personal_data.business_type === accountTypes.PERSONAL && (
								<section>
									<div className={styles.HeaderLine}>
										<Label
											text={fm('rod.admin.account.personal_information')}
											font="--gilroy-Medium-32"
										/>
									</div>
									{renderPersonalData(account)}
								</section>
							)}

						{account.personal_data &&
							account.personal_data.business_type ===
								accountTypes.PERSONAL_ENTREPRENEUR && (
								<section>
									<div className={styles.HeaderLine}>
										<Label
											text={fm('rod.admin.account.sole_proprietor_data')}
											font="--gilroy-Medium-32"
										/>
									</div>
									{renderSoleProprietorData(account, getCountryById)}
								</section>
							)}

						{account.personal_data &&
							account.personal_data.business_type === accountTypes.COMPANY && (
								<section>
									<div className={styles.HeaderLine}>
										<Label
											text={fm('rod.admin.account.company_data')}
											font="--gilroy-Medium-32"
										/>
									</div>
									<Field
										title={fm('rod.admin.account.company_name')}
										value={isDataValidOrDash(account.personal_data.title)}
									/>
									<Row>
										<Col sm={4}>
											<Field
												title={fm('rod.admin.account.personal_data.behalf')}
												value={isDataValidOrDash(account.personal_data.behalf)}
											/>
										</Col>
										<Col sm={4}>
											<Field
												title={fm(
													'rod.admin.account.personal_data.behalf_short'
												)}
												value={isDataValidOrDash(
													account.personal_data.behalf_short
												)}
											/>
										</Col>
										{account.personal_data.attorney && (
											<Col sm={4}>
												<Field
													title={fm('rod.admin.account.attorney')}
													value={isDataValidOrDash(
														account.personal_data.attorney
													)}
												/>
											</Col>
										)}
									</Row>
									<Row>
										<Col sm={4}>
											<Field
												title={fm('rod.admin.account.signature_position')}
												value={isDataValidOrDash(
													account.personal_data.signature_position
												)}
											/>
										</Col>
										<Col sm={6}>
											<Field
												title={fm('rod.admin.account.acting_on')}
												value={
													account.personal_data.acting_on ? (
														<FormattedMessage
															id={`rod.field.${account.personal_data.acting_on}`}
														/>
													) : (
														'\u2212'
													)
												}
											/>
										</Col>
									</Row>
									<Row>
										<Col sm={4}>
											<Field
												title={fm(
													'rod.admin.account.personal_data.company_reg_number'
												)}
												value={isDataValidOrDash(
													account.personal_data.company_registration_number
												)}
											/>
										</Col>
										<Col sm={4}>
											<Field
												title={fm(
													'rod.admin.account.personal_data.company_reg_date'
												)}
												value={isDataValidOrDash(
													account.personal_data.company_registration_date
												)}
											/>
										</Col>
										<Col sm={4}>
											<Field
												title={fm('rod.admin.account.personal_data.country_id')}
												value={
													account.personal_data.country_id
														? getCountryById(account.personal_data.country_id)
														: '\u2212'
												}
											/>
										</Col>
									</Row>
									<Row>
										<Col sm={4}>
											<Field
												title={fm('rod.admin.account.personal_data.itin')}
												value={isDataValidOrDash(account.personal_data.itin)}
											/>
										</Col>
										<Col sm={6}>
											<Field
												title={fm('rod.admin.account.company_vat_number')}
												value={isDataValidOrDash(
													account.personal_data.company_vat_number
												)}
											/>
										</Col>
									</Row>
								</section>
							)}

						{account.contact_data && (
							<section>
								<div className={styles.HeaderLine}>
									<Label
										text={fm('rod.admin.account.contact_information')}
										font="--gilroy-Medium-32"
									/>
								</div>
								<Row>
									<Col sm={4}>
										<Field
											title={fm('rod.admin.account.country')}
											value={
												account.contact_data.country_id
													? getCountryById(account.contact_data.country_id)
													: '\u2212'
											}
										/>
										<Field
											title={fm('rod.admin.account.city')}
											value={isDataValidOrDash(account.contact_data.city)}
										/>
									</Col>
									<Col sm={4}>
										<Field
											title={fm('rod.admin.account.zip_code')}
											value={isDataValidOrDash(account.contact_data.zip_code)}
										/>
										<Field
											title={fm('rod.admin.account.address')}
											value={isDataValidOrDash(account.contact_data.address)}
										/>
									</Col>
									<Col sm={4}></Col>
								</Row>

								<Field
									title={fm('rod.admin.account.user_data.email')}
									value={isDataValidOrDash(account.contact_data.email)}
								/>

								<Field
									title={fm('rod.admin.account.user_data.phone')}
									value={getPhoneNumber(
										account.contact_data.phone_code_country_id,
										account.contact_data.phone_number,
										getCountryDialingCode
									)}
								/>
								{account.contact_data.site_url && (
									<Field
										title={fm('rod.admin.account.site')}
										value={isDataValidOrDash(account.contact_data.site_url)}
									/>
								)}
								{isAllowLanguageDetection && (
									<Row>
										<Col>
											<div className={styles.HeaderLine}>
												<Field
													title={fm('rod.admin.account.locale')}
													font="--gilroy-Medium-32"
													value={
														<FormattedMessage
															id={'rod.admin.account.current_locale'}
															values={{
																current_locale: document.domain,
															}}
														/>
													}
												/>
											</div>

											<Checkbox
												onChange={handleChangeLocale}
												checked={account.redirect ? true : false}
											>
												<FormattedMessage
													id={'rod.admin.account.redirect_locale'}
												/>
											</Checkbox>
										</Col>
									</Row>
								)}
							</section>
						)}

						{account.files && (
							<section>
								<div className={styles.HeaderLine}>
									<Label
										text={fm('rod.admin.account.files.documents')}
										font="--gilroy-Medium-32"
									/>
								</div>
								<table className={styles.Table}>
									<thead>
										<tr>
											<th>{fm('rod.admin.account.files.document_type')}</th>
											<th>{fm('rod.admin.account.files.file')}</th>
											<th className="cb">
												{fm('rod.admin.account.files.quality')}
											</th>
											<th className="cb">
												{fm('rod.admin.account.files.data_match')}
											</th>
										</tr>
									</thead>
									<tbody>
										{Object.keys(account.files).map((key) => (
											<tr>
												<td>{getDocumentTitle(key)}</td>
												<td
													style={{
														display: 'flex',
														alignItems: 'center',
													}}
												>
													<a
														href={account.files[key].url}
														target="_blank"
														rel="noopener noreferrer"
													>
														{account.files[key].client_name}
													</a>
													<a
														href={account.files[key].url}
														target="_blank"
														rel="noreferrer"
														style={{
															marginLeft: 'auto',
														}}
													>
														<i className="fa fa-download" />
													</a>
												</td>
												<td className="cb">
													<Checkbox
														className={styles.Checkbox}
														checked={
															moderationStatus[key]
																? moderationStatus[key]['quality']
																: false
														}
														disabled={!isDocumentsControlsEnabled}
														onChange={toggleModerationStatus(key, 'quality')}
													/>
												</td>
												<td className="cb">
													<Checkbox
														className={styles.Checkbox}
														disabled={!isDocumentsControlsEnabled}
														checked={
															moderationStatus[key]
																? moderationStatus[key]['valid']
																: false
														}
														onChange={toggleModerationStatus(key, 'valid')}
													/>
												</td>
											</tr>
										))}
									</tbody>
								</table>
								{(prevCompletionStep === accountCompletionSteps.PENDING ||
									prevCompletionStep === accountCompletionSteps.APPROVED ||
									prevCompletionStep === accountCompletionSteps.DECLINED ||
									prevCompletionStep ===
										accountCompletionSteps.DECLINED_DOCUMENTS) && (
									<div>
										<div>
											{fm('rod.admin.account.files.set_status')}
											{': '}
											{statusLocalization(completionStep)}
										</div>
										<Button
											primary
											text={
												prevCompletionStep === accountCompletionSteps.APPROVED
													? fm('rod.admin.account.files.approved')
													: prevCompletionStep ===
													  accountCompletionSteps.DECLINED_DOCUMENTS
													? fm('rod.admin.account.files.sent')
													: fm('rod.admin.account.files.to_send')
											}
											disabled={!isDocumentsControlsEnabled}
											className={styles.Button}
											onClick={handleSubmit}
										/>
									</div>
								)}
							</section>
						)}

						{account.societies && (
							<section>
								<div className={styles.HeaderLine}>
									<Label
										text={fm('rod.admin.account.societies')}
										font="--gilroy-Medium-32"
									/>
								</div>
								<table className={styles.Table}>
									<thead>
										<tr>
											<th>
												{fm('rod.admin.account.societies.author_society')}
											</th>
											<th>{fm('rod.admin.account.societies.date')}</th>
										</tr>
									</thead>
									<tbody>
										{Array.isArray(account.societies) &&
											account.societies.length > 0 &&
											account.societies.map((society) => (
												<tr>
													<td>
														{society.title ||
															society.title_ru ||
															society.title_en}
													</td>
													<td>
														{society.mebership_since
															? society.mebership_since
															: ''}
													</td>
												</tr>
											))}
									</tbody>
								</table>
							</section>
						)}

						{account.pseudonyms && (
							<section>
								<div className={styles.HeaderLine}>
									<Label
										text={fm('rod.admin.account.pseudonyms.names_identifiers')}
										font="--gilroy-Medium-32"
									/>
								</div>
								<table className={styles.Table}>
									<thead>
										<tr>
											<th>{fm('rod.admin.account.pseudonyms.identifier')}</th>
											<th>IPI</th>
											<th>ISNI</th>
										</tr>
									</thead>
									<tbody>
										{account.pseudonyms.map((pseudonym) => (
											<tr>
												<td>
													{pseudonym.title ||
														pseudonym.title_ru ||
														pseudonym.title_en}
												</td>
												<td>{isDataValidOrDash(pseudonym.ipi_name_number)}</td>
												<td>{isDataValidOrDash(pseudonym.isni)}</td>
											</tr>
										))}
									</tbody>
								</table>
							</section>
						)}

						{account.payment_info && (
							<section>
								<div className={styles.HeaderLine}>
									<Label
										text={fm('rod.admin.account.payment.data')}
										font="--gilroy-Medium-32"
									/>
								</div>
								<Field
									title={fm('rod.admin.account.payment.method')}
									value={
										account.payment_info.type &&
										account.payment_info.type === 'bank_account'
											? fm('rod.admin.account.payment.bank_data')
											: account.payment_info.type
											? 'Paypal'
											: '\u2212'
									}
								/>
								{account.payment_info.type === 'bank_account' && (
									<React.Fragment>
										<Field
											title={fm('rod.admin.account.payment.beneficiary_name')}
											value={isDataValidOrDash(
												account.payment_info.beneficiary_name
											)}
										/>
										<Field
											title={fm('rod.admin.account.payment.bank_account_name')}
											value={isDataValidOrDash(
												account.payment_info.bank_account_name
											)}
										/>
										<Row>
											<Col sm={6}>
												<Field
													title={fm('rod.admin.account.payment.bank_name')}
													value={isDataValidOrDash(
														account.payment_info.bank_name
													)}
												/>
											</Col>
											<Col sm={6}>
												<Field
													title={fm('rod.admin.account.payment.swift_bic')}
													value={isDataValidOrDash(
														account.payment_info.swift_code
													)}
												/>
											</Col>
										</Row>
										<Row>
											<Col sm={6}>
												<Field
													title={fm('rod.admin.account.payment.bank_address')}
													value={isDataValidOrDash(
														account.payment_info.bank_address
													)}
												/>
											</Col>
											<Col sm={6}>
												<Field
													title={fm('rod.admin.account.payment.bank_country')}
													value={
														account.payment_info.bank_country
															? getCountryById(
																	account.payment_info.bank_country
															  )
															: '\u2212'
													}
												/>
											</Col>
										</Row>
										<Row>
											<Col sm={6}>
												<Field
													title={fm(
														'rod.admin.account.payment.correspondent_account'
													)}
													value={isDataValidOrDash(
														account.payment_info.bank_correspondent_account
													)}
												/>
											</Col>
											<Col sm={6}>
												<Field
													title={fm(
														'rod.admin.account.payment.correspondent_account_swift'
													)}
													value={isDataValidOrDash(
														account.payment_info
															.bank_correspondent_account_swift_code
													)}
												/>
											</Col>
										</Row>
									</React.Fragment>
								)}
								{account.payment_info.type === 'paypal' && (
									<React.Fragment>
										<Field
											title={fm('rod.admin.account.payment.paypal_account')}
											value={isDataValidOrDash(
												account.payment_info.paypal_accoun
											)}
										/>
									</React.Fragment>
								)}
							</section>
						)}

						{account.contracts && (
							<section>
								<div className={styles.HeaderLine}>
									<Label
										text={fm('rod.admin.account.contracts')}
										font="--gilroy-Medium-32"
									/>
								</div>
								<Row>
									<Col sm={6}>
										{account.step && (
											<Field
												title={fm(
													'rod.admin.account.contracts.signing_variants'
												)}
												value={
													account.step === accountCompletionSteps.SIGN_SMS ||
													account.step ===
														accountCompletionSteps.SIGN_PHYSICAL ||
													account.step ===
														accountCompletionSteps.PENDING_CONTRACT
														? account.step === accountCompletionSteps.SIGN_SMS
															? 'SMS'
															: fm('rod.admin.account.contracts.by_hand')
														: '\u2212'
												}
											/>
										)}
									</Col>
									<Col sm={6}>
										{account['allow_sign_change'] &&
										!additionalContracts.length ? (
											<Button
												primary
												text={fm(
													'rod.admin.account.contracts.switch_to_by_hand_signing'
												)}
												onClick={() =>
													switchSignToPhysical(account.personal_data.id)
												}
											/>
										) : (
											''
										)}
									</Col>
								</Row>

								<table className={styles.Table}>
									<TableHead />
									<tbody>
										{Object.keys(account.contracts).map((key, index) => {
											return !account.contracts[key].additional ? (
												<tr>
													<td>{account.contracts[key].type}</td>
													<td
														style={{
															display: 'flex',
															alignItems: 'center',
														}}
													>
														{account.isSMSSign ? (
															<div>{account.contracts[key].client_name}</div>
														) : (
															<>
																{account.contracts[key].url ? (
																	<Link
																		to={`contracts/${account.contracts[
																			key
																		].type.replace('contract-', '')}/${key}`}
																	>
																		{account.contracts[key].client_name}
																	</Link>
																) : (
																	<span>
																		{account.contracts[key].client_name}
																	</span>
																)}
															</>
														)}

														{account.contracts[key].url && (
															<a
																href={account.contracts[key].url}
																target="_blank"
																rel="noreferrer"
																style={{
																	marginLeft: 'auto',
																}}
															>
																<i className="fa fa-download" />
															</a>
														)}
													</td>
													<td className="cb">
														<div
															data-name={'1' + index}
															onClick={(e) => handleWarningBlockShow(e, key)}
															title={
																warnBlockNum === '1' + index ? getTooltip() : ''
															}
														>
															<Checkbox
																className={styles.Checkbox}
																disabled={
																	!isContractsControlsEnabled || isSubmitPressed
																}
																checked={
																	moderationStatus[key]
																		? moderationStatus[key]['quality']
																		: false
																}
																onChange={toggleModerationStatus(
																	key,
																	'quality'
																)}
															/>
														</div>
													</td>
													<td className="cb">
														<div
															data-name={'2' + index}
															onClick={(e) => handleWarningBlockShow(e, key)}
															title={
																warnBlockNum === '2' + index ? getTooltip() : ''
															}
														>
															<Checkbox
																className={styles.Checkbox}
																disabled={
																	!isContractsControlsEnabled || isSubmitPressed
																}
																checked={
																	moderationStatus[key]
																		? moderationStatus[key]['signature']
																		: false
																}
																onChange={toggleModerationStatus(
																	key,
																	'signature'
																)}
															/>
														</div>
													</td>
													<td className="cb">
														<div
															data-name={'3' + index}
															onClick={(e) => handleWarningBlockShow(e, key)}
															title={
																warnBlockNum === '3' + index ? getTooltip() : ''
															}
														>
															<Checkbox
																className={styles.Checkbox}
																disabled={
																	!isContractsControlsEnabled || isSubmitPressed
																}
																checked={
																	moderationStatus[key]
																		? moderationStatus[key]['valid']
																		: false
																}
																onChange={toggleModerationStatus(key, 'valid')}
															/>
														</div>
													</td>
												</tr>
											) : (
												''
											);
										})}
									</tbody>
								</table>

								{(prevCompletionStep ===
									accountCompletionSteps.PENDING_CONTRACT ||
									prevCompletionStep ===
										accountCompletionSteps.DECLINED_CONTRACTS ||
									prevCompletionStep ===
										accountCompletionSteps.APPROVED_CONTRACTS) && (
									<div>
										<div>
											{fm('rod.admin.account.files.set_status')}
											{': '}
											{statusLocalization(completionStep)}
										</div>
										<Button
											primary
											text={
												prevCompletionStep ===
												accountCompletionSteps.APPROVED_CONTRACTS
													? fm('rod.admin.account.files.approved')
													: prevCompletionStep ===
													  accountCompletionSteps.DECLINED_CONTRACTS
													? fm('rod.admin.account.files.sent')
													: fm('rod.admin.account.files.to_send')
											}
											disabled={
												!(
													isDocumentsControlsEnabled ||
													isContractsControlsEnabled
												)
											}
											className={styles.Button}
											onClick={handleSubmit}
										/>
									</div>
								)}
							</section>
						)}

						{account.contracts && (
							<section>
								<div className={styles.HeaderLine}>
									<Label
										text={fm('rod.field.additional_contracts')}
										font="--gilroy-Medium-32"
									/>
								</div>
								<Row>
									<Col sm={6}>
										{account.step && (
											<Field
												title={fm(
													'rod.admin.account.contracts.signing_variants'
												)}
												value={
													additionalContracts.length
														? account.isSMSSign
															? 'SMS'
															: fm('rod.admin.account.contracts.by_hand')
														: '\u2212'
												}
											/>
										)}
									</Col>
									<Col sm={6}>
										{account['allow_sign_change'] &&
										additionalContracts.length ? (
											<Button
												primary
												text={fm(
													'rod.admin.account.contracts.switch_to_by_hand_signing'
												)}
												onClick={() =>
													switchSignToPhysical(account.personal_data.id)
												}
											/>
										) : (
											''
										)}
									</Col>
								</Row>
								<table className={styles.Table}>
									<TableHead />
									<tbody>
										{Object.keys(account.contracts).map((key, index) => {
											return account.contracts[key].additional ? (
												<tr key={key}>
													<td>
														{isDataValidOrDash(account.contracts[key].type)}
													</td>
													<td
														style={{
															display: 'flex',
															alignItems: 'center',
														}}
													>
														{account.isSMSSign ? (
															<div>
																{isDataValidOrDash(
																	account.contracts[key].client_name
																)}
															</div>
														) : (
															<Link
																to={`contracts/${account.contracts[
																	key
																].type.replace('contract-', '')}/${key}`}
															>
																{isDataValidOrDash(
																	account.contracts[key].client_name
																)}
															</Link>
														)}
														<a
															href={account.contracts[key].url}
															target="_blank"
															rel="noreferrer"
															style={{
																marginLeft: 'auto',
															}}
														>
															<i className="fa fa-download" />
														</a>
													</td>
													<td className="cb">
														<div
															data-name={'01' + index}
															onClick={(e) => handleWarningBlockShow(e, key)}
															title={
																warnBlockNumAddContract === '01' + index
																	? getTooltip()
																	: ''
															}
														>
															<Checkbox
																className={styles.Checkbox}
																disabled={
																	!account.contracts[key]
																		.additional_contracts_signed ||
																	isSubmitPressed
																}
																checked={
																	moderationStatus[key]
																		? moderationStatus[key]['quality']
																		: false
																}
																onChange={toggleModerationStatus(
																	key,
																	'quality'
																)}
															/>
														</div>
													</td>
													<td className="cb">
														<div
															data-name={'02' + index}
															onClick={(e) => handleWarningBlockShow(e, key)}
															title={
																warnBlockNumAddContract === '02' + index
																	? getTooltip()
																	: ''
															}
														>
															<Checkbox
																className={styles.Checkbox}
																disabled={
																	!account.contracts[key]
																		.additional_contracts_signed ||
																	isSubmitPressed
																}
																checked={
																	moderationStatus[key]
																		? moderationStatus[key]['signature']
																		: false
																}
																onChange={toggleModerationStatus(
																	key,
																	'signature'
																)}
															/>
														</div>
													</td>
													<td className="cb">
														<div
															data-name={'03' + index}
															onClick={(e) => handleWarningBlockShow(e, key)}
															title={
																warnBlockNumAddContract === '03' + index
																	? getTooltip()
																	: ''
															}
														>
															<Checkbox
																className={styles.Checkbox}
																disabled={
																	!account.contracts[key]
																		.additional_contracts_signed ||
																	isSubmitPressed
																}
																checked={
																	moderationStatus[key]
																		? moderationStatus[key]['valid']
																		: false
																}
																onChange={toggleModerationStatus(key, 'valid')}
															/>
														</div>
													</td>
												</tr>
											) : (
												''
											);
										})}
									</tbody>
								</table>

								{additionalContracts.length ? (
									<div>
										<div>
											{fm('rod.admin.account.files.set_status')}
											{': '}
											{statusLocalizationAdditional()
												? fm('rod.admin.account.files.approved')
												: fm('rod.repertoire.rejected')}
										</div>
										<Button
											primary
											text={
												additionalSubmitBtnData.message
													? additionalSubmitBtnData.message
													: fm('rod.admin.account.files.to_send')
											}
											disabled={isAdditionalBtnDisabled()}
											className={styles.Button}
											onClick={handleSubmit}
										/>
									</div>
								) : (
									''
								)}
							</section>
						)}

						{isPaymentsEnabled &&
							account.payments &&
							account.payments.length > 0 && (
								<PaymentsWithdrawal payments={account.payments} />
							)}
					</div>
				</Col>
			</Row>
			{modalActive && (
				<PrivilegeModal
					active={modalActive}
					setActive={setModalActive}
					account={account}
					type={modalType}
				/>
			)}
		</Container>
	);
}

export default compose(withUI, withRoot)(AccountData);
