import { useContext } from 'react';
import s from './AddRoleContractsBanner.module.css';
import { useHistory } from 'react-router-dom';
import { UIContext } from 'contexts/UIContext';

const AddRoleContractsBanner = (props) => {
	const { showModal } = useContext(UIContext);
	const history = useHistory();
	const handleClick = () => {
		history.push(props.data.route);
		// /treaties/addrole/:id
	};

	const handleAction = (services, postAdditionalServices, id, actionType) => {
		if (actionType === 'to draft') {
			return history.push('/');
		} else {
			postAdditionalServices(id, { service: services })
				.then((res) => {
					history.push(`/treaties/addservices/${id}`);
				})
				.catch((err) => console.info('Error:', err));
		}
	};

	return (
		<div className={s.bannerWrapper}>
			<div className={s.bannerText}>
				<div className={s.bannerTitle}>{props.data.title}</div>
				<div className={s.bannerContent}>{props.data.content}</div>
			</div>

			{props.data.action ? (
				<div
					className={s.bannerBtn}
					onClick={() =>
						handleAction(
							props.data.data,
							props.data.postAdditionalServices,
							props.data.id,
							props.data.action
						)
					}
				>
					{props.data.btnText}
				</div>
			) : (
				<div
					className={s.bannerBtn}
					onClick={showModal(
						{
							services: props.data.data,
							postAdditionalServices: props.data.postAdditionalServices,
							id: props.data.id,
						},
						'add_service'
					)}
				>
					{props.data.btnText}
				</div>
			)}
		</div>
	);
};

export default AddRoleContractsBanner;
