// Core
import { useContext, useEffect, useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

// Services
import lscache from 'lscache';
import { compose } from 'recompose';
import { withAuth, withRoot, withUI } from 'hocs';
import { setCache } from 'services/cacheHelper/setCache';

// Context
import { UIContext } from 'contexts/UIContext';

// Utils
import { checkRangeInOneYear } from '../utils/checkRangeInOneYear';
import { getStartEndDatesForOneYear } from '../utils/getStartEndDatesForOneYear';
import { getDateInYYYYMMDD } from '../utils/getDateInYYYYMMDD';

// UI
import StatisticHeader from 'material-design/Statistic/StatisticHeader/StatisticHeader';
import DataLoadingError from '../DataLoadingError/DataLoadingError';
import { FullScreenModalConfirm } from 'containers/Modal';
import { StatisticByTerritoriesChart } from './StatisticByTerritoriesChart';
import { FormInput } from 'components/Form/FormInput';
import { Loading, NoData, TopTracksSkeleton } from 'components';
import { Pagination } from 'material-design/components';
import close from 'images/close_button.svg';
import MultipleSelectOutletsCheckmarks from 'material-design/components/MuiMultipleCheckedOutlets/MuiMultipleCheckedOutlets';
import MultipleSelectCheckmarks from 'material-design/components/MuiMultipleCheckedTracks/MuiMultipleCheckedTracks';
import TopTracksTable from './TopTracksTable/TopTracksTable';
import StatisticDownloadBtn from 'components/StatisticDownloadBtn/StatisticDownloadBtn';

// Styles
import styles from './StatisticByTerritoriesSection.module.css';

const StatisticByTerritoriesSection = (props) => {
	const [statisticData, setStatisticData] = useState({
		start_date: new Date(new Date().getTime() - 86400000 * 32).toISOString(),
		end_date: new Date(new Date().getTime() - 86400000 * 2).toISOString(),
		outlets: [],
		tracks: [],
	});

	const [showRangeModal, setShowRangeModal] = useState(false);
	const [page, setPage] = useState(1);
	const [pageLoading, setPageLoading] = useState(true);
	const [isPaginationPressed, setIsPaginationPressed] = useState(false);
	const [total, setTotal] = useState(0);
	const [helper, setHelper] = useState(false);
	const [trackSearchValue, setTrackSearchValue] = useState('');
	const [topTracks, setTopTracks] = useState([]);
	const [outletCodes, setOutletCodes] = useState([]);
	const [checkedTopTrack, setCkeckedTopTrack] = useState(null);
	const [loading, setLoading] = useState(true);
	const [errors, setErrors] = useState({});
	const [accountOutlets, setAccountOutlets] = useState([]);
	const [isAllTracks, setIsAllTracks] = useState(false);
	const [isReqLive] = useState(false);
	const [isInitialData, setIsInitialData] = useState(true);
	const [accountMap, setAccountMap] = useState([]);
	const [tracksIds, setTracksIds] = useState([]);
	const [isTracksFilterEmpty, setIsTracksFilterEmpty] = useState(false);
	const [isOutletsFilterEmpty, setIsOutletsFilterEmpty] = useState(false);
	const [mapDataError, setMapDataError] = useState(false);
	const [chartMode, setChartMode] = useState(props.chartMode ?? 'phono');

	const {
		rootContext: {
			getAccountStatisticOutlets,
			getAccountStatisticTopTracks,
			getAccountStatisticAuthorTopTracks,
			getAccountStatisticMapByDate,
			getAccountStatisticAuthorMapByDate,
		},
		adminAccountId,
	} = props;

	const { showModal, isCopyrightingStatisticsEnabled } = useContext(UIContext);

	const isAdmin = adminAccountId ? true : false;
	const accountId = adminAccountId
		? adminAccountId
		: props.authContext.accountId;

	const lang = localStorage.getItem('lang');

	const MAX_DATE = Date.now();
	const MIN_DATE = '2020-01-01';

	const mapRequest =
		chartMode === 'compo'
			? getAccountStatisticAuthorMapByDate
			: getAccountStatisticMapByDate;

	const topTracksRequest =
		chartMode === 'compo'
			? getAccountStatisticAuthorTopTracks
			: getAccountStatisticTopTracks;

	useEffect(() => {
		const isShowedHelper = localStorage.getItem('helper');
		if (isShowedHelper === 'showed') {
			setHelper(false);
		} else {
			setHelper(true);
		}

		getAccountStatisticOutlets().then((res) => {
			res = res.data.data;
			setAccountOutlets(res);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isTracksFilterEmpty || isOutletsFilterEmpty) {
			return;
		} else if (
			accountOutlets.length &&
			tracksIds.length &&
			!Object.keys(errors).length
		) {
			setPageLoading(true);
			setLoading(true);
			const outlets =
				statisticData['outlets']?.length > 0
					? statisticData['outlets']
					: accountOutlets;
			const startEndDates = getStartEndDatesForOneYear(statisticData, isAdmin);
			const outletsCodes = outlets.map((outlet) => outlet.code);

			// Хешируем ключ - Аутлеты сортируем по алфавиту. Соединяем массивы данных в строку через * начиная с аутлетов
			const sortOutlets = outletsCodes.sort();
			const mapHash = `${sortOutlets.join('*')}*${startEndDates.join('*')}*${
				isAllTracks ? 'all' : tracksIds.join('*')
			}*map*${accountId}`;

			mapRequest(
				isAdmin,
				accountId,
				startEndDates[0],
				startEndDates[1],
				outletsCodes,
				tracksIds.length && !isAllTracks ? tracksIds : [],
				tracksIds.length && !isAllTracks ? false : true
			)
				.then((res) => {
					res = res.data.data;
					if (isReqLive) {
						setAccountMap(res.global);
						setLoading(false);
					} else {
						setCache('statisticMapCache', mapHash, res);
						setAccountMap(res.global);
						setLoading(false);
					}
				})
				.catch((error) => {
					if (error.response.status === 422) {
						setMapDataError(true);
					}
					console.error(error.response);
				});

			const topTracksHash = `${sortOutlets.join('*')}*${startEndDates.join(
				'*'
			)}*${isAllTracks ? 'all' : tracksIds.join('*')}*topTracks*${accountId}`;

			setPage(1);
			setIsPaginationPressed(false);
			topTracksRequest(
				isAdmin,
				accountId,
				page,
				startEndDates[0],
				startEndDates[1],
				outletsCodes,
				10,
				tracksIds.length && !isAllTracks ? tracksIds : ''
			).then((res) => {
				res = res.data.data;

				if (isReqLive) {
					setTopTracks(res.recordings);
					setTotal(res.total);
					setPageLoading(false);
				} else {
					setCache('statisticTopTracksCache', topTracksHash, res);
					setTopTracks(res.recordings);
					setTotal(res.total);
					setPageLoading(false);
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		accountOutlets,
		statisticData,
		statisticData.outlets,
		statisticData.start_date,
		statisticData.end_date,
		statisticData.tracks,
		tracksIds,
	]);

	useEffect(() => {
		if (
			accountOutlets.length &&
			isPaginationPressed &&
			statisticData !== null
		) {
			const outlets =
				statisticData['outlets'].length > 0
					? statisticData['outlets']
					: accountOutlets;

			const outletsCodes = outlets.map((outlet) => outlet.code);
			const startEndDates = getStartEndDatesForOneYear(statisticData, isAdmin);
			const sortOutlets = outletsCodes.sort();
			const topTracksHash = `${sortOutlets.join('*')}*${startEndDates.join(
				'*'
			)}*${
				isAllTracks ? 'all' : tracksIds.join('*')
			}*topTracks*${accountId}*${page}`;

			const topTracksReqFromCash = lscache.get(topTracksHash);

			if (topTracksReqFromCash && !isReqLive) {
				setTopTracks(topTracksReqFromCash.recordings);
				setTotal(topTracksReqFromCash.total);
				setPageLoading(false);
			} else {
				topTracksRequest(
					isAdmin,
					accountId,
					page,
					startEndDates[0],
					startEndDates[1],
					outletsCodes,
					10,
					tracksIds.length && !isAllTracks ? tracksIds : ''
				).then((res) => {
					res = res.data.data;

					if (isReqLive) {
						setTopTracks(res.recordings);
						setTotal(res.total);
						setPageLoading(false);
					} else {
						setCache('statisticTopTracksCache', topTracksHash, res);
						setTopTracks(res.recordings);
						setTotal(res.total);
						setPageLoading(false);
					}
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPaginationPressed, page]);

	const closeModal = () => {
		setShowRangeModal(false);
	};

	const changeField = (field) => (e) => {
		switch (field) {
			case 'start_date':
				if (e && e.getFullYear().toString().length === 4) {
					e = getDateInYYYYMMDD(e);
					if (
						Date.parse(e) >= Date.parse(MIN_DATE) &&
						Date.parse(e) < Date.parse(statisticData['end_date'])
					) {
						checkRangeInOneYear(statisticData, setShowRangeModal, showModal, e);
						statisticData[field] = e;
						setStatisticData({ ...statisticData });
						setErrors((prev) => {
							const a = { ...prev };
							delete a['start_date'];
							return a;
						});
					} else {
						let errText = '';
						if (Date.parse(e) < Date.parse(MIN_DATE)) {
							errText = 'minDate';
						} else errText = 'invalid date';
						setErrors((prev) => ({ ...prev, start_date: errText }));
					}
				}
				if (e === null) {
					statisticData[field] = getDateInYYYYMMDD(
						new Date(new Date().getTime() - 86400000 * 32)
					);
					setStatisticData({ ...statisticData });
				}
				break;
			case 'end_date':
				if (e && e.getFullYear().toString().length === 4) {
					e = getDateInYYYYMMDD(e);
					if (
						Date.parse(e) > Date.parse(statisticData['start_date']) &&
						Date.parse(e) <= MAX_DATE
					) {
						checkRangeInOneYear(
							statisticData,
							setShowRangeModal,
							showModal,
							statisticData.start_date,
							e
						);
						statisticData[field] = e;
						setStatisticData({ ...statisticData });
						setErrors((prev) => {
							const a = { ...prev };
							delete a['end_date'];
							return a;
						});
					} else {
						setErrors((prev) => ({ ...prev, end_date: 'invalid date' }));
					}
				}
				if (e === null) {
					statisticData[field] = getDateInYYYYMMDD(
						new Date(new Date().getTime() - 86400000 * 2)
					);
					setStatisticData({ ...statisticData });
				}

				break;

			case 'tracks':
				statisticData[field] = e.map((item) => item.title);
				setStatisticData({ ...statisticData });
				break;

			default:
				statisticData[field] = e.target.value;
				setStatisticData({ ...statisticData });
				break;
		}
		if (statisticData['outlets'].length > 0 && isInitialData) {
			setIsInitialData(false);
		} else if (statisticData['outlets'].length === 0 && !isInitialData) {
			setIsInitialData(true);
		}
	};

	const handleCheckedTracks = (tracks, isAll) => {
		if (!loading) {
			setIsTracksFilterEmpty(tracks.length ? false : true);
		}

		if (tracks.length !== tracksIds.length || tracksIds.length === 1) {
			setIsAllTracks(isAll);
			setTracksIds(tracks);
			statisticData.tracks = tracks;
			setStatisticData({ ...statisticData });
		}
	};

	const handleCheckedOutlets = (outlets) => {
		if (!loading) {
			setIsOutletsFilterEmpty(outlets.length ? false : true);
		}
		const codes = outlets.map((item) => item.code);

		if (outlets.length !== outletCodes.length) {
			setOutletCodes(codes);
			statisticData['outlets'] = outlets;
			setStatisticData({ ...statisticData });
		}
	};

	const handleCheckedTopTrack = (track) => {
		if (track) {
			setCkeckedTopTrack(track);
		} else {
			setCkeckedTopTrack(null);
		}
	};

	const startEndDates = getStartEndDatesForOneYear(statisticData, isAdmin);

	const closeHelper = () => {
		setHelper(false);
		localStorage.setItem('helper', 'showed');
	};

	return (
		<>
			{helper && (
				<div className={styles.helper}>
					<img onClick={closeHelper} src={close} alt="" />
					<span>
						<FormattedHTMLMessage id={'rod.statistic.notification'} />
					</span>
				</div>
			)}
			{isCopyrightingStatisticsEnabled && (
				<StatisticHeader
					chartMode={chartMode}
					setChartMode={setChartMode}
					accountId={accountId}
					statisticData={statisticData}
					accountOutlets={accountOutlets}
					isAdmin={isAdmin}
					trackSearchValue={trackSearchValue}
					tracksIds={tracksIds}
					isAllTracks={isAllTracks}
					statType="byterritory"
				/>
			)}

			<div className={styles.statisticByTerritories__mainContent}>
				<div className={styles.inputsWrapper}>
					<div className={styles.inputOutlets}>
						<MultipleSelectOutletsCheckmarks
							handleCheckedOutlets={handleCheckedOutlets}
							lang={lang}
							className={styles.tracksCheckmarks}
							chartMode={chartMode}
						/>
						{Object.keys(errors).includes('outlets') && (
							<span className={styles.Helper}>error</span>
						)}
					</div>
					<div className={styles.inputTrack}>
						<MultipleSelectCheckmarks
							isAdmin={isAdmin}
							accountId={accountId}
							handleCheckedTracks={handleCheckedTracks}
							checkedTopTrack={checkedTopTrack}
							handleCheckedTopTrack={handleCheckedTopTrack}
							className={styles.tracksCheckmarks}
							dateFrom={startEndDates[0]}
							dateTo={startEndDates[1]}
							setLoading={setLoading}
							setTrackSearchValue={setTrackSearchValue}
							chartMode={chartMode}
						/>
						{Object.keys(errors).includes('tracks') && (
							<span className={styles.Helper}>error</span>
						)}
					</div>
					<div className={styles.inputStartDate}>
						<FormInput
							className={styles.date}
							type={'muiInputDate_v3'}
							defaultValue={true}
							name={'start_date'}
							onChange={changeField}
							minDate={Date.parse(MIN_DATE)}
							maxDate={Date.parse(statisticData.end_date)}
							errors={errors}
							data={statisticData}
							label={<FormattedMessage id={'rod.statistic.start-date'} />}
						/>
						{Object.keys(errors).includes('start_date') && (
							<div className={styles.error}>
								<span className={styles.errorText}>
									{errors['start_date'] === 'invalid date' ? (
										<FormattedMessage id={'rod.admin.invalid-date'} />
									) : (
										<FormattedMessage id={'rod.error.statistic.minDate'} />
									)}
									{errors['start_date'] === 'minDate' && MIN_DATE}
								</span>
							</div>
						)}
					</div>
					<div className={styles.inputDate}>
						<FormInput
							className={styles.date}
							type={'muiInputDate_v3'}
							defaultValue={true}
							name={'end_date'}
							onChange={changeField}
							minDate={Date.parse(statisticData.start_date)}
							maxDate={Date.now()}
							errors={errors}
							data={statisticData}
							label={<FormattedMessage id={'rod.statistic.end-date'} />}
						/>
						{Object.keys(errors).includes('end_date') && (
							<div className={styles.error}>
								<span className={styles.errorText}>
									{errors['end_date'] === 'invalid date' ? (
										<FormattedMessage id={'rod.admin.invalid-date'} />
									) : (
										''
									)}
								</span>
							</div>
						)}
					</div>
					{!isCopyrightingStatisticsEnabled && (
						<StatisticDownloadBtn
							accountId={accountId}
							statisticData={statisticData}
							accountOutlets={accountOutlets}
							isAdmin={isAdmin}
							tracksIds={
								trackSearchValue ? tracksIds : isAllTracks ? [] : tracksIds
							}
						/>
					)}
				</div>

				{!loading && mapDataError && <DataLoadingError />}
				{!loading && (isOutletsFilterEmpty || isTracksFilterEmpty) && (
					<NoData textId="rod.statistic.no-data" />
				)}

				{loading ? (
					<Loading className={styles.loader} />
				) : isOutletsFilterEmpty ||
				  isTracksFilterEmpty ||
				  accountMap.length === 0 ? (
					<></>
				) : (
					accountMap && (
						<>
							{!mapDataError && (
								<StatisticByTerritoriesChart
									data={accountMap}
									selectedOutlets={
										isInitialData ? accountOutlets : statisticData.outlets
									}
								/>
							)}
							<>
								<div className={styles.topTracksTitle}>
									<span>
										<FormattedHTMLMessage id={'rod.statistic.top_tracks'} />
									</span>
								</div>
								{pageLoading ? (
									<TopTracksSkeleton />
								) : (
									topTracks &&
									topTracks.length > 0 && (
										<>
											<TopTracksTable
												topTracks={topTracks}
												handleCheckedTopTrack={handleCheckedTopTrack}
												page={page}
												statisticData={statisticData}
												isAdmin={isAdmin}
												outlets={accountOutlets}
												accountId={accountId}
												chartMode={chartMode}
											/>
											{total > 10 && (
												<Pagination
													page={page}
													total={total}
													paginate={(pageNumber) => {
														setPage(pageNumber);
														setIsPaginationPressed(true);
														setPageLoading(true);
													}}
													items_on_page={10}
												/>
											)}
										</>
									)
								)}
							</>
						</>
					)
				)}
				{showRangeModal && !props.main && (
					<FullScreenModalConfirm
						title={<FormattedMessage id={'rod.modal.statistic_title'} />}
						text={<FormattedMessage id={'rod.modal.statistic_text'} />}
						confirmBtnTxt={<FormattedMessage id={'rod.modal.understood'} />}
						onClose={closeModal}
					/>
				)}
			</div>
		</>
	);
};

export default compose(
	withUI,
	withAuth,
	withRoot
)(StatisticByTerritoriesSection);
