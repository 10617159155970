// Core
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import PropTypes from 'prop-types';

// Icons
import chevronDownImg from 'images/chevron_down.svg';
import chevronUpImg from 'images/chevron_up.svg';

// Styles
import styles from './DropdownCombo.module.css';

const DropdownCombo = ({
	items,
	defaultItemIndex,
	defaultEmpty,
	getItem,
	getListItem,
	getItemValue,
	onChange,
	showChevron,
	showRequired,
	value,
	name,
	helper,
	errors = [],
	className,
	forwardRef,
	disabled,
	registerPhone,
	placeholder,
	...props
}) => {
	const ref = useRef(forwardRef);
	const listRef = useRef(null);
	const selectedRef = useRef(null);
	const dropdownRef = useRef(null);

	const [highLightIndex, setHighLightIndex] = useState(0);
	const [filteredItems, setFilteredItems] = useState(items);
	const [isOpen, setIsOpen] = useState(false);
	const [item, setItem] = useState(
		value
			? items.find((item) => item.keyword === value || item.id === value)
			: defaultEmpty
			? null
			: items[defaultItemIndex]
	);
	const [inputValue, setInputValue] = useState('');

	useEffect(() => {
		setItem(items.find((item) => item.keyword === value || item.id === value));
	}, [value, items]);

	useEffect(() => {
		if (inputValue === '') {
			setFilteredItems(items);
		}
	}, [inputValue, items]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setIsOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handleSelect = useCallback(
		(selectedItem) => () => {
			setItem(selectedItem);
			setIsOpen(false);
			setInputValue(selectedItem.dialing_code);
			onChange(getItemValue(selectedItem));
		},
		[onChange, getItemValue]
	);

	const handleOpen = () => {
		setIsOpen(true);
	};

	const onChangeValue = (e) => {
		const value = e.target.value;
		const filteredItems = items.filter((item) =>
			item.dialing_code.toString().startsWith(value)
		);

		if (!Number(value)) {
			setInputValue('');
		} else {
			setInputValue(value);
			setHighLightIndex(0);
			setFilteredItems(filteredItems.length > 0 ? filteredItems : items);
		}
	};

	const handleFocus = () => {
		if (!isOpen) {
			handleOpen();
		}
	};

	const handleOnBlur = () => {
		setIsOpen(false);
	};

	const scrollToSelected = () => {
		selectedRef.current?.scrollIntoView({
			behavior: 'smooth',
			block: 'nearest',
			inline: 'nearest',
		});
	};

	const showError = (error) => {
		if (typeof showError === 'function') {
			return showError(error);
		} else {
			if (name) {
				return <FormattedMessage id={`rod.error.${name}_${error.rule}`} />;
			} else {
				return <FormattedMessage id={`rod.error.${error.rule}`} />;
			}
		}
	};

	const handleKeyUp = (e) => {
		switch (e.key) {
			case 'ArrowUp':
				setHighLightIndex((prev) => Math.max(0, prev - 1));
				scrollToSelected();
				break;
			case 'ArrowDown':
				setHighLightIndex((prev) =>
					Math.min(prev + 1, filteredItems.length - 1)
				);
				scrollToSelected();
				break;
			case 'Enter':
				const selectedItem = filteredItems[highLightIndex];
				setItem(selectedItem);
				setHighLightIndex(-1);
				setIsOpen(false);
				setInputValue(selectedItem.dialing_code);
				onChange(getItemValue(selectedItem));
				break;
			default:
				return;
		}
	};

	const valueDisplay = item ? getItem(item, isOpen) : null;
	const data = filteredItems;

	const classes = classnames(
		className,
		styles.Dropdown,
		errors.length > 0 && styles.Error
	);

	return (
		<div className={classes} {...props} ref={dropdownRef}>
			{!isOpen ? (
				<div
					className={
						disabled
							? registerPhone
								? `${styles.Value} ${styles.phoneDisabled}`
								: `${styles.Value} ${styles.disabled} ${styles.noHover}`
							: `${styles.Value}`
					}
					onClick={disabled ? undefined : handleOpen}
				>
					{valueDisplay}
				</div>
			) : (
				<div className={styles.inputWrapper}>
					<span className={styles.maskPlus}>+</span>
					<input
						type="text"
						className={styles.Value}
						onClick={handleOpen}
						onFocus={handleFocus}
						handleOnBlur={handleOnBlur}
						value={inputValue}
						onChange={onChangeValue}
						onKeyUp={handleKeyUp}
						autoFocus
						style={{ paddingLeft: '20px' }}
						disabled={disabled}
						ref={ref}
					/>
				</div>
			)}
			{showChevron && (
				<div
					className={styles.Chevron}
					onClick={disabled ? undefined : handleOpen}
				>
					<img src={isOpen ? chevronUpImg : chevronDownImg} alt="" />
				</div>
			)}
			{isOpen && (
				<ul className={styles.List} ref={listRef}>
					{data.map((item, index) => (
						<li
							key={item.id}
							onClick={handleSelect(item)}
							data-index={index}
							ref={highLightIndex === index ? selectedRef : null}
							className={classnames(styles.Item, {
								selected: highLightIndex === index,
							})}
						>
							{getListItem(item)}
						</li>
					))}
				</ul>
			)}
			{helper && errors.length === 0 && (
				<span className={styles.Helper}>{helper}</span>
			)}
			{errors.length > 0 && (
				<span className={styles.Helper}>{showError(errors[0])}</span>
			)}
			<input
				type="hidden"
				name={name}
				value={item ? getItemValue(item) : ''}
				ref={forwardRef}
			/>
		</div>
	);
};

DropdownCombo.propTypes = {
	items: PropTypes.array.isRequired,
	defaultItemIndex: PropTypes.number,
	defaultEmpty: PropTypes.bool,
	getItem: PropTypes.func,
	getListItem: PropTypes.func,
	getItemValue: PropTypes.func,
	onChange: PropTypes.func,
	showError: PropTypes.func,
	showChevron: PropTypes.bool,
	showRequired: PropTypes.bool,
};

DropdownCombo.defaultProps = {
	errors: [],
	showChevron: true,
	defaultItemIndex: 0,
	getItem: (item) => item,
	getListItem: (item) => item,
	getItemValue: (item) => item,
	onChange: () => {},
};

export default DropdownCombo;
