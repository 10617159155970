export const steps = {
	LIST: 'list',
	CONTRACT: 'contract',
	SIGN: 'sign',
	NEXT: 'next',
	NO_CODE: 'no_code',
	COMPLETE: 'complete',
	PENDING: 'pending',
	NO_CONTRACT_YET: 'no_contract',
	ADDITIONAL_CONTRACTS_APPROVED: 'additional_contracts_approved',
	ADDITIONAL_CONTRACTS_DECLINED: 'additional_contracts_declined',
};
