/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

import classNames from 'classnames';
import chevronDownIcon from 'images/chevron_down.svg';
import chevronUpIcon from 'images/chevron_up.svg';
import markIcon from 'images/mark.svg';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styles from './Accordion.module.css';

class Accordion extends Component {
	static propTypes = {
		id: PropTypes.any,
		title: PropTypes.string,
		complete: PropTypes.bool,
		open: PropTypes.bool,
		content: PropTypes.element,
		onClick: PropTypes.func,
	};

	toggleOpenHandler = () => {
		if (typeof this.props.onClick === 'function') {
			this.props.onClick(this.props.id);
		}
	};

	render() {
		const { content, title, complete, open, className } = this.props;
		const classes = classNames(
			className,
			styles['Accordion'],
			complete && 'complete',
			open && 'open'
		);
		return (
			<div className={classes}>
				<div className={styles.Header} onClick={this.toggleOpenHandler}>
					<div className={'title'}>
						{title}
						{complete && <img src={markIcon} alt="" />}
					</div>
					<div>
						{open ? (
							<img src={chevronUpIcon} alt="" />
						) : (
							<img src={chevronDownIcon} alt="" />
						)}
					</div>
				</div>
				<div className={styles.Body}>{content}</div>
			</div>
		);
	}
}

export default Accordion;
