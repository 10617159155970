import { useEffect } from 'react';
import { useLatest } from 'material-design/hooks';

const UseOutsideClick = function (
	elementRef,
	handler,
	attached = true,
	helpRef = null
) {
	const latestHandler = useLatest(handler);

	useEffect(() => {
		if (!attached) return;

		const handleClick = (e) => {
			if (!elementRef.current) return;
			if (helpRef) {
				if (
					!elementRef.current.contains(e.target) &&
					helpRef.current !== e.target
				)
					latestHandler.current();
			} else {
				if (!elementRef.current.contains(e.target)) latestHandler.current();
			}
		};

		document.addEventListener('click', handleClick);
		return () => {
			document.removeEventListener('click', handleClick);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [elementRef, handler, attached]);
};

export default UseOutsideClick;
