// Core
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'react-grid-system';
import lscache from 'lscache';

// Context
import { LangContext } from 'contexts/LangContext';
import { UIContext } from 'contexts/UIContext';
import { RootContext } from 'contexts/RootContext';

// Utils
import { addTranslation } from './utils/addTranslation';
import { formatDate, getDraftPerformers } from 'utils';
import { compose } from 'recompose';
import { setCache } from 'services/cacheHelper/setCache';

// UI
import { Loading, NoData } from 'components';
import TableHeader from './TableHeader/TableHeader';
import { Pagination } from 'material-design/components';
import { Checkbox } from 'components/Checkboxes';
import { withLang, withUI, withRoot } from 'hocs';

// Icons
import remove_cart from 'images/remove_cart.svg';
import cd from 'images/icon_cd.svg';
import nota_in_square from 'images/nota_in_square.svg';

// Types
import modalTypes from 'constants/modalTypes';

// Styles
import styles from './HandlingDrafts.module.css';

const HandlingDrafts = (props) => {
	const { lang } = useContext(LangContext);
	const { showModal } = useContext(UIContext);
	const {
		getUnprocessedDrafts,
		getCompositionTypes,
		getReleaseTypes,
		deleteDraft,
		deleteDraftsList,
	} = useContext(RootContext);

	const history = useHistory();

	const [drafts, setDrafts] = useState('');
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [noData, setNoData] = useState(false);
	const [isReqLive] = useState(false);
	const [releaseTypes, setReleaseTypes] = useState([]);
	const [compositionTypes, setCompositionTypes] = useState([]);
	const [selectedDrafts, setSelectedDrafts] = useState([]);
	const [disableBtn, setDisableBtn] = useState(false);

	useEffect(() => {
		getCompositionTypes().then((res) => {
			setCompositionTypes(res.data);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		handleReleaseTypes();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang]);

	useEffect(() => {
		if (releaseTypes.length && compositionTypes.length) {
			getDrafts(page);
			setSelectedDrafts([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, releaseTypes, compositionTypes]);

	const handleReleaseTypes = () => {
		const releaseTypesCache = lscache.get('releaseTypesCache');
		if (releaseTypesCache && !isReqLive && releaseTypesCache.lang === lang) {
			setReleaseTypes(releaseTypesCache.data);
		} else {
			return getReleaseTypes(lang).then((res) => {
				setCache('releaseTypes', 'releaseTypesCache', res);
				setReleaseTypes(res);
			});
		}
	};

	const getDrafts = (page) => {
		setLoading(true);
		getUnprocessedDrafts(page)
			.then((res) => {
				if (res.data.length) {
					setTotal(res.total);
					setNoData(false);
					setDrafts(addTranslation(res.data, compositionTypes, releaseTypes));
				} else {
					if (page > 1) {
						setPage(page - 1);
					} else {
						setNoData(true);
					}
				}
			})
			.catch((err) => console.error('Error:', err))
			.finally(() => {
				setLoading(false);
			});
	};

	const handleRemoveDraft = (draft) => {
		deleteDraft(draft.draft_type, draft.id)
			.then((res) => {
				getDrafts(page);
			})
			.catch((err) => {
				console.error('Error:', err);
			});
	};

	const handleRemoveDrafts = () => {
		setDisableBtn(true);
		deleteDraftsList(selectedDrafts)
			.then(() => {
				setSelectedDrafts([]);
				getDrafts(page);
			})
			.catch((err) => {
				console.error('Error:', err);
			})
			.finally(() => setDisableBtn(false));
	};

	const selectAll = () => {
		const ids = drafts?.map((draft) => {
			return { id: draft.id, type: draft.draft_type };
		});
		if (ids.length === selectedDrafts.length) {
			setSelectedDrafts([]);
		} else {
			setSelectedDrafts(ids);
		}
	};

	const isUnknownStep = (step) => {
		const allKnownStatuses = [
			'moderation',
			'completed',
			'parsed',
			'check',
			'update',
			'release',
			'file',
			'tracks',
			'authors',
			'lyrics',
			'cover',
			'distribution',
			'confirm',
			'info',
			'authors_shares',
			'composition_recordings',
		];
		return !allKnownStatuses.includes(step);
	};

	const viewAsset = (e, asset) => {
		if (
			e.target.classList.contains('delete-icon') ||
			e.target.classList.contains('mark') ||
			e.target.type === 'checkbox'
		) {
			return;
		}
		if (asset.draft_type === 'release') {
			history.push(`/repertoire/releases/${asset.id}/info`);
		} else {
			history.push(`/repertoire/compositions/${asset.id}`);
		}
	};

	const handleCheckboxChange = (item) => {
		setSelectedDrafts((prevSelectedDrafts) => {
			if (prevSelectedDrafts.some((draft) => draft.id === item.id)) {
				// If the item is already selected, remove it
				return prevSelectedDrafts.filter((draft) => draft.id !== item.id);
			} else {
				// If the item is not selected, add it
				return [...prevSelectedDrafts, { id: item.id, type: item.draft_type }];
			}
		});
	};

	return (
		<div className={styles.mainWrapper}>
			<div className={styles.sectionTitleWrapper}>
				<div className={styles.sectionTitle}>
					<FormattedHTMLMessage id={'rod.admin.handling.drafts_title'} />
				</div>
				{selectedDrafts.length > 0 && (
					<button
						className={
							disableBtn
								? `${styles.sectionDeleteBtn} ${styles.disabled}`
								: styles.sectionDeleteBtn
						}
						disabled={disableBtn}
						onClick={showModal(
							{
								title: (
									<FormattedMessage
										id={'rod.admin.handling.drafts.modal.delete_title'}
									/>
								),
								text: (
									<FormattedHTMLMessage
										id={'rod.admin.handling.drafts.modal.delete_text'}
										values={{ value: selectedDrafts.length }}
									/>
								),
								onAction: () => {
									handleRemoveDrafts(selectedDrafts);
								},
								confirmBtnTxt: <FormattedMessage id={'rod.modal.yes'} />,
								declineBtnTxt: <FormattedMessage id={'rod.modal.no'} />,
							},
							modalTypes.FULL_MODAL
						)}
					>
						<img src={remove_cart} alt="" />
						<FormattedHTMLMessage
							id={'rod.admin.handling.drafts_delete_button'}
						/>
					</button>
				)}
			</div>
			{loading ? (
				<Loading className={styles.loader} />
			) : noData ? (
				<div>
					<NoData />
				</div>
			) : (
				<>
					<div className={styles.TableWrapper}>
						<TableHeader
							checkedAll={drafts?.length === selectedDrafts?.length}
							checkedNotAll={
								selectedDrafts?.length > 0 &&
								drafts?.length !== selectedDrafts?.length
							}
							selectAll={selectAll}
						/>
						<div className={styles.TableBody}>
							<ul>
								{drafts &&
									drafts.map((item, index) => (
										<li
											className={styles.TableItemWrapper}
											onClick={(e) => viewAsset(e, item)}
											style={
												selectedDrafts.some((draft) => draft.id === item.id)
													? { background: 'var(--color-light-grey)' }
													: {}
											}
										>
											<Row className={styles.row_data}>
												<Row className={styles.row_title}>
													<Col width={'5%'}>
														<Checkbox
															key={item.id}
															checked={selectedDrafts.some(
																(draft) => draft.id === item.id
															)}
															onChange={(e) => {
																e.stopPropagation(); // prevents triggering the viewAsset function
																handleCheckboxChange(item);
															}}
														/>
													</Col>
													<Col width={'5%'}>
														<img
															src={
																item.draft_type === 'release'
																	? cd
																	: nota_in_square
															}
															alt=""
														/>
													</Col>
													<Col
														width={'20%'}
														className={styles.item_title}
														title={item.title}
													>
														{item.title}
													</Col>
													<Col
														className={styles.item_performer}
														width={'20%'}
														title={getDraftPerformers(item)}
													>
														{getDraftPerformers(item)}
													</Col>
													<Col
														width={'15%'}
														className={styles.item_type}
														title={
															item.typeColumn[
																lang === 'ru' ? 'title_ru' : 'title_en'
															]
														}
													>
														{
															item.typeColumn[
																lang === 'ru' ? 'title_ru' : 'title_en'
															]
														}
													</Col>
													<Col width={'15%'} className={styles.item_status}>
														<FormattedMessage
															id={`rod.draft.release.${
																isUnknownStep(item.step)
																	? 'unknownstatus'
																	: item.step
															}`}
														/>
													</Col>
													<Col
														width={'15%'}
														className={styles.item_date}
														title={formatDate(item.updated_at)}
													>
														{formatDate(item.updated_at)}
													</Col>
													<Col width={'5%'}>
														<img
															src={remove_cart}
															alt=""
															onClick={showModal(
																{
																	title: (
																		<FormattedMessage
																			id={
																				'rod.admin.handling.drafts_delete_title'
																			}
																		/>
																	),
																	text: (
																		<FormattedMessage
																			id={
																				'rod.admin.handling.drafts_delete_text'
																			}
																		/>
																	),
																	onAction: () => {
																		handleRemoveDraft(item);
																	},
																	confirmBtnTxt: (
																		<FormattedMessage
																			id={'rod.modal.yes_delete'}
																		/>
																	),
																	declineBtnTxt: (
																		<FormattedMessage id={'rod.modal.cancel'} />
																	),
																},
																modalTypes.FULL_MODAL
															)}
															className={`${styles.DeleteBtn} delete-icon`}
														/>
													</Col>
												</Row>
											</Row>
										</li>
									))}
							</ul>
						</div>
					</div>

					{total > 10 && !loading && (
						<Pagination
							page={page}
							total={total}
							paginate={(pageNumber) => {
								setPage(pageNumber);
							}}
							items_on_page={10}
						/>
					)}
				</>
			)}
		</div>
	);
};
export default compose(withUI, withLang, withRoot)(HandlingDrafts);
