// Icons
import play_inactive from 'images/play_button_inactive.svg';

// Styles
import styles from './PlayInactive.module.css';

const PlayInactive = () => {
	return (
		<div className={styles.container}>
			<img
				src={play_inactive}
				alt="play"
				className={styles.repertoire__playLogo}
			/>
		</div>
	);
};

export default PlayInactive;
