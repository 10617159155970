/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

import hoistNonReactStatics from 'hoist-non-react-statics';
import React from 'react';
import getDisplayName from 'recompose/getDisplayName';

export const withForwardRef = WrappedComponent => {
	const ForwardRefComponent = React.forwardRef((props, ref) => {
		return (
			<WrappedComponent forwardRef={ref} {...props} />
		);
	});

	ForwardRefComponent.displayName = getDisplayName(WrappedComponent);
	ForwardRefComponent.name = ForwardRefComponent.displayName;
	ForwardRefComponent.defaultProps = WrappedComponent.defaultProps;
	hoistNonReactStatics(ForwardRefComponent, WrappedComponent);

	return ForwardRefComponent;
};
