export const thousandDiv = (number) => {
	const numLength = Math.floor(number).toString().length;
	if (numLength > 3 && numLength < 7) {
		const result =
			number.toString().slice(0, numLength - 3) +
			' ' +
			number.toString().slice(numLength - 3);

		return result;
	} else return number;
};
