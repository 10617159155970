// Core
import { FormattedMessage } from 'react-intl';

// UI
import { SwitchSave } from 'material-design/MuiStyled/MuiStyled';

// Styles
import styles from './InstrumentalTrackSwitcher.module.css';

const InstrumentalTrackSwitcher = ({ data, handler }) => {
	return (
		<div className={styles.switcher}>
			<div className={styles.switcher__toggler}>
				<SwitchSave
					checked={data?.is_instrumental}
					onChange={(_, c) => handler(c)}
				/>
			</div>
			<span className={styles.switcher__title}>
				<FormattedMessage id={'rod.release.create.step.lyrics.main.switcher'} />
			</span>
		</div>
	);
};

export default InstrumentalTrackSwitcher;
