// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Style
import styles from './EditButton.module.css';

const EditButton = ({ handleEditToggle }) => {
	return (
		<button onClick={handleEditToggle} className={styles.editText}>
			<svg
				width="25"
				height="25"
				viewBox="0 0 25 25"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M24.1089 22.793H0.891603C0.403488 22.793 0.0214844 23.1962 0.0214844 23.6631C0.0214844 24.13 0.42471 24.5332 0.891603 24.5332H24.1301C24.6183 24.5332 25.0003 24.13 25.0003 23.6631C25.0003 23.1962 24.597 22.793 24.1089 22.793Z"
					fill="black"
				/>
				<path
					d="M0.0212224 15.0891L0 19.2275C0 19.4609 0.0848897 19.6944 0.254669 19.8642C0.424448 20.034 0.636672 20.1188 0.870119 20.1188L4.98727 20.0976C5.22071 20.0976 5.43294 20.0127 5.60272 19.843L19.8217 5.62394C20.1613 5.28438 20.1613 4.7326 19.8217 4.37182L15.747 0.254669C15.4075 -0.0848896 14.8557 -0.0848896 14.4949 0.254669L11.6511 3.11969L0.275891 14.4737C0.127335 14.6435 0.0212224 14.8557 0.0212224 15.0891ZM15.1316 2.12224L17.9966 4.98727L16.3837 6.60017L13.5187 3.73514L15.1316 2.12224ZM1.78268 15.4711L12.2666 4.98727L15.1316 7.85229L4.64771 18.3149L1.76146 18.3362L1.78268 15.4711Z"
					fill="black"
				/>
			</svg>
			<span className={styles.editText}>
				<FormattedMessage id={'rod.admin.reports.edit'} />
			</span>
		</button>
	);
};

export default EditButton;
