// Core
import { FormattedMessage } from 'react-intl';

// Styles
import styles from '../Statistics.module.css';

const Disputes = ({ disputes }) => {
	return (
		<div id="disputes_container" className={styles.container__item}>
			<span>
				<FormattedMessage id={'rod.main_page.disputes'} />
			</span>
			{disputes && disputes.length !== 0 ? (
				<span
					id="disputes_content"
					className={Number(disputes) !== 0 ? styles.disputes : null}
				>
					{disputes.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1 `)}
				</span>
			) : (
				<span>-</span>
			)}
		</div>
	);
};

export default Disputes;
