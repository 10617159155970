// Core
import { FormattedHTMLMessage } from 'react-intl';
import { Col, Row } from 'react-grid-system';
import { useContext, useEffect, useState } from 'react';

// Context
import { RootContext } from 'contexts/RootContext';
import { AuthContext } from 'contexts/AuthContext';

// Utils
import { formatDate } from './utils/formatDate';
import { formatParameters } from './utils/formatParameters';

// UI
import Action from './Action/Action';
import { Loading, NoData } from 'components';
import { Pagination } from 'material-design/components';

// Styles
import styles from './StatisticReports.module.css';

const StatisticReports = ({ adminAccountId, accountName }) => {
	const [reports, setReports] = useState([]);
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	// eslint-disable-next-line no-unused-vars
	const [isInProgress, setIsInProgress] = useState(false);

	const {
		getAdminStatisticReports,
		getAccountStatisticReports,
		isReportDownloaded,
	} = useContext(RootContext);
	const authContext = useContext(AuthContext);

	const isAdmin = adminAccountId ? true : false;
	const accountId = adminAccountId
		? adminAccountId
		: localStorage.getItem('accountId');

	const fetchFunction = isAdmin
		? getAdminStatisticReports
		: getAccountStatisticReports;

	const fetchData = () => {
		fetchFunction(accountId, page)
			.then((res) => {
				const data = res.data.map((item) => {
					item.parameters = JSON.parse(item.parameters);
					item.parameters.datefrom = formatParameters(item.parameters.datefrom);
					item.parameters.dateto = formatParameters(item.parameters.dateto);
					item.time = item.updated_at.match(/\d{1,2}:\d{2}/)[0];
					item.updated_at = formatDate(item.updated_at);
					return item;
				});

				if (data.some((report) => report.status === 'in progress')) {
					setIsInProgress(true);
					setTimeout(() => {
						fetchData();
					}, 2000);
				} else {
					setIsInProgress(false);
				}

				setReports(data);
				setTotal(res.total);
			})
			.catch((err) => {
				console.error('Error', err);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const getReportTitle = (item) => {
		const acc = authContext.user.accounts.filter(
			(acc) => acc.id.toString() === item.parameters.account_id.toString()
		);
		let title = acc[0].title;
		if (item.name && item.name.includes('recordings')) {
			title = `${title}. Recordings`
		}
		if (item.name && item.name.includes('compositions')) {
			title = `${title}. Compositions`
		}
		if (item.name && item.name.includes('Summary')) {
			return `${title}. Summary`;
		}
		return title;
	};

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	useEffect(() => {
		if (isReportDownloaded) {
			setTimeout(() => {
				fetchData();
			}, 400);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isReportDownloaded]);

	return (
		<>
			{loading ? (
				<Loading className={styles.loader} />
			) : reports.length ? (
				<div className={styles.RepertoireSongInfo__mainInfoTable}>
					<Row className={styles.row_header}>
						<Col width={'45%'}>
							<FormattedHTMLMessage id={'rod.statistic.reports_title'} />
						</Col>
						<Col width={'25%'}>
							<FormattedHTMLMessage id={'rod.statistic.reports_period'} />
						</Col>
						<Col width={'20%'}>
							<FormattedHTMLMessage id={'rod.statistic.reports_date'} />
						</Col>
						<Col width={'10%'}>
							<FormattedHTMLMessage id={'rod.statistic.reports_action'} />
						</Col>
					</Row>
					<div className={styles.RepertoireMainInfoTable__body}>
						<ul>
							{reports.map((item) => (
								<li>
									<Row className={styles.row_data}>
										<Row className={styles.row_title}>
											<Col width={'45%'}>
												{isAdmin
													? item.updated_at +
													  ' ' +
													  item.time +
													  ' : ' +
													  accountName
													: item.updated_at +
													  ' ' +
													  item.time +
													  ' : ' +
													  getReportTitle(item)}
											</Col>
											<Col width={'25%'}>
												{item.parameters.datefrom +
													' - ' +
													item.parameters.dateto}
											</Col>
											<Col width={'20%'}>{item.updated_at}</Col>
											<Col width={'10%'}>
												<Action item={item} isAdmin={isAdmin} />
											</Col>
										</Row>
									</Row>
								</li>
							))}
						</ul>
					</div>
				</div>
			) : (
				<NoData />
			)}
			{total > 15 && !loading && (
				<Pagination
					page={page}
					total={total}
					paginate={(pageNumber) => {
						setPage(pageNumber);
					}}
					items_on_page={15}
				/>
			)}
		</>
	);
};

export default StatisticReports;
