// Core
import { Col, Row } from 'react-grid-system';
import { FormattedHTMLMessage } from 'react-intl';

// Utils
import { dateFormatEditor } from 'logic';

// UI
import download from 'images/download.svg';

// Styles
import tableStyles from '../../UserReports/UserReports.module.css';

const TableBody = ({ invoices, handleDownload }) => {
	return (
		<div className={tableStyles.RepertoireMainInfoTable__body}>
			<ul>
				{invoices.map((item) => (
					<li className={tableStyles.articlesListItem}>
						<Row className={tableStyles.row_data}>
							<Row className={tableStyles.row_title}>
								<Col width={'15%'} className={tableStyles.shell}>
									{item.created_at &&
										dateFormatEditor(item.created_at, 'dmy', '.')}
								</Col>
								<Col
									width={'25%'}
									className={tableStyles.shell}
									title={item.title}
								>
									<div className={tableStyles.ellipsisContainer}>
										{item.title}
									</div>
								</Col>
								<Col
									width={'15%'}
									className={`${tableStyles.shell} ${
										item.status === 'rejected' ? tableStyles.rejected : ''
									}`}
								>
									<FormattedHTMLMessage
										id={`rod.account.payment.status.${item.status}`}
									/>
								</Col>
								<Col width={'15%'} className={tableStyles.shell}>
									{item.status === 'paid'
										? dateFormatEditor(item.updated_at, 'dmy', '.')
										: '-'}
								</Col>

								<Col width={'15%'} className={tableStyles.shell}>
									{`${item.sum.replace(
										/(\d)(?=(\d{3})+(?!\d))/g,
										`$1\u00A0`
									)} ${item.currency}` ?? '-'}
								</Col>

								<Col width={'10%'}>
									<div
										className={tableStyles.action}
										onClick={() => handleDownload(item.id)}
									>
										<img src={download} alt="" />
										<div className={tableStyles.actionTitle}>
											<FormattedHTMLMessage id={'rod.action.download'} />
										</div>
									</div>
								</Col>
							</Row>
						</Row>
					</li>
				))}
			</ul>
		</div>
	);
};

export default TableBody;
