// Core
import React, { useState, useEffect, useContext } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { FormattedHTMLMessage } from 'react-intl';
import { withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';

// Context
import { LangContext } from 'contexts/LangContext';
import { Loading, NoData } from 'components';

// Icons
import eye from 'images/eye_black.svg';
import icon_cd from 'images/icon_cd.svg';

// Styles
import s from './RepertoireRecordingReleases.module.css';

function RepertoireRecordingReleases(props) {
	const match = useRouteMatch();
	const recordingId = match.params.id;

	const { lang } = useContext(LangContext);
	const {
		authContext: { accountId },
		rootContext: { getRecordingReleases, getReleaseTypesAll },
	} = props;

	const [loading, setLoading] = useState(true);
	const [releases, setReleases] = useState([]);
	const [isInitialLoadingDone, setIsInitialLoadingDone] = useState(false);
	const [releaseTypes, setReleaseTypes] = useState([]);

	useEffect(() => {
		const p0 = getRecordingReleases(accountId, recordingId);
		const p1 = getReleaseTypesAll(lang);

		Promise.all([p0, p1])
			.then((values) => {
				setReleases(values[0]);
				setReleaseTypes(values[1]);
				setLoading(false);
				setIsInitialLoadingDone(true);
			})
			.catch((error) => {
				console.error('Error', error);
				setLoading(false);
			});
	}, []);

	useEffect(() => {
		setLoading(true);
		if (isInitialLoadingDone) {
			getReleaseTypesAll(lang)
				.then((res) => {
					setReleaseTypes(res);
					setLoading(false);
				})
				.catch((error) => {
					console.error('Error', error);
					setLoading(false);
				});
		}
	}, [lang]);

	const getTypeTitleById = (id) => {
		return releaseTypes.find((type) => type.id === id)?.title;
	};

	const getCorrectedTBA = (date) => {
		return date.replace(/(\d{1,4})-(\d{1,2})-(\d{1,2})/g, '$3.$2.$1');
	};

	return loading ? (
		<Loading />
	) : (
		<>
			{releases && releases.length ? (
				<>
					<div className={s.body}>
						<div className={`${s.table__header} ${s.table}`}>
							<FormattedHTMLMessage id={'rod.for_all.title'} />
							<FormattedHTMLMessage id={'rod.for_all.performers'} />
							<FormattedHTMLMessage id={'rod.composition.recordery.category'} />
							<FormattedHTMLMessage id={'rod.recordery.info.release'} />
						</div>
						<ul className={s.main}>
							{releases.map((i) => (
								<li key={i.id}>
									<Link key={i.id} to={`/repertoire/releases/${i.id}/info`}>
										<div className={`${s.table__item} ${s.table}`}>
											<span className={s.item__name}>{i.title}</span>
											<span>{i.performers.join(', ')}</span>
											<span>{getTypeTitleById(i.release_type_id)}</span>
											<span>
												{i.published_date
													? getCorrectedTBA(i.published_date)
													: 'TBA'}
											</span>
											<img
												src={icon_cd}
												width={22}
												height={22}
												className={s.icon_cd}
												alt=""
											/>
											<img src={eye} className={s.eye} alt="" />
										</div>
									</Link>
								</li>
							))}
						</ul>
					</div>
				</>
			) : (
				<div>
					<NoData />
				</div>
			)}
		</>
	);
}
export default compose(withAuth, withRoot)(RepertoireRecordingReleases);
