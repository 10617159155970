/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 29/12/2020, 16:25
 */

import { Label } from 'components/Labels';
import { Success as SuccessForm } from 'forms/common';
import { withUI } from 'hocs';
import React, { Component } from 'react';
import { compose } from 'recompose';
import styles from './Success.module.css';

class Success extends Component {
	componentDidMount() {
		this.props.UIContext.showBackNavi();
	}

	componentWillUnmount() {
		this.props.UIContext.hideBackNavi();
	}

	render() {
		return (
			<SuccessForm>
				<Label black className={styles.Label}>
					{this.props.children}
				</Label>
			</SuccessForm>
		);
	}
}

export default compose(withUI)(Success);
