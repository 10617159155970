// UI
import Card from './Card/Card';

// Styles
import styles from './Skeleton.module.css';

const Skeleton = () => {
	return (
		<div className={styles.skeleton}>
			<Card />
			<Card />
			<Card />
			<Card />
      <Card />
		</div>
	);
};

export default Skeleton;
