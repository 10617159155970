// UI
import TabUnstyled from '@mui/base/TabUnstyled';

// Styles
import { styled } from '@mui/material/styles';

export const HeaderButton = styled(TabUnstyled)(({ theme }) => ({
	backgroundColor: 'var(--color-light-grey)',
	font: 'var(--gilroy-Bold-18)',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	paddingLeft: '30px',
	paddingRight: '30px',
	height: '48px',
	boxSizing: 'border-box',
	border: 'none',
	'&.Mui-selected': {
		backgroundColor: '#FAFAFA',
		textDecoration: 'line-through',
	},
}));
