// Core
import React from 'react';
import classNames from 'classnames';
import { withAuth, withUI } from 'hocs';
import { compose } from 'recompose';

// Styles
import styles from './Auth.module.css';

class Auth extends React.Component {
	static propTypes = {};

	static defaultProps = {};

	static displayName = 'Auth';

	render() {
		const {
			className,
			authContext: { user, isAccountLoading },
			UIContext: { isShowUserMenu },
			withFocusRef,
			userInfo,
			...props
		} = this.props;
		const classes = classNames(className, styles['Auth'], {
			'menu-open': isShowUserMenu,
		});

		let name;
		try {
			// Split the string by '/' and take the first part
			name = user['title_en']
				.split('/')[0]
				.split(' ')
				.slice(0, 2)
				.map((name) => name[0]);
		} catch (e) {
			name = 'NA';
		}

		return (
			<div
				{...props}
				className={classes}
				disabled={isAccountLoading ? true : false}
			>
				<div className={classNames(styles.User)}>{name}</div>
				{!userInfo && (
					<>
						{isShowUserMenu ? (
							<i className="fa fa-caret-up" />
						) : (
							<i className="fa fa-caret-down" />
						)}
					</>
				)}
			</div>
		);
	}
}

export default compose(withAuth, withUI)(Auth);
