/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 25/05/2020, 20:02
 */

import { CloseButton } from 'components/Buttons';
import { Button } from 'components/Buttons';
import { Col, Container, Row } from 'components/Grid';

import { compose } from 'recompose';
import { withLang, withRoot, withUI } from 'hocs';

import React from 'react';
import posed, { PoseGroup } from 'react-pose';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { useEffect, useState } from 'react';

import BaseModal from './BaseModal';
import styles from './Modal.module.css';

import { showError } from 'validators/showError';
import { Checkbox } from 'components/Checkboxes/Checkbox';
import { useHistory } from 'react-router-dom';

const Modal = posed.div({
	enter: {
		x: 0,
		opacity: 1,
		// delay: 200,
		transition: {
			x: {
				ease: 'easeIn',
			},
			default: { duration: 300 },
		},
	},
	exit: {
		x: '100%',
		opacity: 0,
		transition: { duration: 250 },
	},
});

const Shade = posed.div({
	enter: { opacity: 0.5 },
	exit: { opacity: 0 },
});

const AddServiceModal = (props) => {
	// const [pageLoading, setPageLoading] = useState(true);
	const [isVisible, setIsVisible] = useState(true);

	const [isConfirmedBtnActive, setIsConfirmedBtnActive] = useState(true);
	const [isShowConfirmQuestion, setIsShowConfirmQuestion] = useState(false);

	const [checkedService, setCheckedService] = useState([]);

	const { onClose, postAdditionalServices, id } = props;
	const history = useHistory();

	const willUnmount = () => {
		setIsVisible(false);
		setTimeout(() => {
			onClose();
		}, 300);
	};

	const { services } = props;

	const lang = localStorage.getItem('lang');

	const handleCheckedService = (code) => {
		if (checkedService.includes(code)) {
			const data = checkedService.filter((item) => item !== code);
			setCheckedService(data);
		} else {
			setCheckedService((prev) => [...prev, code]);
		}
	};

	const handleSubmitServices = () => {
		setIsConfirmedBtnActive(true);
		postAdditionalServices(id, { service: checkedService })
			.then((res) => {
				willUnmount();
				history.push(`/treaties/addservices/${id}`);
			})
			.catch((err) => console.info('Error:', err))
			.finally(() => {
				setIsConfirmedBtnActive(false);
			});
	};

	return (
		<BaseModal className={styles.Modal}>
			<PoseGroup>
				{isVisible && [
					<Shade key="shade" className={styles.Overlay} />,

					<Modal
						key="modal"
						style={{
							marginLeft: 'auto',
						}}
					>
						<Container fluid>
							<Row>
								<Col
									/* width={'416'} */ /* md={12 - 4} */ onClick={() =>
										willUnmount(false)
									}
								/>
								<Col width={'1'} /* md={4} */ className={styles.notify}>
									<div
										className={styles.notify__wrapper}
										style={{ width: '440px' }}
									>
										<div className={styles.ReportsModalTitle}>
											<CloseButton
												black
												// small
												className={styles.CloseButtonAddService}
												onClick={() => willUnmount(false)}
											/>
										</div>
										<div className={styles.add_service__title}>
											<FormattedHTMLMessage id={'rod.add_service.title'} />
										</div>
										<div className={styles.servicesWrapper}>
											{services.length &&
												services.map((item) => (
													<Checkbox
														key={item.code}
														text={lang === 'en' ? item.title_en : item.title_ru}
														onChange={() => {
															handleCheckedService(item.code);
														}}
														className={styles.servicesField}
													/>
												))}
										</div>

										{!isShowConfirmQuestion ? (
											<>
												<div className={styles.reports__confirm}>
													<Button
														text={
															<FormattedHTMLMessage id={'rod.action.add'} />
														}
														className={styles.activeReportsBtn}
														variant={'primary'}
														disabled={!checkedService.length}
														onClick={() => {
															setIsShowConfirmQuestion(true);
														}}
													/>
												</div>
											</>
										) : (
											<div className={styles.notify__confirm_question_wrapper}>
												<div className={styles.notify__confirm_question}>
													<FormattedHTMLMessage
														id={'rod.modal.notify.admin.confirm-question'}
													/>
												</div>

												<div className={styles.notify__confirm}>
													<Button
														text={
															<FormattedHTMLMessage
																id={'rod.modal.notify.admin.confirm-yes'}
															/>
														}
														className={styles.activeBtnConfirmQuestion}
														variant={
															isConfirmedBtnActive ? 'primary' : 'disabled'
														}
														onClick={() => {
															if (isConfirmedBtnActive) {
																setIsConfirmedBtnActive(false);
																// debouncedSendData();
																handleSubmitServices();
															}
														}}
													/>
													<Button
														text={
															<FormattedHTMLMessage
																id={'rod.modal.notify.admin.confirm-no'}
															/>
														}
														className={styles.activeBtnConfirmQuestion}
														variant={
															isConfirmedBtnActive ? 'primary' : 'disabled'
														}
														onClick={() => {
															if (isConfirmedBtnActive) {
																setIsShowConfirmQuestion(false);
															}
														}}
													/>
												</div>
											</div>
										)}
									</div>
								</Col>
							</Row>
						</Container>
					</Modal>,
				]}
			</PoseGroup>
		</BaseModal>
	);
};

export default compose(withLang, withUI, withRoot)(AddServiceModal);
