// Core
import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose } from 'recompose';
import { withForwardRef, withLang, withRoot } from 'hocs';

// Context
import { LangContext } from 'contexts/LangContext';

// UI
import { DropdownCombo } from './DropdownCombo';
import Flag from 'components/Flag';
import { Input } from 'components/Inputs/Input';

// Utils
import { hasSpaceInPhone } from './utils/hasSpaceInPhone';
import { splitPhoneNumber } from './utils/splitPhoneNumber';

// Icons
import chevronDown from 'images/chevron_down.svg';
import chevronUp from 'images/chevron_up.svg';

// Styles
import styles from './PhoneInputFormatted.module.css';

const PhoneInputFormatted = ({
	focus,
	type = 'text',
	error,
	showRequired,
	showError,
	countries,
	errors,
	phone,
	placeholder,
	helper,
	className,
	forwardRef,
	onBlur,
	disabled,
	forbidEnter,
	doneIcon,
	onChange = () => {},
	...props
}) => {
	const ref = useRef(forwardRef || null);
	const { lang } = useContext(LangContext);

	const [countryId, setCountryId] = useState(null);
	const [dialingCode, setDialingCode] = useState(null);
	const [number, setNumber] = useState(phone || '');

	useEffect(() => {
		const result = splitPhoneNumber(phone);
		if (!phone) {
			setCountryId(null);
			setDialingCode(null);
		} else {
			if (!hasSpaceInPhone(phone)) {
				setNumber(result.phone);
			} else {
				const country = countries.find(
					(item) => item.dialing_code === result.code
				);

				if (country) {
					let countryId = country.id;
					// Add the custom logic for Russia and Kazakhstan
					if (result.code === '7') {
						if (result.phone.startsWith('6') || result.phone.startsWith('7')) {
							countryId = 3;
						} else {
							countryId = 1;
						}
					}

					setCountryId(countryId);
					setDialingCode(country.dialing_code);
					setNumber(result.phone);
				} else {
					if (result.code) {
						setNumber(result.code + result.phone);
					} else {
						setNumber(result.phone);
					}
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countries]);

	useEffect(() => {
		if (focus && ref.current) {
			ref.current.focus();
		}
	}, [focus]);

	const handleChangeNumber = (value) => {
		setNumber(value);
		onChange({ dialingCode, countryId, number: value });
	};

	const handleChangeCode = (id) => {
		setCountryId(id);
		const country = countries.find((item) => item.id === id);
		setDialingCode(country.dialing_code);
		onChange({ dialingCode: country.dialing_code, code: id, number });
	};

	const calculateInputMaxLength = () => {
		const selectedCountry = countries.find((item) => item.id === countryId);
		return selectedCountry
			? 15 - selectedCountry.dialing_code.toString().length
			: 15;
	};

	if (countries.length === 0) return null;

	const country = countries.find((item) => item.id === countryId);
	const countriesWithCorrectDialingCode = countries.filter(
		(item) => item.dialing_code !== 0
	);

	const inputMaxLength = calculateInputMaxLength();

	const classes = classnames(
		className,
		errors && styles.Error,
		styles.PhoneInput
	);

	return (
		<div className={classes}>
			<div className={styles.dropdownContainer}>
				<DropdownCombo
					showChevron={false}
					items={countriesWithCorrectDialingCode}
					value={country?.id}
					getListItem={(item) => (
						<React.Fragment>
							<Flag code={item.code} dialing_code={item.dialing_code} />
							<span>+{item.dialing_code}</span>
							<span>{lang === 'ru' ? item.title_ru : item.title_en}</span>
						</React.Fragment>
					)}
					getItem={(item, isOpen) => (
						<React.Fragment>
							{countryId && (
								<Flag code={item.code} dialing_code={item.dialing_code} />
							)}
							{isOpen ? (
								<img src={chevronUp} alt="" />
							) : (
								<img src={chevronDown} alt="" />
							)}
							+{countryId ? item.dialing_code : ''}
						</React.Fragment>
					)}
					getItemValue={(item) => item.id}
					defaultItemIndex={countries.findIndex(
						(item) => item?.id === country?.id
					)}
					onChange={handleChangeCode}
					disabled={disabled}
				/>
			</div>
			<Input
				errors={errors}
				placeholder={`${placeholder}${
					showRequired && props.required ? '*' : ''
				}`}
				className={
					disabled
						? `${styles.inputWidth} ${styles.disabled}`
						: styles.inputWidth
				}
				onChange={handleChangeNumber}
				type={'number'}
				onBlur={onBlur}
				value={number}
				showError={showError}
				min={0}
				inputMaxLength={inputMaxLength}
				disabled={disabled}
				helper={helper}
				forbidEnter={forbidEnter}
				doneIcon={doneIcon}
				userEdit
				required
			/>
			<input
				type="hidden"
				ref={ref}
				value={number ? country?.dialing_code + number : ''}
			/>
			<input type="hidden" name="phone_code_country_id" value={countryId} />
			<input type="hidden" name="phone_number" value={number} />
		</div>
	);
};

PhoneInputFormatted.propTypes = {
	focus: PropTypes.bool,
	type: PropTypes.string,
	error: PropTypes.object,
	showRequired: PropTypes.bool,
	showError: PropTypes.func,
};

export default compose(withForwardRef, withLang, withRoot)(PhoneInputFormatted);
