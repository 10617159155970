// Core
import React, { useState, useEffect, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import InputMask from 'react-input-mask';
import lscache from 'lscache';
import cloneDeep from 'lodash/cloneDeep';

// Services
import { showError } from 'validators/showError';
import { dateFormat } from 'logic';
import { setCache } from 'services/cacheHelper/setCache';
import { accounts, dictionaries } from 'services';
import { withUI } from 'hocs';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { RootContext } from 'contexts/RootContext';
import { UIContext } from 'contexts/UIContext';

// UI
import { FormInput } from 'components/Form';
import { BottomNavi, InfoHelper } from 'components';
import { TextFieldInput } from 'material-design/MuiStyled/MuiStyled';

// Styles
import s from './BasicInfo.module.css';

const BasicInfo = (props) => {
	const { showBackNavi, hideBackNavi, setIsBottomNaviShow } = useContext(
		UIContext
	);
	const { accountId } = useContext(AuthContext);
	const { account } = useContext(RootContext);

	const [basicInformation, setBasicInformation] = useState({
		composition_type_id: 19,
		created_date: new Date().toISOString(),
	});
	const [countries, setCountries] = useState([]);
	const [compositionTypes, setCompositionTypes] = useState([]);
	const [errors, setErrors] = useState({});
	// eslint-disable-next-line no-unused-vars
	const [isReqLive, setIsReqLive] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [languages, setLanguages] = useState([]);
	const [disableBtn, setDisableBtn] = useState(false);

	function modifyTitle(title) {
		const exeptions = ['the', 'of', 'and', 'и'];
		const lowerTitle = title.toLowerCase();
		if (!lowerTitle.includes(' '))
			return lowerTitle.charAt(0).toUpperCase() + lowerTitle.slice(1);

		const titleAsArray = lowerTitle.split(' ');
		const modifiedTitle = titleAsArray
			.map((word) => {
				return exeptions.includes(word)
					? word
					: word.charAt(0).toUpperCase() + word.slice(1);
			})
			.join(' ');
		return modifiedTitle;
	}

	const getCountries = () => {
		const countriesCache = lscache.get('countriesCashe');
		if (countriesCache && !isReqLive) {
			const countries = countriesCache.map((obj) => {
				let result = obj;
				result.title_en = modifyTitle(obj.title_en);
				result.title_ru = modifyTitle(obj.title_ru);
				return result;
			});
			setCountries(countries);
			return countries;
		} else {
			return dictionaries
				.getCountries()
				.then((res) => {
					res = res.data.data;
					setCache('countries', 'countriesCashe', res);

					const countries = res.map((obj) => {
						let result = obj;
						result.title_en = modifyTitle(obj.title_en);
						result.title_ru = modifyTitle(obj.title_ru);
						return result;
					});

					setCountries(countries);

					return Promise.resolve(res);
				})
				.catch((err) => {
					console.error('Error', err);
				});
		}
	};

	const nextHandler = () => {
		setDisableBtn(true);
		if (typeof props.onChange === 'function' && !Object.keys(errors).length) {
			const clone = cloneDeep(basicInformation);

			if (clone.created_date && clone.created_date.length) {
				const correctedTime = new Date(
					new Date(clone.created_date).getTime() -
						new Date().getTimezoneOffset() * 60000
				).toISOString();

				if (clone.created_date.includes('T')) {
					clone.created_date = correctedTime.slice(
						0,
						clone.created_date.indexOf('T')
					);
				}
			}

			//iswc: 'T-111.222.333-4';
			if (clone.iswc) {
				clone.iswc = clone.iswc.replace(/\./g, '').replace(/-/g, '');
			}

			clone['composition_type_id'] = +clone['composition_type_id'];

			if (clone.genres) {
				const genres = clone.genres.map((item) =>
					item.code ? item.code : item
				);
				clone.genres = genres;
			}

			setBasicInformation({ ...clone });
			props
				.onChange(clone)
				.then()
				.catch()
				.finally(() => setDisableBtn(false));
		}
	};

	useEffect(() => {
		setIsBottomNaviShow(true);

		return () => {
			setIsBottomNaviShow(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const compositionId = localStorage.getItem('compositionId');
		if (!compositionId) {
			accounts
				.getAccountContactsData(accountId)
				.then(() => {
					if (account) {
						basicInformation['created_country_id'] = account['country_id'];
						setBasicInformation({ ...basicInformation });
					}
				})
				.catch((error) => {
					console.error('Error', error);
				});
		}
		getCountries();
		dictionaries
			.getCompositionTypes()
			.then((res) => {
				setCompositionTypes(res.data.data.filter((item) => item.id !== 17));
			})
			.catch((err) => {
				console.error('Error', err);
			});

		if (compositionId) {
			accounts
				.getCompositionData(compositionId)
				.then((res) => {
					res.data.data['created_date'] = res.data.data['created_date']?.match(
						/(\d{4})-(\d{2})-(\d{2})/gm
					)[0];
					res.data.data['composition_type_id'] = +res.data.data[
						'composition_type_id'
					];
					setBasicInformation(res.data.data);
				})
				.catch((err) => {
					console.error('Error', err);
				});
		}
		const languagesCache = lscache.get('languagesCache');
		if (languagesCache && !isReqLive) {
			setLanguages(languagesCache);
		} else {
			accounts
				.getLanguages()
				.then((res) => {
					res = res.data.data;
					setCache('languages', 'languagesCache', res);
					setLanguages(res);
				})
				.catch((error) => {
					console.error('Error', error);
				});
		}
		showBackNavi();

		return function cleanup() {
			hideBackNavi();
		};
		// eslint-disable-next-line
	}, []);

	const changeField = (field) => (e) => {
		switch (field) {
			case 'created_date':
				if (e && e.getFullYear().toString().length === 4) {
					e = dateFormat(e);
				}
				basicInformation[field] = e;
				break;

			case 'genres':
				const newArr = e.map((item) => (item.code ? item.code : item));
				basicInformation[field] = newArr;
				break;
			case 'composition_type_id':
				basicInformation[field] = e.id;
				break;
			case 'created_country_id':
				basicInformation[field] = e;
				break;
			case 'language':
				basicInformation[field] = e.id;

				break;

			default:
				basicInformation[field] = e.target.value;
				break;
		}
		setBasicInformation({ ...basicInformation });
	};

	const changeMask = (e) => {
		basicInformation.iswc = e.target.value;
		setBasicInformation({ ...basicInformation });
	};

	const handleOnBlurDateInput = (err, value) => {
		setErrors({});
		props.checkDateErrors(err);

		if (err) {
			setErrors({ created_date: [{ rule: 'wrong_format' }] });
		}
	};

	const disabledNextBtn =
		!basicInformation.title ||
		!basicInformation.composition_type_id ||
		!basicInformation.created_date ||
		!basicInformation.created_country_id ||
		(errors && Object.keys(errors).includes('created_date'));

	return (
		<>
			<div className={s.page}>
				<div className={s.main}>
					<h2 className={s.title}>
						<FormattedMessage id={'rod.add_composition_page.step1'} />
					</h2>
					<p className={s.required_text}>
						<FormattedMessage id={'rod.label.required_fields'} />
					</p>
					<div>
						<ul className={s.list}>
							<div className={s.input}>
								<li className={s.list_item}>
									<FormInput
										type="muiInput"
										name={'title'}
										onChange={changeField}
										errors={props.errors}
										data={basicInformation}
										label={<FormattedMessage id={'rod.basic_info.name'} />}
										required
									/>
									{props.errors && Object.keys(props.errors).includes('title') && (
										<span className={s.Helper}>
											{props.errors.title[0].rule === 'max_length' ? (
												<FormattedMessage
													id={`rod.error.${props.errors.title[0].rule}`}
													values={{
														num: props.errors.title[0].value,
													}}
												/>
											) : (
												<FormattedMessage
													id={`rod.error.${props.errors.title[0].rule}`}
												/>
											)}
										</span>
									)}
								</li>
							</div>
							<div className={s.input}>
								<li className={s.list_item}>
									<FormInput
										type="muiInput"
										name={'subtitle'}
										onChange={changeField}
										errors={props.errors}
										data={basicInformation}
										label={<FormattedMessage id={'rod.basic_info.subtitle'} />}
									/>
									{props.errors &&
										Object.keys(props.errors).includes('subtitle') && (
											<span className={s.Helper}>
												{props.errors.subtitle[0].rule === 'max_length' ? (
													<FormattedMessage
														id={`rod.error.${props.errors.subtitle[0].rule}`}
														values={{
															num: props.errors.subtitle[0].value,
														}}
													/>
												) : (
													<FormattedMessage
														id={`rod.error.${props.errors.subtitle[0].rule}`}
													/>
												)}
											</span>
										)}
								</li>
							</div>
							<li className={s.list_item}>
								<FormInput
									type="muiInputSelect"
									name={'composition_type_id'}
									onChange={changeField}
									errors={props.errors}
									data={basicInformation}
									label={<FormattedMessage id={'rod.basic_info.type'} />}
									minWidth={352}
									items={compositionTypes}
									required
								/>
							</li>
							<li className={s.list_item}>
								<FormInput
									type={'muiAutocompleteMultipleGenres'}
									name={'genres'}
									onChange={changeField}
									errors={props.errors}
									data={basicInformation}
									label={<FormattedMessage id={'rod.basic_info.genres'} />}
								/>
								{Object.keys(props.errors).includes('genres') && (
									<>
										{props.errors.genres[0].rule !== 'max_size' ? (
											<span className={s.Helper}>
												{
													<FormattedMessage
														id={`rod.error.${props.errors.genres[0].rule}`}
													/>
												}
											</span>
										) : (
											<span className={s.Helper}>
												{props.errors.genres[0] && (
													<FormattedMessage
														id={'rod.error.max_size.genres'}
														values={{ value: props.errors.genres[0]?.value }}
													/>
												)}
											</span>
										)}
									</>
								)}
							</li>
							<div className={s.input}>
								<li className={s.list_item}>
									<FormInput
										type="muiInput"
										name={'alternative_title'}
										onChange={changeField}
										errors={props.errors}
										data={basicInformation}
										label={
											<FormattedMessage id={'rod.basic_info.alter_name'} />
										}
									/>
									{props.errors &&
										Object.keys(props.errors).includes('alternative_title') && (
											<span className={s.Helper} style={{ bottom: '25px' }}>
												{props.errors.alternative_title[0].rule ===
												'max_length' ? (
													<FormattedMessage
														id={`rod.error.${props.errors.alternative_title[0].rule}`}
														values={{
															num: props.errors.alternative_title[0].value,
														}}
													/>
												) : (
													<FormattedMessage
														id={`rod.error.${props.errors.alternative_title[0].rule}`}
													/>
												)}
											</span>
										)}
								</li>
							</div>
							<div className={s.input}>
								<li className={s.list_item}>
									<InputMask
										mask={'T\\-999.999.999-9'}
										name={'iswc'}
										onChange={changeMask}
										errors={props.errors}
										value={basicInformation.iswc ? basicInformation.iswc : ''}
									>
										{() => (
											<TextFieldInput
												variant="standard"
												type="muiInput"
												name={'iswc'}
												errors={errors}
												value={
													basicInformation.iswc ? basicInformation.iswc : ''
												}
												label="ISWC"
											/>
										)}
									</InputMask>
									<p className={s.description}>
										<FormattedMessage id={'rod.basic_info.description_text'} />
									</p>
								</li>
							</div>
							<div className={s.input}>
								<li className={s.list_item}>
									<FormInput
										type={'muiInputDate_v2'}
										name={'created_date'}
										onChange={changeField}
										handleOnBlurDateInput={handleOnBlurDateInput}
										maxDate={true}
										errors={
											Object.keys(props.errors).includes('created_date')
												? props.errors
												: errors
										}
										data={basicInformation}
										required
										label={<FormattedMessage id={'rod.basic_info.date'} />}
									/>
									{errors && Object.keys(errors).includes('created_date') && (
										<span className={s.Helper}>
											{showError(errors.created_date[0].rule)(
												errors.created_date[0]
											)}
										</span>
									)}
									{Object.keys(props.errors).includes('created_date') && (
										<span className={s.Helper}>
											{
												<FormattedMessage
													id={`rod.error.${props.errors.created_date[0].rule}`}
												/>
											}
										</span>
									)}
								</li>
							</div>
							<li className={`${s.list_item} ${s.input}`}>
								<FormattedMessage id={'rod.basic_info.country_name'}>
									{(placeholder) => (
										<FormInput
											type="country"
											name={'created_country_id'}
											placeholder={placeholder}
											onChange={changeField}
											errors={props.errors}
											data={basicInformation}
											minWidth={352}
											items={countries}
											countries={countries}
											required
										/>
									)}
								</FormattedMessage>
							</li>
						</ul>
					</div>
				</div>
				<InfoHelper text="rod.composition.create.step.basic.helper_body" />
			</div>
			<BottomNavi
				showPrevBtn={false}
				disabled={disabledNextBtn || disableBtn}
				next={nextHandler}
			/>
		</>
	);
};

export default withUI(BasicInfo);
