// Core
import { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

// Helpers
import { compareMaps } from './helpers/compareMaps';

// UI
import { Button } from 'components/Buttons';
import CloseIcon from '@mui/icons-material/Close';
import { LangContext } from 'contexts/LangContext';
import MuiSelect from 'material-design/components/MuiSelect/MuiSelect';

// Styles
import s from './RepertoireFilterModalv2.module.css';

const RepertoireFilterModalv2 = ({
	active,
	setActive,
	filterData,
	setFilterData,
	currentFilterSettings,
	setCurrentFilter,
	selectFilters,
	setSelectFilters,
	setPage,
	removeSelectFilter,
	clearAll,
}) => {
	useEffect(() => {
		if (active) {
			document.body.style.position = 'fixed';
			document.body.style.top = `-${window.scrollY}px`;
		}
		if (!active) {
			const scrollY = document.body.style.top;
			document.body.style.position = '';
			document.body.style.top = '';
			window.scrollTo(0, parseInt(scrollY || '0') * -1);
		}
	}, [active]);
	const settings = filterData || [];
	const { lang } = useContext(LangContext);

	const handleSelect = (item, setting) => {
		const newSettings = [...settings];
		const itemIndex = item.id - 1;
		const list = newSettings[itemIndex].list;

		newSettings[itemIndex].list = list.map((innerItem) => {
			return {
				...innerItem,
				checked: innerItem.id === setting.id,
			};
		});
		setFilterData(newSettings);
		handleSetCurrentFilter(item, setting);
	};

	const handleSetCurrentFilter = (item, setting) => {
		if (setting.id < 0) {
			removeSelectFilter(item.title_en, true);
			return;
		}
		const newCurrentFilter = {
			parentId: item.id,
			currentId: setting.id,
			id: '' + item.id + setting.id,
			title_en: item.title_en,
			title_ru: item.title_ru,
			name_en: setting.title_en,
			name_ru: setting.title_ru,
			show: true,
			code: setting.code,
			query: item.query,
		};
		setSelectFilters(newCurrentFilter);
	};

	const handleApplyAllFilters = () => {
		if (!compareMaps(selectFilters, currentFilterSettings)) {
			setPage(1);
			localStorage.setItem('prevPage', 1);
		}
		const isSomeChecked = filterData
			.reduce((itemA, itemB) => [...itemA, ...itemB.list], [])
			.some((i) => i.checked);
		if (!isSomeChecked && !selectFilters.size) {
			setCurrentFilter(new Map());
		} else {
			setCurrentFilter(selectFilters);
		}
		setActive(false);
	};

	return (
		<>
			<div className={active ? `${s.Overlay} ${s.OverlayActive}` : s.Overlay} />

			<div
				className={active ? `${s.modal} ${s.active}` : s.modal}
				onClick={() => setActive(false)}
			>
				<div className={s.container} onClick={(e) => e.stopPropagation()}>
					<div className={s.modalHeader}>
						<div className={s.modalHeader__titleWrapper}>
							<span className={s.modalHeader__title}>
								<FormattedMessage id={'rod.release.info.filters'} />
							</span>
							<span onClick={clearAll} className={s.modalHeader_clear}>
								<FormattedMessage id={'rod.repertoire.clear_all'} />
							</span>
							<div className={s.modalHeader__icon}>
								<CloseIcon
									fontSize="small"
									onClick={() => setActive(false)}
									style={{ cursor: 'pointer' }}
								/>
							</div>
						</div>
					</div>
					<div className={s.modalBody}>
						{settings &&
							settings?.map((item) => {
								const savedSetting = new Map(selectFilters).get(item.title_en);
								const selectedItem = item.list.find(
									(item) => item.checked || savedSetting?.code === item.code
								);
								return (
									<div key={item.id} className={s.setting}>
										<div className={s.setting__title}>
											{lang === 'en' ? item.title_en : item.title_ru}
										</div>
										<MuiSelect
											label={lang === 'en' ? item.label_en : item.label_ru}
											items={item.list}
											value={selectedItem || {}}
											isNotSelectedRowExists
											sort={false}
											lowercase
											onChange={(value) => {
												handleSelect(item, value);
											}}
										/>
									</div>
								);
							})}
						<Button
							className={s.button__select}
							variant={'primary'}
							onClick={() => handleApplyAllFilters()}
						>
							<FormattedMessage id={'rod.release.info.apply'} />
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};

export default RepertoireFilterModalv2;
