// Core
import React from 'react';
import classNames from 'classnames';
import { withLang, withAuth } from 'hocs';
import { compose } from 'recompose';

// Styles
import styles from './Lang.module.css';

class Lang extends React.Component {
	static propTypes = {};
	static defaultProps = {};
	static displayName = 'Lang';

	render() {
		const { className, langContext, withFocusRef, ...props } = this.props;
		const { lang, setLanguage } = langContext;
		const classes = classNames(className, styles['Lang']);

		const anotherLang = lang === 'en' ? 'ru' : 'en';
		const changeLang = () => {
			const {
				authContext: { changeWidget, user },
			} = this.props;

			setLanguage(anotherLang);
			changeWidget(user, anotherLang);
		};

		return (
			<div {...props} className={classes}>
				<ul className={styles.List}>
					<li className="current-lang">{lang}</li>
					<li className="another-lang" onClick={changeLang}>
						{anotherLang}
					</li>
				</ul>
			</div>
		);
	}
}

export default compose(withLang, withAuth)(Lang);
