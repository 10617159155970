/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

import { Button } from 'components/Buttons';
import { Input } from 'components/Inputs';
import { Label } from 'components/Labels';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './ChangePassword.module.css';

class ChangePassword extends PureComponent {
	static propTypes = {
		onSubmit: PropTypes.func,
	};

	state = {
		isMatch: true,
	};

	form = React.createRef();
	password = React.createRef();
	confirm = React.createRef();

	submitHandler = (event) => {
		event.preventDefault();
		if (this.password.current.value === this.confirm.current.value) {
			if (this.props.onSubmit) {
				this.props.onSubmit({
					password: this.password.current.value,
				});
			}
		} else {
			this.setState({
				isMatch: false,
			});
		}

	};

	changeHandler = () => {
		if (!this.state.isMatch) {
			if (this.password.current.value === this.confirm.current.value) {
				this.setState({
					isMatch: true,
				});
			}
		}
	};

	render() {
		return (
			<form className={styles.Form} ref={this.form} onSubmit={this.submitHandler}>
				<Label className={styles.Title} black><FormattedMessage id={'rod.change_password.header'}/></Label>
				<div className={styles.Fields}>
					<Input placeholder={<FormattedMessage id={'rod.field.new_password'}/>} type="password"
								 ref={this.password} onChange={this.changeHandler}
								 error={this.state.isMatch ? null : { message: 'rod.passwords_not_match' }}
					/>
					<Input placeholder={<FormattedMessage id={'rod.field.confirm'}/>} type="password" ref={this.confirm}
								 onChange={this.changeHandler}
					/>
				</div>
				<div className={styles.Controls}>
					<Button text={<FormattedMessage id={'rod.action.change_password'}/>}
									type={'submit'}
									variant={'primary'}
					/>
				</div>

			</form>
		);
	}
}

export default ChangePassword;
