// Core
import moment from 'moment';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { compose } from 'recompose';

// Utils
import { showError, removeDuplicates } from './utils/codeInputUtils';

// UI
import { Input } from 'components/Inputs/Input';
import { withAuth, withUI } from 'hocs';

// Constants
import { INPUT_TIME_COUNTER } from 'constants/timeConstants';

// Styles
import styles from './CodeInputRegister.module.css';

class CodeInput extends PureComponent {
	constructor(props) {
		super(props);
		const {
			UIContext: { putResendSMSTime, resendSMSTime },
		} = this.props;

		this.state = {
			time: moment(new Date(INPUT_TIME_COUNTER)),
			isRemainSMSTime: false,
			showResendButton: false,
			resendSMSTime: resendSMSTime,
			putResendSMSTime: putResendSMSTime,
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.remainSMSTime && !this.state.isRemainSMSTime) {
			this.setState({
				time: moment(new Date(this.props.remainSMSTime)),
				isRemainSMSTime: true,
			});
		}
	}

	componentDidMount() {
		if (this.state.resendSMSTime === INPUT_TIME_COUNTER) {
			this.state.putResendSMSTime(new Date().getTime());
			this.setState({ time: moment(new Date(this.state.resendSMSTime)) });
		} else if (
			INPUT_TIME_COUNTER - (new Date().getTime() - this.state.resendSMSTime) <=
			0
		) {
			this.setState({
				time: moment(new Date(0)),
			});
		} else {
			if (
				(new Date(
					Math.abs(
						INPUT_TIME_COUNTER -
							(new Date().getTime() - this.state.resendSMSTime)
					)
				).getMinutes() +
					('.' +
						new Date(
							Math.abs(
								INPUT_TIME_COUNTER -
									(new Date().getTime() - this.state.resendSMSTime)
							)
						).getSeconds())) *
					60 *
					1000 >
				INPUT_TIME_COUNTER
			) {
				this.setState({
					time: moment(
						new Date(Math.abs(new Date().getTime() - this.state.resendSMSTime))
					),
				});
			} else {
				this.setState({
					time: moment(
						new Date(
							INPUT_TIME_COUNTER -
								(new Date().getTime() - this.state.resendSMSTime)
						)
					),
				});
			}
		}

		setTimeout(() => {
			this.reset();
		}, 0);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
		this.reset();
	}

	reset() {
		this.interval = setInterval(() => {
			if (this.state.time.valueOf() > 0) {
				const time = moment(this.state.time.subtract(1, 'seconds'));
				this.setState({ time });
			} else {
				clearInterval(this.interval);
				if (!this.props.withParentErrors) {
					this.setState({ showResendButton: true });
					this.props.clearAllErrors();
				}
			}
		}, 1000);
	}

	handleResend = (event) => {
		event.preventDefault();
		const { onResend } = this.props;
		this.setState(
			{
				time: moment(new Date(this.context['resendSMSTime'])),
				showResendButton: false,
			},
			() => {
				if (typeof onResend === 'function') {
					onResend();
				}
				this.state.putResendSMSTime(new Date().getTime());
				this.setState({ time: moment(new Date(INPUT_TIME_COUNTER)) });
				this.reset();
			}
		);
	};

	onChange(text) {
		if (this.props.clearAllErrors) {
			this.props.clearAllErrors();
		}
	}

	changeRemainTime = (t) => {
		this.setState({ time: moment(new Date(t)) });
	};

	render() {
		let {
			register,
			onResend,
			errors,
			remainSMSTime,
			disabled,
			phoneDisabled,
			forbidEnter,
			...props
		} = this.props;
		let combinedErrors = [];

		if (register) {
			const {
				authContext: { errors: contextErrors },
			} = this.props;

			if (Object.keys(contextErrors) && contextErrors?.code) {
				// Combine errors and contextErrors into a single array
				combinedErrors = [...errors, ...contextErrors?.code];
			} else {
				combinedErrors = [...errors];
			}
		}

		if (errors.find((element) => element.rule === 'retry')) {
			errors = errors.filter((element) => element.rule !== 'retry');
		}

		if (errors.find((el) => el.rule === 'forbidden')) {
			this.setState({
				showResendButton: false,
			});
		}

		const { time, showResendButton } = this.state;
		return (
			<FormattedHTMLMessage id={'rod.field.sms.helper'}>
				{(helper) => {
					return (
						<div className={styles.CodeInput}>
							<FormattedMessage id={'rod.verify_sms.placeholder'}>
								{(placeholder) => (
									<Input
										{...props}
										className={styles.Input}
										placeholder={placeholder}
										clearInputIfHasError={register ? false : true}
										errors={
											register ? removeDuplicates(combinedErrors) : errors
										}
										onChange={(s) => {
											this.onChange(s);
										}}
										name={'code'}
										showError={(error) =>
											showError(
												error,
												this.props.isChangeSignTypeAvailable,
												register
											)
										}
										helper={
											!showResendButton && !this.props.isLoading
												? `${helper} ${
														time.minutes() > 30
															? '0:00'
															: `${moment(time).format('mm:ss')}`
												  }`
												: undefined
										}
										register={register}
										disabled={disabled}
										forbidEnter={forbidEnter}
									/>
								)}
							</FormattedMessage>
							{showResendButton && phoneDisabled && (
								<button
									className={styles.resendBtn}
									onClick={this.handleResend}
									style={
										Object.keys(errors).length > 0 &&
										errors.code &&
										showResendButton
											? { bottom: '5px' }
											: {}
									}
								>
									<FormattedHTMLMessage id={'rod.verify_sms.send_again'} />
								</button>
							)}
						</div>
					);
				}}
			</FormattedHTMLMessage>
		);
	}
}

CodeInput.propTypes = {
	onResend: PropTypes.func,
	errors: PropTypes.array,
	isChangeSignTypeAvailable: PropTypes.bool,
};

CodeInput.defaultProps = {
	errors: [],
};

export default compose(withAuth, withUI)(CodeInput);
