export const statusPriority = [
	{
		title: 'not_ready',
		priority: 1,
	},

	{
		title: 'rejected',
		priority: 2,
	},

	{
		title: 'pending',
		priority: 3,
	},

	{
		title: 'takendown',
		priority: 4,
	},

	{
		title: 'delivered',
		priority: 5,
	},

	{
		title: 'approved',
		priority: 6,
	},

	{
		title: 'ready',
		priority: 7,
	},
];

export const getItemStatusNew = (statuses) => {
	let filteredStatuses = [];

	statuses.forEach((status) => {
		statusPriority.forEach((priorityItem) => {
			if (status === priorityItem.title?.toString())
				filteredStatuses.push(priorityItem);
		});
	});

	const mainStatus = filteredStatuses.sort(
		(a, b) => a.priority - b.priority
	)[0];

	return mainStatus.title;
};
