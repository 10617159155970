/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 07/08/2020, 19:57
 */
// eslint-disable-next-line
const DocumentTypes = {
	PASSPORT_PHOTO_PAGE: 'passport_photo_page',
	PASSPORT_REGISTRATION_PAGE: 'passport_registration_page',
	DRIVER_LICENSE_FRONT_PAGE: 'driver_license_front_page',
	DRIVER_LICENSE_BACK_PAGE: 'driver_license_back_page',
	SOLE_ENTREPRENEUR_REGISTRATION_CERTIFICATE:
		'sole_entrepreneur_registration_certificate',
	COMPANY_REGISTRATION_CERTIFICATE: 'company_registration_certificate',
	COMPANY_TAX_RESIDENCY_CERTIFICATE: 'company_tax_residency_certificate',
};

export default DocumentTypes;
