// Core
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { withUI, withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';

// Context
import { LangContext } from 'contexts/LangContext';

// UI
import { FormInput } from 'components/Form/FormInput';
import ReportsCheckLayout from '../../ReportsCheckLayout/ReportsCheckLayout';

// Styles
import styles from './BankData.module.css';

const BankData = ({ data, changeField, errors, ...props }) => {
	const { lang } = useContext(LangContext);

	const [personal, setPersonal] = useState({});

	useEffect(() => {
		setPersonal(data);
	}, [data, lang]);

	const {
		rootContext: { countries },
	} = props;

	return (
		<>
			{personal && (
				<ReportsCheckLayout title="rod.account.bank.title" hideClose={true}>
					<div>
						<FormattedMessage id={'rod.field.beneficiary_name'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'beneficiary_name'}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.bank_account_name'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'bank_account_name'}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.swift'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'swift_code'}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						{props.accountType !== 'individual' && (
							<FormattedMessage id={'rod.field.cor_account'}>
								{(placeholder) => (
									<FormInput
										placeholder={placeholder}
										name={'bank_correspondent_account'}
										onChange={changeField}
										errors={errors}
										data={personal}
									/>
								)}
							</FormattedMessage>
						)}
					</div>
					<div>
						<FormattedMessage id={'rod.field.bank_name'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'bank_name'}
									onChange={changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.bank_department_address'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'bank_address'}
									onChange={changeField}
									required
									errors={errors}
									data={personal}
								/>
							)}
						</FormattedMessage>
						<div className={styles.countrySelect}>
							<FormattedMessage id={'rod.field.bank_country'}>
								{(placeholder) => (
									<FormInput
										type="country"
										countries={countries}
										placeholder={placeholder}
										name={'bank_country'}
										onChange={changeField}
										errors={errors}
										data={personal}
										className={styles.country}
										required
									/>
								)}
							</FormattedMessage>
							{Object.keys(errors).includes('bank_country') &&
								errors?.bank_country[0]?.message && (
									<div className={styles.error}>
										<FormattedMessage
											id={'rod.error.currency_country_not_match'}
										/>
									</div>
								)}
						</div>

						{props.accountType !== 'individual' && (
							<FormattedMessage id={'rod.field.cor_swift'}>
								{(placeholder) => (
									<FormInput
										placeholder={placeholder}
										name={'bank_correspondent_account_swift_code'}
										onChange={changeField}
										errors={errors}
										data={personal}
									/>
								)}
							</FormattedMessage>
						)}
					</div>
				</ReportsCheckLayout>
			)}
		</>
	);
};

export default compose(withUI, withAuth, withRoot)(BankData);
