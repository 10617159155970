// Core
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { useContext } from 'react';

// Contexts
import { UIContext } from 'contexts/UIContext';
import { LangContext } from 'contexts/LangContext';

// UI
import { Label } from 'components/Labels/Label';
import { FormInput } from 'components/Form/FormInput';
import { InfoButton } from 'components/Buttons';

// Styles
import styles from '../CompositionLyrics.module.css';

const LyricWrapper = ({
	changeField,
	errors,
	compositionData,
}) => {
	const { lang } = useContext(LangContext);
	const { showModal } = useContext(UIContext);

	return (
		<div className={styles.lyricWrapper}>
			<InfoButton
				className={styles.lyricInfo}
				onClick={showModal({
					text: (
						<FormattedHTMLMessage id={'rod.release.create.step.lyrics.help'} />
					),
				})}
			>
				<Label
					text={
						<FormattedMessage
							id={'rod.release.create.step.lyrics.add_lyrics.title'}
						/>
					}
					font="--gilroy-Medium-24-28"
					black
				/>
			</InfoButton>
			<FormInput
				type={'muiTextarea'}
				name={'lyrics'}
				placeholder={
					lang === 'ru'
						? 'введите текст песни (максимум 10000 символов)'
						: 'enter the lyrics of the song (maximum 10000 characters)'
				}
				onChange={changeField}
				errors={errors}
				data={compositionData}
				isTextAreaEnable={true}
			/>
			{errors && Object.keys(errors).includes('lyrics') && (
				<span className={styles.Helper}>
					{
						<FormattedMessage
							id={`rod.release_create.error.lyrics.${errors.lyrics}`}
						/>
					}
				</span>
			)}
		</div>
	);
};

export default LyricWrapper;
