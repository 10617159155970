// Icons
import release_nota from 'images/release_nota.svg';

// Styles
import styles from './Header.module.css';

const Header = ({ type, name, ean, generate_ean }) => {
	return (
		<div className={styles.header}>
			<img src={release_nota} alt="" />
			<span className={styles.headerTitle__type}>{type ? type : '-'}</span>
			<span className={styles.headerTitle__name} title={name ? name : '-'}>
				{name ? name : '-'}
			</span>
		</div>
	);
};

export default Header;
