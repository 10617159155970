// UI
import InfoTable from './InfoTable/InfoTable';
import CoverBlock from './Cover/Cover';

// Styles
import styles from './Info.module.css';

const Info = ({
	info,
	cover,
	allGenres,
	isCoverLoaded,
	handleImageLoad,
	handleImageErrored,
}) => {
	return (
		<div className={styles.info}>
			<InfoTable info={info} allGenres={allGenres} />
			<CoverBlock
				cover={cover}
				isCoverLoaded={isCoverLoaded}
				handleImageLoad={handleImageLoad}
				handleImageErrored={handleImageErrored}
			/>
		</div>
	);
};

export default Info;
