/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 23/09/2020, 15:24
 */

// Core
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import lscache from 'lscache';

// UI
import { Label } from 'components/Labels';
import { withUI, withAuth } from 'hocs';
import { compose } from 'recompose';

// Styles
import styles from './SessionExpired.module.css';

class SessionExpired extends Component {
	componentDidMount() {
		this.props.UIContext.showBackNavi();
	}

	componentWillUnmount() {
		this.props.UIContext.hideBackNavi();
	}

	handleBack = () => {
		localStorage.removeItem('token');
		localStorage.removeItem('refresh');
		lscache.flush();

		this.setState(
			{
				isAuth: false,
				user: null,
			},
			() => {
				localStorage.removeItem('releaseId');
				localStorage.removeItem('accountId');
				this.props.authContext.getAccountCompletionStep();
				this.props.authContext.changeWidget();
				this.props.history.push('/login');
			}
		);
	};

	render() {
		return (
			<div className={styles.Page}>
				<div className={styles.Form}>
					<div className={styles.Title}>
						<Label font="--gilroy-Medium-40" black>
							<FormattedMessage id={'rod.error_page.session_expired.header'} />
						</Label>
						<Label font="--gilroy-Medium-24" black>
							<FormattedMessage
								id={`rod.error_page.session_expired.body`}
								values={{
									a: (msg) => (
										<span onClick={this.handleBack} className={styles.link}>
											{msg}
										</span>
									),
								}}
							/>
						</Label>
					</div>
				</div>
			</div>
		);
	}
}

export default compose(withUI, withRouter, withAuth)(SessionExpired);
