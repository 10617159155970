export default function Add({ className, color, length=282 }) {
	return (
		<svg 
			className={className}
			width={length}
			height={length}
			viewBox="0 0 282 282"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="1.5"
				y="1.5"
				width="279"
				height="279"
				fill="white"
				stroke={color}
				strokeWidth="3"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M142.579 81H139.421V139.421H81V142.579L139.421 142.579V201H142.579V142.579L201 142.579V139.421H142.579V81Z"
				fill={color}
			/>
		</svg>
	);
}
