/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 03/03/2021, 16:02
 */
// eslint-disable-next-line
const AutoComplete = {
	PHONE_CONFIRM: 'confirm_phone',
	EMAIL_CONFIRM: 'confirm_email',
	ACCOUNT_CREATED: 'account',
	BEGIN: 'begin',
	ROLE: 'role',
	PERSONAL_DATA: 'personal_data',
	CONTACT_DATA: 'contact_data',
	DOCUMENTS: 'documents',
	IDENTITY: 'identity',
	MONEY: 'money',
	PENDING: 'pending',
	PENDING_CONTRACT: 'pending_contracts',
	APPROVED: 'approved',
	DECLINED: 'declined',
	DECLINED_DOCUMENTS: 'declined_docs',
	DECLINED_CONTRACTS: 'declined_contracts',
	APPROVED_CONTRACTS: 'approved_contracts',
	SIGN_PHYSICAL: 'sign_physical',
	CONFIRM: 'confirm',
	SIGN_SMS: 'sign_sms',
	SMS_CONFIRM: 'sms_confirm',
	COMPLETE: 'completed',
	PERSONAL_AREA: 'personal_area',
	REPERTOIRE_APPLY: 'repertoire_apply',
	PERSONAL_AREA_LEGASY: 'personal_area_legacy',
	LIST: 'list',
};

export default AutoComplete;
