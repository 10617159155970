// Core
import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { compose } from 'recompose';
import { withAuth } from 'hocs';

// Icons
import logo_broma from './../../partner/broma/logo.svg';
import logo_hitmaker from './../../partner/hitmaker/logo2.png';
import logo_dgtal from './../../partner/dgtal/logo_dgtal.png';
import logo_mts from './../../partner/mts/logo_mts.svg';
import logo_default_partner from './../../partnerDefault/logo.svg';

let styles;
let logo;

switch (process.env.REACT_APP_CLIENT) {
	case 'broma':
		logo = logo_broma;
		styles = require(`./../../partner/broma/Logo.module.css`);
		break;
	case 'hitmaker':
		logo = logo_hitmaker;
		styles = require(`./../../partner/hitmaker/Logo.module.css`);
		break;
	case 'dgtal':
		logo = logo_dgtal;
		styles = require(`./../../partner/dgtal/Logo.module.css`);
		break;
	case 'mts':
		logo = logo_mts;
		styles = require(`./../../partner/mts/Logo.module.css`);
		break;

	default:
		logo = logo_default_partner;
		styles = require('./../../partnerDefault/Logo.module.css');
		break;
}

class Logo extends React.Component {
	static propTypes = {};

	static defaultProps = {};

	static displayName = 'Logo';

	render() {
		const {
			className,
			authContext: { isAuth, user },
			withFocusRef,
			...props
		} = this.props;

		const classes = classNames(className, styles['Logo']);

		const getLinkPath = () => {
			if (isAuth) {
				if (user.account_id) {
					return `/accounts/${user.account_id}`;
				} else if (user.completion_step === 'confirm_phone') {
					return '/registration/sms/check';
				} else if (user.completion_step === 'confirm_email') {
					return '/registration/email/check';
				} else {
					return '/accounts';
				}
			} else {
				return '/';
			}
		};

		return (
			<div {...props} className={classes}>
				<Link to={getLinkPath()}>
					<div
						style={{ backgroundImage: `url(${logo})` }}
						className={styles.Image}
					/>
				</Link>
				{window.COMMIT_SHA && (
					<div className={styles.Commit} title={window.COMMIT_SHA}>
						{window.COMMIT_SHA.substring(0, 8)}
					</div>
				)}
			</div>
		);
	}
}

export default compose(withAuth)(Logo);
